import React, { useEffect }                                  from 'react';
import { CommentInputTextarea, KirokuButton, KirokuComment } from '../../../../ui';
import { useTranslation }                                    from 'react-i18next';
import { useCommentsTab }                                    from './useCommentTab';
import moment                                                from 'moment';
import { ScrollingAnchor }                                   from '../../../../ui/Scrolling';


export default function CommentsTab({ isView, record, setCommentsCount }) {
    const {
              comments, isLoadingComment, result,
              contentComment, handleSubmitComment,
              getComments, handleChangeComment,
          } = useCommentsTab(record);

    const { t } = useTranslation();

    useEffect(() => {
        getComments(record.id);
    }, [getComments, record]);

    useEffect(() => {
        setCommentsCount(result.length);
    }, [result, setCommentsCount]);


    return (<div hidden={ !isView }>
        <div className='card pt-2 pb-3'>
            <div className="form-comment pl-3">
                <div className="pb-2">{ t('RecordsBeta.comment') }</div>
                <div className="input-form-comment">
                    <CommentInputTextarea
                        onChange={ (e) => {
                            handleChangeComment(e.target.value);
                        } }
                        contentComment={ contentComment }
                        style={ { minHeight: 76 } }
                    />
                </div>

                <div className={ 'pt-2' }>
                    <KirokuButton disabled={ !contentComment }
                                  color={ 'primary' }
                                  onClick={ () => handleSubmitComment() }
                    >
                        <span className='font-text-card-btn'>{ t('RecordsBeta.submitComment') }</span>
                    </KirokuButton>
                </div>
            </div>
        </div>
        <div className={ 'bg-main pt-3' }>
            <div hidden={ !isLoadingComment } className={ 'text-center' }>
                <div className="lds-ellipsis-loading">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>
        </div>
        <div className='card'>
            <div>
                { comments.map((comment, index) => {
                    return (
                        <div key={ index }>
                            <div className={ 'log-day' }>
                                { moment.unix(comment.date).format(t('dateTimeFormat.YearMonthDateWithDay')) }
                            </div>
                            { comment.commentsData.map((contentComment, i) => {
                                return <ScrollingAnchor key={ i }
                                                        anchor={ `comment-record-id-${ contentComment.id }` }
                                >
                                    <div style={ { borderBottom: 'solid 1px #dfe3e9' } }>
                                        <div className={ 'p-3' }>
                                            <KirokuComment
                                                className={ 'font-text-card-detail' }
                                                comment={ contentComment }
                                            />
                                        </div>
                                    </div>
                                </ScrollingAnchor>;
                            }) }
                        </div>
                    );
                }) }
            </div>
        </div>
    </div>);
}

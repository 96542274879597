import { useTranslation }  from 'react-i18next';
import { useCallback }     from 'react';
import * as moment         from 'moment';
import { useCreatorLabel } from './useCreatorLabel';
import { useSurveyLabel }  from './useSurveyLabel';

export function useConditionFromParams(condition) {
    const { t }              = useTranslation();
    const { labelName }      = useCreatorLabel(condition.creator);
    const { templateName }   = useSurveyLabel(condition.templateId);

    const label = {
        userName: labelName,
        templateLabel : templateName
    };

    const makeLabelCondition = useCallback((key, params, label) => {
        switch (key) {
            case 'statusUser':
                return t(`common.${workingFormFactory[params]}`);
            case 'creator':
                return label.userName;
            case 'templateId':
                return label.templateLabel;
            case 'startDate':
                let startDate = params ? `${ moment.unix(params).format('LL') }` : '';
                return t(`Surveys.${ key }`) + `${ startDate }`;
            case 'endDate':
                let endDate = params ? `${ moment.unix(params).format('LL') }` : '';
                return t(`Surveys.${ key }`) + `${ endDate }`;
            case 'status':
                return t(`Surveys.${ statusRecords[params] }`);
            default:
                return t(`RecordList.${ key }`);
        }
    }, [t]);

    const conditionLabels = Object.keys(condition).map(key => {
        return {
            key  : key,
            value: condition[key],
            label: makeLabelCondition(key, condition[key], label),
        };
    });
    return { conditionLabels };
}

const workingFormFactory = {
    'full-time' : 'Full_time',
    'part-time' : 'Part_time'
};

const statusRecords = {
    'all'  : 'All',
    'draft': 'Draft',
};

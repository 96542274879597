import React, { useEffect, useMemo }                                   from 'react';
import { useTranslation }                                              from 'react-i18next';
import StatisticsBox                                                   from './StatisticsBox';
import { resources }                                                   from '@kiroku/api';
import { ComponentMobile, ComponentWeb, KirokuLoader, SearchNotFound } from '../../../ui';
import If                                                              from '../../If';

export default function StatisticsRecord() {

    const { t }                       = useTranslation();
    const { result, search, loading } = resources.useSearch('Dashboard');
    useEffect(() => {
        search().catch(error => {
            console.log(error);
        });
    }, [search]);

    const statistics = useMemo(() => {
        return [
            {
                type : 'yesterday',
                label: t(`Dashboard.todayRecordNumber`),
            },
            {
                type : 'lastWeek',
                label: t(`Dashboard.lastSevenDayRecordNumber`),
            },
            {
                type : 'lastMonth',
                label: t(`Dashboard.lastThirtyDayRecordNumber`),
            },
        ];
    }, [t]);

    return (
        <KirokuLoader loading={ loading }>
            <If statement={ result['comparedData'] } otherwise={ <SearchNotFound/> }>
                {
                    () => (
                        <>
                            <h5 className='pt-3 pb-3 pr-3 recent-title'>
                                { result['organization_name'] + t('Dashboard.pitOutRecord') }
                            </h5>
                            <ComponentWeb>
                                <div className='d-flex justify-content-between'>
                                    { statistics.map((item, index) => (
                                        <div className='card-dashboard mr-1' key={ index }>
                                            <StatisticsBox
                                                label={ item.label }
                                                icon={ 'fas fa-file-signature' }
                                                changeData={ result['comparedData'][item.type] }
                                            />
                                        </div>
                                    )) }
                                </div>
                            </ComponentWeb>
                            <ComponentMobile>
                                <div className='justify-content-between'>
                                    { statistics.map((item, index) => (
                                        <div className='bg-white mt-2' key={ index }>
                                            <StatisticsBox
                                                label={ item.label }
                                                icon={ 'fas fa-file-signature' }
                                                changeData={ result['comparedData'][item.type] }
                                            />
                                        </div>
                                    )) }
                                </div>
                            </ComponentMobile>
                        </>
                    )
                }
            </If>
        </KirokuLoader>
    );
}

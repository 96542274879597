import React, {Component} from "react";
import KirokuButton       from "./KirokuButton";
import {useTranslation}  from 'react-i18next';


class KirokuPaginationTop extends Component {

	increasePage () {
		this.props.increasePage();
	}

	decreasePage () {
		this.props.decreasePage()
	}

	render () {
		let {t} = useTranslation();
		return (
			<div hidden={this.props.hidden}>
				<div className='d-flex justify-content-between' style={{height: '36px'}}>
					<KirokuButton disabled={this.props.disabledPrev} onClick={this.decreasePage.bind(this)}
								  color={'white'}
								  style={{padding: '5px 14px', color: '#7f8fa4'}}>
						<i className="fas fa-caret-left"/>
					</KirokuButton>
					<div className='content-pagination'>
								<span
									className='mr-1'>{t('Surveys.Page', {currentPage: this.props['currentPage']})} 〳 {t('Surveys.TotalPage', {countPage: this.props['totalPage']})}</span>
					</div>
					<KirokuButton disabled={this.props.disabledNext} onClick={this.increasePage.bind(this)}
								  color={'white'}
								  style={{padding: '5px 14px', color: '#7f8fa4'}}>
						<i className="fas fa-caret-right"/>
					</KirokuButton>
				</div>
			</div>
		);
	}
}

export default KirokuPaginationTop;

import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import { resources }                  from '@kiroku/api';
import {
    ComponentMobile, ComponentWeb, KirokuSectionDropdownShowMore, KirokuSectionDropdownShowMoreMobile,
}                                     from '../../../ui';
import { getGroupedOptions }          from '../../Converter';

export default function DropdownTeamSearchCustomer({ handleChangeTags, placeholder, isCloseModal, show }) {
    const { t }                         = useTranslation();
    const {
              result, search,
              cancelAPISearch,
          }                             = resources.useSearch('Team');
    const [currentTags, setCurrentTags] = useState([]);

    useEffect(() => {
        if (show) {
            search({ type: 'all' });
            setCurrentTags([]);
        }
        return () => {
            cancelAPISearch();
        };
    }, [search, cancelAPISearch, show]);

    useEffect(() => {
        if (isCloseModal) {
            setCurrentTags([]);
        }
    }, [isCloseModal]);

    return (
        <div className={ 'pb-2' }>
            <ComponentWeb>
                <KirokuSectionDropdownShowMore
                    isSearchTags
                    tagType={ 'Team' }
                    onChange={ (options) => {
                        handleChangeTags(options);
                        setCurrentTags(options);
                    } }
                    value={ currentTags }
                    options={ getGroupedOptions('Team', result) }
                    handleSearchTags={ () => {
                    } }
                    placeholder={ placeholder || t('Team.placeholderSearch') }
                    sizeCustomize={5}
                />
            </ComponentWeb>
            <ComponentMobile>
                <KirokuSectionDropdownShowMoreMobile
                    isSearchTags
                    tagType={ 'Team' }
                    onChange={ (options) => {
                        handleChangeTags(options);
                        setCurrentTags(options);
                    } }
                    value={ currentTags }
                    options={ getGroupedOptions('Team', result) }
                    handleSearchTags={ () => {
                    } }
                    placeholder={ t('Team.placeholderSearch') }
                />
            </ComponentMobile>
        </div>
    );
}

import React              from 'react';
import { useTranslation } from 'react-i18next';

export default function ReviewStatusCondition({ onSearchReview, condition }) {
    const { t } = useTranslation();

    const setScrollingToView = (tab) => {
        let element = document.getElementById(tab);
        element.scrollIntoView({ block: 'nearest', inline: 'center' });
    };

    return (
        <div style={ { fontSize: 14, width: 'auto', overflowX: 'scroll' } } className={ 'd-flex  align-items-center' }>
            <div
                id={ 'all' }
                style={ { minWidth: 50 } }
                className={ `filter-plan-status-mb ${ (condition['reviewStatus'] === 'all') ? 'active-tab' : '' }` }
                onClick={ () => {
                    onSearchReview('all');
                    setScrollingToView('all');

                } }
            >
                <span className='items-condition'>{ t('common.All') }</span>
            </div>
            <div
                id={ 'unReview' }
                className={ `filter-plan-status-mb ${ (condition['reviewStatus'] === 'unReview') ? 'active-tab' : '' }` }
                style={ { minWidth: 70 } }
                onClick={ () => {
                    onSearchReview('unReview');
                    setScrollingToView('unReview');
                } }
            >
                <span className='items-condition'>
                    { t('plans.unReview') }
                </span>

            </div>
            <div
                id={ 'draftReview' }
                className={ `filter-plan-status-mb ${ (condition['reviewStatus'] === 'draftReview') ? 'active-tab' : '' }` }
                style={ { minWidth: 70 } }
                onClick={ () => {
                    if (condition.beforeStart.toString() === 'true' || condition.draft.toString() === 'true') {
                        return;
                    }
                    onSearchReview('draftReview');
                    setScrollingToView('draftReview');
                } }
            >
                    <span className={ `items-condition ${condition.beforeStart.toString() === 'true' || condition.draft.toString() === 'true' ? 'disabled-text' : ''}` }>
                        { t('plans.draftReview') }
                    </span>

            </div>
            <div
                id={ 'reviewed' }
                className={ `filter-plan-status-mb ${ (condition['reviewStatus'] === 'reviewed') ? 'active-tab' : '' }` }
                style={ { minWidth: 150 } }
                onClick={ () => {
                    if (condition.beforeStart.toString() === 'true' || condition.draft.toString() === 'true' || condition.running.toString() === 'true') {
                        return;
                    }
                    onSearchReview('reviewed');
                    setScrollingToView('reviewed');
                } }
            >
                <span className={ `items-condition ${condition.beforeStart.toString() === 'true' || condition.draft.toString() === 'true' || condition.running.toString() === 'true' ? 'disabled-text': ''}` }>
                    { t('plans.monitored') }
                </span>

            </div>
        </div>
    );
}

import React            from 'react';
import InputTextChart   from './InputTextChart/InputTextChart';
import DateChart        from './DateChart/DateChart';
import SingleChart      from './SingleChart/SingleChart';
import MultiChoiceChart from './MultiChoiceChart/MultiChoiceChart';
import NumberChart      from './NumberChart/NumberChart';
import OnlyDateChart    from './OnlyDateChart/OnlyDateChart';
import TimeChart        from './TimeChart/TimeChart';
import YesNoChart       from './YesNoChart/YesNoChart';
import MediaChart       from './MediaChart/MediaChart';

export function getQuestionsFromRecords(records) {
    records = records.map(record => {
        return record.survey_template.flowedQuestions || record.survey_template.questions;
    });
    return records.reduceRight((questions, questionsNext) => {
        return questions.concat(questionsNext);
    });
}

function MessageChart() {
    return <div>
        MessageChart
    </div>;
}

export const Charts = {
    InputText  : InputTextChart,
    Date       : DateChart,
    Media      : MediaChart,
    Message    : MessageChart,
    MultiChoice: MultiChoiceChart,
    Single     : SingleChart,
    YesNo      : YesNoChart,
    OnlyDate   : OnlyDateChart,
    Time       : TimeChart,
    Number     : NumberChart,
};

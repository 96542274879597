import React, { useEffect, useState }                                   from 'react';
import { resources }                                                    from '@kiroku/api';
import If                                                               from '../../If';
import { ComponentMobile, KirokuButton, KirokuLoader, SearchNotFound } from '../../../ui';
import { useTranslation }                                               from 'react-i18next';
import lodash                                                           from 'lodash';
import CustomerTab                                                      from './CutomerTab/CustomerTab';
import PlanTab                                                          from './PlaningTab/PlanTab';
import RecordTab                                                        from './RecordTab/RecordTab';
import QuestionTab                                                      from './QuestionTab/QuestionTab';
import usePageTitle                                                     from '../../../ui/v3/hooks/usePageTitle';
import { Link }                                                         from 'react-router-dom';
import moment                                                           from 'moment';

export function DetailTemplate(props) {

    const { t }                             = useTranslation();
    const [template, loadDetail, isLoading] = resources.useDetail('Template');

    const [templateIds, setTemplateIds] = useState(props.history.location.state || []);
    const [tabType, setTabType]         = useState('CUSTOMER_TAB');

    usePageTitle(t(`Layout.SurveyTemplates`));
    useEffect(() => {
        setTemplateIds(lodash.uniq(props.history.location.state) || []);
    }, [props.history.location.state]);

    useEffect(() => {
        loadDetail(props.match.params.id).catch(error => console.log(error));
    }, [props.match.params.id, loadDetail]);

    const indexCurrentTemplate = lodash.findIndex(templateIds, (id) => {
        return parseInt(id) === parseInt(props.match.params.id);
    });

    const pageGoBack = () => {
        if (localStorage.getItem('urlSearchSurveys')) {
            props.history.push(`/surveys${ localStorage.getItem('urlSearchSurveys') }`);
        } else {
            props.history.push(`/surveys`)
        }
    };

    const nextTemplate = (id) => {
        if (id) {
            props.history.push({
                pathname: `/surveys/${ id }`,
                state   : templateIds,
            });
        }
    };

    return (
        <KirokuLoader loading={ isLoading }>
            <If statement={ template }
                otherwise={ <div className="bg-white"><SearchNotFound message={ t('common.SurveyInvalid') }
                                                                      description={ ' ' }/></div> }
            >
                { () => {
                    return <div className={ 'padding-responsive' }>
                        <div className={ 'bg-main d-none d-xs-none d-sm-none d-md-block d-lg-block' }
                             style={ {
                                 top     : 70,
                                 position: 'sticky',
                                 zIndex  : 995,
                             } }
                        >
                            <div className={ 'pt-2' }/>
                            <div className='pt-1 d-flex justify-content-between'>
                                <div className='border-back-btn font-back-label cursor-pointer'
                                     style={ { textAlign: 'center' } }
                                     onClick={ () => {
                                         pageGoBack();
                                     } }>
                                    { t('common.goToTemplateList') }
                                </div>
                                <div className=' pr-3 ml-3'
                                     hidden={ indexCurrentTemplate === -1 }>
                                    <KirokuButton
                                        className={ 'color-btn-paginator border-btn-paginator bg-white btn-back' }
                                        style={ { height: 36, fontSize: 14 } }
                                        disabled={ indexCurrentTemplate === 0 }
                                        onClick={ () => {
                                            nextTemplate(templateIds[indexCurrentTemplate - 1]);
                                        } }
                                    > { t('DeletedList.back') } </KirokuButton>
                                    <KirokuButton
                                        className={ 'color-btn-paginator border-btn-paginator bg-white btn-next' }
                                        style={ { height: 36, fontSize: 14 } }
                                        onClick={ () => {
                                            nextTemplate(templateIds[indexCurrentTemplate + 1]);
                                        } }
                                        disabled={ indexCurrentTemplate === templateIds.length - 1 }
                                    > { t('DeletedList.next') } </KirokuButton>
                                </div>
                            </div>

                            <div className="pt-3" >
                                <div className={ `bg-white` }>
                                    <div className='p-3'>
                                        <div className="row">
                                            <div className=' col-xl-4 col-lg-4 col-sm-6 font-customer-name-detail pl-3'>
                                                <div style={ { fontSize: 16 } }>
                                                    { template.title }
                                                </div>
                                            </div>
                                            <div className='col-xl-4 col-lg-4 col-sm-6'>
                                                <div className="d-flex justify-content-start font-text-card-detail">
                                                    <div style={ { textAlign: 'end', padding: 0, minWidth: 100 } }>
                                                        { t('common.Creator') }: &nbsp;
                                                    </div>
                                                    <div style={ { padding: 0, minWidth: 167 } }>
                                                            <span className={ 'font-text-card-detail' }>
                                                                <Link to={ `/users/${ template.user_id }` }>
                                                                    { template.create_by }
                                                                </Link>
                                                            </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex justify-content-start font-text-card-detail pt-10">
                                                    <div
                                                        style={ { textAlign: 'end', padding: 0, minWidth: 100 } }>
                                                        { t('common.DateCreated') }: &nbsp;
                                                    </div>
                                                    <div style={ { padding: 0, minWidth: 120 } }>
                                                             <span className={ 'font-text-card-detail' }>
                                                                 {moment.unix(template.create_at).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                                             </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-xl-4 col-lg-4 col-sm-6'>
                                                <div className="d-flex justify-content-start font-text-card-detail">
                                                    <div style={ { textAlign: 'end', padding: 0, minWidth: 100 } }>
                                                        { t('common.updatedBy') } : &nbsp;
                                                    </div>
                                                    <div style={ { padding: 0, minWidth: 120 } }>
                                                        {template['updater_name'] ? <span className={ 'font-text-card-detail' }>
                                                                <Link to={ `/users/${ template['updater_id'] }` }>
                                                                    { template['updater_name'] }
                                                                </Link>
                                                            </span> : ' '}

                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex justify-content-start font-text-card-detail pt-10">
                                                    <div
                                                        style={ { textAlign: 'end', padding: 0, minWidth: 100 } }>
                                                        { t('common.UpdatedAt') }: &nbsp;
                                                    </div>
                                                    <div style={ { padding: 0, minWidth: 120 } }>
                                                             <span className={ 'font-text-card-detail' }>
                                                                 {moment.unix(template.update_at || template.create_at).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                                             </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={ 'bg-main d-none d-xs-none d-sm-none d-md-block d-lg-block' }
                             style={ {
                                 top     : 70,
                                 position: 'sticky',
                                 zIndex  : 991,
                             } }>
                            <div className={ `bg-white pl-3 pr-3 pb-3` }>
                                <div className="font-text-card-detail">
                                    { template.description || '' }
                                </div>
                            </div>
                        </div>
                        <div className={ 'about-survey-slick bg-main d-none d-xs-none d-sm-none d-md-block d-lg-block'  }
                             style={ {
                                 position: 'sticky',
                                 zIndex  : 995,
                             } }>
                            <div className={ `card` }>
                                <div
                                    className='pt-3 d-flex navbar-tab-records records-detail font-text-reset'
                                    style={ { borderBottom: 'solid 2px #dfe3e9', backgroundColor: '#ffffff', color: '#6E798D' } }>
                                    <div
                                        className={ `nav-bar pr-3 pl-3 cursor-pointer bg-white font-size-14 ${ tabType === 'CUSTOMER_TAB' ? 'active' : '' }` }

                                        onClick={ () => {
                                            setTabType('CUSTOMER_TAB');
                                        } }>
                                        { t('common.customer') }
                                    </div>
                                    <div
                                        className={ `nav-bar pr-3 pl-3 cursor-pointer bg-white font-size-14 ${ tabType === 'RECORD_TAB' ? 'active' : '' } ` }
                                        onClick={ () => {
                                            setTabType('RECORD_TAB');
                                        } }>
                                        { t('common.Records') }
                                    </div>
                                    <div
                                        className={ `nav-bar pr-3 pl-3 cursor-pointer bg-white font-size-14 ${ tabType === 'QUESTION_TAB' ? 'active' : '' } ` }
                                        onClick={ () => {
                                            setTabType('QUESTION_TAB');
                                        } }>
                                        { t('createRecord.Question') }
                                    </div>
                                    <div
                                        className={ `nav-bar pr-3 pl-3 cursor-pointer bg-white font-size-14 ${ tabType === 'PLAN_TAB' ? 'active' : '' } ` }
                                        onClick={ () => {
                                            setTabType('PLAN_TAB');
                                        } }>
                                        { t('plans.header') }
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*mobile*/ }
                        <div className={ 'bg-main d-block d-xs-block d-sm-block d-md-none d-lg-none' }
                             style={ {
                                 top     : 70,
                                 position: 'sticky',
                                 zIndex  : 994,
                             } }>
                            <div className={ 'pt-2' }/>

                            <div className='pt-1 d-flex justify-content-between'>
                                <div className='border-back-btn font-back-label cursor-pointer'
                                     style={ { textAlign: 'center' } }
                                     onClick={ () => {
                                         pageGoBack();
                                     } }>
                                    { t('common.goToTemplateList') }
                                </div>
                                <div className=' pr-3 ml-3'
                                     hidden={ indexCurrentTemplate === -1 }>
                                    <KirokuButton
                                        className={ 'color-btn-paginator border-btn-paginator bg-white btn-back' }
                                        style={ { height: 36, fontSize: 14 } }
                                        disabled={ indexCurrentTemplate === 0 }
                                        onClick={ () => {
                                            nextTemplate(templateIds[indexCurrentTemplate - 1]);
                                        } }
                                    > { t('DeletedList.back') } </KirokuButton>
                                    <KirokuButton
                                        className={ 'color-btn-paginator border-btn-paginator bg-white btn-next' }
                                        style={ { height: 36, fontSize: 14 } }
                                        onClick={ () => {
                                            nextTemplate(templateIds[indexCurrentTemplate + 1]);
                                        } }
                                        disabled={ indexCurrentTemplate === templateIds.length - 1 }
                                    > { t('DeletedList.next') } </KirokuButton>
                                </div>
                            </div>

                            <div className="pt-3">
                                <div className={ `bg-white` }>
                                    <div className={ 'font-customer-name-detail text-align-center pt-3 pb-15px' }
                                         style={ { fontSize: 18 } }
                                    >
                                        { template.title }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={ 'bg-main d-block d-xs-block d-sm-block d-md-none d-lg-none' }
                             style={ {
                                 top     : 70,
                                 position: 'sticky',
                                 zIndex  : 991,
                             } }>
                            <div className={ `bg-white pb-15px` }>
                                <div className={ 'text-align-center font-size-14 pr-3 pl-3' }>{ template.description }</div>
                            </div>
                        </div>

                        <div className={ 'bg-main d-block d-xs-block d-sm-block d-md-none d-lg-none' }
                             style={ {
                                 top     : 190,
                                 position: 'sticky',
                                 zIndex  : 995,
                             } }>
                            <div className={ `card` }>
                                <div
                                    className='d-flex navbar-tab-records records-detail font-text-reset'
                                    style={ { borderBottom: 'solid 2px #dfe3e9', backgroundColor: '#ffffff', color: '#6E798D' } }>
                                    <div
                                        className={ `nav-bar pr-3 pl-3 cursor-pointer bg-white font-size-14 ${ tabType === 'CUSTOMER_TAB' ? 'active' : '' }` }
                                        onClick={ () => {
                                            setTabType('CUSTOMER_TAB');
                                        } }>
                                        { t('common.customer') }
                                    </div>
                                    <div
                                        className={ `nav-bar pr-3 pl-3 cursor-pointer bg-white font-size-14 ${ tabType === 'RECORD_TAB' ? 'active' : '' } ` }
                                        onClick={ () => {
                                            setTabType('RECORD_TAB');
                                        } }>
                                        { t('common.Records') }
                                    </div>
                                    <div
                                        className={ `nav-bar pr-3 pl-3 cursor-pointer bg-white font-size-14 ${ tabType === 'QUESTION_TAB' ? 'active' : '' } ` }
                                        onClick={ () => {
                                            setTabType('QUESTION_TAB');
                                        } }>
                                        { t('createRecord.Question') }
                                    </div>
                                    <div
                                        className={ `nav-bar pr-3 pl-3 cursor-pointer bg-white font-size-14 ${ tabType === 'PLAN_TAB' ? 'active' : '' } ` }
                                        onClick={ () => {
                                            setTabType('PLAN_TAB');
                                        } }>
                                        { t('plans.header') }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div style={ {
                            zIndex: 993,
                            position: 'sticky',
                        }}
                             className={'bg-main'}
                        >
                            <PlanTab surveyId={ props.match.params.id }
                                     tabType={ tabType }/>

                            <CustomerTab
                                params={ props.match.params }
                                tabType={ tabType }
                                template={ template }
                            />
                            <RecordTab
                                params={ props.match.params }
                                tabType={ tabType }
                                template={ template }
                            />
                            <QuestionTab
                                tabType={ tabType }
                                template={ template }
                            />
                        </div>
                        <ComponentMobile>
                            <div style={ { marginBottom: 80 } }/>
                        </ComponentMobile>
                    </div>;
                } }
            </If>
        </KirokuLoader>

    );
}

import { useTranslation } from 'react-i18next';
import React              from 'react';
import { KirokuLabel }    from '../../ui';

export default function LogLabel({ log }) {
    const { t } = useTranslation();
    switch (log.action) {
        case 'login':
            return (
                <KirokuLabel color='dark' style={{
                    padding: '4px',
                    width  : 93,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {t('common.login')
                    }
                </KirokuLabel>);
        case 'create':
            return (<KirokuLabel color='primary'
                                 style={{
                                     padding: '4px',
                                     width  : 93,
                                     display: 'flex',
                                     justifyContent: 'center',
                                     alignItems: 'center'
                                 }}
            >
                {t('common.create')}
            </KirokuLabel>);
        case 'update':
            return (<KirokuLabel color='success'
                                 style={{
                                     padding: '4px',
                                     width  : 93,
                                     display: 'flex',
                                     justifyContent: 'center',
                                     alignItems: 'center'
                                 }}
            >
                {t('common.Update')}
            </KirokuLabel>);
        case 'delete':
            return (<KirokuLabel color='danger'
                                 style={{
                                     padding: '4px',
                                     width  : 93,
                                     display: 'flex',
                                     justifyContent: 'center',
                                     alignItems: 'center'
                                 }}
            >
                {t('common.Delete')}
            </KirokuLabel>);
        case 'view':
            return (<KirokuLabel color='light'
                                 style={{
                                     padding: '4px',
                                     width  : 93,
                                     display: 'flex',
                                     justifyContent: 'center',
                                     alignItems: 'center'
                                 }}
            >
                {t('common.view')}
            </KirokuLabel>);
        case 'comment':
            return (<KirokuLabel color='warning'
                                 style={{
                                     padding: '4px',
                                     width  : 93,
                                     display: 'flex',
                                     justifyContent: 'center',
                                     alignItems: 'center'
                                 }}
            >
                {t('common.comment')}
            </KirokuLabel>);
        default:
    }
}

import _ from 'lodash';

export const HANDLE_SELECT_TAB                              = 'HANDLE_SELECT_TAB';
export const GET_CUSTOMERS_LIST                             = 'GET_CUSTOMERS_LIST';
export const HANDLE_CHECK_ALL_CUSTOMERS                     = 'HANDLE_CHECK_ALL_CUSTOMERS';
export const HANDLE_CHECK_CUSTOMER                          = 'HANDLE_CHECK_CUSTOMER';
export const HANDLE_SEARCH_KEYWORDS                         = 'HANDLE_SEARCH_KEYWORDS';
export const HANDLE_CHANGE_TAG_TEAM                         = 'HANDLE_CHANGE_TAG_TEAM';
export const HANDLE_CHECK_ALL_SELECTED                      = 'HANDLE_CHECK_ALL_SELECTED';
export const HANDLE_CHECK_CUSTOMER_SELECTED                 = 'HANDLE_CHECK_CUSTOMER_SELECTED';
export const HANDLE_SELECT_CUSTOMER_IN_TEAM_SELECTED        = 'HANDLE_SELECT_CUSTOMER_IN_TEAM_SELECTED';
export const CHANGE_KEYWORD_SEARCH_CUSTOMERS_MODAL_SELECTED = 'CHANGE_KEYWORD_SEARCH_CUSTOMERS_MODAL_SELECTED';
export const HANDLE_CONFIRM_SEARCH_MODAL                    = 'HANDLE_CONFIRM_SEARCH_MODAL';
export const HANDLE_RESET_CONDITIONS                        = 'HANDLE_RESET_CONDITIONS';
export const HANDLE_SELECT_CUSTOMER_TAB                     = 'HANDLE_SELECT_CUSTOMER_TAB';
export const HANDLE_CLOSE_MODAL_SELECT_CUSTOMER             = 'HANDLE_CLOSE_MODAL_SELECT_CUSTOMER';
export const HANDLE_OPEN_MODAL_SELECT_CUSTOMER              = 'HANDLE_OPEN_MODAL_SELECT_CUSTOMER';
export const SHOW_MODAL_SELECT_CUSTOMER_MOBILE              = 'SHOW_MODAL_SELECT_CUSTOMER_MOBILE';
export const SET_INIT_TAB_CONDITIONS                        = 'SET_INIT_TAB_CONDITIONS';

function setSelectedCustomer(customers, isSelected) {
    return customers.map(item => ({ ...item, selected: isSelected }));
}

const searchCustomerByKeyword = (customerName, keyword) => {
    return customerName.toLowerCase().search(keyword.toLowerCase()) !== -1;
};

const customerInTeams = (teamSelect, customers) => {
    if (!teamSelect.length) {
        return customers.map(item => ({ ...item, selected: false }));
    }
    let listCustomersInTeams = [];
    teamSelect.forEach(team => {
        listCustomersInTeams = _.concat(listCustomersInTeams, team.customers);
    });

    return _.uniqBy([...listCustomersInTeams], 'id').map(item => ({ ...item, selected: false }));
};

const checkedCustomerSelectedOfCustomer = (customersSelected, customers) => {
    return customers.map(customer => {
        return {
            ...customer,
            selected: _.findIndex(customersSelected, ['id', customer.id]) !== -1,
        };
    });
};

export default function (state, action) {
    switch (action.type) {
        case 'UPDATE_CUSTOMER_DEFAULT' :
            return {
                ...state,
                customerDefault: action.customerDefault,
            };
        case GET_CUSTOMERS_LIST:
            return {
                ...state,
                customers            : action.customers,
                customersShowInModal : action.customers,
                customerTaggedToUser : setSelectedCustomer(action.customerDefault, true),
                customerAll          : checkedCustomerSelectedOfCustomer(action.customerDefault, action.customers),
                customersSelected    : action.customers.filter(customer => customer.selected),
                customersSelectedList: action.customers.filter(customer => customer.selected),
            };
        case HANDLE_SELECT_TAB:
            let ctmSelected = state.customers.filter(customer => customer.selected);
            if (action.selectTab === state.selectTab) {
                return state;
            }
            return {
                ...state,
                selectTab            : action.selectTab,
                teamsSelected        : [],
                customersShowInModal : checkedCustomerSelectedOfCustomer(state.customersSelectedList.filter(ctm => ctm.selected), state.customers),
                customers            : checkedCustomerSelectedOfCustomer(state.customersSelectedList.filter(ctm => ctm.selected), state.customers),
                customersSelected    : setSelectedCustomer(ctmSelected, true),
                customersSelectedList: setSelectedCustomer(ctmSelected, true),
                keywords             : '',
                keywordSelected      : '',
            };
        case HANDLE_CHECK_ALL_CUSTOMERS:
            let isCheckAll   = _.findIndex(state.customersShowInModal, (ctm) => (!ctm.selected)) === -1;
            let ctmCheckAll  = setSelectedCustomer(state.customersShowInModal, !isCheckAll);
            let customerList = [...state.customers];
            ctmCheckAll.forEach(ctm => {
                let indexCustomer           = _.findIndex(state.customers, ['id', ctm.id]);
                customerList[indexCustomer] = {
                    ...customerList[indexCustomer],
                    selected: ctm.selected,
                };
            });
            return {
                ...state,
                customersShowInModal: state.customersShowInModal.map(ctm => ({ ...ctm, selected: !isCheckAll })),
                customers           : customerList,
            };
        case HANDLE_CHECK_CUSTOMER:
            let customerShowing              = [...state.customersShowInModal];
            customerShowing[action.index]    = {
                ...customerShowing[action.index],
                selected: !customerShowing[action.index].selected,
            };
            let customers                    = [...state.customers];
            const indexCustomerChecking      = _.findIndex(customers, ctm => ctm.id === customerShowing[action.index].id);
            customers[indexCustomerChecking] = {
                ...customers[indexCustomerChecking],
                selected: !customers[indexCustomerChecking].selected,
            };
            return {
                ...state,
                customersShowInModal: customerShowing,
                customers           : customers,
            };

        case HANDLE_SEARCH_KEYWORDS:
            const keywordsSearch = action.keywords.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
            const newCustomers   = [...state.customers].filter(customer => {
                if (state.teamSelected.length) {
                    const customerOfTeam = customerInTeams(state.teamSelected, state.customers);
                    return (searchCustomerByKeyword(customer.name, keywordsSearch)
                        && _.findIndex(customerOfTeam, { id: customer.id }) !== -1);
                }
                return searchCustomerByKeyword(customer.name, keywordsSearch);
            });
            return {
                ...state,
                keywords            : action.keywords,
                customersShowInModal: newCustomers,
            };
        case HANDLE_CHANGE_TAG_TEAM:
            let makeCustomerInTeam = customerInTeams(action.teamSelected, state.customers);
            makeCustomerInTeam     = [...state.customers].filter(customer => {
                if (state.keywords) {
                    return searchCustomerByKeyword(customer.name, state.keywords)
                        && _.findIndex(makeCustomerInTeam, ctm => ctm.id === customer.id) !== -1;
                }
                return _.findIndex(makeCustomerInTeam, ctm => ctm.id === customer.id) !== -1;
            });
            return {
                ...state,
                teamSelected        : action.teamSelected,
                customersShowInModal: makeCustomerInTeam,
            };
        case HANDLE_CHECK_ALL_SELECTED:
            let isSelectAllCustomerSelected = _.findIndex(state.customersSelectedList, (ctm) => (!ctm.selected)) === -1;
            let ctmSelectedCheckAll         = setSelectedCustomer(state.customersSelected, !isSelectAllCustomerSelected);
            let libCustomersSelectedNew     = [...state.customersSelectedList];

            ctmSelectedCheckAll.forEach(ctm => {
                let indexCtmOfCustomers                      = _.findIndex(state.customersSelectedList, customer => ctm.id === customer.id);
                libCustomersSelectedNew[indexCtmOfCustomers] = {
                    ...libCustomersSelectedNew[indexCtmOfCustomers],
                    selected: ctm.selected,
                };
            });

            return {
                ...state,
                customersSelected    : ctmSelectedCheckAll,
                customersSelectedList: libCustomersSelectedNew,
                customersShowInModal : checkedCustomerSelectedOfCustomer(ctmSelectedCheckAll.filter(ctm => ctm.selected), state.customers),
                customers            : checkedCustomerSelectedOfCustomer(ctmSelectedCheckAll.filter(ctm => ctm.selected), state.customers),
            };
        case HANDLE_CHECK_CUSTOMER_SELECTED:
            let customersRawSelected = [...state.customersSelected];

            customersRawSelected[action.index] = {
                ...customersRawSelected[action.index],
                selected: !customersRawSelected[action.index].selected,
            };

            let libCustomersRawSelected                     = [...state.customersSelectedList];
            const indexCustomerCheckedOfLibCustomerSelected = _.findIndex(libCustomersRawSelected, ['id', customersRawSelected[action.index].id]);

            libCustomersRawSelected[indexCustomerCheckedOfLibCustomerSelected] = {
                ...libCustomersRawSelected[indexCustomerCheckedOfLibCustomerSelected],
                selected: !libCustomersRawSelected[indexCustomerCheckedOfLibCustomerSelected].selected,
            };

            return {
                ...state,
                customersSelected    : customersRawSelected,
                customersSelectedList: libCustomersRawSelected,
                customersShowInModal : checkedCustomerSelectedOfCustomer(libCustomersRawSelected.filter(ctm => ctm.selected), state.customers),
                customers            : checkedCustomerSelectedOfCustomer(libCustomersRawSelected.filter(ctm => ctm.selected), state.customers),
            };
        case HANDLE_SELECT_CUSTOMER_IN_TEAM_SELECTED :
            let customersIsTeamsRawSelected = customerInTeams(action.teamSelected, state.customersSelectedList).filter(customer => {
                return _.findIndex(state.customersSelectedList, customerSelected => customerSelected.id === customer.id) !== -1;
            });

            customersIsTeamsRawSelected = [...state.customersSelectedList].filter(customer => {
                    if (state.keyword) {
                        return searchCustomerByKeyword(customer.name, state.keyword)
                            && _.findIndex(customersIsTeamsRawSelected, { id: customer.id }) !== -1;
                    }
                    return _.findIndex(customersIsTeamsRawSelected, { id: customer.id }) !== -1;
                },
            );

            return {
                ...state,
                teamsSelected    : action.teamsSelected,
                customersSelected: customersIsTeamsRawSelected,
            };

        case CHANGE_KEYWORD_SEARCH_CUSTOMERS_MODAL_SELECTED:
            let keySearchSelected = action.keywordSelected.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');

            let customersNewSelected = [...state.customersSelectedList].filter(customer => {
                    if (state.teamSelected.length) {
                        let customersIsTeams = customerInTeams(state.teamSelected, state.customersSelectedList);
                        return (searchCustomerByKeyword(customer.name, keySearchSelected) && _.findIndex(customersIsTeams, { id: customer.id }) !== -1);
                    }
                    return searchCustomerByKeyword(customer.name, keySearchSelected);
                },
            );

            return {
                ...state,
                keywordSelected  : action.keywordSelected,
                customersSelected: customersNewSelected,
            };

        case HANDLE_CONFIRM_SEARCH_MODAL:
            return {
                ...state,
                customerSearched: action.customerSearched,
                customerTab     : 'SEARCH_CUSTOM_CUSTOMER',
                keywords        : '',
                teamSelected    : [],
                keywordSelected : '',
            };

        case HANDLE_RESET_CONDITIONS:
            if (state.selectTab === 'selectedTab') {
                return {
                    ...state,
                    customersSelected    : state.customerTaggedToUser,
                    customersSelectedList: state.customerTaggedToUser,
                    customersShowInModal : checkedCustomerSelectedOfCustomer(state.customerTaggedToUser, state.customerAll),
                    customers            : checkedCustomerSelectedOfCustomer(state.customerTaggedToUser, state.customerAll),
                    teamsSelected        : [],
                    keywordSelected      : '',
                };
            }
            return {
                ...state,
                customersShowInModal: checkedCustomerSelectedOfCustomer(state.customerTaggedToUser, state.customerAll),
                customers           : checkedCustomerSelectedOfCustomer(state.customerTaggedToUser, state.customerAll),
                teamsSelected       : [],
                keywords            : '',
            };
        case HANDLE_SELECT_CUSTOMER_TAB:
            let customerSelectedTab = state.customers.filter(customer => customer.selected);
            if (action.customerTab === state.customerTab) {
                return state;
            }
            return {
                ...state,
                customerTab          : action.customerTab,
                teamSelected         : [],
                customers            : checkedCustomerSelectedOfCustomer(state.customersSelectedList.filter(ctm => ctm.selected), state.customers),
                customersShowInModal : checkedCustomerSelectedOfCustomer(state.customersSelectedList.filter(ctm => ctm.selected), state.customers),
                customersSelected    : setSelectedCustomer(customerSelectedTab, true),
                customersSelectedList: setSelectedCustomer(customerSelectedTab, true),
                historyState         : {
                    ...state.historyState,
                    customerTab: action.customerTab,
                },
            };

        case HANDLE_CLOSE_MODAL_SELECT_CUSTOMER:
            return {
                ...state,
                customerTab    : state.customerTab,
                keywords       : '',
                teamSelected   : [],
                keywordSelected: '',
            };

        case HANDLE_OPEN_MODAL_SELECT_CUSTOMER :
            return {
                ...state,
                isOpenModal : !state.isOpenModal,
                historyState: {
                    ...state.historyState,
                    isOpenModal: !state.isOpenModal,
                },
            };
        case SHOW_MODAL_SELECT_CUSTOMER_MOBILE:
            return {
                ...state,
                showModalSelectCustomer: !state.showModalSelectCustomer,
            };
        case SET_INIT_TAB_CONDITIONS:
            const customerChecked      = !!action.customerSearched.length;
            const customerTaggedToUser = !!state.customerTaggedToUser.length;

            const tabActive = (customerChecked || customerTaggedToUser);
            return {
                ...state,
                selectTab: tabActive ? 'selectedTab' : 'optionsTab',
            };
        default:
            return state;
    }
}

import React, {useEffect, useState} from 'react';
import {
    ComponentMobile, ComponentWeb, KirokuAge, KirokuButton, KirokuCard, KirokuCardList, KirokuCardWeb, KirokuCheckBox,
    KirokuInputSearcher, KirokuLoader, KirokuNotFound, KirokuPagination, KirokuPopoverItem, KirokuTable, TBody, THeader,
} from '../../../../src/ui';
import {authentication, resources} from '@kiroku/api';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import {LinkPermission, TdPermission, ThPermission, withPermissions, WrapperPermissions} from '../../HOC';
import usePageTitle from '../../../ui/v3/hooks/usePageTitle';
import DropdownSelectTeamCondition from './DropdownSelectTeamCondition';
import DropdownSelectStaffCondition from './DropDownSelectStaffCondition';
import ModalConfirmDeactivate from './ModalCofirmDeactive';
import lodash from 'lodash';
import If from '../../If';
import BulkActionCustomer, {ActionToggle} from './BulkActionCustomer';
import DropDownFilter from './DropDownFilter';
import queryString from 'query-string';
import useConditionTagParams from './useConditionFromParams';
import {HeaderActionWithPermission} from './HeaderAction';
import ModalSelectCustomers from './ModalSelectCustomers';
import SearchNotFound from '../../../ui/SearchNotFound';
import useNotification from '../../Layout/useNotification';
import {getKeyWordSearch} from '../../ServiceSearch';
import SearchByHiragana from './SearchByHiragana';

const LinkTo = withPermissions(LinkPermission);
const Th = withPermissions(ThPermission);
const Td = withPermissions(TdPermission);
const BulkActionCustomerWithPermission = withPermissions(BulkActionCustomer);
const DropDownFilterStatusWithPermission = withPermissions(DropDownFilter);
const ButtonWithPermission = withPermissions(KirokuButton);

function PagNotFound() {
    const {t} = useTranslation();
    return <SearchNotFound
        description={t('NullPage.customerForTag2')}
    />;
}

export default function CustomerList() {
    const {
        result, search,
        prevPage, nextPage,
        condition, pagination,
        loading, cancelAPISearch,
    } = resources.useSearch('Customer', {type: 'condition', page: 1, status: 'active', order_by: 'ASC'});

    const {t} = useTranslation();
    const history = useHistory();
    const {conditionFormParams} = useConditionTagParams();
    const [customers, setCustomerList] = useState([]);
    const [keywords, setKeywordSearch] = useState(conditionFormParams.keywords);
    const [userTags, setUserTags] = useState([]);
    const [teamTags, setTeamTags] = useState([]);
    const [isShowModal, setShowModal] = useState('');
    const [customerListDel, setCustomerListDel] = useState([]);

    usePageTitle(t(`Layout.Customers`));
    const {notification} = useNotification();
    const {isAdmin, isLeader} = authentication.usePermission();

    const setValueTagWithQueryUrl = (conditionUrl) => {
        setKeywordSearch(conditionUrl.name || '')
        if (conditionUrl.users && typeof conditionUrl.users === 'string') {
            setUserTags([{id: conditionUrl.users}]);
        } else {
            setUserTags(conditionUrl.users ? conditionUrl.users.map(id => {
                return {id};
            }) : []);
        }

        if (conditionUrl.teams && typeof conditionUrl.teams === 'string') {
            setTeamTags([{id: conditionUrl.teams}]);
        } else {
            setTeamTags(conditionUrl.teams ? conditionUrl.teams.map(id => {
                return {id};
            }) : []);
        }
    };

    useEffect(() => {
        try {
            const conditionUrl = queryString.parse(history.location.search.replace('?', ''), {arrayFormat: 'comma'});
            if (conditionUrl.name) {
                conditionUrl.name = getKeyWordSearch(history.location.search.replace('?', ''), 'name')
            }
            if (conditionUrl.type) {
                setValueTagWithQueryUrl(conditionUrl);
                search(conditionUrl);
            } else {
                search({type: 'condition', page: 1, status: 'active', order_by: 'ASC'});
            }
        } catch (e) {
            search({type: 'condition', page: 1, status: 'active', order_by: 'ASC'});
        }
        return () => {
            cancelAPISearch();
        };
    }, [search, history, cancelAPISearch]);

    useEffect(() => {
        const urlQuery = queryString.stringify({...condition}, {arrayFormat: 'comma'});
        history.replace('/customers?' + urlQuery);
    }, [condition, history]);

    useEffect(() => {
        if (result) {
            setCustomerList(result);
        }
    }, [result]);

    useEffect(() => {
        try {
            const conditionQuery = history.location.search.replace('?', '');
            const urlQuery = queryString.stringify({...condition}, {arrayFormat: 'comma'});
            if (conditionQuery !== urlQuery) {
                const conditionUrl = queryString.parse(history.location.search.replace('?', ''), {arrayFormat: 'comma'});
                if (conditionUrl.name) {
                    conditionUrl.name = getKeyWordSearch(history.location.search.replace('?', ''), 'name')
                }
                setValueTagWithQueryUrl(conditionUrl);
                if (conditionUrl.type) {
                    search(conditionUrl);
                } else {
                    search(condition);
                }
            }
        } catch (e) {
            search(condition);
        }
    }, [history.location.search, condition, history, search]);

    const handleSearchTags = () => {
        let taggedTeam = teamTags.map(team => team.id);
        let taggedUser = userTags.map(user => user.id);

        const newCondition = {
            ...condition,
            teams: taggedTeam,
            users: taggedUser,
            name: keywords,
        };

        if (!taggedUser.length) {
            delete newCondition['users'];
        }

        if (!taggedTeam.length) {
            delete newCondition['teams'];
        }

        if (!newCondition.name) {
            delete newCondition.name;
        }

        search({
            ...newCondition,
            page: 1,
        });

    };


    const handleCheckAll = () => {
        let newCustomersSelect = customers.map(customer => ({
            ...customer,
            isChecked: lodash.findIndex(customers, (ctm) => !ctm.isChecked) !== -1,
        }));

        setCustomerList(newCustomersSelect);
        setCustomerListDel(newCustomersSelect.filter(item => item.isChecked));
    };

    const handleCheckCustomer = (customerId) => {
        let newCustomersSelect = customers.map((customer) => {
            if (customer.id === customerId) {
                return {
                    ...customer,
                    isChecked: !customer.isChecked,
                };
            }
            return customer;
        });

        setCustomerList(newCustomersSelect);
        setCustomerListDel(newCustomersSelect.filter(item => item.isChecked));
    };

    const isSelect = ![...customers].filter(item => item.isChecked).length;

    return (
        <div className={'padding-responsive'}>
            <ComponentWeb>
                <div className={'d-flex justify-content-between align-items-baseline'}>
                    <If statement={customers.length}>
                        {() => <div className={`pb-3 ${condition.status === 'deactive' ? 'd-none' : ''}`}
                                    style={{width: 148}}
                        >
                            <BulkActionCustomerWithPermission
                                resource-permission={'|customerList|button-bulkAction|'}
                                isSelect={isSelect}
                                onSelect={(event) => setShowModal(event.kirokuSelected.value)}
                            />
                        </div>}
                    </If>
                    <div/>
                    <If statement={customers.length}>
                        {() => <KirokuPagination
                            disabledPrev={pagination.currentPage === 1}
                            disabledNext={pagination.currentPage === pagination.totalPage}
                            increasePage={nextPage}
                            decreasePage={prevPage}
                            currentPage={pagination.currentPage}
                            totalPage={pagination.totalPage}
                        />}
                    </If>
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <div className={'d-flex justify-content-between align-items-baseline pb-2'}>
                    <div style={{width: 150}}>
                        <DropDownFilterStatusWithPermission
                            resource-permission={'|customerList|button-filterDeActive|'}
                            status={condition.status}
                            onSelect={(event) => {
                                let taggedTeam = teamTags.map(team => team.id);
                                let taggedUser = userTags.map(user => user.id);

                                const conditionSearch = {
                                    ...condition,
                                    teams: taggedTeam,
                                    users: taggedUser,
                                    name: keywords,
                                };

                                if (!taggedUser.length) {
                                    delete conditionSearch['users'];
                                }

                                if (!taggedTeam.length) {
                                    delete conditionSearch['teams'];
                                }

                                if (!conditionSearch.name) {
                                    delete conditionSearch.name;
                                }
                                search({
                                    ...conditionSearch,
                                    status: event.kirokuSelected.value,
                                    page: 1,
                                });
                            }}
                        />
                    </div>

                    <Link to={'/customers/create'}>
                        <ButtonWithPermission
                            resource-permission={'|customerList|button-new|'}
                            color={'primary'}>{t('Customer.AddCustomer')}
                        </ButtonWithPermission>
                    </Link>
                </div>
            </ComponentMobile>

            <DropdownSelectTeamCondition
                teamTags={condition.teams}
                handleChangeTeamTags={(value) => setTeamTags(value)}
                handleSearchTags={handleSearchTags}/>
            <DropdownSelectStaffCondition
                userTags={condition.users}
                handleChangeUserTags={(value) => setUserTags(value)}
                handleSearchTags={handleSearchTags}
            />

            <ComponentWeb>
                <KirokuCardWeb>
                    <div className='d-flex justify-content-between align-items-baseline p-3'>
                        <div>
                            <WrapperPermissions
                                style={{width: 150, minWidth: 150}}
                                resource-permission={'|customerList|button-filterDeActive|'}
                            >
                                <DropDownFilterStatusWithPermission
                                    resource-permission={'|customerList|button-filterDeActive|'}
                                    status={condition.status}
                                    onSelect={(event) => {
                                        let taggedTeam = teamTags.map(team => team.id);
                                        let taggedUser = userTags.map(user => user.id);

                                        const conditionSearch = {
                                            ...condition,
                                            teams: taggedTeam,
                                            users: taggedUser,
                                            name: keywords,
                                        };

                                        if (!taggedUser.length) {
                                            delete conditionSearch['users'];
                                        }

                                        if (!taggedTeam.length) {
                                            delete conditionSearch['teams'];
                                        }

                                        if (!conditionSearch.name) {
                                            delete conditionSearch.name;
                                        }

                                        search({
                                            ...conditionSearch,
                                            status: event.kirokuSelected.value,
                                            page: 1,
                                        });
                                    }}
                                />
                            </WrapperPermissions>
                        </div>
                        <div className={'d-flex align-items-flex-start pl-3'}>
                            <SearchByHiragana
                                handleSearch={(keySearch) => {
                                    let conditionSearch = {
                                        ...condition,
                                        furigana: keySearch,
                                        page: 1
                                    }
                                    if (!keySearch) {
                                        delete conditionSearch.furigana;
                                    }
                                    search(conditionSearch);
                                }}
                                furiganaSearch={condition.furigana || ''}
                            />
                            <div className="pl-3"/>
                            <KirokuInputSearcher
                                value={keywords}
                                onSubmit={() => {
                                    let taggedTeam = teamTags.map(team => team.id);
                                    let taggedUser = userTags.map(user => user.id);

                                    const conditionSearchAtInput = {
                                        ...condition,
                                        teams: taggedTeam,
                                        users: taggedUser,
                                        name: keywords,
                                        page: 1,
                                    };

                                    if (!taggedUser.length) {
                                        delete conditionSearchAtInput['users'];
                                    }

                                    if (!taggedTeam.length) {
                                        delete conditionSearchAtInput['teams'];
                                    }

                                    if (!conditionSearchAtInput.furigana) {
                                        delete conditionSearchAtInput.furigana;
                                    }

                                    if (!conditionSearchAtInput.name) {
                                        delete conditionSearchAtInput.name;
                                    }

                                    search(conditionSearchAtInput);
                                }}
                                onChange={e => setKeywordSearch(e.target.value)}
                                id={'customerInputSearcherWeb'}
                            />
                        </div>
                    </div>
                    <div className='p-2'>
                        <KirokuLoader loading={loading}>
                            <KirokuNotFound isResults={customers.length}
                                            ComponentSearchNoFound={PagNotFound}
                            >
                                <KirokuTable>
                                    <THeader>
                                        <tr>
                                            <Th resource-permission={'|customerList|table-th|'}
                                                hidden={condition.status === 'deactive'}
                                                style={{width: 50}}>
                                                <KirokuCheckBox onChange={handleCheckAll}
                                                                checked={lodash.findIndex(customers, (ctm) => !ctm.isChecked) === -1}/>
                                            </Th>
                                            <th style={{minWidth: 400}}>{t('Customer.Name')}</th>
                                            <th style={{minWidth: 140}}>
                                                <div>
                                                    {t('Customer.Furigana')}
                                                    <span className={'pl-1 cursor-pointer'}>
                                                        <i hidden={condition.order_by === 'DESC'}
                                                           className="fas fa-lg fa-angle-up"
                                                           onClick={() => {
                                                               search({...condition, order_by: 'DESC'})
                                                           }}
                                                        />
                                                        <i hidden={condition.order_by === 'ASC'}
                                                           className="fas fa-lg fa-angle-down"
                                                           onClick={() => {
                                                               search({...condition, order_by: 'ASC'})
                                                           }}
                                                        />
                                                    </span>
                                                </div>
                                            </th>
                                            <th style={{minWidth: 70}}>{t('Customer.Age')}</th>
                                            <th style={{minWidth: 180}}>{t('CustomerEditor.KindOfCertificate')}</th>
                                            <th style={{minWidth: 70}}>{t('Customer.LastRecordedDate')}</th>
                                            <Th className='text-center'
                                                style={{minWidth: 180}}
                                                resource-permission={'|customerList|table-th|Action|'}
                                                hidden={condition.status === 'deactive'}
                                            >
                                                {t('common.Action')}
                                            </Th>
                                        </tr>
                                    </THeader>
                                    <TBody>
                                        {customers.map((customer, index) => {
                                            return <tr key={index}>
                                                <Td resource-permission={'|customerList|table-td|'}
                                                    style={{width: 50}}
                                                    hidden={condition.status === 'deactive'}
                                                >
                                                    <KirokuCheckBox onChange={() => handleCheckCustomer(customer.id)}
                                                                    checked={!!customer.isChecked}/>
                                                </Td>
                                                <td style={{wordBreak: 'keep-all', minWidth: 400}}>
                                                    <If statement={condition.status !== 'deactive'}
                                                        otherwise={customer.name}>
                                                        {() => <Link
                                                            to={{
                                                                pathname: `/customers/${customer.id}`,
                                                                state: {
                                                                    previousPage: 'customerList'
                                                                }
                                                            }}
                                                        >{customer.name} &nbsp;
                                                        </Link>}
                                                    </If>
                                                </td>
                                                <td style={{minWidth: 140}}>{customer.furigana}</td>
                                                <td style={{minWidth: 70}}><KirokuAge
                                                    dob={customer['date_of_birth']}/>
                                                </td>
                                                <td style={{minWidth: 180}}>{customer.certificate.map(item => t(`Customer.certificate.${item}`)).join(', ')}</td>
                                                <td style={{whiteSpace: 'nowrap', minWidth: 70}}>
                                                    {customer['last_record_at'] ? moment.unix(customer['last_record_at']).format(t('dateTimeFormat.YearMonthDateWithHour')) : ''}</td>
                                                <Td resource-permission={'|customerList|table-td|Action|'}
                                                    hidden={condition.status === 'deactive'}
                                                    style={{minWidth: 180}}
                                                >
                                                    <div className='d-flex justify-content-between p-1 fix-icon-align'>
                                                        <LinkTo
                                                            color={'text-dark'}
                                                            resource-permission={'|customerList|button-edit|'}
                                                            href={`/customers/edit/${customer.id}`}
                                                            text={t('common.Edit')}
                                                            icon={<i className="fas fa-pencil-alt text-secondary"/>}
                                                        />
                                                        <LinkTo
                                                            color={'text-dark'}
                                                            resource-permission={'|customerList|button-duplicate|'}
                                                            href={`/customers/duplicate/${customer.id}`}
                                                            icon={<i className="fas fa-clone text-secondary"/>}
                                                            text={t('common.Duplicate')}
                                                        />
                                                    </div>
                                                </Td>
                                            </tr>;
                                        })}
                                    </TBody>
                                </KirokuTable>
                            </KirokuNotFound>
                        </KirokuLoader>
                    </div>
                </KirokuCardWeb>
                <div className={'d-flex justify-content-between align-items-baseline pt-3'}>
                    <If statement={customers.length}>
                        {() => <div className={`${condition.status === 'deactive' ? 'd-none' : ''}`}
                                    style={{width: 148}}
                        >
                            <BulkActionCustomerWithPermission
                                resource-permission={'|customerList|button-bulkAction|'}
                                location={'top'}
                                isSelect={isSelect}
                                onSelect={(event) => setShowModal(event.kirokuSelected.value)}
                            />
                        </div>}
                    </If>
                    <div/>
                    <If statement={customers.length}>
                        {() => <KirokuPagination
                            className={'pb-3'}
                            disabledPrev={pagination.currentPage === 1}
                            disabledNext={pagination.currentPage === pagination.totalPage}
                            increasePage={nextPage}
                            decreasePage={prevPage}
                            currentPage={pagination.currentPage}
                            totalPage={pagination.totalPage}
                        />}
                    </If>
                </div>
            </ComponentWeb>

            <ComponentMobile>
                <KirokuInputSearcher
                    value={keywords}
                    onSubmit={() => {
                        let taggedTeam = teamTags.map(team => team.id);
                        let taggedUser = userTags.map(user => user.id);

                        const conditionSearchAtInput = {
                            ...condition,
                            teams: taggedTeam,
                            users: taggedUser,
                            name: keywords,
                            page: 1,
                        };

                        if (!taggedUser.length) {
                            delete conditionSearchAtInput['users'];
                        }

                        if (!taggedTeam.length) {
                            delete conditionSearchAtInput['teams'];
                        }

                        if (!conditionSearchAtInput.name) {
                            delete conditionSearchAtInput.name;
                        }
                        search(conditionSearchAtInput);
                    }}
                    onChange={e => setKeywordSearch(e.target.value)}
                    id={'customerInputSearcherMobile'}
                />
                <div className="pt-3">
                    <SearchByHiragana
                        handleSearch={(keySearch) => {
                            let conditionSearch = {
                                ...condition,
                                furigana: keySearch,
                                page: 1
                            }
                            if (!keySearch) {
                                delete conditionSearch.furigana;
                            }
                            search(conditionSearch);
                        }}
                        furiganaSearch={condition.furigana || ''}
                    />
                </div>
                <div className={'d-flex justify-content-between align-items-baseline pt-3'}>
                    <div style={{width: 150}}>
                        <If statement={customers.length}>
                            {() => <div className={`${condition.status === 'deactive' ? 'd-none' : ''}`}
                                        style={{width: 148}}
                            >
                                <BulkActionCustomerWithPermission
                                    resource-permission={'|customerList|button-bulkAction|'}
                                    onSelect={(event) => {
                                        setShowModal(event.kirokuSelected.value === 'deactive' ? 'modalSelectCustomer' : '');
                                    }}
                                />
                            </div>}
                        </If>
                    </div>
                    <div/>
                    <If statement={customers.length}>
                        {() => <KirokuPagination
                            className={'pb-3'}
                            isMobile
                            disabledPrev={pagination.currentPage === 1}
                            disabledNext={pagination.currentPage === pagination.totalPage}
                            increasePage={nextPage}
                            decreasePage={prevPage}
                            currentPage={pagination.currentPage}
                            totalPage={pagination.totalPage}
                        />}
                    </If>
                </div>
                <div className='pt-2' id='card-customer'>
                    <KirokuCardList
                        headerLeft={<span>{t('common.Name')}</span>}
                        headerRight={
                            <If statement={condition.status === 'active'}>
                                {() => <HeaderActionWithPermission
                                    resource-permission={'|customerList|button-bulkAction|'}
                                />}
                            </If>
                        }
                    >
                        <KirokuLoader loading={loading}>
                            <KirokuNotFound isResults={customers.length}>
                                <div>
                                    {customers.map((customer, index) => {
                                        let translateCertificate = customer.certificate.map(item => {
                                            return t(`Customer.certificate.${item}`);
                                        });
                                        let certificate = translateCertificate.join(', ');
                                        return <div key={index}>
                                            <KirokuCard
                                                action={<If statement={condition.status === 'active'}>
                                                    {() => (isAdmin || isLeader) ?
                                                        <ActionToggle>
                                                            <KirokuPopoverItem>
                                                                <div
                                                                    className='d-flex justify-content-between p-1 fix-icon-align'>
                                                                    <Link to={`/customers/edit/${customer.id}`}>
                                                                        <i className="fas fa-pencil-alt"/><span
                                                                        className='ml-1'>{t('common.Edit')}</span>
                                                                    </Link>
                                                                    <Link
                                                                        to={`/customers/duplicate/${customer.id}`}>
                                                                        <i className="fas fa-clone"/><span
                                                                        className='ml-1'>{t('common.Duplicate')}</span>
                                                                    </Link>
                                                                </div>
                                                            </KirokuPopoverItem>
                                                        </ActionToggle> : ''
                                                    }
                                                </If>}
                                            >
                                                <div style={{wordBreak: 'keep-all'}}>
                                                    <If statement={condition.status === 'active'}
                                                        otherwise={customer.name}>
                                                        {() => <div>
                                                            <Link
                                                                to={{
                                                                    pathname: `/customers/${customer.id}`,
                                                                    state: {
                                                                        previousPage: 'customerList'
                                                                    }
                                                                }}>{customer.name} &nbsp;
                                                            </Link>
                                                            <div>
                                                                {customer?.furigana}
                                                            </div>
                                                        </div>}
                                                    </If>
                                                    <div>{certificate}</div>
                                                </div>
                                            </KirokuCard>
                                            <hr className={'margin-none'}
                                                hidden={(result.length - 1 === index) || (condition.status === 'active')}/>
                                        </div>;
                                    })}
                                </div>
                            </KirokuNotFound>
                        </KirokuLoader>
                    </KirokuCardList>
                </div>
                <div className={'d-flex justify-content-between align-items-baseline pt-3 pb-5'}>
                    <If statement={customers.length}>
                        {() => <div className={`${condition.status === 'deactive' ? 'd-none' : ''}`}
                                    style={{width: 148}}
                        >
                            <BulkActionCustomerWithPermission
                                resource-permission={'|customerList|button-bulkAction|'}
                                location={'top'}
                                onSelect={(event) => {
                                    setShowModal(event.kirokuSelected.value === 'deactive' ? 'modalSelectCustomer' : '');
                                }}
                            />
                        </div>}
                    </If>
                    <div/>
                    <If statement={customers.length}>
                        {() => <KirokuPagination
                            isMobile
                            className={'pb-3'}
                            disabledPrev={pagination.currentPage === 1}
                            disabledNext={pagination.currentPage === pagination.totalPage}
                            increasePage={nextPage}
                            decreasePage={prevPage}
                            currentPage={pagination.currentPage}
                            totalPage={pagination.totalPage}
                        />}
                    </If>
                </div>
            </ComponentMobile>

            <ModalSelectCustomers
                isShowModal={isShowModal === 'modalSelectCustomer'}
                cancel={() => {
                    setShowModal('');
                }}

                confirm={(customersDel) => {
                    setCustomerListDel(customersDel);
                    setShowModal('deactive');
                }}
            />
            <ModalConfirmDeactivate
                isShowModal={isShowModal === 'deactive'}
                customers={customerListDel}
                handleAfterDelete={() => {
                    notification(t('message.DeleteSuccess'), 'success');
                    search({
                        ...condition,
                        page: 1,
                    });
                }}
                handleAction={() => {
                    setShowModal('');
                }}
            />
        </div>
    );
}

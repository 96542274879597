import React                   from 'react';
import {
    KirokuCardWeb, KirokuInput,
    KirokuCardBodyWeb,
}                              from '../../../../ui';
import { useTranslation }      from 'react-i18next';
import { useHeadingQuestion }  from '../../useSurvey';
import { useAsQuestionType }   from '../../../Recording';
import { ButtonNext, getNext } from './getNextQuestion';
import { QuestionSkipped }     from './QuestionSkipped';
import QuestionCardTitle       from './QuestionCardTitle';
const width = window.innerWidth;

export function setDefaultAnswerNumber(question) {
    if (question[ 'defaultAnswer' ]) {
        return {
            ...question,
            answers: question[ 'defaultAnswer' ],
        };
    }
    return {
        ...question,
        answers: '',
    };
}

export default function NumberQuestion({ question }) {

    const { t }                   = useTranslation();
    const { doAnswer, answering } = useAsQuestionType(question);
    const { content }             = useHeadingQuestion(question);

    return (<div id='component-question'>
        <div id='web-q' className='pb-3 '>
            <KirokuCardWeb>
                <div className={ (width <= 768) && !!question.answers ? 'border-has-answer': ' '}>
                <QuestionCardTitle
                    isDisabled={question.isDisabled}
                    question={question}
                    handleResetAnswer={() => doAnswer('', question.index + 1)}
                    hasAnswer={!!question.answers}
                />
                <KirokuCardBodyWeb>
                    <QuestionSkipped
                        isDisabled={question.isDisabled}
                    >
                        <span className='content-name-question'>{content}</span>
                        <div className='answer-question d-flex align-items-center'>
                            <KirokuInput disabled={question.isDisabled}
                                         name={`${question.index}-number`}
                                         value={question.answers}
                                         inputMode='decimal'
                                         onChange={event => {
                                             answering();
                                             let value = event.target.value.replace(/(?!^-)[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                             doAnswer(value, getNext({ ...question, isAnswers: !!value }));
                                         }}
                                         style={{ maxHeight: 90, color: '#495057', maxWidth: 230 }}
                                         placeholder={t('common.placeholderNumberQuestion')}
                            />
                            <div style={{ float: 'right', paddingLeft: 8 }}
                            >
                                <ButtonNext
                                    question={question}
                                    onClick={() => {
                                        if(question.answers) {
                                            doAnswer(question.answers, getNext(question))
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </QuestionSkipped>
                </KirokuCardBodyWeb>
                </div>
            </KirokuCardWeb>
        </div>
    </div>);
}

import React, { useEffect, useRef, useState }                      from 'react';
import { useTranslation }                                          from 'react-i18next';
import {
    ComponentMobile, ComponentWeb, KirokuButton, KirokuCardWeb, KirokuDropdownClose, KirokuInput, KirokuLoader,
    KirokuMultiSelect,
}                                                                  from '../../../ui';
import useDropDownData                                             from '../../useDropDownData';
import { useHistory }                                              from 'react-router-dom';
import '../staff.css';
import { conditionUpload }                                         from '../UserEditor/validateChecker';
import { resources }                                               from '@kiroku/api';
import useNotification                                             from '../../Layout/useNotification';
import { useMixpanelTracking }                                     from '../../../Mixpanel/useMixpanelTracking';
import moment                                                      from 'moment';
import ModalNotificationValidateUploadAvatar, { ComponentLoading } from '../ModalNotificationValidateUploadAvatar';

export function InputPassword(props) {
    const [isPassword, setIsPassword] = useState(true);

    return <React.Fragment>
        <KirokuInput {...props} type={isPassword ? 'password' : 'text'}/>
        <span className='change-status-password-user'>
            <i onClick={() => setIsPassword(!isPassword)}
               className={isPassword ? 'fas fa-eye text-secondary' : 'fas fa-eye-slash text-secondary'}/>
        </span>
    </React.Fragment>;
}


export default function UserCreator() {
    const history                                             = useHistory();
    const { t }                                               = useTranslation();
    const { kindOfQualification, workingForm }                = useDropDownData();
    const [resultUploadAvatar, uploadAvatar, isLoadingAvatar] = resources.useUpload('User');
    const [userAvatar, setUserAvatar]                         = useState(resultUploadAvatar || '');
    const inputElement                                        = useRef(null);
    const [resultCreated, createUser, isLoadingUser]          = resources.useCreate('User');
    const { notification }                                    = useNotification();

    const { trackingMixpanel } = useMixpanelTracking();

    const [profile, setUserProfile] = useState({
        name           : '',
        username       : '',
        title          : '',
        qualification  : [],
        password       : '',
        confirmPassword: '',
        working_form   : '',
    });
    const [alertUpload, setAlertUpload] = useState(false);

    const [errorsProfile, setErrorsProfile] = useState({
        name           : false,
        password       : false,
        confirmPassword: false,
        username       : false,
        userLong       : false,
        userIsExit     : false,
    });


    const handleChangeQualification = (options) => {
        setUserProfile({
            ...profile,
            qualification: options.map(itm => itm.key),
        });
    };

    const handleChangeImage = () => {
        if (inputElement.current.files[ 0 ] && conditionUpload(inputElement.current.files[ 0 ])) {
            let image = new FormData();
            image.append('file', inputElement.current.files[ 0 ]);
            image.append('name', inputElement.current.files[ 0 ].name);
            uploadAvatar('public', image).then((res) => {
                setUserAvatar(res.url);
            });
        } else if(inputElement.current.files.length){
            setAlertUpload(!alertUpload);
        }
    };

    const handleOnChangeProfile = (event) => {
        setUserProfile({
            ...profile,
            [ event.target.name ]: event.target.value,
        });

        setErrorsProfile({
            ...errorsProfile,
            [ event.target.name ]: !event.target.value,
        });
    };


    const handleOnChangeUserName = (event) => {
        setUserProfile({
            ...profile,
            [ event.target.name ]: event.target.value,
        });

        setErrorsProfile({
            ...errorsProfile,
            [ event.target.name ]: !event.target.value,
            userLong             : event.target.value.length && event.target.value.length < 6,
            userIsExit           : false,
        });
    };

    const handleOnChangePassword        = (event) => {
        setUserProfile({
            ...profile,
            [ event.target.name ]: event.target.value,
        });
        setErrorsProfile({
            ...errorsProfile,
            [ event.target.name ]: !event.target.value,
            confirmPassword      : event.target.value !== profile.confirmPassword,
        });

    };
    const handleOnChangeConfirmPassword = (event) => {
        setUserProfile({
            ...profile,
            [ event.target.name ]: event.target.value,
        });
        setErrorsProfile({
            ...errorsProfile,
            confirmPassword: event.target.value !== profile.password,
        });
    };

    const handleSubmitApplicationUser = () => {
        const userApplicationRequest = {
            name                    : profile.name,
            username                : profile.username,
            password                : profile.password,
            confirm_password        : profile.confirmPassword,
            title                   : profile.title,
            working_form            : profile.working_form,
            qualification           : profile.qualification,
            role                    : 'staff',
            avatar                  : userAvatar,
            teams                   : [],
            customers               : [],
            last_updated_password_at: moment().unix(),
        };

        createUser(userApplicationRequest)
            .then((res) => {
                if (res.code === 'KIROKU_CREATED') {
                    notification(t('message.UpdateSuccess'), 'success');
                    history.push('/users/'.concat(res.id));
                }
            })
            .catch(error => {
                setErrorsProfile({
                    ...errorsProfile,
                    userIsExit: error.code === 'KIROKU_BAD_REQUEST' && error.message === `username [${profile.username}] is existed!`
                    ,
                });
            });
        trackingMixpanel('STAFF_CREATED', userApplicationRequest);
    };

    useEffect(() => {
        // ignore warning
    }, [resultCreated]);

    return (<div>
        <div className='padding-responsive staff-detail'>
            <ModalNotificationValidateUploadAvatar
                flagValidatedUpload={alertUpload}
                closeModal={() => setAlertUpload(!alertUpload)}
            />
            <KirokuCardWeb>
                <div className='pt-3 pb-3 row m-0'>
                    <div className="col-md-8">
                        <div className='row'>
                            <div className="col-md-6">
                                <div className="pb-3">
                                    <div className="mt-1">{t('common.Name')}</div>
                                    <div className="pt-10">
                                        <KirokuInput
                                            tabIndex='1'
                                            name={'name'}
                                            placeholder={t('common.Name')}
                                            onBlur={handleOnChangeProfile}
                                        />
                                        <p hidden={!errorsProfile.name}
                                           style={{
                                               color   : 'red',
                                               fontSize: '13px',
                                               margin  : 0,
                                           }}>
                                            {t('staff.nameStaffMustNotNull')}
                                        </p>
                                    </div>
                                </div>
                                <ComponentWeb>
                                    <div className="pb-3">
                                        <div className="mt-1">{t('Login.password')}</div>
                                        <div className="pt-10">
                                            <div style={{ position: 'relative' }}>
                                                <InputPassword
                                                    tabIndex='3'
                                                    name='password'
                                                    onBlur={handleOnChangePassword}
                                                />
                                            </div>
                                            <p hidden={!errorsProfile.password}
                                               style={{
                                                   color   : 'red',
                                                   fontSize: '13px',
                                                   margin  : 0,
                                               }}>
                                                {t('errors.userDetail.mustNotNull')}
                                            </p>
                                        </div>
                                    </div>
                                </ComponentWeb>
                                <div className="pb-2">
                                    <div className="mt-1">{t('staff.title')}</div>
                                    <div className="pt-10">
                                        <KirokuInput
                                            tabIndex='5'
                                            name='title'
                                            placeholder={t('staff.title')}
                                            onBlur={handleOnChangeProfile}
                                            style={{ height: 36 }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="pb-3">
                                    <div className="mt-1">{t('Login.username')}</div>
                                    <div className="pt-10">
                                        <KirokuInput
                                            tabIndex='2'
                                            name='username'
                                            placeholder={t('Login.username')}
                                            onBlur={handleOnChangeUserName}
                                        />
                                    </div>
                                    <p hidden={!errorsProfile.username}
                                       style={{
                                           color   : 'red',
                                           fontSize: '13px',
                                           margin  : 0,
                                       }}>
                                        {t('staff.userNameNotNull')}
                                    </p>
                                    <p hidden={!errorsProfile.userIsExit}
                                       style={{
                                           color   : 'red',
                                           fontSize: '13px',
                                           margin  : 0,
                                       }}>
                                        {t('staff.nameExisted')}
                                    </p>
                                    <p hidden={!errorsProfile.userLong}
                                       style={{
                                           color   : 'red',
                                           fontSize: '13px',
                                           margin  : 0,
                                       }}>
                                        {t('staff.validateLength')}
                                    </p>
                                </div>
                                <ComponentMobile>
                                    <div className="pb-3">
                                        <div className="mt-1">{t('Login.password')}</div>
                                        <div className="pt-10">
                                            <div style={{ position: 'relative' }}>
                                                <InputPassword
                                                    tabIndex='3'
                                                    name='password'
                                                    onBlur={handleOnChangePassword}
                                                />
                                            </div>
                                            <p hidden={!errorsProfile.password}
                                               style={{
                                                   color   : 'red',
                                                   fontSize: '13px',
                                                   margin  : 0,
                                               }}>
                                                {t('errors.userDetail.mustNotNull')}
                                            </p>
                                        </div>
                                    </div>
                                </ComponentMobile>
                                <div className="pb-3">
                                    <div className="mt-1">{t('common.ConfirmNewPassword')}</div>
                                    <div className="pt-10">
                                        <div style={{ position: 'relative' }}>
                                            <InputPassword
                                                tabIndex='4'
                                                name='confirmPassword'
                                                onBlur={handleOnChangeConfirmPassword}
                                            />
                                        </div>
                                        <p hidden={!errorsProfile.confirmPassword}
                                           style={{
                                               color   : 'red',
                                               fontSize: '13px',
                                               margin  : 0,
                                           }}>
                                            {t('errors.userDetail.notMatch')}
                                        </p>
                                    </div>
                                </div>
                                <div className="pb-3">
                                    <div className="mt-1">{t('common.WorkingForm')}</div>
                                    <div className="pt-10">
                                        <KirokuDropdownClose
                                            title={t(`common.WorkingForm`)}
                                            items={workingForm}
                                            onSelect={event => {
                                                setUserProfile({
                                                    ...profile,
                                                    working_form: event.kirokuSelected.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pb-3">
                            <div className="pb-2">
                                <div className="mt-1">{t('common.Qualification')}</div>
                                <div className="pt-10">
                                    <KirokuMultiSelect
                                        onChange={handleChangeQualification}
                                        options={kindOfQualification}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='fix-center col-md-4 as'>
                        <div className='hidden-screen-max-768'>
                            <KirokuLoader loading={isLoadingAvatar} ComponentLoading={ComponentLoading}>
                                <div className='staff-detail avatar-user-detail as'
                                     style={{
                                         backgroundImage: `url(${userAvatar || '/assets/img/profile-ic.svg'})`,
                                     }}
                                />
                            </KirokuLoader>
                            <div className='mt-24'>
                                <div className='text-center'>
                                    <KirokuButton color={'success'} onClick={() => inputElement.current.click()}>
                                        {t('CustomerEditor.ChangeAvatar')}
                                    </KirokuButton>
                                    <input type='file' ref={inputElement} hidden
                                           accept="image/jpeg, image/png"
                                           onChange={handleChangeImage}
                                    />
                                </div>
                            </div>
                            <div className='content-avatar text-center pt-3'>
                                <span>{t('Customer.SupportedFile')} </span>
                                <br/>
                                <span>{t('Customer.Maximum')}</span>
                            </div>
                        </div>
                    </div>
                    <ComponentWeb>
                        <div className='pl-3 pt-3 d-flex'>
                            <KirokuButton color='white' onClick={() => history.goBack()}>
                                {t('common.Cancel')}
                            </KirokuButton>
                            <div className='pr-1 pl-1'/>
                            <KirokuButton color='primary'
                                          disabled={
                                              !profile.name || !profile.username ||
                                              !profile.confirmPassword || !profile.password ||
                                              isLoadingUser
                                          }
                                          onClick={handleSubmitApplicationUser}>
                                {t('common.Save')}
                            </KirokuButton>
                        </div>
                    </ComponentWeb>
                </div>
            </KirokuCardWeb>
            <ComponentMobile>
                <div className={'d-flex justify-content-center pt-3'}>
                    <KirokuButton color='white' onClick={() => history.goBack()}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <div className='pr-1 pl-1'/>
                    <KirokuButton color='primary'
                                  disabled={
                                      !profile.name || !profile.username ||
                                      !profile.confirmPassword || !profile.password ||
                                      isLoadingUser
                                  }
                                  onClick={handleSubmitApplicationUser}>
                        {t('common.Save')}
                    </KirokuButton>
                </div>
                <div style={{ marginBottom: 80 }}/>
            </ComponentMobile>
        </div>
    </div>);
}

import React, { useEffect, useRef, useState } from 'react';
import { KirokuButton, KirokuLoading }        from "../../../../ui";
import { useTranslation }                     from "react-i18next";
import { resources }                          from "@kiroku/api";
import usePersonalInformation                 from "./usePersonalInformation";
import ModalNotificationValidateUploadAvatar  from '../../../User/ModalNotificationValidateUploadAvatar';
import config                                 from '../../../../config';

export default function PersonalAvatar() {

    const {t} = useTranslation();
    const inputElement = useRef(null);
    const [ result, upload, isLoading ] = resources.useUpload('Record');
    const {avatar, setPersonalInformation} = usePersonalInformation();
    const [alertUpload, setAlertUpload] = useState(false);

    const uploadCondition = (file) => {
        return (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size < config.sizeFileUploadAvatar;
    };

    const handleChangeImage = () => {
        if (inputElement.current.files.length && uploadCondition(inputElement.current.files[0])) {
            let image = new FormData();

            image.append('file', inputElement.current.files[0]);
            image.append('name', inputElement.current.files[0].name);

            image.append('description', 'some value user types');

            upload('public', image)
        } else if (inputElement.current.files.length){
            setAlertUpload(!alertUpload);
        }
    };

    useEffect(() => {
        if (result) {
            setPersonalInformation('avatar', result.url)
        }
    }, [ setPersonalInformation, result ])

    return (
        <div className="customer-detail fix-center">
            <ModalNotificationValidateUploadAvatar
                flagValidatedUpload={alertUpload}
                closeModal={() => setAlertUpload(!alertUpload)}
            />
            {isLoading ? (
                <div className="height-avatar">
                    <KirokuLoading/>
                </div>

            ) : (
                <div className="height-avatar"
                     style={{backgroundImage: `url(${avatar ? avatar : '/assets/img/profile-ic.svg'})`}}
                />
            )}
            <div className="mt-24">
                <div>
                    <KirokuButton color={'success'} onClick={() => {
                        inputElement.current.click()
                    }}>
                        {t('CustomerEditor.ChangeAvatar')}
                    </KirokuButton>
                    <input
                        hidden
                        type='file'
                        accept="image/jpeg, image/png, image/jpg"
                        ref={inputElement}
                        onChange={handleChangeImage}
                    />
                </div>
            </div>
            <div className='content-avatar text-center'>
                <span>{t('Customer.SupportedFile')} </span>
                <br/>
                <span>{t('Customer.Maximum')}</span>
            </div>
        </div>
    )
}

import { useCustomerContext }   from '../../MakeCustomer/CustomerContext';
import { useCallback }          from 'react';
import { CHANGE_INFO_CUSTOMER } from '../../MakeCustomer/CustomerReducer';

export default function useTagCustomers() {

    const { infoCustomer, dispatch } = useCustomerContext();
    const { teams, users }           = infoCustomer;

    const setTagCustomers = useCallback((name, value) => {
        dispatch({
            type: CHANGE_INFO_CUSTOMER,
            name, value,
        });
    }, [dispatch]);

    const getGroupedOptions = (groupItemsKey, groupedOptions) => {
        if (!groupedOptions.length) {
            return [];
        }
        return groupedOptions.map(tmp => {
            switch (groupItemsKey) {
                case 'User' :
                    return {
                        ...tmp,
                        key    : `${ groupItemsKey }_${ tmp.id }`,
                        value  : `${ tmp.name }${ tmp.id }`,
                        label  : tmp.name,
                        staffId: tmp.id,
                        type   : 'Users',
                        tagType: 'users',
                        id     : tmp.id,
                        ...tmp,
                    };
                case 'Team' :
                    return {
                        key      : `${ groupItemsKey }_${ tmp.id }`,
                        value    : `${ tmp.name }${ tmp.id }`,
                        label    : tmp.name,
                        teamId   : tmp.id,
                        customers: tmp.customers,
                        tagType  : 'team',
                        type     : 'Teams',
                        ...tmp,
                    };
                default :
                    return {
                        key  : `default_${ tmp.id }`,
                        value: `default${ tmp.id }`,
                        label: tmp.title,
                    };
            }
        });
    };

    const currentTeam = getGroupedOptions('Team', teams);
    const currentUser = getGroupedOptions('User', users);
    return {
        currentTeam,
        currentUser,
        setTagCustomers,
        getGroupedOptions,
    };
};

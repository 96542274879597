import { authentication }      from '@kiroku/api';
import { useEffect }           from 'react';
import { useMixpanelTracking } from './useMixpanelTracking';

export default function useMixPanelLoginTrack() {
    const { user, loginSuccess } = authentication.useAuthenticatedContext();
    const { trackingMixpanel }   = useMixpanelTracking();

    useEffect(() => {
        if(loginSuccess) {
            trackingMixpanel('LOGGED_IN', user);
        }
    }, [user, loginSuccess, trackingMixpanel]);


}


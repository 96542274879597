import React              from 'react';
import { useTranslation } from 'react-i18next';
import {
    KirokuTable,
    THeader, TBody,
}                         from '../../../ui';
import FormatTimestamp    from '../CommonComponent/FormatTimestamp';

export default function RecordTable({ records, handleChangeOrderBy, sort_by, order_by_type }) {

    const { t } = useTranslation();

    return (
        <KirokuTable>
            <THeader>
                <tr>
                    <th className="deleted-list-th">{t('DeletedList.customerName')}</th>
                    <th className="deleted-list-th">{t('DeletedList.templateName')}</th>
                    <th className="deleted-list-th">
                        <div className={'d-flex cursor-pointer'} style={{paddingLeft: 30}}
                             onClick={() => handleChangeOrderBy('delete_at')}>
                            <div>{t('DeletedList.deletedAtField')}</div>
                            <div className="p-1"/>
                            <div className={`icon-order 
                                                    ${sort_by === 'delete_at' ? 'color-blue-icon-order' : ''}`
                            }>
                                <i hidden={order_by_type.delete_at === 'DESC'} className="fas fa-lg fa-angle-up"/>
                                <i hidden={order_by_type.delete_at === 'ASC'} className="fas fa-lg fa-angle-down"/>
                            </div>
                        </div>
                    </th>
                    <th className="deleted-list-th">
                        <div className={'d-flex cursor-pointer'}
                             onClick={() => handleChangeOrderBy('create_at')}
                             style={{paddingLeft: 30}}
                        >
                            <div>{t('DeletedList.createdAtField')}</div>
                            <div className="p-1"/>
                            <div className={`icon-order 
                                                    ${sort_by === 'create_at' ? 'color-blue-icon-order' : ''}`
                            }>
                                <i hidden={order_by_type.create_at === 'DESC'} className="fas fa-lg fa-angle-up"/>
                                <i hidden={order_by_type.create_at === 'ASC'} className="fas fa-lg fa-angle-down"/>
                            </div>
                        </div>
                    </th>
                </tr>
            </THeader>
            <TBody>
                {
                    records.map((surveyTemplate, index) => {
                        return (
                            <tr key={index}
                                className={`${index === records.length - 1 ? 'border-bottom-none' : 'border-bottom'}`}>
                                <td className={'color-blue title-table-del-name'}>{surveyTemplate.name}</td>
                                <td className={'color-blue title-table-del-name'}>{surveyTemplate.title}</td>
                                <td className={'width-table-create-del-at title-table-del field-date-time-del'}>
                                    <FormatTimestamp timestampUnix={surveyTemplate.delete_at} isAlign={true}/></td>
                                <td className={'width-table-create-del-at title-table-del field-date-time-del'}>
                                    <FormatTimestamp timestampUnix={surveyTemplate.create_at} isAlign={true}/></td>
                            </tr>
                        );
                    })
                }
            </TBody>
        </KirokuTable>
    );
}

import { resources }         from '@kiroku/api';
import React, {
    useEffect, useState,
}                            from 'react';
import { getGroupedOptions } from '../../Converter';
import {
    ComponentMobile, ComponentWeb, KirokuSectionDropdownShowMore, KirokuSectionDropdownShowMoreMobile,
}                            from '../../../ui';
import { useTranslation }    from 'react-i18next';
import { useConditionTag }   from './useConditionFromParams';

export default function DropdownSelectStaffCondition(props) {
    const { t }                               = useTranslation();
    const { userTags }                        = props;
    const { result, search, cancelAPISearch } = resources.useSearch('User');
    const [currentTagUser, getInfoTag]        = useConditionTag('User');
    const [currentTags, setCurrentTags]       = useState([]);

    useEffect(() => {
        setCurrentTags(currentTagUser);
    }, [currentTagUser]);


    useEffect(() => {
        if (userTags) {
            getInfoTag(userTags);
        } else {
            setCurrentTags([])
        }
    }, [userTags, getInfoTag]);

    useEffect(() => {
        search({ type: 'all' });
        return () => {
            cancelAPISearch();
        }
    }, [search, cancelAPISearch]);

    let users = getGroupedOptions('User', result);

    const handleChangeTags = (options) => {
        setCurrentTags(options || []);
        props.handleChangeUserTags(options);
    };

    const handleOnclickSearchBtn = () => {
        props.handleSearchTags();
    };

    return (
        <div className={'pb-3'}>
            <ComponentWeb>
                <KirokuSectionDropdownShowMore
                    isSearchTags
                    tagType={'Staff'}
                    onChange={handleChangeTags}
                    value={currentTags}
                    options={users}
                    handleSearchTags={handleOnclickSearchBtn}
                    placeholder={t('staff.placeholderSearch')}
                />
            </ComponentWeb>
            <ComponentMobile>
                <KirokuSectionDropdownShowMoreMobile
                    isSearchTags
                    tagType={'Staff'}
                    onChange={handleChangeTags}
                    value={currentTags}
                    options={users}
                    handleSearchTags={handleOnclickSearchBtn}
                    placeholder={t('staff.placeholderSearch')}
                />
            </ComponentMobile>
        </div>


    );
}

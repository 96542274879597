import { KirokuInputSearchOnBlur }    from '../../../ui';
import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';

export default function KeywordsSearch({ condition, search, id }) {
    const { t } = useTranslation();
    const [ name, setName ] = useState(condition.title);

    useEffect(() => {
        setName(condition.title);
    }, [condition.title])

    return <KirokuInputSearchOnBlur
        name={'name'}
        placeholder={t('RecordsBeta.keyword')}
        value={name}
        onSubmit={() => {
            let conditionRequest = {
                ...condition, title: name,
                page: 1
            }
            if(!conditionRequest.title) {
                delete conditionRequest.title
            }
            search(conditionRequest);
        }}
        onChange={(e) => setName(e.target.value)}
        onBlur={() => {}}
        id={ id || 'input-search-keyword-web-surveys-v2'}
    />
}

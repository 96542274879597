import React, { Component }                     from 'react';
import { KirokuForm, KirokuInputSearchAndBlur } from './index';
import { withTranslation }                      from 'react-i18next';

class KirokuInputSearchOnBlur extends Component {

    searcherBeginSearch() {
        this.props.onSubmit();
    }

    searcherTypingKeyWord(e) {
        this.props.onBlur(e);
    }

    handleChangeKeyWord(e) {
        this.props.onChange(e);
    }

    render() {
        const { t }                                = this.props;
        const { placeholder, id, isNotIconSearch, styleIcon } = this.props;
        return (
            <KirokuForm onSubmit={ this.searcherBeginSearch.bind(this) }>
                <KirokuInputSearchAndBlur { ...this.props } id={ id || 'KirokuInputSearcher' }
                                          onBlur={ this.searcherTypingKeyWord.bind(this) }
                                          onChange={ this.handleChangeKeyWord.bind(this) }
                                          placeholder={ placeholder || t('common.Search') }>
                    <div id={ 'input-form-searcher' } hidden={ isNotIconSearch }>
                        <button className='fix-icon-input-search cursor-pointer' style={styleIcon}>
                            <i className="fa fa-search " onClick={ this.searcherBeginSearch.bind(this) }/>
                        </button>
                    </div>
                </KirokuInputSearchAndBlur>
            </KirokuForm>
        );
    }
}

export default withTranslation()(KirokuInputSearchOnBlur);

import React                   from 'react';
import {
    KirokuCardWeb, KirokuInputDate,
    KirokuCardBodyWeb,
}                              from '../../../../ui';
import moment                  from 'moment';
import { useTranslation }      from 'react-i18next';
import { useHeadingQuestion }  from '../../useSurvey';
import { useAsQuestionType }   from '../../../Recording';
import QuestionCardTitle       from './QuestionCardTitle';
import { ButtonNext, getNext } from './getNextQuestion';
import { QuestionSkipped }     from './QuestionSkipped';
import ComponentMobile         from '../../../../ui/ComponentMobile';
import ComponentWeb            from '../../../../ui/ComponentWeb';
import KirokuInputDateMobile   from '../../../../ui/KirokuInputDateMobile';

const width = window.innerWidth;

export function setDefaultAnswerOnlyDate(question) {
    return {
        ...question,
        answers: question[ 'defaultAnswer' ],
    };
}

export default function DateQuestion({ question }) {

    const { t }                   = useTranslation();
    const { doAnswer, answering } = useAsQuestionType(question);
    const { content }             = useHeadingQuestion(question);

    return (<div id='component-question'>
        <div id='web-q' className='pb-3'>
            <KirokuCardWeb>
                <div className={ (width <= 768) && !!question.answers ? 'border-has-answer': ' '}>
                <QuestionCardTitle
                    isDisabled={question.isDisabled}
                    question={question}
                    handleResetAnswer={() => doAnswer(null, question.index + 1)}
                    hasAnswer={!!question.answers}
                />
                <KirokuCardBodyWeb>
                    <QuestionSkipped
                        isDisabled={question.isDisabled}
                    >
                        <span className='content-name-question'>{content}</span>
                        <div className="d-flex">
                            <div className='answer-question' style={{ maxWidth: 250 }}>

                                <ComponentWeb>
                                    <KirokuInputDate
                                        className={'date-chart-search'}
                                        inputProps={{
                                            disabled: question.isDisabled, readOnly: true
                                        }}
                                        value={question.answers ? moment.unix(question.answers) : null}
                                        timeFormat={false}
                                        dateFormat={t('dateTimeFormat.YearMonthDate')}
                                        onChange={(momentTime) => {
                                            answering();
                                            doAnswer(
                                                momentTime ? moment(momentTime).unix() : null,
                                                getNext({ ...question, isAnswers: !!momentTime }));
                                        }}
                                        locale={t('createRecord.Date')}
                                    />
                                </ComponentWeb>
                                <ComponentMobile>
                                    <div style={{width: 216}}>
                                        <KirokuInputDateMobile
                                            readOnly={true}
                                            name={`${question.index}-date`}
                                            value={question.answers}
                                            disabled={question.isDisabled}
                                            onChange={(timestampUnix) => {
                                                answering();
                                                doAnswer(
                                                    timestampUnix ? timestampUnix: null,
                                                    getNext({ ...question, isAnswers: !!timestampUnix }));
                                            }}
                                        />
                                    </div>
                                </ComponentMobile>

                            </div>
                            <div
                                style={{ paddingTop: 10 }}
                            >
                                <ButtonNext
                                    question={question}
                                    onClick={() => {
                                        if(question.answers) {
                                            doAnswer(question.answers, getNext(question))
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </QuestionSkipped>

                </KirokuCardBodyWeb>
                </div>
            </KirokuCardWeb>
        </div>
    </div>);
}

import { Link, useHistory }                                                              from 'react-router-dom';
import React, { useCallback, useEffect, useState }                                       from 'react';
import { resources, useClient }                                                          from '@kiroku/api';
import { useTranslation }                                                                from 'react-i18next';
import { ComponentMobile, ComponentWeb, KirokuLoader, KirokuNotFound, KirokuPagination } from '../../../ui';
import SearchNotFound
                                                                                         from '../../../ui/SearchNotFound';
import PlanTableWeb                                                                      from './PlanTableWeb';
import PlanTableMobile                                                                   from './PlanTableMobile';
import ButtonPermission
                                                                                         from '../../HOC/ButtonPermission';
import { withPermissions }                                                               from '../../HOC';
import CustomerCondition                                                                 from '../CustomerCondition';
import queryString                                                                       from 'query-string';
import { useProfile }                                                                    from '../../useProfile';
import { useTeamDefaultCondition }                                                       from '../useTeamDefaultCondition';
import ReviewStatusCondition                                                             from './ReviewStatusCondition';
import PlanStatusCondition                                                               from './PlanStatusCondition';

const Button = withPermissions(ButtonPermission);

function NotFoundResult() {
    return <div style={ { backgroundColor: '#fff' } }>
        <SearchNotFound/>
    </div>;
}

function convertCustomerCondition(customerIds) {
    if(!customerIds) {
        return [];
    }
    if(typeof customerIds === 'string') {
        return [parseInt(customerIds)];
    }
    return customerIds;
}

export default function PlanTables({ customerDefault }) {
    const { t } = useTranslation();

    const {
              result, search,
              condition, pagination,
              cancelAPISearch,
          }                           = resources.useSearch('Plan', {
        draft        : false,
        beforeStart  : false,
        running      : false,
        finished     : false,
    });
    const [loadingAPI, setLoadingAPI] = useState(true);
    const history                     = useHistory();
    const { axios }                   = useClient();

    const [{ draft, overdate }, setResponse] = useState({ draft: 0, overdate: 0 });

    const getDataCountStatusPlan = useCallback((condition) => {
        let customerId = convertCustomerCondition(condition.customerId)
        const customersString = queryString.stringify({...condition, customerId, type: 'condition' });
        axios.get(`/status-plan?${ customersString }`).then(response => {
            setResponse(response);
        });
    }, [axios]);

    const [customerDefaultByTeam, getCustomerDefaultByTeam] = useTeamDefaultCondition();
    const [profile]                                         = useProfile();

    useEffect(() => {
        getCustomerDefaultByTeam(profile.id);
    }, [profile.id, getCustomerDefaultByTeam]);

    useEffect(() => {

        try {
            const conditionUrl = queryString.parse(history.location.search.replace('?', ''), { arrayFormat: 'comma' });
            if (conditionUrl.type) {
                search(conditionUrl).then(() => {
                    setLoadingAPI(false);
                });
                getDataCountStatusPlan({ customerId : conditionUrl.customerId, reviewStatus : conditionUrl.reviewStatus});
            } else {
                search({
                    type         : 'condition',
                    page         : 1,
                    tabActive    : 'CUSTOMER_DEFAULT',
                    customerId   : customerDefault.map(customer => customer.id),
                    draft        : false,
                    beforeStart  : false,
                    running      : false,
                    finished     : false,
                    reviewStatus : 'all'
                }).then(() => {
                    setLoadingAPI(false);
                }).catch(err => {
                    console.log(err);
                    setLoadingAPI(false);
                });
                getDataCountStatusPlan({customerId: customerDefault.map(customer => customer.id ), reviewStatus: 'all'});
            }
        } catch (error) {
            search({
                type         : 'condition', page: 1,
                tabActive    : 'CUSTOMER_DEFAULT',
                customerId   : customerDefault.map(customer => customer.id),
                draft        : false,
                beforeStart  : false,
                running      : false,
                finished     : false,
                reviewStatus : 'all'
            }).then(() => {
                setLoadingAPI(false);
            });
        }

        return () => {
            cancelAPISearch();
        };
    }, [search, cancelAPISearch, customerDefault, history, getDataCountStatusPlan]);

    useEffect(() => {
        const urlQuery = queryString.stringify({ ...condition }, { arrayFormat: 'comma' });
        history.replace('/plans?' + urlQuery);
    }, [condition, history]);

    return (
        <div>
            <ComponentMobile>
                <div className={ 'd-flex justify-content-between pb-2' }>
                    <div/>
                    <Link to={ '/plans/create' }>
                        <Button color='primary'
                                resource-permission={ '|planList|button-create|' }
                        >
                            { t('plans.addPlan') }
                        </Button>
                    </Link>
                </div>
            </ComponentMobile>
            <div className={ 'width-condition-customer-of-plan' }>
                <CustomerCondition
                    customerCondition={ convertCustomerCondition(condition.customerId) }
                    tabActive={ condition.tabActive || 'CUSTOMER_DEFAULT' }
                    customerDefault={ customerDefault }
                    customerDefaultByTeam={ customerDefaultByTeam }
                    searchPlanning={ (conditionTab) => {
                        setLoadingAPI(true);
                        search({
                            ...condition,
                            type      : 'condition',
                            page      : 1,
                            tabActive : conditionTab.tabActive,
                            customerId: conditionTab.customerId,
                        }).then(() => {
                            setLoadingAPI(false);
                        });
                        getDataCountStatusPlan({ reviewStatus: condition.reviewStatus, customerId: condition.customerId});
                    } }
                />
            </div>
            <ComponentWeb>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='filter-log'>
                        <div>{t('plans.planStatus')}: </div>
                        <div
                            className={ `action-log-entity-status ${ (condition.draft.toString() === 'false' &&
                                condition.beforeStart.toString() === 'false' &&
                                condition.running.toString() === 'false' &&
                                condition.finished.toString() === 'false'
                            ) ? 'active-tab' : '' }` }
                            onClick={ () => {
                                setLoadingAPI(true);
                                search({
                                    ...condition,
                                    type         : 'condition',
                                    page         : 1,
                                    draft        : false,
                                    beforeStart  : false,
                                    running      : false,
                                    finished     : false,
                                    reviewStatus : 'all'
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: condition.reviewStatus, customerId: condition.customerId});
                            } }
                        >
                        <span className='items-condition'>
                            <img src={ `/assets/img/All.svg` } style={ { width: 17, height: 19 } }
                                 alt={ 'All.svg ' }/>&nbsp;{ t('common.All') }
                        </span>
                        </div>
                        <div
                            className={ `action-log-entity-status ${ condition.draft.toString() === 'true' ? 'active-tab' : '' }` }
                            onClick={ () => {
                                setLoadingAPI(true);
                                search({
                                    ...condition,
                                    type         : 'condition',
                                    page         : 1,
                                    draft        : true,
                                    beforeStart  : false,
                                    running      : false,
                                    finished     : false,
                                    reviewStatus : 'all'
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: 'all', customerId: condition.customerId});
                            } }
                        >
                            <span className='items-condition'>
                            <img src={ `/assets/icon/iconDraft.svg` } alt="iconDraft.svg"/>
                                &nbsp;{ t('plans.draft') }  { draft ? <>({ draft })</> : <div style={{ width: 91 }}/> }
                            </span>

                        </div>
                        <div
                            className={ `action-log-entity-status ${ condition.beforeStart.toString() === 'true' ? 'active-tab' : '' }` }
                            onClick={ () => {
                                setLoadingAPI(true);
                                search({
                                    ...condition,
                                    type         : 'condition',
                                    page         : 1,
                                    draft        : false,
                                    beforeStart  : true,
                                    running      : false,
                                    finished     : false,
                                    reviewStatus : 'all'
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: 'all', customerId: condition.customerId});
                            } }
                        >
                            <span className='items-condition'>
                            <img src={ `/assets/icon/iconBeforeStart.svg` } alt="iconBeforeStart.svg"/>
                                &nbsp;{ t('plans.beforeStart') }
                            </span>

                        </div>
                        <div
                            className={ `action-log-entity-status ${ condition.running.toString() === 'true' ? 'active-tab' : '' }` }
                            onClick={ () => {
                                setLoadingAPI(true);
                                search({
                                    ...condition,
                                    type         : 'condition',
                                    page         : 1,
                                    draft        : false,
                                    beforeStart  : false,
                                    running      : true,
                                    finished     : false,
                                    reviewStatus : 'all'
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: condition.reviewStatus, customerId: condition.customerId});
                            } }
                        >
                            <span className='items-condition'>
                            <img src={ `/assets/icon/iconRunning.svg` } alt="iconRunning.svg"/>
                                &nbsp;{ t('plans.running') }
                            </span>

                        </div>
                        <div
                            className={ `action-log-entity-status ${ condition.finished.toString() === 'true' ? 'active-tab' : '' }` }
                            onClick={ () => {
                                setLoadingAPI(true);
                                search({
                                    ...condition,
                                    type         : 'condition',
                                    page         : 1,
                                    draft        : false,
                                    beforeStart  : false,
                                    running      : false,
                                    finished     : true,
                                    reviewStatus : 'all'
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: condition.reviewStatus, customerId: condition.customerId});
                            } }
                        >
                            <span className='items-condition'>
                            <img src={ `/assets/icon/iconFinish.svg` } alt="iconFinish.svg"/>
                                &nbsp;{ t('plans.finish') }  { overdate ? <>({ overdate })</> : '' }
                            </span>
                        </div>
                    </div>
                </div>
                <div className={ `justify-content-flex-start align-items-center d-flex` }>
                    <div>{t('plans.statusReview')}:  </div>
                    <div className="d-flex">
                        <div
                            className={ `status-action-review cursor-pointer ${ condition.reviewStatus === 'all' ? 'active-tab' : '' }` }
                            onClick={ () => {
                                setLoadingAPI(true);
                                search({
                                    ...condition,
                                    type         : 'condition',
                                    page         : 1,
                                    reviewStatus : 'all'
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: 'all', customerId: condition.customerId});
                            } }
                        >
                            <span className='items-condition'>
                                {t('common.All')}
                            </span>
                        </div>

                        <div
                            className={ `status-action-review cursor-pointer ${ condition.reviewStatus === 'unReview' ? 'active-tab' : '' }` }
                            onClick={ () => {
                                setLoadingAPI(true);
                                search({
                                    ...condition,
                                    type         : 'condition',
                                    page         : 1,
                                    reviewStatus : 'unReview'
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: 'unReview', customerId: condition.customerId});
                            } }
                        >
                            <span className='items-condition'>
                                {t('plans.unReview')}
                            </span>
                        </div>
                        <div
                            className={ `status-action-review cursor-pointer ${ condition.reviewStatus === 'draftReview' ? 'active-tab' : '' }
                            ${condition.beforeStart.toString() === 'true' || condition.draft.toString() === 'true' ? 'disabled-tab': ' '}
                            ` }
                            onClick={ () => {
                                if (condition.beforeStart.toString() === 'true' || condition.draft.toString() === 'true') {
                                return;
                            }
                                setLoadingAPI(true);
                                search({
                                    ...condition,
                                    type         : 'condition',
                                    page         : 1,
                                    reviewStatus : 'draftReview'
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: 'draftReview', customerId: condition.customerId});
                            } }
                        >
                            <span className={ `items-condition ${condition.beforeStart.toString() === 'true' || condition.draft.toString() === 'true' ? 'disabled-text': ''}` }>
                                {t('plans.draftReview')}
                            </span>
                        </div>
                        <div
                            className={ `status-action-review cursor-pointer ${ condition.reviewStatus === 'reviewed' ? 'active-tab' : '' } 
                            ${condition.beforeStart.toString() === 'true' || condition.draft.toString() === 'true' || condition.running.toString() === 'true'? 'disabled-tab': ' '}
                            ` }
                            onClick={ () => {
                                if (condition.beforeStart.toString() === 'true' || condition.draft.toString() === 'true' || condition.running.toString() === 'true') {
                                    return;
                                }
                                setLoadingAPI(true);
                                search({
                                    ...condition,
                                    type         : 'condition',
                                    page         : 1,
                                    reviewStatus : 'reviewed'
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: 'reviewed', customerId: condition.customerId});
                            } }
                        >
                            <span className={ `items-condition ${condition.beforeStart.toString() === 'true' || condition.draft.toString() === 'true' || condition.running.toString() === 'true'? 'disabled-text': ''}` } >
                                {t('plans.monitored')}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={ `${ result.length ? 'd-flex' : 'd-none' } justify-content-flex-end` }>
                    <KirokuPagination
                        className={ 'pb-0' }
                        disabledPrev={ pagination.currentPage === 1 }
                        disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                        increasePage={ () => {
                            if(loadingAPI) {
                                return null;
                            }
                            setLoadingAPI(true);
                            search({
                                ...condition,
                                type: 'condition',
                                page: pagination.currentPage + 1,
                            }).then(() => {
                                setLoadingAPI(false);
                            });
                            getDataCountStatusPlan({ reviewStatus: condition.reviewStatus, customerId: condition.customerId});
                        } }
                        decreasePage={ () => {
                            if(loadingAPI) {
                                return null;
                            }
                            setLoadingAPI(true);
                            search({
                                ...condition,
                                type: 'condition',
                                page: pagination.currentPage - 1,
                            }).then(() => {
                                setLoadingAPI(false);
                            });
                            getDataCountStatusPlan({ reviewStatus: condition.reviewStatus, customerId: condition.customerId});
                        } }
                        currentPage={ pagination.currentPage }
                        totalPage={ pagination.totalPage }
                    />
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <div className={ 'pt-3 pb-3' }>
                    <div>{t('plans.planStatus')}: &nbsp; </div>
                    <div className="pt-10px">
                        <PlanStatusCondition
                            condition={ condition }
                            overdate={ overdate }
                            draft={ draft }
                            onSearchPlan={ (conditionSearch) => {
                                setLoadingAPI(true);
                                search({
                                    ...conditionSearch,
                                    page : 1,
                                    reviewStatus: 'all'
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: 'all', customerId: condition.customerId});
                            } }
                        />
                    </div>
                </div>
                <div className="pb-3">
                    <div style={ { minWidth: 'max-content' } }>{t('plans.statusReview')}: &nbsp; </div>
                    <div className={'pt-10px'}>
                        <ReviewStatusCondition
                            condition={condition}
                            onSearchReview={(conditionStatus) => {
                                setLoadingAPI(true);
                                search({
                                    ...condition,
                                    page: 1,
                                    reviewStatus: conditionStatus
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: conditionStatus, customerId: condition.customerId});
                            }}
                        />
                    </div>
                </div>
                <div className={ `${ result.length || loadingAPI ? 'd-flex' : 'd-none'} justify-content-flex-end pb-3` }>
                    <KirokuPagination
                        className={ 'pb-0' }
                        disabledPrev={ pagination.currentPage === 1 }
                        disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                        increasePage={ () => {
                            if(loadingAPI) {
                                return null;
                            }
                            setLoadingAPI(true);
                            search({
                                ...condition,
                                type: 'condition',
                                page: pagination.currentPage + 1,
                            }).then(() => {
                                setLoadingAPI(false);
                            });
                            getDataCountStatusPlan({ reviewStatus: condition.reviewStatus, customerId: condition.customerId});
                        } }
                        decreasePage={ () => {
                            if(loadingAPI) {
                                return null;
                            }
                            setLoadingAPI(true);
                            search({
                                ...condition,
                                type: 'condition',
                                page: pagination.currentPage - 1,
                            }).then(() => {
                                setLoadingAPI(false);
                            });
                            getDataCountStatusPlan({ reviewStatus: condition.reviewStatus, customerId: condition.customerId});
                        } }
                        currentPage={ pagination.currentPage }
                        totalPage={ pagination.totalPage }
                    />
                </div>
            </ComponentMobile>
            <div className='bg-white'>
                <KirokuLoader loading={ loadingAPI }>
                    <KirokuNotFound isResults={ result.length }
                                    ComponentSearchNoFound={ NotFoundResult }
                    >
                        <ComponentWeb>
                            <PlanTableWeb result={ result }/>
                        </ComponentWeb>
                        <ComponentMobile>
                            <PlanTableMobile result={ result }/>
                        </ComponentMobile>
                    </KirokuNotFound>
                </KirokuLoader>
            </div>
            <ComponentWeb>
                <div className={ `${ result.length ? 'd-flex justify-content-between pt-3' : 'd-none' }` }>
                    <div/>
                    <div>
                        <KirokuPagination
                            className={ 'pb-2' }
                            disabledPrev={ pagination.currentPage === 1 }
                            disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                            increasePage={ () => {
                                if(loadingAPI) {
                                    return null;
                                }
                                setLoadingAPI(true);
                                search({
                                    ...condition,
                                    type: 'condition',
                                    page: pagination.currentPage + 1,
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: condition.reviewStatus, customerId: condition.customerId});
                            } }
                            decreasePage={ () => {
                                setLoadingAPI(true);
                                if(loadingAPI) {
                                    return null;
                                }
                                search({
                                    ...condition,
                                    type: 'condition',
                                    page: pagination.currentPage - 1,
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: condition.reviewStatus, customerId: condition.customerId});
                            } }
                            currentPage={ pagination.currentPage }
                            totalPage={ pagination.totalPage }
                        />
                    </div>
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <div className={ `${ result.length ? 'd-flex justify-content-between pt-3' : 'd-none' }` }>
                    <div/>
                    <div style={ { marginBottom: 80 } }>
                        <KirokuPagination
                            className={ 'pb-2' }
                            disabledPrev={ pagination.currentPage === 1 }
                            disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                            increasePage={ () => {
                                if(loadingAPI) {
                                    return null;
                                }
                                setLoadingAPI(true);
                                search({
                                    ...condition,
                                    type: 'condition',
                                    page: pagination.currentPage + 1,
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: condition.reviewStatus, customerId: condition.customerId});
                            } }
                            decreasePage={ () => {
                                if(loadingAPI) {
                                    return null;
                                }
                                setLoadingAPI(true);
                                search({
                                    ...condition,
                                    type: 'condition',
                                    page: pagination.currentPage - 1,
                                }).then(() => {
                                    setLoadingAPI(false);
                                });
                                getDataCountStatusPlan({ reviewStatus: condition.reviewStatus, customerId: condition.customerId});
                            } }
                            currentPage={ pagination.currentPage }
                            totalPage={ pagination.totalPage }
                        />
                    </div>
                </div>
            </ComponentMobile>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import { KirokuInputSearchOnBlur }    from '../../../ui';

export default function RecordsCondition({ searchDeleted, condition }) {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');

    const handlerSubmitSearch = () => {
        searchDeleted({
            ...condition,
            page: 1,
            name, title
        });
    };

    useEffect(() => {
        setTitle(condition.title);
        setName(condition.name);
    }, [condition.title, condition.name]);



    return (
        <div className={'display-flex-web'} style={{ width: '100%' }}>
            <div className={'font-size-condition'}>
                <KirokuInputSearchOnBlur
                    name={'name'}
                    placeholder={t('DeletedList.searchByCustomer')}
                    value={name}
                    onSubmit={handlerSubmitSearch}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={() => {}}
                    id={'inputSearchSurveysDeleted'}
                    style={{ height: 36}}
                />
            </div>
            <div className="p-2"/>
            <div className={'font-size-condition'}>
                <KirokuInputSearchOnBlur
                    name={'title'}
                    placeholder={t('DeletedList.searchByTemplate')}
                    value={title}
                    onSubmit={handlerSubmitSearch}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={() => {}}
                    id={'inputSearchSurveysDeleted'}
                    style={{ height: 36}}
                />
            </div>
        </div>
    );
}

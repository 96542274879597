import { useTranslation }             from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { KirokuInput }                from '../../../ui';

export function InputContentQuestion({ index, content = '', onBlur }) {

    const { t }                                           = useTranslation();
    const [inputContentQuestion, setInputContentQuestion] = useState(content);
    const [validate, settValidation]                      = useState(true);

    useEffect(() => {
        setInputContentQuestion(content);
    }, [content]);

    return <>
        <label htmlFor={`input-content-question-${index}`}
               className={'field-form-label'}
        >
            {t('createSurvey.YourQuestion')}
        </label>
        <KirokuInput value={inputContentQuestion}
                     id={`input-content-question-${index}`}
                     onChange={(event) => {
                         setInputContentQuestion(event.target.value);
                         settValidation(!!event.target.value);

                     }}
                     onBlur={onBlur}
                     className={!validate ? 'form-error' : ''}

        />
        <p
            hidden={validate}
            style={{
                color   : 'red',
                fontSize: 12,
            }}
        >
            {t('createSurvey.QuestionMustNotNull')}
        </p>
    </>;
}

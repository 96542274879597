import React, { Component } from 'react';
import moment               from 'moment';
import { withTranslation }  from 'react-i18next';

class KriokuComment extends Component {
    render() {
        const { comment } = this.props;
        const {t} = this.props;
        return (
            <div className={'comment'}>
                <div className={'content-comment'}>
                    <div className={'d-flex'}>
                        <div>
                            <div className={'avatar-user-comment'}
                                 style={{
                                     backgroundImage: `url(${comment.avatar ? comment.avatar : '/assets/img/profile-ic.svg'})`
                                 }}
                            />
                        </div>
                        <div>
                            <div className={'author'}>
                                {comment[ 'user_name' ]}
                            </div>
                            <blockquote className={'mb-2 mt-2'} style={{fontSize: 12}}>
                                {comment.content.split('\n').map((comment, index) => <p key={index}
                                                                                        className={`mb-0 ${this.props.className}`}>{comment}</p>)}
                            </blockquote>
                            <div className={'meta-data'}>
                                <span className={'date'}>
                                    {moment.unix(comment.create_at).format(t('dateTimeFormat.HourMinute'))}
                                </span>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

export default withTranslation() (KriokuComment);

import React, { Component } from 'react';
import CalendarHeatmap      from '@sphinx-software/react-calendar-heatmap';
import ReactTooltip         from 'react-tooltip';
import moment               from 'moment';
import config               from '../../../../config';
import { withTranslation }  from 'react-i18next';


class CalendarChart extends Component {
    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    renderFillColor = (value) => {
        if (!value) {
            return `color-null`
        }
        else if (value.count === 0) {
            return 'color-empty';
        }
        else if (value.count < 4){
            return `color-scale-less`;
        } else if (value.count < 7 ) {
            return `color-scale-normal`;
        } else if (value.count < 10) {
            return `color-scale-more`
        }
        return `color-scale-full-fill`
    };

    renderTooltip = ( value) => {
        const {t, textValueZero} =this.props;
        const getLanguages = localStorage.getItem('i18nextLng');
        const convertFormatDate = moment(value.date).unix();
        if (isNaN(convertFormatDate)) return '';
        return  {
            'data-tip':
                `${moment.unix(convertFormatDate).format(getLanguages === 'ja' ?
                    config.formatDateYMD : config.formatDateTh)}
                    ${t( (!value.count && textValueZero) ? textValueZero : "Analysis.countingRecord", {count: value.count})}`
        };
    };

    render() {
        const {startDate, endDate, dataAnalysis} = this.props;
        const getLanguages = localStorage.getItem('i18nextLng');
        let jaMonthLabels = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
        let enMonthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let jaWeekDayLabels = ['日', '月', '火', ' 水', '木', '金' , '土'];
        let enWeekDayLabels = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
        let monthLabels = getLanguages === 'ja' ? jaMonthLabels : enMonthLabels;
        let weekdayLabels = getLanguages === 'ja' ? jaWeekDayLabels : enWeekDayLabels;

        return (
            <div>
                <CalendarHeatmap
                    startDate={new Date(startDate)}
                    endDate={new Date(endDate)}
                    values={dataAnalysis}
                    classForValue={(value) => this.renderFillColor(value)}
                    tooltipDataAttrs={ this.renderTooltip.bind(this)}
                    monthLabels={monthLabels}
                    weekdayLabels={weekdayLabels}
                    showWeekdayLabels={true}
                    onClick={value => this.props.handleOnClick(value)}
                />
                <ReactTooltip/>
            </div>
        );
    }
}

export default withTranslation()(CalendarChart);

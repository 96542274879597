import React, { useState }        from 'react';
import { KirokuButton }           from '../../../../ui';
import { useTranslation }         from 'react-i18next';
import ModalSelectUpdater         from '../SelectUpdater/ModalSelectUpdater';
import TemplatesModal             from '../TemplatesModal/TemplatesModal';
import { useRecordContext }       from '../../ConditionBuilder/RecordContext';
import UpdaterProvider            from '../SelectUpdater/UpdaterProvider';
import { TemplatesModalProvider } from '../TemplatesModal/TemplateModalContextProvider';

export default function UpdaterCondition() {
    const { t }                     = useTranslation();
    const [showModal, setShowModal] = useState('');
    const { recordCondition }       = useRecordContext();
    return (
        <div className={ 'btn-group-select d-flex p-0 pt-2' }>
            <div className="pl-0">
                <KirokuButton className={ `${ recordCondition.udId.length ? 'btn-active' : 'btn-deActive' }` }
                              onClick={ () => setShowModal('updater') }
                              style={ { height: 36, fontSize: 14 } }
                >{ t('RecordsBeta.selectUpdater') }</KirokuButton>
            </div>
            <div className="pl-3">
                <KirokuButton className={ `${ recordCondition.tId.length ? 'btn-active' : 'btn-deActive' }` }
                              onClick={ () => setShowModal('templates') }
                              style={ { height: 36, fontSize: 14 } }
                >{ t('RecordsBeta.selectTemplate') }</KirokuButton>
            </div>
            <UpdaterProvider>
                <ModalSelectUpdater show={ showModal === 'updater' } closeModal={ () => setShowModal('') }/>
            </UpdaterProvider>
            <TemplatesModalProvider>
                <TemplatesModal show={ showModal === 'templates' } closeModal={ () => setShowModal('') }/>
            </TemplatesModalProvider>
        </div>
    );

}

import React, { useEffect, useState }          from 'react';
import { withPermissions, WrapperPermissions } from '../../../../HOC';
import {
    KirokuCardWeb, KirokuCheckBox, KirokuDropdownClose, KirokuInput, KirokuInputSearcher, KirokuLoader,
    KirokuPagination, KirokuTable, TBody, THeader,
} from '../../../../../ui';
import If                                      from '../../../../If';
import * as moment                             from 'moment';
import config                                  from '../../../../../config';
import { useTranslation }                      from 'react-i18next';
import ThPermission           from '../../../../HOC/ThPermission';
import { useDocumentContext }  from '../DocumentContext';
import useDocumentAction       from '../useDocumentAction';
import lodash                  from 'lodash';
import ModalConfirm            from './ModalConfirm';
import PreviewFileModal        from './PreviewFileModal';
import ModalConfirmDelete      from './ModalConfirmDelete';
import ModalSelectUser         from './ModalSelectUsers';
import useNotification         from '../../../../Layout/useNotification';
import { resources }           from '@kiroku/api';
import ModalRenameFile         from './ModalRenameFIle';
import { KirokuTogglePopover, KirokuPopoverItem } from '../HelperComponent';

const ThWithPermissions = withPermissions(ThPermission);

export default function DocumentsWeb() {
    const { t } = useTranslation();

    const { documentsData, modalInfo, loginUserInfo } = useDocumentContext();
    const {
              handleSelectDocument, handleSelectedAll,
              handleSelectType, handleSearchKeyword,
              handleChangePage, handleSearchTime,
              optionFileTypeItems, optionDateItems,
              isShareFromUserLogin, changeStatusDocument,
              showModalPreviewDocument, closeModalName,
              showModalConfirmChangeStatusToPrivate,
              showModalConfirmDelDocument, refreshDocument,
              handleOpenModalSelectUser, handleShareDocumentToUser,
              handleRenameMode
          }                                           = useDocumentAction();
    const [keywords, setKeywords]                     = useState('');

    const increasePage = () => {
        handleChangePage(documentsData.currentPage + 1);
    };

    const decreasePage = () => {
        handleChangePage(documentsData.currentPage - 1);
    };

    const handleDelete = () => {
        showModalConfirmDelDocument();
    };

    const handleChoiceDate = (event) => {
        handleSearchTime({
            timeKey  : event.kirokuSelected.value,
            timeValue: event.kirokuSelected.value === 'All date' ? '' : moment().startOf(event.kirokuSelected.value).unix(),
        });
    };

    const handleChoiceType = (event) => {
        handleSelectType(event.kirokuSelected.value);

    };

    const previewFile = (documentId) => {
        showModalPreviewDocument(documentId);
    };

    const showModalConfirmToPrivateFromPublic = (id) => {
        showModalConfirmChangeStatusToPrivate(id);
    };

    const switchDocumentToPublicFromPrivate = (documentId) => {
        changeStatusDocument(documentId, 1);
    };

    const handleShareDocument = (document) => {
        handleOpenModalSelectUser(document);
    };

    const handleCheckAll = () => {
        handleSelectedAll(lodash.findIndex(documentsData.documents, documentItem => !documentItem.isChecked) === -1);
    };

    const handleSelect = (documentId) => {
        handleSelectDocument(documentId);
    };

    const [fileName, setFileName] = useState('');
    const displayModeEditName    = (document) => {
        setFileName(document.name);
        handleRenameMode(document.id);
    };

    const handleCancelRenameFile = () => {
        handleRenameMode(null);
        setFileName('');
    };

    const [result, update, isLoadingAPIUpdateNameDocument] = resources.usePatch('Document');
    const { notification } = useNotification();

    useEffect(() => {
    }, [result]);

    const handleConfirmRenameFile = async (document) => {
        if(!fileName.replace(/\s+/g, '') || isLoadingAPIUpdateNameDocument) {
            return null
        }
        await update(document.id, {
            name: fileName,
        }).then(() => {
            handleRenameMode(document.id);
            setFileName('');
            refreshDocument();
            notification(t('message.UpdateSuccess'), 'success');
        });
    };

    return <div>
        <div className={ `d-flex justify-content-between pt-3 pb-3` }>
            <WrapperPermissions
                resource-permission={ '|customerDetail|documentTab|DropdownBulkAction|Delete|' }
            >
                <KirokuDropdownClose
                    disabled={ lodash.findIndex(documentsData.documents, documentItem => documentItem.isChecked) === -1 }
                    style={ { width: 160 } }
                    title={ t('common.BulkAction') }
                    items={ [
                        {
                            value    : 'Delete',
                            component: (<div>{ t('common.Delete') } </div>),
                            label    : t('common.Delete'),
                        },
                    ] }
                    onSelect={ handleDelete }
                />
            </WrapperPermissions>
            <div/>
            <If statement={ documentsData.documents.length }>
                { () => <KirokuPagination
                    className='pb-0'
                    disabledPrev={ 1 === documentsData.currentPage }
                    disabledNext={ documentsData.currentPage === documentsData.totalPage }
                    increasePage={ increasePage }
                    decreasePage={ decreasePage }
                    currentPage={ documentsData.currentPage }
                    totalPage={ documentsData.totalPage === 0 ? 1 : documentsData.totalPage }
                /> }
            </If>

        </div>
        <KirokuCardWeb>
            <div className="p-3">
                <div className='d-flex justify-content-between'>
                    <div className='d-flex'>
                        <KirokuDropdownClose
                            style={ { width: 150 } }
                            icon='far fa-calendar-alt text-secondary'
                            title={ t('Surveys.AllDate') }
                            items={ optionDateItems }
                            onSelect={ handleChoiceDate }
                        />
                        <div className='pr-2'/>

                        <KirokuDropdownClose
                            style={ { width: 160 } }
                            icon='fas fa-check-circle'
                            title={ t('Surveys.AllType') }
                            items={ optionFileTypeItems }
                            onSelect={ handleChoiceType }
                        />

                    </div>
                    <div className="pl-2">
                        <KirokuInputSearcher
                            value={ keywords }
                            onChange={ (event) => {
                                setKeywords(event.target.value);
                            } }
                            onSubmit={ () => {
                                handleSearchKeyword(keywords);
                            } }
                            id={ 'input-search-document-tab-web' }
                            placeholder={ t('common.Search') }/>
                    </div>
                </div>

                <KirokuLoader loading={ documentsData.loading }>
                    <If statement={ documentsData.documents.length }
                        otherwise={ <div className="pt-3 pb-5 fix-center">
                            <img src={ `/assets/img/empty-survey.svg` }
                                 alt="search fail"/>
                            <h6 className="pt-3" style={ { color: 'rgb(9, 30, 66)', fontSize: 20 } }>
                                <div>{ t('common.PleaseSelect') }</div>
                            </h6>
                        </div> }
                    >
                        { () => {
                            return <KirokuTable>
                                <THeader>
                                    <tr>
                                        <ThWithPermissions
                                            style={ { width: 50 } }
                                            resource-permission={ '|customerDetail|documentTab|CheckBoxSelectDocument|' }
                                        >
                                            <KirokuCheckBox
                                                onChange={ () => handleCheckAll() }
                                                checked={ lodash.findIndex(documentsData.documents, documentItem => !documentItem.isChecked) === -1 }
                                            />
                                        </ThWithPermissions>
                                        <th>{ t('common.Name') }</th>
                                        <th>{ t('common.Type') }</th>
                                        <th>{ t('common.DateCreated') }</th>
                                        <th className='text-center width-table-share-document'>{ t('common.Action') }</th>
                                    </tr>
                                </THeader>
                                <TBody>
                                    { documentsData.documents.map((document, index) => {
                                        return <tr key={ index }>
                                            <ThWithPermissions
                                                style={ { width: 50 } }
                                                resource-permission={ '|customerDetail|documentTab|CheckBoxSelectDocument|' }
                                            >
                                                <KirokuCheckBox
                                                    onChange={ () => handleSelect(document.id) }
                                                    checked={ !!document.isChecked }
                                                />
                                            </ThWithPermissions>
                                            <td>
                                                <div className={ 'width-table-document-name' }>
                                                    <div
                                                        style={ {
                                                            display   : 'flex',
                                                            alignItems: 'center',
                                                            height    : 40,
                                                        } }
                                                    >

                                                        <KirokuTogglePopover
                                                            popupVisible={document.isRenameMode}
                                                            TogglePopover={ <div>
                                                                    <span onClick={ () => displayModeEditName(document) }>
                                                                        <i className="fas fa-pencil-alt fa-sm icon-badge-edit"/>
                                                                    </span>
                                                                    <span style={{ paddingLeft: 15 }}>
                                                                       &nbsp; { document.name }
                                                                    </span>
                                                                    <If statement={ document.private && !isShareFromUserLogin(document.users) }>
                                                                        { () => <i className="pl-2 fa fa-lock"/> }
                                                                    </If>
                                                                </div>
                                                                }
                                                        >
                                                            <KirokuPopoverItem alignPopover={'top'} >
                                                                <div>
                                                                    <div style={ {
                                                                        display   : 'flex',
                                                                        alignItems: 'center',
                                                                    } }>

                                                                        <KirokuInput
                                                                            style={ { height: 29 } }
                                                                            maxLength={ 250 }
                                                                            value={ fileName }
                                                                            onChange={ (event) => {
                                                                                setFileName(event.target.value);
                                                                            } }
                                                                        />
                                                                        &nbsp;

                                                                        <span
                                                                            style={ {
                                                                                color : fileName.replace(/\s+/g, '') ? 'green' : '#00800082',
                                                                                cursor: fileName.replace(/\s+/g, '') ? 'pointer' : 'not-allowed',
                                                                            } }
                                                                            onClick={ () => {
                                                                                handleConfirmRenameFile(document);
                                                                            } }
                                                                        >
                                                                        <i className="fas fa-2x fa-check-circle"/>
                                                                       </span>
                                                                        <span
                                                                            className='ml-2'
                                                                            style={ {
                                                                                color : 'red',
                                                                                cursor: 'pointer',
                                                                            } }
                                                                            onClick={ () => {
                                                                                handleCancelRenameFile(document);
                                                                            } }
                                                                        >
                                                                            <i className="fas fa-2x fa-times-circle"/>
                                                                       </span>
                                                                    </div>
                                                                </div>
                                                            </KirokuPopoverItem>
                                                        </KirokuTogglePopover>
                                                    </div>

                                                </div>
                                            </td>
                                            <td style={ { minWidth: 80 } }>{ document.type }</td>
                                            <td style={ { whiteSpace: 'nowrap' } }>
                                                { moment.unix(document.create_at).format(t('dateTimeFormat.YearMonthDateWithHour')) }
                                            </td>
                                            <td>
                                                <div className="d-flex">
                                                    <If statement={ !document.private || loginUserInfo.role !== 'staff' || isShareFromUserLogin(document.users) }>
                                                        { () => <React.Fragment>
                                                            <a href={ `${ config.kirokuAPI.url }/v${ config.kirokuAPI.version }/download/${ document.id }?token=${ localStorage.getItem('token') }` }
                                                               style={ { margin: 'auto' } }>
                                                                <div className='pr-3' style={ { cursor: 'pointer' } }>
                                                                    <i className="fas fa-cloud-download-alt text-secondary"/>
                                                                    <span
                                                                        className='ml-1 text-dark'>{ t('common.Download') }</span>
                                                                </div>
                                                            </a>
                                                            <div/>
                                                            <div onClick={ () => previewFile(document.id) }
                                                                 className="pr-3"
                                                                 style={ { cursor: 'pointer', pointerEvents: `${ !config.allowFileType.includes(document.type) ? 'none' : 'stroke' }`, margin: 'auto' } }>
                                                                <i className={ `${ !config.allowFileType.includes(document.type) ? 'fas fa-binoculars tranfer-text' : 'fas fa-binoculars text-secondary' }` }/>
                                                                <span
                                                                    className={ `${ !config.allowFileType.includes(document.type) ? 'ml-1  tranfer-text' : 'ml-1 text-dark' }` }>{ t('common.Preview') }</span>
                                                            </div>
                                                        </React.Fragment> }
                                                    </If>

                                                    <div/>
                                                    <If statement={ document.private }
                                                        otherwise={
                                                            <WrapperPermissions
                                                                resource-permission={ '|customerDetail|documentTab|changeStatusDocument|' }
                                                            >
                                                                <div
                                                                    onClick={ () => switchDocumentToPublicFromPrivate(document.id) }
                                                                    className="p-3"
                                                                    style={ { cursor: 'pointer', pointerEvents: 'stroke', margin: 'auto' } }>
                                                                    <i className='fas fa-lock '/>
                                                                    <span
                                                                        className='ml-1 '>{ t('common.makePrivate') }</span>
                                                                </div>
                                                            </WrapperPermissions>
                                                        }
                                                    >
                                                        { () => {
                                                            return <React.Fragment>
                                                                <WrapperPermissions
                                                                    resource-permission={ '|customerDetail|documentTab|changeStatusDocument|' }
                                                                >
                                                                    <div
                                                                        onClick={ () => showModalConfirmToPrivateFromPublic(document.id) }
                                                                        className="p-3"
                                                                        style={ { cursor: 'pointer', pointerEvents: 'stroke', margin: 'auto' } }>
                                                                        <i className='fas fa-unlock '/>
                                                                        <span
                                                                            className='ml-1'>{ t('common.makePublic') }</span>
                                                                    </div>
                                                                </WrapperPermissions>
                                                                <WrapperPermissions
                                                                    resource-permission={ '|customerDetail|documentTab|changeStatusDocument|' }
                                                                >
                                                                    <div
                                                                        onClick={ () => handleShareDocument(document) }
                                                                        className="p-3"
                                                                        style={ { cursor: 'pointer', pointerEvents: 'stroke', margin: 'auto' } }>
                                                                        <i className='fas fa-share '/>
                                                                        <span
                                                                            className='ml-1 '>{ t('common.share') }</span>
                                                                    </div>
                                                                </WrapperPermissions>
                                                            </React.Fragment>;
                                                        } }
                                                    </If>
                                                </div>
                                            </td>
                                        </tr>;

                                    }) }
                                </TBody>
                            </KirokuTable>;
                        } }
                    </If>
                </KirokuLoader>
            </div>
        </KirokuCardWeb>

        <div className={ `d-flex justify-content-between pt-3 pb-3` }>
            <WrapperPermissions
                resource-permission={ '|customerDetail|documentTab|DropdownBulkAction|Delete|' }
            >
                <KirokuDropdownClose
                    location={ 'top' }
                    disabled={ lodash.findIndex(documentsData.documents, documentItem => documentItem.isChecked) === -1 }
                    style={ { width: 160 } }
                    title={ t('common.BulkAction') }
                    items={ [
                        {
                            value    : 'Delete',
                            component: (<div>{ t('common.Delete') } </div>),
                            label    : t('common.Delete'),
                        },
                    ] }
                    onSelect={ handleDelete }
                />
            </WrapperPermissions>
            <div/>
            <If statement={ documentsData.documents.length }>
                { () => <KirokuPagination
                    className='pb-0'
                    disabledPrev={ 1 === documentsData.currentPage }
                    disabledNext={ documentsData.currentPage === documentsData.totalPage }
                    increasePage={ increasePage }
                    decreasePage={ decreasePage }
                    currentPage={ documentsData.currentPage }
                    totalPage={ documentsData.totalPage === 0 ? 1 : documentsData.totalPage }
                /> }
            </If>
        </div>

        <ModalConfirm
            show={ modalInfo.name === 'CONFIRM_PUBLIC_DOCUMENT' && modalInfo.data }
            submit={ () => {
                changeStatusDocument(modalInfo.data.id, 0);
            } }
            closeModal={ () => {
                closeModalName();
            } }
        />

        <PreviewFileModal
            isShowModal={ modalInfo.name === 'PREVIEW_DOCUMENT_MODAL' && modalInfo.data }
            fileBePreview={ modalInfo.data }
            closeModal={ () => {
                closeModalName();
            } }
        />

        <ModalConfirmDelete
            documentSelected={ documentsData.documents.filter(doc => doc.isChecked) }
            show={ modalInfo.name === 'CONFIRM_DELETE_DOCUMENT' }
            handleAfterDelete={ () => {
                refreshDocument();
            } }
            closeModal={ () => closeModalName() }
        />

        <ModalSelectUser
            show={ modalInfo.name === 'SELECT_USER_BE_SHARE' }
            handleCloseModal={ () => closeModalName() }
            modalData={ modalInfo.data }
            handleShareDocument={ (users) => {
                handleShareDocumentToUser(modalInfo.data.document.id, users);
            } }
        />

        <ModalRenameFile
            show={modalInfo.name === 'RENAME_FILE'}
            handleCloseModal={() => closeModalName()}
            file={modalInfo.data}
            handleAfterRenameFile={() => {
                refreshDocument();
                closeModalName();
            }}
        />

    </div>;
}

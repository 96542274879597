import { useTranslation } from 'react-i18next';
import React              from 'react';
import { KirokuAge,
    KirokuStaffTagList,
    KirokuCertificate
}                         from '../../../../ui';
import * as moment        from 'moment';
import If                 from '../../../If';

export default function ProfileTabMobile({ customer }) {
    const { t } = useTranslation();
    return (
        <div>
            <div className='card padding-info'>
                <div className={'card-title-info mb-15px'}>{t('CustomerEditor.PersonalInformation')}</div>
                <div className='mb-15px'>
                    <span className='customer-info'>{t('common.Name')}:</span>
                    <br/>
                    <div className='personal-info pt-10px'>{customer.name}</div>
                </div>
                <div className='mb-15px'>
                    <span className='customer-info'>{t('common.Age')}:</span>
                    <br/>
                    <div className='personal-info pt-10px'>
                        <KirokuAge dob={customer.date_of_birth}/>
                    </div>
                </div>
                <div className='mb-15px'>
                    <span className='customer-info'>{t('CustomerEditor.BloodType')}:</span>
                    <br/>
                    <div className='personal-info pt-10px'>{(customer.blood_type === 'Unknown' ? t('CustomerEditor.Unknown') : customer.blood_type) || 'Unknown'}</div>
                </div>
                <div className='mb-15px'>
                    <span className='customer-info'>{t('CustomerEditor.PostCode')}:</span>
                    <br/>
                    <div className='personal-info pt-10px'>{customer.post_code}</div>
                </div>
                <div className='mb-15px'>
                    <span className='customer-info'>{t('common.Address')}:</span>
                    <br/>
                    <div className='personal-info pt-10px'>{customer.address}</div>
                </div>
                <div className='mb-15px'>
                    <span className='customer-info'>{t('CustomerEditor.CustomerGender')}:</span>
                    <br/>
                    <div className='personal-info pt-10px'>{t(`CustomerEditor.${customer.gender}`)}</div>
                </div>
                <div>
                    <span className='customer-info'>{t('common.Characteristic')}:</span>
                    <br/>
                    <div className='personal-info pt-10px'>{customer.characteristic}</div>
                </div>
            </div>
            <div className='mt-2'>
                <div className='card padding-info'>
                    <div className={'card-title-info mb-15px'}>{t('CustomerEditor.CustomerInformation')}</div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.TeamName')}:</span>
                        <br/>
                        <div className='personal-info pt-10px'>{customer.team ? customer.team.name : ''}</div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.StartDateOfContact')}:</span>
                        <br/>
                        <div className='personal-info pt-10px'>
                            {customer.contract_start ? moment.unix(customer.contract_start).format(t('dateTimeFormat.YearMonthDateWithDay')): ''}
                        </div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.EndDateOfContact')}:</span>
                        <br/>
                        <div className='personal-info pt-10px'>
                            { customer.contract_end ? moment.unix(customer.contract_end).format(t('dateTimeFormat.YearMonthDateWithDay')) : ''}
                        </div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.SupportClassification')}:</span>
                        <br/>
                        <div
                            className='personal-info pt-10px'>{(customer.classification === 0 ? t('CustomerEditor.NotApplicable') : customer.classification) || 0}</div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.StaffInCharge')}:</span>
                        <div className='personal-info pt-10px'>
                                <KirokuStaffTagList type={'users'} listTags={customer.users}/>
                             </div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('common.team')}:</span>
                        <div className='personal-info pt-10px'>
                                <KirokuStaffTagList type={'teams'} listTags={customer.teams}/>
                             </div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.KindOfCertificate')}:</span>
                        <div className='personal-info pt-10px'>
                                <KirokuCertificate certificate={customer.certificate.map(item => ({ ...item, key: item }))}/>
                            </div>
                    </div>
                </div>
            </div>

            <div className='mt-2 card padding-info'>
                <div className={'card-title-info mb-15px'}>{t('CustomerEditor.contact.ContactInformation')}</div>
                {customer.contacts.length ? customer.contacts.map((contact, index) => (
                        <div  key={index}>
                            <div className='mb-15px'>
                                <span className='customer-info'>{t('common.Name')}:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{contact.name}</div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{t('common.Relationship')}:</span>
                                <br/>
                                <div
                                    className='personal-info pt-10px'>{t(`CustomerEditor.contact.${contact.relationship}`)}</div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{t('common.Phone')}:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{contact.phone_number}</div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{t('common.Memo')}:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{contact.memo}</div>
                            </div>
                            <hr hidden={customer.contacts.length - 1 === index}/>
                        </div>
                    )) :
                    <div>
                        <div className='mb-15px'>
                            <span className='customer-info'>{t('common.Name')}:</span>
                            <br/>
                        </div>
                        <div className='mb-15px'>
                            <span className='customer-info'>{t('common.Relationship')}:</span>
                            <br/>
                        </div>
                        <div className='mb-15px'>
                            <span className='customer-info '>{t('common.Phone')}:</span>
                            <br/>
                        </div>
                        <div className='mb-15px'>
                            <span className='customer-info'>{t('common.Memo')}:</span>
                            <br/>
                        </div>
                    </div>}
            </div>

            {customer['certifications'].length ? customer['certifications'].map((certification, index) => {
                return (<div className='mt-2' key={index}>
                    <div className='card padding-info'>
                        <div className={'card-title-info mb-15px'}>{t('CustomerEditor.Certification')}</div>
                        <div className='mb-15px'>
                            <span className='customer-info'>{t('CustomerEditor.CertificationNumber')}:
                                <span className='personal-info'>&nbsp;&nbsp;{certification['certification_number']}</span>
                            </span>
                            <br/>
                            <div className='customer-info pt-10px'>{t('CustomerEditor.CertificationIssuer')}:
                                <span className='personal-info'>&nbsp;&nbsp;{certification.issuer}</span>
                            </div>
                            <div className='customer-info pt-10px'>{t('Customer.CustomerExpiration')}:
                                <span className='personal-info'>&nbsp;&nbsp;
                                    {certification.certification_expiration ? moment.unix(certification.certification_expiration).format(t('dateTimeFormat.YearMonth')) : ''}
                                </span>
                            </div>
                        </div>
                        <div>
                            <span className='customer-info'>{t('CustomerEditor.Service')}:</span>
                            <br/>
                            <ul>
                                <If statement={certification.services.length}>
                                    {()  => certification.services.map((service, index) => {
                                        return (
                                            <li key={index} className={'pt-2'}>
                                                <div className={'customer-info'}>{t('CustomerEditor.CertificationServiceName')}:
                                                    <span className='personal-info'>&nbsp;&nbsp;{service.name}</span>
                                                </div>
                                                <div className={'customer-info'}>{t('CustomerEditor.CertificationServiceStartDate')}:
                                                    <span className='personal-info'>&nbsp;&nbsp;
                                                        { service.startDate ? moment.unix(service.startDate).format(t('dateTimeFormat.YearMonthDateWithDay')) : ''}
                                                    </span>
                                                </div>
                                                <div className={'customer-info'}>{t('CustomerEditor.CertificationServiceEndDate')}:
                                                    <span className='personal-info'>&nbsp;&nbsp;
                                                        {service.endDate ? moment.unix(service.endDate).format(t('dateTimeFormat.YearMonthDateWithDay')) : ' '}
                                                    </span>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </If>
                            </ul>
                            <span className='customer-info'>{t('CustomerEditor.monitoringTerms')}:</span>
                            <ul className={'m-0'}>
                                <If statement={certification['monitoring_terms'].length}>
                                    {() => certification['monitoring_terms'].map((monitoring_term, index) => {
                                        return (
                                            monitoring_term.date && <li key={index} className={'customer-info'}>
                                                &nbsp;&nbsp;
                                                {moment.unix(monitoring_term.date).format(t('dateTimeFormat.YearMonth'))}
                                            </li>
                                        );
                                    })}
                                </If>
                            </ul>
                        </div>
                    </div>
                </div>);
            }) : <div className='mt-2'>
                <div className='card padding-info'>
                    <div className={'card-title-info mb-15px'}>{t('CustomerEditor.Certification')}</div>
                    <div className='mb-15px'>
                                <span
                                    className='customer-info'>{t('CustomerEditor.CertificationNumber')}:</span>
                        <br/>
                        <span
                            className='customer-info'>{t('CustomerEditor.CertificationIssuer')}:</span>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.Service')}:</span>
                        <br/>
                        <span className='customer-info'>{t('CustomerEditor.monitoringTerms')}:</span>
                    </div>
                </div>
            </div>
            }
            <div className='mt-2'>
                <div className='card padding-info'>
                    <div className={'card-title-info mb-15px'}>{t('CustomerEditor.Guardianship')}</div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.GuardianshipType')}:</span>
                        <br/>
                        <div className='personal-info pt-10px'>
                            <If statement={customer.guardianship_type}>
                                {() =>  t(`CustomerEditor.guardianshipTypes.${customer.guardianship_type}`)}
                            </If>
                        </div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.AppointedType')}:</span>
                        <br/>
                        <div className='personal-info pt-10px'>{customer.appointed_type || ''}</div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.CompetentCourt')}:</span>
                        <br/>
                        <div className='personal-info pt-10px'>{customer.competent_court || ''}</div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.DecisionDate')}:</span>
                        <br/>
                        <div className='personal-info pt-10px'>
                            <If statement={!!customer.decision_date }>
                                {() => moment.unix(customer.decision_date).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                            </If>
                        </div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.CaseNumber')}:</span>
                        <br/>
                        <div className='personal-info pt-10px'>{customer.case_number || ''}</div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.RegistrationNumber')}:</span>
                        <br/>
                        <div className='personal-info pt-10px'>{customer.registration_number || ''}</div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.PermanentAddress')}:</span>
                        <br/>
                        <div className='personal-info pt-10px'>{customer.permanent_address || ''}</div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.ResidenceCardAddress')}:</span>
                        <br/>
                        <div className='personal-info pt-10px'>{customer.residence_card_address || ''}</div>
                    </div>
                    <div className='mb-15px'>
                        <span className='customer-info'>{t('CustomerEditor.FacilityName')}:</span>
                        <br/>
                        <div className='personal-info pt-10px'>{customer.facility_name || ''}</div>
                    </div>
                    <div>
                        <span className='customer-info'>{t('CustomerEditor.ReportMonth')}:</span>
                        <br/>
                        <div className='personal-info pt-10px'>{customer.report_month || ''}</div>
                    </div>
                </div>
            </div>
            <div className="padding-responsive">
                <div style={ { marginBottom: 80 } }/>
            </div>
        </div>
    );
}

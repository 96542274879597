import React                   from 'react';
import ImageGallery            from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import useMediaChart           from './useMediaChart';
import NoAnswersFoundComponent from '../NoAnswersFoundComponent';

export default function MediaChart({ question, records, handleSelectAnswer}) {
    const chartData = useMediaChart(records, question);

    let checkChartWithOptionAnswers = chartData.filter(chart => chart.original);

    if (checkChartWithOptionAnswers.length) {
        return (
            <div style={ { textAlign: 'center'} }>
                <div style={ { paddingTop: 60 } }>
                    <ImageGallery items={ checkChartWithOptionAnswers } showPlayButton={ false }
                                  showFullscreenButton={ false } showNav={ false }
                                  onThumbnailClick={(e, index) => {
                                      const answerURL = checkChartWithOptionAnswers[index];
                                      handleSelectAnswer({type: question.type, content : question.content, answer : answerURL.thumbnail})
                                  }}
                    />
                </div>
            </div>
        );
    }
    return <NoAnswersFoundComponent/>;

}

import { useTranslation }            from 'react-i18next';
import { authentication, resources } from '@kiroku/api';
import { useEffect, useState }       from 'react';
import { useGetTeamTagByUserId }     from './DropdownSelectTeamTag';
import { useGetCustomerTagByUserId } from './DropdownSelectCustomerTag';
import { conditionUpload }           from './validateChecker';
import { useHistory }                from 'react-router-dom';
import useNotification         from '../../Layout/useNotification';
import { useMixpanelTracking } from '../../../Mixpanel/useMixpanelTracking';

export default function useChangeInfoUser(resultUser, inputElement) {
    const { t }                                               = useTranslation();
    const { user, updateProfile }                             = authentication.useAuthenticatedContext();
    const [resultUploadAvatar, uploadAvatar, isLoadingAvatar] = resources.useUpload('User');
    const { teamsBeingTagUser }                               = useGetTeamTagByUserId(resultUser.id);
    const { customersBeingTagUser }                           = useGetCustomerTagByUserId(resultUser.id);
    const updateUserData                                      = resources.useUpdate('User')[ 1 ];

    const history                       = useHistory();
    const { notification }              = useNotification();


    const [profileData, setProfileData] = useState({
        resultUploadAvatar,
        title        : resultUser.title,
        name         : resultUser.name,
        role         : resultUser.role,
        qualification: resultUser.qualification.map(trans => {
            return {
                key  : t(`common.dropdown.default.${trans}`),
                label: t(`common.dropdown.${trans}`),
                value: t(`common.dropdown.${trans}`),
            };
        }),
        working_form : resultUser.working_form,
        avatar       : resultUser.avatar,

    });

    const [teamTags, setTeamTags]         = useState([]);
    const [customerTags, setCustomerTags] = useState([]);

    const { trackingMixpanel }            = useMixpanelTracking();

    useEffect(() => {
        setTeamTags(teamsBeingTagUser.map(itm => {
            return {
                id  : itm.id,
                name: itm.name,
            };
        }));
    }, [teamsBeingTagUser]);

    useEffect(() => {
        setCustomerTags(customersBeingTagUser.map(itm => {
            return {
                id  : itm.id,
                name: itm.name,
            };
        }));
    }, [customersBeingTagUser]);


    const handleChangeQualification = (options) => {
        setProfileData({
            ...profileData,
            qualification: options,
        });
    };

    const handleChangeImage = () => {
        if (inputElement.current.files[ 0 ] && conditionUpload(inputElement.current.files[ 0 ])) {
            let image = new FormData();
            image.append('file', inputElement.current.files[ 0 ]);
            image.append('name', inputElement.current.files[ 0 ].name);
            uploadAvatar('public', image).then((res) => {
                setProfileData({
                    ...profileData,
                    avatar: res.url,
                });
            });
        }

    };


    const handleSubmitUser    = () => {
        const userDataUpdate = {
            teams        : teamTags,
            customers    : customerTags,
            name         : profileData.name,
            title        : profileData.title,
            role         : profileData.role,
            working_form : profileData.working_form,
            avatar       : profileData.avatar,
            qualification: profileData.qualification.map(itm => itm.key),
        };
        updateUserData(resultUser.id, userDataUpdate).then((res) => {
            if(res.id === user.id) {
                updateProfile(res);
            }
            notification(t('message.UpdateSuccess'), 'success');
            history.push('/users/'.concat(res.id));
        });
        trackingMixpanel('STAFF_UPDATED', userDataUpdate);
    };
    const handleOnChangeTeams = (tagSelected) => {
        setTeamTags(tagSelected);
    };

    const handleOnChangeCustomers = (tagSelected) => {
        setCustomerTags(tagSelected);
    };

    const updateInfoUser = (target) => {
        setProfileData({
            ...profileData,
            [ target.name ]: target.value,
        });
    };
    return {
        handleChangeQualification,
        updateInfoUser, handleOnChangeCustomers, handleOnChangeTeams,
        handleSubmitUser, handleChangeImage, isLoadingAvatar, user, profileData,
    };
}

import React              from "react";
import { useTranslation } from "react-i18next";

export const useRelationship = () => {

    const {t} = useTranslation();
    const relationshipType = [
        {
            id: 1,
            type: 'unknown'
        },
        {
            id: 2,
            type: 'self'
        },
        {
            id: 3,
            type: 'father'
        },
        {
            id: 4,
            type: 'mother'
        },
        {
            id: 5,
            type: 'brotherOrSister'
        },
        {
            id: 6,
            type: 'family'
        },
        {
            id: 7,
            type: 'other'
        }
    ];

    const selectRelationship = (relationship) => {
        return relationship.map(relationship => ({
            value: relationship.type,
            component: (
                <div>
                    <span className={'text-muted'}>{t(`CustomerEditor.contact.${relationship.type}`)}</span>
                </div>
            ),
            label: t(`CustomerEditor.contact.${relationship.type}`)
        }))
    };


    return [ selectRelationship(relationshipType) ]
}

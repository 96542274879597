import { useClient }   from '@kiroku/api';
import { useCallback } from 'react';

export const useDeleteRecord = () => {
    const client = useClient();
    const deleteRecords = useCallback((id, customer) => {
        return client[ 'axios' ]
            .delete(`records/${id}?customerId=${customer.id}&customerName=${customer.name}`)
            .then((response) => {
                return response;
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }, [client])
    return {
        deleteRecords
    }
}

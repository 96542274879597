import React              from 'react';
import AnalysisCondition  from './AnalysisCondition';
import AnalyticalSystem                   from './AnalyticalSystem';
import { ComponentMobile, KirokuCardWeb } from '../../ui';
import SearchProvider                     from './SearchSystem/SearchProvider';
import useSearch          from './useSearch';
import KirokuLoader       from '../../ui/KirokuLoader';
import { useTranslation } from 'react-i18next';
import './style.css';
import usePageTitle       from '../../ui/v3/hooks/usePageTitle';

function AnalysisWarpperSearch() {
    const {
              startDate, endDate, handleOnChangeDateTime,
              handleSelectCustomer, handleSelectSurvey,
              customers, survey, result, recordsByDate, loading,
              updaters, creators, handleSelectUpdater, handleSelectCreator
          }     = useSearch();
    const { t } = useTranslation();

    return (<>
        <AnalysisCondition
            startDate={startDate}
            endDate={endDate}
            handleOnChangeDateTime={handleOnChangeDateTime}

            customers={customers}
            handleSelectCustomer={handleSelectCustomer}

            survey={survey}
            handleSelectSurvey={handleSelectSurvey}

            updaters={updaters}
            handleSelectUpdater={handleSelectUpdater}

            creators={creators}
            handleSelectCreator={handleSelectCreator}
        />
        <hr/>
        <KirokuLoader loading={loading}>
            {
                result.records && result.records.length ? <AnalyticalSystem
                        startDate={startDate}
                        endDate={endDate}
                        records={result.records}
                        surveyTemplate={result.survey[ 0 ]}
                        survey={survey}
                        recordsByDate={recordsByDate}
                        customerCondition={customers}
                        updaters={updaters}
                    /> :
                    <div className="pt-3 pb-5 text-center">
                        <img src={`/assets/img/empty-survey.svg`}
                             alt="search fail"/>
                        <h6 className="pt-3" style={{ color: 'rgb(9, 30, 66)' }}>
                            <div style={{fontWeight: 500}} hidden={customers.length && survey.id}>{t('NullPage.record')}</div>
                            <div style={{fontWeight: 500}} hidden={(!survey.id && !customers.length) || (customers.length && survey.id)}>{t('common.PleaseSelect')}</div>
                            <div style={{fontWeight: 500}} hidden={(!customers.length) || (!survey.id)}>{t('common.NoAnalysis')}</div>
                        </h6>
                    </div>
            }
        </KirokuLoader>

    </>);
}

export default function Analysis(props) {
    const { t } = useTranslation();
    usePageTitle(t(`Layout.Analysis`));

    const stateLocationUrl = props.location.state;
    const survey           = {
        id   : (stateLocationUrl && stateLocationUrl.survey) ? stateLocationUrl.survey.id : null,
        title: (stateLocationUrl && stateLocationUrl.survey) ? stateLocationUrl.survey.title : '',
    };
    const customerId       = (stateLocationUrl && stateLocationUrl.customer) ? stateLocationUrl.customer.id : null;
    const customerName     = (stateLocationUrl && stateLocationUrl.customer) ? stateLocationUrl.customer.name : '';
    const customer         = {
        id        : customerId,
        name      : customerName,
        key       : `Customer_${customerId}`,
        value     : `${customerName}${customerId}`,
        label     : customerName,
        customerId: customerId,
        tagType   : 'customer',
        type      : 'Customers',
    };

    const dateRange = {
        startDate: (stateLocationUrl && stateLocationUrl.plans) ? stateLocationUrl.plans.startDate : null,
        endDate: (stateLocationUrl && stateLocationUrl.plans) ? stateLocationUrl.plans.endDate : null,
    }

    return (
        <div className={'padding-responsive'}>
            <KirokuCardWeb>
                <SearchProvider survey={survey} customer={customer} dateRange={dateRange}>
                    <AnalysisWarpperSearch/>
                </SearchProvider>
            </KirokuCardWeb>
            <ComponentMobile>
                <div style={ { marginBottom: 80 } }/>
            </ComponentMobile>
        </div>
    );
}

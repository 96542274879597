import React, {Component} from 'react';

export default class CheckDivide extends Component {

    state = {
        width: window.innerWidth,
    };

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth});
    };

    render() {
        const {width}  = this.state;
        const isMobile = width <= 768;
        if (isMobile) {
            return (
                <div className={this.props.class}>
                    {this.props.mobile}
                </div>
            );
        } else {
            return (
                <div>
                    {this.props.web}
                </div>
            );
        }
    }
}
import React              from 'react';
import {
    KirokuTable, TBody,
    THeader,
}                         from '../../../ui';
import { useTranslation } from 'react-i18next';
import FormatTimestamp    from '../CommonComponent/FormatTimestamp';

export default function CustomerTable({ users, handleChangeOrderBy, sort_by, order_by_type }) {
    const { t } = useTranslation();
    return (<div>
        <KirokuTable>
            <THeader>
                <tr>
                    <th className="deleted-list-th">{t('DeletedList.customerName')}</th>
                    <th className="deleted-list-th">
                        <div className={'d-flex cursor-pointer'}
                             style={{paddingLeft: 30}}
                             onClick={() => handleChangeOrderBy('delete_at')}>
                            <div>{t('DeletedList.deletedAtField')}</div>
                            <div className="p-1"/>
                            <div className={`icon-order 
                                                    ${sort_by === 'delete_at' ? 'color-blue-icon-order' : ''}`
                            }>
                                <i hidden={order_by_type.delete_at === 'DESC'} className="fas fa-lg fa-angle-up"/>
                                <i hidden={order_by_type.delete_at === 'ASC'} className="fas fa-lg fa-angle-down"/>
                            </div>
                        </div>
                    </th>
                    <th className="deleted-list-th">
                        <div className={'d-flex cursor-pointer'}
                             style={{paddingLeft: 30}}
                             onClick={() => handleChangeOrderBy('create_at')}
                        >
                            <div>{t('DeletedList.createdAtField')}</div>
                            <div className="p-1"/>
                            <div className={`icon-order 
                                                    ${sort_by === 'create_at' ? 'color-blue-icon-order' : ''}`
                            }>
                                <i hidden={order_by_type.create_at === 'DESC'} className="fas fa-lg fa-angle-up"/>
                                <i hidden={order_by_type.create_at === 'ASC'} className="fas fa-lg fa-angle-down"/>
                            </div>
                        </div>
                    </th>
                </tr>
            </THeader>
            <TBody>
                {
                    users.map((user, index) => {
                        return (
                            <tr key={index}
                                className={`${index === users.length - 1 ? 'border-bottom-none' : 'border-bottom'}`}>
                                <td className={'color-blue title-table-del-name'}>{user.name}</td>
                                <td className={'width-table-create-del-at title-table-del field-date-time-del'}>
                                    <FormatTimestamp timestampUnix={user.delete_at} isAlign={true}/></td>
                                <td className={'width-table-create-del-at title-table-del field-date-time-del'}>
                                    <FormatTimestamp timestampUnix={user.create_at} isAlign={true}/></td>
                            </tr>
                        );
                    })
                }
            </TBody>
        </KirokuTable>
    </div>);
}

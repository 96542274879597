import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';
import { KirokuInputCode } from '../../ui';
import { Link, Redirect }  from 'react-router-dom';
import usePageTitle        from '../../ui/v3/hooks/usePageTitle';

export default function SupportPage() {

    const { t } = useTranslation();

    const [supportID, setSupportID] = useState({ inputNumFirst: '', inputNumSecond: '', inputNumThird: '' });

    const getInputFocus = (inputNumber) => {
        if (inputNumber === 'inputNumFirst') return 'inputNumSecond';
        if (inputNumber === 'inputNumSecond') return 'inputNumThird';
        if (inputNumber === 'inputNumThird') return 'inputNumFirst';
    };
    usePageTitle(t(`Layout.supportPage`));

    const handleChangeInput = (name, value) => {

        if (value.replace(/[^0-9]/g, '').length === 3) {
            document.getElementById(getInputFocus(name)).focus();
        }

        if (value.length < 4) {
            setSupportID({
                ...supportID,
                [ name ]: value.replace(/[^0-9]/g, ''),
            });
        }


    };

    return <div className='page-support'>
        <div className='box-login'>
            <div className='tile'>
                <div className='heading-support-page'>
                    <div className='heading-support-page-form'>
                        {t('common.KirokuSupport')}
                    </div>
                    <div className='sub-title-support-page-form'>
                        <span>
                            {t('common.numberConnect')}
                        </span>
                    </div>
                </div>
                <div className='card-login'>
                    <div className='support-page-header padding-header-support-page pt-5'>
                        <div className='d-flex justify-content-center'>
                            <KirokuInputCode name='inputNumFirst' autoFocus
                                             style={{ textAlign: 'center', width: '90px' }}
                                             id='inputNumFirst' maxLength={3} value={supportID.inputNumFirst}
                                             onChange={(event) => {
                                                 handleChangeInput(event.target.name, event.target.value);
                                             }}/>
                            &mdash;
                            <KirokuInputCode name='inputNumSecond' style={{ textAlign: 'center', width: '90px' }}
                                             id='inputNumSecond'
                                             readOnly={!(supportID.inputNumFirst.length === 3)}
                                             maxLength={3} value={supportID.inputNumSecond}
                                             onChange={(event) => {
                                                 handleChangeInput(event.target.name, event.target.value);
                                             }}/>
                            &mdash;
                            <KirokuInputCode name='inputNumThird' style={{ textAlign: 'center', width: '90px' }}
                                             maxLength={3} id='inputNumThird'
                                             value={supportID.inputNumThird}
                                             readOnly={!(supportID.inputNumSecond.length === 3)}
                                             onChange={(event) => {
                                                 handleChangeInput(event.target.name, event.target.value);
                                             }}/>
                        </div>
                    </div>
                    <div className='support-page-body'>
                        <div className="height-body-support-page">
                            <div className="submit-container clearfix pt-5" >
                                {supportID.inputNumFirst.length === 3 && supportID.inputNumSecond.length === 3 && supportID.inputNumThird.length === 3 ?
                                    <button className="btn btn-connect-active" onClick={() => {
                                        window.open(`https://surfly.io/${supportID.inputNumFirst}-${supportID.inputNumSecond}-${supportID.inputNumThird}`);
                                    }}>
                                        {t('common.connect')}
                                    </button> :
                                    <button className={'btn btn-connect-disabled'}>
                                        {t('common.connect')}
                                    </button>
                                }
                            </div>
                            <div className={'pt-3'}>
                                <div className="hr-login">
                                    <span className='hr-login-content'>{t('common.Back')}</span>
                                </div>
                            </div>
                            {localStorage.getItem('token') ?
                                <div style={{ textAlign: 'center' }} className={'pt-2'}
                                     onClick={() => <Redirect to={'/ '}/>}>
                                    <Link
                                        to={'/ '}>{t('common.BackHome')}</Link>
                                </div> :
                                <div style={{ textAlign: 'center' }} className={'pt-2'}>
                                    <Link onClick={() => {
                                    }}
                                          to={'/login'}>{t('common.loginWithKirokuAI')}</Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>;
}

import React                from 'react';
import { useTranslation }   from 'react-i18next';
import If                   from '../../If';
import { useScrollContext } from '../../../ui/Scrolling';

export default function NextQuestion({ nextQuestion }) {
    const { t }        = useTranslation();
    const { scrollTo } = useScrollContext();

    return (
        <If statement={typeof nextQuestion === 'number'}>
            {() => <p className='text-info scroll-link-question' style={{ margin: 0 }}
                      onClick={() => scrollTo(`question-survey-review-${nextQuestion}`, 202)}
            >
                <i className='fas fa-arrow-right text-info'>
                    &nbsp; {t('createRecord.Question')}: {nextQuestion + 1}
                </i>
            </p>}
        </If>

    );
}

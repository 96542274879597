import KirokuModal          from '../../../../ui/KirokuModal';
import React, { useEffect } from 'react';
import {
    KirokuButton, KirokuModalBody,
    KirokuModalHeader,
}                              from '../../../../ui';
import KirokuModalFooter       from '../../../../ui/KirokuModalFooter';
import { useTranslation }      from 'react-i18next';
import useAttachTemplate       from './makeTemplate/useAttachTemplate';
import TemplateAttached        from './TemplateAttached';
import TemplateUnAttached      from './TemplateUnAttached';
import { resources }           from '@kiroku/api';
import useNotification         from '../../../Layout/useNotification';
import { useMixpanelTracking } from '../../../../Mixpanel/useMixpanelTracking';

export default function ModalAddSurveyTemplate(props) {
    const { show, support, onClose, loadSupportDetail } = props;
    const { t }                                         = useTranslation();
    const {
              setInitStatus, attachStatus,
              changeAttachStatus, handleResetState,
              templateAttachedList, templateUnAttachedList
          }                                             = useAttachTemplate();

    const { trackingMixpanel } = useMixpanelTracking();

    const update           = resources.useUpdate('Support')[1];
    const { notification } = useNotification();

    useEffect(() => {
        if (show) {
            setInitStatus('attach');
        }
    }, [show, setInitStatus]);

    const closeModal = () => {
        handleResetState();
        onClose();
    };

    const handleSubmit = () => {
        let surveyAttached = templateAttachedList.filter(template => template.isChecked).map(survey => {
            return {
                id   : survey.id,
                title: survey.title,
            };
        });

        let surveyUnAttached = templateUnAttachedList.filter(template => template.isChecked).map(survey => {
            return {
                id   : survey.id,
                title: survey.title,
            };
        });
        let makeSupportData  = {
            target      : support.target,
            description : support.description,
            term        : support.term,
            priority    : support.priority,
            customer_id : support.plan.customer_id,
            assignment  : surveyAttached,
            unassignment: surveyUnAttached,
            plan_id     : support.plan.id,
            needs       : support.needs,
        };
        update(support.id, makeSupportData).then(() => {
            onClose();
            notification(t('message.UpdateSuccess'), 'success');
            loadSupportDetail();
            trackingMixpanel('QUICK_ADD_TEMPLATE_TO_SUPPORT', {...makeSupportData, support_id: support.id})
        }).catch((error) => {
            console.log(error);
            notification(t('message.UpdateFail'), 'danger');
        });
    };


    return (
        <KirokuModal show={ show }>
            <KirokuModalHeader>
                <div className={'header-quick-add'}>{ t('common.surveyTemplate') }</div>
            </KirokuModalHeader>
            <KirokuModalBody>
                <div className="p-3">
                    <div className={ 'd-flex' }>
                        <div
                            className={ `border-status-quick-add cursor-pointer font-text-on-quick-add-template attached-button ${ attachStatus === 'attach' && 'quick-add-active' }` }
                            onClick={ () => {
                                changeAttachStatus('attach');
                            } }
                        >
                            {t('plans.attached')}
                        </div>
                        <div
                            className={ `border-status-quick-add cursor-pointer font-text-on-quick-add-template unAttach-button ${ attachStatus === 'unAttach' && 'quick-add-active' }` }
                            onClick={ () => {
                                changeAttachStatus('unAttach');
                            } }
                        >
                            {t('plans.unAttached')}
                        </div>
                    </div>
                    <TemplateAttached show={ attachStatus === 'attach' }
                                      customerId={ support.plan.customer.id }/>
                    <TemplateUnAttached show={ attachStatus === 'unAttach' }/>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={ closeModal } color={ 'light' }>
                    { t('plans.cancel') }
                </KirokuButton>
                <div>
                    <KirokuButton color={ 'primary' }
                                  onClick={ () => {
                                      handleSubmit();
                                  } }
                    >
                        { t('plans.acceptAddSurvey') }
                    </KirokuButton>
                </div>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

import { useCallback, useState } from 'react';
import { resources }             from '@kiroku/api';

export default function useSearchTeam() {
    const { search }        = resources.useSearch('Team');
    const [teams, setTeams] = useState([]);

    const searchTeams = useCallback(() => {
        search({ type: 'all' }).then((res) => {
            setTeams(res.result)
        });
    }, [search]);

    return { teams, searchTeams };
}

import useQuestions           from '../MakeSurvey/useQuestions';
import React                  from 'react';
import { EditorQuestionForm } from './Editor';

export function Questions() {
    const { questions, update } = useQuestions();

    return questions.map((question, index) => {
        return <div
            key={index}
            className="pt-3"
        >
            <EditorQuestionForm
                question={question}
                setQuestion={(eventChangeQuestion) => {
                    update(eventChangeQuestion);
                }}
            />
        </div>;
    });
}

import { useCallback } from 'react';

export function useItemPaginator() {
    const getItems = useCallback((items) => {
        let arrayItems = [];
        let i          = 1;
        while (i <= items) {
            arrayItems.push({
                value: i,
                name : i,
            });
            i++;
        }
        return arrayItems;
    }, []);
    return { getItems };
}

import { useCallback, useState } from 'react';
import { authentication, resources }        from '@kiroku/api';


export default function useLockEditRecord(resourceName) {

    const [infoLocking, setInfoLocking]     = useState(null);
    const [canIsFixIt, setCanIsFixIt]       = useState(false);
    const profile                           = authentication.useAuthenticatedContext().user;
    const [lock]                            = resources.useLockTake(resourceName);
    const [unlock]                          = resources.useUnlockTake(resourceName);
    const [isDisplayAskReload, setDisplayAskReload] = useState(false)
    const [previousLock, setPreviousLock]   = useState(null)
    const [isCalling, setIsCalling]         = useState(true)

    const registerLock = useCallback((id) => {
        if(!isCalling) {
            return ;
        }
        lock(id).then(lockDetail => {
            if((profile.id === lockDetail.user_id) !== canIsFixIt) {
                setInfoLocking(lockDetail);
                setCanIsFixIt(profile.id === lockDetail.user_id);
            }

            if(lockDetail.previous_lock && lockDetail.previous_lock.user_id !== lockDetail.user_id) {
                setDisplayAskReload(true)
                setPreviousLock(lockDetail.previous_lock)
            }
        }).catch(err => {
            //todo add handle error
            setIsCalling(false)
            console.log(err);
        });
    }, [lock, profile, canIsFixIt, isCalling]);

    const unRegisterLock = useCallback((id) => {
        if(profile.id === infoLocking.user_id) {
            unlock(id).then(() => {
                setInfoLocking(null);
                setCanIsFixIt(true);
            }).catch(err => {
                console.log(err);
            });
        }
    }, [unlock, profile, infoLocking]);


    const lockAPI = useCallback((id) => {
        lock(id).then(lockDetail => {
            setInfoLocking(lockDetail);
            setCanIsFixIt(profile.id === lockDetail.user_id);
        }).catch(err => {
            console.log(err)
        })
    }, [lock, profile])

    const comebackCalling = useCallback(() => {
        if(!isCalling) {
            setIsCalling(true)
        }
    }, [isCalling])

    return {
        infoLocking,
        canIsFixIt,
        registerLock,
        unRegisterLock,
        isDisplayAskReload,
        setDisplayAskReload,
        previousLock,
        lockAPI,
        comebackCalling
    };
}

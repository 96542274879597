import { useCallback }      from 'react';
import {
    HANDLE_RESET_CONDITION,
    SEARCH_ORDER_CONDITION,
    SEARCH_ORDER_CONDITION_MOBILE, SEARCH_PAGINATOR_CONDITION,
    SET_DATE_RANGE,
} from './ConditionBuilder/RecordReducer';
import { useRecordContext } from './ConditionBuilder/RecordContext';

export function useCondition() {
    const { recordCondition, dispatch } = useRecordContext();
    const {
              status, selectedCustomer, contain_read,
              serviceStartDate, serviceEndDate, udId,
              updateStartDate, updateEndDate, cId,
              tId, order_by, sort_by, answer_search, page,
          }                             = recordCondition;

    const setDateRange = (dateRange) => {
        dispatch({
            type: SET_DATE_RANGE,
            dateRange,
        });
    };

    const searchOrderCondition = useCallback((sort_by, order_by) => {
        dispatch({
            type: SEARCH_ORDER_CONDITION,
            order_by,
            sort_by,
        });
    }, [dispatch]);

    const resetConditionRecords = useCallback((customerDefault) => {
        dispatch({
            type: HANDLE_RESET_CONDITION,
            customerDefault,
        });
    }, [dispatch]);

    const searchOrderConditionMobile = useCallback((sort_by, order_by) => {
        dispatch({
            type: SEARCH_ORDER_CONDITION_MOBILE,
            sort_by,
            order_by,
        });
    }, [dispatch]);

    const updateRecordCondition = useCallback((conditions) => {
        dispatch({
            type: 'UPDATE_RECORD_CONDITION',
            conditions,
        });
    }, [dispatch]);

    const setPaginatorCondition = useCallback((page) => {
        dispatch({
            type: SEARCH_PAGINATOR_CONDITION,
            page
        })
    }, [dispatch]);

    return {
        updateRecordCondition,
        status,
        answer_search,
        cId,
        udId,
        selectedCustomer,
        contain_read,
        tId,
        serviceStartDate,
        serviceEndDate,
        updateStartDate,
        updateEndDate,
        setDateRange,
        order_by,
        sort_by,
        searchOrderCondition,
        resetConditionRecords,
        searchOrderConditionMobile,
        page,
        recordCondition,
        setPaginatorCondition
    };
}

import React, { useState }                                                      from 'react';
import { KirokuCardBodyWeb, KirokuCardWeb, KirokuInput, KirokusSortableHandle } from '../../../ui';
import ActionGroupBtn                                                           from './ActionGroupBtn';
import QuestionCardTitle                                                        from './QuestionTitleCard';
import InfoQuestion                                                             from './InfoQuestion';
import CheckboxUnrequited                                                       from './CheckboxUnrequited';
import NextQuestion                                                             from './NextQuestion';
import useQuestions                                                             from '../MakeSurvey/useQuestions';
import { modalSetLogic }                                                        from '../ModalSetLogicByQuestionType';
import ModalEditQuestion
                                                                                from '../FormQuestion/ModalEditQuestion';

export default function Number({ question }) {

    const { update, copy, delQuestion }                         = useQuestions();
    const ModalSetLogic                                         = modalSetLogic[question.type];
    const [isOpenModalEditQuestion, setIsOpenModalEditQuestion] = useState(false);
    const [isOpenModalName, setIsOpenModalName]                 = useState(null);

    return (
        <div id='component-question'>
            <div id='web-q' className='pb-3'>
                <KirokuCardWeb>
                    <KirokusSortableHandle>
                        <QuestionCardTitle
                            index={question.index}
                            isLogic={typeof question.nextQuestion === 'number'}
                        >
                            <ActionGroupBtn
                                handleOpenModalAddLogic={() => {
                                    setIsOpenModalName(question.index);
                                }}
                                handleOpenModalEdit={() => {
                                    setIsOpenModalEditQuestion(true);
                                }}
                                question={question}
                                resetDefaultAnswer={() => {
                                    if (question.defaultAnswer !== undefined) {
                                        delete question.defaultAnswer;
                                        update(question);
                                    }
                                }}
                                handleDel={() => delQuestion(question.index)}
                                handleCopy={() => {
                                    copy(question);
                                }}
                            />
                        </QuestionCardTitle>
                    </KirokusSortableHandle>

                    <KirokuCardBodyWeb>
                        <CheckboxUnrequited
                            question={question}
                            isNotUse={typeof question.nextQuestion === 'number'}
                        />
                        <InfoQuestion question={question}/>
                        <div className={'pb-10px'}>
                            <NextQuestion nextQuestion={question.nextQuestion}/>
                        </div>
                        <KirokuInput value={question.defaultAnswer || ''}
                                     onChange={(event) => {
                                         let value = event.target.value.replace(/(?!^-)[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                         update({
                                             ...question,
                                             defaultAnswer: value,
                                         });
                                     }}
                        />
                    </KirokuCardBodyWeb>
                </KirokuCardWeb>

                <ModalEditQuestion
                    isOpenModalEditQuestion={isOpenModalEditQuestion}
                    handleAction={() => setIsOpenModalEditQuestion(!isOpenModalEditQuestion)}
                    question={question}
                    updateQuestion={(questionData) => update(questionData)}
                />

                <ModalSetLogic
                    question={question}
                    isOpen={isOpenModalName === question.index}
                    confirm={() => {
                        setIsOpenModalName(null);
                    }}
                    cancellation={() => setIsOpenModalName(null)}
                />
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import CustomerForm                   from '../CustomerForm/CustomerForm';
import CustomerProvider               from '../MakeCustomer/CustomerProvider';
import { resources }                  from '@kiroku/api';
import { useHistory }                 from 'react-router-dom';
import CustomerFactory                from '../MakeCustomer/CustomerFactory';
import { useTranslation }             from 'react-i18next';
import useNotification                from '../../Layout/useNotification';
import { useMixpanelTracking }        from '../../../Mixpanel/useMixpanelTracking';

export default function CustomerCreator() {

    const [ resultCustomer, create ] = resources.useCreate('Customer');
    const history = useHistory();
    const { t }                             = useTranslation();
    const { notification }                  = useNotification();
    const { trackingMixpanel }              = useMixpanelTracking();
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        if (resultCustomer) {
            notification(t('message.UpdateSuccess'), 'success');
            history.push('/customers/'.concat(resultCustomer.id));
        }
    }, [ resultCustomer, history, notification, t ]);

    const factory = new CustomerFactory();
    return (
        <CustomerProvider>
            <CustomerForm
                disabledButton={disabledButton}
                submit={(customer) => {
                    const customerRequestData = factory.make(customer);
                    setDisabledButton(true);
                    create(customerRequestData).then(() => {
                        setDisabledButton(false);
                    });
                    trackingMixpanel('CUSTOMER_CREATED', customerRequestData);
                }}
            />
        </CustomerProvider>
    )

}

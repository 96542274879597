import { useCallback, useEffect, useState } from 'react';
import { resources }                        from '@kiroku/api';

export function useDropdownSurveyDefault() {
    const { search, result, cancelAPISearch } = resources.useSearch('Template');
    const [surveyTagsDefault, setSurveyTagsDefault] = useState([]);
    const getSurveysDefault  = useCallback((id) => {
        search({ type: 'id', id });
    }, [search]);

    useEffect(() => {
        if (result.length) {
            setSurveyTagsDefault(result.map(itm => {
                return {
                    ...itm,
                    key  : `SurveyList_${itm.id}`,
                    label: itm.title,
                    value: `${itm.title}${itm.id}`,
                    type : 'SurveyList',
                    id   : itm.id,
                };
            }))
        }
    }, [result])

    return {surveyTagsDefault, getSurveysDefault, cancelAPISearch};
}

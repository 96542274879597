import YesNo               from './YesNo';
import Single              from './Single';
import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';

import {
    KirokuModal,
    KirokuButton, KirokuModalHeader,
    KirokuModalBody, KirokuModalFooter,
    KirokuDropdownResetLabelSelected,
}                                 from '../../../ui';
import useQuestions               from '../MakeSurvey/useQuestions';
import { getHeadingNextQuestion } from '../../Records/sevicesInRecord';

export function getDropDownItems(questionCurrent, questions) {
    return questions.map((question) => {
        return {
            value    : question.index,
            component: (<div>
                <div><span className={'text-muted'}>{question.content}</span></div>
            </div>),
            label    : question.content,
            style    : question.index <= questionCurrent.index ? { backgroundColor: '#e8ecf1', cursor: 'not-allowed' } : {},
        };
    });
}

function ModalAddLogicGeneral({ isOpen, question, confirm, cancellation }) {
    const { t }                           = useTranslation();
    const { update, questions }           = useQuestions();
    const defaultNextQuestion             = {
        id   : question.nextQuestion,
        label: getHeadingNextQuestion(question, questions),
    };
    const [nextQuestion, setNextQuestion] = useState(defaultNextQuestion);

    return (
        <KirokuModal show={isOpen}>
            <KirokuModalHeader color={'card-web'}>
                {t('createSurvey.QuestionOrder', { index: question.index + 1 })}
            </KirokuModalHeader>
            <KirokuModalBody>
                <div className='p-3'>
                    <b>{question.heading}</b>
                    <p>{question.content} </p>

                    <KirokuDropdownResetLabelSelected
                        title={nextQuestion.label}
                        items={getDropDownItems(question, questions)}
                        onSelect={(event) => {
                            if (event.kirokuSelected.value > question.index) {
                                setNextQuestion({
                                    id   : event.kirokuSelected.value,
                                    label: event.kirokuSelected.label,
                                });
                            }
                        }}/>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton
                    disabled={!nextQuestion.id}
                    onClick={() => {
                        confirm();
                        delete question.unrequited;
                        update({
                            ...question,
                            nextQuestion: nextQuestion.id,
                        });
                    }}
                    color={'primary'}>
                    {t('createSurvey.Confirm')}
                </KirokuButton>
                <KirokuButton onClick={() => {
                    cancellation();
                    setNextQuestion(defaultNextQuestion);
                }} color={'white'}>
                    {t('createSurvey.Cancel')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}


export const modalSetLogic = {
    Date       : ModalAddLogicGeneral,
    Time       : ModalAddLogicGeneral,
    Media      : ModalAddLogicGeneral,
    Number     : ModalAddLogicGeneral,
    Message    : ModalAddLogicGeneral,
    OnlyDate   : ModalAddLogicGeneral,
    InputText  : ModalAddLogicGeneral,
    MultiChoice: ModalAddLogicGeneral,
    YesNo,
    Single,
};

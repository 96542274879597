import React                       from 'react';
import { KirokuCardDeletedScreen } from '../../../ui';
import { useTranslation }          from 'react-i18next';
import FormatTimestamp             from '../CommonComponent/FormatTimestamp';

export default function RecordList({ records }) {


    const { t } = useTranslation();

    return (
        <div className={''}>
            <div className={'bg-white'}>
                <KirokuCardDeletedScreen>
                    {records.map((record, index) => (
                        <div key={index}>
                            <div className='pl-3 pt-3'>
                                <div className='header-card-del'>{record.name}</div>
                                <div className='header-card-del '>{record.title}</div>
                                <div className='title-card-del pt-10px'>{t('DeletedList.deletedAt')} : <FormatTimestamp
                                    timestampUnix={record.delete_at}/></div>
                                <div className='title-card-del pt-10px'>{t('DeletedList.createdAt')} : <FormatTimestamp
                                    timestampUnix={record.create_at}/></div>
                            </div>
                            <hr className='hr-table-mobile'/>
                        </div>
                    ))}
                </KirokuCardDeletedScreen>
            </div>
        </div>
    );
}

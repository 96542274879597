import React, { useEffect }             from 'react';
import SurveyProvider                   from '../MakeSurvey/SurveyProvider';
import Survey                           from '../Survey';
import { resources }                    from '@kiroku/api';
import If                               from '../../If';
import { KirokuLoader, SearchNotFound } from '../../../ui';
import { useHistory }                   from 'react-router-dom';
import useNotification                  from '../../Layout/useNotification';
import { useTranslation }      from 'react-i18next';
import { useMixpanelTracking } from '../../../Mixpanel/useMixpanelTracking';

export default function SurveyEditor(props) {

    const [survey, loadDetail, isLoading] = resources.useDetail('Template');
    const [resultSurvey, update]          = resources.useUpdate('Template');
    const history                         = useHistory();
    const { notification }                = useNotification();
    const { t }                           = useTranslation();

    const {trackingMixpanel} = useMixpanelTracking();

    useEffect(() => {
        loadDetail(props.match.params.id).catch(error => console.log(error));
    }, [loadDetail, props.match.params.id]);

    useEffect(() => {
        if (resultSurvey) {
            notification(t('Surveys.UpdatedSuccessfully'), 'success');
            history.push(`/surveys/${resultSurvey.id}`);
        }
    }, [resultSurvey, history, notification, t]);

    return (
        <KirokuLoader loading={isLoading}>
            <If statement={survey}
                otherwise={ <SearchNotFound message={ t('common.SurveyInvalid') } description={ ' ' }/> }
            >
                {() => {
                    return <div>
                        <SurveyProvider
                            survey={survey}
                        >
                            <Survey
                                isEditingSurvey
                                handleOnSubmit={(surveyDataUpdate) => {
                                    update(survey.id, surveyDataUpdate);
                                    trackingMixpanel('UPDATE_SURVEY', { ...surveyDataUpdate, id: survey.id })
                                }}
                            />
                        </SurveyProvider>
                    </div>;
                }}


            </If>
        </KirokuLoader>
    );
}

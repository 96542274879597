import React, { useEffect }            from 'react';
import { KirokuLoading, KirokuLoader } from '../../../../ui';
import config                          from '../../../../config';
import moment                          from 'moment';
import ActivitiesNoResult              from '../ActivitiesNoResult';
import If                              from '../../../If';
import useResearchActivitiesLog        from './useResearchActivitiesLog';
import LoadingComponent                from '../../HelperComponentLoading/LoadingComponent';
import ConditionSearchActivitiesLog    from './ConditionSearchActivitiesLog';
import HistoryLog                      from './HistoryLog';
import { useTranslation }              from 'react-i18next';

export default function ActivitiesList() {
    const {
              logs, researchActivities, loadingWhenSearchByAction,
              condition, pagination, loading, hiddenInitialPage,
              searchByAction, hasNext
          }       = useResearchActivitiesLog();
    const {t} = useTranslation();

    function isScrolling() {
        if (window.innerWidth < config.configWithMobile) {
            if ((Math.ceil(document.documentElement.scrollTop) >= document.documentElement.offsetHeight - (window.innerHeight + 100))
                && (hasNext && !loading )
            ) {
                researchActivities({ page: pagination.currentPage + 1 });
            }
        } else {
            if ((window.innerHeight + Math.ceil(document.documentElement.scrollTop) + 10 >= document.documentElement.offsetHeight)
                && (hasNext && !loading )
            ) {
                return researchActivities({ page: pagination.currentPage + 1 });
            }
        }

    }

    useEffect(() => {
        window.addEventListener('scroll', isScrolling);
        return () => window.removeEventListener('scroll', isScrolling);
    });
    return (
        <div>
            <h5 className='pt-3 pb-3 pr-3 recent-title'>
                {/*{t('Layout.Logs')}*/}
            </h5>
            <div className={'bg-white'} id={'page-log-dashboard'}>

                {!hiddenInitialPage && <ConditionSearchActivitiesLog search={searchByAction} status={condition.action}/>}

                <KirokuLoader loading={loadingWhenSearchByAction}  ComponentLoading={LoadingComponent}>
                    <If statement={logs.length} otherwise={<div/>}>
                        {() => (<div className={''}>
                            {logs.map((log, index) => {
                                return <div key={ index }>
                                    <div className={'pl-3 log-day-history'}>
                                        {moment.unix(log.date).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                    </div>
                                    <HistoryLog logs={log.logs}/>
                                </div>;
                            })}
                        </div>)}
                    </If>
                </KirokuLoader>
                {!loadingWhenSearchByAction && !loading && !logs.length && (<ActivitiesNoResult/>)}
            </div>
            <div hidden={loadingWhenSearchByAction}>
                {loading ? <KirokuLoading/> : null}
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { resources }                  from '@kiroku/api';
import { KirokuSectionDropdown }      from '../../../ui';
import { getGroupedOptions }          from '../../Converter';

export function useGetTeamTagByUserId(userId) {
    const { result, search } = resources.useSearch('Team', { type: 'all', userId });
    useEffect(() => {
        search({ type: 'all', userId });
    }, [search, userId]);
    return { teamsBeingTagUser: result };
}

export function useGetDataTeamOptions() {
    const { result, search } = resources.useSearch('Team', { type: 'all' });
    useEffect(() => {
        search({ type: 'all' });
    }, [search]);
    return { teams: result };
}

function DropdownSelectTeamTag({ handleOnChangeTeams, userId }) {
    const { teams }             = useGetDataTeamOptions();
    const { teamsBeingTagUser } = useGetTeamTagByUserId(userId);
    const [currentTeamTags, setTeamsBeingTagUser] = useState([]);

    useEffect(() => {
        setTeamsBeingTagUser(teamsBeingTagUser.map(team => {
            return {
                id     : team.id,
                key    : `Team_${team.id}`,
                label  : team.name,
                tagType: 'team',
                teamId : team.id,
                type   : 'Teams',
                value  : `${team.name}${team.id}`
            };
        }));
    }, [teamsBeingTagUser]);

    const handleChangeTags = (optionsSelected) => {
        setTeamsBeingTagUser(optionsSelected);
        handleOnChangeTeams(optionsSelected.map(item => {
            return {
                id: item.teamId,
                name: item.label,
            }
        }))
    };


    return <KirokuSectionDropdown
        onChange={handleChangeTags}
        value={currentTeamTags}
        options={getGroupedOptions('Team', teams)}
    />;
}

export default DropdownSelectTeamTag;

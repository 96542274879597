import React, { useEffect, useState }                                                               from 'react';
import { resources }                                                                                from '@kiroku/api';
import { useTranslation }                                                                           from 'react-i18next';
import { useHistory }                                                                               from 'react-router-dom';
import { ComponentMobile, ComponentWeb, KirokuButton, KirokuCardWeb, KirokuLoader, SearchNotFound } from '../../../ui';
import If                                                                                           from '../../If';
import '../customer.css';
import CustomerInfo        from './CustomerInfo';
import ModalAssignSurveys  from './ModalAssignSurveys';
import SurveyTab           from './SurveyTab';
import RecordTab           from './RecordTab';
import { withPermissions } from '../../HOC';
import ButtonPermission    from '../../HOC/ButtonPermission';
import DocumentTab         from './DocumentTab';
import { HistoryTab }      from './HistoryTab/HistoryTab';
import PlanningTab         from './PlanningTab';
import usePageTitle        from "../../../ui/v3/hooks/usePageTitle";
import CustomerProfile     from './Profile/CustomerProfile';

const ButtonAssignSurvey = withPermissions(ButtonPermission);

export default function CustomerDetail(props) {
    const { t }                                   = useTranslation();
    const history                                 = useHistory();
    const [customer, detailCustomer, loading]     = resources.useDetail('Customer');
    const [showModal, setShowModal]               = useState('');
    const selectTab    = history.location.hash.replace('#', '') || 'survey';
    const setSelectTab = (tab) => {
        history.replace(`/customers/${ props.match.params.id }#${ tab }`);
    };

    useEffect(() => {
        detailCustomer(props.match.params.id).catch(error => console.log(error));
    }, [detailCustomer, props.match.params.id]);

    usePageTitle(t(`Layout.Customers`));

    const setScrollingToView = (tab) => {
        let element = document.getElementById(tab);
        element.scrollIntoView({ block: "nearest", inline: "center"});
    }

    const goBackPage = () => {
        if (history.location.state && history.location.state.previousPage === 'customerList') {
            history.goBack();
        } else{
            history.push('/customers')
        }
    }

    return (
        <KirokuLoader loading={ loading }>
            <If statement={ customer }
                otherwise={ <SearchNotFound message={t('common.CustomerInvalid')} description={' '}/> }
            >
                { () => <div className='default-background-color'>
                    <div className='customer-detail default-background-color'>

                        <div className='padding-responsive padding-responsive-none-on-mobile'>
                            <div className='d-flex justify-content-between pb-3 btn-back-customer-detail'>
                                <KirokuButton onClick={() => goBackPage()}
                                              color='white'>{t('common.goToCustomerList')}</KirokuButton>
                                <ComponentMobile>
                                    <ButtonAssignSurvey
                                        resource-permission="|customerDetail|button-assignSurvey|"
                                        color='success'
                                        onClick={ () => setShowModal('assign') }>{ t('Customer.AssignSurvey') }
                                    </ButtonAssignSurvey>
                                </ComponentMobile>
                                <ComponentWeb>
                                    <div className="hidden-button-assign-customer-detail">
                                        <ButtonAssignSurvey
                                            resource-permission="|customerDetail|button-assignSurvey|"
                                            color='success'
                                            onClick={ () => setShowModal('assign') }>{ t('Customer.AssignSurvey') }
                                        </ButtonAssignSurvey>
                                    </div>
                                </ComponentWeb>
                            </div>
                            <KirokuCardWeb>
                                <div className='p-2 row margin-none'>
                                    <div className="col-md-3 col-lg-3 as">
                                        <div className='customer-detail as height-avatar image-customer-detail'
                                             style={{
                                                 backgroundImage: `url(${customer.avatar || '/assets/img/profile-ic.svg'})`,
                                             } }
                                        />
                                    </div>
                                    <div className='col-md-9 col-lg-6'>
                                        <CustomerInfo customer={ customer }/>
                                    </div>
                                    <div className='col-lg-3 as'>
                                        <ComponentWeb>
                                            <div className="move-button-assign-customer-detail">
                                                <ButtonAssignSurvey
                                                    resource-permission="|customerDetail|button-assignSurvey|"
                                                    color='success'
                                                    onClick={ () => setShowModal('assign') }>{ t('Customer.AssignSurvey') }
                                                </ButtonAssignSurvey>
                                            </div>
                                        </ComponentWeb>
                                    </div>
                                </div>
                                <ComponentWeb>
                                    <div className='d-flex justify-content-between pt-10 pl-3' style={ { maxWidth: 650 } }>
                                        <div style={ { cursor: 'pointer' } }
                                             className={ `nav-bar ${ selectTab === 'survey' ? 'active' : '' }` }
                                             onClick={ () => setSelectTab('survey') }>{ t('common.Survey') }
                                        </div>
                                        <div style={ { cursor: 'pointer' } }
                                             className={ `nav-bar ${ selectTab === 'record' ? 'active' : '' }` }
                                             onClick={ () => setSelectTab('record') }>{ t('common.Records') }
                                        </div>
                                        <div style={ { cursor: 'pointer' } }
                                             className={ `nav-bar ${ selectTab === 'profile' ? 'active' : '' }` }
                                             onClick={ () => setSelectTab('profile') }>{ t('common.ProfileDetail') }
                                        </div>
                                        <div style={ { cursor: 'pointer' } }
                                             className={ `nav-bar ${ selectTab === 'document' ? 'active' : '' }` }
                                             onClick={ () => setSelectTab('document') }>{ t('common.UpLoad') }
                                        </div>
                                        <div style={ { cursor: 'pointer' } }
                                             className={ `nav-bar ${ selectTab === 'historyTab' ? 'active' : '' }` }
                                             onClick={ () => setSelectTab('historyTab') }>{ t('Customer.history') }
                                        </div>
                                        <div style={ { cursor: 'pointer' } }
                                             className={ `nav-bar ${ selectTab === 'planningTab' ? 'active' : '' }` }
                                             onClick={ () => setSelectTab('planningTab') }>{ t('plans.header') }
                                        </div>
                                    </div>
                                </ComponentWeb>
                                <ComponentMobile>
                                    <nav id={'nav-bar'} className='customer-navbar-tabs customer-navbar-text'>
                                        <ul className="customer-scrolling-tabs ">
                                            <li id={'survey'} className={ `customer-detail-tab nav-bar ${ selectTab === 'survey' ? 'active' : '' }` }
                                                 onClick={ () =>  {
                                                     setScrollingToView('survey');
                                                     setSelectTab('survey')

                                                 }}>{ t('common.Template') }
                                            </li>
                                            <li id={'record'} className={ `customer-detail-tab nav-bar ${ selectTab === 'record' ? 'active' : '' }` }
                                                 onClick={ () => {
                                                     setScrollingToView('record');
                                                     setSelectTab('record')
                                                 } }>{ t('common.Records') }
                                            </li>
                                            <li id={'profile'} className={ `customer-detail-tab nav-bar ${ selectTab === 'profile' ? 'active' : '' }` }
                                                 onClick={ () => {
                                                     setSelectTab('profile');
                                                     setScrollingToView('profile');
                                                 }}>{ t('common.ProfileDetail') }
                                            </li>
                                            <li  id={'document'} className={ `customer-detail-tab nav-bar ${ selectTab === 'document' ? 'active' : '' }` }
                                                 onClick={ () => {
                                                     setScrollingToView('document');
                                                     setSelectTab('document')
                                                 } }>{ t('common.UpLoad') }
                                            </li>
                                            <li id={'historyTab'} className={ `customer-detail-tab nav-bar ${ selectTab === 'historyTab' ? 'active' : '' }` }
                                                 onClick={ () => {
                                                     setSelectTab('historyTab');
                                                     setScrollingToView('historyTab');
                                                 } }>{ t('Customer.history') }
                                            </li>
                                            <li id={'planningTab'} className={ `customer-detail-tab nav-bar margin-last-tabs  ${ selectTab === 'planningTab' ? 'active' : '' }` }
                                                 onClick={ () => {
                                                     setSelectTab('planningTab');
                                                     setScrollingToView('planningTab');
                                                 } }>{ t('plans.header') }
                                            </li>
                                        </ul>
                                    </nav>
                                </ComponentMobile>
                            </KirokuCardWeb>
                            <ModalAssignSurveys show={ showModal === 'assign' }
                                                closeModal={ () => setShowModal('') }
                                                loadDetailCustomer={() => {
                                                    detailCustomer(props.match.params.id);
                                                }}
                                                customerId={ customer.id }
                                                checkedSurveys={ customer['surveys'] }
                            />
                            <div className='pt-3'>
                                <SurveyTab customer={ customer } show={ selectTab === 'survey' }
                                           templates={customer.surveys}
                                           showModal={ () => setShowModal('assign') }/>
                                <RecordTab customer={ customer } show={ selectTab === 'record' }/>
                                <CustomerProfile customer={ customer } show={ selectTab === 'profile' }/>

                                <DocumentTab customer={ customer } show={ selectTab === 'document' }/>

                                <HistoryTab customer={ customer } show={ selectTab === 'historyTab' }/>
                                <PlanningTab customer={ customer } show={ selectTab === 'planningTab'}/>
                            </div>
                        </div>
                    </div>
                </div> }
            </If>
        </KirokuLoader>

    );
}

import React, { useEffect, useState }   from 'react';
import { KirokuDropdownOnChangeSearch } from '../../ui';
import { itemsDropdownSearch }          from '../Converter';
import { resources }                    from '@kiroku/api';
import { useTranslation }               from 'react-i18next';

export default function DropdownSelectCustomer(props) {
    const { t }                               = useTranslation();
    const { result, search, cancelAPISearch } = resources.useSearch('Customer');
    const [customers, setCustomers]           = useState([]);
    const [customerName, setCustomerName]     = useState('');

    useEffect(() => {
        search({ type: 'all' });
        return () => {
            cancelAPISearch();
        };
    }, [search, cancelAPISearch]);

    useEffect(() => {
        setCustomers(result);
    }, [result]);

    return (
        <div>
            <KirokuDropdownOnChangeSearch
                defaultValue={ t('Export.SelectCustomer') }
                title={ customerName }
                icon={ 'far fa-user text-secondary' }
                onSelect={ (event) => {
                    setCustomerName(event.kirokuSelected.label);
                    props.customerSelected(event.kirokuSelected.value);
                    props.getCustomerName(event.kirokuSelected.label);
                } }
                items={ itemsDropdownSearch(customers, t) }
                handleChange={ (keyword) => {
                    setCustomers(result.filter(customer => customer.name.toLowerCase().search(keyword.toLowerCase()) !== -1));
                } }
            />
        </div>
    );
}

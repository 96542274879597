import React, { useEffect, useState }                                         from 'react';
import {
    KirokuCard, KirokuDropdownClose, KirokuCardList,
    KirokuInputSearcher, KirokuLoader, KirokuPagination, KirokuPopoverItem, KirokuInput,
} from '../../../../../ui';
import TogglePopover
    from '../../../../Templates/QuestionTypes/TogglePopover';
import { useTranslation }     from 'react-i18next';
import { WrapperPermissions }  from '../../../../HOC';
import { useDocumentContext }  from '../DocumentContext';
import If                                                                     from '../../../../If';
import config                                                                 from '../../../../../config';
import useDocumentAction                                                      from '../useDocumentAction';
import * as moment                                                            from 'moment';
import PreviewFileModal                                                       from './PreviewFileModal';
import ModalConfirm                                                           from './ModalConfirm';
import ModalSelectDocument                                                    from './ModalSelectDocument';
import ModalConfirmDelete                                                     from './ModalConfirmDelete';
import ModalSelectUser                                                        from './ModalSelectUsers';
import { KirokuTogglePopover, KirokuPopoverItem as KirokuPopoverItemHelper  } from '../HelperComponent';
import { resources }                                                          from '@kiroku/api';
import useNotification                                                        from '../../../../Layout/useNotification';

export default function DocumentsMobile() {
    const { t }                                         = useTranslation();
    const { documentsData, modalInfo, loginUserInfo }   = useDocumentContext();
    const [modalSelectDocument, setModalSelectDocument] = useState(false);

    const {
              handleSelectType, handleSearchKeyword,
              handleChangePage, handleSearchTime,
              isShareFromUserLogin, optionDateItems,
              optionFileTypeItems, showModalPreviewDocument,
              closeModalName, showModalConfirmChangeStatusToPrivate,
              changeStatusDocument, refreshDocument, handleShareDocumentToUser,
              handleOpenModalSelectUser, handleRenameMode
          }                       = useDocumentAction();
    const [keywords, setKeywords] = useState('');

    const increasePage = () => {
        handleChangePage(documentsData.currentPage + 1);
    };

    const decreasePage = () => {
        handleChangePage(documentsData.currentPage - 1);
    };

    const handleChoiceType = (event) => {
        handleSelectType(event.kirokuSelected.value);
    };

    const handleDelete = () => {
        setModalSelectDocument(true);
    };

    const handleChoiceDate = (event) => {
        handleSearchTime({
            timeKey  : event.kirokuSelected.value,
            timeValue: event.kirokuSelected.value === 'All date' ? '' : moment().startOf(event.kirokuSelected.value).unix(),
        });
    };

    const previewFile = (documentId) => {
        showModalPreviewDocument(documentId);
    };

    const showModalConfirmToPrivateFromPublic = (id) => {
        showModalConfirmChangeStatusToPrivate(id);
    };

    const switchDocumentToPublicFromPrivate = (documentId) => {
        changeStatusDocument(documentId, 1);
    };

    const handleShareDocument = (document) => {
        handleOpenModalSelectUser(document);
    };

    const [fileName, setFileName] = useState('');
    const displayModeEditName    = (document) => {
        setFileName(document.name);
        handleRenameMode(document.id);
    };

    const handleCancelRenameFile = () => {
        handleRenameMode(null);
        setFileName('');
    };

    const [result, update, isLoadingAPIUpdateNameDocument] = resources.usePatch('Document');
    const { notification } = useNotification();

    useEffect(() => {
    }, [result]);

    const handleConfirmRenameFile = async (document) => {
        if(!fileName.replace(/\s+/g, '') || isLoadingAPIUpdateNameDocument) {
            return null
        }
        await update(document.id, {
            name: fileName,
        }).then(() => {
            handleRenameMode(document.id);
            setFileName('');
            refreshDocument();
            notification(t('message.UpdateSuccess'), 'success');
        });
    };


    return (<div>
        <div className="padding-responsive">
            <div className='pb-2 d-flex justify-content-between'>
                <KirokuDropdownClose
                    style={{ width: 150 }}
                    icon='far fa-calendar-alt '
                    title={t('Surveys.AllDate')}
                    items={optionDateItems}
                    onSelect={handleChoiceDate}
                />
                <KirokuDropdownClose
                    style={{ width: 150 }}
                    icon='fas fa-check-circle'
                    title={t('Surveys.AllType')}
                    items={optionFileTypeItems}
                    onSelect={handleChoiceType}
                />
            </div>
            <KirokuInputSearcher
                value={keywords}
                onChange={(event) => {
                    setKeywords(event.target.value);
                }}
                onSubmit={() => {
                    handleSearchKeyword(keywords);
                }}
                id={'input-search-document-tab-mobile'}
                placeholder={t('common.Search')}
            />
            <div className={'d-flex justify-content-between pt-2'}>
                <WrapperPermissions
                    resource-permission={'|customerDetail|documentTab|DropdownBulkAction|Delete|'}
                >
                    <KirokuDropdownClose
                        style={{ width: 160 }}
                        title={t('common.BulkAction')}
                        items={[
                            {
                                value    : 'Delete',
                                component: (<div>{t('common.Delete')} </div>),
                                label    : t('common.Delete'),
                            },
                        ]}
                        onSelect={handleDelete}
                    />
                </WrapperPermissions>
                <div/>
                <KirokuPagination
                    className='pb-0'
                    isMobile
                    disabledPrev={1 === documentsData.currentPage}
                    disabledNext={documentsData.currentPage === documentsData.totalPage || documentsData.totalPage === 0}
                    increasePage={increasePage}
                    decreasePage={decreasePage}
                    currentPage={documentsData.currentPage}
                    totalPage={documentsData.totalPage === 0 ? 1 : documentsData.totalPage}
                />
            </div>
        </div>

        <KirokuCardList headerLeft={<span>{t('common.Name')}</span>}
                        headerRight={<span>{t('common.Action')}</span>}>
            <KirokuLoader loading={documentsData.loading}>
                <If statement={documentsData.documents.length}
                    otherwise={<div className="pt-3 pb-5 fix-center">
                        <img src={`/assets/img/empty-survey.svg`}
                             alt="search fail"/>
                        <h6 className="pt-3" style={{ color: 'rgb(9, 30, 66)' }}>
                            <div>{t('common.PleaseSelect')}</div>
                        </h6>
                    </div>}
                >
                    {() => {
                        return documentsData.documents.map((document, index) => {
                            return <KirokuCard
                                key={index}
                                action={<If
                                    statement={!document.private || loginUserInfo.role !== 'staff' || isShareFromUserLogin(document.users)}>
                                    {() =>  <TogglePopover>
                                        <KirokuPopoverItem>
                                            <div className={'d-flex justify-content-between p-1 fix-icon-align'}>
                                                <If statement={!document.private || loginUserInfo.role !== 'staff' || isShareFromUserLogin(document.users)}>
                                                    {() => <React.Fragment>
                                                        <a href={`${config.kirokuAPI.url}/v${config.kirokuAPI.version}/download/${document.id}?token=${localStorage.getItem('token')}`}
                                                           style={{ margin: 'auto', color: 'white' }}>
                                                            <div className='pr-3' style={{ cursor: 'pointer' }}>
                                                                <i className="fas fa-cloud-download-alt "/>
                                                            </div>
                                                        </a>
                                                        <div/>
                                                        <div onClick={() => previewFile(document.id)}
                                                             className="pr-3"
                                                             style={{ cursor: 'pointer', pointerEvents: `${!config.allowFileType.includes(document.type) ? 'none' : 'stroke'}`, margin: 'auto' }}>
                                                            <i className={`${!config.allowFileType.includes(document.type) ? 'fas fa-binoculars tranfer-text' : 'fas fa-binoculars '}`}/>&nbsp;
                                                        </div>
                                                    </React.Fragment>}
                                                </If>

                                                <div/>
                                                <If statement={document.private}
                                                    otherwise={
                                                        <WrapperPermissions
                                                            resource-permission={'|customerDetail|documentTab|changeStatusDocument|'}
                                                            onClick={() => switchDocumentToPublicFromPrivate(document.id)}
                                                            className="pr-3"
                                                            style={{ cursor: 'pointer', pointerEvents: 'stroke', margin: 'auto' }}
                                                        >
                                                            <i className='fas fa-lock'/>
                                                        </WrapperPermissions>
                                                    }
                                                >
                                                    {() => {
                                                        return <React.Fragment>
                                                            <WrapperPermissions
                                                                resource-permission={'|customerDetail|documentTab|changeStatusDocument|'}
                                                                style={{ cursor: 'pointer', pointerEvents: 'stroke', margin: 'auto' }}
                                                                className="pr-3"
                                                                onClick={() => showModalConfirmToPrivateFromPublic(document.id)}
                                                            >
                                                                <i className='fas fa-unlock'/>
                                                            </WrapperPermissions>
                                                            <WrapperPermissions
                                                                resource-permission={'|customerDetail|documentTab|changeStatusDocument|'}
                                                                style={{ cursor: 'pointer', pointerEvents: 'stroke', margin: 'auto' }}
                                                                className="pr-3"
                                                                onClick={() => handleShareDocument(document)}
                                                            >
                                                                <i className='fas fa-share'/>
                                                            </WrapperPermissions>
                                                        </React.Fragment>;
                                                    }}
                                                </If>
                                            </div>
                                        </KirokuPopoverItem>
                                    </TogglePopover>}
                                </If>}
                            >
                            <KirokuTogglePopover
                                popupVisible={document.isRenameMode}
                                TogglePopover={<div className={'pl-2'}
                                >
                                    <span onClick={ () => displayModeEditName(document) }>
                                        <i className="fas fa-pencil-alt fa-sm icon-badge-edit"/>
                                        <span className={'pl-4'}>&nbsp;</span>
                                    </span>
                                    <span>
                                        { document.name }
                                    </span>
                                    <If statement={document.private && !isShareFromUserLogin(document.users)}>
                                        {() => <i className="pl-2 fa fa-lock"/>}
                                    </If>
                                </div>}
                            >
                                <KirokuPopoverItemHelper alignPopover={'top'}>
                                    <div>
                                        <div style={ {
                                            display   : 'flex',
                                            alignItems: 'center',
                                        } }>

                                            <KirokuInput
                                                style={ { height: 29 } }
                                                maxLength={ 250 }
                                                value={ fileName }
                                                onChange={ (event) => {
                                                    setFileName(event.target.value);
                                                } }
                                            />
                                            &nbsp;
                                            <span
                                                style={ {
                                                    color : fileName.replace(/\s+/g, '') ? 'green' : '#00800082',
                                                    cursor: fileName.replace(/\s+/g, '') ? 'pointer' : 'not-allowed',
                                                } }
                                                onClick={ () => handleConfirmRenameFile(document)}
                                            >
                                                <i className="fas fa-2x fa-check-circle"/>
                                               </span>
                                            <span
                                                className='ml-2'
                                                style={ {
                                                    color : 'red',
                                                    cursor: 'pointer',
                                                } }
                                                onClick={ () => handleCancelRenameFile(document)}
                                            >
                                                <i className="fas fa-2x fa-times-circle"/>
                                           </span>
                                        </div>
                                    </div>
                                </KirokuPopoverItemHelper>
                            </KirokuTogglePopover>
                        </KirokuCard>;
                        });
                    }}
                </If>
            </KirokuLoader>
        </KirokuCardList>

        <div className={'d-flex justify-content-between pt-2 padding-responsive'}>
            <WrapperPermissions
                resource-permission={'|customerDetail|documentTab|DropdownBulkAction|Delete|'}
            >
                <KirokuDropdownClose
                    location={'top'}
                    style={{ width: 160 }}
                    title={t('common.BulkAction')}
                    items={[
                        {
                            value    : 'Delete',
                            component: (<div>{t('common.Delete')} </div>),
                            label    : t('common.Delete'),
                        },
                    ]}
                    onSelect={handleDelete}
                />
            </WrapperPermissions>
            <div/>
            <KirokuPagination
                className='pb-0'
                disabledPrev={1 === documentsData.currentPage}
                disabledNext={documentsData.currentPage === documentsData.totalPage || documentsData.totalPage === 0}
                increasePage={increasePage}
                decreasePage={decreasePage}
                currentPage={documentsData.currentPage}
                totalPage={documentsData.totalPage === 0 ? 1 : documentsData.totalPage}
            />
        </div>
        <div className="padding-responsive">
            <div style={ { marginBottom: 80 } }/>
        </div>

        <ModalConfirm
            show={modalInfo.name === 'CONFIRM_PUBLIC_DOCUMENT' && modalInfo.data}
            submit={() => {
                changeStatusDocument(modalInfo.data.id, 0);
            }}
            closeModal={() => {
                closeModalName();
            }}
        />

        <PreviewFileModal
            isShowModal={modalInfo.name === 'PREVIEW_DOCUMENT_MODAL' && modalInfo.data}
            fileBePreview={modalInfo.data}
            closeModal={() => {
                closeModalName();
            }}
        />

        <ModalSelectDocument
            show={modalSelectDocument}
            closeModal={() => setModalSelectDocument(false)}
        />
        <ModalConfirmDelete
            documentSelected={documentsData.documents.filter(doc => doc.isChecked)}
            show={modalInfo.name === 'CONFIRM_DELETE_DOCUMENT'}
            handleAfterDelete={() => {
                refreshDocument();
            }}
            closeModal={() => closeModalName()}
        />

        <ModalSelectUser
            show={modalInfo.name === 'SELECT_USER_BE_SHARE'}
            handleCloseModal={() => closeModalName()}
            modalData={modalInfo.data}
            handleShareDocument={(users) => {
                handleShareDocumentToUser(modalInfo.data.document.id, users);
            }}
        />
    </div>);
}

import React, { useEffect, useState } from 'react';
import { Link, useHistory }           from 'react-router-dom';
import {
    KirokuAge, KirokuButton, KirokuCard, KirokuCardList, KirokuCardWeb, KirokuLoader, KirokuTable, SearchNotFound,
    TBody, THeader,
}                                     from '../../../ui';
import { resources }                  from '@kiroku/api';
import { useTranslation }             from 'react-i18next';
import If                             from '../../If';
import ComponentWeb                   from '../../../ui/ComponentWeb';
import ComponentMobile                from '../../../ui/ComponentMobile';
import usePageTitle                   from '../../../ui/v3/hooks/usePageTitle';

export default function TeamDetail(props) {
    const { t }                         = useTranslation();
    const history                       = useHistory();
    const [tabSelect, setTabSelect]     = useState('customer');
    const [team, loadDetail, isLoading] = resources.useDetail('Team');
    usePageTitle(t(`Layout.Team`));
    useEffect(() => {
        loadDetail(props.match.params.id).catch(err => console.log(err));
    }, [loadDetail, props.match.params.id]);

    const goBackPage = () => {
        if (history.location.state && history.location.state.previousPage === 'teamList') {
            history.goBack();
        } else {
            history.push(`/teams`)
        }
    }

    return (
        <KirokuLoader loading={isLoading}>
            <If statement={team}
                otherwise={ <SearchNotFound message={ t('common.TeamInvalid') } description={ ' ' }/> }
            >
                {() => <div className="padding-responsive">
                    <div className="pb-2">
                        <KirokuButton color='white'
                                      onClick={() => goBackPage()}>{t('common.goToTeamList')}</KirokuButton>
                    </div>
                    <ComponentWeb>
                        <KirokuCardWeb>
                            <div className='d-flex justify-content-between p-3'>
                                <div>
                                    <h5 className={'card-title-info'}>{team.name}</h5>
                                    <pre style={{
                                        wordWrap  : 'break-word',
                                        whiteSpace: 'pre-wrap',
                                    }}>{team.description}</pre>
                                </div>
                            </div>
                            <div className='fix-center'>
                                <div className='pl-3 pr-3 pb-3' style={{ width: '50%' }}>
                                    <KirokuButton onClick={() => setTabSelect('customer')}
                                                  className='btn-switch-1'
                                                  color={tabSelect === 'customer' ? 'success' : 'white'}>{t('common.Customer')}</KirokuButton>
                                    <KirokuButton onClick={() => setTabSelect('user')}
                                                  className='btn-switch-2'
                                                  color={tabSelect === 'user' ? 'success' : 'white'}>{t('common.Members')}</KirokuButton>
                                </div>
                            </div>
                            <div hidden={tabSelect === 'user'}>
                                <KirokuTable>
                                    <THeader>
                                        <tr>
                                            <th>{t('common.Name')}</th>
                                            <th>{t('common.Age')}</th>
                                            <th>{t('Customer.KindOfCertificate')}</th>
                                            <th>{t('CustomerEditor.Characteristic')}</th>
                                        </tr>
                                    </THeader>
                                    <TBody>
                                        {team.customers
                                            .filter(customer => !customer.delete_at)
                                            .map((customer, index) => {
                                                    return <tr key={index}>
                                                        <td>
                                                            <Link to={`/customers/${customer.id}`}>
                                                                {customer.name}
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <KirokuAge
                                                                dob={customer.date_of_birth}
                                                            />
                                                        </td>
                                                        <td>
                                                            {customer.certificate.map(item => {
                                                                return t(`Customer.certificate.${item}`);
                                                            }).join(', ')}
                                                        </td>
                                                        <td>{customer.characteristic}</td>
                                                    </tr>;
                                                },
                                            )}
                                    </TBody>
                                </KirokuTable>
                            </div>
                            <div hidden={tabSelect === 'customer'}>
                                <KirokuTable>
                                    <THeader>
                                        <tr>
                                            <th>{t('common.Name')}</th>
                                            <th>{t('common.Role')}</th>
                                            <th>{t('common.Title')}</th>
                                            <th>{t('common.WorkingForm')}</th>
                                        </tr>
                                    </THeader>
                                    <TBody>
                                        {team.users
                                            .filter(user => !user.deleted_at)
                                            .map((member, index) => <tr key={index}>
                                                    <td>
                                                        <Link to={`/users/${member.id}`}>
                                                            <span>{member.name}</span>
                                                        </Link>
                                                    </td>
                                                    <td>{t(`role.${member.role}`)}</td>
                                                    <td>{member.title}</td>
                                                    <td>{t(`common.${member.working_form}`)}</td>
                                                </tr>,
                                            )}
                                    </TBody>
                                </KirokuTable>
                            </div>
                        </KirokuCardWeb>
                    </ComponentWeb>
                    <ComponentMobile>
                        <div className="pt-2">
                            <div className='card'>
                                <div className='p-2 pt-3'>
                                    <h5>{team.name}</h5>
                                    <pre
                                        style={{
                                            color: '#7f8fa4',
                                            wordWrap: 'break-word',
                                            whiteSpace: 'pre-wrap'
                                        }}
                                    >{team.description}</pre>
                                </div>
                            </div>
                        </div>
                        <div className='pt-10'>
                            <div className='card fix-center'>
                                <div className='p-2' style={{width: '100%'}}>
                                    <KirokuButton onClick={() => setTabSelect('customer')}
                                                  style={{borderRadius: 0, width: '50%'}}
                                                  color={tabSelect === 'customer'? 'success' : 'white'}>{t('common.Customer')}</KirokuButton>
                                    <KirokuButton onClick={() => setTabSelect('user')}
                                                  style={{borderRadius: 0, width: '50%'}}
                                                  color={tabSelect === 'user'? 'success' : 'white'}>{t('common.Members')}</KirokuButton>
                                </div>
                            </div>
                            <div hidden={ tabSelect === 'user'}>
                                <KirokuCardList
                                    headerLeft={<span className='customer-name'>{t('common.Name')}</span>}
                                    headerRight={<span className='customer-name'>{t('common.Age')}</span>}
                                >
                                    {team.customers.filter(customer => !customer.delete_at)
                                        .map((customer) => (
                                            <KirokuCard key={customer.id}
                                                        action={ <KirokuAge dob={customer.date_of_birth}/> }>
                                                <p>
                                                    <Link to={`/customers/${customer.id}`}>
                                                        {customer.name}
                                                    </Link>
                                                </p>
                                            </KirokuCard>
                                        ))
                                    }
                                </KirokuCardList>
                            </div>
                            <div hidden={tabSelect === 'customer'}>
                                <KirokuCardList
                                    headerLeft={<span className='customer-name'>{t('common.Name')}</span>}
                                    headerRight={<span className='customer-name'>{t('common.Role')}</span>}
                                >
                                    {team.users.filter(user => !user.deleted_at)
                                        .map(member => (
                                        <KirokuCard key={member.id}
                                                    action={<p>{t(`role.${member.role}`)}</p>}>
                                            <p>
                                                <Link to={`/users/${member.id}`}>
                                                    {member.name}
                                                </Link>
                                            </p>
                                        </KirokuCard>
                                    ))}
                                </KirokuCardList>
                            </div>

                        </div>
                    </ComponentMobile>
                    <div className='pt-3 d-flex justify-content-between'>
                        <KirokuButton color='white' onClick={() => goBackPage()}>{t('common.goToTeamList')}</KirokuButton>
                        <div/>
                    </div>
                    <ComponentMobile>
                        <div style={ { marginBottom: 80 } }/>
                    </ComponentMobile>
                </div>}
            </If>
        </KirokuLoader>
    );
}

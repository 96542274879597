import React               from 'react';
import moment              from "moment";
import { withTranslation } from 'react-i18next';

class FormatTimestamp extends React.Component {
    getDateTimeItem(type, dateString) {
        switch (type) {
            case 'year':
                return `<span class="year-of-action date-time-item" style="font-size:14px;">${dateString.year()}年</span>`;
            case 'month':
                return `<span class="month-of-action date-time-item" style="font-size:14px">${dateString.month()+1}月</span>`;
            case 'date':
                return `<span class="date-of-action date-time-item" style="font-size:14px">${dateString.date()}日</span>`;
            case 'hour':
                return `<span class="hour-of-action date-time-item" style="font-size:14px">${dateString.hour()}:</span>`;
            case 'minute':
                return `<span class="date-time-item" style="font-size:14px">${dateString.minute() > 9 ? dateString.minute() : '0'.concat(dateString.minute()) }</span>`;
            default:
                return '';
        }
    }

    getDateTimeItemNumber(value, isMinute){
        let preChar = isMinute ? '0' : ' ';
        return value < 10 ? preChar.concat(value) : value;
    }

    render() {
        const { timestampUnix, isAlign, t } = this.props;
        if (isAlign && moment.locale() === 'ja'){
            let dateTimeString = moment.unix(timestampUnix);
            return (
                <div dangerouslySetInnerHTML={{__html:
                    this.getDateTimeItem('year',dateTimeString) +
                    this.getDateTimeItem('month',dateTimeString) +
                    this.getDateTimeItem('date',dateTimeString) +
                    moment.unix(timestampUnix).format('(dd)') +
                    this.getDateTimeItem('hour',dateTimeString) +
                    this.getDateTimeItem('minute',dateTimeString)
                }}/>
            )
        }
        return (
            <>{timestampUnix ? moment.unix(timestampUnix).format(t('dateTimeFormat.YearMonthDateWithHour')): ''}</>
        )
    }
}
export default withTranslation() (FormatTimestamp);

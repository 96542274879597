import React              from 'react';
import './RecordCompleted.css';
import { useTranslation } from 'react-i18next';
import { Link }           from 'react-router-dom';
import queryString        from 'query-string';

export default function RecordCompleted(props) {

    const { t } = useTranslation();
    const urlQuery = queryString.parse(props.location.search.replace('?', ''));
    return (
        <div className={'padding-responsive record-complete'}>
            <div className="bg-white d-flex align-items-center justify-content-center" style={{ height: '70vh' }}>
                <div className={'responsive-width-page'}>
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <img src={'/assets/img/file-complete.svg'} alt="file-complete.svg" className="file-complete"/>
                    </div>
                    <div className={'record-complete-text'}>
                        {t('createRecord.CreateRecordSuccess')}
                    </div>
                    <div>
                        <hr/>
                    </div>
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <div>
                            <div className={'link-page'}>
                                <Link to={`/surveys/${urlQuery.surveyId}`}>
                                    {t('createRecord.RecordOtherCustomerForSameTemplate')}
                                </Link>
                            </div>
                            <div className={'link-page'}>
                                <Link to={`/customers/${urlQuery.customerId}`}>
                                    {t('createRecord.RecordOtherTemplatesForFameCustomer')}
                                </Link>
                            </div>
                            <div className={'link-page'}>
                                <Link to={{
                                    pathname: `/analysis`,
                                    state: {
                                        survey: {id: urlQuery.surveyId, title: urlQuery.title},
                                        customer: {id:urlQuery.customerId, name: urlQuery.customerName}
                                    }
                                }}>
                                    {t('createRecord.Analyze')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

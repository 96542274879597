import { KirokuCard, KirokuCardList } from '../../../ui';
import { Link }                       from 'react-router-dom';
import React                          from 'react';
import { useTranslation }             from 'react-i18next';
import moment                         from 'moment';

export default function PlanTableMobile({ result }) {
    const { t } = useTranslation();
    const now = moment().unix();

    return (
        <KirokuCardList headerLeft={ <span>{ t('plans.header') }</span> }
        >
            { result.map((plan, index) => {
                return <div key={ index }>
                    <KirokuCard key={ index }>
                        <Link to={ `/plans/${ plan.id }` }>
                            <div className={ 'planning-list-text' } style={ { fontSize: 14 } }>
                                { plan['customer'] ? plan['customer'].name : '' }
                            </div>
                            <div className={ 'planning-list-text pt-2' }
                                 style={ { fontSize: 14 } }> { t('plans.startDate') }:
                                { !!plan['start_at'] && moment.unix(plan['start_at']).format(t('dateTimeFormat.YearMonthDateWithDay')) }
                            </div>
                            <div className={ 'planning-list-text pt-2' }
                                 style={ { fontSize: 14 } }> { t('plans.endDate') }:
                                { !!plan['end_at'] && moment.unix(plan['end_at']).format(t('dateTimeFormat.YearMonthDateWithDay')) }
                            </div>
                            <div className={ 'planning-list-text pt-2 d-flex align-items-center' }>
                                <div style={ { fontSize: 14 } }>{ t('plans.planStatus') }:&nbsp;&nbsp;</div>

                                <div hidden={plan.published_at || plan.review_at}>
                                    <img src={ `/assets/icon/iconDraft.svg` } alt="iconDraft.svg"/>
                                    &nbsp;<span style={ { fontSize: 14 } }>{ t('plans.draft') }</span>
                                </div>
                                <div hidden={!(plan.published_at && now < plan.start_at) || plan.review_at}>
                                    <div className={ 'd-flex' }>
                                        <img src={ `/assets/icon/iconBeforeStart.svg` } alt="iconBeforeStart.svg"/>
                                        &nbsp;<span style={ { fontSize: 14 } }>{ t('plans.beforeStart') }</span>
                                    </div>
                                </div>
                                <div hidden={!(plan.published_at && now > plan.start_at && now < plan.end_at) || plan.review_at }>
                                    <img src={ `/assets/icon/iconRunning.svg` } alt="iconRunning.svg"/>
                                    &nbsp;<span style={ { fontSize: 14 } }>{ t('plans.running') }</span>
                                </div>
                                <div hidden={!(plan.published_at && now > plan.end_at)}>
                                    <img src={ `/assets/icon/iconFinish.svg` } alt="iconFinish.svg"/>
                                    &nbsp;<span style={ { fontSize: 14 } }>{ t('plans.finish') }</span>
                                </div>
                            </div>
                            <div className={"planning-list-text d-flex pt-2"}>
                                <div style={ { fontSize: 14 } }>{ t('plans.statusReview') }:&nbsp;&nbsp;</div>
                                <div hidden={plan.review_status !== 0 }>
                                    <span>{t('plans.unReview')}</span>
                                </div>
                                <div hidden={plan.review_status !== 1}>
                                    <span>{t('plans.draftReview')}</span>
                                </div>
                                <div hidden={plan.review_status !== 2}>
                                    <span>{t('plans.monitored')}</span>
                                </div>
                            </div>
                        </Link>
                    </KirokuCard>
                    <hr hidden={ result.length - 1 === index } className={ 'margin-none' }/>
                </div>;
                },
            ) }
        </KirokuCardList>
    );
}

import {
    KirokuButton, KirokuLoader, KirokuModal,
    KirokuModalBody, KirokuModalFooter, KirokuModalHeader,
    KirokuLoading,
}                                  from '../../../../ui';
import React, { useEffect }        from 'react';
import { useTranslation }          from 'react-i18next';
import { resources }               from '@kiroku/api';
import If                          from '../../../If';
import useDifferenceRecordHistory  from './useDifferenceRecordHistory';
import { ComponentViewActionEdit } from './ComponentTypeChangeRecord';
import moment                      from 'moment';


const RenderDifferenceQuestion = (differences, questionsBefore, questionsAfter) => {
    return differences.map((diff, index) => {
        return <div key={index}>
            <div className={'pl-3 pr-3'}>
                <ComponentViewActionEdit
                    questionsBefore={questionsBefore}
                    questionsAfter={questionsAfter}
                    diff={diff}
                />
            </div>
            <hr hidden={index === differences.length - 1}/>
        </div>;
    });
};

export default function ModalDifferenceRecord({ isShow, logId, closeModal, record }) {
    const { t } = useTranslation();

    const [historyResult, search, loading] = resources.useSearchWithPathname('History');

    useEffect(() => {
        if (isShow && logId) {
            search({
                type  : 'change',
                record: record.id,
                log   : logId,
            });
        }
    }, [search, logId, isShow, record]);

    return (<KirokuModal show={isShow && logId}>
        <KirokuModalHeader>
            <div style={{ fontSize: 18 }}>{t('logsRecord.changeLogRecord')}</div>
        </KirokuModalHeader>
        <KirokuModalBody>
            <div className='background-color'
                 style={{
                     overflow : 'scroll',
                     minHeight: 'calc(100vh - 250px)',
                     maxHeight: 'calc(100vh - 250px)',
                     height   : 'auto',
                 }}
            >
                <KirokuLoader loading={loading}>
                    <If statement={historyResult}>
                        {() => <DifferentContent
                            activeLog={historyResult.activeLog}
                            oldLog={historyResult.oldLog}
                        />}
                    </If>
                </KirokuLoader>

            </div>
        </KirokuModalBody>
        <KirokuModalFooter>
            <KirokuButton
                color={'white'}
                onClick={() => {
                    closeModal();
                }}
            >
                {t('common.close')}
            </KirokuButton>
        </KirokuModalFooter>
    </KirokuModal>);
}

function DifferentContent({ oldLog, activeLog }) {
    const {
              differences,
              recordAfter, recordBefore,
              statusRecordChange, servicedAt,
          }     = useDifferenceRecordHistory(oldLog, activeLog);
    const { t } = useTranslation();

    return (
        <div>
            {
                !recordAfter && !recordBefore ? <div><KirokuLoading/></div> :
                    <div className={'pb-3'}>
                        <div className={'pl-3 pr-3 pt-3'}>
                            <div className={'d-flex'}>
                                <span style={{ fontSize: 14 }}>{t('logsRecord.statusRecord')}: &nbsp;</span>
                                <span
                                    hidden={statusRecordChange.statusRecordAfter === statusRecordChange.statusRecordBefore}
                                >
                                    <div className={'d-flex align-items-center'}>
                                        <div className={'text-decoration'} style={{ fontSize: 14 }}>
                                            {t(`logsRecord.${statusRecordChange.statusRecordBefore}`)}
                                        </div>
                                        <i className="fas fa-arrow-right pl-2 pr-2"/>
                                        <div style={{ fontSize: 14 }}>
                                            {t(`logsRecord.${statusRecordChange.statusRecordAfter}`)}
                                        </div>
                                    </div>
                                </span>
                                <span
                                    hidden={statusRecordChange.statusRecordAfter !== statusRecordChange.statusRecordBefore}
                                >
                                    <div style={{ fontSize: 14 }}>
                                        {t(`logsRecord.${statusRecordChange.statusRecordAfter}`)}</div>
                                </span>
                            </div>
                            <div className={`${!servicedAt.isChangeServicedAt ? 'd-flex' : ''}`}>
                                <div style={{ fontSize: 14 }}>{t('logsRecord.servicedAt')}: &nbsp;</div>
                                <div className="d-flex">
                                    <span hidden={!servicedAt.isChangeServicedAt}>
                                        <div className={'d-flex align-items-center'}>
                                            <div className={'text-decoration'} style={{ fontSize: 14 }}>
                                            { moment.unix(servicedAt.before.servicedAt).format(servicedAt.before.has_time ? t('dateTimeFormat.YearMonthDateWithHour') : t('dateTimeFormat.YearMonthDateWithDay'))}
                                            </div>
                                            <i className="fas fa-arrow-right pl-2 pr-2"/>
                                            <div style={{ fontSize: 14 }}>
                                            { moment.unix(servicedAt.after.servicedAt).format(servicedAt.after.has_time ? t('dateTimeFormat.YearMonthDateWithHour') : t('dateTimeFormat.YearMonthDateWithDay'))}
                                            </div>
                                        </div>
                                    </span>
                                    <span hidden={servicedAt.isChangeServicedAt}>
                                        <div style={{ fontSize: 14 }}>
                                        { moment.unix(servicedAt.after.servicedAt).format(servicedAt.after.has_time ? t('dateTimeFormat.YearMonthDateWithHour') : t('dateTimeFormat.YearMonthDateWithDay'))}
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        {differences.length ?
                            RenderDifferenceQuestion(differences, recordBefore.survey_template.questions, recordAfter.survey_template.questions)
                            : <div className={'text-center'}>
                                <div style={{ fontSize: 24 }}>{t('logsRecord.notChange')}</div>
                            </div>}
                    </div>
            }
        </div>
    );
}





import React, { useEffect, useState } from 'react';
import {
    KirokuCardWeb, KirokuCheckDraft,
    KirokuTable, TBody, KirokuCardList, KirokuPopoverItem,
    THeader, KirokuTabNoResult, ComponentWeb, ComponentMobile, KirokuCard,
    KirokuLoading, KirokuLoader,
}
    from '../../../ui';
import moment               from 'moment';
import config               from '../../../config';
import { Link }             from 'react-router-dom';
import { useTranslation }   from 'react-i18next';
import If                   from '../../If';
import TogglePopover        from '../../Templates/QuestionTypes/TogglePopover';
import { resources }        from '@kiroku/api';

function NotFoundRecordCustomerDetail() {
    return (
        <KirokuTabNoResult messageFirst={'record'}>
            <img
                className='pt-2 pb-2'
                src={'/assets/img/empty-survey.svg'}
                alt="error img"/>
        </KirokuTabNoResult>
    );
}

function RecordsTabCustomerDetail({result, loading}) {
    const { t }        = useTranslation();
    return <div>
        <KirokuCardList headerLeft={<span>{t('common.Records')}</span>}
                        headerRight={<span>{t('common.Action')}</span>}
        >
            <KirokuLoader loading={ loading }>
            <If statement={result.length}
                otherwise={<NotFoundRecordCustomerDetail/>}
            >
                {() => result.map((record, index) => {
                    return(
                        <KirokuCard key={index}
                                    action={<TogglePopover>
                                        <KirokuPopoverItem>
                                            <div className='d-flex justify-content-between p-1 fix-icon-align'>
                                                <Link to={`/records/edit/${record.id}`}>
                                                    <i className="fas fa-pencil-alt"/><span
                                                    className='ml-1'>{t('common.Edit')}</span>
                                                </Link>
                                            </div>
                                        </KirokuPopoverItem>
                                    </TogglePopover>}
                        >

                            <Link to={`/records/${record.id}`}>{record['survey_template'].title}&nbsp;&nbsp;
                                <KirokuCheckDraft draftable={record}/>
                            </Link>
                            <p>{t('common.ServiceTime')}:
                            { moment.unix(record.serviced_at).format(record.has_time ? t('dateTimeFormat.YearMonthDateWithHour') : t('dateTimeFormat.YearMonthDateWithDay'))}
                            </p>
                        </KirokuCard>
                    )
                } )}

            </If>
            </KirokuLoader>
        </KirokuCardList>
    </div>;
}


export default function RecordTab(props) {
    const { t }                       = useTranslation();
    const { customer }                = props;
    const [loading, setLoading]       = useState(false);
    const [records, setRecords]       = useState([]);
    const [loadingAPI, setLoadingAPI] = useState(false);
    const [hasNext, setHasNext]       = useState(false);
    const { search, cancelAPISearch, pagination } = resources.useSearch('Record', { type: 'customer', page: 1, customerId: [customer.id] });

    useEffect(() => {
        if (props.show) {
            setLoadingAPI(true);
            search({ type: 'customer', page: 1, customerId: [customer.id] })
                .then(res => {
                    setHasNext(res.hasNext)
                    setLoadingAPI(false)
                    setRecords(res.result);
                }).catch(err => {
                    setLoadingAPI(false);
                    console.log(err);
                }
            );
        }
        return () => {
            cancelAPISearch();
        };
    }, [customer.id, cancelAPISearch, search, props.show]);

    function isScrolling() {
        if (window.innerWidth < config.configWithMobile) {
            if ((Math.ceil(document.documentElement.scrollTop) >= document.documentElement.offsetHeight - (window.innerHeight + 100))
                && (hasNext && !loading )
            ) {
                setLoading(true);
                search({ type: 'customer', page: pagination.currentPage + 1, customerId: [customer.id] }).then(res => {
                    setHasNext(res.hasNext)
                    setRecords(records.concat(res.result));
                    setLoading(false);
                });
            }
        } else {
            if (window.innerHeight + Math.ceil(document.documentElement.scrollTop) + 10 >= document.documentElement.offsetHeight){
                if (hasNext && !loading) {
                    setLoading(true);
                    search({ type: 'customer', page: pagination.currentPage + 1, customerId: [customer.id] }).then(res => {
                        setHasNext(res.hasNext)
                        setRecords(records.concat(res.result));
                        setLoading(false)
                    });
                }
            }
        }
    }

    useEffect(() => {
        if(props.show) {
            window.addEventListener('scroll', isScrolling);
            return () => window.removeEventListener('scroll', isScrolling);
        }
    });

    return (
        <div hidden={!props.show}>
            <ComponentWeb>
                <KirokuCardWeb>
                    <div className="p-3">
                        <KirokuLoader loading={loadingAPI}>
                            <If statement={records.length}
                                otherwise={<NotFoundRecordCustomerDetail/>}
                            >
                                {() => <KirokuTable>
                                    <THeader>
                                        <tr>
                                            <th>{t('common.Name')}</th>
                                            <th>{t('common.Description')}</th>
                                            <th style={{ whiteSpace: 'nowrap' }}>{t('common.ServiceTime')}</th>
                                            <th style={{ width: 240 }} className='text-center'>{t('common.Action')}</th>
                                        </tr>
                                    </THeader>
                                    <TBody>
                                        { records.map(record =>
                                            <tr key={ record.id }>
                                                <td><Link
                                                    to={ `/records/${ record.id }` }>{ record.survey_template.title }</Link>
                                                    <span className='ml-6'> <KirokuCheckDraft draftable={ record }/></span></td>
                                                <td>{ record['survey_template'].description }</td>
                                                <td style={ { whiteSpace: 'nowrap' } }>
                                                    { moment.unix(record.serviced_at).format(record.has_time ? t('dateTimeFormat.YearMonthDateWithHour') : t('dateTimeFormat.YearMonthDateWithDay')) }
                                                </td>
                                                <td className='fix-center'>
                                                    <div
                                                        className='d-flex justify-content-between p-1 fix-icon-align'>
                                                        <Link to={ `/records/edit/${ record.id }` }
                                                              style={ { minWidth: 50 } }
                                                        >
                                                            <i className="fas fa-pencil-alt text-secondary"/>
                                                            <span
                                                                className='ml-1  text-dark'>{ t('common.Edit') }</span>
                                                        </Link>
                                                        {/*<div className="pr-1 pl-1"/>*/ }
                                                        {/*<div className='disabled-button-link width-max-content'>*/ }
                                                        {/*    <Link to={`/records/edit/${record.id}`}>*/ }
                                                        {/*        <i className="fas fa-pencil-alt text-secondary"/><span*/ }
                                                        {/*        className='ml-1  text-dark'>{t('common.EditRecordOldUI')}</span>*/ }
                                                        {/*    </Link>*/ }
                                                        {/*</div>*/ }
                                                    </div>
                                                </td>
                                            </tr>,
                                        ) }
                                    </TBody>
                                </KirokuTable>}
                            </If>
                        </KirokuLoader>
                    </div>
                </KirokuCardWeb>
            </ComponentWeb>
            <ComponentMobile>
                <RecordsTabCustomerDetail {...props} result={ records } loading={loadingAPI}/>
            </ComponentMobile>
            <div hidden={!hasNext || loadingAPI}>
                {loading && <KirokuLoading/>}
            </div>
            <div className={'d-block d-xs-block d-sm-block d-md-none d-lg-none'} style={ { paddingBottom: 80 } }/>
        </div>
    );
}

import { resources }         from '@kiroku/api';
import React, {
    useEffect, useState,
}                            from 'react';
import { getGroupedOptions } from '../../Converter';
import {
    ComponentMobile, ComponentWeb, KirokuSectionDropdownShowMore, KirokuSectionDropdownShowMoreMobile,
}                            from '../../../ui';
import { useTranslation }    from 'react-i18next';
import { useConditionTag }   from './useConditionFromParams';

function DropdownSelectTeamCondition(props) {
    const { t }                                          = useTranslation();
    const { teamTags }                                   = props;
    const { result, search, cancelAPISearch }            = resources.useSearch('Team');
    const [currentTagTeam, getInfoTag, cancelGetInfoTag] = useConditionTag('Team');
    const [currentTags, setCurrentTags]                  = useState(currentTagTeam);

    useEffect(() => {
        setCurrentTags(currentTagTeam);
    }, [currentTagTeam]);

    useEffect(() => {
        if (teamTags) {
            getInfoTag(teamTags);
        } else {
            setCurrentTags([]);
        }
        return () => {
            cancelGetInfoTag();
        }
    }, [teamTags, getInfoTag, cancelGetInfoTag]);

    useEffect(() => {
        search({ type: 'all' });
        return () => {
            cancelAPISearch();
        }
    }, [search, cancelAPISearch]);

    let teams = getGroupedOptions('Team', result);

    const handleChangeTags = (options) => {
        setCurrentTags(options || []);
        props.handleChangeTeamTags(options);
    };

    const handleSearch = () => {
        props.handleSearchTags();
    };

    return (
        <div className={'pb-2'}>
            <ComponentWeb>
                <KirokuSectionDropdownShowMore
                    isSearchTags
                    tagType={'Team'}
                    onChange={handleChangeTags}
                    value={currentTags}
                    options={teams}
                    handleSearchTags={handleSearch}
                    placeholder={t('Team.placeholderSearch')}
                />
            </ComponentWeb>
            <ComponentMobile>
                <KirokuSectionDropdownShowMoreMobile
                    isSearchTags
                    tagType={'Team'}
                    onChange={handleChangeTags}
                    value={currentTags}
                    options={teams}
                    handleSearchTags={handleSearch}
                    placeholder={t('Team.placeholderSearch')}
                />
            </ComponentMobile>
        </div>
    );
}

export default DropdownSelectTeamCondition;

import { useTranslation }          from 'react-i18next';
import { KirokuCardDeletedScreen } from '../../../ui';
import FormatTimestamp             from '../CommonComponent/FormatTimestamp';
import React                       from 'react';

export function SurveyList(props) {
    const { surveyTemplates } = props;
    const { t }               = useTranslation();

    return (
        <div className="">
            <div className={'bg-white'}>
                <KirokuCardDeletedScreen>
                    {surveyTemplates.map((surveyTemplate, index) => (
                        <div key={index}>
                            <div className='pl-3 pt-3'>
                                <div className='header-card-del word-break'>{surveyTemplate.title}</div>
                                <div className='title-card-del pt-10px'>{t('DeletedList.deletedAt')} : <FormatTimestamp
                                    timestampUnix={surveyTemplate.delete_at}/></div>
                                <div className='title-card-del pt-10px'>{t('DeletedList.createdAt')} : <FormatTimestamp
                                    timestampUnix={surveyTemplate.create_at}/></div>
                            </div>
                            <hr className='hr-table-mobile'/>
                        </div>
                    ))}
                </KirokuCardDeletedScreen>
            </div>
        </div>
    );
}

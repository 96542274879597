import React, { useState }                                                                        from 'react';
import { ComponentWeb, KirokuCardBodyWeb, KirokuCardWeb, KirokuInputDate, KirokusSortableHandle } from '../../../ui';
import { useTranslation }                                                                         from 'react-i18next';
import ActionGroupBtn
                                                                                                  from './ActionGroupBtn';
import QuestionCardTitle
                                                                                                  from './QuestionTitleCard';
import InfoQuestion                                                                               from './InfoQuestion';
import CheckboxUnrequited
                                                                                                  from './CheckboxUnrequited';
import moment                                                                                     from 'moment';
import useQuestions
                                                                                                  from '../MakeSurvey/useQuestions';
import { modalSetLogic }                                                                          from '../ModalSetLogicByQuestionType';
import ModalEditQuestion
                                                                                                  from '../FormQuestion/ModalEditQuestion';
import ComponentMobile
                                                                                                  from '../../../ui/ComponentMobile';
import KirokuInputTime
                                                                                                  from '../../../ui/KirokuInputTime';

export default function Time({ question }) {
    const { t }                                                 = useTranslation();
    const { update, copy, delQuestion }                         = useQuestions();
    const ModalSetLogic                                         = modalSetLogic[question.type];
    const [isOpenModalEditQuestion, setIsOpenModalEditQuestion] = useState(false);
    const [isOpenModalName, setIsOpenModalName]                 = useState(null);

    return (
        <div id='component-question'>
            <div id='web-q' className='pb-3'>
                <KirokuCardWeb>
                    <KirokusSortableHandle>
                        <QuestionCardTitle
                            index={question.index}
                            isLogic={typeof question.nextQuestion === 'number'}
                        >
                            <ActionGroupBtn
                                handleOpenModalAddLogic={() => {
                                    setIsOpenModalName(question.index);
                                }}
                                handleOpenModalEdit={() => {
                                    setIsOpenModalEditQuestion(true);
                                }}
                                question={question}
                                resetDefaultAnswer={() => {
                                    if (question.defaultAnswer !== undefined) {
                                        delete question.defaultAnswer;
                                        update(question);
                                    }
                                }}
                                handleDel={() => delQuestion(question.index)}
                                handleCopy={() => {
                                    copy(question);
                                }}
                            />
                        </QuestionCardTitle>

                    </KirokusSortableHandle>

                    <KirokuCardBodyWeb>
                        <CheckboxUnrequited
                            question={question}
                            isNotUse={typeof question.nextQuestion === 'number'}
                        />
                        <InfoQuestion question={question}/>
                        <p className='text-info'
                           hidden={typeof question.nextQuestion !== 'number'}
                        >
                            <i className='fas fa-arrow-right text-info'>
                                {t('createRecord.Question')} : {question.nextQuestion + 1}
                            </i>
                        </p>
                        <div className='answer-question bg-kiroku-date' style={{ maxWidth: 250 }}>
                            <ComponentMobile>
                                <KirokuInputTime
                                    name={`${question.index}-time`}
                                    value={question.defaultAnswer}
                                    readOnly={true}
                                    onChange={(timestampUnix) => {
                                        let newQuestion = { ...question };
                                        newQuestion     = {
                                            ...newQuestion,
                                            defaultAnswer: typeof timestampUnix === 'number' ? timestampUnix : '',
                                        };
                                        update(newQuestion);
                                    }}
                                />
                            </ComponentMobile>
                            <ComponentWeb>
                                <KirokuInputDate
                                    inputProps={{ readOnly: true }}
                                    value={question.defaultAnswer ? moment.unix(question.defaultAnswer) : ''}
                                    timeFormat={t('dateTimeFormat.HourMinute')}
                                    dateFormat={false}
                                    onChange={(momentTime) => {
                                        let newQuestion = { ...question };
                                        newQuestion     = {
                                            ...newQuestion,
                                            defaultAnswer: typeof momentTime === 'object' ? momentTime.unix() : '',
                                        };
                                        update(newQuestion);
                                    }}
                                />
                            </ComponentWeb>
                        </div>
                    </KirokuCardBodyWeb>
                </KirokuCardWeb>

                <ModalEditQuestion
                    isOpenModalEditQuestion={isOpenModalEditQuestion}
                    handleAction={() => setIsOpenModalEditQuestion(!isOpenModalEditQuestion)}
                    question={question}
                    updateQuestion={(questionData) => update(questionData)}
                />

                <ModalSetLogic
                    question={question}
                    isOpen={isOpenModalName === question.index}
                    confirm={() => {
                        setIsOpenModalName(null);
                    }}
                    cancellation={() => setIsOpenModalName(null)}
                />
            </div>
        </div>
    );
}


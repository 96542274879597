import React, {Component} from 'react';
import moment             from 'moment';
import KirokuButton       from "./KirokuButton";
import {useTranslation}   from 'react-i18next';
// import connect            from "react-redux/es/connect/connect";
// import {NOTIFICATION}     from "../actions/Notification";
import config             from '../config';

// @connect(() => {
//     return {
//         //profile: state.KirokuAIUser.profile
//     }
// }, (dispatch) => {
//     return {
//         notification: (t) => {
//             dispatch({
//                 type: NOTIFICATION,
//                 typeNotification: 'success',
//                 message: t('message.LoadSuccess')
//             })
//         }
//     }
// })
class KirokuNotificationDraft extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: !!this.props.draft
        }
    }

    closeModal = () => {
        this.setState({
            show: false
        })
    };

    loadDraft = () => {
        this.props.loadDraft();
        this.props.notification(this.props.t);
        this.closeModal();
    };

    discardDraft = () => {
        this.props.discardDraft();
        this.closeModal();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.draft !== this.props.draft) {
            this.setState({
                show: !!nextProps.draft
            })
        }
    }

    render() {
        const { t } = useTranslation();
        const { draft} = this.props;
        return (
            !!draft ? (
                <div hidden={!this.state.show} className='card mb-3' style={{background: '#e7f9f5'}}>
                    <div className='padding-responsive d-flex justify-content-between'>
                        <div style={{margin: 'auto 0'}}>
                            <i className="fa fa-info-circle"/>
                            <span>&nbsp;
                                {t('Surveys.LastSavedDraft')}
                                {/*{t('common.backupRecord')}*/}
                                {moment.unix(draft.create_at).format(config.formatDateTimeTh)}</span>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <KirokuButton
                                    color={'primary'}
                                    onClick={this.loadDraft.bind(this)}
                                >{t('createRecord.loadDraft')}
                                </KirokuButton>
                            </div>
                            <div className='pr-1 pl-1'/>
                            <div>
                                <KirokuButton
                                    color={'white'}
                                    onClick={this.discardDraft.bind(this)}>{t('common.close')}
                                </KirokuButton>
                            </div>
                        </div>
                    </div>
                </div>
            ) : ''
        );
    }
}

export default KirokuNotificationDraft;

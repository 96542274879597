import React, { useEffect, useState } from 'react';
import { KirokuDateRangePicker }      from '../../../../ui';
import { useTranslation }             from 'react-i18next';
import { useCondition }               from '../../useCondition';
import { useRecordContext }           from '../../ConditionBuilder/RecordContext';
import * as moment                    from 'moment';

export default function UpdatedAtCondition({ startDateId, endDateId }) {
    const { t }                           = useTranslation();
    const { setDateRange }                = useCondition();
    const [dateRange, setUpdateDateRange] = useState({
        startDate: null,
        endDate  : null,
    });

    const { recordCondition } = useRecordContext();

    useEffect(() => {
        setUpdateDateRange({
            startDate: recordCondition.updateStartDate ? moment.unix(recordCondition.updateStartDate) : null,
            endDate  : recordCondition.updateEndDate ? moment.unix(recordCondition.updateEndDate) : null,
        });
    }, [recordCondition.updateEndDate, recordCondition.updateStartDate, setUpdateDateRange]);

    return (
        <div className={ 'row pt-2' }>
            <div className="col-lg-4">
                <div className={ 'pt-8 title-date-time' }
                     style={ { minWidth: 98 } }>{ t('RecordsBeta.updatedAt') }</div>
            </div>
            <div className="col-lg-8">
                <div className={ 'fix-height-date-range' }>
                    <div className="date-time-range custom-clear-date">
                        <KirokuDateRangePicker
                            startDateId={ startDateId }
                            endDateId={ endDateId }
                            minimumNights={ 0 }
                            startDate={ dateRange.startDate }
                            endDate={ dateRange.endDate }
                            onChangeDate={ (date) => {
                                setDateRange({
                                    updateStartDate: date.momentStartDate,
                                    updateEndDate  : date.momentEndDate,
                                });
                                setUpdateDateRange({
                                    startDate: date.startDate,
                                    endDate  : date.endDate,
                                });
                            } }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

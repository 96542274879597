import React, { useEffect, useState }                                                 from 'react';
import { authentication, resources }                                                  from '@kiroku/api';
import { useTranslation }                                                             from 'react-i18next';
import If                                                                             from '../../../If';
import { KirokuCardWeb, KirokuLabel, KirokuLoader, KirokuNotFound, KirokuPagination } from '../../../../ui';
import { Link }                                                                       from 'react-router-dom';
import { useSearchCustomerBeTaggedUser }                                              from '../useSearchCustomerBeTaggedUser';
import useSearchTeamOfUser                                                            from '../useSeachTeamOfUser';
import lodash                                                                         from 'lodash';
import ConditionSearch                                                                from '../ConditionSearch';
import moment                                                                         from 'moment';

export default function RecordTab(props) {
    const { tabType, template, params } = props;

    const { user } = authentication.useAuthenticatedContext();
    const {
              result, search, pagination,
              cancelAPISearch, condition
          }        = resources.useSearch('Record', { type: 'condition' });

    const [tabActive, setTabActive]       = useState('IS_USER');
    const [isLoadingTab, setIsLoadingTab] = useState(true);

    const { searchCustomers }    = useSearchCustomerBeTaggedUser(user.id);
    const { searchTeamByUserId } = useSearchTeamOfUser(user.id);

    const { t } = useTranslation();

    useEffect(() => {
        if (tabType === 'RECORD_TAB' && tabActive === 'IS_USER' && parseInt(params.id) === template.id) {
            setIsLoadingTab(true);
            searchCustomers().then(res => {
                search({
                    type      : 'condition',
                    userId    : user.id,
                    templateId: template.id,
                    page      : 1,
                    customerId: res.result.map(customer => customer.id),
                }).then(() => {
                    setIsLoadingTab(false);
                }).catch(error => {
                    console.log(error);
                    setIsLoadingTab(false);
                });
            });
        }

        if (tabType === 'RECORD_TAB' && tabActive === 'TEAM' && parseInt(params.id) === template.id) {
            setIsLoadingTab(true);
            searchTeamByUserId().then(res => {
                let conditionSearch = {
                    type      : 'condition',
                    templateId: template.id,
                    page      : 1,
                    customerId: [],
                };

                if (res.result.length) {
                    const teams     = res.result.map(team => team.customers);
                    const customers = teams.length ? teams.reduceRight((customer, customerNext) => customer.concat(customerNext)) : [];

                    conditionSearch = {
                        type      : 'condition',
                        templateId: template.id,
                        customerId: lodash.uniqBy(customers, 'id')
                            .map(customer => customer.id),
                        page      : 1,
                    };
                }

                if (!conditionSearch.customerId.length) {
                    delete conditionSearch.customerId;
                }
                search(conditionSearch).then(() => {
                    setIsLoadingTab(false);
                }).catch(error => {
                    console.log(error);
                    setIsLoadingTab(false);
                });
            });
        }

        if (tabType === 'RECORD_TAB' && tabActive === 'ALL' && parseInt(params.id) === template.id) {
            setIsLoadingTab(true);
            search({
                type      : 'condition',
                templateId: template.id,
                page      : 1,
            }).then(() => {
                setIsLoadingTab(false);
            }).catch(error => {
                console.log(error);
                setIsLoadingTab(false);
            });
        }
        return () => cancelAPISearch();
    }, [tabType, search, user, template, cancelAPISearch, searchCustomers, tabActive, searchTeamByUserId, params]);

    return (<div hidden={props.tabType !== 'RECORD_TAB'}>
        <div className="pt-3">
            <ConditionSearch
                tabActive={tabActive}
                setTabActive={setTabActive}
            />
            <div className="pt-3">
                <If statement={result.length}>
                    {() => <div className={'d-flex justify-content-end'}>
                        <KirokuPagination
                            disabledPrev={pagination.currentPage === 1}
                            disabledNext={pagination.currentPage === pagination.totalPage}
                            increasePage={() => {
                                setIsLoadingTab(true);
                                search({...condition, page: pagination.currentPage + 1})
                                    .then(() => {
                                        setIsLoadingTab(false);
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    })
                            }}
                            decreasePage={() => {
                                setIsLoadingTab(true);
                                search({...condition, page: pagination.currentPage - 1})
                                    .then(() => {
                                        setIsLoadingTab(false);
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    })
                            }}
                            currentPage={pagination.currentPage}
                            totalPage={pagination.totalPage}
                        />
                    </div>}
                </If>
                <KirokuCardWeb>
                    <KirokuLoader loading={isLoadingTab}>
                        <KirokuNotFound isResults={result.length}>
                            <div>
                                {result.map((recordItem, index) => {
                                    return <div key={index}>
                                        <div className={'p-2'}>
                                            <div>
                                                <span className={'font-customer-name-detail'}
                                                      style={{ fontSize: 16 }}>
                                                    {recordItem.customer &&
                                                    <Link to={`/customers/${recordItem.customer.id}`}>
                                                        {recordItem.customer.name}
                                                    </Link>}
                                                </span>
                                                <span className={'font-customer-name-detail'}
                                                      style={{ fontSize: 12 }}>{t('RecordsBeta.noun')}</span>
                                                <span className='ml-6'>
                                                    {recordItem.drafted_at && <KirokuLabel
                                                        style={{
                                                            backgroundColor: '#EA9B3F',
                                                            color          : 'white',
                                                            marginBottom   : 0
                                                        }}
                                                    >
                                                        {t('Surveys.Draft')}
                                                    </KirokuLabel>}
                                                </span>
                                            </div>
                                            <div className="pt-10"/>
                                            <div>{t('common.ServiceTime')}：
                                                {recordItem.serviced_at ? moment.unix(recordItem.serviced_at).format(t(`dateTimeFormat.${recordItem.has_time ? 'YearMonthDateWithHour': 'YearMonthDateWithDay'}`)) : ''}
                                            </div>
                                            <div className="pt-10"/>
                                            <div>{t('common.recorder')}：{recordItem.user && recordItem.user.name}</div>
                                        </div>
                                        <hr hidden={result.length - 1 === index} className={'m-0'}/>
                                    </div>;
                                })}
                            </div>
                        </KirokuNotFound>

                    </KirokuLoader>
                </KirokuCardWeb>
            </div>
        </div>
    </div>);
}

import React from 'react';

export default function KirokuCheckBox(props) {

    return (
        <div className={'d-flex'}>
            <label className="check-group">
                <input type="checkbox" {...props}  />
                <span className="checkmark"/>
            </label>
            <label style={{...props['stylelabel']}} className='content-checkbox pl-2' htmlFor={props.id}>{props.content}</label>
        </div>
    );
}

import React from 'react';
import {KirokuButton} from "../../../ui";
import {useTranslation} from "react-i18next";

export default function SearchByHiragana({handleSearch, furiganaSearch}) {
    const hiraganaArray = ['あ', 'か', 'さ', 'た', 'な', 'は', 'ま', 'や', 'ら', 'わ'];
    const {t} = useTranslation();
    return (
        <div className=" btn-group d-flex flex-wrap-wrap" style={{rowGap: 5}}>
            {hiraganaArray.map((item, index) => {
                return (
                    <button key={index}
                            className={`${item === furiganaSearch ? 'is-active-text' : ''} btn btn-outline-primary`}
                            style={index === hiraganaArray.length - 1 ? {
                                borderRadius: 0, height: 35, width: 38, padding: 0,
                                marginRight: '1rem'
                            }: {
                                borderRadius: 0, height: 35, width: 38, padding: 0,
                            }}
                            onClick={() => {
                                if (item === furiganaSearch) {
                                    handleSearch('');
                                } else {
                                    handleSearch(item);
                                }
                            }}
                    >
                        {item}
                    </button>
                );
            })}
            <div>
                <KirokuButton
                    onClick={() => handleSearch('')}
                    color={'primary'}
                    style={{borderRadius: 0, height: 35, padding: '0 1rem'}}
                >{t('common.clearConditionBtn')}</KirokuButton>
            </div>
        </div>
    );
}

import React, { useState }  from 'react';
import { useTranslation }   from 'react-i18next';
import ModalChoiceCustomers from './ModalSelectCustomer/ModalChoiceCustomers';
import _                    from 'lodash';

export default function CustomerCondition({ tabActive, searchPlanning, customerDefault, customerCondition, customerDefaultByTeam }) {
    const { t }                     = useTranslation();
    const [showModal, setShowModal] = useState('');

    const searchCustomerByTeam = () => {
        const teams      = customerDefaultByTeam.map(team => team.customers);
        const customers  = teams.length ? teams.reduceRight((customer, customerNext) => customer.concat(customerNext)) : [];
        const customerId = _.uniqBy(customers, 'id').map(customer => customer.id);
        searchPlanning({ customerId, tabActive: 'TEAM' });
    };

    const searchPlanByCustomerDefault = () => {
        const customerId = customerDefault.map(customer => customer.id);
        searchPlanning({ customerId, tabActive: 'CUSTOMER_DEFAULT' });
    };

    const searchCustomerSelected = (customerId) => {
        searchPlanning({ customerId, tabActive: 'CHOICE_CUSTOMER' });

    };

    return (<div className="d-flex justify-content-around border-btn-status-group-customer-condition cursor-pointer">
        <div className={ `border-width-each-status-gray-customer-condition first-btn font-text-on-btn-customer-condition 
                                  ${ tabActive === 'CUSTOMER_DEFAULT' ? 'btn-active' : '' }` }
             onClick={ () => {
                 searchPlanByCustomerDefault();
             } }
        >
            { t('common.customersTaggedTemplate') }
        </div>
        <div className={ `border-width-each-status-gray-customer-condition-center  font-text-on-btn-customer-condition
                                ${ tabActive === 'TEAM' ? 'btn-active' : '' }` }
             onClick={ () => {
                 searchCustomerByTeam();
             } }
        >
            { t('common.team') }
        </div>
        <div className={ `border-width-each-status-gray-customer-condition third-btn font-text-on-btn-customer-condition
                                ${ tabActive === 'CHOICE_CUSTOMER' ? 'btn-active' : '' }` }
             onClick={ () => {
                 setShowModal('SHOW_MODAL');
             } }
        >
            { t('RecordsBeta.choice') }
        </div>
        <ModalChoiceCustomers show={ showModal === 'SHOW_MODAL' }
                              customerDefault={ customerDefault }
                              customerCondition={ customerCondition }
                              onCloseModal={ () => setShowModal('') }
                              searchCustomerModal={ searchCustomerSelected }
        />
    </div>);
}

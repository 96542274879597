import React                                                  from 'react';
import useCustomerInformation                                 from './useCustomerInformation';
import { ArrayStringDiff, DateDiff, GeneralDiffKeyTranslate } from '../DifferenceType';
import { useTranslation }                                     from 'react-i18next';
import If                                                     from '../../../../If';

const DiffComponents = {
    contract_start: DateDiff,
    contract_end  : DateDiff,
    classification: GeneralDiffKeyTranslate,
    // certificate

};

export default function CustomerInformation({ log, old }) {
    const { customerInfoDiffs, certificateDiffs } = useCustomerInformation(old.data, log.data);
    const { t }                                   = useTranslation();

    return (
        <div className="p-2">
            <div className={'font-size-16 pb-10px'}>{t('customerDifference.customerInformation')}</div>
            <If statement={customerInfoDiffs || certificateDiffs.isDiff}
                otherwise={<div className={'text-center'}>
                    <div style={{ fontSize: 24 }} className={'pb-10px'}>{t('customerDifference.notChange')}</div>
                </div>}
            >
                {() => <React.Fragment>
                    <If statement={customerInfoDiffs}>
                        {() => customerInfoDiffs.map((customerInfoDiff, index) => {
                            const DiffComponent = DiffComponents[ customerInfoDiff.path[ 0 ] ];
                            return <div key={index} className="d-flex align-items-center">
                                <b>{t(`customerDifference.${customerInfoDiff.path[ 0 ]}`)}: </b> &nbsp;
                                {DiffComponent &&
                                <DiffComponent lhs={customerInfoDiff.lhs} rhs={customerInfoDiff.rhs}/>}
                            </div>;
                        })}
                    </If>

                    <If statement={certificateDiffs.isDiff}>
                        {() => <div className="d-flex align-items-center">
                            <b>{t(`customerDifference.certificate`)}: </b>
                            <ArrayStringDiff lhs={certificateDiffs.lhs} rhs={certificateDiffs.rhs}/>
                        </div>}
                    </If>
                </React.Fragment>}
            </If>

        </div>
    );
}

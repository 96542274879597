import { useEffect }       from 'react';
import * as Sentry         from '@sentry/browser';
import { authentication }  from '@kiroku/api';

export default function SentryAuthenticatedScope({children}) {
    const { user } = authentication.useAuthenticatedContext();

    useEffect(() => {
        Sentry.configureScope(function(scope) {
            scope.setUser({
                username         : user.username,
                id               : user.id,
                organization_id  : user.organization_id,
                organization_name: user.organization_name,
            });
        });
    })

    return children;
}

import React               from 'react';
import { useTranslation }  from 'react-i18next';
import KirokuDropdownClose from '../../../../ui/KirokuDropdownClose';

export default function BulkActionSetRoleMobile(props) {
    const { t }        = useTranslation();
    const { location } = props;

    const bulkActionItems = [
        {
            value    : 'selectedLeader',
            component: (<div>{ t('Team.SetLeader') }</div>),
            label    : t('Team.SetLeader'),
        },
        {
            value    : 'selectedStaff',
            component: (<div>{ t('Team.SetStaff') }</div>),
            label    : t('Team.SetStaff'),
        },
        // {
        //     value    : 'selectedAssign',
        //     component: (<div>{ t('common.AssignToTeam') }</div>),
        //     label    : t('common.AssignToTeam'),
        // },
    ];

    return (
        <KirokuDropdownClose
            { ...props }
            items={ bulkActionItems }
            location={ location || 'bot' }
        />

    );
}

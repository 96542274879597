import React              from 'react';
import { useTranslation } from 'react-i18next';


export default function TabComponent({handleSelect, tabType, count, monthSelectionRange, isGroupCustomer}) {
    const { t } = useTranslation();

    const setScrollingToView = (tab) => {
        let element = document.getElementById(tab);
        element.scrollIntoView({ block: "nearest", inline: "center"});
    }

    return(
        <div className="tab-component-monitor">
            <div className={`tab-begin cursor-pointer ${tabType === 'monitor_term' ? 'btn-active' : '' }`} id={'monitor_term'}
                 onClick={() => {
                         setScrollingToView('monitor_term');
                         handleSelect({
                             type        : 'monitor_term',
                             page: 1,
                             monitor_term:  monthSelectionRange.startMonth,
                         });
                 }}
            >{t('plans.monitoringTermsTab')} &nbsp; <div>({count.countCustomer})</div>
            </div>
            <div className={`tab-middle cursor-pointer ${tabType === 'condition' ? 'btn-active' : '' }`} id={'condition'}
                 onClick={() => {
                         setScrollingToView('condition');
                         handleSelect({
                             type      : 'condition',
                             page: 1,
                             startMonth: monthSelectionRange.startMonth,
                             endMonth  : monthSelectionRange.endMonth,
                         });
                 }}
            >{t('plans.planTab')} &nbsp; <div>({count.countPlan})</div>
            </div>
            <div className={`tab-middle cursor-pointer ${tabType === 'report_month' ? 'btn-active' : '' }`} id={'report_month'}
                 onClick={() => {
                     setScrollingToView('report_month');
                     handleSelect({
                             type        : 'report_month',
                             page        : 1,
                             report_month: monthSelectionRange.startMonth,
                         });
                 }}
            > {t('CustomerEditor.ReportMonthTab')}<div>({count.countReport})</div>
            </div>
            <div className={`tab-middle cursor-pointer ${tabType === 'certification_expiration' ? 'btn-active' : '' }`} id={'certification_expiration'}
                 onClick={() => {
                     setScrollingToView('certification_expiration');
                     handleSelect({
                         type                    : 'certification_expiration',
                         page                    : 1,
                         certification_expiration: monthSelectionRange.startMonth,
                     });
                 }}
            > {t('Customer.CustomerExpiration')}<div>({count.countExpiration})</div>
            </div>
            <div className={`tab-end cursor-pointer ${(tabType === 'services_end_date' || tabType === 'customer_services_end_date') ? 'btn-active' : '' }`} id={'services_end_date'}
                 onClick={() => {
                         setScrollingToView('services_end_date');
                         if(isGroupCustomer) {
                             handleSelect({
                                 type        : 'customer_services_end_date',
                                 page: 1,
                                 customer_services_end_date:  monthSelectionRange.startMonth,
                             });
                         } else {
                             handleSelect({
                                 type        : 'services_end_date',
                                 page: 1,
                                 services_end_date:  monthSelectionRange.startMonth,
                             });
                         }
                 }}
            >{ t('Customer.service_end_date') } &nbsp; <div>({count.countServiceEndDate})</div>
            </div>
        </div>
    )
}

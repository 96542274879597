import { keepLogic, keepLogicWhenDelete, revertInitQuestion } from '../SurveyService';

export const SWITCH_CURRENT_SCREEN = 'SWITCH_CURRENT_SCREEN';
export const CHANGE_INFO_SURVEY    = 'CHANGE_INFO_SURVEY';
export const CREATE_QUESTION       = 'CREATE_QUESTION';
export const UPDATE_QUESTION       = 'UPDATE_QUESTION';
export const COPY_QUESTION         = 'COPY_QUESTION';
export const DELETE_QUESTION       = 'DELETE_QUESTION';
export const DRAG_QUESTION_SURVEY  = 'DRAG_QUESTION_SURVEY';


export default function SurveyReducer(state, action) {
    let questionsRaw = [...state.questions];
    switch (action.type) {
        case SWITCH_CURRENT_SCREEN:
            return {
                ...state,
                currentScreen: action.screenName,
            };
        case CHANGE_INFO_SURVEY:
            return {
                ...state,
                [ action.nameInput ]: action.value,
            };
        case CREATE_QUESTION:
            questionsRaw.push(action.question);
            return {
                ...state,
                questions: questionsRaw,
            };
        case UPDATE_QUESTION:
            return {
                ...state,
                questions: state.questions.map(question => {
                    if (question.index === action.question.index) {
                        return action.question;
                    }
                    return question;
                }),
            };
        case COPY_QUESTION:
            questionsRaw.push(revertInitQuestion(action.question));
            return {
                ...state,
                questions: questionsRaw.map((question, index) => {
                    return {
                        ...question,
                        index,
                    };
                }),
            };
        case DELETE_QUESTION:
            questionsRaw.splice(action.index, 1);
            questionsRaw = questionsRaw.map((question, index) => {
                question.index = index;
                return question;
            });
            questionsRaw = keepLogicWhenDelete(action.index, questionsRaw);
            return {
                ...state,
                questions: questionsRaw,
            };
        case DRAG_QUESTION_SURVEY:
            let questionsAfterDrag = action.questions.map((question, index) => {
                return {
                    ...question,
                    index,
                };
            });
            if (action.currentIndex < action.nextIndex) {
                for (let i = action.currentIndex; i < action.nextIndex; i++) {
                    questionsAfterDrag = keepLogic(questionsAfterDrag, i, i + 1);
                }
            } else {
                for (let i = action.currentIndex; i > action.nextIndex; i--) {
                    questionsAfterDrag = keepLogic(questionsAfterDrag, i, i - 1);
                }
            }

            return {
                ...state,
                questions: questionsAfterDrag,
            };
        default :
            return state;
    }
}

import { useCallback, useEffect, useState } from 'react';
import { resources }                        from '@kiroku/api';

export function useTeamDefaultCondition() {
    const { search, result, cancelAPISearch }               = resources.useSearch('Team');
    const [customerDefaultByTeam, setCustomerDefaultByTeam] = useState([]);

    useEffect(() => {
        if (result) {
            setCustomerDefaultByTeam(result);
        }
        return () => {
            cancelAPISearch();
        };
    }, [cancelAPISearch, result]);

    const getCustomerDefaultByTeam = useCallback((useId) => {
        return search({ type: 'all', userId: useId });
    }, [search]);

    return [customerDefaultByTeam, getCustomerDefaultByTeam];

}

import React, { useState } from 'react';
import './Accordion.css';
import moment              from 'moment';
import { useTranslation }  from 'react-i18next';
import { useHistory }      from 'react-router-dom';
import { KirokuButton }    from '../../../../../ui';

export const PlanHeader = ({ plan }) => {
    const { t }         = useTranslation();
    const now = moment().unix();
    const history = useHistory();

    return <>
        <div className={'col-overview'} >
            {plan.overview}
        </div>
        <div className={'col-customer'}>{plan.customer.name}</div>
        <div className={'col-startDate'}>
            { moment.unix(plan.start_at).format(t('dateTimeFormat.YearMonthDateWithDay'))}
        </div>
        <div className={'col-endDate'}>
            { moment.unix(plan.end_at).format(t('dateTimeFormat.YearMonthDateWithDay'))}
        </div>
        <div className={'col-status'}>
            <div hidden={plan.published_at || plan.review_at}>
                <img src={`/assets/icon/iconDraft.svg`} alt="iconDraft.svg"/>
                &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.draft')}</span>
            </div>
            <div hidden={!(plan.published_at && now < plan.start_at) || plan.review_at}>
                <img src={`/assets/icon/iconBeforeStart.svg`} alt="iconBeforeStart.svg"/>
                &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.beforeStart')}</span>
            </div>
            <div hidden={!(plan.published_at && now > plan.start_at && now < plan.end_at) || plan.review_at}>
                <img src={`/assets/icon/iconRunning.svg`} alt="iconRunning.svg"/>
                &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.running')}</span>
            </div>
            <div hidden={!(plan.published_at && now > plan.end_at)}>
                <img src={`/assets/icon/iconFinish.svg`} alt="iconFinish.svg"/>
                &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.finish')}</span>
            </div>
        </div>
        <div className={'col-review-status'}>
            <div hidden={plan.review_status !== 0}> {t('plans.unReview')}</div>
            <div hidden={plan.review_status !== 1}> {t('plans.draftReview')}</div>
            <div hidden={plan.review_status !== 2}> {t('plans.monitored')}</div>
        </div>
        <div className={'col-detail-btn'}>
            <KirokuButton color="light"
                          style={{
                              height: 36, fontSize: 14, width: 105,
                              backgroundImage: 'linear-gradient(to top, #f2f4f7, #ffffff)'
                          }}
                          onClick={() => history.push('/plans/' + plan.id)}
            >{t('plans.viewPlan')}</KirokuButton>
        </div>
    </>;
};


export default function AccordionUI({ openDefault = false, headElement, contentElement }) {

    const [isOpen, setIsOpen] = useState(openDefault);

    return (
        <div className={'accordion-ui'}>
            <div className={'accordion-header'} style={{ overflowX: 'scroll' }}>
                <div className={'icon-accordion'}>
                    <i className={`fa fa-chevron-${isOpen ? 'down' : 'right'}`}
                       style={{color: '#6C798F'}}
                       onClick={() => setIsOpen(!isOpen)}
                    />
                </div>
                {headElement}
            </div>
            <div hidden={!isOpen}>{contentElement}</div>
        </div>
    );
}

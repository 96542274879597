import React, { useState }       from 'react';
import { useAnalyticalSystem }   from './AnalyticalSystem/useAnalyticalSystem';
import { useTranslation }        from 'react-i18next';
import { useHeadMapRecordChart } from './Charts/HeadeMapRecordChart/useHeadMapRecordChart';
import { HeadMapRecordChart }    from './Charts/HeadeMapRecordChart/HeadMapRecordChart';
import { AnswersQuestion }       from './AnswersQuestion';
import { groupRecordByDay }      from './AnalyticalSystem/GroupRecordByDate';
import { useHistory }            from 'react-router-dom';
import moment                    from 'moment';

export default function AnalyticalSystem({ startDate, endDate, records, surveyTemplate, customerCondition, updaters }) {
    const { questions } = useAnalyticalSystem(records);
    let startDay = startDate.startOf('date').unix();
    let endDay   = endDate.endOf('date').unix();
    let history  = useHistory();

    const { t }                           = useTranslation();
    const { dataAnalysis } = useHeadMapRecordChart(records, startDay, endDay);
    const [answerSelected, setAnswerSelected] = useState({});

    return (questions.length ? (
        <div>
            <div className="padding-responsive pb-0">
                <HeadMapRecordChart
                    dataAnalysis={dataAnalysis}
                    startDate={startDate.startOf('date')}
                    endDate={endDate.endOf('date')}
                    handleOnClick={(record) => {
                        history.push('/records', {
                            AnalysisCondition : {
                                customerId: customerCondition,
                                updaterId : updaters.map(user => user.id),
                                templateId: [surveyTemplate.id],
                                startDate : moment(record.date).startOf('day').unix(),
                                endDate   : moment(record.date).endOf('day').unix()
                            }
                        })
                    }}
                />
                <div className="info-question">
                    <div className='text-title pb-10px'>{t('common.Title')}</div>
                    <div className='question-number'>{surveyTemplate.title}</div>
                    <div className="text-title label-text-form">{t('common.Description')}</div>
                    <div className='mb-1'>{surveyTemplate.description}</div>
                </div>
            </div>
            <div className={'padding-responsive'} style={{ position: 'relative'}}>
                {
                    questions.map((question, index) => {
                        const QuestionChart = question.chart;
                        return (<div className='card-aly mb-3' key={index}>
                            <div className="bg-title-aly">
                                <div className="title-aly">
                                    {question.heading ? question.heading : question.content}
                                </div>
                            </div>
                            <div className="card-body" style={{ overflowX: 'auto' }}>
                                <div className='row' style={{ marginRight: -8, marginLeft: -8 }}>
                                    <div className='col-lg-6 pb-3 pl-2 pr-2 border-analysis'>
                                        <div className='mb-1'>{question.content}</div>
                                        <div className='mb-1'>{question.answers}</div>
                                        <QuestionChart
                                            question={question}
                                            records={records}
                                            handleSelectAnswer={ (value) => {
                                                if (JSON.stringify(value) !== JSON.stringify(answerSelected)) {
                                                    return setAnswerSelected(value);
                                                }
                                            } }
                                        />
                                    </div>
                                    <AnswersQuestion
                                        recordsByDate={groupRecordByDay(records)}
                                        question={question}
                                        answerSelected={answerSelected}
                                        clearFilter={() => setAnswerSelected({})}
                                        records={records}
                                        handleSelectAnswer={ setAnswerSelected }
                                    />
                                </div>
                            </div>
                        </div>);
                    })
                }
            </div>
        </div>) :
        <div className="pt-3 pb-5 text-center">
            <img src={`/assets/img/empty-survey.svg`}
                 alt="search fail"/>
            <h6 className="pt-3" style={{ color: 'rgb(9, 30, 66)' }}>
                <div style={{fontWeight: 500}}>{t('NullPage.record')}</div>
            </h6>
        </div>
    );
}

import React              from 'react';
import { useTranslation } from 'react-i18next';

export default function KirokuNotificationContent({ color, message, handleClose, isNotAutomaticCloseNotice }) {

    const { t } = useTranslation();

    return (
        <div className='notify-web-a'>
            <div className='notification-container'>
                <div className={'notification bg-' + color}>
                    <div className={'close-icon'}
                         hidden={!isNotAutomaticCloseNotice}
                    >
                        <div style={{width: 30}}
                            onClick={handleClose}
                        >
                            <i className="fas fa-times fa-lg"/>
                        </div>
                    </div>
                    <div hidden={!color}>
                        <i className={`fas ${color === 'danger' ? 'fa-times-circle fa-lg' : 'fa-check-circle'}`}/>
                        <span className='pl-2'>{t(`${message}`)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { useState }           from 'react';
import About                         from './Creator/About';
import useSwitchScreen               from './MakeSurvey/useSwitchScreen';
import { useTranslation }            from 'react-i18next';
import {
    ComponentMobile, KirokuButton, KirokuButtonLink, KirokuModal, KirokuModalBody, KirokuModalFooter,
    KirokuSortableContainer, KirokuSortableElement,
}                                    from '../../ui';
import { CreateQuestionForm }        from './FormQuestion/Creator';
import { Questions }                 from './FormQuestion/Questions';
import { useValidationSurvey }       from './MakeSurvey/useValidationSurvey';
import AboutSurvey                   from './surveysDetail/AboutSurvey';
import DescriptionSurvey             from '../Records/DescriptionSurvey';
import { ScrollingAnchor }           from '../../ui/Scrolling';
import { questionTypes }             from './QuestionTypes';
import useQuestions                  from './MakeSurvey/useQuestions';
import useChangeInfoSurvey           from './MakeSurvey/useChangeInfoSurvey';
import { authentication, resources } from '@kiroku/api';
import { useHistory }                from 'react-router-dom';
import arrayMove                     from 'array-move';
import { NavigatorQuestionSortable } from '../../ui/KirokuItemNavigate';
import { useSurveyContext }          from './MakeSurvey/SurveyContext';
import { useProfile }                from '../useProfile';
import useNotification               from '../Layout/useNotification';


export function ReviewSurvey({ handleOnSubmit }) {
    const { t }                           = useTranslation();
    const { questions, dragDropQuestion } = useQuestions();
    const { title, description }          = useChangeInfoSurvey();
    const { currentScreen, setScreen }    = useSwitchScreen();
    const profile                         = authentication.useAuthenticatedContext().user;

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newItems = [...questions];
        dragDropQuestion(arrayMove(newItems, oldIndex, newIndex), oldIndex, newIndex);
    };

    return (
        <div>
            <div className='padding-responsive keeping-about-survey bg-main'
                 style={ { top: 70 } }>
                <KirokuButton color={ 'light' } onClick={ () => {
                    setScreen(currentScreen === 'Create' ? 'Review' : 'Create');
                } }>
                    <span className="text-tranf">{ t('createRecord.back') }</span>
                </KirokuButton>
                <KirokuButton color={ 'primary' }
                              onClick={ () => {
                                  handleOnSubmit({ title, description, questions });
                              } }
                              disabled={ !questions.length }
                              style={ { float: 'right' } }
                >
                    { t('createSurvey.Submit') }
                </KirokuButton>
            </div>
            <div className={ 'survey-recording' }>
                <div className={ 'navigator-question' }>
                    <div className="padding-responsive keeping-about-survey"
                         style={ { paddingBottom: 0, top: 120 } }
                    >
                        <div className="scroll-navigator-question">
                            <KirokuSortableContainer
                                onSortEnd={ onSortEnd }
                                useDragHandle
                            >
                                <NavigatorQuestionSortable
                                    questions={ questions }
                                    anchor={ `question-survey-review-` }
                                />
                            </KirokuSortableContainer>
                        </div>
                    </div>
                </div>
                <div className={ 'range-recoding' }>
                    <div className={ 'padding-responsive keeping-about-survey bg-main' }
                         style={ { top: 117, paddingBottom: 0, paddingLeft: 0 } }>
                        <div className='fix-center'>
                            <div style={ { width: '100%' } }>
                                <AboutSurvey
                                    survey={ { title, description } }
                                    user={ profile }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="padding-responsive"
                         style={ { paddingTop: 0, paddingLeft: 0 } }>
                        <DescriptionSurvey
                            description={ description }
                        />
                        <KirokuSortableContainer
                            onSortEnd={ onSortEnd }
                            distance={5}
                            useDragHandle
                        >
                            <div className='fix-center'>
                                <div id='question-review' style={ { width: '100%' } }>
                                    { questions.map((question, index) => {
                                        const Question = questionTypes[question.type];
                                        return <KirokuSortableElement key={ index } index={ index }>
                                            <ScrollingAnchor
                                                key={ index }
                                                anchor={ `question-survey-review-${ question.index }` }>
                                                <Question question={ question }/>
                                            </ScrollingAnchor>
                                        </KirokuSortableElement>;
                                    }) }
                                </div>
                            </div>
                        </KirokuSortableContainer>
                    </div>
                    <div className='text-center pt-4' style={ { paddingBottom: '70px' } }>
                        <KirokuButton
                            color={ 'light' }
                            onClick={ () => {
                                setScreen(currentScreen === 'Create' ? 'Review' : 'Create');
                            } }
                        >
                            <span className="text-tranf">{ t('createRecord.back') }</span>
                        </KirokuButton> &nbsp;
                        <KirokuButton color={ 'primary' }
                                      onClick={ () => {
                                          handleOnSubmit({ title, description, questions });
                                      } }
                                      disabled={ !questions.length }
                        >
                            { t('createSurvey.Submit') }
                        </KirokuButton>
                        <ComponentMobile>
                            <div style={ { marginBottom: 80 } }/>
                        </ComponentMobile>
                    </div>

                </div>
            </div>
        </div>
    );
}

export function CreatorSurvey({isEditingSurvey}) {
    const { t }                        = useTranslation();
    const { btnReview }                = useValidationSurvey();
    const { currentScreen, setScreen } = useSwitchScreen();
    const history                      = useHistory();
    const [showModal, setShowModal]    = useState('');
    const { infoSurvey }               = useSurveyContext();
    const [profile]                    = useProfile();
    const removeSurveys             = resources.useDelete('Template')[1];
    const { notification }          = useNotification();
    const handleDeleteTemplate = () => {

        removeSurveys(infoSurvey.id).then(() => {
            notification(t('message.DeleteSuccess'), 'success');
            history.push('/surveys');
            setShowModal('');
        }).catch((error) => {
            console.log(error);
            setShowModal('');
        });
    };

    return (
        <div className={ 'padding-responsive' }>
            {( profile['role'] === 'admin' && isEditingSurvey) && <div className={ 'pb-3 d-flex justify-content-end' }>
                <KirokuButtonLink
                    className={ 'font-label-preview pr-3 ' }
                    style={ {
                        border : 'solid 1px #ff0000',
                        color  : '#ff0000',
                        height : '36px',
                        padding: '0 16px',
                    } }
                    onClick={ () => setShowModal('delete') }
                >
                    <div className="d-flex align-items-center">
                        <i className={ 'far fa-trash-alt text-delete-button' }/> &nbsp;
                        <span style={ { fontSize: 14 } }>{ t('RecordsBeta.delete') }</span>
                    </div>
                </KirokuButtonLink>
            </div> }
            <About/>
            <Questions/>
            <CreateQuestionForm>
                <div className={ 'col-md-3' }/>
                <KirokuButton color='white'
                              onClick={ () => history.goBack() }
                              className={ 'col-6 col-sm-6 col-md-2' }
                >
                    { t('createSurvey.Cancel') }
                </KirokuButton>
                <div className={ 'p-2 d-none d-sm-none d-md-block' }/>
                <KirokuButton
                    disabled={ btnReview }
                    onClick={ () => {
                        setScreen(currentScreen === 'Create' ? 'Review' : 'Create');
                    } }
                    color='success'
                    className={ 'col-6 col-sm-6 col-md-2' }
                >
                    { t('createSurvey.Review') }
                </KirokuButton>
                <ComponentMobile>
                    <div className="padding-responsive">
                        <div style={ { marginBottom: 80 } }/>
                    </div>
                </ComponentMobile>
            </CreateQuestionForm>


            <KirokuModal show={ showModal === 'delete' }
                         onClick={ () => setShowModal('') }>
                <KirokuModalBody>
                    <div className='text-center pt-3'>
                        <b>{ t('RecordList.AreYouWantDelete') }</b>
                        <p style={ { fontSize: 16 } }>{ infoSurvey ? infoSurvey.title : '' }</p>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={ () => setShowModal('') }
                                  color={ 'white' }
                    >
                        { t('common.Cancel') }
                    </KirokuButton>
                    <KirokuButton onClick={ handleDeleteTemplate } color={ 'danger' }>
                        { t('RecordsBeta.delete') }
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        </div>
    );
}


export default function Survey({ handleOnSubmit, isEditingSurvey }) {

    const Screens           = {
        Create: CreatorSurvey,
        Review: ReviewSurvey,
    };
    const { currentScreen } = useSwitchScreen();
    const Screen            = Screens[currentScreen];
    return <Screen
        isEditingSurvey={ isEditingSurvey }
        handleOnSubmit={ handleOnSubmit }
    />;
}

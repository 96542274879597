import { Component }      from 'react';
import React              from 'react';
import KirokuDropdownItem from './KirokuDropdownItem';
import { KirokuForm }     from './index';

export default class KirokuDropdownOnChangeSearch extends Component {

    handleSelected(event) {
        this.props.onSelect(event);
        this.setState({
            popupVisible  : false,
            selected      : event.kirokuSelected,
            keywordsSearch: '',
        });

    }

    constructor(props) {
        super(props);
        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.state              = {
            selected      : {
                label: props.title || props.defaultValue,
            },
            popupVisible  : false,
            keywordsSearch: '',
            items         : [],
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.items !== this.props.items) {
            this.setState({
                items: nextProps.items,
            });
        }
        if (nextProps.title !== this.props.title) {
            this.setState({
                selected : {
                    label : nextProps.title
                }
            })
        }
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }

        if (this.node.contains(e.target)) {
            return;
        }

        this.handleClick();
    }

    handleChangeKeyword(event) {
        this.props.handleChange(event.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, ''));
    }

    render() {
        const { className, hidden, disabled, style, position, icon, handleSearch, onSubmit } = this.props;
        const { popupVisible, selected, items }                                              = this.state;

        return (
            <div ref={ node => {
                this.node = node;
            } }>
                <div className={ `dropdown ${ className || '' }` }>
                    <button hidden={ hidden }
                            disabled={ disabled }
                            style={ style }
                            className={ `overflow btn btn-kiroku ${ className || '' }` }
                            onClick={ this.handleClick.bind(this) }>
                        <i className={ `${ icon } left text-kiroku pr-5px` }/>
                        <span
                            className={ 'text-kiroku left overflow-text ' }>{ selected.label }</span>
                        <i className={ `${ !popupVisible ? 'fa fa-chevron-down icon-kiroku' : 'fa fa-chevron-up icon-kiroku' }` }/>
                    </button>
                    { popupVisible && (
                        <div
                            className={ `dropdown-kiroku dropdown-menu show ${ position || '' }` }
                            style={ { position: 'absolute', willChange: 'top, left', left: 'auto', top: 'auto' } }>
                            <KirokuForm onSubmit={ onSubmit || (() => {}) }>
                                <div className='dropdown-item-kiroku bg-light p-2'>
                                    <div style={ { position: 'relative' } }>
                                        <input onChange={ this.handleChangeKeyword.bind(this) } type="text"
                                               className="form-control-kiroku pr-4"/>
                                        <div className='action-input' onClick={ handleSearch || (() => {}) }>
                                            <i className="fa fa-search"/>
                                        </div>
                                    </div>
                                </div>
                            </KirokuForm>
                            <section className='scroll-data-dropdown-search'>
                                { items.map((item, index) => {
                                    const Item = item.component;
                                    return (
                                        <KirokuDropdownItem key={ index } onClick={ (event) => {
                                            event.kirokuSelected = item;
                                            this.handleClick(event);
                                            this.handleSelected(event);
                                        } }>
                                            { Item }
                                        </KirokuDropdownItem>
                                    );
                                }) }
                            </section>
                        </div>
                    ) }
                </div>
            </div>
        );
    }
}


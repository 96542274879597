import React from 'react';

export let getGroupedOptions = (groupItemsKey, groupedOptions) => {
    if (!groupedOptions.length) {
        return [];
    }
    return groupedOptions.map(tmp => {
        switch (groupItemsKey) {
            case 'Template' :
                return {
                    key       : `${ groupItemsKey }_${ tmp.id }`,
                    value     : `${ tmp.title }${ tmp.id }`,
                    label     : tmp.title,
                    type      : 'SurveyList',
                    surveyId  : tmp.id,
                    templateId: tmp.id,
                    id        : tmp.id,
                    title     : tmp.title,
                };
            case 'User' :
                return {
                    ...tmp,
                    key    : `${groupItemsKey}_${tmp.id}`,
                    value  : `${tmp.name}${tmp.id}`,
                    label  : tmp.name,
                    staffId: tmp.id,
                    type   : 'Users',
                    tagType: 'users',
                    id     : tmp.id,
                };
            case 'Customer' :
                return {
                    key       : `${groupItemsKey}_${tmp.id}`,
                    value     : `${tmp.name}${tmp.id}`,
                    label     : tmp.name,
                    customerId: tmp.id,
                    tagType   : 'customer',
                    type      : 'Customers',
                    id        : tmp.id,
                };
            case 'Team' :
                return {
                    key      : `${groupItemsKey}_${tmp.id}`,
                    value    : `${tmp.name}${tmp.id}`,
                    label    : tmp.name,
                    teamId   : tmp.id,
                    customers: tmp.customers,
                    tagType  : 'team',
                    type     : 'Teams',
                    users    : tmp.users,
                    id       : tmp.id,
                };
            default :
                return {
                    key  : `default_${tmp.id}`,
                    value: `default${tmp.id}`,
                    label: tmp.title,
                };
        }
    });
};

export function getItemsForDropDown(items, t) {
    if (!items.length) {
        return [
            {
                value    : '',
                component: (<div> {t('common.NoResults')}</div>),
                label    : t('common.NoResults'),
            },
        ];
    }

    let itemSelected = items.map(item => ({
        value    : item.id,
        component: (<div>{item.name || item.title}</div>),
        label    : item.name || item.title,
    }));

    let defaultSearch = [
        {
            value    : 'all',
            component: (<div>{t('common.All')}</div>),
            label    : t('common.All'),
        },
    ];
    return [...defaultSearch, ...itemSelected];
}

export function removeConditionsUndefined(params) {
    return Object.keys(params).forEach(key => {
        if (key === 'tags' && !params[ key ].length) {
            delete params[ key ];
        }
        !params[ key ] && delete params[ key ];
    });
}

export function itemsDropdownSearch(items, t) {
    if (!items.length) {
        return [
            {
                value    : '',
                component: (<div> {t('common.NoResults')}</div>),
                label    : t('common.NoResults'),
            },
        ];
    }

    let itemSelected = items.map(item => ({
        value    : item.id,
        component: (<div>{item.name || item.title}</div>),
        label    : item.name || item.title,
    }));
    return [...itemSelected];
}

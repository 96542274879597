import React                                                           from 'react';
import { ComponentMobile, ComponentWeb, KirokuLoader, SearchNotFound } from '../../../ui';
import If                                                              from '../../If';
import UserList                                                        from './UserList';
import UserTable                                                       from './UserTable';

export default function Users({ loading, result, sort_by, order_by_type, changeOrder }) {
    const users = result;

    const handleChangeOrderBy = (field) => {
        let typeOrder = '';
        if (field === 'delete_at') {
            typeOrder = order_by_type.delete_at === 'ASC' ? 'DESC' : 'ASC';
        } else {
            typeOrder = order_by_type.create_at === 'ASC' ? 'DESC' : 'ASC';
        }

        changeOrder(field, typeOrder);
    };

    return (
        <div>
            <KirokuLoader loading={loading}>
                <If statement={users.length}
                    otherwise={<SearchNotFound/>}
                >
                    {() => <React.Fragment>
                        <ComponentWeb>
                            <UserTable
                                users={users}
                                sort_by={sort_by}
                                order_by_type={order_by_type}
                                handleChangeOrderBy={handleChangeOrderBy}
                            />
                        </ComponentWeb>
                        <ComponentMobile>
                            <UserList
                                users={users}
                            />
                        </ComponentMobile>
                    </React.Fragment>
                    }
                </If>
            </KirokuLoader>
        </div>
    );
}

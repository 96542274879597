import {
    useCallback,
    useEffect,
    useState,
}                              from 'react';
import { resources }           from '@kiroku/api';
import { useDocumentContext }  from '../DocumentContext';
import { LOAD_DATA_DOCUMENTS } from '../DocumentReducer';
import useNotification         from '../../../../Layout/useNotification';
import { useTranslation }      from 'react-i18next';

export default function useUploadFile() {
    const [resultFile, uploadFile, loadingUpload]                                   = resources.useUpload('Document');
    const { result, search, loading, pagination }                                   = resources.useSearch('Document');
    const [resultCreateDocument, setFileFromCustomerAPI, loadingSetFileToCustomer]  = resources.useCreate('Document');
    const [isDoneAPIUploadDocumentToCustomer, setIsDoneAPIUploadDocumentToCustomer] = useState(false);
    const { dispatch, customer }                                                    = useDocumentContext();

    const [files, setFiles] = useState([]);
    const [file, setFile]   = useState(null);
    const { notification }  = useNotification();
    const { t }             = useTranslation();

    const onDrop = useCallback((files) => {

        if(!files.length) {
            return null;
        }
        let file     = files.shift();
        // > 10MB
        if (file.size > 10000000) {
            notification(t('message.UploadFail'), 'danger');
            return null;
        }
        console.log(files);
        setFiles(files);
        setFile(file);

    }, [notification, t]);

    const onCancel = useCallback(() => {
        setFiles([]);
    }, []);

    useEffect(() => {
        if (resultCreateDocument && !loadingSetFileToCustomer && isDoneAPIUploadDocumentToCustomer) {
            search({ type_id: customer.id, page: 1, type: 'customer' });
            setIsDoneAPIUploadDocumentToCustomer(false);
        }
    }, [isDoneAPIUploadDocumentToCustomer, resultCreateDocument, loadingSetFileToCustomer, search, customer]);

    useEffect(() => {
        dispatch({
            type       : LOAD_DATA_DOCUMENTS,
            documents  : result,
            loading    : loading,
            currentPage: pagination.currentPage,
            totalPage  : pagination.totalPage,
        });
    }, [dispatch, result, pagination, loading]);


    const handleConfirmUpload = useCallback((file) => {
        if(loadingUpload) {
            return null
        }
        let formData = new FormData();
        formData.append('file', file);
        formData.append('name', file.name);
        return uploadFile('private', formData).then(res => {
            setFileFromCustomerAPI({
                customer_id: customer.id,
                file_path  : res.filePath,
                url        : res.url,
                type       : res.type,
                name       : res.name,
            }).then(() => {
                setIsDoneAPIUploadDocumentToCustomer(true);
                setFile(null);
                notification(t('message.Upload'), 'success');
            }).catch(() => {
                setFile(null);
                setIsDoneAPIUploadDocumentToCustomer(true);
                notification(t('message.UploadFail'), 'danger');
            });
        });
    }, [uploadFile, customer, setFileFromCustomerAPI, t, notification, loadingUpload])

    const handleCancelUpload = useCallback(() => {
        setFiles([]);
        setFile(null);
    },[])

    return {
        onDrop,
        onCancel,
        loadingUpload,
        resultFile,
        files,
        file,
        handleConfirmUpload,
        handleCancelUpload
    };
}

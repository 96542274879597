import React, { useEffect }      from 'react';
import KirokuNotificationContent from './KirokuNotificationContent';
import useNotification           from '../app/Layout/useNotification';

export default function KirokuNotification() {
    const { notification, isOpenNotification, messageNotification, notificationType, isNotAutomaticCloseNotice } = useNotification();

    useEffect(() => {
        let id = setInterval(() => {
            if (isOpenNotification && messageNotification && notificationType && !isNotAutomaticCloseNotice) {
                notification();
            }
        }, 3000);
        return () => clearInterval(id);
    });

    return (
        <div className={isOpenNotification ? 'on' : 'off'}>
            <KirokuNotificationContent message={messageNotification} color={notificationType}
                                       isNotAutomaticCloseNotice={isNotAutomaticCloseNotice}
                                       handleClose={() => { notification() }}

            />
        </div>
    );
}

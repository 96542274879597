import React              from 'react';
import { useTranslation } from 'react-i18next';

export function SurveyRecordLabelComponent() {
    const {t} = useTranslation();
    return <div className={ 'entity-log-label' }>
        <div className={ 'entity-text' }>{ t('common.surveyRecord') }</div>
        &nbsp;
        <div><i className={ 'fas fa-file-signature fa-lg icon-log-entity' }/></div>
    </div>
}


export function SurveyTemplateLabelComponent() {
    const {t} = useTranslation();
    return <div className={ 'entity-log-label' }>
        <div className={ 'entity-text' }>{ t('common.Survey') }</div>
        &nbsp;
        <div><i className={ 'fa fa-clipboard fa-lg icon-log-entity' }/></div>
    </div>;
}

export function TeamLabelComponent() {
    const {t} = useTranslation();
    return <div className={ 'entity-log-label' }>
        <div className={ 'entity-text' }>{ t('common.team') }</div>
        &nbsp;
        <div><i className={ 'fa fa-users fa-lg icon-log-entity' }/></div>
    </div>;
}

export function CustomerLabelComponent() {
    const {t} = useTranslation();
    return <div className={ 'entity-log-label' }>
        <div className={ 'entity-text' }>{ t('common.customer') }</div>
        &nbsp;
        <div><i className={ 'fa fa-user fa-lg icon-log-entity' }/></div>
    </div>;
}

export function StaffLabelComponent() {
    const {t} = useTranslation();
    return <div className={ 'entity-log-label' }>
        <div className={ 'entity-text' }>{ t('common.staff') }</div>
        &nbsp;
        <div><i className={ 'fa fa-user-circle fa-lg icon-log-entity' }/></div>
    </div>;
}

export function PlanLabelComponent() {
    const {t} = useTranslation();
    return <div className={ 'entity-log-label' }>
        <div className={ 'entity-text' }>{ t('Layout.Planning') }</div>
        &nbsp;
        <div><i className={ 'fa fa-list-alt fa-lg icon-log-entity' }/></div>
    </div>;
}



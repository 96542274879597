import React, { useEffect }            from 'react';
import { useTranslation }              from 'react-i18next';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { authentication }              from '@kiroku/api';
import If                              from '../If';
import FormLogin                       from './FormLogin';
import Languages                       from './Languages';
import mixpanel                        from '../../Mixpanel/MixPanel';
import './style.css';

export default () => {
    const { login, token, error, isLoggingIn, nextStepOTP } = authentication.useLoginContext(localStorage);

    const { t } = useTranslation();
    const location  = useLocation();
    const redirectFrom = location.state && location.state.redirectFrom;

    useEffect(() => {
        mixpanel.reset();
    });

    return (
        <If statement={!token} otherwise={<Redirect to={redirectFrom || "/"}/>}>
            {() => <div className='page-login'>
                <div className="box-login">
                    <div className="tile">
                        <Languages/>
                        <div className="card-login">
                            <div className="tile-header" style={{ paddingTop: 60 }}/>
                                <div className="tile-body">
                                    <FormLogin
                                        login={({ userName, password, otpCode }) => {
                                            window.location.previous = 'login';
                                            login(userName, password, otpCode);
                                        }}
                                        error={error}
                                        isLoggingIn={isLoggingIn}
                                        nextStepOTP={nextStepOTP}
                                    />
                                </div>
                                <If statement={nextStepOTP}>
                                    {() => {
                                        return <>
                                            <div className="hr-login">
                                                <span className='hr-login-content'>{t('Login.Two-factor-help')}</span>
                                            </div>
                                            <div className='login-tfa'>
                                                <div>
                                                    {t('Login.tfaDescription')}
                                                </div>
                                            </div>
                                        </>
                                    }}
                                </If>

                                <If statement={!nextStepOTP}>
                                    {() => {
                                        return <>
                                            <div className="hr-login">
                                                <span className='hr-login-content'>{t('common.support')}</span>
                                            </div>
                                            <div className={'pt-2 text-center'}>
                                                <Link to={'/surfly'}>{t('common.support')}</Link>
                                            </div>
                                            <div className="hr-login">
                                                <span className='hr-login-content'>{t('common.about')}</span>
                                            </div>
                                            <div className={'pt-2 text-center'}>
                                                <a href={'https://pitout.co.jp/kirokuai/'}
                                                   target={'_blank'}
                                                   rel="noopener noreferrer"
                                                >
                                                    {t('common.aboutKiroKuAI')}
                                                </a>
                                            </div>
                                            <div className={'pt-2 text-center'}>
                                                <Link to={'/terms'} target={'_blank'}>{t('common.terms')}</Link>
                                            </div>
                                            <div className={'pt-2 text-center'}>
                                                <Link to={'/privacy'} target={'_blank'}>{t('common.policy')}</Link>
                                            </div>
                                        </>
                                    }}
                                </If>

                        </div>
                    </div>
                </div>
            </div>}
        </If>
    );
}

import ConditionRecords          from './ConditionComponent/ConditionRecords/ConditionRecords';
import RecordTableComponent      from './ConditionComponent/TableRecords/RecordTableComponent';
import React                     from 'react';
import useSelectedCustomer       from './ConditionComponent/ModalSelecteCustomers/useSelectedCustomer';
import ModalChoiceCustomerMobile from './ConditionComponent/ModalSelecteCustomers/Mobile/ModalChoiceCustomerMobile';

export default function BuildRecordsComponent() {
    const { showModalSelectCustomer } = useSelectedCustomer();
    return (
        <div>
            <div hidden={ showModalSelectCustomer }>
                <ConditionRecords/>
                <div className="p-1 pt-2"/>
                <RecordTableComponent/>
            </div>
            <ModalChoiceCustomerMobile
                showModal={ showModalSelectCustomer }
            />
        </div>

    );
}

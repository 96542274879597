import React            from 'react';
import {
    KirokuLoader,
    KirokuNotFound, KirokuCardWeb,
    SearchNotFound
}                       from '../../../ui';
import {useTranslation} from 'react-i18next';
import PlanTabWeb       from './PlanTabWeb';
import PlanTabMobile    from './PlanTabMobile';

function PagNotFound() {
    const {t} = useTranslation();
    return <SearchNotFound
        description={t('NullPage.customerForTag2')}
    />;
}

export default function PlanTab({condition, result, loadingAPI}) {
    return (
        <div hidden={condition.type !== 'condition'}>
            <KirokuCardWeb>
                <KirokuLoader loading={loadingAPI}>
                    <KirokuNotFound isResults={result.length}
                                    ComponentSearchNoFound={PagNotFound}
                    >
                        <PlanTabWeb result={result}/>
                        <PlanTabMobile result={result}/>
                    </KirokuNotFound>
                </KirokuLoader>
            </KirokuCardWeb>
        </div>
    );
}

import React                               from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import Login                               from './Login/Login';
import Layout                              from './Layout/Layout';

import { authentication }                from '@kiroku/api';
import Privacy                           from './Policies/Pricacy';
import Terms                             from './Policies/Terms';
import SupportPage                       from './SupportPage/SupportPage';

import '../responsive.css';
import '../index.css';
import './Deleted/deleteScreen.css';
import '../app/Records/record.css';
import './Policies/policies.css';
import { MaintenancePage, NotFoundPage } from './MaintenantDepartment';
import BatchAction                       from './Recordsv2/BatchAction/BatchAction';
import SentryAuthenticatedScope          from '../Sentry/SentryAuthenticatedScope';
import { useMixpanelTracking }           from '../Mixpanel/useMixpanelTracking';
import { MixpanelAuthenticated }         from '../Mixpanel/MixpanelAuthenticated';
import historyRouter                     from '../historyRouter';
import { Components }                    from '../ui';

export default function App() {

    const { trackingMixpanel } = useMixpanelTracking();

    return (
        <Router history={historyRouter}>
            <Switch>
                <Route exact path='/components' component={Components}/>
                <Route exact path='/login' component={Login}/>
                <Route exact path='/surfly' component={SupportPage}/>
                <Route exact path='/privacy' component={Privacy}/>
                <Route exact path='/terms' component={Terms}/>
                <Route exact path={'/404'} component={NotFoundPage}/>
                <Route exact path='/maintenance' component={MaintenancePage}/>
                <Route exact path='/internal-server-error' component={MaintenancePage}/>
                <Route exact path='' component={props => (
                    <authentication.AuthenticationProvider
                        storage={localStorage}
                        renderUnAuthenticated={() => <Redirect to={{
                            pathname: '/login',
                            state: {
                                redirectFrom: historyRouter.location.pathname.concat(historyRouter.location.search),
                            }
                        }}/>}
                        onLogout={(user) => trackingMixpanel('LOGOUT', user)}
                    >
                        <MixpanelAuthenticated>
                            <SentryAuthenticatedScope>
                                <Switch>
                                    <Route exact path={'/batch-action-records/record'} component={BatchAction}/>
                                    <Layout {...props}/>
                                </Switch>
                            </SentryAuthenticatedScope>
                        </MixpanelAuthenticated>
                    </authentication.AuthenticationProvider>
                )}/>
            </Switch>
        </Router>
    );
}

import React, { useState }       from 'react';
import {
    KirokuMultiSelect, KirokuLoader,
    KirokuButton,
}                                from '../../../ui';
import { useTranslation }        from 'react-i18next';
import { useHistory }            from 'react-router-dom';
import useDropDownData           from '../../useDropDownData';
import DropdownSelectTeamTag     from './DropdownSelectTeamTag';
import DropdownSelectCustomerTag from './DropdownSelectCustomerTag';
import InfoBasicUser             from './InfoBasicUser';
import { ComponentLoading }      from '../ModalNotificationValidateUploadAvatar';

export default function TabProfile(props) {

    const {
              show, resultUser,
              inputElement,
              validateAlertAvatar,
              updateInfoUser, handleSubmitUser,
              handleOnChangeCustomers, handleOnChangeTeams,
              handleChangeQualification, handleChangeImage, profileData,
              isLoadingAvatar, user
          }                                     = props;
    const history                               = useHistory();
    const { t }                                 = useTranslation();
    const { kindOfQualification }               = useDropDownData();
    const [disableBtnSubmit, setDisabledButton] = useState(false);

    return (<div hidden={!show}>
        <div className='row'>
            <div className="col-md-8">
                <InfoBasicUser
                    resultUser={resultUser}
                    updateInfoUser={updateInfoUser}
                />
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='pb-15px'>
                            <span className='customer-info f-bold'>{t('common.Qualification')}:</span>
                            <div className="pt-10">
                                <KirokuMultiSelect
                                    onChange={handleChangeQualification}
                                    value={profileData.qualification}
                                    options={kindOfQualification}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden={resultUser.id === user.id}>
                    <div className="pb-15px">
                        <span className='customer-info f-bold'>{t('common.Customer')}:</span>
                        <div className="pt-10">
                            <DropdownSelectCustomerTag
                                userId={resultUser.id}
                                handleOnChangeCustomers={handleOnChangeCustomers}
                            />
                        </div>
                    </div>
                </div>
                <div hidden={resultUser.id === user.id}>
                    <div className="pb-15px">
                        <span className='customer-info f-bold'>{t('common.Team')}:</span>
                        <div className="pt-10">
                            <DropdownSelectTeamTag
                                userId={resultUser.id}
                                handleOnChangeTeams={handleOnChangeTeams}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-4 hidden-screen-max-768">
                <KirokuLoader loading={isLoadingAvatar} ComponentLoading={ComponentLoading}>
                    <div className='staff-detail avatar-user-detail as'
                         style={{
                             backgroundImage: `url(${profileData.avatar ? profileData.avatar : '/assets/img/profile-ic.svg'})`,
                         }}
                    />
                </KirokuLoader>
                <div className='mt-24'>
                    <div className='text-center'>
                        <KirokuButton color={'success'} onClick={() => inputElement.current.click()}>
                            {t('CustomerEditor.ChangeAvatar')}
                        </KirokuButton>
                        <input type='file' ref={inputElement} hidden
                               accept="image/jpeg, image/png"
                               onChange={() => {
                                   handleChangeImage();
                                   validateAlertAvatar();
                               }}
                        />
                    </div>
                </div>
                <div className='content-avatar text-center pt-3'>
                    <span>{t('Customer.SupportedFile')} </span>
                    <br/>
                    <span>{t('Customer.Maximum')}</span>
                </div>
            </div>
        </div>
        <div className='pt-3'>
            <span>
                <KirokuButton onClick={() => history.goBack()} color='white'>
                    {t('common.Cancel')}
                </KirokuButton>
            </span>
            <span className='ml-20'>
                <KirokuButton disabled={!profileData.name || disableBtnSubmit}
                              onClick={() => {
                                  handleSubmitUser();
                                  setDisabledButton(true);
                              }}
                              color='primary'>
                    {t('common.Save')}
                </KirokuButton>
            </span>
        </div>
    </div>);
}

import { useEffect, useState }                   from 'react';
import { resources }                             from '@kiroku/api';
import { concatActivitiesLog, getGroupLogByDay } from '../../../Logs/groupLog';

export default function useResearchActivitiesLog() {
    const { search, condition, pagination }                   = resources.useSearch('Logs');

    const [logs, setLogs]                                     = useState([]);
    const [APILoading, setAPILoading]                         = useState(true);
    const [APILoadingActionSearch, setAPILoadingActionSearch] = useState(false);
    const [hiddenInitialPage, setHiddenInitialPage]           = useState(true);
    const [hasNext, setHasNext] = useState(false)
    useEffect(() => {
        setAPILoading(true);
        search({ page: 1, perPage: 10, type: 'activities' })
            .then(res => {
                setAPILoading(false);
                setHiddenInitialPage(false);
                setLogs(getGroupLogByDay(res.result));
                setHasNext(res.hasNext);
            }).catch(() => {
                setAPILoading(false);
                setHiddenInitialPage(false);
        });
    }, [search]);


    const researchActivities = (conditionsMore) => {
        setAPILoading(true);
        search({ ...condition, ...conditionsMore })
            .then(res => {
                const newLog = getGroupLogByDay(res.result);
                setHasNext(res.hasNext);
                setLogs(concatActivitiesLog(newLog, logs));
                setAPILoading(false);
            })
            .catch(() => {
                setAPILoading(false);
            });
    };

    const searchByAction = (conditionsMore) => {
        setAPILoadingActionSearch(true);
        const conditionSearch = { ...condition, ...conditionsMore };
        if(conditionSearch.action === 'all') {
            delete conditionSearch.action;
        }
        search(conditionSearch)
            .then(res => {
                setHasNext(res.hasNext);
                setLogs(getGroupLogByDay(res.result));
                setAPILoadingActionSearch(false);
            })

    }

    return { logs, researchActivities,
        condition,
        loading: APILoading,
        pagination,
        loadingWhenSearchByAction: APILoadingActionSearch,
        hiddenInitialPage,
        searchByAction,
        setAPILoadingActionSearch,
        hasNext
    };
}

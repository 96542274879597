import { Component }     from "react";
import React             from "react";
// import {noScrollService} from "../services/APIClient";

export default class KirokuModal extends Component {

    render() {
        // noScrollService.noScroll(this.props.show);
        return (
            <div>
                <div className={this.props.show ? "opacity-main" : ""}>
                    <div className="overlay"/>
                </div>
                <div className={! this.props.show ? "display-none" : "modal-kiroku fade show"}>
                    <div className={`modal-dialog modal-dialog-centered ${this.props.className || ''}`} style={this.props.style} >
                        <div className="modal-content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

import React, { useEffect, useRef, useState } from 'react';
import KirokuInputIcon                        from './KirokuInputIcon';
import moment                                 from 'moment';
import { useTranslation }                     from 'react-i18next';

export default function KirokuInputDateTimeMobile({ name, value, disabled, onChange, readOnly = true }) {
    const [date, setDate]   = useState('');
    const [clock, setClock] = useState('');
    const { t }             = useTranslation();
    const dateRef           = useRef(null);
    const timeRef           = useRef(null);
    const [count, setCount] = useState(1);

    useEffect(() => {
        if (value) {
            setDate(moment.unix(value).format('YYYY-MM-DD'));
            setClock(moment.unix(value).format('HH:mm'));
        } else {
            setDate('');
            setClock('');
        }
    }, [value]);

    let dateFormat = moment(date).format(t('dateTimeFormat.YearMonthDate'));
    let getTimeFormat =  date + ' ' + clock;

    return (
        <>
            <div style={{ position: "absolute", zIndex: -999}} hidden={disabled}>
                <input type={ 'date' } value={ date } ref={ dateRef } onChange={ e => {
                    onChange(moment(`${ e.target.value } ${ clock ? clock : moment().format('HH:mm') }`, 'YYYY-MM-DD HH:mm').unix());
                } }/>
                <input type={ 'time' } value={ clock } ref={ timeRef } onChange={ e => {
                    onChange(moment(`${date ? date : moment().format('YYYY-MM-DD')} ${e.target.value}`, 'YYYY-MM-DD HH:mm').unix())
                } }/>

            </div>
            <KirokuInputIcon name={ `${ name }-date` }
                             icon={ 'far fa-calendar-alt' }
                             value={ date ? dateFormat : '' }
                             onFocus={ () => {
                                 dateRef.current.focus();
                             }}
                             onClick={ () => {
                                 dateRef.current.click();
                             }}
                             readOnly={readOnly}
                             disabled={disabled}
            />
            <div className={ 'mb-2 mt-2' }/>
            <KirokuInputIcon name={name}
                             readOnly={readOnly}
                             disabled={disabled}
                             icon={'far fa-clock'}
                             value={clock ? moment(getTimeFormat).format(t('dateTimeFormat.HourMinute')) : ''}
                             onFocus={ () => {
                                 setCount(count + 1);
                                 timeRef.current.focus();
                             }}
                             onClick={ () => {
                                 setCount(count + 1);
                                 timeRef.current.click();
                             }}

            />
        </>
    );
}

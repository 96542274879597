import React                 from 'react';
import { useTranslation }    from 'react-i18next';

export default function InfoQuestion({ question }) {
    const { t } = useTranslation();
    return (
        <>
            <span className='content-name-question' style={{padding: 0}}>
                {t('createSurvey.Heading')}: {question.heading}
            </span>
            <span className='content-name-question pb-2' style={{padding: 0}}>
                {t('createSurvey.Content')}: {question.content}
            </span>
        </>
    );
}

export const SET_RESULT_SEARCH = 'SET_RESULT_SEARCH';

export default function DeletedReducer(state, action) {
    switch (action.type) {
        case SET_RESULT_SEARCH:
            return {
                ...state,
                ...action,
                order_by_type: {
                    ...state.order_by_type,
                    [action.sort_by]: action.order_by
                }
            };
        default :
            return state;
    }
}

export const SELECT_DOCUMENT_ID                          = 'SELECT_DOCUMENT_ID';
export const HANDLE_SELECT_ALL                           = 'HANDLE_SELECT_ALL';
export const LOAD_DATA_DOCUMENTS                         = 'LOAD_DATA_DOCUMENTS';
export const RESULT_SEARCH_DOCUMENT                      = 'RESULT_SEARCH_DOCUMENT';
export const ACTION_CLOSE_MODAL                          = 'ACTION_CLOSE_MODAL';
export const SET_DOCUMENT_DATA_PREVIEW                   = 'SET_DOCUMENT_DATA_PREVIEW';
export const SHOW_MODAL_CONFIRM_CHANGE_STATUS_TO_PRIVATE = 'SHOW_MODAL_CONFIRM_CHANGE_STATUS_TO_PRIVATE';
export const SHOW_MODAL_CONFIRM_DELETE_DOCUMENT          = 'SHOW_MODAL_CONFIRM_DELETE_DOCUMENT';
export const SHOW_MODAL_SELECT_USER_BE_SHARE             = 'SHOW_MODAL_SELECT_USER_BE_SHARE';
export const SHOW_MODAL_RENAME_FILE                      = 'SHOW_MODAL_RENAME_FILE';
export const RENAME_MODE_ACTION                          = 'RENAME_MODE_ACTION';

export default function DocumentReducer(state, action) {
    switch (action.type) {
        case SHOW_MODAL_CONFIRM_CHANGE_STATUS_TO_PRIVATE:
        case SHOW_MODAL_RENAME_FILE:
        case SET_DOCUMENT_DATA_PREVIEW:
        case SHOW_MODAL_CONFIRM_DELETE_DOCUMENT:
        case SHOW_MODAL_SELECT_USER_BE_SHARE:
            return {
                ...state,
                modalInfo: action.modalInfo,
            };
        case ACTION_CLOSE_MODAL:
            return {
                ...state,
                modalInfo: { name: '' },
            };

        case RESULT_SEARCH_DOCUMENT:
            return {
                ...state,
                documentsData: {
                    ...state.documentsData,
                    loading  : action.loading,
                    documents: action.result,
                    ...action.pagination,
                },
            };
        case LOAD_DATA_DOCUMENTS:
            return {
                ...state,
                documentsData: {
                    ...state.documentsData,
                    documents  : action.documents,
                    loading    : action.loading,
                    currentPage: action.currentPage,
                    totalPage  : action.totalPage,
                },
            };
        case HANDLE_SELECT_ALL:
            return {
                ...state,
                documentsData: {
                    ...state.documentsData,
                    documents: state.documentsData.documents.map(document => {
                        return {
                            ...document,
                            isChecked: !action.isSelectAll,
                        };
                    }),
                },
            };
        case SELECT_DOCUMENT_ID:
            return {
                ...state,
                documentsData: {
                    ...state.documentsData,
                    documents: state.documentsData.documents.map(document => {
                        if (document.id === action.documentId) {
                            return {
                                ...document,
                                isChecked: !document.isChecked,
                            };
                        }
                        return document;
                    }),
                },
            };

        case RENAME_MODE_ACTION:
            return {
                ...state,
                documentsData: {
                    ...state.documentsData,
                    documents: state.documentsData.documents.map(document => {
                        return {
                            ...document,
                            isRenameMode: document.id === action.documentId,
                        };
                    }),
                },
            };

        default:
            return state;
    }
}

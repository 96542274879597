import React              from 'react';
import { useTranslation } from 'react-i18next';

function KirokuPagination(props) {

    const { t }       = useTranslation();
    let { className } = props;
    return (
        <div hidden={ props.hidden } className={ className ? className : 'pb-3' }>
            <div className='d-flex justify-content-between' >
                    <div
                        style={{ width: 30, height: 30, textAlign: ' center'}}
                        onClick={ props.decreasePage }
                        className={`${props.disabledPrev ? 'disabled-div-tags' : ' cursor-pointer'}`}
                    >
                        <img src={ `/assets/img/arrow-back.svg`} alt="arrow-back.svg"/>
                    </div>
                <div className='content-pagination p-0' >
                    <span style={{ fontSize: 14 }}>{ t('common.pagination', { currentPage: props['currentPage'], totalPage: props['totalPage'] }) }</span>
                </div>
                <div
                    style={ { width: 30, height: 30, textAlign: ' center' } }
                    onClick={ props.increasePage}
                    className={`${props.disabledNext ? 'disabled-div-tags' : 'cursor-pointer'}`}
                >
                    <img src={ `/assets/img/arrow-next.svg`} alt="arrow-next.svg"/>
                </div>

            </div>
        </div>
    );
}

export default KirokuPagination;

import React                                                        from 'react';
import { ComponentWeb, KirokuButton, KirokuTable, TBody, THeader } from '../../../ui';
import { useTranslation }                                           from 'react-i18next';
import moment                                                       from 'moment';
import { Link, useHistory }                                         from 'react-router-dom';


export default function PlanTabWeb({ result }) {
    const history = useHistory();
    const now = moment().unix();
    const { t } = useTranslation();
    return (<ComponentWeb>
        <KirokuTable>
            <THeader>
                <tr>
                    <th style={{minWidth: 200 }}>{t('plans.customer')}</th>
                    <th style={{minWidth: 180 }}>{t('plans.startDate')}</th>
                    <th style={{minWidth: 180 }}>{t('plans.endDate')}</th>
                    <th>{t('plans.planStatus')}</th>
                    <th>{t('plans.statusReview')}</th>
                    <th/>
                </tr>
            </THeader>
            <TBody>
                {result.map((plan) => {
                    return <tr key={plan.id}>
                        <td className={'font-size-14'}>
                            {(plan[ 'customer' ] && plan.customer_id) ?
                                <Link to={`/customers/${plan[ 'customer' ].id}`}>
                                    {plan[ 'customer' ].name}
                                </Link>: ''}
                        </td>
                        <td style={{ whiteSpace: 'nowrap', fontSize: 14 }}>{!!plan[ 'start_at' ] && moment.unix(plan[ 'start_at' ]).format(t('dateTimeFormat.YearMonthDateWithDay'))}</td>
                        <td style={{ whiteSpace: 'nowrap', fontSize: 14 }}>{!!plan[ 'end_at' ] && moment.unix(plan[ 'end_at' ]).format(t('dateTimeFormat.YearMonthDateWithDay'))}</td>
                        <td style={{ whiteSpace: 'nowrap', fontSize: 14 }}>
                            <div hidden={plan.published_at || plan.review_at}>
                                <img src={`/assets/icon/iconDraft.svg`} alt="iconDraft.svg"/>
                                &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.draft')}</span>
                            </div>
                            <div hidden={!(plan.published_at && now < plan.start_at) || plan.review_at}>
                                <img src={`/assets/icon/iconBeforeStart.svg`} alt="iconBeforeStart.svg"/>
                                &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.beforeStart')}</span>
                            </div>
                            <div hidden={!(plan.published_at && now > plan.start_at && now < plan.end_at) || plan.review_at}>
                                <img src={`/assets/icon/iconRunning.svg`} alt="iconRunning.svg"/>
                                &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.running')}</span>
                            </div>
                            <div hidden={!(plan.published_at && now > plan.end_at)}>
                                <img src={`/assets/icon/iconFinish.svg`} alt="iconFinish.svg"/>
                                &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.finish')}</span>
                            </div>
                        </td>
                        <td>
                            <div className="d-flex align-items-center">
                                <div hidden={plan.review_status !== 0}> {t('plans.unReview')}</div>
                                <div hidden={plan.review_status !== 1}> {t('plans.draftReview')}</div>
                                <div hidden={plan.review_status !== 2}> {t('plans.monitored')}</div>
                            </div>
                        </td>
                        <td>
                            <KirokuButton color="light"
                                          style={{
                                              height: 36, fontSize: 14, width: 105,
                                              backgroundImage: 'linear-gradient(to top, #f2f4f7, #ffffff)'
                                          }}
                                          onClick={() => history.push('/plans/' + plan.id)}
                            >{t('plans.viewPlan')}</KirokuButton>
                        </td>
                    </tr>;
                },
            )}
        </TBody>
    </KirokuTable>
</ComponentWeb>);
}

export default function useDiffContacts(oldProfile, logProfile) {
    const oldContacts = oldProfile.contacts || [];
    const logContacts = logProfile.contacts || [];

    let diffs = [];
    oldContacts.forEach((contact) => {
        diffs.push([contact, null]);
    });

    logContacts.forEach((contact, index) => {
        if (diffs[ index ]) {
            diffs[ index ] = [diffs[ index ][ 0 ], contact];
        }
        if (!diffs[ index ]) {
            diffs.push([null, contact]);
        }
    });

    const diffsContacts = diffs.map(diffs => {
        const lhs = diffs[ 0 ];
        const rhs = diffs[ 1 ];
        if (diffs[ 0 ] && diffs[ 1 ]) {
            return {
                isDiff: lhs.memo !== rhs.memo || lhs.name !== rhs.name || lhs.phone_number !== rhs.phone_number || lhs.relationship !== rhs.relationship,
                lhs, rhs,
            };
        }

        return {
            isDiff: true,
            lhs, rhs,
        };
    });

    return {
        diffsContacts,
        isDiff: JSON.stringify(oldContacts) !== JSON.stringify(logContacts),
    };
}

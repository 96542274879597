import React, { useEffect, useState } from 'react';
import {
    KirokuButton, KirokuCardList, KirokuCheckboxBorder, KirokuLoader, KirokuModal, KirokuModalBody, KirokuModalFooter,
    KirokuModalHeader, KirokuSectionDropdownShowMore, SearchNotFound,
}                                     from '../../ui';
import _                              from 'lodash';
import lodash                         from 'lodash';
import { useTranslation }             from 'react-i18next';

export default function ModalSelectCustomerOfTeam(props) {
    const { t } = useTranslation();

    const { customersSelected, customers, teams, closeModal, show, customerSelectedInModal } = props;

    const [customerAfterSearched, setCustomerAfterSearched] = useState([]);
    const [listCustomer, setListCustomer]                   = useState([]);
    const [currentTags, setCurrentTags]                     = useState([]);
    const [loading, setLoading]                             = useState(false);

    useEffect(() => {
        setLoading(true);
        if (show) {
            setCustomerAfterSearched(getCustomers(customers, customersSelected));
            setListCustomer(getCustomers(customers, customersSelected));
            setLoading(false);
        }
    }, [customersSelected, customers, show]);

    const getCustomers = (customers, customersSelected) => {
        let customersNew = JSON.parse(JSON.stringify(customers));
        if (customersSelected.length) {
            return customersNew.map(customer => {
                return {
                    ...customer,
                    isChecked: _.findIndex(customersSelected, ['customerId', customer.id]) !== -1,
                };
            });
        } else {
            return customersNew.map(customer => {
                return {
                    ...customer,
                    isChecked: false,
                };
            });
        }
    };

    const handleChangeTags = (options) => {
        setCurrentTags(options);
        let customersOfTeam = [];
        if (options.length) {
            options.forEach(itm => {
                customersOfTeam = customersOfTeam.concat(itm.customers);
            });
            customersOfTeam = _.uniqBy(customersOfTeam, 'id');
            let customersOfTeamNew = customersOfTeam.map(customer => {
                const indexCustomerNew = _.findIndex([...listCustomer], { 'id': customer.id });
                if (customer.id === listCustomer[indexCustomerNew].id) {
                    return {
                        ...customer,
                        isChecked: listCustomer[indexCustomerNew].isChecked,
                    };
                }
                return {
                    ...customer,
                };
            });
            setCustomerAfterSearched(customersOfTeamNew);

        } else {
            setCustomerAfterSearched(listCustomer);
        }

    };

    const handleCheckAll = () => {
        const listAllCustomer = customerAfterSearched.map(customer => {
            return {
                ...customer,
                isChecked: lodash.findIndex(customerAfterSearched, ctm => !ctm.isChecked) !== -1
            }
        });
        setCustomerAfterSearched(listAllCustomer);
        setListCustomer(listCustomer.map(customer => {
            const indexCustomerIsChecked = lodash.findIndex(listAllCustomer, ctm => ctm.id === customer.id);
            if(indexCustomerIsChecked !== -1) {
                return {
                    ...customer,
                    isChecked: listAllCustomer[indexCustomerIsChecked].isChecked
                }
            }
            return customer;
        }))

    };

    const handleCheckCustomer = (customer) => {
        setCustomerAfterSearched( customerAfterSearched.map(ctm => {
           if (ctm.id === customer.id)
               return {
                ...ctm,
                   isChecked: !ctm.isChecked,
               }
           return ctm;
        }))
        setListCustomer(listCustomer.map(ctm => {
            if (ctm.id === customer.id) {
                return {
                    ...ctm,
                    isChecked: !ctm.isChecked,
                }
            }
            return ctm;
        }))
    };

    const handleCancel = () => {
        closeModal();
        handleReset();
    };

    const handleApplySelectCustomer = () => {
        closeModal();
        customerSelectedInModal(listCustomer);
        handleReset();
    };

    const handleReset = () => {
        setCurrentTags([]);
        setCustomerAfterSearched([]);
        setListCustomer([]);
    };

    return (
        <KirokuModal show={ show } onCloseModal={ () => closeModal() }>
            <KirokuModalHeader>
                <div className={ 'pt-3' }>
                    <KirokuSectionDropdownShowMore
                        placeholderSelectTeam
                        onChange={ (options) => handleChangeTags(options) }
                        value={ currentTags }
                        options={ teams }
                        tagType={ 'Team' }
                        sizeCustomize={ 5 }
                        placeholder={ t('Team.placeholderSearch') }
                    />
                </div>
            </KirokuModalHeader>
            <KirokuModalBody>
                <div id="body-modal-as">
                    <KirokuCardList
                        headerLeft={ t('common.Name') }
                        headerRight={
                            <div className='text-primary' style={ { cursor: 'pointer' } }
                                 hidden={ customerAfterSearched.length === 0 }>
                                        <span hidden={ _.findIndex(customerAfterSearched, user => !user.isChecked) === -1 }
                                              onClick={ () => handleCheckAll() }
                                        >{ t('common.SelectAll') }</span>

                                <span hidden={ _.findIndex(customerAfterSearched, user => !user.isChecked) !== -1 }
                                      onClick={ () => handleCheckAll() }
                                >{ t('common.UnSelect') }</span>
                            </div>
                        }
                    >
                        <section className="scroll-data-survey">
                            <KirokuLoader loading={loading}>
                                {customerAfterSearched.length ?
                                    customerAfterSearched.map((customer, index) => {
                                        return <div key={ customer.id } className={'cursor-pointer'}>
                                            <KirokuCheckboxBorder
                                                onChange={ () => handleCheckCustomer(customer) }
                                                onClick={ () => handleCheckCustomer(customer) }
                                                key={ index }
                                                id={ `${ index }-${ customer.id }` }
                                                checked={ customer.isChecked }
                                                content={ <span
                                                    className={ 'cursor-pointer' }>{ customer.name }</span> }/>
                                        </div>;
                                    }) : <SearchNotFound/>
                                }
                            </KirokuLoader>
                        </section>
                    </KirokuCardList>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={ handleCancel } color={ 'white' }>
                    { t('common.Cancel') }
                </KirokuButton>
                <KirokuButton onClick={ handleApplySelectCustomer } color={ 'primary' }>
                    { t('common.Apply') }
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );

}

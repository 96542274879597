export default function useCertification(oldProfile, logProfile) {

    oldProfile.certifications = oldProfile.certifications || [];
    logProfile.certifications = logProfile.certifications || [];

    const oldCertificate = oldProfile.certifications[ 0 ];
    const logCertificate = logProfile.certifications[ 0 ];


    let diffServices = [];
    if(oldCertificate) {
        oldCertificate.services.forEach(service => {
            diffServices.push([service, null]);
        })
    }
    if(logCertificate) {
        logCertificate.services.forEach((service, index) => {
            if (diffServices[ index ]) {
                diffServices[ index ] = [diffServices[ index ][ 0 ], service];
            }
            if (!diffServices[ index ]) {
                diffServices.push([null, service]);
            }
        })
    }
    diffServices = diffServices.map(diffs => {
        const lhs = diffs[ 0 ];
        const rhs = diffs[ 1 ];
        if (diffs[ 0 ] && diffs[ 1 ]) {
            return {
                isDiff: lhs.name !== rhs.name || lhs.startDate !== rhs.startDate || lhs.endDate !== rhs.endDate,
                lhs, rhs,
            };
        }

        return {
            isDiff: true,
            lhs, rhs,
        };
    });

    let diffMonitoringTerms = [];
    if(oldCertificate) {
        oldCertificate.monitoring_terms.forEach(monitoring => {
            diffMonitoringTerms.push([monitoring, null]);
        })
    }
    if(logCertificate) {
        logCertificate.monitoring_terms.forEach((monitoring, index) => {
            if (diffMonitoringTerms[ index ]) {
                diffMonitoringTerms[ index ] = [diffMonitoringTerms[ index ][ 0 ], monitoring];
            }
            if (!diffMonitoringTerms[ index ]) {
                diffMonitoringTerms.push([null, monitoring]);
            }
        })
    }

    diffMonitoringTerms = diffMonitoringTerms.map(diffs => {
        const lhs = diffs[ 0 ];
        const rhs = diffs[ 1 ];
        if (diffs[ 0 ] && diffs[ 1 ]) {
            return {
                isDiff: lhs.date !== rhs.date,
                lhs, rhs,
            };
        }

        return {
            isDiff: true,
            lhs, rhs,
        };
    });

    return {
        certification_number: {
            lhs: oldCertificate && oldCertificate.certification_number,
            rhs: logCertificate && logCertificate.certification_number,
        },

        issuer: {
            lhs: oldCertificate && oldCertificate.issuer,
            rhs: logCertificate && logCertificate.issuer,
        },
        diffServices,
        diffMonitoringTerms,
    };
}

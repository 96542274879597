import React, { useEffect } from 'react';
import {
    KirokuButton, KirokuModal,
    KirokuModalBody, KirokuModalFooter,
    KirokuModalHeader,
}                           from '../../../../ui';
import { useTranslation }   from 'react-i18next';
import useSelectedCustomer  from './useSelectedCustomer';
import CustomerOptionsTab   from './CustomerOptionsTab';
import CustomerSelectedTab  from './CustomerSelectedTab';
import { resources }        from '@kiroku/api';
import { useCondition }     from '../../useCondition';
import useSearchTeam        from './useSearchTeam';
import _                    from 'lodash';

export default function ModalChoiceCustomers({ show, closeModal }) {
    const { t }                       = useTranslation();
    const {
              selectTab, handleSelectTab,
              getCustomers, customers,
              getCustomerSearched,
              handleCloseModalSelect, setDefaultTabCondition,
          }                           = useSelectedCustomer();
    const { search, result, loading } = resources.useSearch('Customer');

    const { updateRecordCondition, recordCondition } = useCondition();

    const {teams, searchTeams}                       = useSearchTeam();

    useEffect(() => {
        if (show) {
            search({
                type: 'all',
            });
            searchTeams();
        }
    }, [search, show, searchTeams]);

    useEffect(() => {
        if (result && show) {
            setDefaultTabCondition();
            getCustomers(result);
        }
    }, [result, getCustomers, setDefaultTabCondition, show]);


    const componentCustomer = {
        optionsTab : CustomerOptionsTab,
        selectedTab: CustomerSelectedTab,
    };

    const ComponentShowing = componentCustomer[selectTab];

    return (
            <KirokuModal show={ show }
                         onCloseModal={ closeModal }>
                <KirokuModalHeader>
                    <div className={ 'font-header-on-modal' }> { t('RecordsBeta.customerSelection') }</div>
                </KirokuModalHeader>
                <KirokuModalBody className={'p-3'}>
                    <div className="d-flex justify-content-around border-btn-status-group">
                        <div className={ `border-width-each-status first-btn font-text-on-btn cursor-pointer
                            ${ selectTab === 'selectedTab' ? 'btn-active' : '' }
                        ` }
                             onClick={ () => handleSelectTab('selectedTab') }>
                            { t('RecordsBeta.displaySelection') }
                        </div>
                        <div
                            className={ `border-width-each-status third-btn font-text-on-btn cursor-pointer
                             ${ selectTab === 'optionsTab' ? 'btn-active' : '' }
                            ` }
                            onClick={ () => handleSelectTab('optionsTab') }>
                            { t('RecordsBeta.showAll') }
                        </div>
                    </div>
                    <ComponentShowing
                        selectedTab={ selectTab }
                        show={ show }
                        teams={ teams }
                        loading={ loading }
                    />
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={ () => {
                        handleCloseModalSelect();
                        closeModal();
                    } }
                                  color={'light'}
                    >
                        { t('RecordsBeta.cancel') }
                    </KirokuButton>
                    <KirokuButton color={'primary'}
                                  onClick={ () => {
                                      let customerSearched = customers.filter(customer => customer.selected)
                                          .map(ctm => ctm.id);
                                      getCustomerSearched(customerSearched);
                                      let isCheckedAllCustomer = _.findIndex(customers, ctm => !ctm.selected);
                                      if (isCheckedAllCustomer === -1) {
                                          updateRecordCondition({
                                              ...recordCondition,
                                              cId: 'all',
                                              page: 1,
                                              customerTab:'SEARCH_CUSTOM_CUSTOMER'
                                          });
                                      } else {
                                          updateRecordCondition({
                                              ...recordCondition,
                                              cId: customerSearched,
                                              page: 1,
                                              customerTab:'SEARCH_CUSTOM_CUSTOMER'
                                          });
                                      }
                                      closeModal();
                                  } }
                    >
                        { t('RecordsBeta.confirm') }
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
    );
}

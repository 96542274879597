import React                                                          from 'react';
import { ComponentMobile, ComponentWeb, KirokuButton, KirokuCardWeb } from '../../../ui';
import { useTranslation }                                             from 'react-i18next';
import PersonalInformation                                            from './PersonalInformation/PersonalInformation';
import PersonalAvatar                                                 from './PersonalInformation/PersonalAvatar';
import TagCustomer                                                    from './TagsCustomer/TagCustomer';
import CustomerInformation                                            from './CustomerInfomation/CustomerInformation';
import PropTypes                                                      from 'prop-types';
import ContactsEditor                                                 from './ContactsEditor/ContactsEditor';
import Certification                                                  from './Certifications/Certification';
import Guardianship                                                   from './Guardianship/Guardianship';
import { useCustomerContext }                                         from '../MakeCustomer/CustomerContext';
import useValidationCustomer                                          from '../MakeCustomer/useValidationCustomer';
import { useHistory }                                                 from 'react-router-dom';

export default function CustomerForm({ submit, disabledButton }) {

    const { t } = useTranslation();
    const { infoCustomer } = useCustomerContext();
    const { btnSave } = useValidationCustomer();
    const history = useHistory()

    return (
        <div className="padding-responsive">
            <ComponentMobile>
                <div className='mb-10'>
                    <KirokuCardWeb>
                        <div style={{ padding: "25px 0" }}>
                            <PersonalAvatar/>
                        </div>
                    </KirokuCardWeb>
                </div>
            </ComponentMobile>
            <KirokuCardWeb>
                <div className="pt-3 pb-3 row m-0">
                    <div className="col-md-8">
                        <PersonalInformation/>
                    </div>
                    <div className='fix-center col-md-4 as'>
                        <ComponentWeb>
                            <PersonalAvatar/>
                        </ComponentWeb>
                    </div>
                </div>
            </KirokuCardWeb>
            <div className='mt-10'>
                <KirokuCardWeb>
                    <div className='pt-3 pb-3 row m-0'>
                        <div className='col-md-8'>
                            <TagCustomer/>
                        </div>
                        <div className='col-md-4'/>
                    </div>
                </KirokuCardWeb>
            </div>
            <div className='mt-10'>
                <KirokuCardWeb>
                    <div className='p-3'>
                        <CustomerInformation/>
                    </div>
                </KirokuCardWeb>
            </div>

            <div className='mt-10'>
                <KirokuCardWeb>
                    <div className='p-3'>
                        <ContactsEditor/>
                    </div>
                </KirokuCardWeb>
            </div>

            <div className='mt-10'>
                <KirokuCardWeb>
                    <div className='p-3'>
                        <Certification/>
                    </div>
                </KirokuCardWeb>
            </div>

            <div className='mt-10'>
                <KirokuCardWeb>
                    <div className='p-3'>
                        <Guardianship/>
                    </div>
                </KirokuCardWeb>
            </div>

            <ComponentWeb>
                <div className='d-flex justify-content-center mt-24' style={{ minHeight: '130px' }}>
                    <div>
                        <KirokuButton color='white' onClick={() => history.goBack()}>
                            {t('common.Cancel')}
                        </KirokuButton>
                    </div>
                    <div className='ml-20'>
                        <KirokuButton
                            disabled={btnSave || disabledButton}
                            onClick={() => submit(infoCustomer)}
                            color='primary'>
                            {t('common.Save')}
                        </KirokuButton>
                    </div>
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <div className="pt-3">
                    <KirokuButton color='white' className="btn-block" onClick={() => history.goBack()}>
                        {t('common.Cancel')}
                    </KirokuButton>
                </div>
                <div className="pt-3 pb-5">
                    <KirokuButton className="btn-block" disabled={btnSave || disabledButton} onClick={() => submit(infoCustomer)}
                                  color='primary'>
                        {t('common.Save')}
                    </KirokuButton>
                </div>
            </ComponentMobile>
            <ComponentMobile>
                <div style={ { marginBottom: 80 } }/>
            </ComponentMobile>
        </div>
    )
}

CustomerForm.propTypes = {
    submit: PropTypes.func.isRequired
}

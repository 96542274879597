import React, { useReducer } from "react";
import CustomerContext       from "./CustomerContext";
import CustomerReducer       from "./CustomerReducer";
import moment                from "moment";

export default function CustomerProvider({ customer = null, children }) {

    const initialCustomer = {
        isValidContractStartDate: true,
        isValidContractEndDate: true,
        post_code: "",
        avatar: "",
        blood_type: "Unknown",
        gender: 'unselected',
        guardianship_type: "",
        date_of_birth: null,
        contract_end: null,
        contract_start: moment(new Date()).unix(),
        classification: 0,
        certificate: [],
        contacts: [
            {
                name: '',
                phone_number: '',
                memo: '',
                relationship: 'unknown'
            }
        ],
        certifications: [
            {
                certification_number: '',
                issuer: '',
                certification_date: '',
                services: [
                    {
                        name: '',
                        startDate: '',
                        endDate: ''
                    }
                ],
                monitoring_terms: [
                    {
                        date: ''
                    }
                ],
                certification_expiration: null
            }
        ],
        teams: [],
        users: [],
        ...customer
    };

    const [ infoCustomer, dispatch ] = useReducer(CustomerReducer, initialCustomer, () => initialCustomer);

    return (
        <CustomerContext.Provider value={{ infoCustomer, dispatch }}>
            {children}
        </CustomerContext.Provider>
    );
};

import {resources} from "@kiroku/api";

export default function() {
    const { search, result, loading, pagination, cancelAPISearch, condition} = resources.useSearch('Customer');
    return {
        searchReportMonth: search,
        resultReportMonth: result,
        loadingReportMonth: loading,
        paginationReportMonth: pagination,
        cancelAPISearchReportMonth: cancelAPISearch,
        conditionReportMonth: condition
    }
}

import React, { useEffect, useState }                                                             from 'react';
import { useTranslation }                                                                         from 'react-i18next';
import useDropDownData
                                                                                                  from '../../useDropDownData';
import { resources }                                                                              from '@kiroku/api';
import useNotification
                                                                                                  from '../../Layout/useNotification';
import { ComponentMobile, ComponentWeb, KirokuButton, KirokuDropdownResetTitle, KirokuInputArea } from '../../../ui';
import { useMixpanelTracking }                                                                    from '../../../Mixpanel/useMixpanelTracking';

export default function SupportMonitored({ show, closeModal, support, loadingDetail, isPlanMonitored }) {
    const { t }                                                   = useTranslation();
    const { dataActionStatus, dataAchievementStatus, statusIcon } = useDropDownData();
    const [actionStatus, setActionStatus]                         = useState('');
    const [achievementStatus, setAchievementStatus]               = useState('');
    const [comment, setComment]                                   = useState('');
    const [result, loadDetailView]                                = resources.useDetail('ReviewSupport');
    const createReview                                            = resources.useCreate('ReviewSupport')[1];
    const updateReview                                            = resources.useUpdate('ReviewSupport')[1];
    const { notification }                                        = useNotification();
    const [isDisabled, setIsDisabled]                             = useState(false);
    const { trackingMixpanel }                                    = useMixpanelTracking();

    useEffect(() => {
        if (show) {
            loadDetailView(support.id);
            setIsDisabled(false);
        }
    }, [loadDetailView, support, show]);

    useEffect(() => {
        if (show && result) {
            setActionStatus(result.review ? result.review.action_status : '');
            setAchievementStatus(result.review ? result.review.achievement_status : '');
            setComment(result.review ? result.review.comment : '');
        } else {
            setActionStatus('');
            setAchievementStatus('');
            setComment('');
        }
    }, [support, show, result]);

    const handleSubmit = (isDraft) => {
        let valueSubmit = {
            support_id        : support.id,
            action_status     : actionStatus,
            achievement_status: achievementStatus,
            comment,
            is_draft          : isDraft,
        };
        setIsDisabled(true);
        if (result.review) {
            updateReview(support.id, valueSubmit).then(() => {
                setIsDisabled(false);
                notification(t('message.UpdateSuccess'), 'success');
                trackingMixpanel('UPDATE_REVIEW_SUPPORT', valueSubmit);
                loadingDetail();
                closeModal();
            });
        } else {
            createReview(valueSubmit).then(() => {
                trackingMixpanel('ADD_REVIEW_SUPPORT', valueSubmit);
                setIsDisabled(false);
                notification(t('message.CreateSuccess'), 'success');
                loadingDetail();
                closeModal();
            });
        }
    };
    return (
        <div className={ 'bg-white mb-3' }>
            <div className="padding-responsive">
                <div className={ 'review-plan-text pb-2' }>
                    { t('plans.review') }
                </div>
                <div className="d-flex align-items-center">
                    <div className={'label-monitor-form font-size-14'}
                    >
                        { t('plans.resultOfAction') }:
                    </div>
                    <div className="pl-2"/>
                    <div style={ { width: 170 } }>
                        <KirokuDropdownResetTitle
                            style={ { minWidth: '120px', height: 36 } }
                            title={ actionStatus ?
                                <div className={ 'd-flex font-size-14' }>  { t(`status.${ actionStatus }`) } <img
                                    src={ `/assets/img/${ statusIcon[actionStatus] }` } className={ 'pl-2' }
                                    alt={ statusIcon[actionStatus] }/></div> : '' }
                            items={ dataActionStatus }
                            onSelect={ event => {
                                setActionStatus(event.kirokuSelected.value);
                            } }
                        />
                    </div>
                </div>
                <div className="pt-3"/>
                <div className={ 'd-flex align-items-center' }>
                    <div
                         className={'label-monitor-form font-size-14'}
                    >
                        { t('plans.resultOfAchievement') }:
                    </div>
                    <div className="pl-2"/>
                    <div style={ { width: 170 } }

                    >
                        <KirokuDropdownResetTitle
                            style={ { minWidth: '120px', height: 36 } }
                            title={ achievementStatus ?
                                <div className={ 'd-flex font-size-14' }>  { t(`achievementStatus.${ achievementStatus }`) } <img
                                    src={ `/assets/img/${ statusIcon[achievementStatus] }` } className={ 'pl-2' }
                                    alt={ statusIcon[achievementStatus] }/></div> : '' }
                            items={ dataAchievementStatus }
                            onSelect={ event => {
                                setAchievementStatus(event.kirokuSelected.value);
                            } }
                        />
                    </div>
                </div>
                <div className="pt-3"/>
                <div className={ 'd-flex comment-monitor' }>
                    <div className={ 'label-monitor-form font-size-14 pt-2' }
                    >
                        { t('plans.commentSupport') }:
                    </div>
                    <div className="pl-2"/>
                    <div  className={'input-area-comment-monitor font-size-14'}>
                        <KirokuInputArea
                            onChange={ event => setComment(event.target.value) }
                            value={ comment }
                        />
                    </div>
                </div>
                <ComponentWeb>
                    <div className={ 'p-3 d-flex' }
                         style={ { borderTop: 'solid 1px #dfe2e5' } }
                    >
                        <KirokuButton onClick={ () => closeModal() } color={ 'light' }
                                      className={'font-size-14'}
                        >
                            { t('plans.cancel') }
                        </KirokuButton>
                        <div className={ 'p-2' }/>
                        <KirokuButton onClick={ () => handleSubmit(false) }
                                      className={'font-size-14'}
                                      disabled={ !actionStatus || !achievementStatus || isDisabled }
                                      color={ 'success' }
                        >
                            { t('plans.confirm') }
                        </KirokuButton>
                        <div className="pl-3">
                            <KirokuButton
                                onClick={ () => handleSubmit(true) }
                                disabled={ isPlanMonitored }
                                color={ 'primary' }

                                className={ 'font-size-14' }
                            >
                                { t('plans.save as draft') }
                            </KirokuButton>
                        </div>
                    </div>
                </ComponentWeb>
                <ComponentMobile>
                    <div className="text-center">
                        <div className={'pb-3'}>
                            <KirokuButton onClick={ () => handleSubmit(false) }
                                          className={'font-size-14'}
                                          disabled={ !actionStatus || !achievementStatus || isDisabled }
                                          color={ 'success' }
                            >
                                { t('plans.confirm') }
                            </KirokuButton>
                        </div>
                        <div className="d-flex">
                            <KirokuButton onClick={ () => closeModal() } color={ 'light' }
                                          className={'font-size-14'}
                            >
                                { t('plans.cancel') }
                            </KirokuButton>
                            <div className="pl-3">
                                <KirokuButton
                                    onClick={ () => handleSubmit(true) }
                                    disabled={ isPlanMonitored }
                                    color={ 'primary' }

                                    className={ 'font-size-14' }
                                >
                                    { t('plans.save as draft') }
                                </KirokuButton>
                            </div>
                        </div>
                    </div>
                </ComponentMobile>
            </div>
        </div>
    );
}

import React                          from 'react';
import { handleLogError, withSentry } from './Sentry';
import config                         from '../config';

class ErrorBoundary extends React.Component {

    componentDidCatch(error, errorInfo) {
        if(!config.KNOWN_ERRORS.includes(error.code)) {
            handleLogError(this.props.sentry, {error, errorInfo});
        }
    }

    render() {
        return this.props.children;
    }
}

export default withSentry(ErrorBoundary);

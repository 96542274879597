import React               from 'react';
import { useTranslation }  from 'react-i18next';
import { withPermissions } from '../../HOC';

export default function HeaderAction() {
    const { t } = useTranslation();
    return <span>{t('common.Action')}</span>;
}

export const HeaderActionWithPermission = withPermissions(HeaderAction);

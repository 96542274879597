import { useTranslation }                from 'react-i18next';
import { resources }                     from '@kiroku/api';
import React, { useEffect, useState }    from 'react';
import { getGroupedOptions }             from '../../Converter';
import _                                 from 'lodash';
import { KirokuSectionDropdownShowMore } from '../../../ui';

export default function DropdownSelectCustomerFromTeam(props) {
    const { t }                         = useTranslation();
    const { search, cancelAPISearch }   = resources.useSearch('Team');
    const [teams, setTeams]             = useState([]);
    const [currentTags, setCurrentTags] = useState([]);

    useEffect(() => {
        if (props.show) {
            search({ type: 'all' }).then(res => {
                setTeams(res.result);
            });
            setCurrentTags([]);
        }
        return () => {
            cancelAPISearch();
        };
    }, [search, cancelAPISearch, props.show]);

    let teamOptions = getGroupedOptions('Team', teams);

    const handleChangeTags = (options) => {
        let customers = [];
        options.map(item => {
            customers = customers.concat(item.customers);
            return item;
        });
        setCurrentTags(options || []);
        props.getCustomers(_.uniqBy(customers, 'id'));
        props.getTeamSelecting(options)
    };

    return (
        <KirokuSectionDropdownShowMore
            isSearchTags
            tagType={ 'Team' }
            placeholder={ t('Team.placeholderSearch') }
            onChange={ options => handleChangeTags(options) }
            value={ currentTags }
            options={ teamOptions }
        />

    );

}

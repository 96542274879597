import { resources }                                  from '@kiroku/api';
import useReportCondition                             from '../makeReport/useReportCondition';
import React, { useEffect }                           from 'react';
import { removeConditionsUndefined }                  from '../../Converter';
import momentTZ                                       from 'moment-timezone';
import { KirokuButton, KirokuLoader, SearchNotFound } from '../../../ui';
import If                                             from '../../If';
import { checkUseHeadingQuestion }                    from './index';
import '../report.css';
import { useTranslation }                             from 'react-i18next';

const renderQuestion = (question) => {
    switch (question.type) {
        case 'Media':
            return (
                <div className="pt-2">
                    <img className="report-tab" src={ question.answers } alt=""/>
                </div>
            );
        case 'YesNo':
        case 'Date':
            return (<div className="answer-content">{ question.answers }</div>);
        default:
            return (<div className="answer-content">{ question.answers }</div>);
    }
};

const Template = ({ reports }) => (
    <div className="report-tab container">
        <div className="wrap-content">
            <div className="A4">
                <div className="content">
                    <div className="wrap-bg">
                        { reports.customers.map((customer, index) => {
                            return (
                                <div className="pb-5" key={ index }>
                                    <div>
                                        <label className="report-tab">
                                            { reports.translate_customerName }
                                        </label>
                                        <div className="border-title-template">
                                            { customer.customerName }
                                        </div>
                                    </div>
                                    <div>
                                        { customer.records.map((record, index) => {
                                            return (
                                                <div className="pt-3" key={ index }>
                                                    <div
                                                        className="row fix-margin-row border-bottom-dashed ">
                                                        <div className={ 'col-3 fix-padding-col' }>
                                                            <label className="report-tab">
                                                                { reports.translate_title }
                                                            </label>
                                                            <div>
                                                                { record.title }
                                                            </div>
                                                        </div>
                                                        <div className="col-3 pb-10px">
                                                            <label className="report-tab">
                                                                { reports.translate_serviced }
                                                            </label>
                                                            <div>{ record.serviced_date_time }</div>
                                                        </div>
                                                        <div className={ 'col-6 pb-10px' }>
                                                            <label className="report-tab">
                                                                { reports.translate_created_by }
                                                            </label>
                                                            <div>
                                                                { record.record_by }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="answers-list">
                                                        { record.questions.map((question, index) => {
                                                            return (
                                                                <div
                                                                    className="d-flex flex-column justify-content-between pt-2 answer-item"
                                                                    key={ index }>
                                                                    <div
                                                                        className={ index === record.questions.length - 1 ?
                                                                            'border-bottom-inline row fix-margin-row pb-2' :
                                                                            'border-bottom-dashed row fix-margin-row' }>
                                                                        <label
                                                                            className="report-tab col-3 pb-10px fix-padding-col">
                                                                            { checkUseHeadingQuestion(question) }
                                                                        </label>
                                                                        <div className={ 'col-9' }>
                                                                            { renderQuestion(question) }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }) }
                                                    </div>
                                                </div>
                                            );
                                        }) }
                                    </div>
                                </div>
                            );
                        }) }
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default function CustomerOnly() {

    const { search, result, loading } = resources.useSearch('Report');
    const { condition, print }        = useReportCondition();
    const { t }                       = useTranslation();

    useEffect(() => {
        if (condition) {
            removeConditionsUndefined(condition);
            search({
                type    : 'report',
                template: 'CustomerOnly',
                lang    : localStorage.getItem('i18nextLng') || 'ja',
                timezone: momentTZ.tz.guess(),
                ...condition,
            });
        }
    }, [search, condition]);

    return (
        <KirokuLoader loading={ loading }>
            <If statement={ result.customers && result.customers.length } otherwise={ <SearchNotFound
                message={t(`Export.showResultSearch`)}
                description={t(`Export.TrySearch`)}
            /> }>
                { () => (
                    <div>
                        <div className="d-flex pt-2 justify-content-end pr-10">
                            <div className="p-2"/>
                            <KirokuButton color={ 'primary' } onClick={ () => print('CustomerOnly') }>
                                { t('common.print') }
                            </KirokuButton>
                        </div>
                        <Template reports={ result }/>
                    </div>
                ) }
            </If>
        </KirokuLoader>
    );
}

import React, {Component}    from 'react';
import KirokuShortDate       from "./KirokuShortDate";
import {KirokuShortDateItem} from "./index";
import {DateRangePicker}     from "react-dates";
import moment                from "moment";
import { withTranslation }   from 'react-i18next';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

class KirokuDateRangePicker extends Component {

    state = {
        startDate: null,
        endDate: null,
        focusedInput: null,
        momentStartDate: null,
        momentEndDate: null,
    };

    UNSAFE_componentWillMount() {
        if (this.props.startDate) {
            this.setState({
                startDate: this.props.startDate,
                momentStartDate: this.props.startDate.startOf('day').unix()
            })
        }
        if (this.props.endDate) {
            this.setState({
                endDate: this.props.endDate,
                momentEndDate: this.props.endDate.endOf('day').unix()
            })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.startDate !== this.props.startDate || nextProps.endDate !== this.props.endDate) {
            const momentS = nextProps.startDate ? nextProps.startDate.startOf('day').unix() : null;
            const momentE = nextProps.endDate ? nextProps.endDate.endOf('day').unix() : null;
            this.setState({
                startDate: nextProps.startDate,
                endDate: nextProps.endDate,
                momentStartDate: momentS,
                momentEndDate: momentE,
            })
        }
    }

    handleOnChangeDateRanger(startDate, endDate) {
        const momentS = startDate ? startDate.startOf('day').unix() : NaN;
        const momentE = endDate ? endDate.endOf('day').unix() : NaN;
        this.setState({
            startDate, endDate,
            momentStartDate: momentS,
            momentEndDate: momentE,
        }, () => {
            this.props.onChangeDate({
                startDate, endDate,
                momentStartDate: momentS,
                momentEndDate: momentE,
            });
        });

    }

    shortDatePicker(name) {
        // const {dateRange} = this.props;
        // if (dateRange === name) {
        //     this.props.removeDateRange();
        //     return this.setState({
        //         startDate: null,
        //         endDate: null,
        //         focusedInput: null,
        //         momentStartDate: null,
        //         momentEndDate: null,
        //     })
        // }
        // this.props.selectDateRange(name);
        switch (name) {
            case "Today":
                let today = {
                    startDate: moment().startOf('day'),
                    endDate: moment().endOf('day'),
                    momentStartDate: moment().startOf('day').unix(),
                    momentEndDate: moment().endOf('day').unix(),
                };

                this.props.onChangeDate({
                    ...today
                });
                return this.setState({
                    ...today
                });

            case 'Yesterday':
                let yesterday = {
                    startDate: moment().subtract(1, 'days').startOf('day'),
                    endDate: moment().subtract(1, 'days').endOf('day'),
                    momentStartDate: moment().subtract(1, 'days').startOf('day').unix(),
                    momentEndDate: moment().subtract(1, 'days').endOf('day').unix(),
                };
                this.props.onChangeDate({
                    ...yesterday
                });

                return this.setState({
                    ...yesterday
                });
            case 'LastWeek':
                let lastWeek = {
                    startDate: moment().subtract(1, 'weeks').startOf('week'),
                    endDate: moment().subtract(1, 'weeks').endOf('week'),
                    momentStartDate: moment().subtract(1, 'weeks').startOf('week').unix(),
                    momentEndDate: moment().subtract(1, 'weeks').endOf('week').unix(),
                };
                this.props.onChangeDate({
                    ...lastWeek
                });
                return this.setState({
                    ...lastWeek

                });
            case 'LastMonth':
                let lastMonth = {
                    startDate: moment().subtract(1, 'months').startOf('month'),
                    endDate: moment().subtract(1, 'months').endOf('month'),
                    momentStartDate: moment().subtract(1, 'months').startOf('month').unix(),
                    momentEndDate: moment().subtract(1, 'months').endOf('month').unix(),
                };

                this.props.onChangeDate({
                    ...lastMonth
                });
                return this.setState({
                    ...lastMonth
                });
            case 'Last7days':
                let last7Days = {
                    startDate: moment().subtract(7, 'days').startOf('day'),
                    endDate: moment().subtract(1, 'days').endOf('day'),
                    momentStartDate: moment().subtract(7, 'days').startOf('day').unix(),
                    momentEndDate: moment().subtract(1, 'days').endOf('day').unix(),
                };
                this.props.onChangeDate({
                    ...last7Days
                });
                return this.setState({
                    ...last7Days
                });
            case 'Last30days':
                let last30Days = {
                    startDate: moment().subtract(30, 'days').startOf('day'),
                    endDate: moment().subtract(1, 'days').endOf('day'),
                    momentStartDate: moment().subtract(30, 'days').startOf('day').unix(),
                    momentEndDate: moment().subtract(1, 'days').endOf('day').unix(),
                };

                this.props.onChangeDate({
                    ...last30Days
                });
                return this.setState({
                    ...last30Days
                });
            default :
                return;
        }
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {

            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => (
            {
                popupVisible: !prevState.popupVisible
            }
        ));
    }

    handleOutsideClick() {
        if (!this.outSidleClick) {
            return;
        }
        this.handleClick();
    }

    render() {
        const {t, numberMonthSelected, isMobile, disabled, dateRange, showDateRanger, startDateId, endDateId, placeholderStart_at, placeholderEnd_at } = this.props;

        return (
            <div>
                {!!showDateRanger ? (
                    <div className="pb-2">
                        <div className="wrap-text-customs-date d-flex">
                            <div className={`pl-2 pr-2 m-1 label-date-time-picker ${dateRange === "Today" ? "active" : "border-status-searching"}`}
                                onClick={this.shortDatePicker.bind(this, 'Today')}>{t('Surveys.day')}</div>
                            <div className={`pl-2 pr-2 m-1 label-date-time-picker ${dateRange === "Yesterday" ? "active" : "border-status-searching"}`}
                                onClick={this.shortDatePicker.bind(this, 'Yesterday')}>{t('Surveys.yesterday')}</div>
                            <div className={`pl-2 pr-2 m-1 label-date-time-picker ${dateRange === "LastWeek" ? "active" : "border-status-searching"}`}
                                onClick={this.shortDatePicker.bind(this, 'LastWeek')}>{t('Surveys.lastWeek')}</div>
                            <div className={`pl-2 pr-2 m-1 label-date-time-picker ${dateRange === "LastMonth" ? "active" : "border-status-searching"}`}
                                onClick={this.shortDatePicker.bind(this, 'LastMonth')}>{t('Surveys.lastMonth')}</div>
                            <div className={`pl-2 pr-2 m-1 label-date-time-picker ${dateRange === "Last7days" ? "active" : "border-status-searching"}`}
                                onClick={this.shortDatePicker.bind(this, 'Last7days')}>{t('Surveys.last7days')}</div>
                            <div className={`pl-2 pr-2 m-1 label-date-time-picker ${dateRange === "Last30days" ? "active" : "border-status-searching"}`}
                                onClick={this.shortDatePicker.bind(this, 'Last30days')}>{t('Surveys.last30days')}</div>
                        </div>
                    </div>
                ) : (<div/>)}
                <div id='fix-date-rage' ref={node => {
                    this.outSidleClick = node
                }}>
                    <DateRangePicker
                        disabled={disabled}
                        readOnly
                        numberOfMonths={numberMonthSelected || 2}
                        monthFormat={t("DateRange.monthFormat")}
                        hideKeyboardShortcutsPanel={true}
                        startDateId={startDateId || "startDate"}
                        endDateId={endDateId || "endDateId"}
                        startDatePlaceholderText={placeholderStart_at || t('Export.StartDate')}
                        endDatePlaceholderText={placeholderEnd_at || t('Export.EndDate')}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        focusedInput={this.state.focusedInput}
                        displayFormat={() => moment.localeData(localStorage.getItem('i18nextLng')).longDateFormat('LL')}
                        onDatesChange={({startDate, endDate}) =>
                            this.handleOnChangeDateRanger(startDate, endDate)
                        }
                        onFocusChange={(focusedInput) => {
                            this.setState({focusedInput})
                        }}
                        initialVisibleMonth={() => moment().subtract(1, 'month')}
                        isOutsideRange={() => false}
                        minimumNights={0}
                        showClearDates
                        renderCalendarInfo={() => {
                            return (
                                <KirokuShortDate
                                    isMobile={isMobile}
                                    closeSelectModal={() => {
                                        this.setState({focusedInput: null})
                                    }}
                                >
                                    <KirokuShortDateItem
                                        onClick={this.shortDatePicker.bind(this, 'Today')}>{t('Surveys.day')}</KirokuShortDateItem>
                                    <KirokuShortDateItem
                                        onClick={this.shortDatePicker.bind(this, 'Yesterday')}>{t('Surveys.yesterday')}</KirokuShortDateItem>
                                    <KirokuShortDateItem
                                        onClick={this.shortDatePicker.bind(this, 'LastWeek')}>{t('Surveys.lastWeek')}</KirokuShortDateItem>
                                    <KirokuShortDateItem
                                        onClick={this.shortDatePicker.bind(this, 'LastMonth')}>{t('Surveys.lastMonth')}</KirokuShortDateItem>
                                    <KirokuShortDateItem
                                        onClick={this.shortDatePicker.bind(this, 'Last7days')}>{t('Surveys.last7days')}</KirokuShortDateItem>
                                    <KirokuShortDateItem
                                        onClick={this.shortDatePicker.bind(this, 'Last30days')}>{t('Surveys.last30days')}</KirokuShortDateItem>
                                </KirokuShortDate>

                            )
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation()(KirokuDateRangePicker);

import React                        from 'react';
import {
    KirokuCardWeb, KirokuRadio,
    KirokuCardBodyWeb,
}                                   from '../../../../ui';
import { useTranslation }           from 'react-i18next';
import { useHeadingQuestion }       from '../../useSurvey';
import { useAsQuestionType }        from '../../../Recording';
import QuestionCardTitle            from './QuestionCardTitle';
import { ButtonNext, getNextYesNo } from './getNextQuestion';
import { QuestionSkipped }          from './QuestionSkipped';
const width = window.innerWidth;

export function setDefaultAnswerYesNo(question) {

    if (question[ 'defaultAnswer' ]) {
        return {
            ...question,
            answers: question[ 'defaultAnswer' ],
        };
    }
    return {
        ...question,
        answers: '',
    };
}

export default function YesNoQuestion({ question }) {

    const { t }                   = useTranslation();
    const { doAnswer, answering } = useAsQuestionType(question);
    const { content }             = useHeadingQuestion(question);

    const options = [{
        value: 'No',
        id   : `${question.index}No`,
        name : `questionYesNo${question.index}`,
    }, {
        value: 'Yes',
        id   : `${question.index}Yes`,
        name : `questionYesNo${question.index}`,
    }];

    return (
        <div id='component-question'>
            <div id='web-q' className='pb-3'>
                <KirokuCardWeb>
                    <div className={ (width <= 768) && !!question.answers ? 'border-has-answer': ' '}>
                    <QuestionCardTitle
                        isDisabled={question.isDisabled}
                        question={question}
                        handleResetAnswer={() => doAnswer('', question.index + 1)}
                        hasAnswer={!!question.answers}
                    />
                    <KirokuCardBodyWeb>
                        <QuestionSkipped
                            isDisabled={question.isDisabled}
                        >
                            <span className='content-name-question'>{content}</span>
                            <div className='answer-question' style={{ padding: 0, marginTop: '20px' }}>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        {options.map((option, index) => {
                                            return (
                                                <KirokuRadio
                                                    {...option}
                                                    key={index}
                                                    onChange={(event) => {
                                                        answering();
                                                        doAnswer(event.target.value,
                                                            getNextYesNo({
                                                                ...question,
                                                                isAnswers: !!event.target.value,
                                                                answers  : event.target.value,
                                                            }));
                                                    }}
                                                    checked={question.answers === option.value}
                                                    style={{ width: '100%' }}
                                                >
                                                    <span
                                                        style={{ color: '#495057' }}>{t(`createRecord.${option.value}`)}</span>
                                                </KirokuRadio>
                                            );
                                        })}
                                    </div>
                                    <div/>
                                    <div>
                                        <ButtonNext
                                            question={question}
                                            onClick={() => {
                                                if (question.answers) {
                                                    doAnswer(question.answers, getNextYesNo(question));
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </QuestionSkipped>
                    </KirokuCardBodyWeb>
                    </div>
                </KirokuCardWeb>
            </div>
        </div>
    );
}

"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "KirokuClientContext", {
  enumerable: true,
  get: function get() {
    return _KirokuProvider.KirokuClientContext;
  }
});
Object.defineProperty(exports, "KirokuProvider", {
  enumerable: true,
  get: function get() {
    return _KirokuProvider.KirokuProvider;
  }
});
exports.authentication = exports.auth2fa = void 0;
Object.defineProperty(exports, "createClient", {
  enumerable: true,
  get: function get() {
    return _createClient.default;
  }
});
exports.resources = void 0;
Object.defineProperty(exports, "useClient", {
  enumerable: true,
  get: function get() {
    return _useClient.default;
  }
});
Object.defineProperty(exports, "withKirokuClient", {
  enumerable: true,
  get: function get() {
    return _KirokuProvider.withKirokuClient;
  }
});
var _createClient = _interopRequireDefault(require("./createClient.js"));
var _KirokuProvider = require("./KirokuProvider.js");
var _useClient = _interopRequireDefault(require("./useClient.js"));
var resources = _interopRequireWildcard(require("./resources.js"));
exports.resources = resources;
var authentication = _interopRequireWildcard(require("./authentication.js"));
exports.authentication = authentication;
var auth2fa = _interopRequireWildcard(require("./auth2fa"));
exports.auth2fa = auth2fa;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
import React                   from 'react';
import Chart                   from 'react-google-charts';
import { useTranslation }      from 'react-i18next';
import useMultiChoiceChart     from './useMultiChoiceChart';
import NoAnswersFoundComponent from '../NoAnswersFoundComponent';

export default function MultiChoiceChart({records, question, handleSelectAnswer}) {

    const {t} = useTranslation();
    const { data, isNotDataAnalysis } = useMultiChoiceChart(records, question);

    const options = {
        vAxis : {
            title: t('createRecord.Choices'),
        },
        hAxis : {
            type  : 'number',
            format: '#',
        },
        legend: { position: 'none' },
        tooltip: { trigger: 'selection' }
    };

    if (isNotDataAnalysis) {
        return <NoAnswersFoundComponent/>
    }

    const chartEvents = [
        {
            eventName: "select",
            callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                if (selection.length === 1) {
                    const [selectedItem] = selection;
                    const { row } = selectedItem;
                    handleSelectAnswer({type: question.type, content : question.content, answer : data[row+1][0]});
                }
            }
        }
    ]

    return (
        <Chart
            chartType="BarChart"
            width="100%"
            height="500px"
            data={data}
            options={options}
            chartEvents={chartEvents}
        />
    );
}

import React, { useState }                 from 'react';
import useHeadingQuestion                  from '../app/Records/useSurvey/useHeadingQuestion';
import { useScrollContext }                from './Scrolling';
import { SortableElement, sortableHandle } from 'react-sortable-hoc';

export function KirokuItemNavigate({ active, isDisabled, children, onClick, hasAnswer, requiredAnswer, isMessageQuestion, isMobile }) {
    return (
        <li
            className={`cursor-pointer list-group-item ${isMobile ? 'padding-item-question': ''}
            ${isDisabled ? 'list-group-item-secondary' : active ? 'list-group-item-primary' :  (hasAnswer && !isMessageQuestion ? 'bg-has-answer' 
                : (requiredAnswer && !isMessageQuestion ? 'bg-question-required-answer' : ''))}`}
            onClick={onClick}
        >
            <div className={'d-flex justify-content-between align-items-center'}>
                <div className={`text-overflow-elip 
                ${isDisabled ? 'list-group-item-secondary' 
                    : (hasAnswer && !isMessageQuestion ? 'text-has-answer' 
                        : (requiredAnswer && !isMessageQuestion ? 'text-question-required-answer' : 'main-content-info'))}`}
                     style={{ width: '80%' }}>
                    {children}
                </div>
                <div className={`${ isMobile || isDisabled || !hasAnswer  ? 'd-none' : ' '}`} >
                    <span
                        style={ {
                            color   : 'green',
                            cursor  : 'pointer',
                            position: 'relative',
                            margin  : 'auto 0',
                        } } className='ml-2'
                    >
                        <i className="fas fa-lg fa-check-circle"/>
                    </span>
                </div>
            </div>
        </li>
    );
}


export function WrapContentQuestion({ question, isActive, onClick }) {
    const { contentOrHeading } = useHeadingQuestion(question);
    return (
        <KirokuItemNavigate
            onClick={onClick}
            active={isActive}
        >
            {contentOrHeading}
        </KirokuItemNavigate>
    );
}

export function NavigateQuestion({ questions, anchor }) {
    const [activeQuestion, setActiveQuestion] = useState(null);
    const { scrollTo }                        = useScrollContext();

    return <div className={'scroll-navigator-question bg-white'}>
        <ul className="list-group">
            {questions.map((question, index) => {
                return <WrapContentQuestion
                    key={index}
                    onClick={() => {
                        setActiveQuestion(question.index);
                        scrollTo(`${anchor}${question.index}`, 202);
                    }}
                    question={question}
                    isActive={activeQuestion === question.index}
                />;
            })}
        </ul>
    </div>;
}


const SortableHandlePoint = sortableHandle(() => <span><img src={`/assets/img/move.png`} alt=""/></span>);

function ItemQuestionNavBar({ question, onClick, isActive }) {
    const { content } = useHeadingQuestion(question);

    return <li
        className={`cursor-pointer list-group-item ${isActive ? 'list-group-item-primary' : ''}`}
        onClick={onClick}
        style={{
            zIndex     : 995,
            paddingLeft: 6,
        }}
    >
        <div className="d-flex justify-content-between">
            <SortableHandlePoint/>
            <div className={'text-overflow-elip main-content-info'} style={{ width: '80%' }}>
                {content}
            </div>
        </div>

    </li>;
}


export function NavigatorQuestionSortable({ questions, anchor }) {
    const { scrollTo }                        = useScrollContext();
    const [activeQuestion, setActiveQuestion] = useState(null);

    return (
        <ul className="list-group">
            {questions.map((question, index) => {
                const SortableElementElement = SortableElement(ItemQuestionNavBar);
                return <SortableElementElement
                    key={index}
                    index={index}
                    question={question}
                    onClick={() => {
                        setActiveQuestion(question.index);
                        scrollTo(`${anchor}${question.index}`, 202);
                    }}
                    isActive={activeQuestion === question.index}
                />;
            })}
        </ul>
    );
}

import React, { useState }       from 'react';
import { useTranslation }        from 'react-i18next';
import KirokuInputDate           from './KirokuInputDate';
import moment                    from 'moment';

function getTime(dateTime, hasTime, t) {
    return <span className='question-number font-size-16'>{moment.unix(dateTime).format(hasTime ? t('dateTimeFormat.YearMonthDateWithHour') : t('dateTimeFormat.YearMonthDateWithDay'))}</span>;
}


export default function ServicedAt(props) {

    const { t } = useTranslation();

    const {
              handleChangedServicedAt,
              isNotEditServiced,
              defaultServiced,
          }                      = props;
    const convertDefaultServiced = {
        serviced_at: defaultServiced.serviced_at ? defaultServiced.serviced_at : moment().unix(),
        has_time   : !!defaultServiced.has_time,
    };

    const [servicedAt, setServicedAt] = useState(convertDefaultServiced);

    const [isChangeTime, setIsChangeTime] = useState(false);

    const handleOnChangeDateTime = () => {
        setIsChangeTime(!isChangeTime);
    };

    const handleSwitchHasTime = () => {
        setServicedAt({
            ...servicedAt,
            has_time: !servicedAt.has_time,
        });
    };

    const handleCancelChangeServiced = () => {
        setServicedAt(convertDefaultServiced);
        handleOnChangeDateTime();
    };

    const handleConfirmChangeServiced = () => {
        handleChangedServicedAt(servicedAt);
        handleOnChangeDateTime();
    };

    const handleChangeDateTime = (datetime) => {
        setServicedAt({
            ...servicedAt,
            serviced_at: moment(datetime).unix(),
        });
    };


    return (
        <div>
            <div className='content-name-question d-flex' style={{ flexWrap: 'wrap' }}>

                <div style={{ position: 'relative', margin: 'auto 0' }}
                     className='title-record-info font-size-16'>{t('createRecord.ServicedAt')}:&nbsp;</div>
                <div hidden={isChangeTime}>
                    {getTime(servicedAt.serviced_at, servicedAt.has_time, t)}
                    <span
                        hidden={isNotEditServiced}
                        onClick={() => handleOnChangeDateTime()}
                        style={{
                            color   : 'blue',
                            cursor  : 'pointer',
                            position: 'relative',
                            margin  : 'auto 0',
                        }} className='ml-2'>
                                <i className='fa fa-pencil'/>
                        &nbsp;{t('createRecord.Edit')}
                         </span>
                </div>
                <div hidden={!isChangeTime}>
                    <div className='d-flex' style={{ flexWrap: 'no-wrap' }}>
                        <KirokuInputDate
                            className='serviced-at'
                            timeFormat={servicedAt.has_time ? t('dateTimeFormat.HourMinute') : false}
                            value={moment.unix(servicedAt.serviced_at)}
                            dateFormat={t('dateTimeFormat.YearMonthDate')}
                            onChange={handleChangeDateTime}
                            locale={t('createRecord.Date')}
                            inputProps={{ readOnly: true }}
                        />
                        <span
                            onClick={() => handleConfirmChangeServiced()}
                            style={{
                                color   : 'green',
                                cursor  : 'pointer',
                                position: 'relative',
                                margin  : 'auto 0',
                            }} className='ml-2'>
                                    <i className="fas fa-2x fa-check-circle"/>
                                </span>
                        <span
                            onClick={() => handleCancelChangeServiced()}
                            style={{
                                color   : 'red',
                                cursor  : 'pointer',
                                position: 'relative',
                                margin  : 'auto 0',
                            }} className='ml-2'>
                                <i className="fas fa-2x fa-times-circle"/>
                                </span>
                        <span className='ml-2'
                              onClick={() => handleSwitchHasTime()}
                              style={{
                                  color   : 'blue',
                                  cursor  : 'pointer',
                                  position: 'relative',
                                  margin  : 'auto 0',
                              }}

                        >
                                 {servicedAt.has_time ? t('createRecord.RemoveTime') : t('createRecord.AddTime')}
                            </span>
                    </div>
                </div>
            </div>
        </div>

    );
}

import React, { useReducer } from 'react';
import { SearchContext }     from './SearchContext';
import AnalysisReducer       from './SearchReducer';
import moment                from 'moment';

export default function SearchProvider({children, customer, survey = {id: null, title: ''}, dateRange}) {
    const initState = {
        customers: customer.id ? [customer] : [],
        survey,
        startDate: dateRange.startDate ? moment.unix(dateRange.startDate) : moment().add(-1, 'month'),
        endDate: dateRange.endDate ? moment.unix(dateRange.endDate) : moment(),
        updaters: [],
        creators: []
    };

    const [analysisState, dispatch] = useReducer(AnalysisReducer, initState, initState => initState);
    return <SearchContext.Provider value={{analysisState, dispatch}}>
        {children}
    </SearchContext.Provider>
}

import React                             from 'react';
import StatusCondition                   from './StatusCondition';
import CustomerSelectedCondition         from './CustomerSelectedCondition';
import ServicedAtCondition               from './ServicedAtCondition';
import ReadUnReadCondition               from './ReadUnReadCondition';
import UpdatedAtCondition                from './UpdatedAtCondition';
import UpdaterCondition                  from './UpdaterCondition';
import KeywordsSearchCondition           from './KeywordSearchCondition';
import CustomerSelectConditionMobile     from './CustomerSelectConditionMobile';
import { ComponentWeb, ComponentMobile } from '../../../../ui';

export default function ConditionRecords() {
    return (
        <div>

            <ComponentWeb>
                <div className={ 'row' }>
                    <div className='col-md-6 pt-3'>
                        <StatusCondition/>
                    </div>
                    <div className="col-md-6">
                        <CustomerSelectedCondition/>
                    </div>
                </div>
            </ComponentWeb>

            <ComponentMobile>
                <div className={ 'row m-0' }>
                    <div className='col-md-6 pt-3 pl-0 pr-0'>
                        <StatusCondition/>
                    </div>
                    <div className="col-md-6 pl-0 pr-0">
                        <CustomerSelectConditionMobile/>
                    </div>
                </div>
            </ComponentMobile>

            <div className={ 'pt-2 d-none d-lg-block d-xl-block' }>
                <div className={ 'row' }>
                    <div className='col-lg-7 col-xl-6'>
                        <ServicedAtCondition startDateId={ 'startDate_serviced_at_lg_screen' }
                                             endDateId={ 'endDate_serviced_at_lg_screen' }/>
                        <UpdatedAtCondition/>
                    </div>
                    <div className='col-lg-5 col-xl-6' style={ { paddingTop: 8 } }>
                        <ReadUnReadCondition/>
                        <UpdaterCondition/>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className='col-lg-7 col-xl-6'>
                        <KeywordsSearchCondition id={ 'inputSearchRecordsBeta-1' }/>
                    </div>
                </div>
            </div>
            {/*for-md-screen*/ }
            <div className=" pt-2  d-none d-md-block d-lg-none">
                <div className="row">
                    <div className="col-md-6">
                        <ServicedAtCondition startDateId={ 'startDateServicedAtMdScreen' }
                                             endDateId={ 'endDateServicedAtMdScreen' }/>
                    </div>
                    <div className="col-md-6">
                        <UpdatedAtCondition startDateId={ 'startDateUpdatedAtStartDateId' }
                                            endDateId={ 'startDateUpdatedAtEndDateId' }/>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-md-6 pt-2">
                        <ReadUnReadCondition/>
                    </div>
                    <div className="col-md-6">
                        <UpdaterCondition/>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col md-6">
                        <KeywordsSearchCondition id={ 'inputSearchRecordsBeta-2' }/>
                    </div>
                    <div className="col-md-6"/>
                </div>
            </div>
            <ComponentMobile>
                <div className="pt-3">
                    <ReadUnReadCondition/>
                </div>
                <div className="pt-2">
                    <ServicedAtCondition
                        startDateId={ 'servicedStartDateIdMobile' }
                        endDateId={ 'servicedEndDateIdMobile' }/>
                </div>
                <div className="pt-2">
                    <KeywordsSearchCondition id={ 'inputSearchRecordsBeta-mobile' }/>
                </div>
            </ComponentMobile>

        </div>
    );
}

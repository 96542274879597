import React, { useEffect, useState }   from 'react';
import { resources }                    from '@kiroku/api';
import { KirokuLoader, SearchNotFound } from '../../../ui';
import If                               from '../../If';
import CustomerForm                     from '../CustomerForm/CustomerForm';
import CustomerProvider                 from '../MakeCustomer/CustomerProvider';
import { useHistory }                   from 'react-router-dom';
import CustomerFactory                  from '../MakeCustomer/CustomerFactory';
import useNotification                  from '../../Layout/useNotification';
import { useTranslation }               from 'react-i18next';
import { useMixpanelTracking }          from '../../../Mixpanel/useMixpanelTracking';

export default function CustomerEditor(props) {

    const [customer, loadDetail, isLoading] = resources.useDetail('Customer');
    const [resultCustomer, update]          = resources.useUpdate('Customer');
    const history                           = useHistory();
    const { t }                             = useTranslation();
    const { notification }                  = useNotification();
    const { trackingMixpanel }              = useMixpanelTracking();
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        loadDetail(props.match.params.id).catch(error => console.log(error));
    }, [loadDetail, props.match.params.id]);

    useEffect(() => {
        if (resultCustomer) {
            notification(t('message.UpdateSuccess'), 'success');
            history.push('/customers/'.concat(resultCustomer.id));
        }
    }, [resultCustomer, history, notification, t]);

    const factory = new CustomerFactory();
    return (
        <KirokuLoader loading={isLoading}>
            <If statement={customer} otherwise={<SearchNotFound message={t('common.CustomerInvalid')} description={' '}/>}>
                {() => (
                    <CustomerProvider customer={factory.makeFormRequest(customer)}>
                        <CustomerForm
                            disabledButton={disabledButton}
                            submit={(customer) => {
                                setDisabledButton(true);
                                const customerRequestData = factory.make(customer);
                                delete customerRequestData.surveys;
                                update(customer.id, customerRequestData).then(() => {
                                    setDisabledButton(false);
                                });
                                trackingMixpanel('CUSTOMER_UPDATED', { ...customerRequestData, id:customer.id });

                            }}
                        />
                    </CustomerProvider>
                )}
            </If>
        </KirokuLoader>
    );
}

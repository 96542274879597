import React, { useEffect }                           from 'react';
import { resources }                                  from '@kiroku/api';
import useReportCondition                             from '../makeReport/useReportCondition';
import momentTZ                                       from 'moment-timezone';
import { KirokuButton, KirokuLoader, SearchNotFound } from '../../../ui';
import If                                             from '../../If';
import '../report.css';
import { removeConditionsUndefined }                  from '../../Converter';
import { checkUseHeadingQuestion }                    from './index';
import { useTranslation }                             from 'react-i18next';

const renderQuestion = (question) => {
    switch (question.type) {
        case 'Media':
            return (
                <div className="">
                    <img className="report-tab pb-10px" src={ question.answers } alt=""/>
                </div>
            );
        case 'YesNo':
        case 'Date':
            return (<div className="answer-content pb-10px">{ question.answers }</div>);
        default:
            return (<div className="answer-content pb-10px">{ question.answers }</div>);
    }
};

const Template = ({ record }) => (<div className="report-tab container">
    <div className="wrap-content">
        <div className="A4">
            <div className="content">
                { record.templates.map((template, index) => {
                    return (
                        <div className="wrap-bg" key={ index }>
                            { template['groupCustomer'].map((customer, index) => {
                                return (
                                    <div style={ { paddingBottom: 24 } } key={ index }>
                                        <div>
                                            <div>
                                                <label className="report-tab">
                                                    { template['translate_title'] }
                                                </label>
                                                <div className="border-title-template pb-10px">
                                                    { template.title }
                                                </div>
                                            </div>
                                            <div className="pt-10px">
                                                <label className="report-tab">
                                                    { customer['translate_customerName'] }
                                                </label>
                                                <div className="border-customer-name">
                                                    { customer.customerName }
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            { customer.records.map((record, index) => {
                                                return (
                                                    <div key={ index }
                                                         className={ 'pt-4 border-bottom-inline' }>
                                                        <div
                                                            className="row fix-margin-row border-bottom-dashed">
                                                            <div
                                                                className="col-3 fix-padding-col pb-10px">
                                                                <label className="report-tab">
                                                                    { record['translate_serviced'] }
                                                                </label>
                                                                <div>
                                                                    { record['serviced_date_time'] }
                                                                </div>
                                                            </div>
                                                            <div className={ 'col-9 pb-10px' }>
                                                                <label className="report-tab">
                                                                    { record['translate_created_by'] }
                                                                </label>
                                                                <div>
                                                                    { record['created_by'] }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="answers-list">
                                                            { record.questions.map((question, index) => {
                                                                return (
                                                                    <div
                                                                        className="flex-column justify-content-between pt-2 answer-item"
                                                                        key={ index }>
                                                                        <div className={
                                                                            `${ index === record.questions.length - 1 ?
                                                                                '' : 'border-bottom-dashed' } 
                                                                                                row fix-margin-row `
                                                                        }
                                                                        >
                                                                            <label
                                                                                className="report-tab col-3 pb-10px fix-padding-col">
                                                                                { checkUseHeadingQuestion(question) }
                                                                            </label>
                                                                            <div
                                                                                className={ 'col-9' }>
                                                                                { renderQuestion(question) }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }) }
                                                        </div>
                                                    </div>
                                                );
                                            }) }
                                        </div>
                                    </div>
                                );
                            }) }
                        </div>
                    );
                }) }
            </div>
        </div>
    </div>
</div>);

export default function TemplateRecord() {

    const { search, result, loading } = resources.useSearch('Report');
    const { condition, print }        = useReportCondition();
    const { t }                       = useTranslation();

    useEffect(() => {
        if (condition) {
            removeConditionsUndefined(condition);
            search({
                type    : 'report',
                template: 'TemplateRecord',
                lang    : localStorage.getItem('i18nextLng') || 'ja',
                timezone: momentTZ.tz.guess(),
                ...condition,
            });
        }
    }, [search, condition]);

    return (
        <KirokuLoader loading={ loading }>
            <If statement={ result.templates && result.templates.length } otherwise={ <SearchNotFound
                message={t(`Export.showResultSearch`)}
                description={t(`Export.TrySearch`)}
            /> }>
                { () => (
                    <div>
                        <div className="d-flex pt-2 justify-content-end pr-10">
                            <div className="p-2"/>
                            <KirokuButton color={ 'primary' } onClick={ () => print('TemplateRecord') }>
                                { t('common.print') }
                            </KirokuButton>
                        </div>
                        <Template record={ result }/>
                    </div>
                ) }
            </If>
        </KirokuLoader>
    );
}

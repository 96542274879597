import React                                                                      from 'react';
import { Action, ComponentMobile, KirokuCard, KirokuCardList, KirokuPopoverItem } from '../../../ui';
import moment                                                                     from 'moment';
import { useTranslation }                                                         from 'react-i18next';
import { Link }                                                                   from 'react-router-dom';

export default function PlanTabMobile({result}) {
    const { t } = useTranslation();
    const now = moment().unix();
    return (<ComponentMobile>
        <KirokuCardList headerLeft={<span className={'font-size-14'}>{t('common.Name')}</span>}>
            {result.map((plan, index) => {
                    return <div key={index}>
                        <KirokuCard key={index}
                                    action={
                                        <Action>
                                            <KirokuPopoverItem style={{width: 120}}>
                                                <div className='p-1 fix-icon-align'>
                                                    <Link to={ `/plans/${ plan.id }` }>
                                                        <span className='ml-1 font-size-14'>
                                                            {t('plans.viewPlan')}
                                                        </span>
                                                    </Link>
                                                </div>
                                            </KirokuPopoverItem>
                                        </Action> }
                        >
                            <div className={'planning-list-text font-size-14'}>
                                {(plan[ 'customer' ] && plan.customer_id) ? <Link to={`/customers/${plan.customer_id}`}>
                                    {plan[ 'customer' ].name}
                                </Link> : ''}
                            </div>
                            <div className={'planning-list-text pt-3 font-size-14'}> {t('plans.startDate')}:
                                { !!plan[ 'start_at' ] && moment.unix(plan[ 'start_at' ]).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                            </div>
                            <div className={'planning-list-text pt-3 font-size-14'}> {t('plans.endDate')}:
                                { !!plan[ 'end_at' ] && moment.unix(plan[ 'end_at' ]).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                            </div>
                            <div className={'planning-list-text pt-3 d-flex align-items-center font-size-14'}>
                                <div>{t('plans.planStatus')}:&nbsp;&nbsp;</div>
                                <div hidden={plan.published_at || plan.review_at}>
                                    <img src={`/assets/icon/iconDraft.svg`} alt="iconDraft.svg"/>
                                    &nbsp;<span style={{ fontSize: 14 }}>{t('plans.draft')}</span>
                                </div>
                                <div hidden={!(plan.published_at && now < plan.start_at) || plan.review_at}>
                                    <img src={`/assets/icon/iconBeforeStart.svg`} alt="iconBeforeStart.svg"/>
                                    &nbsp;<span style={{ fontSize: 14 }}>{t('plans.beforeStart')}</span>
                                </div>
                                <div hidden={!(plan.published_at && now > plan.start_at && now < plan.end_at) || plan.review_at}>
                                    <img src={`/assets/icon/iconRunning.svg`} alt="iconRunning.svg"/>
                                    &nbsp;<span style={{ fontSize: 14 }}>{t('plans.running')}</span>
                                </div>
                                <div hidden={!(plan.published_at && now > plan.end_at)}>
                                    <img src={`/assets/icon/iconFinish.svg`} alt="iconFinish.svg"/>
                                    &nbsp;<span style={{ fontSize: 14 }}>{t('plans.finish')}</span>
                                </div>
                            </div>
                            <div className="planning-list-text d-flex align-items-center pt-3">
                                <div>{t('plans.statusReview')}:&nbsp;&nbsp;</div>
                                <div hidden={plan.review_status !== 0}> {t('plans.unReview')}</div>
                                <div hidden={plan.review_status !== 1}> {t('plans.draftReview')}</div>
                                <div hidden={plan.review_status !== 2}> {t('plans.monitored')}</div>
                            </div>
                        </KirokuCard>
                        <hr hidden={result.length - 1 === index} className={'margin-none'}/>
                    </div>;
                },
            )}
        </KirokuCardList>
    </ComponentMobile>);
}

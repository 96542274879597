import React, { useEffect, useState } from 'react';
import { resources }                  from '@kiroku/api';
import {
    ComponentMobile, ComponentWeb, KirokuButton, KirokuButtonLink, KirokuLoader, SearchNotFound,
}                                     from '../../../ui';
import If                             from '../../If';
import { useTranslation }             from 'react-i18next';
import lodash                         from 'lodash';
import CardRecordDetailWeb            from './CardRecordDetailWeb';
import CardRecordDetailMobile         from './CardRecordDetailMobile';
import RecordTab                      from './RecordTab';
import HistoryTab                     from './HistoryTab/HistoryTab';
import CommentsTab                    from './Comments/CommentsTab';
import usePageTitle                   from '../../../ui/v3/hooks/usePageTitle';
import { useScrollContext }           from '../../../ui/Scrolling';


export default function DetailRecord(props) {
    const { t } = useTranslation();
    const [commentsCount, setCommentsCount] = useState(0);

    const [recordIds, setRecordIds] = useState(props.history.location.state || []);
    const [tabType, setTabType]     = useState('RECORD_TAB');

    const [record, loadDetail, isLoading]            = resources.useDetail('Record');

    const indexCurrentRecord = lodash.findIndex(recordIds, (id) => {
        return parseInt(id) === parseInt(props.match.params.id);
    });
    const { scrollTo }                 = useScrollContext();


    useEffect(() => {
        try {
            scrollTo(`comment-record-id-${ localStorage.getItem('commentId') }`, 330);
        } catch (e) {

        }
        if(props.history.location.hash === '#comment-tab') {
            setTabType('COMMENT_TAB');

        }
    }, [props.history.location, scrollTo]);

    useEffect(() => {
        setRecordIds(props.history.location.state || []);
    }, [props.history.location.state]);

    useEffect(() => {
        loadDetail(props.match.params.id).catch(error => console.log(error));
    }, [props.match.params.id, loadDetail]);

    usePageTitle(t('RecordsBeta.record'));

    const pageGoBack = () => {
        props.history.push(`/records`);
    };

    const nextRecord = (id) => {
        if (id) {
            props.history.push({
                pathname: `/records/${id}`,
                state   : recordIds,
            });
        }
    };

    return (<KirokuLoader loading={isLoading}>
        <If statement={record}
            otherwise={<SearchNotFound message={t('common.RecordInvalid')} description={' '}/>}
        >
            {() => <div>
                <div className={'padding-responsive keeping-about-survey bg-main'}
                     style={{ top: 70, paddingBottom: 0 }}>
                    <div className={'pt-2'}/>
                    <div className='pt-1 pl-1 d-flex justify-content-between'>
                        <KirokuButtonLink
                            className={'font-size-14'}
                            onClick={() => {
                                pageGoBack();
                            }}
                        >
                            {t('common.goToRecordList')}
                        </KirokuButtonLink>
                        <div className=' pr-3 ml-3'
                             hidden={indexCurrentRecord === -1}>
                            <KirokuButton className={'color-btn-paginator border-btn-paginator bg-white btn-back font-size-14'}
                                          disabled={indexCurrentRecord === 0}
                                          onClick={() => {
                                              nextRecord(recordIds[ indexCurrentRecord - 1 ]);
                                          }}
                            > {t('DeletedList.back')} </KirokuButton>
                            <KirokuButton className={'color-btn-paginator border-btn-paginator bg-white btn-next font-size-14'}
                                          onClick={() => {
                                              nextRecord(recordIds[ indexCurrentRecord + 1 ]);
                                          }}
                                          disabled={indexCurrentRecord === recordIds.length - 1}
                            > {t('DeletedList.next')} </KirokuButton>
                        </div>
                    </div>
                    <div className="pt-3">
                        <div className={`card`}>
                            <div className='pt-3'>
                                <ComponentWeb>
                                    <div className="hidden-info-record-detail-tablet">
                                        <CardRecordDetailMobile record={record}/>
                                    </div>
                                    <div className="show-info-record-detail-tablet">
                                        <CardRecordDetailWeb record={record}/>
                                    </div>

                                </ComponentWeb>
                                <ComponentMobile>
                                    <CardRecordDetailMobile record={record}/>
                                </ComponentMobile>
                            </div>
                            <div
                                className='pt-4 d-flex navbar-tab-records records-detail font-text-reset'
                                style={{ borderBottom: 'solid 2px #dfe3e9', backgroundColor: '#ffffff', color: '#6E798D' }}>
                                <div
                                    className={`nav-bar cursor-pointer bg-white ${tabType === 'RECORD_TAB' ? 'active' : ''}`}
                                    onClick={() => {
                                        setTabType('RECORD_TAB');
                                    }} style={{ width: 100 }}>
                                    {t('RecordsBeta.record')}
                                </div>
                                <div
                                    className={`nav-bar cursor-pointer bg-white ${tabType === 'COMMENT_TAB' ? 'active' : ''} `}
                                    onClick={() => {
                                        setTabType('COMMENT_TAB');
                                    }} style={{ width: 100 }}>
                                    {t('RecordsBeta.comment')}
                                    <span className='badge'>{commentsCount}</span>
                                </div>
                                <div
                                    className={`nav-bar cursor-pointer bg-white ${tabType === 'HISTORY_TAB' ? 'active' : ''} `}
                                    onClick={() => {
                                        setTabType('HISTORY_TAB');
                                    }} style={{ width: 100 }}>
                                    {t('RecordsBeta.history')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="padding-responsive">
                    <RecordTab record={record} isView={tabType === 'RECORD_TAB'}/>
                    <HistoryTab record={record} isView={tabType === 'HISTORY_TAB'}/>
                    <CommentsTab
                        record={record} isView={tabType === 'COMMENT_TAB'}
                        setCommentsCount={(commentsCount) => setCommentsCount(commentsCount)}
                        history={props.history}
                    />
                    <ComponentMobile>
                        <div style={ { marginBottom: 80 } }/>
                    </ComponentMobile>
                </div>
            </div>}
        </If>
    </KirokuLoader>);
}

import { useTranslation }   from 'react-i18next';
import React, { useEffect } from 'react';
import {
    KirokuButton, KirokuModal,
    KirokuModalBody, KirokuModalFooter,
    KirokuModalHeader,
}                           from '../../../../ui';
import TemplateOptionsTab     from './TemplateOptionsTab';
import TemplateSelectedTab    from './TemplateSelectedTab';
import useSelectTemplateModal from './useSelectTemplateModal';
import { useCondition }       from '../../useCondition';
import _                      from 'lodash';

export default function TemplatesModal(props) {
    const { t } = useTranslation();

    const componentTemplate = {
        optionsTab : TemplateOptionsTab,
        selectedTab: TemplateSelectedTab,
    };

    const {
              getTemplateListOptions, loading,
              selectedTabName, setShowOpenModal,
              templates, resetInitCondition
          } = useSelectTemplateModal();

    const { updateRecordCondition, recordCondition } = useCondition();

    useEffect(() => {
        if (props.show) {
            resetInitCondition();
            getTemplateListOptions();
        }
    }, [props.show, getTemplateListOptions,resetInitCondition]);

    const ComponentTemplate = componentTemplate[selectedTabName];

    return (
        <KirokuModal show={ props.show }
                     onCloseModal={ props.closeModal }>
            <KirokuModalHeader>
                <div
                    className={ 'font-header-on-modal' }>{ t('RecordsBeta.headerSelectTemplate') }</div>
            </KirokuModalHeader>
            <KirokuModalBody className={ 'p-3' }>
                <div className="d-flex justify-content-around border-btn-status-group">
                    <div
                        className={ `border-width-each-status first-btn cursor-pointer 
                            ${ selectedTabName === 'selectedTab' ? 'btn-active' : '' }` }
                        onClick={ () => setShowOpenModal('selectedTab') }>
                        { t('RecordsBeta.displaySelection') }
                    </div>
                    <div
                        className={ `border-width-each-status third-btn cursor-pointer 
                            ${ selectedTabName === 'optionsTab' ? 'btn-active' : '' }` }
                        onClick={ () => setShowOpenModal('optionsTab') }>
                        { t('RecordsBeta.showAll') }
                    </div>
                </div>
                <ComponentTemplate
                    selectedTabName={ selectedTabName }
                    isUse={ props.show }
                    loading={ loading }
                />
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={ () => {
                    props.closeModal();
                    getTemplateListOptions();
                } }
                              color={ 'light' }
                >
                    { t('RecordsBeta.cancel') }
                </KirokuButton>
                <KirokuButton onClick={ () => {
                    let templateChecked = templates.filter(template => template.checked).map(tmp => tmp.id);
                    let isCheckedAll = _.findIndex(templates, tmp => !tmp.checked);
                    if (isCheckedAll === -1) {
                        updateRecordCondition({
                            ...recordCondition,
                            tId : 'all',
                            page: 1,
                        });
                    } else {
                        updateRecordCondition({
                            ...recordCondition,
                            tId : templateChecked,
                            page: 1,
                        });
                    }
                    props.closeModal();
                } }
                              color={ 'primary' }
                >
                    { t('RecordsBeta.confirm') }
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

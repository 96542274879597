import React                   from 'react';
import { Chart }               from 'react-google-charts';
import './inputTextChart.css';
import { useTranslation }      from 'react-i18next';
import useInputTextChart       from './useInputTextChart';
import NoAnswersFoundComponent from '../NoAnswersFoundComponent';

const options = {
    chartArea: { width: '80%' },
    bar      : { width: 40, maxWidth: 50 },
    legend   : { position: 'none' },
    tooltip: { trigger: 'selection' }
};

const data = [
    'Element',
    'Density',
    { role: 'style' },
    { role: 'tooltip', type: 'string' },
    {
        sourceColumn: 0,
        role        : 'annotation',
        type        : 'string',
        calc        : 'stringify',
    },
];

export default function InputTextChart({ records, question, handleSelectAnswer }) {

    const { t }            = useTranslation();
    const {analysisQuestion, isNotDataAnalysis} = useInputTextChart(records, question);

    if (isNotDataAnalysis) {
        return <NoAnswersFoundComponent/>
    }

    const chartEvents = [{
        eventName: "select",
        callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart()
            const selection = chart.getSelection()
            if (selection.length === 1) {
                const [selectedItem] = selection;
                const { row } = selectedItem;
                handleSelectAnswer({type: question.type, content : question.content, answer : analysisQuestion[row][3].slice(0, - 6)});
            }
        }
    }]

    return (
        <div>
            { analysisQuestion.length ? (
                <>
                    <Chart
                        chartType="ColumnChart"
                        loader={ <div>Loading Chart</div> }
                        height="400px"
                        data={ [data, ...analysisQuestion] }
                        options={ options }
                        chartEvents={chartEvents }
                    />
                    <div className="d-flex justify-content-center">
                        <div className="d-flex align-content-center">
                            <span className='legend-span legend-span-green'/>
                            <div className='margin-vertical-5'>
                                { t('Analysis.Positive') }
                            </div>
                        </div>
                        <div className="d-flex align-content-center">
                            <span className='legend-span legend-span-orange'/>
                            <div className='margin-vertical-5'>
                                { t('Analysis.Neutral') }
                            </div>
                        </div>
                        <div className="d-flex align-content-center">
                            <span className='legend-span legend-span-red'/>
                            <div className='margin-vertical-5'>
                                { t('Analysis.Negative') }
                            </div>
                        </div>
                    </div>
                </>
            ) : <div/> }

        </div>
    );
};

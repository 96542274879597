import React, { useEffect, useState }                                               from 'react';
import { KirokuButton, KirokuCardWeb, KirokuInput, KirokuInputArea, KirokuLoader } from '../../ui';
import { useTranslation }                                                           from 'react-i18next';
import { authentication, resources }                                                from '@kiroku/api';
import usePageTitle
                                                                                    from '../../ui/v3/hooks/usePageTitle';
import { useMixpanelTracking }                                                      from '../../Mixpanel/useMixpanelTracking';
import useNotification                                                              from '../Layout/useNotification';

export default function Organization() {
    const { t }                                       = useTranslation();
    const [name, setName]                             = useState('');
    const [description, setDescription]               = useState('');
    const [invalidName, setInvalidName]               = useState(false);
    const [invalidDescription, setInvalidDescription] = useState(false);
    const profile                                     = authentication.useAuthenticatedContext().user;
    const [result, loadDetail, loading]               = resources.useDetail('Organization');
    const update                                      = resources.useUpdate('Organization')[ 1 ];
    const { trackingMixpanel }                        = useMixpanelTracking();
    const { notification }                            = useNotification();

    usePageTitle(t(`Layout.Organization`));

    useEffect(() => {
        loadDetail(profile.organization_id);
    }, [loadDetail, profile.organization_id]);

    useEffect(() => {
        if (result) {
            setName(result.name);
            setDescription(result.description);
        }
    }, [result]);

    const handleUpdate = () => {
        let dataOrg = {
            ...result,
            name,
            description,
        };
        update(profile.organization_id, dataOrg).then(() => {
            notification(t('message.UpdateSuccess'), 'success');
            trackingMixpanel('UPDATE_ORGANIZATION', dataOrg);
        });
    };

    return (
        <div className='default-background-color'>
            <div className='padding-responsive'>
                <KirokuLoader loading={loading}>
                    <KirokuCardWeb>
                        <div className='p-3'>
                            <h5 className={'card-title-info'}>{t('Organization.OrganizationDetail')}</h5>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label className='mt-2 mb-10'>{t('Organization.OrganizationName')}</label>
                                    <KirokuInput onChange={(e) => setName(e.target.value)}
                                                 name={'name'}
                                                 value={name}
                                                 onBlur={() => setInvalidName(!name)}
                                                 disabled={profile.role !== 'admin'}
                                                 error={invalidName ? 'form-error' : ''}
                                    />
                                    <p hidden={!invalidName} style={{
                                        color   : 'red',
                                        fontSize: 12,
                                    }}>{t('errors.nameNotNull')}</p>
                                    <label className='pt-15px mb-10'>{t('Organization.OrganizationDescription')}</label>
                                    <KirokuInputArea onChange={e => setDescription(e.target.value)}
                                                     onBlur={() => setInvalidDescription(!description)}
                                                     name={'description'}
                                                     disabled={profile.role !== 'admin'}
                                                     className={'bg-white'}
                                                     value={description}
                                    />
                                    <p hidden={!invalidDescription} style={{
                                        color   : 'red',
                                        fontSize: 12,
                                    }}>{t('errors.descriptionNotNull')}</p>
                                    {profile.role !== 'admin' ? '' :
                                        <div className="mt-3">
                                            <KirokuButton
                                                disabled={invalidName || invalidDescription }
                                                onClick={handleUpdate}
                                                color='primary'>{t('common.Update')}</KirokuButton>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </KirokuCardWeb>
                </KirokuLoader>
            </div>
        </div>
    );
}

import {Component}        from "react";
import React              from "react";
import KirokuDropdownItem from "./KirokuDropdownItem";

export default class KirokuDropdownResetTitle extends Component {

    state = {
        selected: {
            label: ''
        },
        value: '',
        popupVisible: false
    };

    handleSelected(event) {
        this.props.onSelect(event);
        this.setState({
            popupVisible: false,
            selected: event.kirokuSelected,
            value: event.kirokuSelected.value
        });
    }

    constructor() {
        super();

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick() {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }
    UNSAFE_componentWillMount() {
        if(this.props.title) {
            const newSelected = {...this.state.selected, label: this.props.title};
            this.setState({
                selected: newSelected,
                value: this.props.title
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.title !== this.state.selected.label && nextProps.title !== this.state.value) {
            const newSelected = {...this.state.selected, label: nextProps.title};
            this.setState({
                selected: newSelected,
                value: nextProps.title
            });
        }
    }
    render() {
        const {location} = this.props;

        return (
            <div ref={node => {
                this.node = node
            }}>
                <div className={`dropdown ${this.props.className ? this.props.className : ''}`}>
                    <button hidden={this.props.hidden}
                            disabled={this.props.disabled}
                            style={this.props.style}
                            className={`overflow btn ${this.props['classNameBtn'] ? this.props['classNameBtn'] : 'btn-kiroku'}`}
                            onClick={this.handleClick.bind(this)}>
                        <i className={`${this.props.icon} left text-kiroku pr-5px`}/>
                        <span style={this.props.selectScreen ? {color: '#202528'} : {}}
                            className={`text-kiroku left font-size-14 ${this.props.isPagination ? 'color-dropdown-select-pagination' : ''}`}>
                            {this.state.selected.label ? this.state.selected.label : this.props.defaultValue}</span>
                        { location === 'top' ?
                            <i className={`${!this.state.popupVisible ? 'fa fa-chevron-up icon-kiroku-white' : 'fa fa-chevron-down icon-kiroku-white'}`}/> :
                            <i className={`${!this.state.popupVisible ? 'fa fa-chevron-down icon-kiroku-white' : 'fa fa-chevron-up icon-kiroku-white'}`}/>
                        }

                    </button>
                    {this.state.popupVisible && (
                        <div
                            className={`dropdown-kiroku dropdown-menu show ${this.props.location ? this.props.location : ''}`}
                            style={{position: 'absolute', willChange: 'top, left', left: 'auto', top: 'auto'}}>
                            <section className='scroll-data-dropdown-search'>
                                {this.props.items.map((item, index) => {
                                    const Item = item.component;
                                    return (
                                        <KirokuDropdownItem key={index} onClick={(event) => {
                                            event.kirokuSelected = item;
                                            this.handleClick(event);
                                            this.handleSelected(event)
                                        }}>
                                            {Item}
                                        </KirokuDropdownItem>
                                    )
                                })}
                            </section>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}


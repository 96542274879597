import React, { useEffect }    from 'react';
import SurveyProvider          from '../MakeSurvey/SurveyProvider';
import Survey                  from '../Survey';
import { resources }           from '@kiroku/api';
import { useHistory }          from 'react-router-dom';
import useNotification         from '../../Layout/useNotification';
import { useTranslation }      from 'react-i18next';
import { useMixpanelTracking } from '../../../Mixpanel/useMixpanelTracking';

export default function SurveyCreator() {
    const [resultSurvey, createSurvey] = resources.useCreate('Template');
    const history                      = useHistory();
    const { notification }             = useNotification();
    const { t }                        = useTranslation();

    const { trackingMixpanel } = useMixpanelTracking();

    useEffect(() => {
        if (resultSurvey) {
            notification(t('Surveys.Created'), 'success');
            history.push(`/surveys/${resultSurvey.id}`)
        }
    }, [resultSurvey, history, notification, t]);

    return (<SurveyProvider>
        <Survey
            handleOnSubmit={(surveyDataUpdate) => {
                createSurvey(surveyDataUpdate);
                trackingMixpanel('CREATE_SURVEY', surveyDataUpdate);
            }}
        />
    </SurveyProvider>);
}

import React              from 'react';
import { useTranslation } from 'react-i18next';

function KirokuTabNoResult(props) {

    const { t } = useTranslation();
    return (
        <div className='fix-center'>
            {/*<img className='pt-2 pb-2' src="/assets/img/team-icon@2x.png" alt="no team"/>*/ }
            { props.children }
            <h5 className='mt-1'>{ props.messageFirst ? t(`NullPage.${ props.messageFirst }`) : '' }</h5>
            <h5 className='text-center'>{ props.messageSecond ? t(`NullPage.${ props.messageSecond }`) : '' }</h5>
        </div>
    );
}

export default KirokuTabNoResult;

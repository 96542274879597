"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _KirokuProvider = require("./KirokuProvider");
var _default = exports.default = function _default() {
  return (0, _react.useContext)(_KirokuProvider.KirokuClientContext);
};
import React, { useContext } from 'react';

export const ScrollingContext = React.createContext();

export function useScrollContext() {
    return useContext(ScrollingContext);
}

export class ScrollProvider extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <ScrollingContext.Provider {...this.props} value={{
                registerAnchor: (name, element) => {
                    this.setState({
                        [name]: element
                    })
                },

                unRegisterAnchor: (name) => {
                    this.setState({
                        name: undefined
                    })
                },

                scrollTo: (name, top) => this.props.smoothScroll(this.state[name], top)
            }} />
        )
    }
}

export class ScrollingAnchor extends React.PureComponent {

    static get contextType() {
        return ScrollingContext;
    }

    componentDidMount() {
        this.context.registerAnchor(this.props.anchor, this.element)
    }

    componentWillUnmount() {
        this.context.unRegisterAnchor(this.props.anchor);
    }

    render() {
        return (
            <div ref={ node => this.element = node }>
                {this.props.children}
            </div>
        )
    }
}

import React              from 'react';
import { useTranslation } from 'react-i18next';
import KirokuButton       from './KirokuButton';

function NoResultWithButtonLink(props) {
    const { t }                           = useTranslation();
    const { message, description, title } = props;
    return (
        <div className='pt-3 pb-5 text-center' style={ { marginTop: 50 } }>
            <img src={ `/assets/img/not-template.svg` } alt="No template"/>
            <h5 style={ { color: '#6c798f' } } className={ 'pt-3' }>
                <div>{ message || t('plans.cannotConnectSurvey') }</div>
            </h5>
            <p> { description || '' }</p>
            <div>
                <KirokuButton { ...props }>{ title || '' }</KirokuButton>
            </div>
        </div>
    );
}

export default NoResultWithButtonLink;

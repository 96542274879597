import React                                  from 'react';
import useSelectTemplateModal                 from './useSelectTemplateModal';
import { KirokuCheckboxBorder, KirokuLoader } from '../../../../ui';
import { KirokuInput }                        from '../../../../../src/ui';
import _                                      from 'lodash';
import { useTranslation }                     from 'react-i18next';

export default function TemplateOptionsTab({ loading }) {
    const { t } = useTranslation();
    const {
              templateShowInModal,
              handleChangeKeyword, handleChecked,
              handleCheckAllTemplates,
              keywords,
          }     = useSelectTemplateModal();

    return (
        <div>
            <div className={ 'pt-4' } style={ { paddingBottom: 30 } }>
                <KirokuInput
                    style={ { height: 36, fontSize: 14 } }
                    value={ keywords }
                    onChange={ (e) => handleChangeKeyword(e.target.value) }
                    placeholder={ t('RecordsBeta.keyword') }
                />
            </div>
            <div style={ { minHeight: 'calc(100vh - 390px' } }>
                <KirokuLoader loading={ loading }>
                    { templateShowInModal.length ?
                        <div className='background-color'>
                            <div className={'p-3'} style={ { border: 'solid 1px #dfe2e5' } }>
                                <div className={'d-flex align-items-center'}>
                                    <label className="check-group">
                                        <input type="checkbox" checked={  _.findIndex(templateShowInModal, (ctm) => (!ctm.checked)) === -1 }
                                               onChange={ handleCheckAllTemplates }/>
                                        <span className="checkmark"/>
                                    </label>
                                    <span className={`font-text-content-in-modal pl-3`}>&nbsp;</span>
                                </div>
                            </div>
                            <div style={ {
                                overflow : 'scroll',
                                height   : 'auto',
                                minHeight: 'calc(100vh - 450px',
                                maxHeight: 'calc(100vh - 450px',
                            } }>

                                { templateShowInModal.map((survey, i) => {
                                    return (
                                        <div key={ i }
                                             onClick={ () => handleChecked(i) }>
                                            <div className={ 'cursor-pointer' }>
                                                <KirokuCheckboxBorder
                                                    content={ <span className={ 'font-text-in-checkbox' } style={ { wordBreak: 'break-word' } }> { survey.title }</span> }
                                                    id={ `${ survey.id }-${ i }` }
                                                    checked={ !!survey.checked }
                                                    onChange={ () => handleChecked(i) }
                                                />
                                            </div>
                                        </div>
                                    );
                                }) }
                            </div>
                        </div> : <div className='background-color d-flex'
                                      style={ {
                                          fontSize: '16px',
                                          height: 'auto',
                                          minHeight: 'calc(100vh - 400px)'
                                      } }>
                            <div style={ { margin: 'auto' } }>
                                { t('RecordsBeta.noSurveys') }
                            </div>
                        </div>
                    }
                </KirokuLoader>
            </div>
        </div>
    );
}

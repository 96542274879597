import React                        from 'react';
import { KirokuDropdownResetTitle } from '../../../ui';
import { useTranslation }           from 'react-i18next';
import useDropDownData              from '../../useDropDownData';

export default function DropdownSelectStatus({ onSelect, defaultStatus }) {
    const { t }                                 = useTranslation();
    const { dataTargetStatus, statusIcon } = useDropDownData();

    return (
        <KirokuDropdownResetTitle
            title={ defaultStatus ? <div className={ 'd-flex font-size-14' }>  { t(`status.${ defaultStatus }`) } <img
                src={ `/assets/img/${ statusIcon[defaultStatus] }` } className={ 'pl-2' }
                alt={ statusIcon[defaultStatus] }/></div> : '' }
            onSelect={ (event) => {
                onSelect(event.kirokuSelected.value);
            } }
            items={ dataTargetStatus }
        />
    );
}

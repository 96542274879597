import { DeepDiff } from 'deep-diff';
import _            from 'lodash';
import moment       from 'moment';

const diff = DeepDiff.diff;

const isChangeServicedAt = (unixBefore, unixAfter ) => {
    let year = moment.unix(unixBefore.servicedAt).year() !== moment.unix(unixAfter.servicedAt).year();
    let month = moment.unix(unixBefore.servicedAt).month() !== moment.unix(unixAfter.servicedAt).month();
    let day = moment.unix(unixBefore.servicedAt).date() !== moment.unix(unixAfter.servicedAt).date();
    let hh = moment.unix(unixBefore.servicedAt).hour() !== moment.unix(unixAfter.servicedAt).hour();
    let mm = moment.unix(unixBefore.servicedAt).minute() !== moment.unix(unixAfter.servicedAt).minute();
    let has_time = !!unixBefore.has_time !== !!unixAfter.has_time;
    return year || month || day || hh || mm || has_time;
};

const getStatusRecord = (recordBefore, recordAfter) => {
    const statusRecordBefore =  recordBefore['has_draft'] === null ? 'recorded' : 'draft';
    const statusRecordAfter =  recordAfter['has_draft'] === null ? 'recorded' : 'draft';
    return {
        statusRecordBefore,
        statusRecordAfter
    };
};

export default function useDifferenceRecordHistory(oldLog, activeLog) {
    const recordBefore = oldLog.data;
    const recordAfter = activeLog.data;
    let questionsBefore = recordBefore.survey_template.questions;
    let questionsAfter = recordAfter.survey_template.questions;

    questionsBefore = questionsBefore.map(question => {
        delete question.visibility;
        return question;
    }).filter(question => question.type !== 'Message');

    questionsAfter = questionsAfter.map(question => {
        delete question.visibility;
        return question;
    }).filter(question => question.type !== 'Message');
    let differences = diff(questionsBefore, questionsAfter);

    let isChangeServiceAt = isChangeServicedAt({
        servicedAt: recordBefore.serviced_at,
        has_time: recordAfter.has_time
    }, {
        servicedAt: recordBefore.serviced_at,
        has_time: recordAfter.has_time
    });

    return {
        differences: _.uniqBy(differences, (diff) => {
            return diff.path[0];
        }).reverse(),
        recordAfter: {
            ...recordAfter,
            survey_template: {
                ...recordAfter.survey_template,
                questions: questionsAfter
            }
        },
        recordBefore: {
            ...recordBefore,
            survey_template: {
                ...recordBefore.survey_template,
                questions: questionsBefore
            }
        },
        statusRecordChange: getStatusRecord(recordBefore, recordAfter),
        servicedAt: {
            before: {
                servicedAt: recordBefore.serviced_at,
                has_time: recordBefore.has_time,
            },
            after: {
                servicedAt: recordAfter.serviced_at,
                has_time: recordAfter.has_time
            },
            isChangeServicedAt: isChangeServiceAt
        },
    };
}

import React, { useEffect, useState }                        from "react";
import { KirokuDropdownClose, KirokuInput, KirokuInputDate } from "../../../../ui";
import { useTranslation }                                    from "react-i18next";
import useGuardianship                                       from "./useGuardianship";
import { useListGuardianship }                               from "./useListGuardianship";
import * as moment                                           from "moment";

function GuardianshipSelect() {

    const {guardianship_type, setGuardianship} = useGuardianship();
    const [ listGuardianship ] = useListGuardianship();
    const {t} = useTranslation();

    return (
        <>
            <label className="mt-1 mb-10">{t('CustomerEditor.GuardianshipType')}</label>
            <KirokuDropdownClose
                title={guardianship_type ? t(`CustomerEditor.guardianshipTypes.${guardianship_type}`) : t('CustomerEditor.guardianshipTypes.Null')}
                items={listGuardianship}
                onSelect={e => setGuardianship('guardianship_type', e.kirokuSelected.value)}
            />
        </>
    )
}

function DecisionDate() {

    const {t} = useTranslation();
    const {decision_date, setGuardianship} = useGuardianship();
    return (
        <>
            <label className='mt-1 mb-10'>{t('CustomerEditor.DecisionDate')}</label>
            <KirokuInputDate
                dateFormat={t('dateTimeFormat.YearMonthDate')}
                onChange={date => setGuardianship('decision_date', moment(date).unix())}
                name={t('CustomerEditor.DecisionDate')}
                defaultValue={decision_date ? moment.unix(decision_date) : ''}
            />
            {/*<p hidden={!errors.decisionDate} style={{
                        color: 'red',
                        fontSize: 12
                    }}>{t('CustomerEditor.validate.ValidDate')}</p>*/}
        </>
    )
}

function KirokuInputFields(props) {

    const [ input, setInput ] = useState(props.default);

    useEffect(() => {
        setInput(props.default)
    }, [ props.default ])

    return (
        <>
            <label className='mt-1 mb-10'>{props.labels}</label>
            <KirokuInput
                onChange={e => setInput(e.target.value)}
                value={input || ''}
                {...props}
            />
        </>
    )
}

export default function Guardianship() {

    const {t} = useTranslation();
    const {
        appointed_type, competent_court, case_number,
        registration_number, permanent_address, setGuardianship,
        residence_card_address, facility_name, report_month
    } = useGuardianship();

    return (
        <div>
            <h5 className={'card-title-info'}>{t('CustomerEditor.Guardianship')}</h5>
            <div className='row'>
                <div className="col-md-8">
                    <GuardianshipSelect/>
                </div>
            </div>
            <div className="row pt-15px">
                <div className='col-md-4'>
                    <DecisionDate/>
                    <div className="pt-15px"/>
                    <KirokuInputFields
                        name='appointed_type'
                        default={appointed_type}
                        labels={t('CustomerEditor.AppointedType')}
                        placeholder={t('CustomerEditor.AppointedType')}
                        onBlur={e => setGuardianship(e.target.name, e.target.value)}
                    />
                    <div className="pt-15px"/>
                    <KirokuInputFields
                        name='competent_court'
                        default={competent_court}
                        labels={t('CustomerEditor.CompetentCourt')}
                        placeholder={t('CustomerEditor.CompetentCourt')}
                        onBlur={e => setGuardianship(e.target.name, e.target.value)}
                    />
                </div>
                <div className="col-md-4">
                    <KirokuInputFields
                        name='case_number'
                        default={case_number}
                        labels={t('CustomerEditor.CaseNumber')}
                        placeholder={t('CustomerEditor.CaseNumber')}
                        onBlur={e => setGuardianship(e.target.name, e.target.value)}
                    />
                    <div className="pt-15px"/>
                    <KirokuInputFields
                        name='registration_number'
                        default={registration_number}
                        labels={t('CustomerEditor.RegistrationNumber')}
                        placeholder={t('CustomerEditor.RegistrationNumber')}
                        onBlur={e => setGuardianship(e.target.name, e.target.value)}
                    />
                    <div className="pt-15px"/>
                    <KirokuInputFields
                        name='permanent_address'
                        default={permanent_address}
                        labels={t('CustomerEditor.PermanentAddress')}
                        placeholder={t('CustomerEditor.PermanentAddress')}
                        onBlur={e => setGuardianship(e.target.name, e.target.value)}
                    />
                </div>

                <div className="col-md-4">
                    <KirokuInputFields
                        name='residence_card_address'
                        default={residence_card_address}
                        labels={t('CustomerEditor.ResidenceCardAddress')}
                        placeholder={t('CustomerEditor.ResidenceCardAddress')}
                        onBlur={e => setGuardianship(e.target.name, e.target.value)}
                    />
                    <div className="pt-15px"/>
                    <KirokuInputFields
                        name='facility_name'
                        default={facility_name}
                        labels={t('CustomerEditor.FacilityName')}
                        placeholder={t('CustomerEditor.FacilityName')}
                        onBlur={e => setGuardianship(e.target.name, e.target.value)}
                    />
                    <div className="pt-15px"/>
                    <KirokuInputFields
                        name='report_month'
                        type='number'
                        min='1'
                        max='12'
                        default={report_month}
                        labels={t('CustomerEditor.ReportMonth')}
                        placeholder={t('CustomerEditor.ReportMonth')}
                        onBlur={e => setGuardianship(e.target.name, e.target.value)}
                    />
                </div>
            </div>
        </div>
    )
}

import KirokuInputIcon                        from './KirokuInputIcon';
import React, { useEffect, useRef, useState } from 'react';
import moment                                 from 'moment';
import { useTranslation }                     from 'react-i18next';

export default function KirokuInputTime({name, value, onChange, disabled = false, readOnly = true}) {
    const { t }                       = useTranslation();
    const [clock, setClock]           = useState('');
    const inputRef                    = useRef(null);

    useEffect(() => {
        if(value) {
            setClock(moment.unix(value).format('HH:mm'))
        } else {
            setClock('')
        }
    }, [value]);

    const getTimeFormat = (time) => {
        let momentTime = moment().startOf('day').unix();
        let timeToFormat = moment.unix(momentTime).format('YYYY-MM-DD') + ' ' + time;
        return moment(timeToFormat).format(t('dateTimeFormat.HourMinute'))
    }

    return(
        <div>
            <div style={{ position: "absolute", zIndex: -999}}>
                <input type={'time'} style={{ border: 'none'}} onChange={ e => {
                    onChange(moment(`${e.target.value}`, 'HH:mm').unix());
                }} value={ clock } ref={ inputRef }/>
            </div>
            <KirokuInputIcon name={name}
                             readOnly={readOnly}
                             icon={'far fa-clock'}
                             value={ clock ? getTimeFormat(clock) : ''}
                             onFocus={ () => {
                                 inputRef.current.focus();
                             }}
                             onClick={ () => {
                                 inputRef.current.click();
                             }}
                             disabled={disabled}

            />
        </div>
    )
}

import React                                                     from 'react';
import { ComponentMobile, KirokuButtonLink, KirokuCardTitleWeb } from '../../../../ui';
import { useTranslation }                                        from 'react-i18next';
import { useHeadingQuestion }                   from '../../useSurvey';
import ComponentWeb                             from '../../../../ui/ComponentWeb';

export default function QuestionCardTitle({ question, handleResetAnswer, isDisabled, hasAnswer }) {

    const { t }                        = useTranslation();
    const { optionalContentOrHeading } = useHeadingQuestion(question);

    return (
        <div>
            <ComponentWeb>
                <KirokuCardTitleWeb
                    className={`card-title card-title-web d-flex justify-content-between align-items-center p-2 
                            ${isDisabled ? 'bg-disable-question' : 'bg-card-web'}
                        `}
                >
                    <div className='question-number text-overflow-elip'>
                        {optionalContentOrHeading}
                    </div>
                    <div/>
                    <div hidden={isDisabled || question.type === 'Message'}>
                        <KirokuButtonLink
                            style={{ height: 36}}
                            onClick={handleResetAnswer}
                        >
                            {t('createRecord.resetAnswer')}
                        </KirokuButtonLink>
                    </div>
                </KirokuCardTitleWeb>
            </ComponentWeb>
            <ComponentMobile>
                <KirokuCardTitleWeb
                    className={`card-title card-title-web d-flex justify-content-between align-items-center p-2
                            ${isDisabled ? 'bg-disable-question' 
                        : ( hasAnswer ? 'bg-has-answer' 
                            : (!question.unrequited && question.type !== 'Message' ? 'bg-question-required-answer': 'bg-card-web'))}                     
                       `}
                >
                    <div className={`question-number text-overflow-elip 
                    ${ isDisabled ? 'color-disabled-question-header' 
                        : (hasAnswer ? 'text-has-answer' 
                            : (!question.unrequited && question.type !== 'Message' ? 'text-question-required-answer': ''))}`}>
                        {optionalContentOrHeading}
                    </div>
                    <div/>
                    <div hidden={isDisabled || question.type === 'Message'}>
                        <KirokuButtonLink
                            style={{ height: 36}}
                            onClick={handleResetAnswer}
                        >
                            {t('createRecord.resetAnswer')}
                        </KirokuButtonLink>
                    </div>
                </KirokuCardTitleWeb>
            </ComponentMobile>
        </div>
    );
}

import { renderRecordsAfterSearch } from './DataCalendarChartHead';
import { groupRecordByDay }         from '../../AnalyticalSystem/GroupRecordByDate';
import { useCallback }              from 'react';

export function useHeadMapRecordChart(records, startDate, endDate) {

    const handleOnClick = useCallback((records) => {

    }, []);
    return {
        dataAnalysis: renderRecordsAfterSearch(groupRecordByDay(records), startDate, endDate),
        handleOnClick
    }
}

import React                       from 'react';
import SideBar                     from './SideBar';
import Header                      from './Header';
import useActionSideBar            from './useActionSideBar';
import { Redirect, Route, Switch } from 'react-router-dom';
import screens                     from '../Router/screens';
import { KirokuNotification }      from '../../ui';

export default (props) => {
    const [isOpenMenuSideBar, changeIsOpenMenu] = useActionSideBar();

    return (
        <div>
            <div id='main'
                 className={isOpenMenuSideBar ? 'opacity-mobile' : ''}>
                <div id='overlay-main' className="overlay"
                     onClick={() => {
                         changeIsOpenMenu();
                     }}/>
                <div className="slider-close"/>
                <KirokuNotification/>
            </div>
            <SideBar/>
            <Header path={props.location.pathname}/>
            <div className={`main-container ${!isOpenMenuSideBar && 'main-collapse'}`}>
                <div className={'main-content'}>
                    <Switch>
                        <Route path={"/"} exact render={() => <Redirect to="/ " />} />
                        {
                            screens.map((screen, index) => {
                                return <Route
                                    path={screen.path}
                                    component={screen.component}
                                    exact={screen.exact}
                                    key={index}
                                />;
                            })
                        }
                        <Redirect to="/404"/>
                    </Switch>
                </div>
            </div>
        </div>

    );
}

import React                                                                                from 'react';
import { KirokuButton, KirokuModal, KirokuModalBody, KirokuModalFooter } from '../../ui';
import { useHistory }                                                                       from 'react-router-dom';
import { authentication }                                                from '@kiroku/api';
import moment                                                            from 'moment';
import { useTranslation }                                                from 'react-i18next';

export default function ModalAlertPassword(props) {
    const { show, onCloseModal, user } = props;
    const history                      = useHistory();
    const update                       = authentication.useChangePassword()[1];
    const {t} = useTranslation();
    return (
        <KirokuModal show={ show }>
            <KirokuModalBody>
                <div className="d-flex" style={{
                    flexDirection: 'row-reverse',
                    padding:' 5px 5px 0 0'}}>
                    <button className='close' style={ { float: 'left' } }
                            onClick={ () => {
                                window.location.previous = '';
                                onCloseModal();
                            } }>
                        <span style={ { fontSize: '24px', color: '#a8aab7' } }>&times;</span>
                    </button>
                </div>
                <div className={'d-flex justify-content-center align-items-center p-3'}>
                    <i className="fas fa-user-clock fa-3x"/>
                    <span className='pl-2'>
                        <div>{t('common.Six months have passed since the last password change')}</div>
                        <div>{t('common.Do you want to update your password')}</div>
                    </span>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton
                    color="light"
                    onClick={ () => {
                        update(user.id, {
                            current_password        : '',
                            new_password            : '',
                            confirm_password        : '',
                            is_not_change_password  : true,
                            last_updated_password_at: moment().unix(),
                        }).then(() => {
                            window.location.previous = '';
                            onCloseModal();
                        });
                    } }
                >
                    { t('common.No') }
                </KirokuButton>
                <KirokuButton
                    color="primary"
                    onClick={ () => {
                        window.location.previous = '';
                        history.push(`/users/edit-my-profile`, {
                            activeTab: 'changePass',
                            user: {
                                id : user.id
                            }
                        });
                        onCloseModal();
                    } }
                >
                    { t('common.Yes, Update Password') }
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

import { useCustomerContext }   from '../../MakeCustomer/CustomerContext';
import { useCallback }          from 'react';
import { CHANGE_INFO_CUSTOMER } from '../../MakeCustomer/CustomerReducer';

export default function usePersonalInformation() {

    const {infoCustomer, dispatch} = useCustomerContext();
    const {name, date_of_birth, blood_type, characteristic, address, gender, post_code, avatar, furigana} = infoCustomer;

    const setPersonalInformation = useCallback((name, value) => {
        dispatch({
            type: CHANGE_INFO_CUSTOMER,
            name, value
        })
    }, [ dispatch ]);

    return {
        name, date_of_birth, blood_type, characteristic, address, gender, post_code, avatar,
        furigana,
        setPersonalInformation
    };
};

import React              from 'react';
import {
    KirokuCardWeb,
    KirokuLabel,
}                         from '../../../ui';
import { useTranslation } from 'react-i18next';

export default function TabInfoComponent({ isShow, userInfo }) {
    const { t } = useTranslation();

    return (
        <div hidden={!isShow}>
            <KirokuCardWeb>
                <div className="padding-info">
                    <div className={'card-title-info mb-15px'}>{t('CustomerEditor.PersonalInformation')}</div>
                    <div className='row'>
                        <div className="col-lg-4 col-md-6">
                            <div className='mb-15px'>
                                <span className='customer-info'>{t('common.Name')}:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{userInfo.name}</div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{t('common.userName')}:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{userInfo.username}</div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{t('common.Title')}:</span>
                                <br/>
                                <div className='personal-info pt-10px'>
                                {userInfo.title}
                            </div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{t('common.Role')}:</span>
                                <br/>
                                <div className='personal-info pt-10px'>
                                {t(`common.${userInfo.role}`)}
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className='mb-15px'>
                                <span className='customer-info'>{t('common.WorkingForm')}:</span>
                                <br/>
                                <div
                                    className='personal-info pt-10px'>{userInfo[ 'working_form' ] ? t(`common.${userInfo[ 'working_form' ]}`) : ''}</div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{t('common.Qualification')}:</span>
                                <br/>
                                <div className='personal-info pt-10px'>
                                    {userInfo.qualification.map(item => {
                                        return t(`common.dropdown.${item}`);
                                    }).join(', ')}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3"/>
                    </div>
                    <hr className={'m-0'}/>
                    <div className={'card-title-info mb-15px pt-15px'}>{t('common.Security')}</div>
                    <div className="row">
                        <div className="col-md-4 col-6">
                            <span className='customer-info'>{t('common.Two-factorAuthentication')}:</span>
                        </div>
                        <div className="col-md-4 col-6">
                            {!!userInfo[ '2fa' ] ? (
                                <KirokuLabel color="primary">
                                    {t('common.enabled')}
                                </KirokuLabel>
                            ) : (
                                <KirokuLabel color="danger">
                                    {t('common.disabled')}
                                </KirokuLabel>
                            )}
                        </div>
                    </div>
                </div>
            </KirokuCardWeb>
        </div>
    );
}

import React, { useState }     from 'react';
import { useTranslation }      from 'react-i18next';
import {
    KirokuCardWeb,
    KirokuCardTitleWeb,
    KirokuCardBodyWeb, KirokuInput,
    KirokuInputArea, KirokuButton,
}                              from '../../../ui';
import useSwitchScreen         from '../MakeSurvey/useSwitchScreen';
import useChangeInfoSurvey     from '../MakeSurvey/useChangeInfoSurvey';
import { useValidationSurvey } from '../MakeSurvey/useValidationSurvey';

function InputTitleSurvey() {
    const { t }                       = useTranslation();
    const { title, setInfoSurvey }    = useChangeInfoSurvey();
    const [inputTitle, setInputTitle] = useState(title);
    const [validate, settValidation]  = useState(true);

    return (
        <>
            <label htmlFor={'input-title-survey'}
                   className={'field-form-label'}
            >
                {t('createSurvey.Title')}
            </label>
            <KirokuInput name={'title'}
                         id={'input-title-survey'}
                         value={inputTitle}
                         onChange={e => {
                             setInputTitle(e.target.value);
                             settValidation(!!e.target.value);
                         }}
                         onBlur={e => setInfoSurvey(e.target.name, e.target.value)}
                         className={!validate ? 'form-error' : ''}
            />
            <p
                hidden={validate}
                style={{
                    color   : 'red',
                    fontSize: 12,
                    margin  : 0,
                }}
            >
                {t('createSurvey.TitleMustNotNull')}
            </p>
        </>
    );
}

function Description() {
    const { t }                                   = useTranslation();
    const { description, setInfoSurvey }          = useChangeInfoSurvey();
    const [inputDescription, setInputDescription] = useState(description);
    const [validate, settValidation]              = useState(true);

    return (
        <>
            <label htmlFor={'input-description-survey'}
                   className={'pt-3 field-form-label'}>
                {t('createSurvey.Description')}
            </label>
            <KirokuInputArea
                name={'description'}
                id={'input-description-survey'}
                value={inputDescription}
                onChange={e => {
                    setInputDescription(e.target.value);
                    settValidation(!!e.target.value);

                }}
                onBlur={e => setInfoSurvey(e.target.name, e.target.value)}
                className={!validate ? 'form-error' : ''}
            />
            <p
                hidden={validate}
                style={{
                    color   : 'red',
                    fontSize: 12,
                    margin  : 0,
                }}
            >
                {t('createSurvey.DescriptionMustNotNull')}
            </p>
        </>
    );
}

export default function About() {
    const { t }                        = useTranslation();
    const { currentScreen, setScreen } = useSwitchScreen();
    const {btnReview} = useValidationSurvey();

    return <div>
        <KirokuCardWeb>
            <KirokuCardTitleWeb>
                <div className='d-flex justify-content-between'>
                    <div className={'card-title-info'}>{t('createSurvey.Summary')}</div>
                    <KirokuButton color='success'
                                  disabled={btnReview}
                                  onClick={() => {
                                      setScreen(currentScreen === 'Create' ? 'Review' : 'Create');
                                  }}>
                        {t('createSurvey.Review')}
                    </KirokuButton>
                </div>
            </KirokuCardTitleWeb>
            <KirokuCardBodyWeb>
                <div className='summary-content'>
                    <InputTitleSurvey/>
                    <Description/>
                </div>
            </KirokuCardBodyWeb>
        </KirokuCardWeb>
    </div>;
}

import React              from 'react';
import ReportProvider     from './makeReport/ReportProvider';
import ReportContent      from './ReportContent';
import usePageTitle       from '../../ui/v3/hooks/usePageTitle';
import { useTranslation } from 'react-i18next';

export default function ReportScreen() {
    const { t } = useTranslation();
    usePageTitle(t(`Layout.ReportsTab`));

    return (
        <ReportProvider>
            <ReportContent/>
        </ReportProvider>
    );
}

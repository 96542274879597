import React, { useEffect }                           from 'react';
import { resources }                                  from '@kiroku/api';
import useReportCondition                             from '../makeReport/useReportCondition';
import momentTZ                                       from 'moment-timezone';
import { KirokuButton, KirokuLoader, SearchNotFound } from '../../../ui';
import If                                             from '../../If';
import { removeConditionsUndefined }                  from '../../Converter';
import { checkUseHeadingQuestion }                    from './index';
import '../report.css';
import { useTranslation }                             from 'react-i18next';

const Template = ({ record }) => (
    <div className="report-tab container">
        <div className="wrap-content">
            <div className="A4">
                <div className="content">
                    <div className="wrap-bg">
                        { record.templates.map((template, index) => {
                            return (
                                <div className="pb-4" key={ index }>
                                    <div>
                                        <label className="report-tab">
                                            { template.translate_title }
                                        </label>
                                        <div className="border-title-template">
                                            { template.title }
                                        </div>
                                        { template.groupCustomer.map((customer, index) => {
                                            return (
                                                <div key={ index }>
                                                    <div className="pb-2 pt-3">
                                                        <label className="report-tab">
                                                            { record.translate_customerName }
                                                        </label>
                                                        <div className="border-customer-name">
                                                            { customer.customerName }
                                                        </div>
                                                    </div>
                                                    { customer.questions.map((question, index) => {
                                                        return (
                                                            <div className="pb-3 " key={ index }>
                                                                <div className="content-question pb-2 pt-2">
                                                                    { checkUseHeadingQuestion(question) }
                                                                </div>
                                                                <div className="row ml-0 mr-0 ">
                                                                    <div className="col-3 pl-0 ">
                                                                        <label className="report-tab">
                                                                            { record.translate_serviced }
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-3 ">
                                                                        <label className="report-tab">
                                                                            { record.translate_created_by }
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-6 ">
                                                                        <label className="report-tab">
                                                                            { record.translate_answer }
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="answers-list">
                                                                    { question.answers.map((answer, index) => {
                                                                        return (
                                                                            <div
                                                                                className={ `${ index === 0 ? '' : 'pt-10px' } 
                                                                                        ${ answer.answerEnd ? 'end-answer' : 'answer' }
                                                                                        pb-10px row ml-0 mr-0 answer-item` }
                                                                                key={ index }>
                                                                                <div className="col-3 pl-0">
                                                                                    { answer.serviced_date_time }
                                                                                </div>
                                                                                <div className="col-3  ">
                                                                                    { answer.record_by }
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    { renderQuestion(answer) }
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }) }
                                                                </div>
                                                            </div>
                                                        );
                                                    }) }
                                                </div>
                                            );
                                        }) }
                                    </div>
                                </div>
                            );
                        }) }
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const renderQuestion = (answer) => {
    switch (answer.type) {
        case 'Media':
            return (
                <div className="pb-3">
                    <img className="report-tab" src={ answer.answer } alt=""/>
                </div>
            );
        case 'YesNo':
        case 'Date':
            return (<div className="answer-content">{ answer.answer }</div>);
        default:
            return (<div className="answer-content">{ answer.answer }</div>);
    }
};

export default function TemplateQuestion() {

    const { search, result, loading } = resources.useSearch('Report');
    const { condition, print }        = useReportCondition();
    const { t }                       = useTranslation();

    useEffect(() => {
        if (condition) {
            removeConditionsUndefined(condition);
            search({
                type    : 'report',
                template: 'TemplateQuestion',
                lang    : localStorage.getItem('i18nextLng') || 'ja',
                timezone: momentTZ.tz.guess(),
                ...condition,
            });
        }
    }, [search, condition]);

    return (
        <KirokuLoader loading={ loading }>
            <If statement={ result.templates && result.templates.length } otherwise={ <SearchNotFound
                message={t(`Export.showResultSearch`)}
                description={t(`Export.TrySearch`)}
            /> }>
                { () => (
                    <div>
                        <div className="d-flex pt-2 justify-content-end pr-10">
                            <div className="p-2"/>
                            <KirokuButton color={ 'primary' } onClick={ () => print('TemplateQuestion') }>
                                { t('common.print') }
                            </KirokuButton>
                        </div>
                        <Template record={ result }/>
                    </div>
                ) }
            </If>
        </KirokuLoader>
    );
}

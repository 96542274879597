import React                                           from "react";
import { ComponentMobile, ComponentWeb, KirokuButton } from "../../../../ui";
import { useTranslation }                              from "react-i18next";
import useContacts                                     from "./useContacts";
import ContactsForm                                    from "./ContactsForm";

export default function ContactsEditor() {
    const { contacts, addContact, setContacts, removeContact, showButtonRemove } = useContacts();
    const { t } = useTranslation();

    const updateFieldChanged = index => newContact => {
        let updateContacts = [ ...contacts ];
        updateContacts[index] = newContact;
        setContacts("contacts", updateContacts)
    }

    return (
        <div>
            <div className='d-flex justify-content-between'>
                <h5 className='card-title-info'>{t('CustomerEditor.contact.ContactInformation')}</h5>
                <ComponentWeb>
                    <KirokuButton
                        color='primary'
                        onClick={addContact}
                    >
                        {t('CustomerEditor.contact.AddContact')}
                    </KirokuButton>
                </ComponentWeb>
            </div>
            {contacts.map((contact, index) =>
                <div key={index}>
                    <ContactsForm
                        contact={contact}
                        onChange={updateFieldChanged(index)}
                        remove={() => removeContact(index)}
                        showButtonRemove={showButtonRemove}
                    />
                </div>
            )}
            <ComponentMobile>
                <div className="pt-3">
                    <KirokuButton
                        color='primary'
                        onClick={addContact}
                    >
                        {t('CustomerEditor.contact.AddContact')}
                    </KirokuButton>
                </div>
            </ComponentMobile>
        </div>
    );
}

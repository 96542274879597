import lodash               from 'lodash';
import { useTranslation }   from 'react-i18next';
import { getFlowQuestions } from '../HelperComponent/getQuestions';


export default function useYesNoChart(records, question) {
    const { t } = useTranslation();

    let questions       = records.map(record => {
        return getFlowQuestions(record.survey_template).filter(q => q.content === question.content && q.type === question.type);
    });
    questions           = questions.filter(question => !!question[ 0 ]);
    questions           = questions.map(question => question[ 0 ]);
    const dataWithCount = lodash.countBy(questions, function (question) {
        return question.answers;
    });

    return {
        isNotDataAnalysis: !dataWithCount.Yes && !dataWithCount.No,
        data: [
            ['Task', 'Yes No chart.'],
            [
                t('createRecord.Yes'),
                dataWithCount.Yes || 0,
            ],
            [
                t('createRecord.No'),
                dataWithCount.No || 0,
            ],
        ],
    };
}

import React from 'react';

export default function KirokuCheckboxBorder(props) {
    const { checked, content, onChange, onClick = () => {}, action, classCutLongText } = props;

    return (
        <div className={ 'border-check-box' }>
            <div className={ 'd-flex justify-content-between align-items-center p-3' }
                 onClick={ (event) => {
                     event.preventDefault();
                     onClick(event)
                 } }
            >
                <div className={'d-flex align-items-center'}>
                    <label className="check-group">
                        <input type="checkbox" checked={ checked }
                               onChange={ onChange }/>
                        <span className="checkmark"/>
                    </label>
                    <span className={`font-text-content-in-modal pl-3 ${classCutLongText || ''}`}>{ content }</span>
                </div>
                <div hidden={!action}>
                    {action}
                </div>
            </div>
        </div>
    );

}

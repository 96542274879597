import { useUpdaterContext } from './UpdaterContext';
import { useCallback }       from 'react';
import _                     from 'lodash';
import {
    SET_USERS,
    HANDLE_CHECK_ALL,
    HANDLE_CHECK_USER,
    HANDLE_SEARCH_TEAM,
    HANDLE_SEARCH_KEYWORDS,
    CHANGE_UPDATER_SELECTED,
    HANDLE_CHECK_ALL_SELECTED,
    HANDLE_CHANGE_TAB_OPTIONS,
    HANDLE_SEARCH_TEAM_CHECKED,
    HANDLE_CHECK_USER_SELECTED,
    HANDLE_SEARCH_KEYWORDS_SELECTED,
    HANDLE_SET_SELECT_TAB,
} from './UpdaterReducer';
import { useRecordContext }   from '../../ConditionBuilder/RecordContext';
import { HANDLE_RESET_STATE } from '../../../Planning/planSupporter/QuickAddSurvey/makeTemplate/TemplateReducer';

export function useSelectUsers() {
    const { updaterState, dispatch } = useUpdaterContext();
    const {
              userCheck, users, keywords,
              isSelectedTab, userConfirm,
              keywordSelected, userList
          }                          = updaterState;
    const { recordCondition }        = useRecordContext();

    const setSelectedUpdater = useCallback((userSelected) => {
        dispatch({
            type: CHANGE_UPDATER_SELECTED,
            userSelected,
        });
    }, [dispatch]);

    const getUsers = useCallback((users) => {
        if (recordCondition.udId === 'all') {
            let usersChecked = users.map(user => {
                return {
                    ...user,
                    isChecked : true
                }
            });
            dispatch({
                type: SET_USERS,
                users : usersChecked,
            });
        } else if (recordCondition.udId.length) {
            recordCondition.udId.forEach(user => {
                let indexUser    = _.findIndex(users, usr => usr.id === user);
                users[indexUser] = {
                    ...users[indexUser],
                    isChecked: true,
                };
            });
            dispatch({
                type: SET_USERS,
                users,
            });
        } else {
            dispatch({
                type: SET_USERS,
                users: users.map(user => ({...user, isChecked: false,}))
            });
        }
        dispatch({
            type: HANDLE_SET_SELECT_TAB
        })

    }, [dispatch, recordCondition.udId]);

    const handleCheckAll = useCallback(() => {
        dispatch({
            type: HANDLE_CHECK_ALL,
        });
    }, [dispatch]);

    const handleCheckUser = useCallback((index) => {
        dispatch({
            type: HANDLE_CHECK_USER,
            index,
        });
    }, [dispatch]);

    const handleSearchKeywords = useCallback((keywords) => {
        dispatch({
            type: HANDLE_SEARCH_KEYWORDS,
            keywords,
        });
    }, [dispatch]);

    const handleChangeTags = useCallback((teamSelected) => {
        dispatch({
            type: HANDLE_SEARCH_TEAM,
            teamSelected,
        });
    }, [dispatch]);

    const handleChangeTab = useCallback((isSelectedTab) => {
        dispatch({
            type: HANDLE_CHANGE_TAB_OPTIONS,
            isSelectedTab
        });
    }, [dispatch]);

    const handleChangeTeamChecked = useCallback((teamSelected) => {
        dispatch({
            type: HANDLE_SEARCH_TEAM_CHECKED,
            teamSelected,
        });
    }, [dispatch]);

    const handleCheckUserSelectedTab = useCallback((index) => {
        dispatch({
            type: HANDLE_CHECK_USER_SELECTED,
            index,
        });
    }, [dispatch]);

    const handleCheckAllSelectedTab = useCallback(() => {
        dispatch({
            type: HANDLE_CHECK_ALL_SELECTED,
        });
    }, [dispatch]);

    const handleSearchKeywordsSelected = useCallback((keywordSelected) => {
        dispatch({
            type: HANDLE_SEARCH_KEYWORDS_SELECTED,
            keywordSelected,
        });
    }, [dispatch]);

    const handleResetState = useCallback(() => {
        dispatch({
            type: HANDLE_RESET_STATE
        })
    }, [dispatch])

    return {
        userConfirm,
        users,
        isSelectedTab,
        keywords,
        getUsers,
        userCheck,
        handleCheckAll,
        keywordSelected,
        handleCheckUser,
        handleChangeTab,
        handleChangeTags,
        setSelectedUpdater,
        handleSearchKeywords,
        handleChangeTeamChecked,
        handleCheckAllSelectedTab,
        handleCheckUserSelectedTab,
        handleSearchKeywordsSelected,
        userList,
        handleResetState
    };
}

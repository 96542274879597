import KirokuInputIcon                        from './KirokuInputIcon';
import React, { useEffect, useRef, useState } from 'react';
import moment                                 from 'moment';
import { useTranslation }                     from 'react-i18next';

export default function KirokuInputDateMobile({ name, value, onChange, disabled = false, readOnly = true }) {
    const [date, setDate] = useState('');
    const inputRef        = useRef(null);
    const { t }           = useTranslation();

    useEffect(() => {
        if(value) {
            setDate(moment.unix(value).format('YYYY-MM-DD'))
        } else {
            setDate('');
        }
    }, [value, t]);

    let dateFormat = moment(date).format(t('dateTimeFormat.YearMonthDate'))

    return (
        <div>
            <div style={{ position: "absolute", zIndex: -999}}>
                <input type={ 'date' } style={{ border: 'none'}} value={ date } ref={ inputRef } onChange={ (e) => {
                    onChange(moment(`${e.target.value}`, 'YYYY-MM-DD').unix());
                } }/>
            </div>
            <KirokuInputIcon name={ name }
                             readOnly={readOnly}
                             disabled={disabled}
                             icon={ 'far fa-calendar-alt' }
                             value={ date ?  dateFormat : '' }
                             onFocus={ () => {
                                 inputRef.current.focus();
                             } }
                             onClick={ () => {
                                 inputRef.current.click();
                             }}
            />
        </div>
    );
}

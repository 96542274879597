import React                                                             from 'react';
import { useTranslation }                                                from 'react-i18next';
import { KirokuModalBody, KirokuModalFooter, KirokuButton, KirokuModal } from '../../../../../ui';


export default function ModalConfirm(props) {
    const { t } = useTranslation();
    const closeModal = ()  => {
        props.closeModal()
    };

   const submit = () => {
        props.closeModal();
        props.submit()
    };

    return (
        <KirokuModal show={props.show} onClick={() => closeModal}>
            <KirokuModalBody>
                <p className='text-center mt-8'>
                    {t('message.confirmPublic')}
                </p>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={() => closeModal()} color={'white'}>
                    {t('common.Cancel')}
                </KirokuButton>
                <KirokuButton onClick={() => submit()} color={'primary'}>
                    {t('common.Confirm')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

import React                                                   from 'react';
import If                                                      from '../../../If';
import { ScrollingAnchor }                                     from '../../../../ui/Scrolling';
import { getHeadingNextQuestion }                              from '../../../Records/sevicesInRecord';
import { useTranslation }                                      from 'react-i18next';
import { ComponentWeb, KirokuButtonLink, KirokuDropdownClose } from '../../../../ui';
import { useHistory }                                          from 'react-router-dom';
import { componentQuestion }                                   from './QuestionType';
import ComponentMobile                                         from '../../../../ui/ComponentMobile';
import { withPermissions }                                     from '../../../HOC';
import DivTagPermission                                        from '../../../HOC/DivTagPermission';

const DivPermission = withPermissions(DivTagPermission);

export default function QuestionTab({ tabType, template }) {
    const { t }     = useTranslation();
    const history   = useHistory();

    return (
        <div hidden={ tabType !== 'QUESTION_TAB' }>
            <div className="pt-3">
                <If statement={ template }>
                    { () =>
                        <div>
                            <div className={ 'pb-3' }>
                                <DivPermission resource-permission={ '|surveyList|button-edit|' }>
                                    <ComponentWeb>
                                        <div className={ `d-flex justify-content-end` }>
                                            {/*<div style={ { height: 62 } }>*/ }

                                            {/*</div>*/ }
                                            {/*<div className="pl-3">*/ }
                                            {/*    <KirokuButtonLink*/ }
                                            {/*        className={ 'font-label-preview pr-3 ' }*/ }
                                            {/*        style={ {*/ }
                                            {/*            border : 'solid 1px #1468a7',*/ }
                                            {/*            color  : '#1468a7',*/ }
                                            {/*            height : '36px',*/ }
                                            {/*            padding: '0 16px',*/ }
                                            {/*        } }*/ }
                                            {/*        onClick={ () => {*/ }
                                            {/*            history.push(`/surveys/preview/${ template.id }`);*/ }
                                            {/*        } }*/ }
                                            {/*    >*/ }
                                            {/*        <div className="d-flex align-items-center">*/ }
                                            {/*            <i className="fas fa-binoculars text-secondary text-preview-button"/>&nbsp;*/ }
                                            {/*            <span style={ { fontSize: 14 } }>{ t('common.Preview') }</span>*/ }
                                            {/*        </div>*/ }
                                            {/*    </KirokuButtonLink>*/ }
                                            {/*</div>*/ }
                                            <KirokuButtonLink
                                                className={ 'font-label-preview pr-3 ' }
                                                style={ {
                                                    border : 'solid 1px #17a2b8',
                                                    color  : '#17a2b8',
                                                    height : '36px',
                                                    padding: '0 16px',
                                                } }
                                                onClick={ () => {
                                                    history.push(`/surveys/duplicate/${ template.id }`);
                                                } }
                                            >
                                                <div className="d-flex align-items-center">
                                                    <img src={ `/assets/img/duplicate.svg` } alt="duplicate.svg"
                                                    /> &nbsp;<span
                                                    style={ { fontSize: 14 } }>{ t('common.Duplicate') }</span>
                                                </div>
                                            </KirokuButtonLink>
                                            <div className="pl-3">
                                                <KirokuButtonLink
                                                    className={ 'font-label-preview pr-3 ' }
                                                    style={ {
                                                        border : 'solid 1px #6c798f',
                                                        color  : '#6c798f',
                                                        height : '36px',
                                                        padding: '0 16px',
                                                    } }
                                                    onClick={ () => {
                                                        history.push(`/surveys/edit/${ template.id }`);
                                                    } }
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <img src={ `/assets/img/pen-edit.svg` } alt="pen-edit.svg"
                                                        /> &nbsp;
                                                        <span
                                                            style={ { fontSize: 14 } }>{ t('RecordsBeta.edit') }</span>
                                                    </div>
                                                </KirokuButtonLink>
                                            </div>
                                        </div>
                                    </ComponentWeb>
                                    <ComponentMobile>
                                        <div style={{ width: 120}}>

                                        <KirokuDropdownClose
                                            title={ t('common.BulkAction') }
                                            items={ [{
                                                value    : 'Duplicate',
                                                component: (<div className="d-flex align-items-center">
                                                    <img src={ `/assets/img/duplicate.svg` } alt="duplicate.svg"
                                                    /> &nbsp;<span
                                                    style={ { fontSize: 14, color: '#17a2b8' } }>{ t('common.Duplicate') }</span>
                                                </div>),
                                                label    : <div className="d-flex align-items-center">
                                                    <img src={ `/assets/img/duplicate.svg` } alt="duplicate.svg"
                                                    /> &nbsp;<span
                                                    style={ { fontSize: 14, color: '#17a2b8' } }>{ t('common.Duplicate') }</span>
                                                </div>,
                                            },
                                                {
                                                    value    : 'Edit',
                                                    component: (<div className="d-flex align-items-center">
                                                        <img src={ `/assets/img/pen-edit.svg` } alt="pen-edit.svg"
                                                        /> &nbsp;
                                                        <span
                                                            style={ { fontSize: 14 } }>{ t('RecordsBeta.edit') }</span>
                                                    </div>),
                                                    label    : <div className="d-flex align-items-center">
                                                        <img src={ `/assets/img/pen-edit.svg` } alt="pen-edit.svg"
                                                        /> &nbsp;
                                                        <span
                                                            style={ { fontSize: 14 } }>{ t('RecordsBeta.edit') }</span>
                                                    </div>,
                                                }] }
                                            onSelect={ event => {
                                                if (event.kirokuSelected.value === 'Edit') {
                                                    history.push(`/surveys/edit/${ template.id }`);
                                                }
                                                if (event.kirokuSelected.value === 'Duplicate') {
                                                    history.push(`/surveys/duplicate/${ template.id }`);
                                                }
                                            } }
                                        />
                                        </div>
                                    </ComponentMobile>
                                </DivPermission>
                            </div>
                            <div className='fix-center'>
                                <div id='question-tab-survey-detail-v2' style={ { width: '100%' } }>
                                    { template.questions.map((question, index) => {
                                        const Question = componentQuestion[question.type];
                                        return (
                                            <ScrollingAnchor key={ index }
                                                             anchor={ `question-survey-${ index }` }>
                                                <Question question={ question }
                                                          value={ question.defaultAnswer }
                                                          headerNextQuestion={ getHeadingNextQuestion(question, template.questions) }
                                                          isSurveyDetail
                                                />
                                            </ScrollingAnchor>
                                        );
                                    }) }
                                </div>
                            </div>

                        </div>
                    }
                </If>
            </div>
        </div>
    );
}

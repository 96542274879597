import InputPassword       from './InputPassword';
import If                  from '../If';
import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';
import InputUserName       from './InputUserName';

export default function FormLogin({login, error, isLoggingIn, nextStepOTP}) {
    const { t } = useTranslation();
    const [password, setPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [otpCode, setOtpCode]   = useState('');

    return (
        <form id="form-login" onSubmit={(event) => {
            event.preventDefault();
            login({userName, password, otpCode});
        }}>
            <If statement={!nextStepOTP}>
                {() => {
                    return <div>
                        <InputUserName
                            userName={userName} setUserName={setUserName}
                        />
                        <InputPassword
                            password={password} setPassword={setPassword}
                        />
                    </div>
                }}
            </If>

            <If statement={nextStepOTP}>
                {() => {
                    return <div>
                        <div className="auth-form-header p-0">
                            <h1>{t('Login.TwoFactorRecovery')}</h1>
                        </div>
                        <label className="form-input">
                            <input name="otp"
                                   type="text"
                                   autoComplete='otp'
                                   onChange={event => setOtpCode(event.target.value)}
                            />
                            <span className="label">{t('Login.authenticationCode')}</span>
                            <div className="underline"/>
                        </label>
                    </div>
                }}
            </If>

            <If statement={error}>
                {error =>
                    <p
                        className='text-danger text-center'
                        style={{ textAlight: 'justify' }}
                    >
                        {t(`Login.${error.code}`)}
                    </p>}
            </If>
            <If statement={!otpCode && nextStepOTP}>
                {() =>
                    <p
                        className='text-danger text-center'
                        style={{ textAlight: 'justify' }}
                    >
                        {t(`Login.Please enter the Auth Code`)}
                    </p>}
            </If>
            <div className="submit-container clearfix">
                <button disabled={isLoggingIn || (!otpCode && nextStepOTP)} id="submit" type='submit' className="btn btn-login">
                    {t('Login.login')}
                </button>
            </div>
        </form>
    )
}

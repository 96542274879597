import React          from 'react';
import {
    KirokuLoader, SearchNotFound,
    ComponentWeb, ComponentMobile,
}                     from '../../../ui';
import If             from '../../If';
import SurveyTable    from './SurveyTable';
import { SurveyList } from './SurveyList';

export default function Surveys({ loading, result, sort_by, changeOrder, order_by_type }) {
    const surveys = result;

    const handleChangeOrderBy = (field) => {
        let typeOrder = '';
        if (field === 'delete_at') {
            typeOrder = order_by_type.delete_at === 'ASC' ? 'DESC' : 'ASC';
        } else {
            typeOrder = order_by_type.create_at === 'ASC' ? 'DESC' : 'ASC';
        }
        changeOrder(field, typeOrder);
    };

    return (
        <div>
            <KirokuLoader loading={loading}>
                <If statement={surveys.length}
                    otherwise={<SearchNotFound/>}
                >
                    {() => <React.Fragment>
                        <ComponentWeb>
                            <SurveyTable
                                surveyTemplates={surveys}
                                sort_by={sort_by}
                                order_by_type={order_by_type}
                                handleChangeOrderBy={handleChangeOrderBy}
                            />
                        </ComponentWeb>
                        <ComponentMobile>
                            <SurveyList
                                surveyTemplates={surveys}
                            />
                        </ComponentMobile>
                    </React.Fragment>
                    }
                </If>
            </KirokuLoader>
        </div>
    );
}

import {
    ComponentMobile,
    ComponentWeb,
    KirokuButton,
    KirokuInput,
    KirokuModal,
    KirokuModalBody,
    KirokuModalFooter,
}                         from '../../../../../ui';
import React, {
    useEffect,
    useState,
}                         from 'react';
import UploadFileWeb      from './UploadFileWeb';
import UploadFileMobile   from './UploadFileMobile';
import useUploadFile      from './useUploadFile';
import { useTranslation } from 'react-i18next';

export default function UploadFie() {
    const { onDrop, onCancel, loadingUpload, files, file,
              handleConfirmUpload,
              handleCancelUpload } = useUploadFile();
    const [fileName, setFileName] = useState('');

    const fileType = file ? file.name.split('.').slice(-1)[0] : '' // get last item on array this is type file
    const currentFileName = file ? file.name.replace(`.${fileType}`, '') : ''

    useEffect(() => {
        setFileName(currentFileName)
    }, [currentFileName])
    const { t } = useTranslation()
    return (
        <>
            <KirokuModal show={!!file && fileType}>
                <KirokuModalBody>
                    <div className='p-3'>
                        <label className="mt-1 mb-10">{t('common.fileName')}</label>
                        <KirokuInput
                            maxLength={250}
                            value={fileName}
                            onChange={(event) => {
                                setFileName(event.target.value)
                            }}
                        />
                        <p hidden={fileName.replace(/\s+/g, '') || !file} style={{
                            color: 'red',
                            fontSize: 12,
                            marginBottom: 0
                        }}>
                            {t('common.please enter the file name')}
                        </p>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton
                        color={'white'}
                        onClick={() => handleCancelUpload()}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <KirokuButton
                        color={'success'}
                        disabled={!fileName.replace(/\s+/g, '') || loadingUpload}
                        onClick={() => {
                        handleConfirmUpload(new File([file], `${fileName}.${fileType}`, {type: file.type}));
                    }}>{t('common.Confirm')}</KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
            <ComponentWeb>
                <UploadFileWeb
                    onDrop={onDrop}
                    loadingUpload={loadingUpload}
                    onCancel={onCancel}
                    files={files}
                />
            </ComponentWeb>
            <ComponentMobile>
                <UploadFileMobile
                    onDrop={onDrop}
                    loadingUpload={loadingUpload}
                    onCancel={onCancel}
                    files={files}
                />
            </ComponentMobile>
        </>
    )
}

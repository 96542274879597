import React, { useEffect, useState }       from 'react';
import {
    KirokuCardWeb,
    KirokuCardBodyWeb, KirokuButton,
    KirokuLoader, KirokuUploadFile,
    KirokuModal, KirokuModalBody,
    KirokuModalFooter,
}                                           from '../../../../ui';
import { useTranslation }                   from 'react-i18next';
import { useHeadingQuestion }               from '../../useSurvey';
import { resources }                        from '@kiroku/api';
import { useAsQuestionType }                from '../../../Recording';
import QuestionCardTitle                    from './QuestionCardTitle';
import { ButtonNext, getNextQuestionMedia } from './getNextQuestion';
import { QuestionSkipped }                  from './QuestionSkipped';
import config                               from '../../../../config';

const width = window.innerWidth;

function ModalNotificationValidateUpload({ flagValidatedUpload, closeModal }) {

    const { t } = useTranslation();

    return (
        <KirokuModal show={ flagValidatedUpload } onCloseModal={ closeModal }>
            <KirokuModalBody>
                <div style={ { textAlign: 'center' } } className={ 'p-2' }>

                    { t('createRecord.NotificationUpload') }
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={ closeModal } color={ 'white' }>
                    { t('createRecord.Yes') }
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

export function setDefaultAnswerMedia(question) {
    if (question['defaultAnswer']) {
        return {
            ...question,
            answers: question['defaultAnswer'],
        };
    }
    return {
        ...question,
        answers: {},
    };
}


export default function MediaFileQuestion({ question }) {

    const { content }                        = useHeadingQuestion(question);
    const { doAnswer, answering }            = useAsQuestionType(question);
    const [result, upload, isLoading, error] = resources.useUpload('Record');
    const [isNotify, setNotify]              = useState(error);

    const handleUploadImage = (e) => {
        console.log(result);
        const file = e.currentTarget.files[0];
        if (file && ((file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size < config.sizeFileUploadImg)) {
            let formData = new FormData();
            formData.append('file', e.currentTarget.files[0]);
            formData.append('name', e.currentTarget.files[0].name);
            upload('public', formData).then(response => {
                answering();
                doAnswer({
                    answer    : response.url,
                    typeAnswer: file.type,
                }, getNextQuestionMedia({ ...question, isAnswers: !!response.url }));
            });
        } else {
            setNotify(!isNotify);
        }
    };

    useEffect(() => {
        setNotify(error);
    }, [error]);

    return (<div id='component-question'>
        <ModalNotificationValidateUpload
            flagValidatedUpload={ isNotify }
            closeModal={ () => setNotify(!isNotify) }

        />
        <div id='web-q' className='pb-3'>
            <div className={ (width <= 768) && !!question.answers.answer ? 'border-has-answer' : ' ' }>
                <KirokuCardWeb>
                    <QuestionCardTitle
                        isDisabled={ question.isDisabled }
                        question={ question }
                        handleResetAnswer={ () => doAnswer({}, question.index + 1) }
                        hasAnswer={ !!question.answers.answer }
                    />
                    <KirokuCardBodyWeb>
                        <QuestionSkipped
                            isDisabled={ question.isDisabled }
                        >
                            <span className='content-name-question'>{ content }</span>

                            <div className='answer-question'>
                                <KirokuLoader loading={ isLoading }>
                                    <KirokuUploadFile disabled={ question.isDisabled }
                                                      url={ question.answers.answer }
                                                      onChange={ (e) => handleUploadImage(e) }/>
                                </KirokuLoader>
                            </div>
                            <div style={ { textAlign: 'center' } }>
                                <ButtonNext
                                    question={ question }

                                />
                            </div>
                        </QuestionSkipped>
                    </KirokuCardBodyWeb>
                </KirokuCardWeb>
            </div>
        </div>
    </div>);
}

import { useSurveyContext } from './SurveyContext';
import lodash               from 'lodash';

export function useValidationSurvey() {
    const { infoSurvey }                    = useSurveyContext();
    const { title, description, questions } = infoSurvey;

    const validateContentQuestion = questions.filter(question => !question.content).length;

    const validateChoiceQuestion = () => {
        let arrayChecksAnswerQuestions = questions.map(question => {
            if (question[ 'choices' ]) {
                return lodash.findIndex(question.choices, ['answer', '']) !== -1;
            }
            return !!question[ 'choices' ];
        });
        return !!(arrayChecksAnswerQuestions.find((item) => item === true));
    };

    const btnReview = !title || !description || !questions.length || validateContentQuestion || validateChoiceQuestion();
    return { btnReview };
}

import React              from "react";
import { useTranslation } from "react-i18next";

export const useClassifications = () => {

    const {t} = useTranslation();
    const classications = [
        {
            id: 1,
            name: t('CustomerEditor.NotApplicable'),
            value: 0
        },
        {
            id: 2,
            name: '1',
            value: 1
        },
        {
            id: 3,
            name: '2',
            value: 2
        },
        {
            id: 4,
            name: '3',
            value: 3
        },
        {
            id: 5,
            name: '4',
            value: 4
        },
        {
            id: 6,
            name: '5',
            value: 5
        },
        {
            id: 7,
            name: '6',
            value: 6
        },
    ];

    const  Classications = (classications) => {
        return classications.map(classication => ({
            value: classication.value,
            component: (
                <div>
                    <span className={'text-muted'}>{classication.name}</span>
                </div>
            ),
            label: classication.name
        }))
    };

    return [ Classications(classications) ]
}

import AccordionUI, { PlanHeader } from './Plan/AccordionUI';
import React                       from 'react';
import { useTranslation }          from 'react-i18next';
import { Link }                    from 'react-router-dom';
import lodash                      from 'lodash';

export default function PlanTableWebComponent({ result, surveyId }) {
    const { t } = useTranslation();
    return (
        <div>
            <div className={'label-header-planing'}>
                <div className={'col-tooltip-icon'}/>
                <div className={'col-overview'}>{t('plans.assistancePolicy')}</div>
                <div className={'col-customer'}>{t('common.customer')}</div>
                <div className={'col-startDate'}>{t('plans.startDate')}</div>
                <div className={'col-endDate'}>{t('plans.endDate')}</div>
                <div className={'col-status'}>{t('plans.planStatus')}</div>
                <div className={'col-review-status'}>{t('plans.statusReview')}</div>
                <div className={'col-detail-btn'}/>
            </div>
            {result.map((plan, index) => {
                return <div className={'bg-white mb-2'} key={index}>
                    <AccordionUI
                        headElement={<PlanHeader plan={plan} index={index}/>}
                        contentElement={<div>
                            <div className="supports-header">
                                <div className={'col-tooltip-icon'}/>
                                <div className={'col-priority-sp'}>{t('plans.priority')}</div>
                                <div className={'col-object-sp'}>{t('plans.objective')}</div>
                                <div className={'col-overview-sp'}>{t('plans.content')}</div>
                                <div className={'col-term-sp'}>{t('plans.achievementTime')}</div>
                                <div className={'col-status-sp'}>{t('plans.statusReview')}</div>
                                <div className={'col-action-sp'}/>
                            </div>
                            <div>
                                {plan.supports.map((support, index) => {
                                    return <div className={'supports-body'} key={index}>
                                        <div className={'col-tooltip-icon'}/>
                                        <div className={'col-priority-sp'}>
                                             <span
                                                 className="ellipse d-flex justify-content-center align-items-center">
                                                {support.priority}
                                            </span>
                                        </div>

                                        <div className={'col-object-sp'}>{support.target}</div>

                                        <div className={'col-overview-sp'}>{support.description}</div>

                                        <div
                                            className={'col-term-sp'}>{support.term}{t('plans.monthLater')}</div>

                                        <div className={'col-status-sp'}>
                                            {support['review_at'] ?
                                                <div className="font-content-table">
                                                    {support['has_draft_review'] ?
                                                        <div className="d-flex align-items-center">
                                                            <img src={ `/assets/icon/iconDraft.svg` } alt="iconDraft.svg"
                                                                 style={ { width: 18, height: 16 } }
                                                            /> &nbsp;<span
                                                            className={ 'font-size-14' }>{ t('plans.draft') }</span>
                                                        </div> :
                                                        <div>
                                                            <img src={`/assets/img/reviewd.svg`} alt="reviewd.svg"/>
                                                            &nbsp;{t('plans.monitoredStatus')}
                                                        </div> }
                                                </div> : ''
                                            }
                                        </div>

                                        <div className={'col-action-sp'}>
                                            {
                                                lodash.findIndex(support.surveys, (survey) => {
                                                    return parseInt(survey.id) === parseInt(surveyId);
                                                }) !== -1 &&
                                                <Link className="btn btn-kiroku-success d-flex align-items-center justify-content-center"
                                                    to={`/records/create/customer/${plan.customer_id}/survey/${parseInt(surveyId)}`}
                                                    style={{color: '#fff', width: 80 }}
                                                >
                                                    {t('common.Records')}
                                                </Link>
                                            }
                                        </div>
                                    </div>;
                                })}
                            </div>
                        </div>}
                    />
                </div>;
            })}
        </div>
    );
}

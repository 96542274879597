import React              from 'react';
import {
    KirokuButton, ComponentMobile,
    KirokuCircleUp, Paginator,
}                         from '../../ui';
import { useTranslation } from 'react-i18next';

export function getItems(n) {
    let arrayItem = [];
    let i         = 1;
    while (i <= n) {
        arrayItem.push({
            value: i,
            name : i,
        });
        i++;
    }
    return arrayItem;
}

export default function UIList(props) {
    const {
              currentScreen, condition,
              pagination, searchDeleted,
              loading, result, sort_by, order_by_type
          }              = props;
    const { t }          = useTranslation();
    const ComponentTable = currentScreen.table;

    const nextPage = () => {
        if (condition.page + 1 <= pagination.totalPage) {
            searchDeleted({
                ...condition,
                page: condition.page + 1,
            });
        }
    };

    const prevPage = () => {
        if (condition.page - 1 >= 0) {
            searchDeleted({
                ...condition,
                page: condition.page - 1,
            });
        }
    };

    const gotoPage = (pageNumber) => {
        searchDeleted({
            ...condition,
            page: pageNumber,
        });
    };

    const changeOrder = (sort_by, order_by) => {
        searchDeleted({
            ...condition,
            sort_by, order_by, page: 1,
        });
    };

    return (<div>
        <div className="pt-3 pb-3" hidden={!result.length}>
            <Paginator
                items={getItems(pagination.totalPage)}
                numberPage={pagination.currentPage}
                onChangePaginator={(event) => gotoPage(event.kirokuSelected.value)}
                increasePage={() => {
                    nextPage();
                }}
                decreasePage={() => {
                    prevPage();
                }}
                disabledPrev={pagination.currentPage === 1}
                disabledNext={pagination.currentPage >= pagination.totalPage}
            />
        </div>

        <ComponentMobile>
            <div className='pb-3'>
                <div className='d-flex justify-content-center'>
                    <KirokuButton
                        className={sort_by === 'delete_at' ? 'btn-action-active btn-del' : 'btn-action-deActive btn-del'}
                        onClick={() => changeOrder('delete_at', 'DESC')}
                    >{t('DeletedList.deletedAtBtn')}</KirokuButton>
                    <KirokuButton
                        className={sort_by === 'create_at' ? 'btn-action-active btn-cre' : 'btn-action-deActive btn-cre'}
                        onClick={() => changeOrder('create_at', 'DESC')}
                    >{t('DeletedList.createdAtBtn')}</KirokuButton>
                </div>
            </div>
        </ComponentMobile>

        <div className={'bg-white'}>
            <ComponentTable
                loading={loading}
                result={result}
                sort_by={sort_by}
                order_by_type={order_by_type}
                changeOrder={changeOrder}
            />
        </div>

        <div className="pt-3 pb-3" hidden={!result.length}>
            <Paginator
                location={'top'}
                items={getItems(pagination.totalPage)}
                numberPage={pagination.currentPage}
                onChangePaginator={(event) => gotoPage(event.kirokuSelected.value)}
                increasePage={() => {
                    nextPage();
                }}
                decreasePage={() => {
                    prevPage();
                }}
                disabledPrev={pagination.currentPage === 1}
                disabledNext={pagination.currentPage >= pagination.totalPage}
            />
        </div>
        <ComponentMobile>
            <div style={{ marginBottom: 80 }}>
                <KirokuCircleUp/>
            </div>
        </ComponentMobile>
    </div>);
}

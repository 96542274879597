import React               from 'react';
import { useTranslation }  from 'react-i18next';
import {
    ComponentMobile, ComponentWeb, KirokuAge, KirokuCertificateList, KirokuStaffTagList, KirokuTeamList,
}                          from '../../../../src/ui';
import { withPermissions } from '../../HOC';
import LinkPermission      from '../../HOC/LinkPermission';
import { authentication }  from '@kiroku/api';

const LinkTo = withPermissions(LinkPermission);

export default function CustomerInfo(props) {
    const { t }        = useTranslation();
    const { customer } = props;
    const profile      = authentication.useAuthenticatedContext().user;

    return (
        <div>
            <div className='pt-10'>
                <ComponentMobile>
                    <h5 className='pr-3 text-align-center card-title-info'>{customer.name}</h5>
                    <div className={'text-align-center mb-10'}>
                        <span hidden={profile.role === 'staff'} className='pr-1 pt-edit'>
                            <i className='fas fa-pencil-alt text-secondary'/>
                        </span>
                        <span className='pt-edit '>
                            <LinkTo
                                resource-permission={'|customerDetail|button-editProfile|'}
                                href={`/customers/edit/${customer.id}`}
                                text={t('common.EditProfile')}
                            />
                        </span>
                    </div>
                </ComponentMobile>

                <ComponentWeb>
                    <div className='d-flex align-items-center' style={ { flexWrap: 'wrap' }}>
                        <h5 className='pr-3 card-title-info'>{customer.name}</h5>
                        <div style={{marginBottom: 5}}>
                             <span hidden={profile.role === 'staff'} className='pr-1 pt-edit'>
                                <i className='fas fa-pencil-alt text-secondary'/>
                            </span>
                            <span className='pt-edit '>
                                <LinkTo
                                    resource-permission={'|customerDetail|button-editProfile|'}
                                    href={`/customers/edit/${customer.id}`}
                                    text={t('common.EditProfile')}
                                />
                            </span>
                        </div>
                    </div>
                </ComponentWeb>
            </div>
            <div className="text-left">
                <div className='mb-10'>
                    <span className='customer-info'>
                        <span className="mr-2 font-size-16">{t('Customer.Furigana')}:</span>
                        <span>{customer.furigana}</span>
                    </span>
                </div>
                <div className='mb-10'>
                    <span className='customer-info'>
                        <span className="mr-2 font-size-16">{t('common.Age')}:</span>
                        <KirokuAge dob={customer.date_of_birth}/>
                    </span>
                </div>
                <div className="mb-10">
                    <div className="d-flex align-items-center" style={{ flexWrap: 'wrap' }}>
                        <div className='customer-info '>
                            <span className="mr-2 font-size-16">{t('CustomerEditor.KindOfCertificate')}:</span>
                        </div>
                        <KirokuCertificateList certificate={customer.certificate} numberCerlist={1}/>
                    </div>
                </div>
                <div className='mb-10'>
                    <span className='customer-info'>
                        <span className="mr-2 font-size-16">{t('common.Characteristic')}:</span>
                    </span>
                    <span>{customer.characteristic}</span>
                </div>

                <div className='mb-10'>
						<span className='customer-info'>
							<span className="mr-2 font-size-16">{t('common.Staff')}:</span>
						</span>
                    <ComponentWeb>
                        <KirokuStaffTagList type={'users'} listTags={customer[ 'users' ]}/>
                    </ComponentWeb>
                    <ComponentMobile>
                        <KirokuTeamList type={"users"} listTags={customer['users']}/>
                    </ComponentMobile>
                </div>
                <div className='mb-10'>
						<span className='customer-info'>
							<span className="mr-2 font-size-16">{t('common.team')}:</span>
						</span>
                    <ComponentWeb>
                        <KirokuStaffTagList type={'teams'} listTags={customer['teams']}/>
                    </ComponentWeb>
                    <ComponentMobile>
                        <KirokuTeamList type={"teams"} listTags={customer['teams']}/>
                    </ComponentMobile>
                </div>
            </div>
        </div>
    );
}

import React                 from 'react';
import KirokuDateMonthSelect from './KirokuDateMonthSelect';

export default function Components() {
    return (
        <div>
            <div className="p-4">
                <KirokuDateMonthSelect/>
            </div>
        </div>
    )
}

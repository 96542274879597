import React, { useEffect, useState } from 'react';
import { resources }                  from '@kiroku/api';
import { useHistory }                 from 'react-router-dom';
import PlanningAction                 from '../PlanningAction';
import { useMixpanelTracking }        from '../../../Mixpanel/useMixpanelTracking';

export default function PlanningCreator(props) {
    const create  = resources.useCreate('Plan')[1];
    const history = useHistory();
    const [isScrolling, setIsScrolling] = useState(false);
    const {trackingMixpanel}            = useMixpanelTracking();

    useEffect(() => {
        setIsScrolling(true);
    }, [setIsScrolling])

    return (
        isScrolling && <PlanningAction
            message={'message.CreateSuccess'}
            handleSubmit={ (value) => create(value).then(resultAfterCreatePlan => {
                history.push(`/plans/${ resultAfterCreatePlan.id }`);
                trackingMixpanel('CREATE_PLAN', value);
            }) }
            plan={{
                customer : props.location.state ? props.location.state.customer : '',
                customer_id : props.location.state ? props.location.state.customer_id : '',
                isDisabledCustomer: props.location.state ? props.location.state.isDisabledCustomer : false,
            }}
        />
    );
}

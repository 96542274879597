import { fromJS }          from 'immutable';
import { combineReducers } from 'redux-immutable';

export const KIROKU_ACTION_SIDE_BAR      = 'KIROKU_ACTION_SIDE_BAR';
export const KIROKU_SWITCH_STATUS_NOTIFY = 'KIROKU_SWITCH_STATUS_NOTIFY';

const initialStateKirokuaiReducer = fromJS({
    isOpenMenuSideBar  : false,
    isOpenNotification : false,
    messageNotification: '',
    notificationType   : '',
    isNotAutomaticCloseNotice   : true,
});

const kirokuaiReducer = (state = initialStateKirokuaiReducer, action) => {
    switch (action.type) {
        case KIROKU_ACTION_SIDE_BAR: {
            return state.set('isOpenMenuSideBar', !state.get('isOpenMenuSideBar'));
        }
        case KIROKU_SWITCH_STATUS_NOTIFY: {
            if(state.get('isOpenNotification') && action.notificationType) {
                return state;
            }
            let newState = state;
            newState = newState.set('isOpenNotification', !newState.get('isOpenNotification'));
            newState = newState.set('messageNotification', action.messageNotification);
            newState = newState.set('notificationType', action.notificationType);
            newState = newState.set('isNotAutomaticCloseNotice', !!action.isNotAutomaticCloseNotice);
            return newState;
        }
        default:
            return state;
    }
};


export default combineReducers({
    kirokuaiReducer,
});

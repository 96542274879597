import { KirokuLoader, KirokuTextareaAutosize } from '../../../ui';
import React, { useEffect, useState }           from 'react';
import { resources }                            from '@kiroku/api';
import { useTranslation }                       from 'react-i18next';

export default function Memo(props) {
    const {t} = useTranslation();
    const [memo, setMemo]                 = useState(props.plan.memo || '');
    const [plan, setPlan]                 = useState(props.plan);
    const patch                           = resources.usePatch('Plan')[1];
    const [isLoadingMemo, setLoadingMemo] = useState(false);
    const [isEditingMemo, setShowEditing] = useState(false);

    useEffect(() => {
        if (plan) {
            setPlan(plan);
        }
    }, [plan]);
    return (
        <div className="card">
            <div className="p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div className={'font-size-18'}>
                        {t('plans.memo')}
                    </div>
                    <div className="justify-content-flex-end pt-2">
                        { !isEditingMemo ?
                            <span onClick={ () => setShowEditing(!isEditingMemo) } className={'cursor-pointer'}>
                                                    <i className="fas fa-edit fa-lg" style={{ width: 24, height: 24 }}/>
                                                </span>
                            : <div className="d-flex align-items-center">
                                <div className={ 'd-flex align-items-center' }>
                                        <span
                                            style={ {
                                                color   : 'red',
                                                cursor  : 'pointer',
                                                position: 'relative',
                                                margin  : 'auto 0',
                                            } } className='ml-2'
                                            onClick={ () => {
                                                setShowEditing(!isEditingMemo)
                                                setMemo(plan.memo)
                                            } }
                                        >
                                            <i className="fas fa-2x fa-times-circle"/>
                                        </span>
                                    <div className="pl-2">
                                        <span
                                            onClick={ () => {
                                                setLoadingMemo(true);
                                                patch(plan.id, {memo: memo}).then(() => {
                                                    setPlan({
                                                        ...plan,
                                                        memo: memo
                                                    })
                                                    setLoadingMemo(false);
                                                    setShowEditing(false);
                                                });
                                            } }
                                            style={ {
                                                color   : 'green',
                                                cursor  : 'pointer',
                                                position: 'relative',
                                                margin  : 'auto 0',
                                            } } className='ml-2'>
                                            <i className="fas fa-2x fa-check-circle"/>
                                        </span>
                                    </div>
                                </div>
                            </div> }

                    </div>
                </div>
                <span className={'pl-1 pr-1'}
                      style={ { fontSize: 12, width: 70,
                          color: '#c9a043',
                          borderRadius: 3
                      } }>
                    {t('plans.memoCannotExport')}
                </span>
                <div className={ 'pt-10px' }>
                    <KirokuLoader loading={ isLoadingMemo }>
                        {!isEditingMemo ? <pre>{memo}</pre> : <KirokuTextareaAutosize
                            value={ memo }
                            onChange={ (e) => setMemo(e.target.value) }
                            className='form-area text-form-area'
                        />}
                    </KirokuLoader>
                </div>
            </div>
        </div>
    );
}

import { useCallback, useState } from 'react';


export default function useProgressEvent(eventName, participants) {

    const [isDone, setIsDone]       = useState(false);
    const [statusBar, setStatusBar] = useState(0);

    const holdEvent  = useCallback((localtionIndex) => {

        if(! participants[ localtionIndex ]) {
            setIsDone(true);
            return null;
        }

        if (participants[ localtionIndex ]) {
            return eventName(participants[ localtionIndex ])
                .then((resolved, reject) => {
                    if (resolved) {
                        holdEvent(localtionIndex + 1);
                        const number = (localtionIndex + 1) * 100 / participants.length - 1;
                        setStatusBar(parseInt(number))
                    }
                    return reject;
                });
        }
        return null;
    }, [eventName, participants]);

    const beginEvent = useCallback(() => {
        return holdEvent(0);
    }, [holdEvent]);

    const cancelEvent = useCallback(() => {
        //todo
    }, []);

    const setInitial = useCallback(() => {
        setIsDone(false);
        setStatusBar(0);
    }, []);

    return [
        beginEvent,
        isDone,
        setInitial,
        statusBar,
        cancelEvent
    ];
}

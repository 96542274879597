import React              from 'react';
import { useTranslation } from 'react-i18next';

export default function DescriptionSurvey({ description } ) {

    const { t } = useTranslation();

    return (
        <div className="survey fix-center pt-3 pb-3">
            <div style={{width: '100%'}}>
                <div className='info-question'>
                    <div className='content-name-question d-flex'>
                        <span style={{ wordBreak: 'keep-all' }}
                              className='title-record-info font-size-16'
                        >
                            {t('createRecord.Description')}&nbsp;
                        </span>
                        <pre style={{
                            wordWrap  : 'break-word',
                            whiteSpace: 'pre-wrap',
                            margin    : 0,
                            fontSize  : 16
                        }}>
                            {description}
                        </pre>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React              from 'react';
import Dropzone           from 'react-dropzone';
import config             from '../../../../../config';
import { useTranslation } from 'react-i18next';
import { KirokuButton }   from '../../../../../ui';

export default function UploadFileMobile(props) {
    const { t }                = useTranslation();
    const { onDrop, onCancel, loadingUpload, files } = props;

    return (<div className='bg-white'>
        <div className='padding-responsive'>
            <div className='wrap-upload-customer'>
                <div className='fix-center pt-3 pb-3'>
                    <div className={`border-icon-upload ${(files.length && loadingUpload) ? 'active' : ''}`}>
                        <div className='main-loading'>
                            <div className={loadingUpload ? 'opacity-main' : ''}>
                                <div className="overlay"/>
                            </div>
                            <div
                                className={!loadingUpload ? 'display-none' : 'modal-kiroku fade show'}>
                                <div className="modal-dialog modal-dialog-centered fix-center">
                                    <div className='as'>
                                        <button className='btn' style={{
                                            padding   : '.5rem',
                                            background: 'transparent',
                                        }}>
                                            <div className="spinner" style={{ width: 40, height: 40 }}/>
                                            <p className='text-white pt-2'>{t('common.Uploading')}</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <i hidden={loadingUpload}
                           className="fas fa-file-upload color-icon-upload-customer"/>
                    </div>
                    <div className="pt-3">
                        <div className='form-upload-data'>
                            <Dropzone
                                multiple={false}
                                accept={config.acceptFileTypes}
                                onDrop={onDrop}
                                onFileDialogCancel={onCancel}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()} className='fix-center'>
                                        <input {...getInputProps()} />
                                        <KirokuButton
                                            color='success'>{t('common.ChoseFile')}</KirokuButton>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

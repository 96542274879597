import React, { useEffect, useState } from 'react';
import SupportAction                  from './SupportAction';
import { resources }                  from '@kiroku/api';
import { useTranslation }             from 'react-i18next';
import useNotification                from '../../Layout/useNotification';
import { useHistory }                 from 'react-router-dom';
import { KirokuLoader }               from '../../../ui';
import If                             from '../../If';
import { useMixpanelTracking }        from '../../../Mixpanel/useMixpanelTracking';

export default function SupportUpdater(props) {
    const history                     = useHistory();
    const { t }                       = useTranslation();
    const { notification }            = useNotification();
    const [loadingAPI, setLoadingAPI] = useState(true);
    const update                      = resources.useUpdate('Support')[1];
    const [support, loadDetail]       = resources.useDetail('Support');
    const [error, setError]           = useState(null);
    const { trackingMixpanel }        = useMixpanelTracking();
    const [isDisabled, setDisabled]   = useState(false);

    useEffect(() => {
        loadDetail(props.match.params.id).then(() => {
            setLoadingAPI(false);
        }).catch(err => {
            setError(err);
            setLoadingAPI(false);
            history.push(`/plans`);
        });
    }, [loadDetail, props.match.params.id, history]);

    return (
        <KirokuLoader loading={ loadingAPI }>
            <If statement={ support && !error }  otherwise={<div/>}>
                { () => <SupportAction support={ support }
                                       plan={ support.plan }
                                       customer={ support.plan.customer.id }
                                       titleHeader={ t('plans.edit') }
                                       isDisabled={ isDisabled }
                                       isUpdate
                                       handleSubmit={ (value) => {
                                           setDisabled(true);
                                           update(support.id, {
                                               ...value,
                                               priority: support.priority,
                                           }).then(() => {
                                               setLoadingAPI(false);
                                               trackingMixpanel('UPDATE_SUPPORT', {
                                                   ...value,
                                                   priority: support.priority,
                                               });
                                               notification(t('message.UpdateSuccess'), 'success');
                                               history.goBack();
                                           });
                                       } }
                /> }
            </If>
        </KirokuLoader>
    );
}

import React             from 'react';
import Datetime          from 'react-datetime';
import 'moment/locale/ja';
import 'moment/locale/en-au';
import '../../node_modules/react-datetime/css/react-datetime.css';
import { withUserAgent } from 'react-useragent';

function KirokuInputDate(props) {
    const { ua } = props;
    return (
        <Datetime
            dateFormat={'YYYY/MM/DD'}
            timeFormat={false}
            closeOnSelect={!ua.mobile}
            monthYearLocale={localStorage.getItem('i18nextLng')}
            {...props}
        />
    );
}


export default withUserAgent(KirokuInputDate);

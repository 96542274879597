import React, { useState } from 'react';

export function ComponentImage({ rotation, path }) {
    return (
        <div className={'rotatable-image'}
             style={{
                 backgroundImage: `url("${path}")`,
                 transform      : `rotate(${rotation}deg)`,
             }}
        />
    );
}


export function ComponentPDF({ path }) {
    return <iframe title='document' id="iframe" src={path}/>;
}

export const typeDocumentComponent = {
    gif : ComponentImage,
    png : ComponentImage,
    jpg : ComponentImage,
    jpeg: ComponentImage,
    pdf : ComponentPDF,
    GIF : ComponentImage,
    PNG : ComponentImage,
    JPG : ComponentImage,
    JPEG: ComponentImage,
    PDF : ComponentPDF,
};


export default function PreviewFileModal({ isShowModal, closeModal, fileBePreview = { type: 'png', url: '' } }) {
    const [rotation, setRotation] = useState(0);

    const rotate = (increase) => {
        if (rotation >= 360) {
            return setRotation(90);
        }
        if (rotation <= -360) {
            return setRotation(-90);
        }
        setRotation(increase ? rotation + 90 : rotation - 90);
    };

    const ComponentFile = typeDocumentComponent[ fileBePreview.type ];

    return (<div className='modal-preview-file'>
            <div className={isShowModal ? 'opacity-main' : ''}>
                <div className="overlay"/>
            </div>
            <div className={!isShowModal ? 'display-none' : 'modal-kiroku fade show'}
                 style={{ overflow: 'hidden !important' }}>
                <div className=" modal-dialog modal-dialog-centered">
                    <div
                        className={`${['pdf', 'PDF'].includes(fileBePreview.type) ? 'pdf' : 'png'} modal-content`}
                    >
                        <div className="group-btn d-flex">
                            {!['pdf', 'PDF'].includes(fileBePreview.type) && (
                                <React.Fragment>
                                    <div>
                                        <i className="fas fa-2x fa-undo cursor-pointer"
                                           style={{ color: '#fff', paddingTop: 6 }}
                                           onClick={() => rotate(false)}/>
                                    </div>
                                    <div>
                                        <i className="fas fa-2x fa-redo cursor-pointer pl-3 pr-3"
                                           style={{ color: '#fff', paddingTop: 6 }}
                                           onClick={() => rotate(true)}/>
                                    </div>
                                </React.Fragment>
                            )}
                            <div>
                                <i className="fas fa-times cursor-pointer"
                                   onClick={() => {
                                       closeModal();
                                       setRotation(0);
                                   }}
                                   style={{ color: '#fff', fontSize: 38 }}
                                />
                            </div>
                        </div>
                        {ComponentFile && <ComponentFile
                            rotation={rotation}
                            path={fileBePreview.url}
                        />}


                    </div>
                </div>
            </div>
        </div>
    );
}

import { resources }   from '@kiroku/api';
import { useCallback } from 'react';

export default function useDifferenceCustomerHistory(customerId) {


    const [historyResult, search, loading] = resources.useSearchWithPathname('History');

    const historySearch = useCallback((logId) => {
        search({
            type: 'change',
            customer: customerId,
            log: logId
        })
    }, [search, customerId]);

    return {
        historyResult, historySearch, loading
    };
}

import React                                       from 'react';
import { KirokuLogicApplicated, KirokuUploadFile } from '../../../../../ui';
import { useHeadingQuestion }                      from '../../../../Records/useSurvey';
import { useScrollContext }                        from '../../../../../ui/Scrolling';

export default function MediaFileQuestion(props) {

    const { question, value, headerNextQuestion, isSurveyDetail } = props;

    const { optionalContentOrHeading } = useHeadingQuestion(question);
    const { scrollTo }                 = useScrollContext();

    return (<div className={'pb-3'}>
        <div className='wraper-question'>
            <div className="background-question">
                <div className='number-question d-flex justify-content-between align-items-center'>
                    <div className="question-number text-overflow-elip">
                        {optionalContentOrHeading}
                    </div>
                    <div hidden={!isSurveyDetail} className={'float-right'}>
                        <KirokuLogicApplicated  padding={'5px 5px 5px 7px'} question={question}/>
                    </div>
                </div>
                <span className='content-name-question'>{question.content}</span>
                <div
                    hidden={typeof question.nextQuestion !== 'number' || !isSurveyDetail}
                    className='text-info pl-10 text-overflow-elip scroll-link-question'
                    onClick={() => scrollTo(`question-survey-${question.nextQuestion}`, 202)}
                >
                    <i className='fas fa-arrow-right' style={{ paddingTop: 4 }}/>
                    <span className={'font-size-14'}> {headerNextQuestion}</span>
                </div>
            </div>
            <div className='answer-question'>
                <KirokuUploadFile disabled url={(!isSurveyDetail && value) ? value.answer : null}
                                  fileType={value ? value.typeAnswer : null}
                                  isPreview
                                  onChange={null}/>
            </div>
        </div>
    </div>);
}

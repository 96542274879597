import React, { useState }               from 'react';
import { ComponentMobile, ComponentWeb } from '../../../../ui';
import moment                            from 'moment';

export default function LongTerm({ plan, t }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={ 'accordion-ui' }>
            <div className={ 'header-card-detail-long-term d-flex align-items-center' }>
                <div className={ 'icon-accordion pl-0' } style={ { width: 24 } }>
                    <i className={ `fa fa-chevron-${ isOpen ? 'down' : 'right' }` }
                       style={ { color: '#6C798F' } }
                       onClick={ () => setIsOpen(!isOpen) }
                    />
                </div>
                <div className={ 'main-content-info pl-3' }>{ t('plans.longTermTarget') }</div>
            </div>
            <div hidden={ !isOpen }>

                <div className={ 'pt-3 pb-3' } style={ { borderBottom: 'solid 1px #dfe2e5' } }>
                    <ComponentWeb>
                        <div className="pb-3 row margin-none padding-content-info">
                            <div className={ 'col-6' }>
                                <span className={ 'label-info' }>{ t('plans.targetTerm') }:</span><br/>
                            </div>
                            <div className={ 'col-3' }>
                                <span className={ 'label-info' }>{ t('plans.long_term_target_start_at') }:</span><br/>
                            </div>
                            <div className={ 'col-3' }>
                                <span className={ 'label-info' }>{ t('plans.long_term_target_end_at') }:</span><br/>
                            </div>
                        </div>
                        <hr className="m-0"/>
                        <div className="pt-3 row margin-none padding-content-info">
                            <div className={ 'col-6' }>
                                <span className={ 'main-content-info' }>{ plan['long_term_target'] }</span>
                            </div>
                            <div className={ 'col-3' }>
                                <div className={ 'content ' }>
                                    { !!plan['long_term_target_start_at'] && moment.unix(plan['long_term_target_start_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                </div>
                            </div>
                            <div className={ 'col-3' }>
                                <div className={ 'content ' }>
                                    { !!plan['long_term_target_end_at'] && moment.unix(plan['long_term_target_end_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                </div>
                            </div>
                        </div>
                    </ComponentWeb>
                    <ComponentMobile>
                        <div style={ { padding: '0 30px' } }>
                            <span className={ 'main-content-info' }>{ plan['long_term_target'] }</span><br/>
                            <div className="pt-3 d-flex align-items-center">
                                <span className={ 'main-content-info' }>{ t('plans.long_term_target_start_at') }:</span><br/>
                                <div className="pl-2"/>
                                <div className={ 'content ' }>
                                    { !!plan['long_term_target_start_at'] && moment.unix(plan['long_term_target_start_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))
                                    }
                                </div>
                            </div>
                            <div className="pt-3 d-flex align-items-center">
                                <span className={ 'main-content-info' }>{ t('plans.long_term_target_end_at') }:</span><br/>
                                <div className="pl-2"/>
                                <div className={ 'content ' }>
                                    { !!plan['long_term_target_end_at'] &&  moment.unix(plan['long_term_target_end_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))
                                    }
                                </div>
                            </div>
                        </div>
                    </ComponentMobile>
                </div>
            </div>
        </div>
    );
}

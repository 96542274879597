import React                               from 'react';
import { KirokuForm, KirokuInputToSearch } from './index';
import { useTranslation }                  from 'react-i18next';

function KirokuInputSearcher(props) {

    const { t }                                = useTranslation();
    const { placeholder, id, isNotIconSearch } = props;
    return (
        <KirokuForm onSubmit={ props.onSubmit }>
            <KirokuInputToSearch { ...props } id={ id || 'KirokuInputSearcher' }
                                 placeholder={ placeholder || t('common.Search') }>
                <div id={ 'input-form-searcher' } hidden={ isNotIconSearch }>
                    <button className='fix-icon-input-search'>
                        <i className="fa fa-search " onClick={ props.onSubmit }/>
                    </button>
                </div>
            </KirokuInputToSearch>
        </KirokuForm>
    );
}

export default KirokuInputSearcher;

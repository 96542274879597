import { useDeletedContext }     from './DeleteProvider';
import { useCallback, useState } from 'react';
import { SET_RESULT_SEARCH }     from './deletedReducer';
import { resources }         from '@kiroku/api';
import conditionBase         from './ConditionBase';

export default function useDeleted() {
    const { deletedState, dispatch, ScreensComponent } = useDeletedContext();

    const {
              search
          } = resources.useSearch('Deleted', { type: 'survey', page: 1 });

    const {
              currentScreen,
              sort_by, order_by,
              name, title, pagination,
              result, order_by_type
          } = deletedState;

    const [loading, setLoading] = useState(false);

    const searchDeleted = useCallback((condition) => {
        setLoading(true);
        const conditionByScreen = conditionBase[ condition.type ](condition);
        search(conditionByScreen).then((res) => {
            setLoading(false);
            dispatch({
                ...conditionByScreen,
                type         : SET_RESULT_SEARCH,
                result       : res.result,
                currentScreen: ScreensComponent[ condition.type ],
                pagination   : {
                    currentPage: res.currentPage,
                    totalPage  : res.totalPage,
                    totalResult: res.totalResult,
                },
            });
        });

    }, [search, dispatch, ScreensComponent]);

    return {
        loading,
        searchDeleted,
        currentScreen,
        pagination,
        condition: {
            type: currentScreen.name,
            sort_by, order_by,
            page: pagination.currentPage,
            name, title,
        },
        result, sort_by, order_by_type
    };
}

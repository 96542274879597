import { KirokuTable, TBody, THeader } from '../../../ui';
import moment                          from 'moment';
import React                           from 'react';
import { useTranslation }              from 'react-i18next';
import { useHistory }                  from 'react-router-dom';

export default function PlanTableWeb({ result }) {
    const { t }   = useTranslation();
    const history = useHistory();
    const now = moment().unix();

    return (
        <KirokuTable>
            <THeader>
                <tr>
                    <th style={{ fontSize: 14 }}>{t('plans.customer')}</th>
                    <th style={{ minWidth: 180, fontSize: 14 }}>{t('plans.startDate')}</th>
                    <th style={{ width: 180, fontSize: 14 }}>{t('plans.endDate')}</th>
                    <th style={{ fontSize: 14 }}>{t('plans.planStatus')}</th>
                    <th style={{ fontSize: 14, minWidth: 180 }}> {t('plans.statusReview')} </th>
                </tr>
            </THeader>
            <TBody>
                {result.map((plan) => {
                    return <tr key={plan.id}
                               data-href={'/plans/' + plan.id}
                               onClick={() => history.push('/plans/' + plan.id)}
                               className={'cursor-pointer'}
                    >
                        <td style={{ fontSize: 14 }}>
                            {(plan[ 'customer' ] && plan.customer_id) ? plan[ 'customer' ].name : ''}
                        </td>
                        <td style={{ whiteSpace: 'nowrap', fontSize: 14 }}>{ !!plan[ 'start_at' ] && moment.unix(plan[ 'start_at' ]).format(t('dateTimeFormat.YearMonthDateWithDay'))}</td>
                        <td style={{ whiteSpace: 'nowrap', fontSize: 14 }}>{ !!plan[ 'end_at' ] && moment.unix(plan[ 'end_at' ]).format(t('dateTimeFormat.YearMonthDateWithDay'))}</td>
                        <td style={{ whiteSpace: 'nowrap' }}>
                            <div hidden={plan.published_at || plan.review_at}>
                                <img src={`/assets/icon/iconDraft.svg`} alt="iconDraft.svg"/>
                                &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.draft')}</span>
                            </div>
                            <div hidden={!(plan.published_at && now < plan.start_at) || plan.review_at}>
                                <img src={`/assets/icon/iconBeforeStart.svg`} alt="iconBeforeStart.svg"/>
                                &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.beforeStart')}</span>
                            </div>
                            <div hidden={!(plan.published_at && now > plan.start_at && now < plan.end_at) || plan.review_at }>
                                <img src={`/assets/icon/iconRunning.svg`} alt="iconRunning.svg"/>
                                &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.running')}</span>
                            </div>
                            <div hidden={!(plan.published_at && now > plan.end_at) }>
                                <img src={`/assets/icon/iconFinish.svg`} alt="iconFinish.svg"/>
                                &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.finish')}</span>
                            </div>
                        </td>
                        <td>
                            <div hidden={plan.review_status !== 0}> {t('plans.unReview')}</div>
                            <div hidden={plan.review_status !== 1}> {t('plans.draftReview')}</div>
                            <div hidden={plan.review_status !== 2}> {t('plans.monitored')}</div>
                        </td>
                    </tr>;
                    },
                )}
            </TBody>
        </KirokuTable>
    );
}

import React               from 'react';
import useHeadingQuestion  from '../../Records/useSurvey/useHeadingQuestion';


export default function Message(props) {
    const { question } = props;

    const { content, heading } = useHeadingQuestion(question);

    return (<div className={'content-question-type'}>
        <div className='wraper-question none-border-question pt-2 pt-2'>
            <div className="background-question">
                {heading && <div className="text-overflow-elip font-heading-questions pb-10px">{ heading }</div> }
                <div className='padding-content-question'>
                    <span className={'font-content-question'}>{content}</span>
                </div>
            </div>
        </div>
    </div>);
}

import TemplateRecord   from './TemplateRecord';
import TemplateQuestion from './TemplateQuestion';
import TemplateOnly     from './TemplateOnly';
import CustomerRecord   from './CustomerRecord';
import CustomerQuestion from './CustomerQuestion';
import CustomerOnly     from './CustomerOnly';

const checkUseHeadingQuestion = (question) => {
    return (typeof question.heading !== 'undefined' && question.heading.trim()) ? question.heading : question.content;
};

export {
    TemplateRecord,
    TemplateQuestion,
    TemplateOnly,
    CustomerRecord,
    CustomerQuestion,
    CustomerOnly,
    checkUseHeadingQuestion,
};

import React, { useEffect, useState }           from 'react';
import { KirokuInput, KirokuInputSearchOnBlur } from '../../../../ui';
import { useTranslation }                       from 'react-i18next';
import { useCondition }                         from '../../useCondition';
import ComponentWeb                             from '../../../../ui/ComponentWeb';
import ComponentMobile                          from '../../../../ui/ComponentMobile';
import { useRecordContext }                     from '../../ConditionBuilder/RecordContext';

export default function KeywordsSearchCondition(props) {
    const { t }                     = useTranslation();
    const [keywords, setKeywords]   = useState('');
    const { updateRecordCondition } = useCondition();
    const { recordCondition }       = useRecordContext();

    useEffect(() => {
        setKeywords(recordCondition.answer_search || '');
    }, [recordCondition.answer_search]);

    return (
        <div className={ 'fix-icon-search placeholder-beta-color-757575' }>
            <ComponentWeb>
                <KirokuInputSearchOnBlur
                    style={ { height: 36, fontSize: 13 } }
                    placeholder={ t('RecordsBeta.keyword') }
                    value={ keywords }
                    onChange={ (event) => setKeywords(event.target.value) }
                    onBlur={ () => {
                    } }
                    onSubmit={ () => updateRecordCondition({ ...recordCondition, answer_search: keywords, page: 1 }) }
                    id={ props.id }
                />
            </ComponentWeb>
            <ComponentMobile>
                <KirokuInput
                    placeholder={ t('RecordsBeta.keyword') }
                    value={ keywords }
                    style={ { height: 36, fontSize: 13 } }
                    onChange={ (event) => setKeywords(event.target.value) }
                    onBlur={ () => updateRecordCondition({ ...recordCondition, answer_search: keywords, page: 1 }) }
                />
            </ComponentMobile>

        </div>
    );
}

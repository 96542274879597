import React, { useEffect, useState }          from 'react';
import { Menu, MenuButton, MenuItem, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

export default function KirokuDropdownSubMenu(props) {
    const {
              title, disabled, items, onSelect, onSelectChildren, subClassName, className
          } = props;

    const [selectedLabel, setSelectedLabel] = useState('');

    useEffect(() => {
        setSelectedLabel(title);
    }, [title]);

    return (
        <Menu menuButton={ <MenuButton className={ className || '' }
                                       disabled={ disabled }>{ selectedLabel }</MenuButton> }>
            { items.map((item, index) => {
                if (!item.subMenu.length) {
                    return (
                        <MenuItem key={ index }
                                  value={ item.value }
                                  onClick={ (event) => {
                                      onSelect(event.value);
                                  } }>{ item.component }</MenuItem>
                    );
                } else {
                    return (
                        <SubMenu label={ item.component } key={ index } styles={subClassName || ''}>
                            { item.subMenu.map((child, indexSub) => {
                                return (
                                    <MenuItem key={ indexSub }
                                              value={ child.value }
                                              onClick={ (event) => {
                                                  onSelectChildren(event.value);
                                              } }
                                    >
                                        { child.label }
                                    </MenuItem>
                                );
                            }) }
                        </SubMenu>
                    );
                }

            }) }
        </Menu>
    );
}

import React              from "react";
import { useTranslation } from "react-i18next";

export const useListGuardianship = () => {

    const {t} = useTranslation();
    const guardianshipTypes = [
        {
            id: 1,
            name: t('CustomerEditor.guardianshipTypes.Null'),
            type: t('CustomerEditor.guardianshipTypes.Null'),
            value: ''
        }, {
            id: 2,
            name: t('CustomerEditor.guardianshipTypes.Curatorship'),
            type: t('CustomerEditor.guardianshipTypes.Curatorship'),
            value: 'Curatorship'
        },
        {
            id: 3,
            name: t('CustomerEditor.guardianshipTypes.Guardianship'),
            type: t('CustomerEditor.guardianshipTypes.Guardianship'),
            value: 'Guardianship'
        },
        {
            id: 4,
            name: t('CustomerEditor.guardianshipTypes.Assistance'),
            type: t('CustomerEditor.guardianshipTypes.Assistance'),
            value: 'Assistance'
        },
        {
            id: 5,
            name: t('CustomerEditor.guardianshipTypes.Optional'),
            type: t('CustomerEditor.guardianshipTypes.Optional'),
            value: 'Optional'
        }
    ];

    const selectGuardianshipType = (guardianshipTypes) => {
        return guardianshipTypes.map(guardianshipType => ({
            value: guardianshipType.value,
            component: (
                <div>
                    <span className={'text-muted'}>{guardianshipType.type}</span>
                </div>
            ),
            label: guardianshipType.type
        }))
    };


    return [ selectGuardianshipType(guardianshipTypes) ]
}

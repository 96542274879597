import React, { useReducer } from 'react';
import ReportContext         from './ReportContext';
import ReportReducer         from './ReportReducer';

export default function ReportProvider({ initialCondition = null, children }) {

    const [condition, dispatch] = useReducer(ReportReducer, initialCondition, () => initialCondition);

    return (
        <ReportContext.Provider value={ { condition, dispatch } }>
            { children }
        </ReportContext.Provider>
    );
};

import React              from 'react';
import { useTranslation } from 'react-i18next';

export default function PlanStatusCondition(props) {
    const { t }                                    = useTranslation();
    const { overdate, draft, onSearchPlan, condition } = props;

    return (
        <div style={ { fontSize: 14, flexWrap: 'wrap' } } className={ 'd-flex  align-items-center' }>
            <div
                style={ { minWidth: 80 }}
                className={ `filter-plan-status-mb ${ (condition.draft.toString() === 'false' &&
                    condition.beforeStart.toString() === 'false' &&
                    condition.running.toString() === 'false' &&
                    condition.finished.toString() === 'false'
                ) ? 'active-tab' : '' }` }
                onClick={ () => {
                    onSearchPlan({
                        ...condition,
                        draft      : false,
                        beforeStart: false,
                        running    : false,
                        finished   : false,
                    })

                } }
            >
                <span className='items-condition'>
                    <img src={ `/assets/img/All.svg` } style={ { width: 17, height: 19 } }
                         alt={ 'All.svg ' }/>&nbsp;{ t('common.All') }
                </span>
            </div>
            <div
                style={ { minWidth: 115 }}
                className={ `filter-plan-status-mb ${ condition.draft.toString() === 'true' ? 'active-tab' : '' }` }
                onClick={ () => {
                    onSearchPlan({
                        ...condition,
                        draft      : true,
                        beforeStart: false,
                        running    : false,
                        finished   : false,
                    })
                } }
            >
                <span className='items-condition'>
                <img src={ `/assets/icon/iconDraft.svg` } alt="iconDraft.svg"/>
                    &nbsp;{ t('plans.draft') } { draft ? <>({ draft })</> : <div style={{ width: 20}}/> }
                </span>

            </div>
            <div
                style={ { minWidth: 155 }}
                className={ `filter-plan-status-mb ${ condition.beforeStart.toString() === 'true' ? 'active-tab' : '' }` }
                onClick={ () => {
                    onSearchPlan({
                        ...condition,
                        draft      : false,
                        beforeStart: true,
                        running    : false,
                        finished   : false,
                    })
                } }
            >
                    <span className='items-condition'>
                    <img src={ `/assets/icon/iconBeforeStart.svg` } alt="iconBeforeStart.svg"/>
                        &nbsp;{ t('plans.beforeStart') }
                    </span>

            </div>
            <div
                style={ { minWidth: 155 }}
                className={ `filter-plan-status-mb ${ condition.running.toString() === 'true' ? 'active-tab' : '' }` }
                onClick={ () => {
                    onSearchPlan({
                        ...condition,
                        draft      : false,
                        beforeStart: false,
                        running    : true,
                        finished   : false,
                    })
                } }
            >
                <span className='items-condition'>
                <img src={ `/assets/icon/iconRunning.svg` } alt="iconRunning.svg"/>
                    &nbsp;{ t('plans.running') }
                </span>

            </div>
            <div
                style={ { minWidth: 130 }}
                className={ `filter-plan-status-mb ${ condition.finished.toString() === 'true' ? 'active-tab' : '' }` }
                onClick={ () => {
                    onSearchPlan({
                        ...condition,
                        draft      : false,
                        beforeStart: false,
                        running    : false,
                        finished   : true,
                    })
                } }
            >
                    <span className='items-condition'>
                    <img src={ `/assets/icon/iconFinish.svg` } alt="iconFinish.svg"/>
                        &nbsp;{ t('plans.finish') } { overdate ? <>({ overdate })</> : '' }
                    </span>
            </div>
        </div>
    );
}

"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAssign = useAssign;
exports.useCreate = useCreate;
exports.useDelete = useDelete;
exports.useDetail = useDetail;
exports.useDetailWithParameters = useDetailWithParameters;
exports.useExport = useExport;
exports.useExportDocx = useExportDocx;
exports.useExportExcel = useExportExcel;
exports.useLazyLoad = useLazyLoad;
exports.useLockTake = useLockTake;
exports.usePatch = usePatch;
exports.useSearch = useSearch;
exports.useSearchWithPathname = useSearchWithPathname;
exports.useUnlockTake = useUnlockTake;
exports.useUpdate = useUpdate;
exports.useUpdateByAction = useUpdateByAction;
exports.useUpdateRole = useUpdateRole;
exports.useUpload = useUpload;
var _useClient = _interopRequireDefault(require("./useClient"));
var _react = require("react");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function useSearch(resourceName) {
  var defaultCondition = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var defaultPagination = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    page: 1
  };
  var client = (0, _useClient.default)();
  var _useState = (0, _react.useState)(defaultCondition),
    _useState2 = _slicedToArray(_useState, 2),
    condition = _useState2[0],
    setCondition = _useState2[1];
  var _useState3 = (0, _react.useState)(defaultPagination),
    _useState4 = _slicedToArray(_useState3, 2),
    pagination = _useState4[0],
    setPaginationCondition = _useState4[1];
  var _useState5 = (0, _react.useState)(false),
    _useState6 = _slicedToArray(_useState5, 2),
    loading = _useState6[0],
    setLoading = _useState6[1];
  var _useState7 = (0, _react.useState)({
      result: [],
      currentPage: 1,
      totalPage: 1,
      totalResult: 0
    }),
    _useState8 = _slicedToArray(_useState7, 2),
    resources = _useState8[0],
    setResources = _useState8[1];
  var _useState9 = (0, _react.useState)(null),
    _useState10 = _slicedToArray(_useState9, 2),
    error = _useState10[0],
    setError = _useState10[1];
  var search = (0, _react.useCallback)(function (condition) {
    var pathname = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var cancelRef = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    setLoading(true);
    setCondition(condition);
    return client[resourceName].search(_objectSpread({}, condition), pathname, cancelRef).then(function (resourceSearchingResult) {
      setLoading(false);
      setResources(resourceSearchingResult);
      setPaginationCondition({
        currentPage: resourceSearchingResult.currentPage,
        totalPage: resourceSearchingResult.totalPage,
        totalResult: resourceSearchingResult.totalResult
      });
      setError(null);
      return resourceSearchingResult;
    }).catch(function (error) {
      setLoading(false);
      setError(error);
      return Promise.reject(error);
    });
  }, [client, resourceName]);
  var cancelAPISearch = (0, _react.useCallback)(function () {}, []);
  return {
    condition: condition,
    loading: loading,
    result: resources.result,
    pagination: pagination,
    search: search,
    nextPage: function nextPage() {
      if (loading) {
        return null;
      }
      search(_objectSpread(_objectSpread({}, condition), {}, {
        page: parseInt(condition.page) + 1
      }));
    },
    prevPage: function prevPage() {
      if (loading) {
        return null;
      }
      search(_objectSpread(_objectSpread({}, condition), {}, {
        page: parseInt(condition.page) - 1
      }));
    },
    gotoPage: function gotoPage(page) {
      search(_objectSpread(_objectSpread({}, condition), {}, {
        page: page
      }));
    },
    error: error,
    cancelAPISearch: cancelAPISearch
  };
}
function useCreate(resourceName) {
  var client = (0, _useClient.default)();
  var _useState11 = (0, _react.useState)(null),
    _useState12 = _slicedToArray(_useState11, 2),
    error = _useState12[0],
    setError = _useState12[1];
  var _useState13 = (0, _react.useState)(false),
    _useState14 = _slicedToArray(_useState13, 2),
    isLoading = _useState14[0],
    setIsLoading = _useState14[1];
  var _useState15 = (0, _react.useState)(null),
    _useState16 = _slicedToArray(_useState15, 2),
    result = _useState16[0],
    setResult = _useState16[1];
  var create = (0, _react.useCallback)(function (creatingData) {
    var pathname = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    setIsLoading(true);
    return client[resourceName].create(creatingData, pathname).then(function (response) {
      setIsLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setIsLoading(false);
      setError(error);
      return Promise.reject(error);
    });
  }, [client, resourceName]);
  return [result, create, isLoading, error];
}
function useUpdate(resourceName) {
  var client = (0, _useClient.default)();
  var _useState17 = (0, _react.useState)(null),
    _useState18 = _slicedToArray(_useState17, 2),
    error = _useState18[0],
    setError = _useState18[1];
  var _useState19 = (0, _react.useState)(false),
    _useState20 = _slicedToArray(_useState19, 2),
    isLoading = _useState20[0],
    setIsLoading = _useState20[1];
  var _useState21 = (0, _react.useState)(null),
    _useState22 = _slicedToArray(_useState21, 2),
    result = _useState22[0],
    setResult = _useState22[1];
  var update = (0, _react.useCallback)(function (id, updateData) {
    setIsLoading(true);
    return client[resourceName].update(id, updateData).then(function (response) {
      setIsLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setIsLoading(false);
      setError(error);
      return Promise.reject(error);
    });
  }, [client, resourceName]);
  return [result, update, isLoading, error];
}
function useDelete(resourceName) {
  var client = (0, _useClient.default)();
  var _useState23 = (0, _react.useState)(null),
    _useState24 = _slicedToArray(_useState23, 2),
    error = _useState24[0],
    setError = _useState24[1];
  var _useState25 = (0, _react.useState)(false),
    _useState26 = _slicedToArray(_useState25, 2),
    isLoading = _useState26[0],
    setIsLoading = _useState26[1];
  var _useState27 = (0, _react.useState)(null),
    _useState28 = _slicedToArray(_useState27, 2),
    result = _useState28[0],
    setResult = _useState28[1];
  var actionDelete = (0, _react.useCallback)(function (id) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    setIsLoading(true);
    return client[resourceName].delete(id, params).then(function (response) {
      setIsLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setIsLoading(false);
      setError(error);
      return Promise.reject(error);
    });
  }, [resourceName, client]);
  return [result, actionDelete, isLoading, error];
}
function useDetail(resourceName) {
  var client = (0, _useClient.default)();
  var _useState29 = (0, _react.useState)(null),
    _useState30 = _slicedToArray(_useState29, 2),
    error = _useState30[0],
    setError = _useState30[1];
  var _useState31 = (0, _react.useState)(true),
    _useState32 = _slicedToArray(_useState31, 2),
    isLoading = _useState32[0],
    setIsLoading = _useState32[1];
  var _useState33 = (0, _react.useState)(null),
    _useState34 = _slicedToArray(_useState33, 2),
    result = _useState34[0],
    setResult = _useState34[1];
  var loadDetail = (0, _react.useCallback)(function (id) {
    setIsLoading(true);
    return client[resourceName].detail(id).then(function (response) {
      setIsLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setIsLoading(false);
      setError(error);
      return Promise.reject(error);
    });
  }, [resourceName, client]);
  return [result, loadDetail, isLoading, error];
}
function useUpload(resourceName) {
  var client = (0, _useClient.default)();
  var _useState35 = (0, _react.useState)(null),
    _useState36 = _slicedToArray(_useState35, 2),
    error = _useState36[0],
    setError = _useState36[1];
  var _useState37 = (0, _react.useState)(false),
    _useState38 = _slicedToArray(_useState37, 2),
    isLoading = _useState38[0],
    setIsLoading = _useState38[1];
  var _useState39 = (0, _react.useState)(null),
    _useState40 = _slicedToArray(_useState39, 2),
    result = _useState40[0],
    setResult = _useState40[1];
  var upload = (0, _react.useCallback)(function (type, file) {
    setIsLoading(true);
    return client[resourceName].uploadFile(type, file).then(function (response) {
      setIsLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setIsLoading(false);
      setError(error);
      return Promise.reject(error);
    });
  }, [resourceName, client]);
  return [result, upload, isLoading, error];
}
function useLockTake(resourceName) {
  var client = (0, _useClient.default)();
  var lockTake = (0, _react.useCallback)(function (id) {
    return client[resourceName].lock(id);
  }, [resourceName, client]);
  return [lockTake];
}
function useUnlockTake(resourceName) {
  var client = (0, _useClient.default)();
  var unlock = (0, _react.useCallback)(function (id) {
    return client[resourceName].unlock(id);
  }, [resourceName, client]);
  return [unlock];
}
function useUpdateByAction(resourceName) {
  var client = (0, _useClient.default)();
  var _useState41 = (0, _react.useState)(null),
    _useState42 = _slicedToArray(_useState41, 2),
    error = _useState42[0],
    setError = _useState42[1];
  var _useState43 = (0, _react.useState)(false),
    _useState44 = _slicedToArray(_useState43, 2),
    isLoading = _useState44[0],
    setIsLoading = _useState44[1];
  var _useState45 = (0, _react.useState)(null),
    _useState46 = _slicedToArray(_useState45, 2),
    result = _useState46[0],
    setResult = _useState46[1];
  var update = (0, _react.useCallback)(function (actionName, updateData) {
    setIsLoading(true);
    return client[resourceName].updateByAction(actionName, updateData).then(function (response) {
      setIsLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setIsLoading(false);
      setError(error);
      return Promise.reject(error);
    });
  }, [client, resourceName]);
  return [result, update, isLoading, error];
}
function useAssign(resourceName) {
  var client = (0, _useClient.default)();
  var _useState47 = (0, _react.useState)(null),
    _useState48 = _slicedToArray(_useState47, 2),
    error = _useState48[0],
    setError = _useState48[1];
  var _useState49 = (0, _react.useState)(false),
    _useState50 = _slicedToArray(_useState49, 2),
    loading = _useState50[0],
    setLoading = _useState50[1];
  var _useState51 = (0, _react.useState)(null),
    _useState52 = _slicedToArray(_useState51, 2),
    result = _useState52[0],
    setResult = _useState52[1];
  var assignments = (0, _react.useCallback)(function (requestBodyData) {
    setLoading(true);
    return client[resourceName].assignments(requestBodyData).then(function (response) {
      setLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setLoading(false);
      setError(error);
      return Promise.reject(error);
    });
  }, [client, resourceName]);
  return [assignments, loading, result, error];
}
function useUpdateRole(resourceName) {
  var client = (0, _useClient.default)();
  var _useState53 = (0, _react.useState)(null),
    _useState54 = _slicedToArray(_useState53, 2),
    error = _useState54[0],
    setError = _useState54[1];
  var _useState55 = (0, _react.useState)(false),
    _useState56 = _slicedToArray(_useState55, 2),
    loading = _useState56[0],
    setLoading = _useState56[1];
  var _useState57 = (0, _react.useState)(null),
    _useState58 = _slicedToArray(_useState57, 2),
    result = _useState58[0],
    setResult = _useState58[1];
  var updateRole = (0, _react.useCallback)(function (requestUpdateRole) {
    setLoading(true);
    return client[resourceName].setRole(requestUpdateRole).then(function (response) {
      setLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setError(error);
      setLoading(false);
      return Promise.reject(error);
    });
  }, [client, resourceName]);
  return [updateRole, loading, result, error];
}
function useSearchWithPathname(resourceName, pathname) {
  var client = (0, _useClient.default)();
  var _useState59 = (0, _react.useState)(null),
    _useState60 = _slicedToArray(_useState59, 2),
    error = _useState60[0],
    setError = _useState60[1];
  var _useState61 = (0, _react.useState)(false),
    _useState62 = _slicedToArray(_useState61, 2),
    loading = _useState62[0],
    setLoading = _useState62[1];
  var _useState63 = (0, _react.useState)(null),
    _useState64 = _slicedToArray(_useState63, 2),
    result = _useState64[0],
    setResult = _useState64[1];
  var search = (0, _react.useCallback)(function (condition) {
    setLoading(true);
    return client[resourceName].search(condition, pathname).then(function (response) {
      setLoading(false);
      setResult(response);
      return response;
    }).catch(function (err) {
      setLoading(false);
      setError(err);
      return Promise.reject(err);
    });
  }, [client, resourceName, pathname]);
  return [result, search, loading, error];
}
function useExport(resourceName) {
  var client = (0, _useClient.default)();
  var _useState65 = (0, _react.useState)(null),
    _useState66 = _slicedToArray(_useState65, 2),
    error = _useState66[0],
    setError = _useState66[1];
  var _useState67 = (0, _react.useState)(false),
    _useState68 = _slicedToArray(_useState67, 2),
    loading = _useState68[0],
    setLoading = _useState68[1];
  var _useState69 = (0, _react.useState)(null),
    _useState70 = _slicedToArray(_useState69, 2),
    result = _useState70[0],
    setResult = _useState70[1];
  var exports = (0, _react.useCallback)(function (type, request) {
    setLoading(true);
    return client[resourceName].exportDocument(type, request).then(function (response) {
      setLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setError(error);
      setLoading(false);
      return Promise.reject(error);
    });
  }, [client, resourceName]);
  return [exports, loading, result, error];
}
function useExportDocx(resourceName) {
  var client = (0, _useClient.default)();
  var _useState71 = (0, _react.useState)(null),
    _useState72 = _slicedToArray(_useState71, 2),
    error = _useState72[0],
    setError = _useState72[1];
  var _useState73 = (0, _react.useState)(false),
    _useState74 = _slicedToArray(_useState73, 2),
    loading = _useState74[0],
    setLoading = _useState74[1];
  var _useState75 = (0, _react.useState)(null),
    _useState76 = _slicedToArray(_useState75, 2),
    result = _useState76[0],
    setResult = _useState76[1];
  var exports = (0, _react.useCallback)(function (pathname, requestBody, requestParams) {
    setLoading(true);
    return client[resourceName].postRequest(pathname, requestBody, requestParams).then(function (response) {
      setLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setError(error);
      setLoading(false);
      return Promise.reject(error);
    });
  }, [client, resourceName]);
  return [exports, loading, result, error];
}
function useDetailWithParameters(resourceName) {
  var client = (0, _useClient.default)();
  var _useState77 = (0, _react.useState)(null),
    _useState78 = _slicedToArray(_useState77, 2),
    error = _useState78[0],
    setError = _useState78[1];
  var _useState79 = (0, _react.useState)(false),
    _useState80 = _slicedToArray(_useState79, 2),
    loading = _useState80[0],
    setLoading = _useState80[1];
  var _useState81 = (0, _react.useState)(null),
    _useState82 = _slicedToArray(_useState81, 2),
    result = _useState82[0],
    setResult = _useState82[1];
  var loadDetail = (0, _react.useCallback)(function (id, params) {
    setLoading(true);
    return client[resourceName].detail(id, params).then(function (response) {
      setLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setLoading(false);
      setError(error);
      return Promise.reject(error);
    });
  }, [resourceName, client]);
  return [result, loadDetail, loading, error];
}
function useExportExcel(resourceName) {
  var client = (0, _useClient.default)();
  var _useState83 = (0, _react.useState)(null),
    _useState84 = _slicedToArray(_useState83, 2),
    error = _useState84[0],
    setError = _useState84[1];
  var _useState85 = (0, _react.useState)(false),
    _useState86 = _slicedToArray(_useState85, 2),
    loading = _useState86[0],
    setLoading = _useState86[1];
  var _useState87 = (0, _react.useState)(null),
    _useState88 = _slicedToArray(_useState87, 2),
    result = _useState88[0],
    setResult = _useState88[1];
  var exports = (0, _react.useCallback)(function (pathname) {
    setLoading(true);
    return client[resourceName].exportExcel(pathname).then(function (response) {
      setLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setError(error);
      setLoading(false);
      return error;
    });
  }, [client, resourceName]);
  return [exports, loading, result, error];
}
function useLazyLoad(resourceName) {
  var client = (0, _useClient.default)();
  var _useState89 = (0, _react.useState)(null),
    _useState90 = _slicedToArray(_useState89, 2),
    error = _useState90[0],
    setError = _useState90[1];
  var _useState91 = (0, _react.useState)(true),
    _useState92 = _slicedToArray(_useState91, 2),
    isLoading = _useState92[0],
    setIsLoading = _useState92[1];
  var _useState93 = (0, _react.useState)(null),
    _useState94 = _slicedToArray(_useState93, 2),
    result = _useState94[0],
    setResult = _useState94[1];
  var lazyLoad = (0, _react.useCallback)(function (id) {
    setIsLoading(true);
    return client[resourceName].lazyLoad(id).then(function (response) {
      setIsLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setIsLoading(false);
      setError(error);
      return Promise.reject(error);
    });
  }, [resourceName, client]);
  return [result, lazyLoad, isLoading, error];
}
function usePatch(resourceName) {
  var client = (0, _useClient.default)();
  var _useState95 = (0, _react.useState)(null),
    _useState96 = _slicedToArray(_useState95, 2),
    error = _useState96[0],
    setError = _useState96[1];
  var _useState97 = (0, _react.useState)(false),
    _useState98 = _slicedToArray(_useState97, 2),
    isLoading = _useState98[0],
    setIsLoading = _useState98[1];
  var _useState99 = (0, _react.useState)(null),
    _useState100 = _slicedToArray(_useState99, 2),
    result = _useState100[0],
    setResult = _useState100[1];
  var patch = (0, _react.useCallback)(function (id, requestBody) {
    setIsLoading(true);
    return client[resourceName].patch(id, requestBody).then(function (response) {
      setIsLoading(false);
      setResult(response);
      return response;
    }).catch(function (error) {
      setIsLoading(false);
      setError(error);
      return Promise.reject(error);
    });
  }, [client, resourceName]);
  return [result, patch, isLoading, error];
}
import lodash               from 'lodash';
import { getFlowQuestions } from '../HelperComponent/getQuestions';

export function getChoices(questions){
    let choices = [];
    questions.forEach( item => {
        item.choices.forEach( choice => {
            if (choices.indexOf(choice.answer) < 0){
                choices.push(choice.answer);
            }
        })
    });
    return choices;
}

export default function useSingleChart(records, question) {
    records = records.map(record => getFlowQuestions(record.survey_template));
    let questions = records.reduceRight((questions, questionsNext) => questions.concat(questionsNext));
    questions = questions.filter(q => q.content === question.content && q.type === question.type && q.type === 'Single');

    const choices = getChoices(questions);
    let data      = choices.map((choice, index) => {
        return [
            choice,
            lodash.countBy(questions, function (question) {
                return question.answers.is_choice;
            })[ index ] || 0,
        ];
    });
    data.unshift(['Task', 'Single chart.']);

    return {
        data,
        isNotDataAnalysis: questions.filter(question => question.answers.answer).length === 0
    };
}

import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import {
    ComponentMobile, ComponentWeb, KirokuDropDownSearch, KirokuInputDate, KirokuSectionDropdownShowMore,
    KirokuSectionDropdownShowMoreMobile,
}                                     from '../../ui';
import { resources }                  from '@kiroku/api';
import useDropDownData                from '../useDropDownData';
import { getGroupedOptions }          from '../Converter';
import useTeamForSelectCustomer       from './useTeamForSelectCustomer';
import ModalSelectCustomerByTeam      from './ModalSelectCustomerByTeam';

function CustomerSelectedDropdown({ customers, handleSelectCustomer, customerResults }) {
    const [currentTags, setCurrentTags]          = useState([]);

    useEffect(() => {
        setCurrentTags(customers);
    }, [customers]);

    const { t }                      = useTranslation();

    let customersOptions = getGroupedOptions('Customer', customerResults);

    const handleSearchTags = () => {
        handleSelectCustomer(currentTags)
    };

    const handleChangeTags = (options) => {
        setCurrentTags(options);
        handleSelectCustomer(options);
    };

    return (
        <div>
            <ComponentWeb>
                <KirokuSectionDropdownShowMore
                    isSearchTags
                    tagType={ 'Customer' }
                    value={ currentTags }
                    options={ customersOptions }
                    handleSearchTags={ handleSearchTags }
                    onChange={ handleChangeTags }
                    placeholder={ t('Customer.placeholderSearch') }
                />
            </ComponentWeb>
            <ComponentMobile>
                <KirokuSectionDropdownShowMoreMobile
                    isSearchTags
                    tagType={ 'Customer' }
                    value={ currentTags }
                    options={ customersOptions }
                    handleSearchTags={ handleSearchTags }
                    onChange={ handleChangeTags }
                    placeholder={ t('Customer.placeholderSearch') }
                />
            </ComponentMobile>
        </div>
    );
}

function SurveySelectDropdown({ survey, handleSelectSurvey, customers }) {
    const { search, result, cancelAPISearch }             = resources.useSearch('Template', { type: 'all' });

    useEffect(() => {
        search({ type: 'all' });
        return () =>  {
            cancelAPISearch();
        }
    }, [search, cancelAPISearch]);
    const [surveyOfCustomer, setSurveyOfCustomer] = useState([]);

    useEffect(() => {
        if (result) {
            setSurveyOfCustomer(result);
        }
    }, [result]);

    useEffect(() => {
        if (customers.length) {
            search({
                type: 'customers',
                customers: customers.map(customer => customer.id)
            })
        }
    }, [customers,search]);

    const { dropdownSelectSurvey } = useDropDownData();
    const { t }                    = useTranslation();

    return (
        <KirokuDropDownSearch title={survey.title || t('Dashboard.SurveyTemplates')}
                              items={dropdownSelectSurvey(surveyOfCustomer)}
                              disabled={!customers.length}
                              onSelect={handleSelectSurvey}
        />
    );
}

function StartDate({ startDate, handleOnChangeDateTime }) {
    const {t} = useTranslation();
    return (
        <KirokuInputDate
            dateFormat={t('dateTimeFormat.YearMonthDate')}
            className={'date-chart-search'}
            inputProps={{ disabled: false, readOnly: true }}
            onChange={(event) => handleOnChangeDateTime(event, 'startDate')}
            value={startDate}
        />
    );
}

function EndDate({ endDate, handleOnChangeDateTime }) {
    const {t} = useTranslation();
    return (
        <KirokuInputDate
            dateFormat={t('dateTimeFormat.YearMonthDate')}
            className={'date-chart-search'}
            inputProps={{ disabled: false, readOnly: true }}
            onChange={(event) => handleOnChangeDateTime(event, 'endDate')}
            value={endDate}
        />
    );
}

function DropdownMultiSelectUser(props) {

    const {handleOnSelect, optionsItem, currentTags, setCurrentTags} = props;
    const {t} = useTranslation();

    const handleSearchTags = () => {
        handleOnSelect(currentTags);
    };

    const handleChangeTags = (options) => {
        setCurrentTags(options);
    };

    return (
        <div>
            <ComponentWeb>
                <KirokuSectionDropdownShowMore
                    isSearchTags
                    tagType={ 'Staff' }
                    value={ currentTags }
                    options={ optionsItem }
                    handleSearchTags={ handleSearchTags }
                    onChange={ handleChangeTags }
                    placeholder={ t('staff.placeholderSearch') }
                />
            </ComponentWeb>
            <ComponentMobile>
                <KirokuSectionDropdownShowMoreMobile
                    isSearchTags
                    tagType={ 'Staff' }
                    value={ currentTags }
                    options={ optionsItem }
                    handleSearchTags={ handleSearchTags }
                    onChange={ handleChangeTags }
                    placeholder={ t('staff.placeholderSearch') }
                />
            </ComponentMobile>
        </div>

    )
}

function DropdownUserTagSearchCondition({ handleSelectCreator, handleSelectUpdater, t, currentCreatorTags, setCurrentCreatorTags, currentUpdaterTags, setCurrentUpdaterTags}) {
    const { search, result, cancelAPISearch } = resources.useSearch('User', { type: 'all' });

    useEffect(() => {
        search({ type: 'all' });
        return () => {
            cancelAPISearch() ;
        }
    }, [search, cancelAPISearch]);
    let optionsItem = getGroupedOptions('User', result);



    return (<>
        <div>
            <div className="label-text-form">{t('common.Creator')}</div>
            <DropdownMultiSelectUser
                currentTags={currentCreatorTags}
                setCurrentTags={setCurrentCreatorTags}
                handleOnSelect={(creator) => {
                    handleSelectCreator(creator, currentUpdaterTags);
                }}
                optionsItem={optionsItem}
            />
        </div>
        <div>
            <div className="label-text-form">{t('common.updater')}</div>
            <DropdownMultiSelectUser
                currentTags={currentUpdaterTags}
                setCurrentTags={setCurrentUpdaterTags}
                handleOnSelect={(updater) => {
                    handleSelectUpdater(updater, currentCreatorTags);
                }}
                optionsItem={optionsItem}
            />
        </div>
    </>)
}

export default function AnalysisCondition(props) {
    const { t } = useTranslation();
    const { search, result, cancelAPISearch } = resources.useSearch('Customer', { type: 'all' });

    useEffect(() => {
        search({ type: 'all' });
        return () =>  {
            cancelAPISearch();
        }
    }, [search, cancelAPISearch]);


    const {
              startDate, endDate, handleOnChangeDateTime,
              survey, customers, handleSelectCustomer, handleSelectSurvey,
              handleSelectUpdater, handleSelectCreator
          } = props;

    const { teams }                                             = useTeamForSelectCustomer();
    const [currentCreatorTags, setCurrentCreatorTags]           = useState([]);
    const [currentUpdaterTags, setCurrentUpdaterTags]           = useState([]);
    const [showModalSelectCustomer, setShowModalSelectCustomer] = useState(false);

    return <div className={'padding-responsive'}>
        <div className={''}>
            <div>
                <div>
                    <div className="pb-10px">
                        <div className="d-flex">
                            <div>
                                {t('common.Customer')}
                            </div>
                            <div className={ 'cursor-pointer' } style={ { color: '#1891eb' } }
                                 onClick={() => setShowModalSelectCustomer(!showModalSelectCustomer)}
                            >
                                &nbsp;{ t('RecordList.SelectCustomerOfTeam') }
                            </div>
                        </div>
                    </div>
                    <CustomerSelectedDropdown
                        customers={customers}
                        customerResults={result}
                        handleSelectCustomer={handleSelectCustomer}/>
                </div>
            </div>
            <div>
                <div className="label-text-form">{t('Dashboard.SurveyTemplates')}</div>
                <SurveySelectDropdown
                    survey={survey}
                    handleSelectSurvey={(event) => {
                        handleSelectSurvey(event, currentCreatorTags, currentUpdaterTags)
                    }}
                    customers={customers}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-md-6'>
                <div className="label-text-form">{t('Surveys.startDate')}</div>
                <StartDate
                    startDate={startDate}
                    handleOnChangeDateTime={(event, dateKey) => {
                        handleOnChangeDateTime(event, dateKey, currentCreatorTags, currentUpdaterTags);
                    }}/>
            </div>
            <div className='col-md-6'>
                <div className="label-text-form">{t('Surveys.endDate')}</div>
                <EndDate
                    endDate={endDate}
                    handleOnChangeDateTime={(event, dateKey) => {
                        handleOnChangeDateTime(event, dateKey, currentCreatorTags, currentUpdaterTags);
                    }}
                />
            </div>
        </div>
        <ModalSelectCustomerByTeam
            show={ showModalSelectCustomer }
            customers={ result }
            customersSelected={ customers }
            closeModal={ () => setShowModalSelectCustomer(!showModalSelectCustomer) }
            teams={ getGroupedOptions('Team', teams)}
            customerSelectedInModal={ customers => {
                handleSelectCustomer(getGroupedOptions('Customer', customers.filter(customer => customer.isChecked)))
            }}
        />
        <DropdownUserTagSearchCondition
            t={t}
            setCurrentCreatorTags={setCurrentCreatorTags}
            currentCreatorTags={currentCreatorTags}

            setCurrentUpdaterTags={setCurrentUpdaterTags}
            currentUpdaterTags={currentUpdaterTags}

            handleSelectUpdater={handleSelectUpdater}
            handleSelectCreator={handleSelectCreator}
        />
    </div>;
}

import { useCallback }                 from 'react';
import { useDispatch, useSelector }    from 'react-redux';
import { KIROKU_SWITCH_STATUS_NOTIFY } from '../applicationReducer';

export default function useNotification() {

    const dispatch = useDispatch();

    const isOpenNotification  = useSelector(state => state.getIn(['app', 'kirokuaiReducer', 'isOpenNotification']));
    const messageNotification = useSelector(state => state.getIn(['app', 'kirokuaiReducer', 'messageNotification']));
    const notificationType    = useSelector(state => state.getIn(['app', 'kirokuaiReducer', 'notificationType']));
    const isNotAutomaticCloseNotice = useSelector(state => state.getIn(['app', 'kirokuaiReducer', 'isNotAutomaticCloseNotice']));

    const notification = useCallback((messageNotification = '', notificationType = '') => {
        dispatch({
            type: KIROKU_SWITCH_STATUS_NOTIFY,
            messageNotification, notificationType
        })
    }, [dispatch]);

    return {notification, isOpenNotification, messageNotification, notificationType, isNotAutomaticCloseNotice};
}

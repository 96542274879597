import {resources} from "@kiroku/api";
import moment      from "moment";

export default function useSearchCertificationExpiration() {
    const {
              result, search,
              pagination,
              cancelAPISearch,
              condition
          } = resources.useSearch('Customer', {
        type        : 'certification_expiration',
        page        : 1,
        status      : 'active',
        certification_expiration: moment().startOf('month').startOf('day').unix(),
    });


    return {
        resultSearchExpired : result,
        searchCertificationExpired: search,
        paginationExpired: pagination,
        cancelAPISearch,
        conditionCertificationExpiration: condition
    }
}

import React, { useEffect }                            from 'react';
import UploadFie                                       from './Documents/UploadFile/UploadFile';
import { ComponentMobile, ComponentWeb } from '../../../ui';
import DocumentsWeb                                    from './Documents/DocumentUI/DocumentsWeb';
import DocumentProvider                                from './Documents/DocumentProvider';
import DocumentsMobile                                 from './Documents/DocumentUI/DocumentsMobile';
import { resources }                                   from '@kiroku/api';

export default function DocumentTab({ customer, show }) {
    const { result, search, loading, pagination, cancelAPISearch } = resources.useSearch('Document');

    useEffect(() => {
        if (show) {
            search({ type_id: customer.id, page: 1, type: 'customer'});
        }
        return () => {
            cancelAPISearch();
        }
    }, [customer.id, search, show, cancelAPISearch]);


    return (
        <div hidden={!show}>
            <DocumentProvider
                documentsData={{
                    documents: result,
                    ...pagination,
                    loading
                }}
                customer={customer}
            >
                <UploadFie/>
                <ComponentWeb>
                    <DocumentsWeb/>
                </ComponentWeb>

                <ComponentMobile>
                    <DocumentsMobile/>
                </ComponentMobile>
            </DocumentProvider>
        </div>
    );
}

import { resources }   from '@kiroku/api';
import { useCallback } from 'react';

export default function useSearchTeamOfUser(userId) {
    const { search, loading } = resources.useSearch('Team', { type: 'all' });
    const searchTeamByUserId = useCallback(() => {
        return search({
            type: 'all', userId
        })
    }, [userId, search])

    return {searchTeamByUserId, loadingSearchTeamByUserId: loading};
}

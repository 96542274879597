import React, { useState }   from 'react';
import { useTranslation }    from 'react-i18next';
import { Link }              from 'react-router-dom';
import { authentication }    from '@kiroku/api';
import { componentQuestion } from '../QuestionTypes';
import ModalSelectCustomers  from '../../Records/copyRecords/ModalSelectCustomers';
import {
    KirokuModal, KirokuModalBody,
    KirokuButton, KirokuModalFooter, KirokuButtonLink,
}                            from '../../../ui';
import { useDeleteRecord }   from '../../Records/recordList/DeleteRecord/useDeleteRecord';
import useNotification       from '../../Layout/useNotification';
import { useHistory }        from 'react-router-dom';

function Questions({ questions }) {
    return (<div id='question-review'>
        { questions.map((question, index) => {
            const Question = componentQuestion[question.type];
            if (question.type !== 'Message') {
                return <Question
                    key={ index }
                    question={ { ...question, index: index } }
                    value={ question.answers }
                />;
            }
            return null;
        }) }
    </div>);

}


export default function RecordTab({ record, isView }) {
    const { t }                                           = useTranslation();
    const { user }                                        = authentication.useAuthenticatedContext();
    const { deleteRecords }                               = useDeleteRecord();
    const { notification }                                = useNotification();
    const history                                         = useHistory();
    const [isShowing, setIsShowing]                       = useState(false);
    const [isShowConfirmDeleted, setIsShowConfirmDeleted] = useState(false);
    const handleDelRecord                                 = () => {
        deleteRecords(record.id, record.customer).then(() => {
            setIsShowConfirmDeleted(!isShowConfirmDeleted);
            notification(t('message.DeleteSuccess'), 'success');
            history.goBack();
        }).catch((err) => {
            console.log(err);
            setIsShowConfirmDeleted(!isShowConfirmDeleted);
        });
    };

    return (<div hidden={ !isView }>
            <div className="d-flex justify-content-between">
                { user['is_admin'] ?
                    <div style={ { height: 62 } } onClick={ () => {
                    } }
                         hidden={ !user['is_admin'] }
                    >
                        <KirokuButtonLink
                            onClick={ () => {
                                setIsShowConfirmDeleted(!isShowConfirmDeleted);
                            } }
                            style={ {
                                border : 'solid 1px red',
                                padding: '0 16px',
                                color  : 'red',
                                height : 36,
                                fontSize: 14
                            } }
                        >
                            { t('RecordsBeta.delete') }
                        </KirokuButtonLink>
                    </div> : <div/>
                }
                <div className={'d-flex'} style={ { height: 62 } }>
                    <Link to={ `/records/edit/${ record.id }` }
                          onClick={ () => {
                              window.history.previous = window.location.pathname;
                          } }
                    >
                        <div className=' edit-records-button cursor-pointer font-size-14'
                             style={ { float: 'right' } }
                        >
                            { t('RecordsBeta.edit') }
                        </div>
                    </Link>
                    <div className="pl-3">
                        <KirokuButtonLink
                            onClick={ () => setIsShowing(true) }
                            style={ {
                                border : 'solid 1px #249533',
                                padding: '0 16px',
                                color  : '#249533',
                                height : 36,
                                fontSize: 14
                            } }

                        >
                            { t('createRecord.Copy') }
                        </KirokuButtonLink>

                    </div>
                </div>
            </div>

            <Questions questions={ record.survey_template.flowedQuestions || record.survey_template.questions }/>

            {
                isShowing && (
                    <ModalSelectCustomers
                        show={ isShowing }
                        templates={ record['survey_id'] }
                        record={ record.id }
                        onClose={ () => setIsShowing(false) }
                    />
                )
            }

            <KirokuModal show={ isShowConfirmDeleted }
                         onClick={ () => {
                             setIsShowConfirmDeleted(!isShowConfirmDeleted);
                         } }>
                <KirokuModalBody>
                    <div className='text-center pt-3'>
                        <b>{ t('RecordList.AreYouWantDelete') }</b>
                        <p style={ { fontSize: 16 } }>{ record.title }</p>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={ () => {
                        setIsShowConfirmDeleted(!isShowConfirmDeleted);
                    } }
                                  color={ 'white' }
                    >
                        { t('common.Cancel') }
                    </KirokuButton>
                    <KirokuButton onClick={ () => {
                        handleDelRecord();
                    } } color={ 'primary' }>
                        { t('common.Confirm') }
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>

        </div>
    );
}

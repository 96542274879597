import React              from 'react';
import {
    KirokuCardWeb, TBody, KirokuAge,
    ComponentMobile, KirokuCard, KirokuCardList,
    THeader, ComponentWeb, KirokuLoader,
    KirokuNotFound, SearchNotFound, KirokuTable,
}                         from '../../../ui';
import { useTranslation } from 'react-i18next';

import If       from '../../If';
import { Link } from 'react-router-dom';
import moment         from 'moment';

function PagNotFound() {
    const { t } = useTranslation();
    return <SearchNotFound
        description={t('NullPage.customerForTag2')}
    />;
}

export default function MonitoringTermComponent({ condition, result, loadingAPI }) {
    const { t } = useTranslation();

    return (<div hidden={condition.type !== 'monitor_term'}>
        <KirokuCardWeb>
            <ComponentWeb>
                <div className='p-2'>
                    <KirokuLoader loading={loadingAPI}>
                        <KirokuNotFound isResults={result.length}
                                        ComponentSearchNoFound={PagNotFound}
                        >
                            <KirokuTable>
                                <THeader>
                                    <tr>
                                        <th style={{minWidth: 200 }}>{t('Customer.Name')}</th>
                                        <th style={{minWidth: 100 }}>{t('Customer.Age')}</th>
                                        <th style={{minWidth: 180 }}>{t('CustomerEditor.KindOfCertificate')}</th>
                                        <th style={{minWidth: 180 }}>{t('CustomerEditor.monitoringTerms')}</th>
                                    </tr>
                                </THeader>
                                <TBody>
                                    {result.map((customer, index) => {
                                        return <tr key={index}>
                                            <td style={{ wordBreak: 'keep-all' }}>
                                                <If statement={condition.status !== 'deactive'}
                                                    otherwise={customer.name}>
                                                    {() => <Link
                                                        to={`/customers/${customer.id}`}>{customer.name} &nbsp;
                                                    </Link>}
                                                </If>
                                            </td>
                                            <td style={{ width: 50 }}><KirokuAge
                                                dob={customer[ 'date_of_birth' ]}/>
                                            </td>
                                            <td>{customer.certificate.map(item => t(`Customer.certificate.${item}`)).join(', ')}</td>
                                            <td style={{ whiteSpace: 'nowrap' }}>
                                                {moment.unix(condition.monitor_term).format(t('dateTimeFormat.YearMonth'))}
                                            </td>
                                        </tr>;
                                    })}
                                </TBody>
                            </KirokuTable>
                        </KirokuNotFound>
                    </KirokuLoader>
                </div>
            </ComponentWeb>

            <ComponentMobile>
                <KirokuCardList
                    headerLeft={<span>{t('common.Name')}</span>}
                    headerRight={''}
                >
                    <KirokuLoader loading={loadingAPI}>
                        <KirokuNotFound isResults={result.length}>
                            <div>
                                {result.map((customer, index) => {
                                    let translateCertificate = customer.certificate.map(item => {
                                        return t(`Customer.certificate.${item}`);
                                    });
                                    let certificate          = translateCertificate.join(', ');
                                    return <div key={index}>
                                        <KirokuCard action={''}>
                                            <div style={{ wordBreak: 'keep-all' }}>
                                                <If statement={condition.status === 'active'}
                                                    otherwise={customer.name}>
                                                    {() => <Link
                                                        to={`/customers/${customer.id}`}>{customer.name} &nbsp;</Link>}
                                                </If>
                                                <div className={'pt-2'}> {t('Customer.Age')}: &nbsp;
                                                    <KirokuAge
                                                        dob={customer[ 'date_of_birth' ]}/>
                                                </div>
                                                <div
                                                    className={'pt-2'}>{t('CustomerEditor.KindOfCertificate')}: &nbsp;{certificate}</div>
                                                <div
                                                    className={'pt-2'}>
                                                    {t('CustomerEditor.monitoringTerms')}: &nbsp;{moment.unix(condition.monitor_term).format(t('dateTimeFormat.YearMonth'))}
                                                </div>
                                            </div>
                                        </KirokuCard>
                                        <hr className={'margin-none'}/>
                                    </div>;
                                })}
                            </div>
                        </KirokuNotFound>
                    </KirokuLoader>
                </KirokuCardList>
            </ComponentMobile>
        </KirokuCardWeb>
    </div>);
}

import React                                  from 'react';
import { useTranslation }                     from 'react-i18next';
import { KirokuLogicApplicated, KirokuRadio } from '../../../../../ui';
import { useHeadingQuestion }                 from '../../../../Records/useSurvey';
import { useScrollContext }                   from '../../../../../ui/Scrolling';

export default function YesNoQuestion(props) {

    const { question, headerNextQuestion, value = '', isSurveyDetail } = props;

    const { optionalContentOrHeading } = useHeadingQuestion(question);
    const { scrollTo }                 = useScrollContext();

    const {t} = useTranslation();
    return (<div className={'pb-3'}>
        <div className='wraper-question'>
            <div className="background-question">
                <div className='number-question d-flex justify-content-between align-items-center'>
                    <div
                        className="question-number text-overflow-elip ">
                        {optionalContentOrHeading}
                    </div>
                    <div hidden={!isSurveyDetail} className={'float-right'}>
                        <KirokuLogicApplicated  padding={'5px 5px 5px 7px'} question={question}/>
                    </div>
                </div>
                <span className='content-name-question'>{question.content}</span>
                <div
                    hidden={typeof question.nextQuestion !== 'number' || !isSurveyDetail}
                    className='text-info text-overflow-elip pl-10 scroll-link-question'
                    onClick={() => scrollTo(`question-survey-${question.nextQuestion}`, 202)}
                >
                    <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                    <span className={'font-size-14'}> {headerNextQuestion.general}</span>
                </div>
            </div>
            <div className='answer-question'>
                <KirokuRadio onChange={() => {}}
                             checked={value === 'Yes'}
                             readOnly
                >
                    <div style={{ cursor : 'no-drop'}}>{t('createRecord.Yes')}</div>
                </KirokuRadio>
                <div
                    hidden={ typeof question.nextQuestionYes !== 'number' || !isSurveyDetail}
                    className={'text-info text-overflow-elip pl-10 scroll-link-question'}
                    onClick={() => scrollTo(`question-survey-${question.nextQuestionYes}`, 202)}
                >
                    <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                    <span className={'font-size-14'}> {headerNextQuestion.yes}</span>
                </div>
                <KirokuRadio onChange={() => {}}
                             checked={value === 'No'}
                             readOnly
                >
                    <div style={{ cursor : 'no-drop'}}>{t('createRecord.No')}</div>
                </KirokuRadio>
                <div
                    hidden={ typeof question.nextQuestionNo !== 'number' || !isSurveyDetail}
                    className={'text-info text-overflow-elip pl-10  scroll-link-question'}
                    onClick={() => scrollTo(`question-survey-${question.nextQuestionNo}`, 202)}
                >
                    <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                    <span className={'font-size-14'}> {headerNextQuestion.no}</span>
                </div>
            </div>
        </div>
    </div>);
}

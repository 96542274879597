import { useClient }             from '@kiroku/api';
import { useCallback, useState } from 'react';
import moment               from 'moment';

export default function () {
    const { axios }               = useClient();
    const [countResult, setCount] = useState({
        monitoringTerm         : 0, serviceEndMonth: 0,
        planEndMonth           : 0, reportMonth: 0,
        certificationExpiration: 0,
    });
    const getMonthlyCount         = useCallback((startMonth, endMonth) => {
        const types = [{
            kindGetCount: 'monitoringTerm',
            condition: { monitor_term : moment.unix(startMonth).format('M/YYYY') }
        }, {
            kindGetCount: 'planEndMonth',
            condition: { startMonth, endMonth }
        }, {
            kindGetCount: 'reportMonth',
            condition: { report_month : moment.unix(startMonth).format('M') }
        },{
            kindGetCount:  'certificationExpiration',
            condition: { certification_expiration : moment.unix(startMonth).format('M/YYYY') }
        },{
            kindGetCount:  'serviceEndMonthCount',
            condition: {
                services_end_date : moment.unix(startMonth).format('YYYY-MM')
        }}];

        Promise.all(
            types.map(({ condition, kindGetCount }) =>
                axios.get('/monthly-list-count', {
                    params: { ...condition, type: kindGetCount }
                }))
        ).then(([monitoringTerm, planEndMonth, reportMonth, certificationExpiration, serviceEndMonth]) => {
            setCount({
                monitoringTerm: monitoringTerm.result,
                planEndMonth: planEndMonth.result, reportMonth: reportMonth.result,
                certificationExpiration: certificationExpiration.result,
                serviceEndMonth: serviceEndMonth.result,
            });
        });
    }, [axios]);
    return {
        getMonthlyCount,
        countResult,
    };
}

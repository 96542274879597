import React              from 'react';
import { useTranslation } from 'react-i18next';
import { KirokuLabel }    from '../ui';

export default function UseDropDownData() {
    const { t } = useTranslation();

    const statusIcon = {
        'open'       : 'open.svg',
        'in-progress': 'in-progress.svg',
        'resolved'   : 'resolved.svg',
        'done'       : 'resolved.svg',
    };

    const dataAchievementStatus = [
        {
            value: '',
            label: <div className={'d-flex font-size-14'}>
                &nbsp;
            </div>,
            component: (
                <div className={'d-flex font-size-14'}>
                    &nbsp;
                </div>
            )
        },{
            value: 'resolved',
            label: <div className={'d-flex font-size-14'}>
                {t('achievementStatus.resolved')}
                <img src={'/assets/img/resolved.svg'} className={'pl-2'} alt={'resolved.svg'}/>
            </div>,
            component: (
                <div className={'d-flex font-size-14'}>
                    {t('achievementStatus.resolved')}
                    <img src={'/assets/img/resolved.svg'} className={'pl-1 pt-1'} alt={'resolved.svg'}/>
                </div>
            )
        },{
            value: 'in-progress',
            label: <div className={'d-flex font-size-14'}>
                {t('achievementStatus.in-progress')}
                <img src={'/assets/img/in-progress.svg'} className={'pl-2'} alt={'in-progress.svg'}/>
            </div>,
            component: (<div className={'d-flex font-size-14'}>
                {t('achievementStatus.in-progress')}
                <img src={'/assets/img/in-progress.svg'} className={'pl-1 pt-1'} alt={'in-progress.svg'}/>
            </div>)
        },{
            value: 'open',
            label: <div className={'d-flex font-size-14'}>
                {t('achievementStatus.open')}
                <img src={'/assets/img/open.svg'} className={'pl-2'} alt={'open.svg'}/>
            </div>,
            component: (<div className={'d-flex font-size-14'}>
                {t('achievementStatus.open')}
                <img src={'/assets/img/open.svg'} className={'pl-1 pt-1'} alt={'open.svg'}/>
            </div>)
        }
    ]

    const dataActionStatus = [
        {
            value: '',
            label: <div className={'d-flex font-size-14'}>
                &nbsp;
            </div>,
            component: (<div className={'d-flex font-size-14'}>
                &nbsp;
            </div>)
        },{
            value: 'done',
            label: <div className={'d-flex font-size-14'}>
                {t('status.done')}
                <img src={'/assets/img/resolved.svg'} className={'pl-2'} alt={'resolved.svg'}/>
            </div>,
            component: (<div className={'d-flex font-size-14'}>
                {t('status.done')}
                <img src={'/assets/img/resolved.svg'} className={'pl-1 pt-1'} alt={'resolved.svg'}/>
            </div>)
        },{
            value: 'in-progress',
            label: <div className={'d-flex font-size-14'}>
                {t('status.in-progress')}
                <img src={'/assets/img/in-progress.svg'} className={'pl-2'} alt={'in-progress.svg'}/>
            </div>,
            component: (<div className={'d-flex font-size-14'}>
                {t('status.in-progress')}
                <img src={'/assets/img/in-progress.svg'} className={'pl-1 pt-1'} alt={'in-progress.svg'}/>
            </div>)
        },{
            value: 'open',
            label: (<div className={'d-flex font-size-14'}>
                {t('status.open')}
                <img src={'/assets/img/open.svg'} className={'pl-2'} alt={'open.svg'}/>
            </div>),
            component: (<div className={'d-flex font-size-14'}>
                {t('status.open')}
                <img src={'/assets/img/open.svg'} className={'pl-1 pt-1'} alt={'open.svg'}/>
            </div>)
        }
    ]

    const dataTargetStatus = [
        {
            value: '',
            label: <div className={'d-flex font-size-14'}> &nbsp;</div>,
            component: (<div className={'d-flex font-size-14'}>
                &nbsp;
            </div>)
        },{
            value: 'resolved',
            label: <div className={'d-flex font-size-14'}>
                {t('status.resolved')}
                <img src={'/assets/img/resolved.svg'} className={'pl-2'} alt={'resolved.svg'}/>
            </div>,
            component: (<div className={'d-flex font-size-14'}>
                {t('status.resolved')}
                <img src={'/assets/img/resolved.svg'} className={'pl-1 pt-1'} alt={'resolved.svg'}/>
            </div>)
        }, {
            value: 'in-progress',
            label: <div className={'d-flex font-size-14'}>
                {t('status.in-progress')}
                <img src={'/assets/img/in-progress.svg'} className={'pl-2'} alt={'in-progress.svg'}/>
            </div>,
            component: (<div className={'d-flex font-size-14'}>
                {t('status.in-progress')}
                <img src={'/assets/img/in-progress.svg'} className={'pl-1 pt-1'} alt={'in-progress.svg'}/>
            </div>)
        },{
            value: 'open',
            label: (<div className={'d-flex font-size-14'}>
                {t('status.open')}
                <img src={'/assets/img/open.svg'} className={'pl-2'} alt={'open.svg'}/>
            </div>),
            component: (<div className={'d-flex font-size-14'}>
                {t('status.open')}
                <img src={'/assets/img/open.svg'} className={'pl-1 pt-1'} alt={'open.svg'}/>
            </div>)
        },
    ]

    const workingForm         = [
        {
            value    : 'Full_time',
            component: (<div>{t('common.Full_time')}</div>),
            label    : t('common.Full_time'),
        },
        {
            value    : 'Part_time',
            component: (<div>{t('common.Part_time')}</div>),
            label    : t('common.Part_time'),
        },
    ];
    const selectRole          = [
        {
            value    : 'staff',
            component: (<div>{t('common.Staff')}</div>),
            label    : t('common.Staff'),
        },
        {
            value    : 'leader',
            component: (<div>{t('role.leader')}</div>),
            label    : t('role.leader'),
        },
    ];
    const kindOfQualification = [
        {
            key  : t('common.dropdown.default.Social_worker'),
            label: t('common.dropdown.Social_worker'),
            value: t('common.dropdown.Social_worker'),
        },
        {
            key  : t('common.dropdown.default.Psychiatric_Sosial_Worker'),
            value: t('common.dropdown.Psychiatric_Sosial_Worker'),
            label: t('common.dropdown.Psychiatric_Sosial_Worker'),
        },
        {
            key  : t('common.dropdown.default.Care_Worker'),
            label: t('common.dropdown.Care_Worker'),
            value: t('common.dropdown.Care_Worker'),
        },
        {
            key  : t('common.dropdown.default.Nurse'),
            label: t('common.dropdown.Nurse'),
            value: t('common.dropdown.Nurse'),
        },
        {
            key  : t('common.dropdown.default.Childminder'),
            label: t('common.dropdown.Childminder'),
            value: t('common.dropdown.Childminder'),
        },
        {
            key  : t('common.dropdown.default.Occupational_therapist'),
            label: t('common.dropdown.Occupational_therapist'),
            value: t('common.dropdown.Occupational_therapist'),
        },
        {
            key  : t('common.dropdown.default.Psychotherapist'),
            label: t('common.dropdown.Psychotherapist'),
            value: t('common.dropdown.Psychotherapist'),
        },
        {
            key  : t('common.dropdown.default.Doctor'),
            label: t('common.dropdown.Doctor'),
            value: t('common.dropdown.Doctor'),
        },

    ];

    const labelAction = {
        create: <KirokuLabel
            color='primary'
            style={{ padding: '2px 4px' }}
        >
            {t('common.create')}
        </KirokuLabel>,
        update: <KirokuLabel
            color='success'
            style={{ padding: '2px 4px' }}
        >
            {t('common.Update')}
        </KirokuLabel>,
        delete: <KirokuLabel
            color='danger'
            style={{ padding: '2px 4px' }}
        >
            {t('common.Delete')}
        </KirokuLabel>,
        view  : <KirokuLabel
            color='light'
            style={{ padding: '2px 4px' }}
        >
            {t('common.ViewDetail')}
        </KirokuLabel>,
    };

    const optionalDeletedScreens = [
        {
            value    : 'surveys',
            component: (<div className={'font-size-14'} style={{margin: 0, color: '#202528'}}>{t(`DeletedList.surveys`)}</div>),
            label    : t(`DeletedList.surveys`)
        },
        {
            value    : 'records',
            component: (<div className={'font-size-14'} style={{margin: 0, color: '#202528'}}>{t(`DeletedList.records`)}</div>),
            label    : t(`DeletedList.records`)
        },
        {
            value    : 'customers',
            component: (<div className={'font-size-14'} style={{margin: 0, color: '#202528'}}>{t(`DeletedList.customers`)}</div>),
            label    : t(`DeletedList.customers`)
        },
        {
            value    : 'users',
            component: (<div className={'font-size-14'} style={{margin: 0, color: '#202528'}}>{t(`DeletedList.users`)}</div>),
            label    : t(`DeletedList.users`)
        }
    ];


    const dropdownSelectCustomer = (customers) => {
      return customers.map(customer => {
          return {
              value: customer.id,
              component: (
                  <div><h6>{customer.name}</h6></div>
              ),
              label: customer.name
          }
      })
    };

    const dropdownSelectSurvey = (surveys) => {
        if (!surveys.length) {
            return [{
                component: (
                    <div><h6>{t('Analysis.noSurveyTemplate')}</h6></div>
                ),
                label: t('Analysis.noSurveyTemplate')
            }];
        }
      return surveys.map(survey => {
          return {
              value: survey.id,
              component: (
                  <div><h6>{survey.title}</h6></div>
              ),
              label: survey.title
          }
      })
    };

    const optionDateItems     = [
        {
            value    : 'All date',
            component: (<div>{t('RecordList.allDate')} </div>),
            label    : t('RecordList.allDate'),
        },
        {
            value    : 'day',
            component: (<div>{t('RecordList.day')} </div>),
            label    : t('RecordList.day'),
        },
        {
            value    : 'week',
            component: (<div>{t('RecordList.week')}</div>),
            label    : t('RecordList.week'),
        },
        {
            value    : 'month',
            component: (<div>{t('RecordList.month')}</div>),
            label    : t('RecordList.month'),
        },
    ];
    const optionFileTypeItems = [
        {
            value    : 'all',
            component: (<div>{t('Surveys.AllType')}</div>),
            label    : t('common.All'),
        },
        {
            value    : 'pdf',
            component: (<div>pdf</div>),
            label    : 'pdf',
        },
        {
            value    : 'xlsx',
            component: (<div>xlsx</div>),
            label    : 'xlsx',
        },
        {
            value    : 'png',
            component: (<div>png</div>),
            label    : 'png',
        },
        {
            value    : 'jpeg',
            component: (<div>jpeg</div>),
            label    : 'jpeg',
        },
        {
            value    : 'jpg',
            component: (<div>jpg</div>),
            label    : 'jpg',
        },
    ];

    const dropdownLanguages = [
        {
            value    : 'ja',
            component: (<div>日本語</div>),
            label    : (<span><img src={`/assets/img/globe.svg`} alt={'globe'}/> &nbsp;日本語</span>),
        },
        {
            value    : 'en',
            component: (<div>English</div>),
            label    : (<span><img src={`/assets/img/globe.svg`} alt={'globe'}/> &nbsp;English</span>) ,
        }
    ];

    return {
        dropdownSelectSurvey,
        dropdownSelectCustomer,
        kindOfQualification,
        selectRole,
        workingForm,
        labelAction,
        optionalDeletedScreens,
        optionDateItems,
        optionFileTypeItems,
        dataActionStatus,
        dataAchievementStatus,
        dataTargetStatus,
        statusIcon,
        dropdownLanguages
    };
}

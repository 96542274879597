import React              from 'react';
import useHeadingQuestion from '../../Records/useSurvey/useHeadingQuestion';
import { KirokuInput }    from '../../../ui';

export default function NumberQuestion(props) {

    const { question, value = '' } = props;

    const { heading, t } = useHeadingQuestion(question);

    return (<div className={'content-question-type'}>
        <div className='wraper-question none-border-question pt-2 pt-2'>
            <div className="background-question">
                {heading && <div className="text-overflow-elip font-heading-questions pb-10px">{ heading }</div> }
                <div className='padding-content-question'>
                    <span className={'font-content-question'}>
                        {question.unrequited ? t('createRecord.option'):''}{question.content}
                    </span>
                </div>
            </div>
            <div className='answer-question padding-16-answer-question'>
                <KirokuInput readOnly
                             style={{ color: '#0a1d42', cursor: 'no-drop' }}
                             value={value || ''}/>
            </div>
        </div>
    </div>);
}

import React                    from 'react';
import { useTranslation }       from 'react-i18next';
import { Link }                 from 'react-router-dom';
import moment                   from 'moment';

export default function CardRecordDetailMobile({ record }) {
    const { t } = useTranslation();

    return (
        <div >
            <div style={{ textAlign: 'center' }} className='font-customer-name-detail'>
                <div>
                    <p className={'label-record-beta'} style={{ margin: 0, fontSize: 16 }}>
                        <span className="label-name">
                            <Link to={`/customers/${record.customer.id}`}>
                            {record.customer.name} </Link>
                        </span>
                        <span className="font-name-san">{t('RecordsBeta.noun')}</span>
                    </p>
                </div>
                <div className='pt-2' style={{color: '#091e42', fontSize: 16}}>
                    {record.title}
                </div>
            </div>
            <div className='font-label-draft' style={{ color: '#202528' }}>
                <div className='d-flex justify-content-center'>
                    <div style={{ width: 'max-content' }}>
                        <div className="d-flex justify-content-start pt-2">
                            <div
                                className={'font-text-card-detail'}
                                style={{ textAlign: 'end', padding: 0, minWidth: 112 }}>{t('RecordsBeta.servicedAt')} : &nbsp;</div>
                            <div style={{ padding: 0 }}>
                                <span className={'font-text-card-detail'} >
                                    { moment.unix(record.serviced_at).format(record.has_time ? t('dateTimeFormat.YearMonthDateWithHour') :t('dateTimeFormat.YearMonthDateWithDay'))}
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

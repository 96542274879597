import React from 'react';

export default function Terms() {
    return <div className='policy-page A4 pt-3'>
        <h2 className="font-weight-header align-header">利用規約</h2>
        <br/>
        <div className={'fontSize-content'} style={{ marginBottom: 0 }}>
            本利用規約（以下「本規約」といいます。）には、ピットアウト株式会社（以下、「当社」といいます。）が提供する業務記録サービス「kirokuai」（以下「本サービス」といいます。）の提供条件及び当社とユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
        </div>
        <hr/>
        <h5 className="font-weight-header">第1条（適用)</h5>
        <ol>
            <li>本規約は、本サービスの提供条件及び本サービスの利用に関する当社とユーザーとの間の権利義務関係を定めることを目的とし、登録ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。</li>
            <li>当社が当社ウェブサイト上で掲載する本サービス利用に関するルール、当社がユーザーと別途合意した契約、当社がユーザーに対し交付、配信、掲示した文書等（ヘルプ、注意書きなども含む。以下、これらを総称して「個別規約」といいます。）は、本規約の一部を構成するものとします。</li>
            <li>本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第2条（定義）</h5>
        <div className={'fontSize-content align-content'}>本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。</div>
        <div>
            <ol>
                <ol className={'number-list'}>
                    <li>「利用契約」とは、本規約を契約条件として当社と登録ユーザーの間で締結される、本サービスの利用契約を意味します。</li>
                    <li>「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。</li>
                    <li>「当社ウェブサイト」とは、そのドメインが「https://kiroku.ai」である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。</li>
                    <li>「契約者」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人または法人を意味します。</li>
                    <li>「ユーザー」とは、本サービスを利用する個々の利用者を意味し、契約者の役職員、契約者の認めたゲストを含みます。</li>
                    <li> 「外部サービス」とは、当社の提供するサービス以外であって、当社のサービスと連携するサービスを意味します。</li>
                    <li> 「ユーザー情報」とは、当社プライバシーポリシーに定める登録情報、記録等のデータ、端末情報その他の本サービスの利用に関しユーザーから収集する情報を意味します。</li>
                    <li> 「アカウント」とは、後記する管理アカウント、従業員アカウント、ゲストアカウントの総称を意味します。</li>
                    <li> 「管理者アカウント」とは、当社が契約者に付与し、本サービスにおけるアカウント全体の管理者権限を有するアカウントです。</li>
                    <li> 「従業員アカウント」とは、契約者の従業員に対し、当社または契約者が付与するアカウントで、管理アカウント以外のものを意味します。</li>
                    <li>「ゲストアカウント」とは、契約者の外部の者に対し、契約者が一時的に付与するアカウントであって、契約者が適宜に機能を制限することができるものを意味します。</li>
                    <li>「一般アカウント」とは、従業員アカウントとゲストアカウントの総称を意味します。</li>
                </ol>
            </ol>
        </div>
        <hr/>
        <h5 className="font-weight-header">第3条（登録）</h5>
        <ol>
            <li>
                本サービスの利用を希望する者（以下「利用希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
            </li>
            <li>
                当社は、当社の基準に従って、第１項に基づいて登録申請を行った利用希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者の契約者としての登録は、当社が本項の通知を行ったことをもって完了したものとします。
            </li>
            <li>
                前項に定める登録の完了時に、サービス利用契約が契約者と当社の間に成立し、契約者は本サービスを本規約に従い利用することができるようになります。
            </li>
            <li>
                当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
            </li>
            <ol className={'number-list'}>
                <li>契約者が第１項の方法によらずに利用の申し込みをした場合</li>
                <li>当社に提供した登録情報の全部または一部につき虚偽、誤記または記載漏れがあった場合</li>
                <li>未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合</li>
                <li>登録申請者が第23条（反社会的勢力等の排除）1項各号に該当する者である場合</li>
                <li>過去当社との契約に違反した者またはその関係者であると当社が判断した場合</li>
                <li>第11条（本サービスの停止等）に定める措置を受けたことがある場合</li>
                <li>当社サービスと競合するサービスを提供する者及びその者と資本関係のあり、あるいは役職員を共通とする者である場合</li>
                <li>その他、登録を適当でないと当社が判断した場合</li>
            </ol>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第４条（試用）</h5>
        <ol>
            <li>当社は、利用希望者の申請があった場合には、当該利用希望者に対して、同一の利用希望者につき１回に限り、本サービスの試用として、当社の許容する期間、内容、範囲で無償あるいは割り引いて本サービスを利用させることができることとします（以下、それぞれ、「試用期間」「試用内容」「試用範囲」といいます。）。</li>
            <li>利用希望者は、本サービスの試用を希望する場合には、当社に対し、試用を希望する旨の申請を行うこととします。</li>
            <li>当社は、当社の基準に従って、前項の申請を行った利用希望者の試用の可否を判断し、試用を認める場合には、その旨を利用希望者に通知します。利用希望者は、当社からの通知を受領した日から起算し、試用期間が終了するまでの間、本サービスを試用することができます。</li>
            <li>利用希望者が試用期間終了までに第３条に基づく本サービスの利用の登録を申請しないときは、試用期間が終了後、本サービスを利用することができなくなります。</li>
            <li>利用希望者が試用するにあたっては、試用中の利用に関しては、本利用規約の定めが適用されることとします。</li>
            <li>利用希望者において、１回を超えて試用を行い、あるいは当社においてその疑いがあると認めた場合には、当社は、当該利用希望者による既に継続中の試用を即座に中止します。その場合において、利用希望者が本サービスを利用することにより享受した利益について、利用希望者は、当社に対し、返還すべき義務を負うものとします。</li>
            <li>当社は、利用希望者に対し、試用期間終了の通知ないし告知を行う義務を負わないものとします。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第5条（登録情報の変更）</h5>
        <p>契約者は、登録情報に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。</p>
        <hr/>
        <h5 className="font-weight-header">第6条（登録情報の利用）</h5>
        <ol>
            <li>当社は、契約者及びユーザが登録した登録情報又は外部サービスとの連携により取得した情報を前提として、本サービスを提供する場合があります。これらの情報の内容に虚偽、誤り、記載漏れ又は変更未了があったことにより契約者及びユーザーに損害が生じたとしても、当社は一切責任を負いません。</li>
            <li>ユーザーが登録情報の修正又は変更を怠ったことにより、当社からの通知が不到達となった場合、当該通知は、通常到達すべき時に到達したとみなします。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第7条（ユーザーの責務）</h5>
        <ol>
            <li>
                ユーザーは、自己の責任において、本サービスに関するアカウント（ユーザーID、パスワード）を適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
            </li>
            <li>
                当社は、アカウントの一致を確認した場合、アカウントを保有する者として登録されたユーザーが本サービスを利用したものとみなします。
            </li>
            <li>
                アカウントの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はユーザーが負うものとし、当社はこれによる一切の責任を負いません。
            </li>
            <li>
                ユーザーは、アカウントが盗用され又は第三者に使用されていることが判明した場合には、契約者及びユーザーは、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
            </li>
            <li>
                ユ―ザーは本サービスまたは当社ウェブサイトに関連して他のユーザーまたは第三者との間において生じた取引、連絡、紛争等について、契約者及びユーザー自身の責任によって解決するものとします。
            </li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第8条（外部サービス）</h5>
        <ol>
            <li>
                当社は、外部サービスと連携して本サービスを提供する場合があります。契約者及びユーザーは、外部サービスの利用にあたり、本規約のほか、外部サービスの定める利用規約その他の条件に従うものとします。
            </li>
            <li>
                外部サービスについては、当該サービスを提供する事業者が責任を負うものとし、当社は、外部サービスにつき、ユーザーの特定の目的への適合性、商品的価値、正確性、有用性、完全性、適法性、ユーザーに適用のある団体の内部規則等への適合性、及びセキュリティ上の欠陥、エラー、バグ又は不具合が存しないこと、並びに第三者の権利を侵害しないことについて、如何なる保証も行うものではなく、これらに起因する一切の責任を負いません。
            </li>
            <li>
                当社が、外部サービスと連携して本サービスを提供する場合の、ユーザ情報の取り扱いについては、別途定めるプライバシーポリシーの規定に従うものとします。
            </li>
        </ol>
        <hr/>
        <h5 className="font-weight-header"> 第9条（料金及び支払方法）</h5>
        <ol>
            <li>当社は、本サービス利用の対価として、別途当社が定め、当社が提示する料金表に基づく利用料金を計算し、契約者に対して請求を行うこととします。</li>
            <li>契約者は、前項の請求に基づき、同請求において指定された期日、方法により、当社に対し、利用料金を支払うものとします。同支払いにおいて、金融機関に支払う振込手数料その他の費用は、契約者の負担とします。</li>
            <li>当社は、前項に基づき、利用料金に変更がある場合には、変更が適用されるユーザーに対し、事前に通知するものとします。</li>
            <li>当社は、契約者の個別の承諾なく、利用料金の変更を行うことができるものとします。変更後の料金体系は、当社が前項の通知を行った月の翌月より適用されるものとします。</li>
            <li>当社は、第15条（本サービスの内容の変更、終了）第3項に基づく場合を除き、契約者が当社に支払った本サービスに関する一切の料金等を、いかなる事由によっても返還しません。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第10条（禁止事項）</h5>
        <ol>
            <li>
                契約者及びユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。また、以下の各号の行為を直接又は間接に惹起し、又は容易にしてはなりません。
            </li>
            <ol className={'number-list'}>
                <li> 本サービスの運営に支障を与える行為
                    <div>ア 本サービスのネットワークまたはシステム等又は当社サーバー等に過度な負荷をかけ、あるいは誤作動を誘引する行為</div>
                    <div>イ 本サービス、ネットワーク又はシステム等に対するリバースエンジニアリングその他の解析行為</div>
                    <div>ウ 当社のネットワーク又はシステム等に権限なく不正にアクセスし又は当社設備に蓄積された情報を不正に書き換え若しくは消去する行為</div>
                    <div>エ 当社のネットワーク又はシステム等を複製、譲渡、貸与又は改変する行為</div>
                    <div>オ その他、本サービスの運営を妨害し、または妨害するおそれのある行為</div>
                </li>
                <li> 本サービスを不正に利用する行為
                    <div>ア 同一のユーザーが複数のアカウントを利用する行為</div>
                    <div>イ 同意、不同意を問わず、本サービスの他のユーザーのアカウントを利用する行為</div>
                    <div>ウ 実在、架空を問わず第三者になりすます行為</div>
                    <div>エ 当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</div>
                </li>
                <li> 犯罪・法令違反に該当し又は該当しうる行為
                    <div>ア
                        当社、その他の第三者の知的財産権（著作権、特許権、実用新案権、商標権、意匠権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含む。）をいう。）、肖像権、プライバシー、名誉、信用、その他の権利または利益を侵害する行為、それらの者に対する誹謗中傷行為又はそのおそれがある行為</div>
                    <div>イ 当社、その他の第三者に対する詐欺等を行い、又はこれに関連する行為</div>
                    <div>ウ 犯罪行為又は反社会的行為を暗示、誘発、助長、推奨等する行為</div>
                    <div>エ 当社、若しくは他のユーザーに対し、虚偽、不完全、不正確な情報を提供し、又は虚偽の事実を申告する行為</div>
                    <div>オ コンピューター・ウィルスその他の有害なコンピューター・プログラムを作成、取得、保管、供与、行使する行為</div>
                    <div>カ その他法令に違反する行為または犯罪行為に関連する行為</div>
                </li>
                <li> 不適切な情報の送信
                    <div>以下の内容を含むデータの作成又は送信する行為</div>
                    <div>イ 過度に暴力的または残虐な表現を含む内容</div>
                    <div>ウ わいせつ、児童ポルノ又は児童虐待に相当する画像、文書等を含む内容</div>
                    <div>エ 未成年者に有害な内容</div>
                    <div>オ 連鎖販売取引（ネットワークビジネス、マルチ商法等）、無限連鎖講（ネズミ講）及びこれに類する内容、又はそれらを勧誘する内容</div>
                    <div>カ 反社会的な表現を含む内容</div>
                    <div>キ チェーンメール等の第三者への情報の拡散を求める内容</div>
                    <div>カ 反社会的な表現を含む内容</div>
                    <div>ク 当該ユーザー以外の他のユーザーに関する内容</div>
                    <div>ケ 布教及び宗教の勧誘を目的とする内容</div>
                    <div>コ 差別を助長する表現を含む内容</div>
                    <div>サ 閲覧者に不快感を与える内容</div>
                    <div>シ 自殺、自傷行為を助長する表現を含む内容</div>
                    <div>ス 薬物の不適切な利用を助長する表現を含む内容</div>
                    <div>セ 当該ユーザー以外の他のユーザーに関する内容</div>
                    <div>ソ 前⑴から⑶のいずれかの行為に該当し、あるいは各行為の一部に該当するなど本規約又は別途当社の定める掲載基準に違反し、あるいは違反するおそれのある内容</div>
                    <div>タ 公序良俗に反し、又は反するおそれのある内容</div>
                    <div>チ 上記アからトまでに掲げるほか、法令に抵触するおそれのある内容</div>
                    <div>ツ その他当社が不適切と判断する内容</div>
                </li>
                <li> その他
                    <div>ア 公序良俗に反する行為</div>
                    <div>イ 上記⑴から⑷まで及びアの行為を直接または間接に惹起し、または容易にする行為、及びこれを試みる行為</div>
                    <div>ウ その他、当社が不適切と判断する行為</div>
                </li>
            </ol>
            <li> 契約者及びユーザーは、前項の禁止行為を行わないことを表明し、保証するものとします。</li>
            <li> 当社は、第１項各号のいずれかに該当する行為がなされたと当社が判断した場合、前項への抵触の有無等を確認することができ、確認の上で当社が必要と考える措置をとることができるものとします。ただし、当社は、左記措置をとる義務を負うものではありません。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第11条（本サービスの停止等）</h5>
        <div>当社は、以下のいずれかに該当する場合には、契約者及びユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。</div>
        <ol className={'number-list'}>
            <li> 本サービスに係るコンピューター・システムの点検または保守作業を行う場合</li>
            <li> コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合</li>
            <li> 地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</li>
            <li> その他、当社が停止または中断を必要と判断した場合</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第12条（権利帰属）</h5>
        <ol>
            <li>当社ウェブサイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。</li>
            <li>契約者及びユーザーは、投稿データについて、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。</li>
            <li>契約者及びユーザーは、個人情報を除く投稿データについて、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。</li>
            <li>ユーザーは、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第13条（利用停止等の措置）</h5>
        <ol>
            <li>当社は、契約者及びユーザーが、以下の各号のいずれかの事由に該当し、又は該当するおそれがあると当社が判断した場合には、事前に通知または催告することなく、契約者に対し、データの全部若しくは一部の削除、本サービスの利用の一時停止若しくは制限又はアカウント削除、本サービス利用契約の解除等の措置（以下「利用停止等」といいます。）を講じることができます。</li>
            <ol className={'number-list'}>
                <li>本規約のいずれかの条項に違反した場合</li>
                <li>当社に提供された情報又は外部サービスとの連携により当社が取得したユーザーの情報の全部又は一部につき虚偽の事実があることが判明した場合</li>
                <li>支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合</li>
                <li>当社からの問い合わせその他の回答を求める連絡に対して90日間以上応答がない場合</li>
                <li>未成年者、成年被後見人、被保佐人又は被補助人のいずれかであって、法定代理人、後見人､保佐人又は補助人の同意等を得ていない場合</li>
                <li>第3条第4項各号に該当する場合</li>
                <li>その他、前各号に類する事由により当社が本サービスの利用または登録ユーザーとしての登録の継続を適当でないと判断した場合</li>
            </ol>
            <li>当社は、本サービスの解約後も、契約者及びユーザーが当社に提供した情報を保有・利用することができるものとします。</li>
            <li>当社は、ユーザーが第１項各号に該当し又は該当するおそれがあると当社が判断した場合には、ユーザーに対し、期間を定めて、違反行為の中止、送信した情報の自発的な削除・訂正等を求めることがあり、ユーザーは、これに対応しなければならないこととします。</li>
            <li>当社は、本条に基づき当社が行った措置によりユーザーに生じた不利益及び損害について一切の責任を負いません。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第14条（解約）</h5>
        <ol>
            <li>契約者は、当社所定の手続の完了により、本サービスを解約することができます。</li>
            <li>解約後の利用者情報の取扱いについては、別途定めるプライバシーポリシーの規定に従うものとします。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第15条（本サービスの内容の変更、終了）</h5>
        <ol>
            <li>当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。</li>
            <li>当社が本サービスの提供を終了する場合、当社は、ユーザーに対し、終了の90日以上前に当社ウェブサイト等において、告知します。</li>
            <li>当社は、サービスを終了する場合、既に支払われている利用料金のうち、終了する本サービスについて提供しない日数に対応する金額を日割り計算にてユーザーに返還するものとします。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第16条（保証の否認）</h5>
        <ol>
            <li>当社は、本サービス及び本サービスを通じて提供されるコンテンツその他一切の情報につき、ユーザーの特定の目的への適合性、期待する機能、商用的価値、正確性（記録テンプレート及びその有効性を含みますが、これらに限られません。）、有用性、完全性、適法性、ユーザーやユーザーに適用のある団体の内部規則等への適合性、継続的に利用できること、及びセキュリティ上の欠陥、エラー、バグ又は不具合が存しないこと、並びに第三者の権利を侵害しないことについて明示又は目次を問わず、如何なる保証も行いません。</li>
            <li>当社は、本サービスがすべての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、ユーザーは予め了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第17条（責任制限、免責）</h5>
        <ol>
            <li>当社は、本サービスを監視する義務を負いません。本サービスを利用したことにより発生した、ユーザーに対し、故意または重過失がある場合を除き一切の責任を負いません。</li>
            <li>ユーザーは、外部サービスの利用規約及び運用方針の変更等に伴い、本サービスの一部又は全部の利用が制限される可能性があることを予め了承するものとします。</li>
            <li>本サービスに関し、ユーザーと他の第三者との間で紛争が生じた場合、ユーザーは、直ちにその旨を当社に通知するとともに、自己の責任と費用においてこれを解決するものとし、当社はこれに一切関与せず、何ら責任を負わないものとします。</li>
            <li>当社は本サービスの提供にあたり、本サービスの欠陥、本サービスの提供の停止および終了、情報の削除および変更、並びにそれらが原因で発生した契約者及びユーザーの損害に対し、当社に故意または重過失がある場合を除き一切の責任を負いません。</li>
            <li>本規約に関して、当社がユーザーに対して負う損害賠償責任の範囲は、その原因の如何を問わず、当該事由が生じた日を含む月の前月末日を起算日として過去１年間に、当該ユーザーが当社に対して本サービスを利用するために支払った利用料金の金額を上限とするものとします。また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第18条（秘密保持）</h5>
        <div
            className={' font-size-content align-content'}>契約者及びユーザーは、本サービスに関連して当社が契約者及びユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。
        </div>
        <hr/>
        <h5 className="font-weight-header">第19条（ユーザー情報の取扱い）</h5>
        <div className={'font-size-content align-content'}>当社によるユーザー情報の取扱いについては、別途当社プライバシーポリシー（ <a
            href={'https://kiroku.ai/privacy'}> https://kiroku.ai/privacy </a>）
            の定めによるものとし、登録ユーザーはこのプライバシーポリシーに従って当社が登録ユーザーの利用者情報を取扱うことについて同意するものとします。
        </div>
        <hr/>
        <h5 className="font-weight-header">第20条（著作権の取扱い）</h5>
        <ol>
            <li>本サービスにおいて、契約者及びユーザーが作成したデータに関する著作権は、当該作成等を行った契約者及びユーザー自身に留保されるものとし、当社は当該作成等データに関する著作権を取得することはありません。</li>
            <li>契約者及びユーザーは、本サービスの適切な運用に必要な限りで、当社及び当社から権利を承継し又は許諾された者に対し、著作者人格権を行使しないものとします。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第21条（本規約等の変更）</h5>
        <ol>
            <li>当社は、当社が必要と認めた場合は、本規約の目的、変更後の内容の相当性等の見地から合理的といえる範囲において、本規約を変更できるものとします。</li>
            <li>本規約を変更する場合、変更後の本規約の施行時期及び内容を当社ウェブサイト上での掲示その他の適切な方法により事前に周知し、または契約者に通知します。但し、法令上契約者の同意が必要となるような内容の変更の場合は、当社所定の方法で登録ユーザーの同意を得るものとします。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第22条（連絡・通知）</h5>
        <ol>
            <li>本規約の変更に関する通知その他本サービスに関する当社から契約者への連絡は、当社ウェブサイト内の適宜の場所への掲示、電子メールの送信、又はプッシュ通知その他当社が適当と判断する方法により行うものとします。</li>
            <li>本サービスに関する問い合わせその他契約者及びユーザーから当社に対する連絡又は通知は、当社ウェブサイト内の適宜の場所に設置するお問い合わせフォームへの送信、電子メールその他当社が指定する方法により行うものとします。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第23 条（反社会的勢力等の排除）</h5>
        <ol>
            <li> 契約者及びユーザーは、現在及び将来において、次の各号に定める事項を表明し保証するものとします。</li>
            <ol className="number-list">
                <li>契約者及びユーザーが暴力団、暴力団員でなくなったときから５年を経過しない者、暴力団準構成員、暴力団関係者、総会屋その他の反社会的勢力（以下、総称して「反社会的勢力」という。）に該当しないこと</li>
                <li>契約者及びユーザーが反社会的勢力に対して資金を提供し、または便益を供与し、もしくは便益の提供を受ける等の関係を有していないこと。</li>
                <li>前各号のほか、契約者及びユーザーが、反社会的勢力と関係を有することによって、社会的に非難されることがないこと。</li>
            </ol>
            <li>当社は、契約者及びユーザーが前項各号のいずれかに該当することが明らかになった場合には、通知をすることなく登録を解除することができるものとします。ただし、当社は、本項に基づく解除により契約者及びユーザーに生じた損害について賠償する責任を負わないものとし、当社に損害が生じた場合には、契約者及びユーザーは損害を賠償しなければならないものとします。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第24 条（権利義務の譲渡禁止）</h5>
        <p>契約者は、当社の書面による事前の承諾なく、本サービス利用契約上の地位又は本サービス利用契約に基づく権利義務の全部又は一部を、第三者に譲渡（合併、会社分割等による包括承継を含みます。）し又は担保の目的に供することはできません。</p>
        <hr/>
        <h5 className="font-weight-header">第25 条（当社における事業譲渡等の場合の取扱い）</h5>
        <p>当社が本サービスにかかる事業を他社に譲渡した場合、当社は、当該事業譲渡に伴い本サービス利用契約上の地位、権利及び義務並びに登録情報その他の情報を当該事業譲渡の譲受人に譲渡できるものとし、ユーザーは、かかる譲渡につき本条において予め同意したものとみなします。本条にいう事業譲渡には、当社が消滅会社又は分割会社となる合併又は会社分割等による包括承継を含むものとします。</p>
        <hr/>
        <h5 className="font-weight-header">第26条（完全合意）</h5>
        <p>本規約及び個別規約は、本規約の対象事項に関する当事者間の完全な合意を示すものであり、本規約締結までに当事者間でなされた書面、口頭又は黙示的になされた本規約及び個別規約の定めに反するあらゆる合意はその効力を失うこととします。</p>
        <hr/>
        <h5 className="font-weight-header">第27条（分離可能性）</h5>
        <ol>
            <li>本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、当該判断は他の部分に影響を及ぼさず、本規約の残りの部分は、引き続き完全に有効かつ執行力を有するものとします。当社及びユーザーは、当該無効若しくは執行不能とされた条項又は部分の趣旨に従い、これと同等の効果を確保できるように速やかに本規約を修正するとともに、修正された本規約に拘束されることに同意するものとします。</li>
            <li>本規約のいずれかの条項又はその一部が、あるユーザーとの関係で無効又は執行不能と判断された場合であっても、他のユーザーとの関係における有効性等には影響を及ぼさないものとします。</li>
        </ol>
        <hr/>
        <h5 className="font-weight-header">第28条（準拠法及び管轄裁判所）</h5>
        <ol>
            <li>本規約及びサービス利用契約の準拠法は日本法とします。</li>
            <li> 本規約に起因し又は関連する一切の紛争については、その訴額に応じ、福岡地方裁判所又は福岡簡易裁判所を第一審の専属的合意管轄裁判所とします。</li>
        </ol>
        <br/>
        <br/>
        <p className={'font-size-content'}>2020年3月1日制定</p>
        <p className={'font-size-content'}>2020年3月1日改定</p>
    </div>;
}

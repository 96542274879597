import React, { useEffect, useState } from 'react';
import { resources }                  from '@kiroku/api';
import {
    ComponentMobile, ComponentWeb, KirokuActionPage, KirokuCard, KirokuCardWeb, KirokuLoader, KirokuNotFound,
    KirokuTable, TBody, THeader,
}                                     from '../../../ui';
import { Link, useHistory }           from 'react-router-dom';
import { useTranslation }             from 'react-i18next';
import SearchNotFound                 from '../../../ui/SearchNotFound';
import { withPermissions }            from '../../HOC';
import moment                         from 'moment';

const KirokuAction = withPermissions(KirokuActionPage);

function NotFoundResult() {
    return <div className={ 'pt-3' } style={ { backgroundColor: '#fff' } }>
        <SearchNotFound description={ ' ' }/>
    </div>;
}

export default function PlanningTab(props) {
    const { t }   = useTranslation();
    const history = useHistory();
    const now = moment().unix();
    const {
              search, result,
          } = resources.useSearch('Plan');

    const [loadingAPI, setLoadingAPI] = useState(true);

    useEffect(() => {
        if (props.show) {
            search({ type: 'customer', customer: props.customer.id }).then(() => setLoadingAPI(false));
        }
    }, [props, search]);

    return (
        <div hidden={ !props.show }>
            <div className={ `d-flex justify-content-between ${ result.length ? '' : 'pb-3' } ` }>
                <div/>
                <div className={ 'd-flex' }>
                    <div className="pr-3 pb-3">
                        <Link to={ {
                            pathname: '/plans/create',
                            state   : {
                                customer          : {
                                    name: props.customer.name,
                                },
                                customer_id       : props.customer.id,
                                isDisabledCustomer: true,
                            },
                        } }
                        >
                            <KirokuAction
                                resource-permission={ '|planList|button-create|' }
                            >
                                { t('plans.addPlan') }
                            </KirokuAction>
                        </Link>
                    </div>
                </div>
            </div>
            <KirokuLoader loading={ loadingAPI }>
                <KirokuNotFound isResults={ result.length }
                                ComponentSearchNoFound={ NotFoundResult }
                >
                    <ComponentWeb>
                        <KirokuTable>
                            <THeader>
                                <tr>
                                    <th>{ t('plans.startDate') }</th>
                                    <th>{ t('plans.endDate') }</th>
                                    <th>{ t('plans.planStatus') }</th>
                                    <th>{ t('plans.statusReview') }</th>
                                </tr>
                            </THeader>
                            <TBody>
                                { result.map((plan) => {
                                     return (
                                         <tr key={ plan.id }
                                             data-href={ '/plans/' + plan.id }
                                             onClick={ () => history.push('/plans/' + plan.id, { from : 'customerDetail'}) }
                                             className={ 'cursor-pointer' }
                                         >
                                             <td className={ 'word-break-keep-all' }>
                                                 <div>{ !!plan.start_at ?
                                                     moment.unix(plan.start_at).format(t('dateTimeFormat.YearMonthDateWithDay')) : '' }
                                                 </div>
                                             </td>
                                             <td style={ { whiteSpace: 'nowrap' } }>
                                                 <div>{ !!plan.end_at ? moment.unix(plan.end_at).format(t('dateTimeFormat.YearMonthDateWithDay')) : '' }</div>

                                             </td>
                                             <td>
                                                 <div hidden={plan.published_at || plan.review_at}>
                                                     <img src={`/assets/icon/iconDraft.svg`} alt="iconDraft.svg"/>
                                                     &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.draft')}</span>
                                                 </div>
                                                 <div hidden={!(plan.published_at && now < plan.start_at) || plan.review_at}>
                                                     <img src={`/assets/icon/iconBeforeStart.svg`} alt="iconBeforeStart.svg"/>
                                                     &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.beforeStart')}</span>
                                                 </div>
                                                 <div hidden={!(plan.published_at && now > plan.start_at && now < plan.end_at) || plan.review_at}>
                                                     <img src={`/assets/icon/iconRunning.svg`} alt="iconRunning.svg"/>
                                                     &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.running')}</span>
                                                 </div>
                                                 <div hidden={!(plan.published_at && now > plan.end_at)}>
                                                     <img src={`/assets/icon/iconFinish.svg`} alt="iconFinish.svg"/>
                                                     &nbsp;&nbsp;<span style={{ fontSize: 14 }}>{t('plans.finish')}</span>
                                                 </div>
                                             </td>
                                             <td>
                                                 <div hidden={ plan.review_status !== 0 }>
                                                     {t('plans.unReview')}
                                                 </div>
                                                 <div hidden={ plan.review_status !== 1 }>
                                                     {t('plans.draftReview')}
                                                 </div>
                                                 <div hidden={ plan.review_status !== 2 }>
                                                     {t('plans.monitored')}
                                                 </div>
                                             </td>
                                         </tr>
                                     )
                                    }
                                ) }
                            </TBody>
                        </KirokuTable>
                    </ComponentWeb>
                    <ComponentMobile>
                        <KirokuCardWeb>
                            { result.map((plan, index) => {
                                return ( <div key={ index }
                                         style={ { borderBottom: '1px solid rgba(77, 82, 89, 0.07)' } }
                                    >
                                        <KirokuCard>
                                            <Link to={ {
                                                pathname: `/plans/${plan.id }`,
                                                state: {
                                                    from: 'customerDetail'
                                                }
                                            }}>
                                                <div className={'pb-15px label-info'}>{ t('plans.startDate') }: { !!plan.start_at ?
                                                    <span className="main-content-info">
                                                        {moment.unix(plan.start_at).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                                    </span> : '' }</div>
                                                <div className={'pb-15px label-info'}>{ t('plans.endDate') }: { !!plan.end_at ?
                                                    <span className="main-content-info">
                                                        {moment.unix(plan.end_at).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                                    </span> : '' }</div>
                                                <div className={'pb-15px'}>
                                                    <div className="d-flex align-items-center">
                                                        <div className={'label-info'}>{t('plans.planStatus')}: &nbsp;&nbsp;</div>
                                                        <div hidden={plan.published_at || plan.review_at}>
                                                            <img src={`/assets/icon/iconDraft.svg`} alt="iconDraft.svg"/>
                                                            &nbsp;<span className="main-content-info" style={{ fontSize: 14 }}>{t('plans.draft')}</span>
                                                        </div>
                                                        <div hidden={!(plan.published_at && now < plan.start_at) || plan.review_at}>
                                                            <img src={`/assets/icon/iconBeforeStart.svg`} alt="iconBeforeStart.svg"/>
                                                            &nbsp;<span className="main-content-info" style={{ fontSize: 14 }}>{t('plans.beforeStart')}</span>
                                                        </div>
                                                        <div hidden={!(plan.published_at && now > plan.start_at && now < plan.end_at) || plan.review_at}>
                                                            <img src={`/assets/icon/iconRunning.svg`} alt="iconRunning.svg"/>
                                                            &nbsp;<span className="main-content-info" style={{ fontSize: 14 }}>{t('plans.running')}</span>
                                                        </div>
                                                        <div hidden={!(plan.published_at && now > plan.end_at)}>
                                                            <img src={`/assets/icon/iconFinish.svg`} alt="iconFinish.svg"/>
                                                            &nbsp;<span className="main-content-info" style={{ fontSize: 14 }}>{t('plans.finish')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="d-flex align-items-center label-info">
                                                        <div>{t('plans.statusReview')}: &nbsp;&nbsp;</div>
                                                        <div className={'label-info'} hidden={ plan.review_status !== 0 }>
                                                            {t('plans.unReview')}
                                                        </div>
                                                        <div hidden={ plan.review_status !== 1 }>
                                                            {t('plans.draftReview')}
                                                        </div>
                                                        <div hidden={ plan.review_status !== 2 } >
                                                            {t('plans.monitored')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </KirokuCard>
                                    </div>
                                )
                            }) }
                        </KirokuCardWeb>
                    </ComponentMobile>
                </KirokuNotFound>
            </KirokuLoader>
            <ComponentMobile>
                <div className="padding-responsive">
                    <div style={ { marginBottom: 80 } }/>
                </div>
            </ComponentMobile>
        </div>
    );
}

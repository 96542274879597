import React                                                from 'react';
import { Link }                                             from 'react-router-dom';
import { KirokuHighLightText, KirokuLabel, SearchNotFound } from '../../../../ui';
import { useTranslation }                                   from 'react-i18next';
import KirokuCardDeletedScreen                              from '../../../../ui/KirokuCardDeletedScreen';
import KirokuLoader                                         from '../../../../ui/KirokuLoader';
import { useCondition }                                     from '../../useCondition';
import moment                                               from 'moment';

export default function TableRecordsMobile(props) {
    const { t }                                       = useTranslation();
    const { records, handleCheckRecordRead, loading } = props;
    const { updateRecordCondition, recordCondition }  = useCondition();
    return (
        <div>
            <div className="pt-2 pb-3" hidden={ !records.length }>
                <div className="d-flex justify-content-center">
                    <div
                        className={ `border-width-each-status first-btn font-text-on-btn ${ recordCondition.sort_by === 'serviced_at' ? 'btn-active' : '' }` }
                        onClick={ () => {
                            updateRecordCondition({ ...recordCondition, order_by: 'DESC', sort_by: 'serviced_at', page: 1 });
                        } }
                    >
                        { t('RecordsBeta.orderOfServicedAt') }
                    </div>
                    <div
                        className={ `border-width-each-status third-btn font-text-on-btn ${ recordCondition.sort_by === 'update_at' ? 'btn-active' : '' }` }
                        onClick={ () => {
                            updateRecordCondition({ ...recordCondition, order_by: 'DESC', sort_by: 'update_at', page: 1 });
                        } }
                    >
                        { t('RecordsBeta.orderOfUpdatedAt') }
                    </div>
                </div>
            </div>
            <div className='bg-white'>
                <KirokuLoader loading={ loading }>
                    <KirokuCardDeletedScreen>
                        { records.length ? records.map((record, index) => (

                            <Link
                                key={ index }
                                to={ {
                                    pathname: `/records/${ record.id }`,
                                    state   : records.map(item => item.id),
                                } }
                                onClick={ () => {
                                    localStorage.setItem('conditionRecords', JSON.stringify(recordCondition));
                                } }
                            >
                                <div className={ `${ !handleCheckRecordRead(record) ? 'border-read-record' : '' }` }>
                                    <div className={ ` pl-3 font-customer-name ${ index === 0 ? 'pt-3' : 'pt-1' }` }>
                                        <div className={ 'label-record-beta' } style={ { margin: 0 } }>
                                            <span className="label-name">{ record.customer.name }</span>
                                            <div className="pr-3 pt-1 font-label-draft" style={ { float: 'right' } }
                                                 hidden={ !record.drafted_at }>
                                                <KirokuLabel color={ 'warning' }
                                                             style={ {
                                                                 width          : '94px', height: '30px',
                                                                 border         : 'solid 1px #EA9B3F',
                                                                 backgroundColor: '#EA9B3F',
                                                             } }
                                                >{ t('RecordsBeta.draft') }</KirokuLabel>
                                            </div>
                                            <span className="font-name-san">{ t('RecordsBeta.noun') }</span>
                                        </div>
                                    </div>
                                    <div className='pl-3 label-record-beta pt-10px' style={ { wordBreak: 'break-word' } }>
                                        <span className='label-name'> { record.title } </span>
                                    </div>
                                    <div className={ 'pl-3 pt-10px pb-3' }>
                                        <div className=' d-flex justify-content-start m-0 pb-10px'
                                             style={ { color: '#202528' } }>
                                            <div style={ { textAlign: 'end', minWidth: 117 } }
                                            >
                                                { t('common.ServiceTime') }:
                                            </div>
                                            <div className='pl-2'>
                                                <div className="font-text-record-card">
                                                    { moment.unix(record.serviced_at).format(record.has_time ? t('dateTimeFormat.YearMonthDateWithHour') : t('dateTimeFormat.YearMonthDateWithDay'))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-start m-0'
                                             style={ { color: '#202528' } }>
                                            <div style={ { textAlign: 'end', minWidth: 117 } }
                                            >
                                                { recordCondition.sort_by === 'update_at' ? `${ t('RecordsBeta.updatedDate') }:` : `${ t('RecordsBeta.createdAtAndUpdatedAt') }:` }
                                            </div>
                                            <div className='pl-2'>
                                                <div className={ 'font-text-record-card' }>
                                                    { moment.unix(record['last_action_at']).format( t('dateTimeFormat.YearMonthDateWithHour'))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pl-3 pb-3 pt-1 font-highlight-keyword "
                                         hidden={ !recordCondition.answer_search }
                                         style={ { wordBreak: 'break-word' } }>
                                        { record.highlight ? <div
                                                dangerouslySetInnerHTML={ { __html: record.highlight['answer_search.answer'] }}/> :
                                            <KirokuHighLightText
                                                className={ 'background-highText' }
                                                searchWords={ [recordCondition.answer_search] }
                                                textToHighlight={ record['answer_search'] || '' }
                                            />}
                                    </div>
                                    <hr className='m-0'/>
                                </div>
                            </Link>
                        )) : <SearchNotFound/> }
                    </KirokuCardDeletedScreen>
                </KirokuLoader>

            </div>
        </div>
    );
}

export const CHANGE_INFO_CUSTOMER = 'CHANGE_INFO_CUSTOMER';
export const CHANGE_CONTRACT_START_CUSTOMER = 'CHANGE_CONTRACT_START_CUSTOMER';
export const CHANGE_CONTRACT_END_CUSTOMER = 'CHANGE_CONTRACT_END_CUSTOMER';
export const CHANGE_CUSTOMER = 'CHANGE_CUSTOMER';

export default function (state, action) {
    switch (action.type) {
        case CHANGE_INFO_CUSTOMER:
            return {
                ...state,
                [action.name]: action.value,
            };
        case CHANGE_CONTRACT_START_CUSTOMER:
            return {
                ...state,
                contract_start: action.contract_start,
                isValidContractStartDate: action.isValidContractStartDate,
            };
        case CHANGE_CONTRACT_END_CUSTOMER:
            return {
                ...state,
                isValidContractEndDate: action.isValidContractEndDate,
                contract_end: action.contract_end,
            };

        case CHANGE_CUSTOMER:
            return {
                ...state,
                ...action.value
            };
        default:
            return state;
    }
};

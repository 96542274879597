import React                       from 'react';
import { KirokuCardDeletedScreen } from '../../../ui';
import FormatTimestamp             from '../CommonComponent/FormatTimestamp';
import { useTranslation }          from 'react-i18next';

export default function UserList({ users }) {
    const { t } = useTranslation();

    return (<div className="">
        <div className={'bg-white'}>
            <KirokuCardDeletedScreen>
                {users.map((user, index) => (
                    <div key={index}>
                        <div className='pl-3 pt-3'>
                            <div className='header-card-del word-break'>{user.name}</div>
                            <div className='title-card-del pt-10px'>{t('DeletedList.deletedAt')} : <FormatTimestamp
                                timestampUnix={user.delete_at}/></div>
                            <div className='title-card-del pt-10px'>{t('DeletedList.createdAt')} : <FormatTimestamp
                                timestampUnix={user.create_at}/></div>
                        </div>
                        <hr className='hr-table-mobile'/>
                    </div>
                ))}
            </KirokuCardDeletedScreen>
        </div>
    </div>);
}

import React, { useState }                                                                from 'react';
import { KirokuButton, KirokuInputDate, KirokuModal, KirokuModalBody, KirokuModalFooter } from '../../../ui';
import { useTranslation }                                                                 from 'react-i18next';
import moment                                                                             from 'moment';
import useNotification
                                                                                          from '../../Layout/useNotification';
import { resources }                                                                      from '@kiroku/api';
import { useHistory }                                                                     from 'react-router-dom';

export default function ModalConfirmPublishPlan(props) {
    const { show, closeModal, plan, loadDetail } = props;
    const { t }                                  = useTranslation();
    const [publishedAt, setPublishedAt]          = useState(moment());
    const { notification }                       = useNotification();
    const update                                 = resources.useUpdate('Plan')[1];
    const history                                = useHistory();
    const [disabledBtn, setDisabledBtn]          = useState(false);

    const handleConfirmPublish = () => {
        setDisabledBtn(true);
        let value = {
            ...plan,
            customer_id               : plan.customer_id,
            customer_name             : plan.customer.name,
            overview                  : plan.overview,
            short_term_target         : plan.short_term_target,
            long_term_target          : plan.long_term_target,
            long_term_target_start_at : plan.long_term_target_start_at || null,
            long_term_target_end_at   : plan.long_term_target_end_at || null,
            short_term_target_start_at: plan.short_term_target_start_at || null,
            short_term_target_end_at  : plan.short_term_target_end_at || null,
            start_at                  : plan.start_at || null,
            end_at                    : plan.end_at || null,
            published_at              : publishedAt.unix(),
            type                      : 'PUBLISHED',
        };
        update(plan.id, value).then(() => {
            setDisabledBtn(false);
            history.push(`/plans/${ plan.id }`);
            notification(t('message.UpdateSuccess'), 'success');
            closeModal();
            loadDetail();
        }).catch((error) => {
            console.log(error);
            closeModal();
            notification(t('message.UpdateFail'), 'danger');
        });
    };


    return (
        <KirokuModal show={ show }>
            <KirokuModalBody>
                <div className="padding-responsive">
                    <div className={ 'header-message-publish' }>{ t('plans.updatePublishMessage') }</div>
                    <div style={ { paddingTop: 18, fontSize: 14 } }>{ t('plans.published_at') }</div>
                    <div className="pt-2" style={ { width: '100%' } }>
                        <KirokuInputDate value={ publishedAt }
                                         inputProps={{ disabled: false, readOnly: true }}
                                         dateFormat={t('dateTimeFormat.YearMonthDate')}
                                         onChange={ (date) => {
                                             setPublishedAt(date);
                                         } }
                                         className='read-only-date'
                        />
                    </div>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton color="light"
                              onClick={ () => {
                                  closeModal();
                                  setPublishedAt(moment())
                              } }
                >
                    <span style={ { fontSize: 14 } }>
                        { t('plans.cancel') }
                    </span>
                </KirokuButton>
                <KirokuButton style={ {
                                  backgroundColor: '#249533',
                                  color          : '#fff',
                                  padding        : '5px 16px',
                              } }
                              disabled={ disabledBtn }
                              onClick={ handleConfirmPublish }
                >
                    <span style={ { fontSize: 14 } }>
                        { t('plans.publishBtn') }
                    </span>
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

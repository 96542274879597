import React                                      from 'react';
import { useTranslation }                         from 'react-i18next';
import { KirokuButton, KirokuDropdownResetTitle } from './index';

function OptionsSizePaginator(items) {
    return items.map(pagination => ({
        value    : pagination.value,
        label    : pagination.name,
        component: (<div><span
            className={ 'color-dropdown-select-pagination'}>{ pagination.name }</span>
        </div>),
    }));
}

export function Paginator(props) {
    const { t } = useTranslation();
    const {
              onChangePaginator,
              numberPage, location, decreasePage,
              increasePage, disabledPrev, disabledNext,
              items, height = 36
          }     = props;

    return (
        <div className={ 'd-flex' }>
            <KirokuButton className={ 'border-btn-paginator bg-white btn-back' }
                          disabled={ disabledPrev }
                          onClick={ decreasePage }
                          style={{height: height, fontSize: 14}}
            > { t('DeletedList.back') } </KirokuButton>
            <KirokuDropdownResetTitle
                classNameBtn={'btn-kiroku-white-del'}
                location={ location || '' }
                style={ { minWidth: '70px', height: height } }
                title={ numberPage }
                items={ OptionsSizePaginator(items) }
                onSelect={ event => {
                    onChangePaginator(event);
                } }
                isPagination
            />
            <KirokuButton className={ 'border-btn-paginator bg-white btn-next' }
                          onClick={ increasePage }
                          disabled={ disabledNext }
                          style={{height: height, fontSize: 14}}
            > { t('DeletedList.next') } </KirokuButton>
        </div>
    );
}

export default Paginator;

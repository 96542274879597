import React, { useEffect, useState } from 'react';
import ExportResults                  from './ExportResults';
import DropdownSelectCustomer         from './DropdownSelectCustomer';
import {
    KirokuButton, KirokuCardWeb,
    KirokuDateRangePicker, SearchNotFound,
}                                     from '../../ui';
import { useTranslation }             from 'react-i18next';
import { resources }                  from '@kiroku/api';
import KirokuLoader                   from '../../ui/KirokuLoader';
import { removeConditionsUndefined }  from '../Converter';
import usePageTitle                   from '../../ui/v3/hooks/usePageTitle';

export default function Exports() {
    const { t }                           = useTranslation();
    const [records, setRecords]           = useState([]);
    const { search, result, loading }     = resources.useSearch('Record');
    const [customerName, setCustomerName] = useState('');
    const [initValue, setInitValue]       = useState(true);

    usePageTitle(t(`Layout.AdminExport`));

    useEffect(() => {
        if (result) {
            setRecords(result);
        }
    }, [result]);

    const [condition, setConditions] = useState({
        customer          : '',
        startDate_created : null,
        endDate_created   : null,
        startDate_serviced: null,
        endDate_serviced  : null,
    });

    return (
        <div>
            <div className={ 'padding-responsive' }>
                <KirokuCardWeb>
                    <div className="padding-responsive exports">
                        <div className='d-flex justify-content-between'>
                            <div className={'card-title-info'}>{ t('Export.Export') }</div>
                        </div>
                        <div className='pb-10px pt-15px'>{ t('Export.SelectCustomer') }</div>
                        <div style={{ width : 303}}>
                            <DropdownSelectCustomer
                                customerSelected={ (value) => {
                                    setConditions({ ...condition, customer: value });
                                } }
                                getCustomerName={ (name) => setCustomerName(name) }/>
                        </div>
                        <div className='pb-10px pt-15px'>{ t('Export.SelectCreatedAt') }</div>
                        <div className="date-time-range">
                            <KirokuDateRangePicker
                                startDateId='startDateCreated'
                                endDateId='endDateCreated'
                                onChangeDate={ (dateRange) => {
                                    setConditions({
                                        ...condition,
                                        startDate_created: dateRange.momentStartDate,
                                        endDate_created  : dateRange.momentEndDate,
                                    });

                                } }
                            />
                        </div>
                        <div className='pb-10px pt-15px'>{ t('Export.SelectServicedAt') }</div>
                        <div className="date-time-range">
                            <KirokuDateRangePicker
                                startDateId='startDateServiced'
                                enDateId='endDateServiced'
                                onChangeDate={ (date) => {
                                    setConditions({
                                        ...condition,
                                        startDate_serviced: date.momentStartDate,
                                        endDate_serviced  : date.momentEndDate,
                                    });
                                } }
                            />
                        </div>
                        <div className='pt-15px'>
                            <KirokuButton
                                color={ 'primary' }
                                disabled={ !customerName }
                                onClick={ () => {
                                    setInitValue(false);
                                    let valueSearch = {
                                        ...condition,
                                        type: 'export',
                                    };
                                    removeConditionsUndefined(valueSearch);
                                    search(valueSearch);
                                } }
                            >
                                { t('Export.Search') }
                            </KirokuButton>
                        </div>
                    </div>
                </KirokuCardWeb>
                { initValue ? <div/> : <div className={"pt-3"}>
                    <KirokuLoader loading={ loading }>
                        { !records.length ? <div className={'card'}> <SearchNotFound adminExport
                                                                                          message={ t('Export.showResultSearch') }
                                                                                          description={ t('Export.TrySearch') }
                            /></div> :
                            <ExportResults records={ records } condition={ condition } customerName={ customerName }/> }
                    </KirokuLoader>
                </div> }
            </div>
        </div>
    );
}

import React              from 'react';
import { useTranslation } from 'react-i18next';
import { useCondition }   from '../../useCondition';

export default function ReadUnReadCondition() {
    const { t }                          = useTranslation();
    const { contain_read, updateRecordCondition, recordCondition } = useCondition();

    return (
        <div className="d-flex justify-content-around border-btn-status-group">
            <div className={ `border-width-each-status font-text-on-btn first-btn cursor-pointer
                                    ${ contain_read ? 'btn-active' : '' }` }
                 onClick={ () => updateRecordCondition({...recordCondition, contain_read: true, page : 1})}
            >
                { t('RecordsBeta.unRead') }
            </div>
            <div/>
            <div className={ `border-width-each-status font-text-on-btn third-btn cursor-pointer
                                     ${ !contain_read ? 'btn-active' : '' }` }
                 onClick={ () => updateRecordCondition({...recordCondition, contain_read: false, page : 1}) }
            >
                { t('RecordsBeta.all') }
            </div>
        </div>
    );
}

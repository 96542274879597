import { KirokuButton, KirokuModal, KirokuModalBody, KirokuModalFooter } from '../../../ui';
import React, { useEffect, useState }                                    from 'react';
import { useTranslation }                                                from 'react-i18next';
import { resources }                                                     from '@kiroku/api';
import useProgressEvent        from '../useProgressEvent';
import { useMixpanelTracking } from '../../../Mixpanel/useMixpanelTracking';

export default function ModalConfirmDeactivate({ isShowModal, customers, handleAction, handleAfterDelete }) {
    const { t }                                                 = useTranslation();
    const deActive                                              = resources.useDelete('Customer')[1];
    const [beginDelete, isDoneDelete, setInitial, statusDelete] = useProgressEvent(deActive, customers.filter(customer => customer.isChecked).map(item => item.id));
    const [isDisabledBtnSubmit, setIsDisabledBtnSubmit]         = useState(false);
    const [isShowProgress, setIsShowProgress]                   = useState(false);
    const { trackingMixpanel }                                  = useMixpanelTracking();

    const handleConfirmDeactivate = () => {
        setIsShowProgress(true);
        setIsDisabledBtnSubmit(true);
        beginDelete();
        trackingMixpanel('DEACTIVE_CUSTOMER', customers);
    };

    useEffect(() => {
        if (isDoneDelete) {
            setIsShowProgress(false);
            handleAction();
            setIsDisabledBtnSubmit(false);
        }
        if(isDoneDelete && isShowModal) {
            handleAfterDelete();
            setInitial();
        }
    }, [isDoneDelete, handleAction, isShowModal, handleAfterDelete, setInitial]);

    return (<KirokuModal show={isShowModal}>
        <KirokuModalBody>
            <p className='text-center pt-3'>
                {t('Customer.Verify', {
                    customers: customers.filter(customer => customer.isChecked)
                        .map(item => item.name).join(', '),
                })}
            </p>

            <div className="progress" hidden={!isShowProgress}>
                <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                     aria-valuenow={statusDelete} aria-valuemin="0" aria-valuemax="100"
                     style={{ width: statusDelete * 100 }}>
                </div>
            </div>
        </KirokuModalBody>
        <KirokuModalFooter>
            <KirokuButton onClick={() => {
                handleAction();
            }} color={'white'}>
                {t('common.Cancel')}
            </KirokuButton>
            <KirokuButton
                disabled={isDisabledBtnSubmit}
                onClick={() => {
                    handleConfirmDeactivate();
                }}
                color={'primary'}
            >
                {t('common.Confirm')}
            </KirokuButton>
        </KirokuModalFooter>
    </KirokuModal>);
}

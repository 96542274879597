import { useTemplateContext } from './TemplateContext';
import { useCallback }        from 'react';
import {
    GET_TEMPLATES,
    HANDLE_RESET_STATE,
    CHANGE_ATTACH_STATUS,
    SET_INIT_ATTACH_TAB_STATUS,
    HANDLE_CHECK_TEMPLATE_ATTACHED,
    HANDLE_CHECK_TEMPLATE_UNATTACHED,
    CHANGE_KEYWORD_TO_SEARCH_ATTACHED,
    CHANGE_KEYWORD_TO_SEARCH_UNATTACHED,
    HANDLE_CHECK_ALL_TEMPLATES_ATTACHED,
    HANDLE_CHECK_ALL_TEMPLATES_UNATTACHED,
} from './TemplateReducer';

export default function useAttachTemplate() {
    const { templatesContext, dispatch } = useTemplateContext();

    const {
              templateAttached,
              templateUnAttached,
              attachStatus,
              keywords,
              templateData,
              templateAttachedList,
              templateUnAttachedList,
          } = templatesContext;

    const getTemplates = useCallback((templates) => {
        dispatch({
            type: GET_TEMPLATES,
            templates,
        });
    }, [dispatch]);

    const changeAttachStatus = useCallback((attachStatus) => {
        dispatch({
            type: CHANGE_ATTACH_STATUS,
            attachStatus,
        });
    }, [dispatch]);

    const handleChangeKeywordAttached = useCallback((keywords) => {
        dispatch({
            type: CHANGE_KEYWORD_TO_SEARCH_ATTACHED,
            keywords,
        });
    }, [dispatch]);

    const handleChangeKeywordUnAttached = useCallback((keywords) => {
        dispatch({
            type: CHANGE_KEYWORD_TO_SEARCH_UNATTACHED,
            keywords,
        });
    }, [dispatch]);

    const handleCheckTemplateAttached = useCallback((index) => {
        dispatch({
            type: HANDLE_CHECK_TEMPLATE_ATTACHED,
            index,
        });
    }, [dispatch]);

    const handleCheckTemplateUnAttached = useCallback((index) => {
        dispatch({
            type: HANDLE_CHECK_TEMPLATE_UNATTACHED,
            index,
        });
    }, [dispatch]);

    const setInitStatus = useCallback((status) => {
        dispatch({
            type: SET_INIT_ATTACH_TAB_STATUS,
            status,
        });
    }, [dispatch]);

    const handleCheckAllTemplatesAttached = useCallback(() => {
        dispatch({
            type: HANDLE_CHECK_ALL_TEMPLATES_ATTACHED,
        });
    }, [dispatch]);

    const handleCheckAllTemplatesUnattached = useCallback(() => {
        dispatch({
            type: HANDLE_CHECK_ALL_TEMPLATES_UNATTACHED,
        });
    }, [dispatch]);

    const handleResetState = useCallback(() => {
        dispatch({
            type: HANDLE_RESET_STATE,
        });
    }, [dispatch]);

    return {
        keywords,
        templateData,
        setInitStatus,
        getTemplates,
        templateAttached,
        templateUnAttached,
        attachStatus,
        changeAttachStatus,
        handleChangeKeywordAttached,
        handleChangeKeywordUnAttached,
        handleCheckTemplateAttached,
        handleCheckTemplateUnAttached,
        handleCheckAllTemplatesAttached,
        handleCheckAllTemplatesUnattached,
        handleResetState,
        templateAttachedList,
        templateUnAttachedList
    };

}

import React, { useEffect, useState }            from 'react';
import { useTranslation }                        from 'react-i18next';
import { resources }                             from '@kiroku/api';
import {
    KirokuCardWeb,
    KirokuLoading,
}                                                from '../../../../ui';
import If                                        from '../../../If';
import config                                    from '../../../../config';
import { concatActivitiesLog, getGroupLogByDay } from '../../../Logs/groupLog';
import moment                                    from 'moment';
import LazyLoadHistory                           from './LazyLoadHistory';

export default function HistoryTab({ isView, record }) {
    const { t }                                   = useTranslation();
    const { search, pagination, cancelAPISearch } = resources.useSearch('History', { page: 1, perPage: 15 });
    const [loadingAPI, setLoadingAPI]             = useState(true);
    const [history, setHistory]                   = useState([]);
    const [hasNext, setHasNext]                   = useState(false);

    const convertLogsResult = (result) => {
        return result.map((logData, index) => {
            return {
                ...logData,
                firstActionUpdate: index === result.length - 1 && logData.action === 'update'
            }
        });
    }
    useEffect(() => {
        if (isView) {
            search({
                type  : 'condition',
                record: record.id,
            }).then(res => {
                setLoadingAPI(false);
                const logsResult = convertLogsResult(res.result);
                setHistory(getGroupLogByDay(logsResult));
                setHasNext(res.hasNext);

            }).catch(err => {
                console.log(err);
                setLoadingAPI(false);
            });
        }

        return () => {
            cancelAPISearch();
        };
    }, [record.id, search, isView, cancelAPISearch]);


    function isScrolling() {
        if (window.innerWidth < config.configWithMobile) {
            if ((Math.ceil(document.documentElement.scrollTop) >= document.documentElement.offsetHeight - (window.innerHeight + 100))
                && (hasNext && !loadingAPI)
            ) {
                setLoadingAPI(true);
                search({
                    type  : 'condition',
                    record: record.id,
                    page  : pagination.currentPage + 1,
                }).then(res => {
                    const logsResult = convertLogsResult(res.result);
                    const newResult = getGroupLogByDay(logsResult);
                    setHistory(concatActivitiesLog(newResult, history));
                    setLoadingAPI(false);
                    setHasNext(res.hasNext);
                }).catch(() => {
                    setLoadingAPI(false);
                });
            }
        } else {
            if (
                (window.innerHeight + Math.ceil(document.documentElement.scrollTop) + 30 >= document.documentElement.offsetHeight)
                && hasNext && !loadingAPI) {
                setLoadingAPI(true);
                search({
                    type  : 'condition',
                    record: record.id,
                    page  : pagination.currentPage + 1,
                }).then(res => {
                    const logsResult = convertLogsResult(res.result);
                    const newResult = getGroupLogByDay(logsResult);
                    const newHistory = concatActivitiesLog(newResult, history)
                    setHasNext(res.hasNext);
                    setHistory(newHistory);
                    setLoadingAPI(false);
                }).catch(() => {
                    setLoadingAPI(false);
                });
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', isScrolling);
        return () => window.removeEventListener('scroll', isScrolling);
    });
    const setHidden = (historyItem) => {
        return historyItem.length === 1 && historyItem[0].logs.length === 1 && historyItem[0].logs[0].firstActionUpdate
    }
    return (<div hidden={ !isView }>
        <KirokuCardWeb className="card card-kiroku-web">
            <If statement={ history }>
                { () => history.map((historyItem, index) => {
                    return <div key={ index } hidden={setHidden(history)}>
                        <div className={'log-day'}>
                            { moment.unix(historyItem.date).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                        </div>
                        { historyItem.logs.map((log, index) => {
                            return (
                                <div hidden={log.firstActionUpdate}  key={ index }>
                                    <LazyLoadHistory log={log} record={record}/>
                                </div>
                            );
                        }) }
                    </div>;
                }) }
            </If>
            <div>
                { loadingAPI ? <KirokuLoading/> : null }
            </div>
        </KirokuCardWeb>
    </div>);
}

import React              from 'react';
import { useTranslation } from 'react-i18next';

export default function NotFoundPage() {
    const { t } = useTranslation();

    return (<div className='page-error default-background-color'>
        <div className="wrapper-page-error">
            <main>
                <div>
                    <span className={'text-404'}>404</span>
                    <h2 className='text-page-not-found'>{t('common.pageNotFound')}</h2>
                    <a href="/" className="btn btn-kiroku btn-kiroku-primary" style={{ width: 150 }}>
                        {t('common.BackHome')}
                    </a>
                </div>
            </main>
        </div>
    </div>);
}

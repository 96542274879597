import React, { useEffect, useState } from 'react';
import { resources }                  from '@kiroku/api';
import {
    KirokuButton, KirokuCheckboxBorder, KirokuInputSearch, KirokuLoader, KirokuModal, KirokuModalBody,
    KirokuModalFooter, KirokuModalHeader, KirokuSectionDropdownShowMore, SearchNotFound,
}                                     from '../../../ui';
import { useTranslation }             from 'react-i18next';
import lodash                         from 'lodash';
import useNotification                from '../../Layout/useNotification';
import { useMixpanelTracking }        from '../../../Mixpanel/useMixpanelTracking';
import useSearchTeams                 from './useSearchTeams';
import { getGroupedOptions }          from '../../Converter';

export default function ModalSelectCustomers({ show, templates, onClose, record }) {

    const { t }                                     = useTranslation();
    const { result, search, loading }               = resources.useSearch('Customer');
    const [resultCustomer, create]                  = resources.useCreate('CopyRecord');
    const [keywordSearch, setKeywordSearch]         = useState('');
    const [customersForModal, setCustomersForModal] = useState([]);
    const [customers, setCustomers]                 = useState([]);
    const { notification }                          = useNotification();
    const { trackingMixpanel }                      = useMixpanelTracking();
    const { teams, searchTeams }                    = useSearchTeams();
    const [currentTags, setCurrentTags]             = useState([]);
    const [isCopying, setIsCopying]                 = useState(false);

    useEffect(() => {
        search({
            type    : 'template',
            template: templates,
        });
        searchTeams();
    }, [search, templates, searchTeams]);

    useEffect(() => {
        setCustomers(result);
        setCustomersForModal(result);
    }, [setCustomers, result]);

    const handleCheck = (value) => {
        setCustomers(customers.map(customer => {
            if (customer.id === value) {
                return {
                    ...customer,
                    isChecked: !customer.isChecked,
                };
            }
            return customer;
        }));
        setCustomersForModal(customersForModal.map(customer => {
            if (customer.id === value) {
                return {
                    ...customer,
                    isChecked: !customer.isChecked,
                };
            }
            return customer;
        }));

    };

    const handleCheckAll = () => {
        const listCustomerSelectAll = customersForModal.map(customer => {
            return {
                ...customer,
                isChecked: lodash.findIndex(customersForModal, customer => !customer.isChecked) !== -1,
            };
        });
        setCustomersForModal(listCustomerSelectAll);
        setCustomers(customers.map(customer => {
            if (lodash.findIndex(listCustomerSelectAll, item => item.id === customer.id) !== -1) {
                return {
                    ...customer,
                    isChecked: !customer.isChecked,
                };
            }
            return customer;
        }));
    };

    useEffect(() => {
        if (resultCustomer && resultCustomer.code === 'KIROKU_CREATED') {
            notification(t('message.UpdateSuccess'), 'success');
            onClose();
        }
    }, [resultCustomer, onClose, notification, t]);

    const onChangeSearch = (event) => {
        const inputValue = event.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        setKeywordSearch(inputValue);
        setCustomersForModal(customers.filter(customer => {
            return customer.name.toLowerCase().search(inputValue.toLowerCase()) !== -1;
        }));
    };

    const handleChangeTeams = (options) => {
        setCurrentTags(options);
        let customerSelected = [];
        if (options.length) {
            options.forEach(team => {
                customerSelected = customerSelected.concat(team.customers);
            })
            let combinedCustomer =  lodash.uniqBy(customerSelected, 'id');
            let customerSearched =  combinedCustomer.map(ctm => {
                const indexOfCustomer = lodash.findIndex(customers, customer => customer.id === ctm.id);
                if (indexOfCustomer !== -1) {
                    return {
                        ...ctm,
                        isSelected: true,
                        isChecked: customers[indexOfCustomer].isChecked
                    }
                }
                return ctm;
            });
            let customerSelectedRaw = customerSearched.filter(customer => customer.isSelected);
            setCustomersForModal(customerSelectedRaw);
            setCustomers(customerSelectedRaw);
        } else {
            setCustomersForModal(customers);
            setCustomers(result.map(ctm => {
                const indexOfCustomer = lodash.findIndex(customers, customer => customer.id === ctm.id);
                if (indexOfCustomer !== -1) {
                    return {
                        ...ctm,
                        isSelected: true,
                        isChecked: customers[indexOfCustomer].isChecked
                    }
                }
                return ctm;
            }))
        }
    }

    return (
        <KirokuModal show={ show } onCloseModal={ onClose }>
            <KirokuModalHeader>
                <div className={ 'font-header-on-modal' }>{ t('Surveys.selectCustomer') }</div>
            </KirokuModalHeader>
            <KirokuModalBody className={'p-3'}>
                <div className={'pb-3'}>
                    <KirokuSectionDropdownShowMore
                        options={ getGroupedOptions('Team', teams) }
                        value={ currentTags }
                        onChange={ handleChangeTeams }
                        tagType={'Team'}
                        placeholder={t('Team.placeholderSearch')}
                        sizeCustomize={5}
                    />
                </div>
                <div style={ { paddingBottom: 17 } }>
                    <KirokuInputSearch onChange={ (event) => onChangeSearch(event) }
                               value={ keywordSearch }
                               placeholder={ t('RecordsBeta.filterByCustomerName') }
                               style={ {height: 36, fontSize: 14}}
                    />

                </div>
                <div id="body-modal-as">
                    {customersForModal.length ? <div className={ 'checkbox-bigger p-3 border-bottom d-flex justify-content-between data-in-modal' }>
                        <div className={'font-size-16'}>{ t('Customer.CustomerList') }</div>
                        <div className='text-primary' style={ { cursor: 'pointer' } }>
                                    <span
                                        hidden={ lodash.findIndex(customersForModal, customer => !customer.isChecked) === -1 }
                                        onClick={ () => handleCheckAll() }
                                        className={'font-size-16'}
                                    >
                                        { t('common.SelectAll') }
                                    </span>
                            <span
                                hidden={ lodash.findIndex(customersForModal, customer => !customer.isChecked) !== -1 }
                                onClick={ () => handleCheckAll() }
                                className={'font-size-16'}
                            >
                                        { t('common.UnSelect') }
                                    </span>
                        </div>
                    </div> : null }
                    <div className="background-color" style={ {
                        overflow : 'scroll',
                        minHeight: 'calc(100vh - 450px)',
                        maxHeight: 'calc(100vh - 450px)',
                        height   : 'auto',
                    } }>
                        <KirokuLoader loading={ loading }>
                            {!customersForModal.length ? <SearchNotFound description={' '} /> :
                                customersForModal.map((customer, index) => (
                                    <div key={ index }>
                                        <div className={ 'checkbox-bigger cursor-pointer' }
                                        onClick={ (event) => {
                                            event.preventDefault();
                                            handleCheck(customer.id);
                                        }}>
                                            <KirokuCheckboxBorder
                                                content={ <span className={ 'font-text-in-checkbox cursor-pointer' }>{ customer.name }</span> }
                                                id={ `user-select-modal-document-tab-${ index }` }
                                                name={ `user-select-modal-document-tab` }
                                                onChange={ () => handleCheck(customer.id) }
                                                checked={ !!customer.isChecked }
                                            />
                                        </div>
                                    </div>
                                )) }
                        </KirokuLoader>
                    </div>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton
                    onClick={ onClose }
                    color={'white'}
                >
                    { t('common.Cancel') }
                </KirokuButton>
                <KirokuButton
                    disabled={ !customers.filter(customer => customer.isChecked).length || isCopying}
                    color={ 'primary' }
                    onClick={ () => {
                        setIsCopying(true)
                        const payload = {
                            record, customers: customers.filter(customer => customer.isChecked).map(customer => customer.id),
                        };
                        create(payload).then(res => {
                            trackingMixpanel('COPY_RECORD_TO_CUSTOMERS', payload);
                            setIsCopying(false)
                        });
                    } }
                >
                    { t('common.Assign') }
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
};

import React                                                           from 'react';
import { ComponentMobile, ComponentWeb, KirokuLoader, SearchNotFound } from '../../../ui';
import If                                                              from '../../If';
import RecordTable                                                     from './RecordTable';
import RecordList                                                      from './RecordList';

export default function Records({ loading, result, sort_by, order_by_type, changeOrder }) {
    const records = result;

    const handleChangeOrderBy = (field) => {
        let typeOrder = '';
        if (field === 'delete_at') {
            typeOrder = order_by_type.delete_at === 'ASC' ? 'DESC' : 'ASC';
        } else {
            typeOrder = order_by_type.create_at === 'ASC' ? 'DESC' : 'ASC';
        }

        changeOrder(field, typeOrder);
    };

    return (
        <div>
            <KirokuLoader loading={loading}>
                <If statement={records.length}
                    otherwise={<SearchNotFound/>}
                >
                    {() => <React.Fragment>
                        <ComponentWeb>
                            <RecordTable
                                records={records}
                                sort_by={sort_by}
                                order_by_type={order_by_type}
                                handleChangeOrderBy={handleChangeOrderBy}
                            />
                        </ComponentWeb>
                        <ComponentMobile>
                            <RecordList
                                records={records}
                            />
                        </ComponentMobile>
                    </React.Fragment>
                    }
                </If>
            </KirokuLoader>
        </div>
    );
}

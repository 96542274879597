import React              from 'react';
import { Link }           from 'react-router-dom';
import LogLabel           from './LogLabel';
import moment             from 'moment';
import useUser            from '../LazyLoad/useUser';
import { KirokuLoader }   from '../../ui';
import { useTranslation } from 'react-i18next';
import { useHistory }     from 'react-router-dom';

export default function ({ log }) {
    const { t }             = useTranslation();
    const { user, loading } = useUser(log.user_id);
    const history           = useHistory();

    const onClickLink = (log) => {
        if (log.action === 'comment' && log.type === 'surveyRecord') {
            localStorage.setItem('commentId', log.data.comment && log.data.comment.id);
            return history.push(`/records/${ log['type_id'] }#comment-tab`);
        }
        if (log.action !== 'delete') {
            switch (log.type) {
                case 'surveyRecord' :
                    return history.push(`/records/${ log['type_id'] }`);
                case 'surveyTemplate' :
                    return history.push(`/surveys/${ log['type_id'] }`);
                case 'team' :
                    return history.push(`/teams/${ log['type_id'] }`);
                case 'customer' :
                    return history.push(`/customers/${ log['type_id'] }`);
                case 'plan' :
                    return history.push(`/plans/${ log['type_id'] }`);
                default :
                    return history.push(`/users/${ log['type_id'] }`);
            }
        }
    };

    return (
        <div>
            <KirokuLoader loading={ loading }>
                { user && log ?
                    <div className="d-flex pl-3 pr-3 pt-2 border-date-log">
                        <div>
                            <div className="mr-1 pt-10px">
                                <Link to={ `/users/${ user.id }` }>
                                    <div className='avatar' style={ {
                                        width             : 48,
                                        height            : 48,
                                        borderRadius      : '100%',
                                        backgroundImage   : `url(${ user.avatar ? user.avatar : 'assets/img/profile-ic.svg' })`,
                                        backgroundSize    : 'cover',
                                        backgroundPosition: 'center center',
                                    } }/>
                                </Link>
                            </div>
                        </div>
                        <div style={ { width: '100%' } } className={ 'pl-1 pb-1' }>
                            <div className="d-flex justify-content-between"
                                 style={ { flexWrap: 'wrap' } }>
                                <div>
                                    <span className={ 'font-size-title' }>{ user.username } </span>
                                    <span>{ t(`logs.${ log.action }${ log.type }`) }</span>
                                </div>
                                <LogLabel log={ log }/>
                            </div>
                            <div className={ 'pb-10px' }>
                                <b className={ `${ log.action !== 'delete' && 'text-primary cursor-pointer' }  ` }
                                   onClick={ () => onClickLink(log) }
                                >
                                    { log['type_name'] }
                                </b>
                            </div>
                            <div className={ 'pb-10px' }>
                                { user.username }
                            </div>
                            <div
                                className={ 'font-text-time' }> { moment.unix(log.create_at).format(t('dateTimeFormat.HourMinute')) }</div>
                        </div>
                    </div> : '' }
            </KirokuLoader>
        </div>
    );
}

import { useEffect, useState } from 'react';
import { createClient }        from 'contentful';
import config                  from '../config';

const client               = createClient(config.contentfulConfig);
export const useContentful = (contentType) => {
    const [data, setData]       = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        client.getEntries({
            content_type       : contentType,
            'fields.start[lte]': new Date().toISOString(),
            'fields.end[gte]'  : new Date().toISOString(),
            locale             : localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en-US',
        }).then(function (entries) {
            setData(entries);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setLoading(false);
        });
    }, [contentType]);

    return [data, loading];
};

import React              from 'react';
import { useTranslation } from 'react-i18next';

function KirokuCertificate(props) {
    const  {t} = useTranslation();
    const showCertificates = props['certificate'];
    return (
        <span>
                { showCertificates.map((showCertificate, index) =>
                    <span key={ index }
                          className="kiroku-btn-data mr-1">{ t(`Customer.certificate.${ showCertificate.key }`) }</span>,
                ) }
            </span>
    );
}

export default KirokuCertificate;

export default class CustomerFactory {

    make(rawCustomer) {
        const newCertifications = [
            {
                ...rawCustomer.certifications[0],
                monitoring_terms: rawCustomer.certifications[0].monitoring_terms.filter(tmp => tmp.date),
                services        : rawCustomer.certifications[0].services.filter(tmp => tmp.name || tmp.startDate || tmp.endDate),
            },
        ];

        delete rawCustomer.records;
        return {
            ...rawCustomer,
            contacts      : rawCustomer.contacts.filter(contact => (contact.name !== '' || contact.phone_number !== '')),
            certifications: newCertifications,
            report_month  : !rawCustomer.report_month ? 0 : rawCustomer.report_month
        };
    }

    makeFormRequest(rawCustomer) {
        return {
            ...rawCustomer,
            contacts      : this.checkContact(rawCustomer.contacts),
            certifications: this.checkCertification(rawCustomer.certifications),
        };
    }

    makeCustomerDuplicate(rawCustomer) {
        delete rawCustomer.id;
        return {
            ...rawCustomer,
            name          : '',
            furigana      : '',
            date_of_birth : null,
            address       : '',
            characteristic: '',
            post_code     : '',
            avatar        : '',
            blood_type    : 'Unknown',
            update_at     : null,
            contacts      : this.checkContact(rawCustomer.contacts),
            certifications: this.checkCertification(rawCustomer.certifications),
        };
    }

    checkCertification(certifications) {
        return certifications.length ? certifications : [
            {
                certification_number: '',
                issuer              : '',
                services            : [
                    {
                        name     : '',
                        startDate: '',
                        endDate  : '',
                    },
                ],
                monitoring_terms    : [
                    {
                        date: '',
                    },
                ],
            },
        ];
    }

    checkContact(contacts) {
        return contacts.length ? contacts : [
            {
                name        : '',
                phone_number: '',
                memo        : '',
                relationship: 'unknown',
            },
        ];
    }
}

import React              from "react";
import { useTranslation } from "react-i18next";

export const useGender = () => {
    const {t} = useTranslation();
    const genderType = [
        {
            name: 'CustomerEditor.unselected',
            type: 'unselected'
        },
        {
            name: 'CustomerEditor.Male',
            type: 'Male'
        },
        {
            name: 'CustomerEditor.Female',
            type: 'Female'
        }
    ]

    const selectGender = (genderType) => {
        return genderType.map(gender => ({
            value: gender.type,
            component: (
                <div>
                    <span className={'text-muted'}>{t(`CustomerEditor.${gender.type}`)}</span>
                </div>
            ),
            label: t(`CustomerEditor.${gender.type}`)
        }))
    };

    return [ selectGender(genderType) ]
}

import React, {useEffect, useState} from 'react';
import { useTranslation }         from 'react-i18next';
import useQuestions               from '../MakeSurvey/useQuestions';
import { getHeadingNextQuestion } from '../../Records/sevicesInRecord';
import {
    KirokuButton, KirokuDropdownResetLabelSelected,
    KirokuModal, KirokuModalBody,
    KirokuModalFooter, KirokuModalHeader,
}                                 from '../../../ui';
import { getDropDownItems }       from './index';

export default function Single({ isOpen, question, confirm, cancellation }) {
    const { t }                 = useTranslation();
    const { update, questions } = useQuestions();

    const [choices, setChoices] = useState([]);

    useEffect(() => {
        let newChoices = question.choices.map((choice, index) => {
                return {
                    ...choice,
                    answer: choice.answer,
                    id    : choice.nextQuestion || '',
                    label : getHeadingNextQuestion(question, questions)[ index ],
                };
            })
            setChoices(newChoices);
    }, [question, questions])

    return (
        <KirokuModal show={isOpen}>
            <KirokuModalHeader color={'card-web'}>
                {t('createSurvey.QuestionOrder', { index: question.index + 1 })}
            </KirokuModalHeader>
            <KirokuModalBody>
                <div className='p-3'>
                    <b>{question.heading}</b>
                    <p>{question.content} </p>
                    {choices.map((choice, index) => {
                        return (
                            <div className='pb-2' key={index}>
                                <div>{choice.answer}</div>
                                <KirokuDropdownResetLabelSelected
                                    title={choice.label}
                                    items={getDropDownItems(question, questions)}
                                    onSelect={(event) => {
                                        if (event.kirokuSelected.value > question.index) {
                                            setChoices(choices.map((choice, i) => {
                                                if (i === index) {
                                                    return {
                                                        ...choice,
                                                        id   : event.kirokuSelected.value,
                                                        label: event.kirokuSelected.label,
                                                    };
                                                }
                                                return choice;
                                            }));
                                        }
                                    }}/>
                            </div>
                        );
                    })}

                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton
                    onClick={() => {
                        confirm();
                        delete question.unrequited;
                        update({
                            ...question,
                            choices: choices.map(choice => {
                                return {
                                    defaultAnswer: choice.defaultAnswer,
                                    answer       : choice.answer,
                                    nextQuestion : choice.id,
                                };
                            }),
                        });
                    }}
                    color={'primary'}>
                    {t('createSurvey.Confirm')}
                </KirokuButton>
                <KirokuButton onClick={() => {
                    cancellation();
                }} color={'white'}>
                    {t('createSurvey.Cancel')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

import React                        from 'react';
import { useTranslation }           from 'react-i18next';
import { KirokuDropdownResetTitle } from '../../../ui';


export default function TabComponentMobile({ handleSelect, monthSelectionRange, count, tabType, isGroupCustomer }) {
    const { t } = useTranslation();

    const dropdownMonthlyTab = [
        {
            value    : 'monitor_term',
            component: (<div>{ t('plans.monitoringTermsTab') } ({count.countCustomer})</div>),
            label    : (<div>{ t('plans.monitoringTermsTab') } ({count.countCustomer})</div>),
        },
        {
            value    : 'condition',
            component: (<div>{ t('plans.planTab') } ({count.countPlan})</div>),
            label    : (<div>{ t('plans.planTab') } ({count.countPlan})</div>),
        },
        {
            value    : 'report_month',
            component: (<div>{ t('CustomerEditor.ReportMonthTab') }({count.countReport})</div>),
            label    : (<div>{ t('CustomerEditor.ReportMonthTab') }({count.countReport})</div>),
        },
        {
            value    : 'certification_expiration',
            component: (<div>{ t('Customer.CustomerExpiration') }({count.countExpiration})</div>),
            label    : (<div>{ t('Customer.CustomerExpiration') }({count.countExpiration})</div>),
        },
        {
            value    : 'services_end_date',
            component: (<div>{ t('Customer.service_end_date') }({count.countServiceEndDate})</div>),
            label    : (<div>{ t('Customer.service_end_date') }({count.countServiceEndDate})</div>),
        }
    ];

    const getTitleDropDown = () => {

        const itemSelect = dropdownMonthlyTab.find(item => {
            if(isGroupCustomer) {
                return item.value === 'services_end_date'
            }
            return item.value === tabType
        })
        if(itemSelect) {
            return itemSelect.label
        }
        return <div>{t('plans.monitoringTermsTab')} ({count.countCustomer})</div>
    }

    return (
        <div>
            <KirokuDropdownResetTitle
                title={getTitleDropDown()}
                style={{ width: 230}}
                onSelect={ (event) => {
                    if (event.kirokuSelected.value === 'monitor_term') {
                        handleSelect({
                            type        : 'monitor_term',
                            page        : 1,
                            monitor_term: monthSelectionRange.startMonth,
                            actionSearch: null,
                        });
                    }
                    if(isGroupCustomer && (event.kirokuSelected.value === 'services_end_date')) {
                        handleSelect({
                            type                      : 'customer_services_end_date',
                            page                      : 1,
                            customer_services_end_date: monthSelectionRange.startMonth,
                            actionSearch              : null,
                        });
                    }
                    if (!isGroupCustomer && event.kirokuSelected.value === 'services_end_date') {
                        handleSelect({
                            type        : 'services_end_date',
                            page        : 1,
                            services_end_date: monthSelectionRange.startMonth,
                            actionSearch: null,
                        });
                    }
                    if (event.kirokuSelected.value === 'condition') {
                        handleSelect({
                            type        : 'condition', page: 1,
                            startMonth  : monthSelectionRange.startMonth,
                            endMonth    : monthSelectionRange.endMonth,
                            actionSearch: null,
                        });
                    }
                    if (event.kirokuSelected.value === 'report_month') {
                        handleSelect({
                            type        : 'report_month',
                            page        : 1,
                            report_month: monthSelectionRange.startMonth,
                            actionSearch: null,
                        });
                    }
                    if (event.kirokuSelected.value === 'certification_expiration') {
                        handleSelect({
                            type                    : 'certification_expiration',
                            page                    : 1,
                            certification_expiration: monthSelectionRange.startMonth,
                            actionSearch            : null,
                        });
                    }
                } }
                items={ dropdownMonthlyTab }
            />
        </div>
    );
}

import React, { useReducer } from 'react';
import RecordContext         from './RecordContext';
import RecordReducer         from './RecordReducer';
import moment                from 'moment';

export default function RecordProvider({ children, customerDefault = [], analysisCondition = {}}) {
    let customerCondition = analysisCondition.customerId.length ? analysisCondition.customerId.map(customer => customer.id) : customerDefault.map(customer => customer.id);
    const initCondition = {
        status          : 'recorded',
        serviceStartDate: analysisCondition.startDate || moment().subtract(1, 'month').startOf('day').unix(),
        serviceEndDate  : analysisCondition.endDate || moment().endOf('day').unix(),
        updateStartDate : null,
        updateEndDate   : null,
        cId             : customerCondition,
        udId            : analysisCondition.updaterId.length ? analysisCondition.updaterId : [],
        tId             : analysisCondition.templateId.length ? analysisCondition.templateId : [],
        answer_search   : '',
        contain_read    : !analysisCondition.startDate || false,
        order_by        : 'DESC',
        sort_by         : 'serviced_at',
        customerTab     : analysisCondition.customerId.length ? 'SEARCH_CUSTOM_CUSTOMER' :'SEARCH_BY_CUSTOMER_TAG',
        page            : 1,
    };

    const [recordCondition, dispatch] = useReducer(RecordReducer, initCondition, () => initCondition);

    return (
        <RecordContext.Provider value={{ recordCondition, dispatch }}>
            {children}
        </RecordContext.Provider>
    );
}

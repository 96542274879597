import Dashboard          from '../Dashboard/Dashboard';
import PreviewTakeSurvey  from '../Templates/PreviewTakeSurvey/PreviewTakeSurvey';
import TeamListScreen     from '../Teams/teamList/TeamListScreen';
import CreateTeam         from '../Teams/TeamCreator/CreateTeam';
import UpdateTeam         from '../Teams/TeamUpdator/UpdateTeam';
import TeamDetail         from '../Teams/TeamDetail/TeamDetail';
import CustomerList       from '../Customer/CustomerList/CustomerList';
import Organization       from '../Organization';
import SurveyCreator      from '../Templates/Creator/SurveyCreator';
import SurveyEditor       from '../Templates/Editor/SurveyEditor';
import SurveyDuplicate    from '../Templates/Duplicate/SurveyDuplicate';
import CustomerDetail     from '../Customer/CustomerDetail/CustomerDetail';
import CustomerEditor     from '../Customer/CustomerEditor/CustomerEditor';
import CustomerCreator    from '../Customer/CustomerCreator/CustomerCreator';
import Logs               from '../Logs/Logs';
import UserDetail         from '../User/UserDetail/UserDetail';
import UserEditor         from '../User/UserEditor/UserEditor';
import UserCreator        from '../User/UserCreator/UserCreator';
import CustomerDuplicate  from '../Customer/CustomerDuplicate/CustomerDuplicate';
import UserList           from '../User/UserList/UserList';
import Exports            from '../AdminExport/Exports';
import ReportScreen       from '../Report/ReportScreen';
import Records            from '../Recordsv2/Records';
import DetailRecordV2     from '../Recordsv2/DetailRecord/DetailRecord';
import UserDuplicate      from '../User/UserDuplicate/UserDuplicate';
import DeletedList        from '../Deleted/DelectedList';
import Analysis           from '../Analysis/Analysis';
import Planning           from '../Planning/Planning';
import PlanningDetail     from '../Planning/PLanningDetail/PlanningDetail';
import PlanningCreator    from '../Planning/PlanningCreator/PlanningCreator';
import PlanningUpdater    from '../Planning/PlanningUpdater/PlanningUpdater';
import SupportDetail      from '../Planning/planSupporter/SupportDetail';
import Templates          from '../TemplatesV2/TemplateList/Templates';
import { DetailTemplate } from '../TemplatesV2/DetailSurvey/DetailTemplate';
import PlanningDuplicate  from '../Planning/PlanningDuplicate/PlanningDuplicate';
import SupportCreator     from '../Planning/planSupporter/SupportCreator';
import SupportUpdater     from '../Planning/planSupporter/SupportUpdater';
import RecordCompleted    from '../Records/RecordTaking/CreateRecordCompleted/RecordCompleted';
import TakeRecord         from '../Records/RecordTaking/RecordCreate/TakeRecord';
import RecordEditor       from '../Records/RecordTaking/RecordEdit/RecordEditor';
import Customers          from '../MonitoringTerm/Customers';
import MyProfile          from '../User/MyProfile/MyProfile';

export default [
    {
        path     : '/ ',
        exact    : true,
        component: Dashboard,
    }, {
        path     : '/surveys',
        exact    : true,
        component: Templates,
    }, {
        path     : '/surveys/create',
        exact    : true,
        component: SurveyCreator,
    }, {
        path     : '/surveys/edit/:id',
        exact    : true,
        component: SurveyEditor,
    }, {
        path     : '/surveys/duplicate/:id',
        exact    : true,
        component: SurveyDuplicate,
    }, {
        path     : '/surveys/preview/:id',
        exact    : true,
        component: PreviewTakeSurvey,
    }, {
        path     : '/surveys/:id',
        exact    : true,
        component: DetailTemplate,
    }, {
        path     : '/records',
        exact    : true,
        component: Records,
    }, {
        path     : '/records/create/customer/:customerId/survey/:surveyId',
        exact    : true,
        component: TakeRecord,
    }, {
        path     : '/records/edit/:id',
        exact    : true,
        component: RecordEditor,
    }, {
        path     : '/records/:id',
        exact    : true,
        component: DetailRecordV2,
    }, {
        path     : '/teams',
        exact    : true,
        component: TeamListScreen,
    }, {
        path     : '/teams/create',
        exact    : true,
        component: CreateTeam,
    }, {
        path     : '/teams/edit/:id',
        exact    : true,
        component: UpdateTeam,
    }, {
        path     : '/teams/:id',
        exact    : true,
        component: TeamDetail,
    }, {
        path     : '/customers',
        exact    : true,
        component: CustomerList,
    }, {
        path     : '/customers/create',
        exact    : true,
        component: CustomerCreator,
    }, {
        path     : '/customers/:id',
        exact    : true,
        component: CustomerDetail,
    }, {
        path     : '/customers/edit/:id',
        exact    : true,
        component: CustomerEditor,
    }, {
        path     : '/customers/duplicate/:id',
        exact    : true,
        component: CustomerDuplicate,
    }, {
        path     : '/organization',
        exact    : true,
        component: Organization,
    }, {
        path     : '/users',
        exact    : true,
        component: UserList,
    }, {
        path     : '/users/create',
        exact    : true,
        component: UserCreator,
    }, {
        path     : '/users/edit/:id',
        exact    : true,
        component: UserEditor,
    }, {
        path     : '/users/duplicate/:id',
        exact    : true,
        component: UserDuplicate,
    }, {
        path     : '/users/edit-my-profile',
        exact    : true,
        component: MyProfile,
    }, {
        path     : '/users/:id',
        exact    : true,
        component: UserDetail,
    }, {
        path     : '/exports',
        exact    : true,
        component: Exports,
    }, {
        path     : '/analysis',
        exact    : true,
        component: Analysis,
    }, {
        path     : '/logs',
        exact    : true,
        component: Logs,
    }, {
        path     : '/reports',
        exact    : true,
        component: ReportScreen,
    }, {
        path     : '/deleted',
        exact    : true,
        component: DeletedList,
    }, {
        path     : '/plans',
        exact    : true,
        component: Planning,
    }, {
        path     : '/plans/create',
        exact    : true,
        component: PlanningCreator,
    }, {
        path     : '/plans/:id',
        exact    : true,
        component: PlanningDetail,
    }, {
        path     : '/plans/duplicate/:id',
        exact    : true,
        component: PlanningDuplicate,
    }, {
        path     : '/plans/edit/:id',
        exact    : true,
        component: PlanningUpdater,
    }, {
        path     : '/supports/create',
        exact    : true,
        component: SupportCreator,
    }, {
        path     : '/supports/:id',
        exact    : true,
        component: SupportDetail,
    }, {
        path     : '/supports/edit/:id',
        exact    : true,
        component: SupportUpdater,
    }, {
        path     : '/customers-monitoring',
        exact    : true,
        component: Customers,
    }, {
        path     : '/record-completed',
        exact    : true,
        component: RecordCompleted,
    },
];

import React, { useEffect, useState }                           from "react";
import { KirokuActionSurvey, KirokuDropdownClose, KirokuInput } from "../../../../ui";
import { useTranslation }                                       from "react-i18next";
import { useRelationship }                                      from "./useRelationship";

function Name({ name, handleOnChange }) {
    const { t } = useTranslation()
    const [ currentName, setCurrentName ] = useState(name);

    useEffect(() => {
        setCurrentName(name);
    }, [ name ]);

    return (
        <KirokuInput
            value={currentName}
            name={'name'}
            onChange={(e) => setCurrentName(e.target.value)}
            onBlur={handleOnChange}
            placeholder={t('common.Name')}
        />
    );
}

function PhoneNumber({ phone_number, handleOnChange }) {
    const { t } = useTranslation();
    const [ currentPhone, setCurrentPhone ] = useState(phone_number);

    useEffect(() => {
        setCurrentPhone(phone_number);
    }, [ phone_number ]);

    return (
        <KirokuInput
            value={currentPhone}
            name='phone_number'
            placeholder={t('common.Phone')}
            onChange={(e) => setCurrentPhone(e.target.value.replace(/[^0-9]/g, ''))}
            onBlur={handleOnChange}
        />
    )
}

function Relationship({ relationship, handleOnChange }) {

    const { t } = useTranslation();
    const [ listRelationship ] = useRelationship();
    return (
        <KirokuDropdownClose
            title={relationship ? t(`CustomerEditor.contact.${relationship}`) : t('CustomerEditor.contact.unknown')}
            items={listRelationship}
            onSelect={e => {
                const relationship = e.kirokuSelected.label === "不明" ? 'unknown' : e.kirokuSelected.value;
                handleOnChange(relationship)
            }}
        />
    )
}

function Memo({ memo, handleOnChange }) {

    const { t } = useTranslation();
    const [ currentMemo, setCurrentMemo ] = useState(memo);

    useEffect(() => {
        setCurrentMemo(memo);
    }, [ memo ]);

    return (
        <KirokuInput
            value={currentMemo}
            name={'memo'}
            onChange={(e) => setCurrentMemo(e.target.value)}
            placeholder={t('common.Memo')}
            onBlur={handleOnChange}
        />
    )
}

export default function ContactsForm({ contact, showButtonRemove, onChange, remove }) {

    const handleOnchange = e => {
        const newContact = { ...contact };
        newContact[e.target.name] = e.target.value;

        onChange(newContact)
    };

    const handleChangeRelationship = (value) => {
        const newContact = { ...contact };
        newContact['relationship'] = value;

        onChange(newContact)
    }

    return (
        <>
            <div className='pt-3 d-flex' style={{width: '100%'}}>
                <div className='row' style={{width: '90%'}}>
                    <div className='col-md-4 pb-3'>
                        <Name name={contact['name']} handleOnChange={handleOnchange}/>
                    </div>
                    <div className='col-md-4 pb-3'>
                        <PhoneNumber phone_number={contact['phone_number']} handleOnChange={handleOnchange}/>
                    </div>

                    <div className='col-md-4 pb-3'>
                        <Relationship relationship={contact['relationship']} handleOnChange={handleChangeRelationship}/>
                    </div>
                </div>
                <div className='action-fix pb-3' style={{width: '10%', float: 'left'}}>
                    <div className='pl-4 cursor-pointer' style={{width: 20}}>
                        <div
                            hidden={showButtonRemove}
                            onClick={remove}
                        >
                            <KirokuActionSurvey icon={'minus'}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row' style={{width: '90%'}}>
                <div className='col-md-12'>
                    <Memo memo={contact['memo']} handleOnChange={handleOnchange}/>
                </div>
                <hr className='mb-0' style={{width: '90%', float: 'left'}} hidden={showButtonRemove}/>
            </div>
        </>
    );
}

import client from '../../kirokuAPIClient';
import useSWR from 'swr/esm/use-swr';

const userFetcher = (userId) => {
    //get real user's id
    return client.User.lazyLoad(userId.split('-')[1])
}

export default (userId) => {
    //to pass falsy when user id is zero
    const { data, error } = useSWR('user-'.concat(userId), userFetcher)

    return {
        user: data,
        loading: !data && !error,
        error: error
    }
}

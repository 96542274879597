import React, { useEffect, useReducer }         from 'react';
import DocumentReducer, { LOAD_DATA_DOCUMENTS } from './DocumentReducer';
import DocumentContext                          from './DocumentContext';
import { authentication }                       from '@kiroku/api';

export default function DocumentProvider({ documentsData, customer, children }) {

    const defaultDocumentState = {
        documentsData       : {
            currentPage: 1,
            totalPages : 1,
            documents  : [],
            ...documentsData,
        },
        customer            : customer,
        modalInfo           : { name: '' },
    };
    const [
              documentState,
              dispatch,
          ]                    = useReducer(DocumentReducer, defaultDocumentState, () => defaultDocumentState);
    const { user }             = authentication.useAuthenticatedContext();


    useEffect(() => {
        dispatch({
            type       : LOAD_DATA_DOCUMENTS,
            documents  : documentsData.documents,
            loading    : documentsData.loading,
            currentPage: documentsData.currentPage,
            totalPage  : documentsData.totalPage,
        });
    }, [documentsData]);


    return (
        <DocumentContext.Provider
            value={{
                dispatch,
                loginUserInfo       : user,
                customer            : customer,
                modalInfo           : documentState.modalInfo,
                documentsData       : documentState.documentsData,
            }}>
            {children}
        </DocumentContext.Provider>
    );
}

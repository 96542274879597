import { useTranslation }                                                     from 'react-i18next';
import React, { useState }                                                    from 'react';
import useQuestions                                                           from '../MakeSurvey/useQuestions';
import { KirokuButton, KirokuCardBodyWeb, KirokuCardTitleWeb, KirokuCardWeb } from '../../../ui';
import { InputHeadingQuestion }                                               from './InputHeadingQuestion';
import { InputContentQuestion }                                               from './InputContentQuestion';
import { Choices, QuestionTypeOptions }                                       from './QuestionTypeOptions';
import If                                                                     from '../../If';
import config                                                                 from '../../../config';

export function CreateQuestionForm({ children }) {
    const { t }                   = useTranslation();
    const [question, setQuestion] = useState({
        heading: '', content: '',
        type   : 'InputText',
    });
    const { create, questions }   = useQuestions();
    const isQuestionUseChoices    = ['MultiChoice', 'Single'].includes(question.type);

    const choices = question.choices || [{ answer: '', validate: true }, { answer: '', validate: true }, { answer: '', validate: true }];

    const validateQuestion = () => {
        if (isQuestionUseChoices) {
            return !question.content || choices.filter(choice => !choice.answer).length || questions.length >= config.maxAmountQuestion;
        }
        return !question.content || questions.length >= config.maxAmountQuestion;
    };

    const onChangeChoiceAnswer = (event, i) => {
        setQuestion({
            ...question,
            choices: choices.map((choice, index) => {
                if (index === i) {
                    return {
                        answer  : event.target.value,
                        validate: !!event.target.value,
                    };
                }
                return {
                    ...choice,
                    validate: !!choice.answer,
                };
            }),
        });
    };

    const handleDelChoice = (index) => {
        if (choices.length > 2) {
            choices.splice(index, 1);
            setQuestion({
                ...question,
                choices: choices,
            });
        }
    };

    const handleAddChoice = () => {
        choices.push({ answer: '', validate: true });
        setQuestion({
            ...question,
            choices: choices,
        });
    };

    const submitCreateQuestion = () => {
        if (isQuestionUseChoices) {
            create({
                ...question,
                choices: question.choices.map(choice => {
                    return { answer: choice.answer };
                }),
            });
        } else {
            create(question);
        }

        setQuestion({
            type   : question.type,
            heading: '', content: '',
        });
    };

    return <div className={'pt-3'}>
        <KirokuCardWeb>
            <KirokuCardTitleWeb color='success'>
                <h4 className='text-dark card-title-info'>{t('createSurvey.CreateQuestion', 1)}</h4>
            </KirokuCardTitleWeb>
            <KirokuCardBodyWeb>
                <div className='summary-content2'>
                    <InputHeadingQuestion
                        {...question}
                        onBlur={(event) => {
                            setQuestion({
                                ...question,
                                heading: event.target.value,
                            });
                        }}
                    />
                    <div className={'pt-3'}/>
                    <InputContentQuestion
                        {...question}
                        onBlur={(event) => {
                            setQuestion({
                                ...question,
                                content: event.target.value,
                            });
                        }}
                    />
                </div>

                <div className='pt-3'>
                    <QuestionTypeOptions
                        question={question}
                        onChange={event => {
                            setQuestion({
                                ...question,
                                type: event.target.value,
                            });
                        }}
                    />
                    <If statement={isQuestionUseChoices}>
                        {() => {
                            return <Choices
                                question={{ ...question, choices }}
                                onChangeChoiceAnswer={(event, i) => onChangeChoiceAnswer(event, i)}
                                handleDelChoice={(index) => handleDelChoice(index)}
                                handleAddChoice={() => handleAddChoice()}
                            />;
                        }}
                    </If>
                </div>
            </KirokuCardBodyWeb>
        </KirokuCardWeb>

        <div className='pt-4 btn-group-action-template'>
            <KirokuButton
                disabled={validateQuestion()}
                onClick={() => submitCreateQuestion()}
                color='primary'
                style={{minWidth: 'max-content'}}
                className={'col-12 col-sm-12 col-md-2 btn-width'}
            >
                <i className='p-1 fas fa-plus'/>
                <span className='pl-1'>{t('createSurvey.SaveQuestion')}</span>
            </KirokuButton>
            {children}
            <div/>
        </div>
    </div>;
}





import React, { useEffect }   from 'react';
import { useTranslation }     from 'react-i18next';
import { resources }          from '@kiroku/api';
import If                     from '../../If';
import {
    KirokuCard, KirokuCardList, KirokuAge,
    ComponentWeb, KirokuTable, TBody,
    KirokuLoader, ComponentMobile, THeader, KirokuPagination, KirokuButton, KirokuCardWeb,
} from '../../../ui';
import { Link }               from 'react-router-dom';
import TabResult              from './TabResult';
import { WrapperPermissions } from '../../HOC';
import { useHistory }         from 'react-router-dom';

export default function TabCustomersComponent({ isShow, userId }) {
    const { t } = useTranslation();
    const history = useHistory();
    const {
              result, search, loading,
              pagination, nextPage, prevPage,
          } = resources.useSearch('Customer', { type: 'condition', users: userId, page: 1 });

    useEffect(() => {
        if (userId && isShow) {
            search({ type: 'condition', users: userId, page: 1 });
        }
    }, [userId, isShow, search]);

    return (
        <div hidden={!isShow}>
            <KirokuLoader loading={loading}>
                <If statement={result.length}
                    otherwise={<KirokuCardWeb>
                        <TabResult messageFirst={'customer1'} messageSecond={'customer2'}
                        >
                            <img className='pt-2 pb-2'
                                 src={`/assets/img/team-icon@2x.png`}
                                 alt="no team"/>
                        </TabResult>
                        <WrapperPermissions
                            resource-permission={'|staffDetail|button-editProfile|'}
                            className={'pb-3'}
                        >
                            <div className='text-center'>
                                <KirokuButton onClick={() => history.push(`/customers`)} color={'primary'}>
                                    {t('NullPage.customerlist')}
                                </KirokuButton>
                            </div>
                        </WrapperPermissions>
                    </KirokuCardWeb>}
                >
                    {() => {
                        return <React.Fragment>
                            <div className="d-flex justify-content-between">
                                <div/>
                                <KirokuPagination
                                    className={'pb-3'}
                                    disabledPrev={pagination.currentPage === 1}
                                    disabledNext={pagination.currentPage === pagination.totalPage || pagination.totalPage === 0}
                                    increasePage={nextPage}
                                    decreasePage={prevPage}
                                    currentPage={pagination.currentPage}
                                    totalPage={pagination.totalPage}
                                />
                            </div>
                            <ComponentWeb>
                                <KirokuTable>
                                    <THeader>
                                        <tr>
                                            <th>{t('common.Name')}</th>
                                            <th>{t('common.Age')}</th>
                                            <th>{t('CustomerEditor.KindOfCertificate')}</th>
                                            <th className='text-center'/>
                                        </tr>
                                    </THeader>
                                    <TBody>
                                        {result.map(customer => {
                                                return <tr key={customer.id}>
                                                    <td><Link
                                                        to={`/customers/${customer.id}`}>{customer.name}</Link>
                                                    </td>
                                                    <td><KirokuAge dob={customer[ 'date_of_birth' ]}/></td>
                                                    <td>{customer.certificate.map(item => {
                                                        return t(`Customer.certificate.${item}`);
                                                    }).join(', ')}</td>
                                                    <td className='fix-center'/>
                                                </tr>;
                                            },
                                        )}
                                    </TBody>
                                </KirokuTable>
                            </ComponentWeb>

                            <ComponentMobile>
                                <KirokuCardList
                                    headerLeft={<span>{t('common.Name')}</span>}
                                    headerRight={<span>
                                    {t('common.Age')}
                            </span>}
                                >
                                    {result.map((customer, index) =>
                                        <div key={index} style={{ backgroundColor: index % 2 !== 0 ? 'rgba(197,208,222,.1)' : '' }}>
                                            <KirokuCard
                                                action={<span>
                                                        <KirokuAge dob={customer[ 'date_of_birth' ]}/>
                                                    </span>
                                                }
                                            >
                                                <Link
                                                    to={`/customers/${customer.id}`}>{customer.name}&nbsp;&nbsp;</Link>
                                            </KirokuCard>
                                        </div>,
                                    )}
                                </KirokuCardList>
                            </ComponentMobile>


                            <div className="d-flex justify-content-between">
                                <div/>
                                <KirokuPagination
                                    className={'pt-3'}
                                    disabledPrev={pagination.currentPage === 1}
                                    disabledNext={pagination.currentPage === pagination.totalPage || pagination.totalPage === 0}
                                    increasePage={nextPage}
                                    decreasePage={prevPage}
                                    currentPage={pagination.currentPage}
                                    totalPage={pagination.totalPage}
                                />
                            </div>
                        </React.Fragment>;
                    }}
                </If>
            </KirokuLoader>
        </div>
    );
}

import { KirokuButton, KirokuModalSurvey, KirokuCardBodyWeb, KirokuCardTitleWeb, KirokuCardWeb } from '../../../ui';
import React, { useEffect, useState }                                                            from 'react';
import { useTranslation }                                                                        from 'react-i18next';
import lodash                              from 'lodash';
import { revertInitQuestion }              from '../SurveyService';
import { InputHeadingQuestion }                                 from './InputHeadingQuestion';
import { InputContentQuestion }                                 from './InputContentQuestion';
import { Choices, QuestionTypeOptions }                         from './QuestionTypeOptions';
import If                                                       from '../../If';

export function EditorQuestion({ question, setQuestion, onClickClose }) {
    const { t } = useTranslation();

    const choices              = question.choices || [{ answer: '', validate: false }, { answer: '', validate: false }, { answer: '', validate: false }];
    const onChangeChoiceAnswer = (event, i) => {
        setQuestion({
            ...question,
            choices: choices.map((choice, index) => {
                if (index === i) {
                    return {
                        answer  : event.target.value,
                        validate: !!event.target.value,
                    };
                }
                return {
                    ...choice,
                    validate: !!choice.answer,
                };
            }),
        });
    };

    const handleDelChoice = (index) => {
        if (choices.length > 2) {
            choices.splice(index, 1);
            setQuestion({
                ...question,
                choices: choices,
            });
        }
    };

    const handleAddChoice = () => {
        choices.push({ answer: '', validate: true });
        setQuestion({
            ...question,
            choices: choices,
        });
    };

    return <KirokuCardWeb>
        <KirokuCardTitleWeb color='success'>
            <div className="d-flex justify-content-between">
                <h4 className='text-dark card-title-info'>{t('createSurvey.QuestionOrder', { index: question.index + 1 })}</h4>
                <button className='close' style={ { float: 'right', marginBottom: 12 } }
                        onClick={ onClickClose }>
                    <span style={ { fontSize: '24px', color: '#a8aab7' } }>&times;</span>
                </button>
            </div>
        </KirokuCardTitleWeb>
        <KirokuCardBodyWeb>
            <div style={question.type === 'MultiChoice' ||  question.type === 'Single' ? {
                overflow : 'scroll',
                height: 'auto',
                minHeight: 'calc(100vh - 240px)',
                maxHeight: 'calc(100vh - 240px)'
            }: {}}>
                <div className='summary-content2'>
                    <InputHeadingQuestion
                        {...question}
                        onBlur={(event) => {
                            setQuestion({
                                ...question,
                                heading: event.target.value,
                            });
                        }}
                    />
                    <div className={'pt-3'}/>
                    <InputContentQuestion
                        {...question}
                        onBlur={(event) => {
                            setQuestion({
                                ...question,
                                content: event.target.value,
                            });
                        }}
                    />
                </div>
                <div className='pt-3'>
                    <QuestionTypeOptions
                        question={question}
                        onChange={event => {
                            let newQuestion = {
                                ...question,
                                type: event.target.value,
                            };
                            if(newQuestion.defaultAnswer) {
                                delete newQuestion.defaultAnswer;
                            }
                            if(newQuestion.nextQuestion) {
                                delete newQuestion.nextQuestion;
                            }
                            if(newQuestion.nextQuestionYes) {
                                delete newQuestion.nextQuestionYes;
                            }
                            if(newQuestion.nextQuestionNo) {
                                delete newQuestion.nextQuestionNo;
                            }
                            if(!newQuestion.choices && (event.target.value === 'Single' || event.target.value === 'MultiChoice') ) {
                                newQuestion = {
                                    ...question,
                                    type: event.target.value,
                                    choices: [{ answer: '', validate: false }, { answer: '', validate: false }, { answer: '', validate: false }],
                                }
                            }
                            if(event.target.value !== 'Single' && event.target.value !== 'MultiChoice') {
                                delete newQuestion.choices
                            }
                            if(newQuestion.unrequited) {
                                delete newQuestion.unrequited
                            }
                            setQuestion(newQuestion);
                        }}
                    />
                    <If statement={['MultiChoice', 'Single'].includes(question.type)}>
                        {() => {
                            return <Choices
                                question={{ ...question, choices }}
                                onChangeChoiceAnswer={(event, i) => onChangeChoiceAnswer(event, i)}
                                handleDelChoice={(index) => handleDelChoice(index)}
                                handleAddChoice={() => handleAddChoice()}
                            />;
                        }}
                    </If>
                </div>
            </div>
        </KirokuCardBodyWeb>
    </KirokuCardWeb>;
}

export default function ModalEditQuestion({isOpenModalEditQuestion, question, handleAction, updateQuestion}) {
    const [questionBeingChanged, setQuestionBeingChanged] = useState({});

    useEffect(() => {
        if (isOpenModalEditQuestion) {
            setQuestionBeingChanged(question);
        }
    }, [question, isOpenModalEditQuestion]);

    const validateEditQuestion = () => {
        let newQuestion = {
            ...questionBeingChanged,
        };

        if (questionBeingChanged.type !== 'MultiChoice' && questionBeingChanged.type !== 'Single') {
            delete newQuestion[ 'choices' ];
            return !newQuestion.content;
        } else {
            return newQuestion.choices === undefined || lodash.findIndex(newQuestion.choices, { 'answer': '' }) !== -1 || !newQuestion.content;
        }
    };



    const handleUpdateQuestion = () => {
        updateQuestion({
            index: questionBeingChanged.index,
            ...revertInitQuestion(questionBeingChanged),
        });
        handleAction();
    };


    const { t } = useTranslation();
    return (
        <KirokuModalSurvey
            show={isOpenModalEditQuestion}
        >
            <EditorQuestion
                question={questionBeingChanged}
                setQuestion={(questionAfterChanged) => {
                    setQuestionBeingChanged(questionAfterChanged);
                }}
                onClickClose={handleAction}
            />
            <hr/>
            <div className='fix-center pb-3'>
                <div className='d-flex justify-content-between'>
                    <KirokuButton color={'primary'}
                                  onClick={() => handleUpdateQuestion()}
                                  disabled={validateEditQuestion()}
                    >
                        {t('common.Apply')}
                    </KirokuButton>
                    <div className='pr-1 pl-1'/>
                    <KirokuButton
                        color={'white'}
                        onClick={handleAction}
                    >
                        {t('common.Cancel')}
                    </KirokuButton>
                </div>
            </div>
        </KirokuModalSurvey>
    )
}

import { useTranslation }                                       from 'react-i18next';
import React                                                    from 'react';
import { KirokuCardBodyWeb, KirokuCardTitleWeb, KirokuCardWeb } from '../../../ui';
import { InputHeadingQuestion }                                 from './InputHeadingQuestion';
import { InputContentQuestion }                                 from './InputContentQuestion';
import { Choices, QuestionTypeOptions }                         from './QuestionTypeOptions';
import If                                                       from '../../If';


export function EditorQuestionForm({ question, setQuestion }) {
    const { t } = useTranslation();

    const choices              = question.choices || [{ answer: '', validate: false }, { answer: '', validate: false }, { answer: '', validate: false }];
    const onChangeChoiceAnswer = (event, i) => {
        setQuestion({
            ...question,
            choices: choices.map((choice, index) => {
                if (index === i) {
                    return {
                        answer  : event.target.value,
                        validate: !!event.target.value,
                    };
                }
                return {
                    ...choice,
                    validate: !!choice.answer,
                };
            }),
        });
    };

    const handleDelChoice = (index) => {
        if (choices.length > 2) {
            choices.splice(index, 1);
            setQuestion({
                ...question,
                choices: choices,
            });
        }
    };

    const handleAddChoice = () => {
        choices.push({ answer: '', validate: true });
        setQuestion({
            ...question,
            choices: choices,
        });
    };

    return <KirokuCardWeb>
        <KirokuCardTitleWeb color='success'>
            <h4 className='text-dark card-title-info'>{t('createSurvey.QuestionOrder', { index: question.index + 1 })}</h4>
        </KirokuCardTitleWeb>
        <KirokuCardBodyWeb>
            <div className='summary-content2'>
                <InputHeadingQuestion
                    {...question}
                    onBlur={(event) => {
                        setQuestion({
                            ...question,
                            heading: event.target.value,
                        });
                    }}
                />
                <div className={'pt-3'}/>
                <InputContentQuestion
                    {...question}
                    onBlur={(event) => {
                        setQuestion({
                            ...question,
                            content: event.target.value,
                        });
                    }}
                />
            </div>
            <div className='pt-3'>
                <QuestionTypeOptions
                    question={question}
                    onChange={event => {
                        let newQuestion = {
                            ...question,
                            type: event.target.value,
                        };
                        if(newQuestion.defaultAnswer) {
                            delete newQuestion.defaultAnswer;
                        }
                        if(newQuestion.nextQuestion) {
                            delete newQuestion.nextQuestion;
                        }
                        if(newQuestion.nextQuestionYes) {
                            delete newQuestion.nextQuestionYes;
                        }
                        if(newQuestion.nextQuestionNo) {
                            delete newQuestion.nextQuestionNo;
                        }
                        if(!newQuestion.choices && (event.target.value === 'Single' || event.target.value === 'MultiChoice') ) {
                            newQuestion = {
                                ...question,
                                type: event.target.value,
                                choices: [{ answer: '', validate: false }, { answer: '', validate: false }, { answer: '', validate: false }],
                            }
                        }
                        if(event.target.value !== 'Single' && event.target.value !== 'MultiChoice') {
                            delete newQuestion.choices
                        }
                        if(newQuestion.unrequited) {
                            delete newQuestion.unrequited
                        }
                        setQuestion(newQuestion);
                    }}
                />
                <If statement={['MultiChoice', 'Single'].includes(question.type)}>
                    {() => {
                        return <Choices
                            question={{ ...question, choices }}
                            onChangeChoiceAnswer={(event, i) => onChangeChoiceAnswer(event, i)}
                            handleDelChoice={(index) => handleDelChoice(index)}
                            handleAddChoice={() => handleAddChoice()}
                        />;
                    }}
                </If>
            </div>
        </KirokuCardBodyWeb>
    </KirokuCardWeb>;
}

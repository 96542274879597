import { useSurveyContext }      from './SurveyContext';
import { useCallback }           from 'react';
import { SWITCH_CURRENT_SCREEN } from './SurveyReducer';

export default function useSwitchScreen() {
    const { infoSurvey, dispatch } = useSurveyContext();
    const {currentScreen}            = infoSurvey;
    const setScreen = useCallback((screenName) => {
        dispatch({
            type: SWITCH_CURRENT_SCREEN,
            screenName,
        });
    }, [dispatch]);

    return {
        currentScreen, setScreen,
    };
}

import _ from 'lodash';

export function isAnswersGeneric({ answers }) {
    return !!answers;
}

export function isAnswersMultiChoice({ answers }) {
    return _.findIndex([...answers], (answer) => answer[ 'is_choice' ]) !== -1;
}

export function isAnswersSingleChoice({ answers }) {
    return answers && !!answers.answer;
}


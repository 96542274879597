import React, { useEffect, useState } from 'react';
import config                         from '../../config';
import i18n                           from '../../i18n/i18n';
import moment                         from 'moment';
import { KirokuDropdownClose }        from '../../ui';
import useDropDownData                from '../useDropDownData';

function useLanguage(defaultLanguage) {
    const [language, setLanguage] = useState(defaultLanguage);

    useEffect(() => {
        i18n.changeLanguage(language).then(() => {
            localStorage.setItem('i18nextLng', language);
        });
    }, [language]);

    return {
        language,
        setLanguage,
    };
}

function useMoment(defaultLanguage) {
    const [languageMoment, setLanguageMoment] = useState(defaultLanguage);

    useEffect(() => {
        i18n.changeLanguage(languageMoment).then(() => {
            moment.locale(languageMoment);
        });
    }, [languageMoment]);

    return { setLanguageMoment };
}

export const defaultLanguage = localStorage.getItem('i18nextLng') || config.defaultLanguage;

export default function Languages() {
    const languages = localStorage.getItem('i18nextLng') || config.defaultLanguage;
    const { language, setLanguage } = useLanguage(languages);
    const { setLanguageMoment }     = useMoment(languages);
    const { dropdownLanguages }     = useDropDownData();

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className="language">
                <div style={ {
                    height         : 30,
                    width          : 113,
                    background     : 'no-repeat',
                    backgroundImage: `url(/assets/img/logo-kiroku.svg)`,
                } }/>
                <div/>
            </div>
            <div className={'margin-right-20px'}>
                <KirokuDropdownClose
                    className={'dropdown-languages'}
                    items={ dropdownLanguages }
                    title={ <span><img src={`/assets/img/globe.svg`} alt={'globe'}/> &nbsp;{config.languages[language]}</span> }
                    onSelect={ (event) => {
                        setLanguage(event.kirokuSelected.value);
                        setLanguageMoment(event.kirokuSelected.value);
                    } }
                />
            </div>

        </div>
    );
}

import React, { useCallback, useEffect, useState }                  from "react";
import { useTranslation }                                           from "react-i18next";
import { ComponentMobile, ComponentWeb, KirokuButton, KirokuInput } from "../../../../ui";
import MonitoringTerms                                              from "./MonitoringTerms";
import ServiceCertification                                         from "./ServiceCertification";
import useCertification                                             from "./useCertification";
import moment                                                       from "moment";
import Datetime                                                     from 'react-datetime';

const defaultObj = {
    services: {
        name: '',
        startDate: '',
        endDate: ''
    },
    monitoring_terms: {
        date: null
    }
};

function CertificationNumber() {
    const { certifications, setCertifications } = useCertification();
    const [ CertificationNumber, setCertificationNumber ] = useState(certifications[0]['certification_number']);
    const [ validate, setValidate ] = useState(true);

    const updateFieldChanged = e => {
        let updateCertifications = [ ...certifications ];
        updateCertifications[0]['certification_number'] = e.target.value;
        setCertifications("certifications", updateCertifications)
    }

    const checkError = (number) => {
        if (number.length >= 1 && number.length < 10) {
            setValidate(false);
        } else {
            setValidate(true)
        }
    };

    const { t } = useTranslation();

    return (
        <>
            <label className='mt-1 mb-10'>{t('CustomerEditor.CertificationNumber')}</label>
            <KirokuInput
                value={CertificationNumber || '' }
                onChange={e => {
                    setCertificationNumber(e.target.value.replace(/[^0-9]/g, ''))
                    checkError(e.target.value)
                }}
                onBlur={updateFieldChanged}
                maxLength={10}
            />
            <p hidden={validate} style={{
                color: 'red',
                fontSize: 12
            }}>
                {t("CustomerEditor.validate.FormatNumber")}
            </p>
        </>
    )
}

function CertificationIssuer() {
    const { certifications, setCertifications } = useCertification();
    const [ issuer, setIssuer ] = useState(certifications[0]['issuer']);
    const { t } = useTranslation();

    const updateFieldChanged = e => {
        let updateCertifications = [ ...certifications ];
        updateCertifications[0]['issuer'] = e.target.value;

        setCertifications("certifications", updateCertifications)
    }

    return (
        <>
            <label className='mt-1 mb-10'> {t('CustomerEditor.CertificationIssuer')} </label>
            <KirokuInput
                onChange={e => setIssuer(e.target.value)}
                value={issuer || ''}
                onBlur={updateFieldChanged}
            />
        </>
    )
}

function CertificationExpirationDate() {
    const {t} = useTranslation();
    const { certifications, setCertifications } = useCertification();
    return (
        <div>
            <label className='mt-1 mb-10'> {t('CustomerEditor.CertificationExpirationYearMonth')} </label>
            <Datetime
                className='read-only-date rdtPicker-web-right'
                inputProps={{disabled: false, readOnly: true}}
                value={certifications[0]['certification_expiration'] ? certifications[0]['certification_expiration'] * 1000 : ''}
                timeFormat={false}
                closeOnSelect={true}
                dateFormat={t('dateTimeFormat.YearMonth')}
                onChange={(date) => {
                    let updateCertificationDate = [...certifications];
                    updateCertificationDate[0]['certification_expiration'] = moment(date).unix();
                    setCertifications("certifications", updateCertificationDate);
                }}
                locale={t('createRecord.Date')}
            />
        </div>
    )

}

export default function Certification() {
    const { t } = useTranslation();
    const { certifications, addCertifications, removeCertifications, setCertifications } = useCertification();

    const { services, monitoring_terms } = certifications[0];

    const updateCertifications = useCallback(
        (name, index) => value => {
            const newCertifications = [ ...certifications ]
            newCertifications[0][name][index] = value;

            setCertifications("certifications", newCertifications)
        }, [ setCertifications, certifications ]
    )

    const setDefaultCertifications = useCallback(
        (name) => {
            const newCertifications = [ ...certifications ]
            newCertifications[0][name] = [ defaultObj[name] ];

            setCertifications("certifications", newCertifications)
        }, [ setCertifications, certifications ]
    )

    useEffect(() => {
        if (!services.length) {
            setDefaultCertifications('services')
        }
        if (!monitoring_terms.length) {
            setDefaultCertifications('monitoring_terms')
        }
    }, [ setDefaultCertifications, services, monitoring_terms ])
    return (
        <div>
            <h5 className={'card-title-info'}>{t('CustomerEditor.Certification')}</h5>
            <ComponentWeb>
                <div className={'row'}>
                    <div className={'col-3'}>
                        <CertificationNumber/>
                    </div>
                    <div className="col-6">
                        <CertificationIssuer/>
                    </div>
                    <div className="col-3">
                        <CertificationExpirationDate/>
                    </div>
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <CertificationNumber/>
                <CertificationIssuer/>
                <CertificationExpirationDate/>
            </ComponentMobile>
            <div className={'p-2'}/>
            <div style={{
                border: '1px solid rgb(200, 200, 200)',
                padding: '20px'
            }}>
                <div className="d-flex justify-content-between col-10 pl-0">
                    <h5 className={'card-title-info'}>{t('CustomerEditor.Service')}</h5>
                    <ComponentWeb>
                        <KirokuButton className={'cursor-pointer pb-2'}
                                      color={'primary'}
                                      onClick={() => addCertifications('services')}
                        >
                            {t('CustomerEditor.add')}
                        </KirokuButton>
                    </ComponentWeb>
                </div>
                {services.map((service, index) => {
                    return (
                        <div className="row pt-2" key={index}>
                            <ServiceCertification
                                service={service}
                                isFirst={index !== 0}
                                hidden={services.length === 1}
                                updateCertifications={updateCertifications('services', index)}
                                remove={() => removeCertifications('services', index)}
                            />
                        </div>
                    )
                })}
                <ComponentMobile>
                    <div className="pt-3">
                        <KirokuButton className={'cursor-pointer pb-2'}
                                      color={'primary'}
                                      onClick={() => addCertifications('services')}
                        >
                            {t('CustomerEditor.add')}
                        </KirokuButton>
                    </div>
                </ComponentMobile>
            </div>
            <div className={'pt-3'}/>
            <div style={{
                border: '1px solid rgb(200, 200, 200)',
                padding: '20px'
            }}>
                <div className={'d-flex'} style={{ justifyContent: "space-between" }}>
                    <h5 className={'card-title-info'}>{t('CustomerEditor.monitoringTerms')}</h5>
                    <ComponentWeb>
                        <KirokuButton className={'cursor-pointer d-flex'}
                                      onClick={() => addCertifications('monitoring_terms')}
                                      color={'primary'}>
                            {t('CustomerEditor.add')}
                        </KirokuButton>
                    </ComponentWeb>
                    <div/>
                </div>
                <div className="row pt-2">
                    {monitoring_terms.map((monitor, index) => {
                        return (
                            <div className={'col-md-5 pt-2'} key={index}>
                                <MonitoringTerms
                                    monitor={monitor}
                                    hidden={monitoring_terms.length === 1}
                                    updateCertifications={updateCertifications('monitoring_terms', index)}
                                    remove={() => removeCertifications('monitoring_terms', index)}
                                />
                            </div>
                        )
                    })}
                    <ComponentMobile>
                        <div className="pt-3" style={{ paddingLeft: 15 }}>
                            <KirokuButton className={'cursor-pointer'}
                                          onClick={() => addCertifications('monitoring_terms')}
                                          color={'primary'}>
                                {t('CustomerEditor.add')}
                            </KirokuButton>
                        </div>
                    </ComponentMobile>
                </div>
            </div>
        </div>
    )
}

import { useEffect }         from 'react';
import { prepareProfile }    from './MixPanel';
import { authentication }    from '@kiroku/api';

export function MixpanelAuthenticated({ children }) {

    const { user } = authentication.useAuthenticatedContext();

    useEffect(() => {
        prepareProfile(user);
    });

    return children;
}

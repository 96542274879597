import { KirokuLabel }    from '../../../../ui';
import React              from 'react';
import { useTranslation } from 'react-i18next';
import { Link }           from 'react-router-dom';
import maxBy             from 'lodash/maxBy';

export default function StatusRecordLabel({ customer }) {
    const { t } = useTranslation();

    const getRecordId = (timeLine) => {
        const customerRecords = customer[timeLine].filter(record => record.customer_id === customer.id)
        return maxBy(customerRecords, 'serviced_at').id;
    }

    return (
        <React.Fragment>
            {customer.record_draft_at && <span className='ml-6'>
                <Link to={`/records/${getRecordId('record_draft_at_yesterday')}`}>
                    <KirokuLabel
                        style={{
                            backgroundColor: '#EA9B3F',
                            color          : 'white',
                            cursor         : 'pointer',
                        }}
                    >
                        {t('common.haveDraft')}
                    </KirokuLabel>
                </Link>
            </span>}
            {customer.serviced_today && <span className='ml-6'>
                <Link to={`/records/${getRecordId('record_serviced_today')}`}>
                    <KirokuLabel
                        style={{
                            backgroundColor: '#337ab7',
                            color          : 'white',
                            cursor         : 'pointer',
                        }}
                    >
                        {t('CustomerEditor.Recorded')}
                    </KirokuLabel>
                </Link>
            </span>}
            {!customer.serviced_today && <span className='ml-6'>
                <KirokuLabel
                    style={{
                        backgroundColor: '#FFFFFF',
                        color          : '#4579B2',
                        border         : 'solid 1px #4579B2',
                    }}
                >
                    {t('common.todayIsNotRecord')}
                </KirokuLabel>
            </span>}
            {customer.serviced_yesterday && <span className='ml-6'>
                <Link to={`/records/${getRecordId('record_serviced_yesterday')}`}>
                    <KirokuLabel
                        style={{
                            backgroundColor: '#337ab7',
                            color          : 'white',
                            cursor         : 'pointer',
                        }}
                    >
                        {t('common.yesterdayIsRecord')}
                    </KirokuLabel>
                </Link>
            </span>}
            {!customer.serviced_yesterday && <span className='ml-6'>
                <KirokuLabel
                    style={{
                        backgroundColor: '#FFFFFF',
                        color          : '#4579B2',
                        border         : 'solid 1px #4579B2',
                    }}
                >
                    {t('common.yesterdayIsNotRecord')}
                </KirokuLabel>
            </span>}
        </React.Fragment>
    );
}

import { useSurveyContext }   from './SurveyContext';
import { useCallback }        from 'react';
import { CHANGE_INFO_SURVEY } from './SurveyReducer';

export default function useChangeInfoSurvey() {
    const { infoSurvey, dispatch } = useSurveyContext();
    const { title, description }   = infoSurvey;

    const setInfoSurvey = useCallback((nameInput, value) => {
        dispatch({
            type: CHANGE_INFO_SURVEY,
            nameInput, value,
        });
    }, [dispatch]);
    return {
        title, setInfoSurvey,
        description,
    };
}

import {resources} from "@kiroku/api";
import moment      from "moment";

export default function useSearchServiceCustomer() {
    const {
        result, search,
        pagination,
        cancelAPISearch,
        condition
    } = resources.useSearch('Customer', {
        type        : 'services_end_date',
        page        : 1,
        status      : 'active',
        services_end_date: moment().startOf('month').startOf('day').unix(),
    });


    return {
        resultServiceEndDate: result, searchServiceEndDate: search,
        paginationServiceEndDate: pagination,
        cancelAPISearch, conditionServiceEndDate: condition
    }
}

import React, { useEffect, useState }                                 from 'react';
import { resources }                                                  from '@kiroku/api';
import { KirokuButton, KirokuCardWeb, KirokuLoader, SearchNotFound } from '../../../ui';
import If                                                             from '../../If';
import { useTranslation }                                             from 'react-i18next';
import { Link, useHistory }                                           from 'react-router-dom';
import { SpamPermission, withPermissions }                            from '../../HOC';
import TabTeamsComponent                                              from './TabTeamsComponent';
import TabInfoComponent                                               from './TabInfoComponent';
import TabCustomersComponent                                          from './TabCustomersComponent';
import ComponentWeb                                                   from '../../../ui/ComponentWeb';
import ComponentMobile                                                from '../../../ui/ComponentMobile';
import DivTagPermission                                               from '../../HOC/DivTagPermission';
import usePageTitle                                                   from '../../../ui/v3/hooks/usePageTitle';

const SpanWithPermissions = withPermissions(SpamPermission);
const DivPermission       = withPermissions(DivTagPermission);

export default function UserDetail(props) {
    const [userInfo, detailUser, loading] = resources.useDetail('User');
    const history                         = useHistory();
    const { t }                           = useTranslation();
    const [currentTab, setCurrentTab]     = useState('teams');
    usePageTitle(t(`Layout.Staffs`));

    useEffect(() => {
        detailUser(props.match.params.id).catch(e => console.log(e));
    }, [detailUser, props.match.params.id]);

    return (<div className={ ' customer-detail padding-responsive' }>
        <KirokuLoader loading={ loading }>
            <If statement={ userInfo }
                otherwise={ <SearchNotFound message={ t('common.UserInvalid') } description={ ' ' }/> }
            >
                { () => <div>
                    <KirokuButton onClick={ () => {
                        if (history.location.state && history.location.state.previousPage === 'userList') {
                            history.goBack();
                        } else {
                            history.push('/users')
                        }
                    } }
                                  color='white'>{ t('common.goToUserList') }</KirokuButton>
                    <div className="p-2"/>
                    <KirokuCardWeb>
                        <div className='padding-info row m-0'>
                            <div className="col-md-3 as">
                                <div className='customer-detail height-avatar as image-customer-datail'
                                     style={ {
                                         backgroundImage: `url(${ userInfo.avatar ? userInfo.avatar : '/assets/img/profile-ic.svg' })`,
                                     } }
                                />
                            </div>
                            <div className='col-md-9'>
                                <div className='pt-10'>
                                    <ComponentWeb>
                                        <div className='d-flex align-items-center'>
                                            <div className='pr-3 font-size-18'>{ userInfo.name }</div>
                                            <SpanWithPermissions
                                                resource-permission={ '|staffDetail|button-editProfile|' }>
                                        <span className='pr-10'>
                                            <i className='fas fa-pencil-alt text-secondary'/>
                                        </span>
                                                <Link
                                                    to={ `/users/edit/${ userInfo.id }` }>
                                                    <span style={ { minWidth: 'max-content' } }>
                                                        { t('common.EditProfile') }
                                                    </span>
                                                </Link>
                                            </SpanWithPermissions>
                                        </div>
                                    </ComponentWeb>
                                    <ComponentMobile>
                                        <div className='pr-3 font-size-18'
                                             style={ { textAlign: 'center' } }>{ userInfo.name }</div>
                                        <DivPermission resource-permission={ '|staffDetail|button-editProfile|' }
                                                       style={ { textAlign: 'center' } }>
                                            <span className='pr-10'>
                                                <i className='fas fa-pencil-alt text-secondary'/>
                                            </span>
                                            <Link
                                                to={ `/users/edit/${ userInfo.id }` }>
                                                <span style={ { minWidth: 'max-content' } }>
                                                    { t('common.EditProfile') }
                                                </span>
                                            </Link>
                                        </DivPermission>
                                    </ComponentMobile>
                                </div>
                                <div className="text-left">
                                    <div className='mb-10'>
                                        <div className='customer-info f-bold'>
                                            <span className="mr-2 font-size-16">{ t('staff.title') }: </span>
                                            <span className="font-size-14">{ userInfo.title } </span>
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div className='customer-info f-bold'>
                                            <span className="mr-2 font-size-16">{ t('common.Role') }: </span>
                                            <span className={ 'font-size-14' }>{ t(`common.${ userInfo.role }`) }</span>
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div className='customer-info f-bold'>
                                        <span
                                            className="mr-2 font-size-16">{ t('common.WorkingForm') }: </span>
                                            <span
                                                className="font-size-14">{ userInfo['working_form'] ? t(`common.${ userInfo['working_form'] }`) : '' } </span>
                                        </div>
                                    </div>
                                    <div className='mb-10'>
                                        <div className='customer-info f-bold'>
                                            <span className="mr-2 font-size-16">{ t('common.Qualification') }: </span>
                                            <span className="font-size-14">{ userInfo.qualification.map(item => {
                                                return t(`common.dropdown.${ item }`);
                                            }).join(', ') } </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className='col-md-3 as'/>*/}
                        </div>
                        <div className='d-flex justify-content-between pt-10 pl-3 pr-3 nar-bar-user-detail'>
                            <div style={ { cursor: 'pointer' } }
                                 className={ `nav-bar ${ currentTab === 'teams' ? 'active' : '' }` }
                                 onClick={ () => {
                                     setCurrentTab('teams');
                                 } }>{ t('common.Team') }
                            </div>
                            <div style={ { cursor: 'pointer' } }
                                 className={ `nav-bar ${ currentTab === 'customers' ? 'active' : '' }` }
                                 onClick={ () => {
                                     setCurrentTab('customers');
                                 } }>{ t('common.Customers') }
                            </div>
                            <div style={ { cursor: 'pointer' } }
                                 className={ `nav-bar ${ currentTab === 'info' ? 'active' : '' }` }
                                 onClick={ () => {
                                     setCurrentTab('info');
                                 } }>{ t('common.StaffDetail') }
                            </div>
                        </div>
                    </KirokuCardWeb>
                    <div className={ 'p-2' }/>
                    <TabTeamsComponent
                        isShow={ currentTab === 'teams' }
                        userId={ userInfo.id }
                    />

                    <TabCustomersComponent
                        isShow={ currentTab === 'customers' }
                        userId={ userInfo.id }
                    />

                    <TabInfoComponent
                        isShow={ currentTab === 'info' }
                        userInfo={ userInfo }
                    />
                    <ComponentMobile>
                        <div style={ { marginBottom: 80 } }/>
                    </ComponentMobile>
                </div> }
            </If>
        </KirokuLoader>
    </div>);
}

import React                   from 'react';
import moment                  from 'moment';
import {
    KirokuCardWeb, KirokuInputDate,
    KirokuCardBodyWeb,
}                              from '../../../../ui';
import { useTranslation }      from 'react-i18next';
import { useHeadingQuestion }  from '../../useSurvey';
import { useAsQuestionType }   from '../../../Recording';
import { ButtonNext, getNext } from './getNextQuestion';
import { QuestionSkipped }     from './QuestionSkipped';
import QuestionCardTitle       from './QuestionCardTitle';
import KirokuInputTime         from '../../../../ui/KirokuInputTime';
import ComponentWeb            from '../../../../ui/ComponentWeb';
import ComponentMobile         from '../../../../ui/ComponentMobile';
const width = window.innerWidth;

export function setDefaultAnswerTime(question) {
    return {
        ...question,
        answers: question[ 'defaultAnswer' ],
    };
}

export default function TimeQuestion({ question }) {

    const { t }                   = useTranslation();
    const { doAnswer, answering } = useAsQuestionType(question);
    const { content }             = useHeadingQuestion(question);

    return (
        <div id='component-question'>
            <div id='web-q' className='pb-3'>
                <KirokuCardWeb>
                    <div className={ (width <= 768) && !!question.answers ? 'border-has-answer': ' '}>

                    <QuestionCardTitle
                        isDisabled={question.isDisabled}
                        question={question}
                        handleResetAnswer={() => doAnswer(null, question.index + 1)}
                        hasAnswer={!!question.answers}
                    />
                    <KirokuCardBodyWeb>
                        <QuestionSkipped
                            isDisabled={question.isDisabled}
                        >
                            <span className='content-name-question'>{content}</span>
                            <div className="d-flex">
                                <div className='answer-question' style={{ maxWidth: 250 }}>
                                    <ComponentWeb>
                                        <KirokuInputDate
                                            className={'date-chart-search'}
                                            inputProps={{ disabled: question.isDisabled, readOnly: true }}
                                            value={question.answers ? moment.unix(question.answers) : null}
                                            timeFormat={t('dateTimeFormat.HourMinute')}
                                            dateFormat={false}
                                            onChange={(momentTime) => {
                                                answering();
                                                doAnswer(
                                                    momentTime ? moment(momentTime).unix() : null,
                                                    getNext({ ...question, isAnswers: !!momentTime }));
                                            }}
                                            locale={t('createRecord.Date')}
                                        />
                                    </ComponentWeb>
                                    <ComponentMobile>
                                        <div style={{width: 216}}>
                                            <KirokuInputTime
                                                readOnly={true}
                                                name={`${question.index}-clock`}
                                                disabled={question.isDisabled}
                                                onChange={(timestampUnix) => {
                                                    answering();
                                                    doAnswer(
                                                        timestampUnix ? timestampUnix : null,
                                                        getNext({ ...question, isAnswers: !!timestampUnix }));
                                                }}
                                                value={question.answers}
                                            />
                                        </div>
                                    </ComponentMobile>
                                </div>
                                <div style={{ paddingTop: 10 }}
                                >
                                    <ButtonNext
                                        question={question}
                                        onClick={() => {
                                            if(question.answers) {
                                                doAnswer(question.answers, getNext(question))
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </QuestionSkipped>
                    </KirokuCardBodyWeb>
                    </div>
                </KirokuCardWeb>
            </div>
        </div>
    );
}

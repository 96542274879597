import React               from 'react';
import { useTranslation }  from 'react-i18next';

export default function ConditionSearch({tabActive, setTabActive }) {
    const { t }      = useTranslation();

    return (<div className="d-flex justify-content-around border-btn-status-group">
        <div className={`border-width-each-status first-btn font-text-on-btn cursor-pointer
                                  ${tabActive === 'IS_USER' ? 'btn-active' : ''}`}
             onClick={() => {
                 setTabActive('IS_USER');
             }}
        >
            {t('common.customersTaggedTemplate')}
        </div>
        <div className={`border-width-each-status-center font-text-on-btn cursor-pointer
                                ${tabActive === 'TEAM' ? 'btn-active' : ''}`}
             onClick={() => {
                 setTabActive('TEAM');
             }}
        >
            {t('common.team')}
        </div>
        <div className={`border-width-each-status third-btn font-text-on-btn cursor-pointer
                                ${tabActive === 'ALL' ? 'btn-active' : ''}`}
             onClick={() => {
                 setTabActive('ALL');
             }}
        >
            {t('RecordsBeta.all')}
        </div>
    </div>);
}

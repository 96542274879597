import React                from 'react';
import moment               from 'moment';
import { Link }             from 'react-router-dom';
import _                    from 'lodash';
import { useTranslation }   from 'react-i18next';
import { KirokuButton }     from '../../ui';
import { getFlowQuestions } from './Charts/HelperComponent/getQuestions';

function renderAnswers(question, t) {
    switch (question.type) {
        case 'InputText':
            return question.answers;
        case 'YesNo':
            if (!question.answers) {
                return t('Analysis.unanswered');
            }
            return t(`createRecord.${question.answers}`);
        case 'Single' :
            if (!question.answers.answer) {
                return t('Analysis.unanswered');
            }
            return question.answers.answer;
        case 'MultiChoice' :
            const answerIsChoice = [...question.answers].filter(answer => answer[ 'is_choice' ]);
            if (!answerIsChoice.length) {
                return t('Analysis.unanswered');
            }
            return answerIsChoice.map(answer => answer[ 'answer' ]).join(', ');
        case 'Number' :
            if (!question.answers) {
                return t('Analysis.unanswered');
            }
            return question.answers;
        case 'Time' :
            if (!question.answers) {
                return t('Analysis.unanswered');
            }
            return moment.unix(question.answers).format(t('dateTimeFormat.HourMinute'));
        case 'OnlyDate' :
            if (!question.answers) {
                return t('Analysis.unanswered');
            }
            return moment.unix(question.answers).format(t('dateTimeFormat.YearMonthDateWithDay'));
        case 'Media':
            if (!question.answers.answer) {
                return t('Analysis.unanswered');
            }
            return '';
        default :
            return;
    }
}

function isShowingAnswerSelected (itemQuestion, question, answerSelected, t) {
    const yesNoAnswer = { 'はい': 'Yes', 'いいえ': 'No', '未回答' : 'unanswered' };
    switch (itemQuestion.type) {
        case 'Single':
            let hasAnswerSingle = itemQuestion.answers.answer || t('Analysis.unanswered');
            return question.type === answerSelected.type && answerSelected.answer !== hasAnswerSingle && question.content === answerSelected.content;
        case 'YesNo':
            let hasYesNoAnswer = itemQuestion.answers || t('Analysis.unanswered');
            return question.type === answerSelected.type && yesNoAnswer[answerSelected.answer] !== hasYesNoAnswer && question.content === answerSelected.content;
        case 'MultiChoice':
            const answerIsChoice = itemQuestion.answers.filter(answer => answer[ 'is_choice' ]);
            let isChoice = answerIsChoice.filter(ans =>  ans.answer === answerSelected.answer);
            return question.type === answerSelected.type && !isChoice.length && question.content === answerSelected.content;
        case 'Time':
            let timeAnswer = itemQuestion.answers ? moment.unix(itemQuestion.answers).format(t('dateTimeFormat.Hour')) : t('Analysis.unanswered')
            return question.type === answerSelected.type && answerSelected.answer !== timeAnswer && question.content === answerSelected.content;
        case 'OnlyDate':
            let hasOnlyAnswer = itemQuestion.answers ? moment.unix(itemQuestion.answers).format(t('dateTimeFormat.YearMonthDateWithDay')) : t('Analysis.unanswered');
            return question.type === answerSelected.type && answerSelected.answer !== hasOnlyAnswer && question.content === answerSelected.content;
        case 'Number':
            let hasNumberAnswer = itemQuestion.answers ? parseInt(itemQuestion.answers) : t('Analysis.unanswered');
            return question.type === answerSelected.type && answerSelected.answer !== hasNumberAnswer && question.content === answerSelected.content;
        case 'Media':
            return question.type === answerSelected.type && answerSelected.answer !== itemQuestion.answers.answer && question.content === answerSelected.content;
        case 'InputText':
            let hasInputAnswer = itemQuestion.answers || t('Analysis.unanswered');
            return question.type === answerSelected.type && answerSelected.answer !== hasInputAnswer && question.content === answerSelected.content
        default:
            return false;
    }
}

function getChoices(questions){
    let choices = [];
    questions.forEach( item => {
        item.choices.forEach( choice => {
            if (choices.indexOf(choice.answer) < 0){
                choices.push(choice.answer);
            }
        })
    });
    return choices;
}

function findQuestion(question,records) {
    records = records.map(record => getFlowQuestions(record.survey_template));
    let questions = records.reduceRight((questions, questionsNext) => questions.concat(questionsNext));
    questions = questions.filter(q => q.content === question.content && q.type === question.type);
    return questions;
}

function getAnswersLabel(question, records, t) {
    if(question.type === 'MultiChoice') {
        return _.uniqBy(getChoices(findQuestion(question, records)).map(answer => {
            return {
                content: question.content,
                type: question.type,
                answer: answer,
            }
        }), 'answer');
    }

    if(question.type === 'Single') {
        return _.uniqBy(findQuestion(question, records).map(item => {
            return {
                content: question.content,
                type: question.type,
                answer: item.answers.answer || t('Analysis.unanswered'),
            }
        }), 'answer');
    }

    if(question.type === 'Number') {
        return _.uniqBy(findQuestion(question, records).map(item => {
            return {
                content: question.content,
                type: question.type,
                answer: item.answers ? parseInt(item.answers) : t('Analysis.unanswered'),
            }
        }), 'answer');
    }
    if(question.type === 'OnlyDate') {
        return _.uniqBy(findQuestion(question, records).map(item => {
            let hasAnswer = item.answers ? moment.unix(item.answers).format(t('dateTimeFormat.YearMonthDateWithDay')) : t('Analysis.unanswered')
            return {
                content: question.content,
                type: question.type,
                answer: hasAnswer,
            }
        }), 'answer');
    }
    if(question.type === 'Time') {
        return _.uniqBy(findQuestion(question, records).map(item => {
            return {
                content: question.content,
                type: question.type,
                answer: item.answers ? moment.unix(item.answers).format(t('dateTimeFormat.Hour')) : t('Analysis.unanswered'),
            }
        }), 'answer');
    }

    if (question.type === 'YesNo') {
        return _.uniqBy(findQuestion(question, records).map(item => {
            return {
                content: question.content,
                type   : question.type,
                answer : item.answers ? t(`createRecord.${ item.answers }`) : t('Analysis.unanswered'),
            }
        }), 'answer');
    }

    if (question.type === 'Media') {
        return _.uniqBy(findQuestion(question, records).map(() => {
            return {
                content: question.content,
                type   : question.type,
                answer : '',
            }
        }), 'answer');
    }

    if (question.type === 'InputText') {
        return _.uniqBy(findQuestion(question, records).map(item => {
            return {
                content: question.content,
                type : question.type,
                answer : item.answers || t('Analysis.unanswered'),
            }
        }), 'answer');
    }
    return []
}


export function AnswersQuestion({recordsByDate, question, answerSelected, clearFilter, records, handleSelectAnswer}) {
    const { t } = useTranslation();
    const answers = getAnswersLabel(question, records, t);

    return (
        <div className='col-lg-6 pb-3 pl-2 pr-2 scroll-history-answer'>
            <div className='content-history pl-3'>
                <div className=' mb-1 f-bold'>{t('Analysis.AnswerHistory')}</div>
                <div className="d-flex align-items-center flex-wrap-wrap pl-1 pt-2">
                    <span hidden={question.type === 'Media'}>
                            {t('common.filter')}: &nbsp;
                        </span>
                    { !!answers.length && answers.map((item, index) => {
                        return <div key={index} className={'p-1'}>
                            <div
                                hidden={item.type === 'Media'}
                                style={{
                                padding: '2px 4px',
                                margin: 2,
                                border: '1px solid',
                                borderRadius: '3px',
                                maxWidth: 300
                            }} className={ `cut-long-text-label ${answerSelected && question.type === answerSelected.type && question.content === answerSelected.content && item.answer === answerSelected.answer ? 'label-active' : 'label-inactive'}`}>
                                <span className={`cursor-pointer`}
                                      onClick={() => {
                                          handleSelectAnswer(item);
                                      }}>
                                    <i className="fas fa-check" hidden={ !answerSelected || item.answer !== answerSelected.answer || question.type !== answerSelected.type || question.content !== answerSelected.content}/> &nbsp;
                                    { item.answer}
                                </span>
                            </div>
                        </div>
                    }) }
                    {answerSelected && question.type === answerSelected.type && question.content === answerSelected.content ? <span>
                            <span className={'pl-1'}>
                                <KirokuButton
                                    className={'clear-highlight-button'}
                                    color="primary"
                                    onClick={ clearFilter }> {t('common.clear')} </KirokuButton>
                            </span>
                        </span> : '' }
                </div>
                {recordsByDate.map((record, index) => {
                    return (
                        <ul className='list-style-analysis'
                            key={index}>
                            <li className='list-li'>
                                <i className="fas fa-circle dot-icon"/><span
                                className='pl-2 text-list-day'>{record.day}</span>
                                <ul className='list-style-analysis-2'>
                                    {record.recordOnlyQuestions.map(itemRecord => {
                                        const isHasTime = _.findIndex(record.recordOnlyQuestions, (itm => itm.has_time)) !== -1;
                                        return itemRecord[ 'survey_template' ].flowedQuestions.map((itemQuestion, index) => {
                                            if (itemQuestion.content === question.content && itemQuestion.type === question.type) {
                                                return (
                                                    <div  key={index}>
                                                        <li hidden={ answerSelected && isShowingAnswerSelected(itemQuestion, question, answerSelected, t)}>
                                                            <span>
                                                                { itemRecord.has_time ? moment.unix(itemRecord.serviced_at).format(t('dateTimeFormat.HourMinute')) : ''}
                                                                    <span className={`pl-2 ${(isHasTime && !itemRecord.has_time) ? 'label-answer-analysis' : ''} `}>
																{ renderAnswers(itemQuestion, t)}</span>
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    <Link to={`/records/${itemRecord.id}`} target='_blank'>
                                                                        {t('Analysis.LinkToRecord')}
                                                                    </Link> - {itemRecord.customer &&
                                                                        <Link to={`/customers/${itemRecord.customer.id}`} target='_blank'>
                                                                            {itemRecord.customer && itemRecord.customer.name}
                                                                        </Link> }
                                                                </span>
                                                        </li>
                                                    </div>

                                                );
                                            }
                                            return '';
                                        });
                                    })}
                                </ul>
                            </li>
                        </ul>
                    );
                })}
            </div>
        </div>
    );
}

import { useEffect } from 'react';
import { resources } from '@kiroku/api';

export function useSurveyLabel(surveyId) {
    const { result, search, cancelAPISearch } = resources.useSearch('Template', { type: 'id' });
    useEffect(() => {
        if (surveyId) {
            search({ type: 'id', id: [surveyId] });
            return () =>  {
                cancelAPISearch();
            }
        }
    }, [search, surveyId, cancelAPISearch]);

    const templateName = result.map(item => item.title);

    return { templateName };
}

import DateTimeQuestion, { setDefaultAnswerDateTime }                    from './DateTimeQuestion';
import InputText, { setDefaultAnswerInput }                              from './InputText';
import Message, { setDefaultAnswerMessage }                              from './Message';
import YesNoQuestion, { setDefaultAnswerYesNo }                          from './YesNoQuestion';
import SingleChoice, { setDefaultAnswerSingleChoice }                    from './SingleChoice';
import MultiChoice, { setDefaultAnswerMultiChoice }                      from './MultiChoice';
import MediaFileQuestion, { setDefaultAnswerMedia }                      from './MediaFileQuestion';
import DateQuestion, { setDefaultAnswerOnlyDate }                        from './DateQuestion';
import TimeQuestion, { setDefaultAnswerTime }                            from './TimeQuestion';
import NumberQuestion, { setDefaultAnswerNumber }                        from './NumberQuestion';
import {
    getNext, getNextMultiChoice, getNextQuestionMedia, getNextSingleChoice, getNextYesNo,
}                                                                        from './getNextQuestion';
import { isAnswersGeneric, isAnswersMultiChoice, isAnswersSingleChoice } from './validateAnswer';

export const availableQuestions = {
    InputText  : {
        isAnswers       : isAnswersGeneric,
        getNext         : getNext,
        setDefaultAnswer: setDefaultAnswerInput,
        component       : InputText,
    },
    Message    : {
        isAnswers       : isAnswersGeneric,
        getNext         : getNext,
        setDefaultAnswer: setDefaultAnswerMessage,
        component       : Message,
    },
    MultiChoice: {
        isAnswers       : isAnswersMultiChoice,
        getNext         : getNextMultiChoice,
        setDefaultAnswer: setDefaultAnswerMultiChoice,
        component       : MultiChoice,
    },
    YesNo      : {
        isAnswers       : isAnswersGeneric,
        getNext         : getNextYesNo,
        setDefaultAnswer: setDefaultAnswerYesNo,
        component       : YesNoQuestion,
    },
    Date       : {
        isAnswers       : isAnswersGeneric,
        getNext         : getNext,
        setDefaultAnswer: setDefaultAnswerDateTime,
        component       : DateTimeQuestion,
    },
    Single     : {
        isAnswers       : isAnswersSingleChoice,
        getNext         : getNextSingleChoice,
        setDefaultAnswer: setDefaultAnswerSingleChoice,
        component       : SingleChoice,
    },
    Media      : {
        isAnswers       : isAnswersGeneric,
        getNext         : getNextQuestionMedia,
        setDefaultAnswer: setDefaultAnswerMedia,
        component       : MediaFileQuestion,
    },
    OnlyDate   : {
        isAnswers       : isAnswersGeneric,
        getNext         : getNext,
        setDefaultAnswer: setDefaultAnswerOnlyDate,
        component       : DateQuestion,
    },
    Time       : {
        isAnswers       : isAnswersGeneric,
        getNext         : getNext,
        setDefaultAnswer: setDefaultAnswerTime,
        component       : TimeQuestion,
    },
    Number     : {
        isAnswers       : isAnswersGeneric,
        getNext         : getNext,
        setDefaultAnswer: setDefaultAnswerNumber,
        component       : NumberQuestion,
    },
};


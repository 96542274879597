import moment                                                                       from 'moment';
import React, { useState }                                                          from 'react';
import { KirokuCardBodyWeb, KirokuCardWeb, KirokuInputDate, KirokusSortableHandle } from '../../../ui';
import ActionGroupBtn                                                               from './ActionGroupBtn';
import QuestionCardTitle                                                            from './QuestionTitleCard';
import InfoQuestion                                                                 from './InfoQuestion';
import CheckboxUnrequited                                                           from './CheckboxUnrequited';
import NextQuestion                                                                 from './NextQuestion';
import useQuestions                                                                 from '../MakeSurvey/useQuestions';
import ModalEditQuestion
                                                                                    from '../FormQuestion/ModalEditQuestion';
import { modalSetLogic }                                                            from '../ModalSetLogicByQuestionType';
import ComponentWeb                                                                 from '../../../ui/ComponentWeb';
import ComponentMobile                                                              from '../../../ui/ComponentMobile';
import KirokuInputDateTimeMobile
                                                                                    from '../../../ui/KirokuInputDateTimeMobile';
import { useTranslation }                                                           from 'react-i18next';

export default function Date({ question }) {
    const { update, copy, delQuestion }                         = useQuestions();
    const [isOpenModalName, setIsOpenModalName]                 = useState(null);
    const ModalSetLogic                                         = modalSetLogic[question.type];
    const [isOpenModalEditQuestion, setIsOpenModalEditQuestion] = useState(false);
    const { t }                                                 = useTranslation();
    return (
        <div id='component-question'>
            <div id='web-q' className='pb-3'>
                <KirokuCardWeb>
                    <KirokusSortableHandle>
                        <QuestionCardTitle
                            index={question.index}
                            isLogic={typeof question.nextQuestion === 'number'}
                        >
                            <ActionGroupBtn
                                handleOpenModalAddLogic={() => {
                                    setIsOpenModalName(question.index);
                                }}
                                handleOpenModalEdit={() => {
                                    setIsOpenModalEditQuestion(true);
                                }}
                                question={question}
                                resetDefaultAnswer={() => {
                                    if (question.defaultAnswer !== undefined) {
                                        delete question.defaultAnswer;
                                        update(question);
                                    }
                                }}
                                handleDel={() => delQuestion(question.index)}
                                handleCopy={() => {
                                    copy(question);
                                }}
                            />
                        </QuestionCardTitle>
                    </KirokusSortableHandle>

                    <KirokuCardBodyWeb>
                        <div>
                            <CheckboxUnrequited
                                question={question}
                                isNotUse={typeof question.nextQuestion === 'number'}
                            />

                            <InfoQuestion question={question}/>

                            <NextQuestion nextQuestion={question.nextQuestion}/>

                            <div className='answer-question bg-kiroku-date' style={{ maxWidth: 250 }}>
                                <ComponentMobile>
                                    <KirokuInputDateTimeMobile
                                        name={`${question.index}`}
                                        readOnly={true}
                                        value={question.defaultAnswer}
                                        onChange={(timestampUnix) => {
                                            let newQuestion = { ...question };
                                            newQuestion     = {
                                                ...newQuestion,
                                                defaultAnswer: typeof timestampUnix === 'number' ? timestampUnix : '',
                                            };
                                            update(newQuestion);
                                        }}
                                    />
                                </ComponentMobile>
                                <ComponentWeb>
                                    <KirokuInputDate
                                        value={question.defaultAnswer ? moment.unix(question.defaultAnswer) : ''}
                                        inputProps={{ readOnly: true }}
                                        timeFormat={t('dateTimeFormat.HourMinute')}
                                        dateFormat={t('dateTimeFormat.YearMonthDate')}
                                        onChange={(momentTime) => {
                                            let newQuestion = { ...question };
                                            newQuestion     = {
                                                ...newQuestion,
                                                defaultAnswer: typeof momentTime === 'object' ? momentTime.unix() : '',
                                            };
                                            update(newQuestion);
                                        }}
                                    />
                                </ComponentWeb>

                            </div>
                        </div>
                    </KirokuCardBodyWeb>
                </KirokuCardWeb>

                <ModalEditQuestion
                    isOpenModalEditQuestion={isOpenModalEditQuestion}
                    handleAction={() => setIsOpenModalEditQuestion(!isOpenModalEditQuestion)}
                    question={question}
                    updateQuestion={(questionData) => update(questionData)}
                />

                <ModalSetLogic
                    question={question}
                    isOpen={isOpenModalName === question.index}
                    confirm={() => {
                        setIsOpenModalName(null);
                    }}
                    cancellation={() => setIsOpenModalName(null)}
                />

            </div>
        </div>
    );
}

import React, { useEffect, useRef, useState }                                       from 'react';
import { resources }                                                                from '@kiroku/api';
import { ComponentMobile, KirokuButton, KirokuCardWeb, KirokuLabel, KirokuLoader } from '../../../ui';
import If                                                                           from '../../If';
import { useTranslation }                                                           from 'react-i18next';
import TabProfile                                                                   from './TabProfile';
import '../staff.css';
import TabChangePassword                                                            from './TabChangePassword';
import TabManageTeam                                                                from './TabManageTeam';
import useChangeInfoUser                                                            from './useChangeInfoUser';
import SearchNotFound                                                               from '../../../ui/SearchNotFound';
import ModalNotificationValidateUploadAvatar, { ComponentLoading }                  from '../ModalNotificationValidateUploadAvatar';
import { conditionUpload }                                                          from './validateChecker';

function WrapperUserEditor({ resultUser }) {
    const { t }                         = useTranslation();
    const [tabName, setTabName]         = useState( 'profile');
    const inputElement                  = useRef(null);
    const [alertUpload, setAlertUpload] = useState(false);

    const {
              handleChangeImage, profileData,
              isLoadingAvatar,
              updateInfoUser, handleSubmitUser,
              handleOnChangeCustomers, handleOnChangeTeams,
              handleChangeQualification, user,
          } = useChangeInfoUser(resultUser, inputElement);


    const validateAlertAvatar = () => {
        if(inputElement.current.files.length && !(inputElement.current.files[ 0 ] && conditionUpload(inputElement.current.files[ 0 ]))) {
            setAlertUpload(!alertUpload);
        }
    }

    return (<div className='padding-responsive staff-detail'>
        <ModalNotificationValidateUploadAvatar
            flagValidatedUpload={alertUpload}
            closeModal={() => setAlertUpload(!alertUpload)}
        />
        <KirokuCardWeb>
            <div>
                <ComponentMobile>
                    <div className='pt-2'/>
                    <KirokuLoader loading={isLoadingAvatar} ComponentLoading={ComponentLoading}>
                        <div className='staff-detail avatar-user-detail as'
                             style={{
                                 backgroundImage: `url(${profileData.avatar ? profileData.avatar : '/assets/img/profile-ic.svg'})`,
                             }}
                        />
                    </KirokuLoader>
                    <div className='mt-24'>
                        <div className='text-center'>
                            <KirokuButton color={'success'} onClick={() => inputElement.current.click()}>
                                {t('CustomerEditor.ChangeAvatar')}
                            </KirokuButton>
                            <input type='file' ref={inputElement} hidden
                                   accept="image/jpeg, image/png"
                                   onChange={() => {
                                       handleChangeImage();
                                       validateAlertAvatar();
                                   }}
                            />
                        </div>
                    </div>
                    <div className='content-avatar text-center pt-3'>
                        <span>{t('Customer.SupportedFile')} </span>
                        <br/>
                        <span>{t('Customer.Maximum')}</span>
                    </div>
                </ComponentMobile>

                <div className='col-md-12' style={{ borderBottom: 'solid 1px #dfe3e9' }}>
                    <div className='d-flex justify-content-between pt-10 nar-bar-user-detail'>
                        <div style={{ cursor: 'pointer' }}
                             className={`nav-bar ${tabName === 'profile' ? 'active' : ''}`}
                             onClick={() => setTabName('profile')}>{t('staff.ProfileDetail')}
                        </div>
                        <div style={{ cursor: 'pointer' }}
                             className={`nav-bar ${tabName === 'changePass' ? 'active' : ''}`}
                             onClick={() => setTabName('changePass')}>{t('common.ChangePassword')}
                        </div>
                        <div style={{ cursor: 'pointer' }}
                             className={`nav-bar ${tabName === 'teams' ? 'active' : ''}`}
                             onClick={() => setTabName('teams')}>{t('common.Team')}
                        </div>
                    </div>
                </div>
            </div>
            <div className='padding-responsive'>
                <TabProfile show={tabName === 'profile'}
                            resultUser={resultUser}
                            inputElement={inputElement}
                            validateAlertAvatar={() => validateAlertAvatar()}
                            updateInfoUser={updateInfoUser}
                            handleSubmitUser={handleSubmitUser}
                            handleOnChangeCustomers={handleOnChangeCustomers}
                            handleOnChangeTeams={handleOnChangeTeams}
                            handleChangeQualification={handleChangeQualification}
                            handleChangeImage={handleChangeImage}
                            profileData={profileData}
                            isLoadingAvatar={isLoadingAvatar}
                            user={user}
                />
                <TabChangePassword
                    show={tabName === 'changePass'}
                    resultUser={resultUser}/>
                <TabManageTeam
                    show={tabName === 'teams'}
                    resultUser={resultUser}/>
            </div>
        </KirokuCardWeb>


        <div hidden={tabName !== 'profile'} className='pt-3 pb-3'>
            <KirokuCardWeb>
                <h5 className='pt-3 pl-3 pr-3  m-0'>{t('common.Security')}</h5>
                <div className="row pl-3 pr-3 pb-3 m-0">
                    <div className="col-md-8 pt-10">
                        <span className='customer-info f-bold'>{t('common.Two-factorAuthentication')}:</span>
                    </div>
                    <div className="col-md-4 pt-10">
                        {!!resultUser[ '2fa' ] ? (
                            <KirokuLabel color="primary">
                                {t('common.enabled')}
                            </KirokuLabel>
                        ) : (
                            <KirokuLabel color="danger">
                                {t('common.disabled')}
                            </KirokuLabel>
                        )}
                    </div>
                </div>
            </KirokuCardWeb>
        </div>
        <ComponentMobile>
            <div style={{ marginBottom: 80 }}/>
        </ComponentMobile>
    </div>);
}


export default function UserEditor(props) {
    const [resultUser, userDetail, userDetailLoading] = resources.useDetail('User');
    const { t } = useTranslation();

    useEffect(() => {
        userDetail(props.match.params.id).catch(error => console.log(error));
    }, [props.match.params.id, userDetail]);


    return (<KirokuLoader loading={userDetailLoading}>
        <If statement={resultUser}
            otherwise={<SearchNotFound message={t('common.UserInvalid')} description={' '}/>}
        >
            {() => <WrapperUserEditor
                resultUser={resultUser}
            />}
        </If>
    </KirokuLoader>);
}

import React, { useState }                                               from 'react';
import { KirokuButton, KirokuModal, KirokuModalBody, KirokuModalFooter } from '../../../ui';
import { useTranslation }                                                from 'react-i18next';
import { resources }                                                     from '@kiroku/api';

export default function ModalDeletePlan(props) {
    const { t }                                         = useTranslation();
    const { searchAfterDelete, closeModal, show, plan } = props;
    const deletePlan                                    = resources.useDelete('Plan')[1];
    const [isDisabledBtnSubmit, setIsDisabledBtnSubmit] = useState(false);

    const confirmRemove = () => {
        setIsDisabledBtnSubmit(true);
        closeModal();
        deletePlan(plan.id, { customer_id: plan.customer.id, customer_name: plan.customer.name })
            .then(() => {
                searchAfterDelete();
            })
    };
    return (
        <KirokuModal show={ show } onClick={ closeModal }>
            <KirokuModalBody>
                <p className='p-3'>
                    { t('plans.messageDelete') }
                </p>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={ closeModal } color={ 'white' }>
                    { t('common.Cancel') }
                </KirokuButton>
                <KirokuButton
                    onClick={ confirmRemove }
                    disabled={ isDisabledBtnSubmit }
                    color={ 'danger' }>
                    {t('plans.delete')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

import React, { useEffect, useState } from 'react';
import { resources }                  from '@kiroku/api';
import If                             from '../../If';
import {
    ComponentMobile, ComponentWeb, KirokuButtonLink, KirokuCard, KirokuCardList, KirokuDropdownClose, KirokuLabel,
    KirokuLoader, KirokuPopoverItem, KirokuTable, NoResultWithButtonLink, SearchNotFound, TBody, THeader,
}                                     from '../../../ui';
import moment                         from 'moment';
import { useTranslation }             from 'react-i18next';
import { Link, useHistory }           from 'react-router-dom';
import TogglePopover                  from '../../Templates/QuestionTypes/TogglePopover';
import usePageTitle                   from '../../../ui/v3/hooks/usePageTitle';
import ModalAddSurveyTemplate         from './QuickAddSurvey/ModalAddSurveyTemplate';
import SupportMonitored               from './SupportMonitored';
import TemplateProvider               from './QuickAddSurvey/makeTemplate/TemplateProvider';

export default function SupportDetail(props) {
    const { t }                               = useTranslation();
    const [support, loadDetail]               = resources.useDetail('Support');
    const { search, result, cancelAPISearch } = resources.useSearch('Template');
    const [loadingAPI, setLoadingAPI]         = useState(true);
    const history                             = useHistory();
    const [showModal, setShowModal]           = useState('');
    usePageTitle(t('plans.content'));

    useEffect(() => {
        loadDetail(props.match.params.id).then(() => {
            setLoadingAPI(false);
        }).catch((err) => {
            console.log(err);
            setLoadingAPI(false);
        });
    }, [loadDetail, props.match.params.id]);

    useEffect(() => {
        if (support) {
            search({ type: 'customers', customers: support.plan.customer_id });
        }
        return () =>  cancelAPISearch();
    }, [support, search, cancelAPISearch]);

    const renderStatusReview = (status) => {
        switch (status) {
            case 'open':
                return <img style={{paddingBottom: 2}} src={ '/assets/img/open.svg' } alt={ 'open.svg' }/>;
            case 'in-progress' :
                return <img style={{paddingBottom: 2}} src={ '/assets/img/in-progress.svg' } alt={ 'in-progress.svg' }/>;
            case 'resolved' :
                return <img style={{paddingBottom: 2}} src={ '/assets/img/resolved.svg' } alt={ 'resolved.svg' }/>;
            case 'done' :
                return <img style={{paddingBottom: 2}} src={ '/assets/img/resolved.svg' } alt={ 'resolved.svg' }/>;
            default :
                return;
        }
    };

    return (
        <div>
            <KirokuLoader loading={ loadingAPI }>
                <If statement={ support }
                    otherwise={ <SearchNotFound message={t('common.SupportInvalid')} description={' '}/> }
                >
                    { () => <div>
                        <div className='padding-responsive'>
                            <div>
                                <div className={ 'd-flex align-items-center' }>
                                    <img src={ `/assets/img/back.svg` } alt={ 'back-button' }/>
                                    <span className={ 'pl-2 text-back-button cursor-pointer' }
                                          onClick={ () => history.push(`/plans/${support.plan_id}`) }
                                    >
                                        { t('common.goToPlanDetail', {customerName: support.plan.customer.name}) }
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between pt-3 pb-3 align-items-center">
                                <div className={ 'd-flex' } style={ { flexWrap: 'wrap' } }>
                                    { !support.review &&
                                    <div className={ ` ${ !support.review || 'pl-2 pt-2' }` }>
                                        <KirokuButtonLink
                                            className={ 'font-label-preview' }
                                            style={ {
                                                border : 'solid 1px #1468a7',
                                                color  : '#1468a7',
                                                padding: '0 16px',
                                                height : 36,
                                            } }
                                            onClick={ () => setShowModal('review') }
                                        >
                                            <div className="d-flex align-items-center">
                                                <img src={ `/assets/icon/iconMonitor.svg` } alt="iconMonitor.svg"
                                                /> &nbsp;<span style={ { fontSize: 14 } }>{ t('plans.review') }</span>
                                            </div>
                                        </KirokuButtonLink>
                                    </div>
                                    }
                                </div>
                                <div hidden={support.plan.published_at}>
                                    <ComponentWeb>
                                        <KirokuButtonLink
                                            className={ 'font-label-preview btn-edit-plan' }
                                            onClick={ () => {
                                                history.push(`/supports/edit/${ support.id }`);
                                            } }
                                            style={ {
                                                padding: '0 16px',
                                                height : 36,
                                            } }
                                        >
                                            <div className={ 'd-flex align-items-center' }>
                                                <img src={ `/assets/img/pen-edit.svg` } alt="pen-edit.svg"
                                                /> &nbsp;<span
                                                style={ { fontSize: 14 } }>{ t('common.Edit') }</span>
                                            </div>
                                        </KirokuButtonLink>
                                    </ComponentWeb>
                                    <ComponentMobile>
                                        <KirokuDropdownClose
                                            title={ t('Surveys.BulkAction') }
                                            onSelect={ (event) => {
                                                if (event.kirokuSelected.value === 'update') {
                                                    history.push(`/supports/edit/${ support.id }`);
                                                }
                                            } }
                                            items={ [
                                                {
                                                    value    : 'update', label: t('common.Edit'),
                                                    component: <div className={ 'd-flex align-items-center' }>
                                                        <img src={ `/assets/img/pen-edit.svg` } alt="pen-edit.svg"
                                                        /><span
                                                        style={ { fontSize: 14 } }> &nbsp;{ t('common.Edit') }</span>
                                                    </div>
                                                    ,
                                                },
                                            ] }
                                        />
                                    </ComponentMobile>
                                </div>
                            </div>
                            <div>
                                { (support.review && !showModal) &&
                                <div className="pb-3">
                                    <div className="bg-white">
                                        <div className="padding-responsive">
                                            <div className="d-flex justify-content-between">
                                                <div className={'d-flex align-items-center'}>
                                                    { support.review.is_draft ?
                                                        <div className="d-flex align-items-center">
                                                            <div className={ 'review-plan-text' }> {t('plans.review')} &nbsp;</div>
                                                            <div className='label-drafted-at'>
                                                                <KirokuLabel color={ 'warning' }
                                                                             style={ {
                                                                                 width          : 'fit-content',
                                                                                 border: 'solid 1px #EA9B3F',
                                                                                 backgroundColor: '#EA9B3F',
                                                                                 margin         : 0,
                                                                             } }>
                                                                    { t('plans.draft') }
                                                                </KirokuLabel>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>
                                                            <img src={`/assets/img/reviewd.svg`} alt="reviewd.svg"
                                                                 style={ { width: 21, height: 20 } }
                                                            />
                                                            <span className={ 'font-size-18' }>&nbsp;{t('plans.monitoredStatus')}</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='d-flex edit-review-text cursor-pointer'
                                                     onClick={ () => setShowModal('review') }>
                                                    <i className='fas fa-pencil-alt'>
                                                        <span className={ 'ml-1' }>
                                                            { t('common.Edit') }
                                                        </span>
                                                    </i>
                                                </div>
                                            </div>
                                            <div className="pt-2">
                                                <div className="d-flex">
                                                    <div className={'label-monitor-form main-content-info'}>{ t('plans.resultOfAction') }:</div>
                                                    <div className="pl-2"/>
                                                    { support.review.action_status ?
                                                        <div className={'d-flex align-items-center'}>
                                                            <div className={'main-content-info'}>{ t(`status.${ support.review.action_status }`) }</div>
                                                            <div className={ 'pl-1' }>{ renderStatusReview(support.review.action_status) }</div>
                                                        </div> : ''
                                                    }
                                                </div>
                                            </div>
                                            <div className="pt-2">
                                                <div className="d-flex">
                                                    <div className={'label-monitor-form main-content-info'}>{ t('plans.resultOfAchievement') }:</div>
                                                    <div className="pl-2"/>
                                                    { support.review.achievement_status ?
                                                        <div className={'d-flex align-items-center'}>
                                                            <div className={'main-content-info'}>{ t(`achievementStatus.${ support.review.achievement_status }`) }</div>
                                                            <div className={ 'pl-1' }>{ renderStatusReview(support.review.achievement_status) }</div>
                                                        </div> : ''
                                                    }

                                                </div>
                                            </div>
                                            <div className="pt-2">
                                                <div className="d-flex comment-monitor">
                                                    <div className={'label-monitor-form main-content-info'} >{ t('plans.commentSupport') }:</div>
                                                    <div className="pl-2"/>
                                                    <div className={'input-area-comment-monitor main-content-info break-word-and-pre-wrap'} >
                                                        { support.review.comment }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> }

                                {
                                    showModal === 'review' && <SupportMonitored
                                        show={ showModal === 'review' }
                                        closeModal={ () => setShowModal('') }
                                        isPlanMonitored={ support.plan.review_status === 2}
                                        support={ support }
                                        loadingDetail={ () => {
                                            setLoadingAPI(true);
                                            loadDetail(support.id).then(() => setLoadingAPI(false));
                                        } }
                                    />
                                }
                            </div>
                            <div className="bg-white">
                                <div className={ 'header-card-detail' }>
                                    <div className="d-flex justify-content-between align-items-center"
                                         style={ { flexWrap: 'wrap' } }>
                                        <div className="support-name">{t('plans.supportInfo')}</div>
                                        <div className={ 'p-2' }/>
                                        <div className={ 'last-update-text' }
                                             style={ { fontSize: 14 } }>{ t('plans.lastUpdateAt') }: &nbsp;
                                            { moment.unix(support['updated_at'] || support['created_at']).format(t('dateTimeFormat.YearMonthDateWithHour')) }
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="padding-info" style={ { borderBottom: 'solid 1px #dfe2e5' } }>
                                        <div className={ 'label-info' }>{ t('plans.customerNeeds') }:</div>
                                        <div className={ 'main-content-info word-break break-word-and-pre-wrap pt-10' } >
                                            { support.needs }
                                        </div>
                                    </div>
                                    <div className="padding-info" style={ { borderBottom: 'solid 1px #dfe2e5' } }>
                                        <div className={ 'label-info' }>{ t('plans.targetTerm') }:</div>
                                        <div className={ 'main-content-info word-break pt-10' }>{ support.target }</div>
                                    </div>
                                    <div className="padding-info" style={ { borderBottom: 'solid 1px #dfe2e5' } }>
                                        <div className={ 'label-info' }>{ t('plans.content') }:</div>
                                        <div className={ 'main-content-info pt-10 break-word-and-pre-wrap' }> { support['description'] }</div>
                                    </div>
                                    <div className="padding-info">
                                        <div className={ 'label-info' }>{ t('plans.achievementTime') }:</div>
                                        <div className={ 'main-content-info pt-10' } >
                                            { support.term }{ t('plans.monthLater') }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-3">
                                <ComponentWeb>
                                    { support.surveys.length ? <KirokuTable>
                                            <THeader>
                                                <tr>
                                                    <th style={ { minWidth: 100 } }>{ t('common.Title') }</th>
                                                    <th style={ { minWidth: 180 } }>{ t('common.Description') }</th>
                                                    <th style={ { width: 300 } }/>
                                                </tr>
                                            </THeader>
                                            <TBody>
                                                { support.surveys.map((survey, index) =>
                                                    <tr key={ index }>
                                                        <td><Link
                                                            to={ `/surveys/${ survey.id }` }>{ survey.title }</Link>
                                                        </td>
                                                        <td> { survey.description }</td>
                                                        <td>
                                                            <div className="d-flex" style={ { width: 300 } }>
                                                                <Link to={ {
                                                                    pathname: '/analysis',
                                                                    state   : {
                                                                        survey  : {
                                                                            id   : survey.id,
                                                                            title: survey.title,
                                                                        },
                                                                        customer: {
                                                                            id  : support.plan.customer.id,
                                                                            name: support.plan.customer.name,
                                                                        },
                                                                        plans   : {
                                                                            startDate: support.plan.start,
                                                                            endDate  : support.plan.end,
                                                                        },
                                                                    },
                                                                } }
                                                                      className='btn btn-kiroku-primary d-flex align-items-center'
                                                                >
                                                                    { t('common.Analysis') }
                                                                </Link>
                                                                <div className={ 'p-2' }/>
                                                                <Link
                                                                    to={ `/records/create/customer/${ support.plan.customer.id }/survey/${ survey.id }` }
                                                                >
                                                                    <div
                                                                        className='btn btn-kiroku-success d-flex align-items-center justify-content-center'
                                                                    >
                                                                        { t('common.Records') }
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>) }
                                            </TBody>
                                        </KirokuTable> :
                                        <div className={ 'bg-white' }>
                                            <NoResultWithButtonLink
                                                onClick={ () => {
                                                    setShowModal('addTemplate');
                                                } }
                                                title={ t('plans.addSurveyTemplates') }
                                                color={ 'primary' }
                                            />
                                        </div> }
                                </ComponentWeb>
                                <ComponentMobile>
                                    { support.surveys.length ?
                                        <KirokuCardList>
                                            {
                                                support.surveys.map((survey, index) =>
                                                    <KirokuCard
                                                        key={ index }
                                                        action={ <TogglePopover>
                                                            <KirokuPopoverItem>
                                                                <div
                                                                    className='d-flex justify-content-between p-1 fix-icon-align'>
                                                                    <Link to={ {
                                                                        pathname: '/analysis',
                                                                        state   : {
                                                                            survey  : {
                                                                                id   : survey.id,
                                                                                title: survey.title,
                                                                            },
                                                                            customer: {
                                                                                id  : support.plan.customer.id,
                                                                                name: support.plan.customer.name,
                                                                            },
                                                                            plans   : {
                                                                                startDate: support.plan.start,
                                                                                endDate  : support.plan.end,
                                                                            },
                                                                        },
                                                                    } }>
                                                                        <i className={ 'fa fa-line-chart pr-1' }/>
                                                                        <span>{ t('common.Analysis') }</span>
                                                                    </Link>
                                                                    <Link
                                                                        to={ `/records/create/customer/${ support.plan.customer.id }/survey/${ survey.id }` }>
                                                                        <i className={ 'fas fa-file-signature pr-1' }/>
                                                                        <span>{ t('common.Records') }</span>
                                                                    </Link>
                                                                </div>
                                                            </KirokuPopoverItem>
                                                        </TogglePopover> }
                                                    >
                                                        <div className={`pr-3 ${support.surveys.length - 1 === index ? 'pb-3' : ''}`}>
                                                            <Link to={ `/surveys/${ survey.id }` }>{ survey.title }</Link>
                                                            <div className={'pt-2'}>{ t('common.Description') }: {survey.description}</div>
                                                        </div>
                                                    </KirokuCard>,
                                                )
                                            }
                                        </KirokuCardList>: <div className={ 'bg-white' }>
                                            <NoResultWithButtonLink
                                                onClick={ () => {
                                                    setShowModal('addTemplate');
                                                } }
                                                title={ t('plans.addSurveyTemplates') }
                                                color={ 'primary' }
                                            />
                                        </div> }
                                </ComponentMobile>
                            </div>
                            <ComponentMobile>
                                <div style={ { marginBottom: 80 } }/>
                            </ComponentMobile>
                        </div>
                        <TemplateProvider templateDefault={ result }>
                            <ModalAddSurveyTemplate
                                show={ showModal === 'addTemplate' }
                                onClose={ () => setShowModal('') }
                                support={ support }
                                loadSupportDetail={ () =>  {
                                    setLoadingAPI(true);
                                    loadDetail(props.match.params.id).then(() => {
                                        setLoadingAPI(false);
                                    }).catch((err) => {
                                        console.log(err);
                                        setLoadingAPI(false);
                                    })
                                }}
                            />
                        </TemplateProvider>
                    </div> }
                </If>
            </KirokuLoader>
        </div>
    );
}

import { useCustomerContext } from './CustomerContext';
import moment                 from 'moment';

export default function useValidationCustomer() {
    const { infoCustomer } = useCustomerContext();
    const { name, contract_start, contract_end, date_of_birth, furigana,
              certifications, isValidContractStartDate, isValidContractEndDate } = infoCustomer;

    const dateOfBirth = date_of_birth === null || moment(date_of_birth).isValid()
    const validateDate = (contract_end && contract_start) ? contract_start > contract_end : false;
    const service = certifications[0].services.filter(tmp => tmp.name || tmp.startDate || tmp.endDate)
    const validateService = service.map(tmp => {
        if(tmp.endDate && tmp.startDate) {
            return tmp.endDate < tmp.startDate
        }
        return false
    })
    const furiganaText = furigana && !furigana.match(/^[ぁ-んーゔ\s]+$/g);
    const btnSave = !name || !dateOfBirth || validateDate || !isValidContractStartDate || !isValidContractEndDate
        || validateService.includes(true) || (certifications[0].certification_number
    && certifications[0].certification_number.length < 10) || furiganaText;

    return { btnSave };
};

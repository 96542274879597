import { useTranslation }                               from 'react-i18next';
import { KirokuRadio, KirokuInput, KirokuActionSurvey } from '../../../ui';
import React                                            from 'react';
import lodash                                           from 'lodash';


export function Choices({ question, onChangeChoiceAnswer, handleDelChoice, handleAddChoice }) {
    const { t } = useTranslation();

    return (
        <div id={'choicesQuestion'} className={`${question.choices.length < 4 ? '' : 'scroll_Answer_Question mt-2'}`}>
            {question.choices.map((choice, index) => {
                return <div key={index} className='summary-content'>
                    <label htmlFor={`input-choices-${question.index}-${index}`}
                           className='field-form-label pt-3'>{t('createSurvey.Answer')}
                    </label>
                    <div className='d-flex'>
                        <KirokuInput value={choice.answer}
                                     id={`input-choices-${question.index}-${index}`}
                                     name={'answer'}
                                     onChange={(e) => onChangeChoiceAnswer(e, index)}
                                     placeholder={t('createSurvey.EnterAnAnswerChoice')}/>
                        <div className='action-fix pl-4'>
                            <div
                                hidden={question.choices.length === 2}
                                onClick={() => handleDelChoice(index)}
                            >
                                <KirokuActionSurvey icon={'minus'}/>
                            </div>
                        </div>
                    </div>

                    <div hidden={choice.validate || choice.answer}
                         style={{
                             color   : 'red',
                             fontSize: 12,
                         }}
                         className='mt-2'
                    >
                        {t('createSurvey.AnswerMustNotNull')}
                    </div>

                </div>;
            })}
            <div className='pt-3 action-fix-plus'>
                <div onClick={() => {
                    handleAddChoice();
                }} className='d-flex'>
                    <div style={{ cursor: 'pointer', display: 'flex' }}>
                        <KirokuActionSurvey icon={'plus'}/>
                        <span className='pl-2'>{t('createSurvey.AddOtherAnswer')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}


export function QuestionTypeOptions({ question, onChange }) {
    const { t } = useTranslation();

    if (!question) {
        question = {
            type : 'InputText',
            index: null,
        };
    }

    const questionTypes = [
        {
            image: 'input-text.svg',
            text : 'InputText',
            value: 'InputText',
        }, {
            image: 'no.svg',
            text : 'YesNo',
            value: 'YesNo',
        }, {
            image: 'date-time.svg',
            text : 'Date',
            value: 'Date',
        }, {
            image: 'multi-select.svg',
            text : 'Single',
            value: 'Single',
        }, {
            image: 'single-select.svg',
            text : 'Multiple',
            value: 'MultiChoice',
        }, {
            image: 'media2.svg',
            text : 'Media',
            value: 'Media',
        }, {
            image: 'simple-mess.svg',
            text : 'Simple',
            value: 'Message',
        }, {
            image: 'numbers.svg',
            text : 'NumberQuestion',
            value: 'Number',
            style: { width: 25 },
        }, {
            image: 'date.svg',
            text : 'Date',
            value: 'OnlyDate',
        }, {
            image: 'clock.svg',
            text : 'Time',
            value: 'Time',
        },
    ];

    const getQuestionTypes = (isNotQuestionTypeYesNo) => {
        if (isNotQuestionTypeYesNo) {
            lodash.remove(questionTypes, (i) => {
                return i.value === 'YesNo';
            });
        }
        return questionTypes.map(type => {
            return {
                label: <div className={'pr-2'}>
                        <span>
                            <img src={`/assets/img/${type.image}`} alt=""
                                 style={{ ...type.style }}/>
                        </span>
                    <span className={'text-muted pl-2'}>
                            {t(`createSurvey.${type.text}`)}
                        </span>
                </div>,
                value: type.value,
            };
        });
    };


    return <>
        <p className='margin-none'>{t('createSurvey.ChooseType')}</p>
        <div className='group-select-type-question-on-survey'>
            <div className={'container-radio-select-type'}>
                <div className={'radio-select-left'}>
                    {getQuestionTypes(question.type !== 'YesNo').map((type, index) => {

                        if(index > 4) {
                            return null;
                        }
                        return <KirokuRadio value={type.value}
                                            onChange={onChange}
                                            checked={question.type === type.value}
                                            name={`radio-select-type-question-${question.index}`}
                                            id={`radio-select-type-question-${question.index}-${index}`}
                                            key={index}
                        >
                            {type.label}
                        </KirokuRadio>;
                    })}
                </div>
                <div className={'radio-select-right'}>
                    {getQuestionTypes(question.type !== 'YesNo').map((type, index) => {
                        if(index < 5){
                            return null;
                        }
                        return <KirokuRadio value={type.value}
                                            onChange={onChange}
                                            checked={question.type === type.value}
                                            name={`radio-select-type-question-${question.index}`}
                                            id={`radio-select-type-question-${question.index}-${index}`}
                                            key={index}
                        >
                            {type.label}
                        </KirokuRadio>;
                    })}
                </div>
            </div>
        </div>
    </>;
}

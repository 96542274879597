import { useDispatch, useSelector } from 'react-redux';
import { useCallback }              from 'react';
import { KIROKU_ACTION_SIDE_BAR }   from '../applicationReducer';

export default () => {
    const isOpenMenuSideBar = useSelector(state => state.getIn(['app','kirokuaiReducer','isOpenMenuSideBar']));
    const dispatch = useDispatch();
    const changeIsOpenMenu  = useCallback(() => {
        dispatch({type: KIROKU_ACTION_SIDE_BAR})
    }, [dispatch]);
    return [isOpenMenuSideBar, changeIsOpenMenu]

}

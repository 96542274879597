import React                                                             from 'react';
import { KirokuButton, KirokuModal, KirokuModalBody, KirokuModalFooter } from '../../../ui';
import { resources }                                                     from '@kiroku/api';
import useNotification                                                   from '../../Layout/useNotification';
import { useTranslation }                                                from 'react-i18next';

export default function ModalConfirmSaveDraft(props) {
    const { show, plan, closeModal, loadDetail } = props;
    const update                                 = resources.useUpdate('Plan')[1];
    const { notification }                       = useNotification();
    const { t }                                  = useTranslation();

    const handleSaveDraft = () => {
        let value = {
            ...plan,
            customer_id               : plan.customer.id,
            customer_name             : plan.customer.name,
            overview                  : plan.overview,
            short_term_target         : plan.short_term_target,
            long_term_target          : plan.long_term_target,
            long_term_target_start_at : plan.long_term_target_start_at,
            long_term_target_end_at   : plan.long_term_target_end_at,
            short_term_target_start_at: plan.short_term_target_start_at,
            short_term_target_end_at  : plan.short_term_target_end_at,
            start_at                  : plan.start_at,
            end_at                    : plan.end_at,
            review_status             : 0
        };
        update(plan.id, value).then(() => {
            notification(t('message.UpdateSuccess'), 'success');
            closeModal();
            loadDetail();
        });
    };

    const styleTextConfirmSaveDraft = {
        fontSize: 14
    }

    return (
        <KirokuModal show={ show }>
            <KirokuModalBody>
                <div className="padding-responsive"
                     style={
                         plan.review ?
                             { ...styleTextConfirmSaveDraft, color: '#ed1c24'} :
                             styleTextConfirmSaveDraft
                     }>
                    {plan.review &&  t('plans.draftedReviewWillBeRemoved') }
                    {t('plans.confirmSaveDraft') }
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton color="light"
                              onClick={ closeModal }
                >
                    <span style={ { fontSize: 14 } }>
                        { t('plans.cancel') }
                    </span>
                </KirokuButton>
                <KirokuButton style={ {
                    backgroundColor: plan.review ? '#ed1c24': '#C9A043',
                    color          : '#fff',
                    padding        : '5px 16px',
                } }
                              onClick={ handleSaveDraft }
                >
                    <span style={ { fontSize: 14 } }>
                        { t('plans.changeDraft') }
                    </span>
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

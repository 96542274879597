import React, { useEffect }              from 'react';
import { KirokuSectionDropdownShowMore } from '../../../ui';
import { useTranslation }                from 'react-i18next';
import { resources }                     from '@kiroku/api';
import { getGroupedOptions }             from '../../Converter';

export default function SelectTemplates({ templates, onChange }) {

    const { t }              = useTranslation();
    const { result, search } = resources.useSearch('Template');

    useEffect(() => {
        search({ type: 'all' });
    }, [search]);

    return (
        <>
            <div className="p-2"/>
            <div className={ 'pb-2' }>{ t('RecordList.surveyTemplate') }</div>
            <KirokuSectionDropdownShowMore
                tagType={ 'Template' }
                placeholder={ t('Surveys.placeholderSearch') }
                onChange={ onChange }
                value={ templates }
                options={ getGroupedOptions('Template', result) }
            />
        </>
    );
}

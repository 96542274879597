import React                       from 'react';
import { useTranslation }          from 'react-i18next';
import { useCondition }            from '../../useCondition';
import useSelectedCustomer         from '../ModalSelecteCustomers/useSelectedCustomer';
import { useCustomerModalContext } from '../ModalSelecteCustomers/CustomerModalContext';
import { useProfile }              from '../../../useProfile';
import lodash                      from 'lodash';
import { resources }               from '@kiroku/api';

export default function CustomerSelectConditionMobile() {
    const { t }               = useTranslation();
    const { customerState }   = useCustomerModalContext();
    const { customerDefault } = customerState;
    const { search }          = resources.useSearch('Team');
    const [profile]           = useProfile();

    const {
              updateRecordCondition, recordCondition,
          } = useCondition();

    const {
              handleChangeCustomerTab, isOpenModal,
              setShowModalSelectCustomer,
          } = useSelectedCustomer();

    return (
        <div className="p-0 pt-3">
            <div className="d-flex justify-content-around border-btn-status-group">
                <div className={`border-width-each-status first-btn font-text-on-btn cursor-pointer
                                    ${(recordCondition.customerTab === 'SEARCH_BY_CUSTOMER_TAG') && !isOpenModal ? 'btn-active' : ''}`}
                     onClick={() => {
                         updateRecordCondition({
                             ...recordCondition, customerId: customerDefault.map(customer => customer.id),
                             page                          : 1,
                             customerTab                   : 'SEARCH_BY_CUSTOMER_TAG',
                         });
                         handleChangeCustomerTab('SEARCH_BY_CUSTOMER_TAG');
                     }}
                >
                    {t('RecordsBeta.customerTagByUser')}
                </div>
                <div className={`border-width-each-status-center font-text-on-btn cursor-pointer
                                     ${(recordCondition.customerTab === 'SEARCH_BY_TEAM_TAG') && !isOpenModal ? 'btn-active' : ''}`}
                     onClick={() => {
                         search({
                             type  : 'all',
                             userId: profile.id,
                         }).then(res => {
                             const teams     = res.result.map(team => team.customers);
                             const customers = teams.length ? teams.reduceRight((customer, customerNext) => customer.concat(customerNext)) : [];
                             updateRecordCondition({
                                 ...recordCondition,
                                 customerId : lodash.uniqBy(customers, 'id')
                                     .map(customer => customer.id),
                                 page       : 1,
                                 customerTab: 'SEARCH_BY_TEAM_TAG',
                             });
                             handleChangeCustomerTab('SEARCH_BY_TEAM_TAG');
                         });
                     }}
                >
                    {t('RecordsBeta.team')}
                </div>
                <div className={`border-width-each-status third-btn font-text-on-btn cursor-pointer
                                     ${(recordCondition.customerTab === 'SEARCH_CUSTOM_CUSTOMER') || isOpenModal ? 'btn-active' : ''}`}
                     onClick={() => {
                         setShowModalSelectCustomer();
                     }}
                >
                    {t('RecordsBeta.choice')}
                </div>
            </div>
        </div>
    );
}

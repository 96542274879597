import React from 'react';

function KirokuInputToSearch(props) {
    return (
        <div style={ { position: 'relative' } }>
            <input value={ props.value || '' } placeholder={ props.placeholder } id={ props.id }
                   name={props.name}
                   onChange={ props.onChange } onBlur={ props.onBlur }
                   type="text" className="form-control-kiroku pr-4"/>
            <div className='action-input'>
                { props.children }
            </div>
        </div>
    );
}

export default KirokuInputToSearch;

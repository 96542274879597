import React                                      from 'react';
import { KirokuInputIcon, KirokuLogicApplicated } from '../../../../../ui';
import * as moment                                from 'moment';
import { useHeadingQuestion }                     from '../../../../Records/useSurvey';
import { useScrollContext }                       from '../../../../../ui/Scrolling';
import { useTranslation }                         from 'react-i18next';

export default function TimeQuestion(props) {

    const { question, headerNextQuestion, value = null, isSurveyDetail } = props;

    const { optionalContentOrHeading } = useHeadingQuestion(question);
    const { scrollTo }                 = useScrollContext();
    const {t} = useTranslation();

    return (<div className={'pb-3'}>
        <div className='wraper-question'>
            <div className="background-question">
                <div className='number-question d-flex justify-content-between align-items-center'>
                    <div
                        className="question-number text-overflow-elip">
                        {optionalContentOrHeading}
                    </div>
                    <div hidden={!isSurveyDetail}>
                        <KirokuLogicApplicated  padding={'5px 5px 5px 7px'} question={question}/>
                    </div>
                </div>
                <span className='content-name-question'>{question.content}</span>
                <div
                    hidden={typeof question.nextQuestion !== 'number' || !isSurveyDetail}
                    className='text-info pl-10 text-overflow-elip scroll-link-question'
                    onClick={() => scrollTo(`question-survey-${question.nextQuestion}`, 202)}
                >
                    <i className='fas fa-arrow-right' style={{ paddingTop: 4 }}/>
                    <span className={'font-size-14'}> {headerNextQuestion}</span>
                </div>
            </div>
            <div className='answer-question'>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <KirokuInputIcon disabled name={'date'} icon={'far fa-clock'}
                                         value={value ? moment.unix(value).format(t('dateTimeFormat.HourMinute')) : ''}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

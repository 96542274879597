import React, { useEffect, useState }                                                  from 'react';
import { useTranslation }                                                              from 'react-i18next';
import { KirokuButton, KirokuCardWeb, KirokuLoader, KirokuNotFound, KirokuPagination } from '../../../../ui';
import { authentication, resources }                                                   from '@kiroku/api';
import ConditionSearch                                                                 from '../ConditionSearch';
import If                                                                              from '../../../If';
import { Link }                                                                        from 'react-router-dom';
import StatusRecordLabel                                                               from './StatusRecordLabel';
import useSearchTeamOfUser                                                             from '../useSeachTeamOfUser';

export default function CustomerTab(props) {
    const { tabType, template, params } = props;

    const { user } = authentication.useAuthenticatedContext();
    const {
              result, search, condition,
              pagination, cancelAPISearch,
          }        = resources.useSearch('Customer');

    const [tabActive, setTabActive]       = useState('IS_USER');
    const [isLoadingTab, setIsLoadingTab] = useState(true);

    const { searchTeamByUserId } = useSearchTeamOfUser(user.id);

    const { t }                  = useTranslation();

    useEffect(() => {
        if (tabType === 'CUSTOMER_TAB' && tabActive === 'IS_USER' && parseInt(params.id) === template.id) {
            setIsLoadingTab(true);
            search({
                type     : 'labeling',
                users    : user.id,
                survey_id: template.id,
                page     : 1,
            }, 'customer-with-label')
                .then(() => {
                    setIsLoadingTab(false);
                }).catch((error) => {
                console.log(error);
                setIsLoadingTab(false);
            });
        }

        if (tabType === 'CUSTOMER_TAB' && tabActive === 'TEAM' && parseInt(params.id) === template.id) {
            setIsLoadingTab(true);
            searchTeamByUserId().then(res => {
                const conditionSearch = {
                    type     : 'labeling',
                    survey_id: template.id,
                    users    : user.id,
                    teams    : res.result.map(team => team.id),
                    page     : 1,
                };
                if (!conditionSearch.teams.length) {
                    delete conditionSearch.teams;
                }
                search(conditionSearch, 'customer-with-label').then(() => {
                    setIsLoadingTab(false);
                });
            }).catch((error) => {
                console.log(error);
                setIsLoadingTab(false);
            });
        }

        if (tabType === 'CUSTOMER_TAB' && tabActive === 'ALL' && parseInt(params.id) === template.id) {
            setIsLoadingTab(true);
            search({
                type     : 'labeling',
                survey_id: template.id,
                page     : 1,
            }, 'customer-with-label').then(() => {
                setIsLoadingTab(false);
            }).catch((error) => {
                console.log(error);
                setIsLoadingTab(false);
            });
        }
        return () => cancelAPISearch();
    }, [tabType, search, user, template, cancelAPISearch, tabActive, searchTeamByUserId, params]);

    return (
        <div hidden={tabType !== 'CUSTOMER_TAB'}  className="pt-3">
            <ConditionSearch
                tabActive={tabActive}
                setTabActive={setTabActive}
            />
            <div className="pt-3">
                <If statement={result.length}>
                    {() => <div className={'d-flex justify-content-end'}>
                        <KirokuPagination
                            disabledPrev={pagination.currentPage === 1}
                            disabledNext={pagination.currentPage === pagination.totalPage}
                            increasePage={() => {
                                if(isLoadingTab) {
                                    return null
                                }
                                setIsLoadingTab(true);
                                search({...condition, page: pagination.currentPage + 1 }, 'customer-with-label')
                                    .then(() => setIsLoadingTab(false));
                            }}
                            decreasePage={ () => {
                                if(isLoadingTab) {
                                    return null
                                }
                                setIsLoadingTab(true);
                                search({...condition, page: pagination.currentPage - 1 }, 'customer-with-label')
                                    .then(() => setIsLoadingTab(false));
                            }}
                            currentPage={pagination.currentPage}
                            totalPage={pagination.totalPage}
                        />
                    </div>}
                </If>
                <KirokuCardWeb>
                    <KirokuLoader loading={isLoadingTab}>
                        <KirokuNotFound isResults={result.length}>
                            <div>
                                {result.map((customer, index) => {
                                    return <div key={index}>
                                        <div className={'d-flex justify-content-between p-2 align-items-center'}>
                                            <div>
                                                <div>
                                                    <span className={'font-customer-name-detail'}
                                                          style={{ fontSize: 16 }}>
                                                        <Link to={`/customers/${customer.id}`}>{customer.name} </Link>
                                                    </span>

                                                    <span className={'font-customer-name-detail'}
                                                          style={{ fontSize: 12 }}> {t('RecordsBeta.noun')} </span>
                                                </div>
                                                <div className="pt-10"/>
                                                <StatusRecordLabel customer={customer}/>

                                            </div>
                                            <Link
                                                to={`/records/create/customer/${customer.id}/survey/${template.id}`}
                                            >
                                                <KirokuButton
                                                    style={{
                                                        backgroundColor: '#2B91FF',
                                                        color          : '#ffffff',
                                                    }}
                                                    onClick={() => {

                                                    }}
                                                >{t('common.recording')}</KirokuButton>
                                            </Link>

                                        </div>
                                        <hr hidden={result.length - 1 === index} className="m-0"/>
                                    </div>;

                                })}
                            </div>
                        </KirokuNotFound>

                    </KirokuLoader>
                </KirokuCardWeb>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import {
    ComponentWeb, KirokuTable,
    ComponentMobile,
    TBody, THeader,
}                                     from '../../ui';
import { resources }                  from '@kiroku/api';
import KirokuLoader                   from '../../ui/KirokuLoader';
import If                             from '../If';

export default function SurveyPlans({ survey_id, customer_id }) {
    const { t } = useTranslation();
    const [plans, setPlans]                                          = useState([]);
    const { search, loading }                                        = resources.useSearch('Plan');

    const lastPlansCreated = (plans) => {
        return plans.slice(-1)[0];
    }

    useEffect(() => {
        if (survey_id) {
            search({
                type    : 'survey',
                surveyId: survey_id,
                customer_id: customer_id
            }).then((res) => {
                setPlans(res.result)
            });
        }
    }, [search, survey_id, customer_id]);

    const plan = lastPlansCreated(plans);

    return (
        <KirokuLoader loading={loading}>
            <If statement={plans.length}>
                {() => <div className="survey fix-center pb-3">
                    <div style={ { width: '100%' } }>
                        <div className='card'>
                            <div className={ 'header-card-detail' }>
                                <span className="font-name-san">{ t('plans.headerPlanning') }</span>
                            </div>
                            <div className="p-3">
                                <div style={ { wordBreak: 'work-break' } }>
                                    <div className={ 'content-name-question' }> { t('plans.assistancePolicy') } </div>
                                    <div className={ 'content-name-question pt-2' }> { plan.overview || '' }</div>
                                    <div className="pt-3 pb-2">
                                        { t('plans.supportPlans') }:
                                    </div>
                                    <ComponentWeb>
                                        <div style={{
                                            border: 'solid 1px #dfe2e5'
                                        }}>
                                            <KirokuTable>
                                                <THeader>
                                                    <tr>
                                                        <th style={ { width: 100 } }>{ t('plans.priority') }</th>
                                                        <th style={ { minWidth: 180 } }>{ t('plans.objective') }</th>
                                                        <th style={ { minWidth: 180 } }>{ t('plans.content') }</th>
                                                        <th style={ { minWidth: 180 } }>{ t('plans.achievementTime') }</th>
                                                    </tr>
                                                </THeader>
                                                <TBody>
                                                    { plan.supports.map((support, index) => <tr key={ index }>
                                                        <td>
                                                            <div
                                                                className={ 'd-flex justify-content-center align-items-center' }>
                                                    <span
                                                        className="ellipse d-flex justify-content-center align-items-center">
                                                        { support.priority }
                                                    </span>
                                                            </div>
                                                        </td>
                                                        <td>{ support.target }</td>
                                                        <td>{ support.description }</td>
                                                        <td>{ support.term }{t('plans.monthLater')} </td>
                                                    </tr>) }
                                                </TBody>
                                            </KirokuTable>
                                        </div>
                                    </ComponentWeb>
                                    <ComponentMobile>
                                        { plan.supports.map((support, index) => <div key={ index }>
                                            <div
                                                className="p-3 d-flex"
                                                style={ {
                                                    border: '1px solid #dfe2e5',
                                                    background: index % 2 === 0 ? 'rgba(227, 227, 227, 0.2)' : 'none'
                                                } }
                                            >
                                                <div>
                                                    <div className="d-flex">
                                                        <span className='ellipse'>{ support.priority }</span>
                                                    </div>
                                                    <div className={ 'pt-2' }>
                                                        { support.target }
                                                    </div>
                                                    <div className={ 'planning-list-text pt-2' }> { support.description } </div>
                                                    <div
                                                        className={ 'planning-list-text pt-2' }> { t('plans.achievementTime') } : { support.term }{t('plans.monthLater')}</div>
                                                </div>
                                            </div>
                                        </div>) }
                                    </ComponentMobile>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </If>

        </KirokuLoader>
    );
}

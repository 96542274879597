import React, { useEffect, useState } from 'react';
import { KirokuInputSearchOnBlur }    from '../../ui';
import { useTranslation }             from 'react-i18next';

export default function GenericCondition({ searchDeleted, condition }) {
    const { t }                        = useTranslation();
    const [keyword, setKeyword]        = useState('');
    const handleChangeKeyword          = (event) => {
        setKeyword(event.target.value);
    };

    const handleSearchKeyWord = (event) => {
        const keywordsSearch = {
            surveys  : 'title',
            customers: 'name',
            users    : 'name',
        };
        searchDeleted({
            ...condition,
            page                                : 1,
            [ keywordsSearch[ condition.type ] ]: keyword,
        });
    };

    useEffect(() => {
        setKeyword(condition.type === 'surveys' ? condition.title : condition.name);
    }, [condition.type, condition.name, condition.title]);

    return (
        <div className={'font-size-condition'}>
            <KirokuInputSearchOnBlur
                value={keyword}
                onBlur={() => {
                }}
                onSubmit={handleSearchKeyWord}
                placeholder={t('DeletedList.searchByName')}
                onChange={handleChangeKeyword}
                style={{ height: 36}}
            />
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { resources }                  from '@kiroku/api';
import useAttachTemplate
                                      from './makeTemplate/useAttachTemplate';
import {
    KirokuCheckboxBorder,
    KirokuInputSearch, KirokuLoader,
    KirokuTabNoResult,
} from '../../../../ui';
import { useTranslation }             from 'react-i18next';
import lodash                         from 'lodash';

function NotFoundSurvey() {
    return (
        <KirokuTabNoResult messageFirst={ 'survey' }>
            <img
                className='pt-2 pb-2'
                src={ `/assets/img/not-template.svg` }
                alt="No template"
            />
        </KirokuTabNoResult>
    );
}

export default function TemplateUnAttached(props) {
    const { show }                            = props;
    const {
              getTemplates, templateUnAttached,
              handleChangeKeywordUnAttached, keywords,
              handleCheckTemplateUnAttached,
              handleCheckAllTemplatesUnattached,
          }                                   = useAttachTemplate();
    const { search, cancelAPISearch } = resources.useSearch('Template');
    const [loadingAPI, setLoadingAPI]         = useState(false);
    const { t }                               = useTranslation();

    useEffect(() => {
        if (show) {
            setLoadingAPI(true);
            search({ type: 'all' }).then((res) => {
                getTemplates(res.result);
                setLoadingAPI(false);
            });
        }
        return () => {
            cancelAPISearch();
        };

    }, [search, show, cancelAPISearch, getTemplates]);

    return (
        <div hidden={ !show }>
            <div className={ 'pt-3' }>
                <KirokuInputSearch
                    onChange={ (event) => {
                        handleChangeKeywordUnAttached(event.target.value);
                    } }
                    value={ keywords }
                    placeholder={ t('CustomerEditor.EnterNameSurvey') }/>
            </div>
            <div className={ 'pt-3' }>
                <div hidden={ !templateUnAttached.length }>
                    <div className="d-flex justify-content-between data-in-modal align-items-center p-2">
                        <div className={ 'font-text-template-name' }>
                            { t('common.surveyTemplate') }
                        </div>
                        <div className='text-primary' style={ { cursor: 'pointer' } }>
                                <span
                                    hidden={ lodash.findIndex(templateUnAttached, survey => !survey.isChecked) === -1 }
                                    onClick={ () => handleCheckAllTemplatesUnattached() }
                                    className={ 'font-size-16' }>{ t('common.SelectAll') }</span>
                            <span
                                hidden={ lodash.findIndex(templateUnAttached, survey => !survey.isChecked) !== -1 }
                                onClick={ () => handleCheckAllTemplatesUnattached() }
                                className={ 'font-size-16' }> { t('common.UnSelect') }</span>
                        </div>
                    </div>
                </div>
                <div style={ {
                        overflow : 'scroll',
                        minHeight: 'calc(100vh - 450px)',
                        maxHeight: 'calc(100vh - 450px)',
                        height   : 'auto',
                    } }>
                        <KirokuLoader loading={ loadingAPI }>
                            {templateUnAttached.length ?

                            <section className={ `${ templateUnAttached.length ? 'scroll-data-template' : '' }` }>
                                { templateUnAttached.map((survey, index) => (
                                    <div key={ index }>
                                        <KirokuCheckboxBorder
                                            checked={ !!survey.isChecked }
                                            onClick={ () => handleCheckTemplateUnAttached(index) }
                                            onChange={ () => handleCheckTemplateUnAttached(index) }
                                            content={ survey.title }
                                        />
                                    </div>
                                )) }
                            </section> : <div style={ {
                                    minHeight: 'calc(100vh - 396px)',
                                    maxHeight: 'calc(100vh - 396px)',
                                    height   : 'auto',
                                } }>
                                    <NotFoundSurvey/>
                                </div>
                            }
                        </KirokuLoader>
                    </div>
            </div>
        </div>
    );
}

import { KirokuDropdownResetLabelSelected, KirokuInput } from '../../../ui';
import React, { useState }                               from 'react';
import { useTranslation }                                from 'react-i18next';
import useDropDownData                                   from '../../useDropDownData';
import { authentication }                                from '@kiroku/api';

export default function InfoBasicUser({ resultUser, updateInfoUser }) {
    const { t }    = useTranslation();
    const { user } = authentication.useAuthenticatedContext();

    const {
              selectRole,
              workingForm,
          }                             = useDropDownData();
    const [profileData, setProfileData] = useState({
        title        : resultUser.title,
        name         : resultUser.name,
        role         : resultUser.role,
        working_form : resultUser.working_form,
        avatar       : resultUser.avatar,
    });

    const handleChangeInput = event => {
        setProfileData({
            ...profileData,
            [ event.target.name ]: event.target.value,
        });
    };

    const selectWorkingForm = (event) => {
        setProfileData({
            ...profileData,
            working_form: event.kirokuSelected.value,
        });
        updateInfoUser({
            name: 'working_form',
            value: event.kirokuSelected.value
        })
    };

    const roleChoose = (event) => {
        setProfileData({
            ...profileData,
            role: event.kirokuSelected.value,
        });

        updateInfoUser({
            name: 'role',
            value: event.kirokuSelected.value
        })
    };


    return (<div className='row'>
        <div className='col-lg-6'>
            <div className='pb-15px'>
                <span className='customer-info f-bold'>{t('common.Name')}:</span>
                <div className={'pt-10'}>
                    <KirokuInput
                        name='name'
                        onChange={handleChangeInput}
                        onBlur={(event) => {
                            updateInfoUser({
                                name: event.target.name,
                                value: event.target.value
                            })
                        }}
                        value={profileData.name}
                        style={{ height: 36}}
                    />
                    <p hidden={profileData.name}
                       style={{
                           color   : 'red',
                           fontSize: '13px',
                       }}>
                        {t('errors.nameMustNotNull')}
                    </p>
                </div>
            </div>
            <div className='pb-15px'>
                <span className='customer-info f-bold'>{t('staff.title')}:</span>
                <div className={ 'pt-10' }>
                <span className='personal-info'>
                    <KirokuInput
                        name='title'
                        onChange={ handleChangeInput }
                        onBlur={ (event) => {
                            updateInfoUser({
                                name : event.target.name,
                                value: event.target.value,
                            });
                        } }
                        style={{ height: 36}}
                        value={ profileData.title }/>
                    </span>
                </div>
            </div>
        </div>
        <div className='col-lg-6'>
            <div className='pb-15px'>
                <span className='customer-info f-bold'>{t('common.WorkingForm')}:</span>
                <div className="pt-10">
                    <KirokuDropdownResetLabelSelected
                        title={profileData[ 'working_form' ] ? t(`common.${profileData[ 'working_form' ]}`) : t(`common.WorkingForm`)}
                        items={workingForm}
                        onSelect={selectWorkingForm}
                    />
                </div>
            </div>
            <div className='pb-15px'
                 hidden={(user.id === resultUser.id) && (user.role !== 'admin')}>
                <span className='customer-info f-bold'>{t('common.Role')}:</span>
                <div className="pt-10">
                    <KirokuDropdownResetLabelSelected
                        title={profileData.role ? t(`common.${profileData.role}`) : t(`common.Role`)}
                        items={selectRole}
                        onSelect={roleChoose}
                    />
                </div>
            </div>
        </div>
    </div>);
}

import React, { useEffect, useState } from 'react';
import PropTypes                      from 'prop-types';
import { useTranslation }  from 'react-i18next';
import moment              from 'moment';

export default function KirokuDateMonthSelect(props) {
    const {
              numberMonthSelected = 12, onSelectMonth = (monthSelected) => {},
              monthSelectedValue
          } = props;

    const { t }                             = useTranslation();
    const [monthSelected, setMonthSelected] = useState(monthSelectedValue ? moment(monthSelectedValue): moment());

    useEffect(() => {
        if(monthSelectedValue) {
            setMonthSelected(moment.unix(parseInt(monthSelectedValue)));
        } else {
            setMonthSelected(moment());
        }
    }, [monthSelectedValue])

    const monthSelection = () => {
        let monthDisplay = [{
            monthLabel: t('common.January'),
            month     : 0,
        }, {
            monthLabel: t('common.February'),
            month     : 1,
        }, {
            monthLabel: t('common.March'),
            month     : 2,
        }, {
            monthLabel: t('common.April'),
            month     : 3,
        }, {
            monthLabel: t('common.May'),
            month     : 4,
        }, {
            monthLabel: t('common.June'),
            month     : 5,
        }, {
            monthLabel: t('common.July'),
            month     : 6,
        }, {
            monthLabel: t('common.August'),
            month     : 7,
        }, {
            monthLabel: t('common.September'),
            month     : 8,
        }, {
            monthLabel: t('common.October'),
            month     : 9,
        }, {
            monthLabel: t('common.November'),
            month     : 10,
        }, {
            monthLabel: t('common.December'),
            month     : 11,
        }];

        return monthDisplay.slice(0, numberMonthSelected);
    };

    const setMonthYear = ({ month, year }) => {
        const yearNumber  = typeof year === 'number' ? year : monthSelected.year();
        const monthNumber = typeof month === 'number' ? month : monthSelected.month();
        setMonthSelected(moment().set({ 'year': yearNumber, 'month': monthNumber }));
        onSelectMonth(moment().set({ 'year': yearNumber, 'month': monthNumber }));
    };

    return (
        <div className={'d-flex align-items-center'}>
            <div
                className={'pr-2 cursor-pointer'}
                style={{ paddingBottom: 5 }}
                onClick={() => {
                    setMonthYear({ year: monthSelected.year() - 1 });
                }}
            >
                <img src={'/assets/img/left-arrow.svg'} alt="left-arrow.svg"/>
            </div>
            <div className={'d-flex min-width-max-content year pr-2'}
                 dangerouslySetInnerHTML={{ __html: t('common.year', { year: monthSelected.year() }) }}
            />
            <div className={'month-zone'}>
                {monthSelection().map((month, i) => {
                    return (
                        <div key={i}>
                            <span
                                dangerouslySetInnerHTML={{ __html: month.monthLabel }}
                                className={`month ${monthSelected.month() === month.month ? 'active' : ''}`}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setMonthYear({ month: month.month });
                                }}
                            >
                            </span>
                        </div>
                    );
                })}
            </div>
            <div
                className={'pr-2 cursor-pointer'}
                style={{ transform: 'rotate(180deg)' }}
                onClick={() => {
                    setMonthYear({ year: monthSelected.year() + 1 });
                }}
            >
                <img src={'/assets/img/left-arrow.svg'} alt="left-arrow.svg"/>
            </div>
        </div>
    );
}
KirokuDateMonthSelect.propTypes = {
    numberMonthSelected: PropTypes.number,
};

import {resources} from "@kiroku/api";
import moment      from "moment";

export default function useSearchCustomer() {
    const {
        result, search,
        pagination,
        cancelAPISearch,
        condition
    } = resources.useSearch('Customer', {
        type        : 'monitor_term',
        page        : 1,
        status      : 'active',
        monitor_term: moment().startOf('month').startOf('day').unix(),
    });


    return {
        result, search,
        paginationCustomer: pagination,
        cancelAPISearch, conditionCustomer: condition
    }
}
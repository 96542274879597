import React  from 'react';
import Survey from './Survey';

const SurveyMemo = React.memo(
    props => {
        return <Survey {...props}/>
    },
    (prevProps, nextProps) => {
        return prevProps.infoLocking
            && (prevProps.infoLocking.user_id === nextProps.infoLocking.user_id)
            && prevProps.canIsFixIt === nextProps.canIsFixIt
            && prevProps.isLoadingAPI === nextProps.isLoadingAPI;
    }
);

export default SurveyMemo;

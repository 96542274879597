import React, { useContext, useReducer } from 'react';
import DeletedReducer                    from './deletedReducer';
import Surveys                           from './Survey/Surveys';
import GenericCondition                  from './GenericCondition';
import RecordsCondition                  from './Record/RecordsCondition';
import Records                           from './Record/Records';
import Customers                         from './Customer/Customers';
import Users                             from './User/Users';
export const DeletedContext = React.createContext(null);

export function useDeletedContext() {
    return useContext(DeletedContext);
}

const ScreensComponent = {
    surveys: {
        table    : Surveys,
        condition: GenericCondition,
        name     : 'surveys'
    },
    records        : {
        table    : Records,
        condition: RecordsCondition,
        name     : 'records'
    },
    customers: {
        table    : Customers,
        condition: GenericCondition,
        name     : 'customers'
    },
    users: {
        table    : Users,
        condition: GenericCondition,
        name     : 'users'
    },
};



export function DeletedProvider({ children, result, pagination }) {
    const initial = {
        currentScreen: ScreensComponent['surveys'],
        sort_by: 'delete_at', //create_at
        order_by: 'DESC', //ASC
        name: '',
        title: '',
        result: result,
        pagination,
        order_by_type: {
            delete_at: 'DESC',
            create_at: 'DESC'
        }
    };

    const [deletedState, dispatch] = useReducer(DeletedReducer, initial, () => initial);

    return <DeletedContext.Provider value={{ deletedState, dispatch, ScreensComponent } }>
        {children}
    </DeletedContext.Provider>
}

import routersConfig      from '../Router/slideBar';
import { NavLink }        from 'react-router-dom';
import Tooltip            from 'react-tooltip-lite';
import React              from 'react';
import useActionSideBar   from './useActionSideBar';
import { withUserAgent }  from 'react-useragent';
import { useTranslation } from 'react-i18next';
import { authentication } from '@kiroku/api';
import { ComponentWeb }   from '../../ui';

function NavbarItem({ router, ua, isOpenMenuSideBar, setPageLink, changeIsOpenMenu }) {

    const { t }    = useTranslation();
    const { user } = authentication.useAuthenticatedContext();

    return (
        <NavLink to={ setPageLink(router.pathUrl) }
                 className="menu-item"
                 hidden={!router.permissions.includes(user.role)}
                 onClick={ () => {
                     if (ua.mobile) {
                         changeIsOpenMenu();
                     }
                     localStorage.removeItem('conditionRecords');
                 } }
        >
            <Tooltip content={ t(`Layout.${ router.pageName }`) }
                     className="tooltip-target"
                     direction='right'
                     useHover={ !ua.mobile && !isOpenMenuSideBar }
            >
                <div className="menu-link">
                    <div className="sidebar-align-icon">
                        { router.icon && <i className={ router.icon } style={{width: 23, height: 23}}/> }
                        { router.iconImg && <div
                            style={ {
                                width          : 21, height: 17,
                                minWidth       : 21,
                                backgroundImage: `url(/assets/img/${ router.iconImg })`,
                                backgroundSize : 'cover',
                            } }/> }
                    </div>
                    <span className="title-menu">{ t(`Layout.${ router.pageName }`) }</span>
                </div>
            </Tooltip>
        </NavLink>
    );
}

const SideBar = (props) => {
    const { t }                                 = useTranslation();
    const [isOpenMenuSideBar, changeIsOpenMenu] = useActionSideBar();
    const { user }                              = authentication.useAuthenticatedContext();

    const setPageLink = (pathUrl) => {
        if (pathUrl === '/customers' && user) {
            const paramURL = '/customers?page=1&status=active&type=condition&order_by=ASC'
            if(user.role === 'admin') {
                return paramURL;
            }
            return paramURL + `&users=${ user.id }`;
        }
        return pathUrl;
    };

    return (
        <div className={ `sidenav ${ isOpenMenuSideBar ? 'open-slide' : 'close-slide' }` }>
            <div className="header-slidenav d-flex justify-content-between pr-3">
                <div style={ {
                    height         : 30,
                    width          : 113,
                    background     : 'no-repeat',
                    backgroundImage: `url(/assets/img/logo-kiroku.svg)`,
                } }/>
                <div className='icon-tooltip-sidebar'>
                    <i className="fa fa-bars" onClick={ () => {
                        localStorage.removeItem('conditionRecords');
                        changeIsOpenMenu();
                    } }/>
                </div>
            </div>

            <nav className="slider-navigation">
                <ul className="menu">
                    { routersConfig.map((router, index) => {
                        if (router.menuLink) {
                            return <li key={ index }
                                       className="menu-category">{ t(`Layout.${ router.menuLink }`) }</li>;
                        }
                        if (router['isWeb']) {
                            return <ComponentWeb key={ index }>
                                <li>
                                    <NavbarItem { ...props } router={ router } setPageLink={ setPageLink }
                                                isOpenMenuSideBar={ isOpenMenuSideBar }
                                                changeIsOpenMenu={ changeIsOpenMenu }/>
                                </li>
                            </ComponentWeb>;
                        }
                        return (
                            <li key={ index }>
                                <NavbarItem { ...props } router={ router } setPageLink={ setPageLink }
                                            isOpenMenuSideBar={ isOpenMenuSideBar }
                                            changeIsOpenMenu={ changeIsOpenMenu }/>
                            </li>
                        );
                    }) }
                </ul>
            </nav>

        </div>
    );
};
export default withUserAgent(SideBar);

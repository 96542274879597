import React               from 'react';
import KirokuDropdownClose from '../../../ui/KirokuDropdownClose';
import { useTranslation }  from 'react-i18next';

export default function BulkActionSetRoleUser(props) {
    const { t }        = useTranslation();
    const { location } = props;

    const bulkActionItems = [
        {
            value    : 'leader',
            component: (<div>{ t('Team.SetLeader') }</div>),
            label    : t('Team.SetLeader'),
        },
        {
            value    : 'staff',
            component: (<div>{ t('Team.SetStaff') }</div>),
            label    : t('Team.SetStaff'),
        },
        // {
        //     value    : 'assign',
        //     component: (<div>{ t('common.AssignToTeam') }</div>),
        //     label    : t('common.AssignToTeam'),
        // },
    ];

    return (
        <KirokuDropdownClose
            { ...props }
            items={ bulkActionItems }
            location={ location || 'bot' }
        />

    );
}

export default {
    defaultLanguage: 'ja',
    KNOWN_ERRORS : [
        'KIROKU_BAD_REQUEST',
        'KIROKU_NOT_FOUND',
        'KIROKU_UNAUTH_INCORRECT_USERNAME',
        'KIROKU_UNAUTH_INCORRECT_PASSWORD',
        'KIROKU_UNAUTH_INCORRECT_OTP_FAIL',
        'KIROKU_UNPROCESSABLE_ENTITY',
        'KIROKU_FORBIDDEN',
        // ... todo more known error here
    ],
    // languages: [
    //     {
    //         name : 'Japanese',
    //         icon : 'jp',
    //         key  : 'ja',
    //         class: 'flag-icon ja ',
    //     },
    //     {
    //         name : 'English',
    //         icon : 'gb',
    //         key  : 'en',
    //         class: 'flag-icon en ',
    //     },
    // ],

    languages : {
        'ja' : '日本語',
        'en' : 'English'
    },

    kirokuAPI: {
        version: 2,
        url    : process.env.REACT_APP_DOMAIN_DEV_V2,
    },

    formatDate      : 'YYYY/MM/DD',
    formatDateTh    : 'YYYY/M/D(ddd)',
    formatDateYMD   : 'YYYY年M月D日(ddd)',
    formatDateTime  : 'YYYY年M月D日(ddd) H:mm',
    formatDateTimeTh: 'YYYY/M/D(ddd) HH:mm',
    formatTime      : 'HH:mm',
    lockCheckTime   : 10000,
    sizeTagOnWed    : 11,
    sizeTagOnMobile : 5,
    allowFileType   : ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'PNG', 'JPG', 'JPEG', 'GIF', 'PDF'],
    acceptFileTypes : [
        'text/*',
        'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/*',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    contentfulConfig: {
        space      : process.env.REACT_APP_SPACE_ID,
        accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    },
    questionsTypeAnalyst: ['MultiChoice', 'YesNo', 'Single', 'Number', 'Time', 'OnlyDate', 'Media'],
    configWithMobile: 768,
    sizeFileUploadImg: 10485760,
    sizeFileUploadAvatar: 5057723,
    sentry: {
        // dsn        : 'https://9643cb0ee60b4edeb79a778ee48ab522@o401485.ingest.sentry.io/5261126',
        // enabled    : true,
        dsn        : process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENVIRONMENT,
        enabled    : JSON.parse(process.env.REACT_APP_SENTRY_ENABLED),
    },

    mixpanel            : {
        // token  : 'dcc445643b3a2f3bf10b7c3d5d3dc760',
        // enabled: true,
        token  : process.env.REACT_APP_MIXPANEL_TOKEN,
        enabled: JSON.parse(process.env.REACT_APP_MIXPANEL_ENABLED),
    },
    maxAmountQuestion: 100,
    outDatePassword: 6,
    orgUsingElasticSearch: [1,2,3,15,17,18,24,39,43,44,46,53]

};

import lodash          from 'lodash';
import moment          from 'moment';

const concatActivitiesLog = (newLog, oldLog) => {
    const newLogCover = [...newLog];
    const oldLogCover = [...oldLog];
    if (lodash.head(newLogCover).date === lodash.last(oldLogCover).date) {
        const date = lodash.head(newLogCover).date;
        const logs = lodash.head(newLogCover).logs
            .concat(lodash.last(oldLogCover).logs)
            .sort((a, b) => parseFloat(b.create_at) - parseFloat(a.create_at));
        oldLogCover.splice(oldLogCover.length - 1, 1);
        newLogCover.splice(0, 1);
        oldLogCover.push({ date, logs });

        return oldLogCover.concat(newLogCover);
    }
    return oldLogCover.concat(newLogCover);
};

const getGroupLogByDay = (resultLogs) => {

    const groups = resultLogs.reduce((groups, logs) => {
        const date = moment.unix(logs.create_at).startOf('day').unix();
        if (!groups[ date ]) {
            groups[ date ] = [];
        }
        groups[ date ].push(logs);
        return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
        return {
            date,
            logs: groups[ date ]
        };
    });
    return groupArrays.sort((a, b) => parseFloat(b.date) - parseFloat(a.date));
};

export {
    concatActivitiesLog,
    getGroupLogByDay
}

import React              from 'react';
import { useTranslation } from 'react-i18next';
import Plan               from './Plan';
import LongTerm           from './LongTerm';
import ShortTerm          from './ShortTerm';
import './PlanInfo.css';

export default function PlanInfo({ plan }) {
    const { t } = useTranslation();

    return (
        <div className="bg-white">
            <div className={ 'planning-info' }
                 style={ { border: 'solid 1px #dfe2e5' } }
            >
                <Plan plan={ plan } t={ t }/>
                <LongTerm plan={ plan } t={ t }/>
                <ShortTerm plan={ plan } t={ t }/>
            </div>
        </div>
    );
}
import React                             from 'react';
import { ComponentMobile, ComponentWeb } from '../../ui';
import { useTranslation }                from 'react-i18next';
import ActionDropdown                    from './ActionDropdown';

export default function ConditionSearchActionLog({ search, action }) {
    const { t } = useTranslation();

    return (<>
        <ComponentWeb>
            <div className="d-flex" style={{ flexWrap: 'wrap' }}>
                <span
                    className={`action-log-entity cursor-pointer ${!action ? 'active-tab' : ''}`}
                    onClick={() => {
                        search({ action: '', page: 1 });
                    }}
                >
                        <img src={`/assets/img/All.svg`} style={{ minWidth: 'max-content' }}
                             alt={'All.svg '}/>&nbsp;{t('common.all')}
                </span>
                <span
                    className={`action-log-entity cursor-pointer ${action === 'update' ? 'active-tab' : ''}`}
                    onClick={() => {
                        search({ action: 'update', page: 1 });
                    }}
                >
                    <img src={`/assets/img/file-update.svg`}
                         alt={'file-update.svg '}/>&nbsp;{t('common.update')}
                </span>
                <span
                    className={`action-log-entity cursor-pointer ${action === 'create' ? 'active-tab' : ''}`}
                    onClick={() => {
                        search({ action: 'create', page: 1 });
                    }}
                >
                    <img src={`/assets/img/file-add.svg`}
                         alt={'file-add.svg '}/>&nbsp;{t('common.create')}
                </span>
                <span
                    className={`action-log-entity cursor-pointer ${action === 'delete' ? 'active-tab' : ''}`}
                    onClick={() => {
                        search({ action: 'delete', page: 1 });
                    }}
                >
                    <img src={`/assets/img/file-delete.svg`}
                         alt={'file-delete.svg '}/>&nbsp;{t('common.delete')}
                </span>
                <span
                    className={`action-log-entity cursor-pointer ${action === 'view' ? 'active-tab' : ''}`}
                    onClick={() => {
                        search({ action: 'view', page: 1 });
                    }}
                >
                    <img src={`/assets/img/file-view.svg`}
                         alt={'file-view.svg '}/>&nbsp;{t('common.view')}
                </span>
                <span
                    className={`action-log-entity cursor-pointer ${action === 'comment' ? 'active-tab' : ''}`}
                    onClick={() => {
                        search({ action: 'comment', page: 1 });
                    }}
                >
                    <img src={`/assets/img/file-text.svg`}
                         alt={'file-text.svg '}/>&nbsp;{t('common.comment')}
                </span>
            </div>
        </ComponentWeb>
        <ComponentMobile>
            <ActionDropdown search={search} status={action}/>
        </ComponentMobile>
    </>);
}

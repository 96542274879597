export const CHANGE_CONDITION_REPORT = 'CHANGE_CONDITION_REPORT';

export default function (state, action) {
    switch (action.type) {
        case CHANGE_CONDITION_REPORT:
            return action.value;
        default:
            return state;
    }
};

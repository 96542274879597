import { useTranslation }                                                               from 'react-i18next';
import { KirokuButton, KirokuLoading, KirokuModal, KirokuModalBody, KirokuModalFooter } from '../../ui';
import React                                                                            from 'react';

export default function ModalNotificationValidateUploadAvatar({ flagValidatedUpload, closeModal }) {

    const { t } = useTranslation();

    return (
        <KirokuModal show={flagValidatedUpload} onCloseModal={closeModal}>
            <KirokuModalBody>
                <div style={{ textAlign: 'center' }} className={'p-2'}>

                    {t('message.NotificationUploadAvatar')}
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={closeModal} color={'white'}>
                    {t('createRecord.Yes')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}
export const ComponentLoading = () => {
    return (
        <div style={{height: 120}}>
            <KirokuLoading/>
        </div>
    )
}

import React              from 'react';
import { useTranslation } from 'react-i18next';
import { Link }           from 'react-router-dom';
import If                 from '../../If';
import { ServicedAt }     from '../../../ui';


export default function AboutSurvey(props) {
    const { t } = useTranslation();
    const {
              survey, user, customer,
              detailRecord, hidden, isNotEditServiced,
              isUseServicedAt, handleChangedServicedAt,
              infoLocking, canIsFixIt
          }     = props;

    return (
        <div hidden={hidden}>
            <div id='about-survey'>
                <div className='info-question' id='create-record-fix'>
                    <span className="question-number">{survey.title} </span>
                    <If statement={customer}>
                        {() =>
                            <div className='content-heading-question d-flex pt-10px'>
                                <span className='title-record-info font-size-16'>{t('createRecord.Customer')}&nbsp;</span>
                                <If statement={detailRecord || !customer.delete_at} otherwise={customer.name}>
                                    {() => <Link to={`/customers/${customer.id}`}
                                                 className="font-size-16"
                                    >
                                        {customer.name}
                                    </Link>
                                    }
                                </If>
                            </div>
                        }

                    </If>
                    <div className='content-heading-question d-flex pt-10px'>
                        <span className='title-record-info font-size-16'>{t('createRecord.Staff')}&nbsp;</span>
                        <If statement={user}>
                            {() => <If statement={detailRecord && !user.delete_at} otherwise={<div className="font-size-16">{ user.name }</div>}>
                                {() => <Link to={`/users/${user.id}`} className="font-size-16">{user.name}</Link>}
                            </If>
                            }
                        </If>
                    </div>
                    <If statement={isUseServicedAt}>
                        {() =>
                            <div className={'pt-10px'}>
                                <ServicedAt
                                    defaultServiced={
                                        {
                                            serviced_at: survey.serviced_at,
                                            has_time   : survey.has_time,
                                        }
                                    }
                                    isNotEditServiced={isNotEditServiced}
                                    handleChangedServicedAt={handleChangedServicedAt}
                                />
                            </div>
                        }
                    </If>
                    <If statement={infoLocking && !canIsFixIt}>
                        {() => <div className='content-name-question d-flex pt-10px' style={{ flexWrap: 'wrap' }}>
                            <span style={{ wordBreak: 'keep-all', fontSize: 16 }}
                                  className='title-record-info'
                            >
                                {t('common.editing', { user: infoLocking.user_name })} &nbsp;
                            </span>
                            <div id="wave">
                                <span className="dot"/>
                                <span className="dot"/>
                                <span className="dot"/>
                            </div>
                        </div>}
                    </If>

                </div>
            </div>
        </div>
    );

}

import { resources }   from '@kiroku/api';
import { useCallback } from 'react';

export function useSearchCustomerBeTaggedUser(userId) {
    const { search } = resources.useSearch('Customer', { type: 'all', userId });
    const searchCustomers = useCallback(() => {
        return search({ type: 'all', users: userId });
    }, [search, userId])


    return { searchCustomers };
}

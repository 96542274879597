import { useSurveyContext } from './SurveyContext';
import { useCallback }      from 'react';
import {
    COPY_QUESTION, CREATE_QUESTION, DELETE_QUESTION, DRAG_QUESTION_SURVEY, UPDATE_QUESTION,
}                           from './SurveyReducer';
import useNotification      from '../../Layout/useNotification';
import { useTranslation }   from 'react-i18next';

export default function useQuestions() {
    const { infoSurvey, dispatch } = useSurveyContext();
    const { questions }            = infoSurvey;
    const { notification }         = useNotification();
    const { t }                    = useTranslation();

    const create = useCallback((question) => {
        dispatch({
            type    : CREATE_QUESTION,
            question: {
                ...question,
                index: questions.length,
            },
        });
    }, [dispatch, questions]);

    const update = useCallback((question) => {
        dispatch({
            type: UPDATE_QUESTION,
            question,
        });
    }, [dispatch]);

    const copy = useCallback((question) => {
        dispatch({
            type: COPY_QUESTION,
            question,
        });
        notification(t('common.copyQuestionSuccess'), 'success');
    }, [dispatch, notification, t]);

    const delQuestion = useCallback((index) => {
        dispatch({
            type: DELETE_QUESTION,
            index,
        });
    }, [dispatch]);

    const dragDropQuestion = useCallback(((questions, currentIndex, nextIndex) => {
        dispatch({
            type: DRAG_QUESTION_SURVEY,
            questions,
            currentIndex,
            nextIndex

        })
    }),[dispatch]);
    return {
        questions,
        create,
        update,
        copy, delQuestion,
        dragDropQuestion
    };
}

import { useCallback, useContext }                                 from 'react';
import { ANSWER_QUESTION, NAVIGATE_TO_QUESTION, CURRENT_QUESTION } from './RecordingReducer';
import RecordingContext                                            from './RecordingContext';


export default function useAsQuestion(question) {

    const { dispatch } = useContext(RecordingContext);

    const doAnswer = useCallback((answer, next) => {

        dispatch({
            type    : ANSWER_QUESTION,
            question: question.index,
            answer, next, dispatch,
        });
    }, [dispatch, question]);

    const navigate = useCallback((nextQuestion) => {
        dispatch({
            type    : NAVIGATE_TO_QUESTION,
            nextQuestion,
            question: question.index,
        });

    }, [dispatch, question]);

    const answering = useCallback(() => {
        dispatch({
            type: CURRENT_QUESTION,
            question,
        });
    }, [dispatch, question]);

    return { doAnswer, navigate, answering };
}



import React                   from 'react';
import {
    KirokuCardWeb,
    KirokuCardBodyWeb,
}                              from '../../../../ui';
import { useHeadingQuestion }  from '../../useSurvey';
import QuestionCardTitle       from './QuestionCardTitle';
import { ButtonNext, getNext } from './getNextQuestion';
import { useAsQuestionType }   from '../../../Recording';

export function setDefaultAnswerMessage(question) {
    return {
        ...question,
        answers: 'message',
    };
}

export default function Message({ question }) {

    const { content }  = useHeadingQuestion(question);
    const { doAnswer } = useAsQuestionType(question);

    return (
        <div id='component-question'>
            <div id='web-q' className='pb-3'>
                <KirokuCardWeb>
                    <QuestionCardTitle
                        isDisabled={question.isDisabled}
                        question={question}
                        hasAnswer={false}
                    />
                    <KirokuCardBodyWeb>
                        <span className='content-name-question'>{content}</span>
                        <div className='pt-2'>
                            <ButtonNext
                                question={question}
                                onClick={
                                    () => {
                                        doAnswer(question.answers,
                                            getNext({ ...question, isAnswers: !!question.answers }));
                                    }
                                }
                            />
                        </div>
                    </KirokuCardBodyWeb>
                </KirokuCardWeb>
            </div>
        </div>
    );
}

import React, { useReducer } from 'react';
import CustomerReducer                  from './CustomerReducer';
import CustomerModalContext             from './CustomerModalContext';

export function CustomerProvider({ children, customerDefault = [] }) {

    const intState = {
        customers              : [],
        customersShowInModal   : [],
        selectTab              : 'optionsTab',
        keywords               : '',
        teamSelected           : [],
        customersSelected      : [],
        customersSelectedList  : [],
        keywordSelected        : '',
        customerSearched       : [],
        customerDefault        : customerDefault,
        customerAll            : [],
        customerTaggedToUser   : [],
        customerTab            : 'SEARCH_BY_CUSTOMER_TAG',
        isOpenModal            : false,
        prevCustomerTab        : '',
        showModalSelectCustomer: false,
        historyState           : {
            customerTab: 'SEARCH_BY_CUSTOMER_TAG',
            isOpenModal: false,
        },
    };

    const [customerState, dispatch] = useReducer(CustomerReducer, intState, () => intState);

    return <CustomerModalContext.Provider value={ { customerState, dispatch } }>
        { children }
    </CustomerModalContext.Provider>;
}

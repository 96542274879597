import { KirokuCheckBox, KirokuCheckboxBorder, KirokuInput } from '../../../../ui';
import _                                                     from 'lodash';
import React                           from 'react';
import { useTranslation }              from 'react-i18next';
import { useSelectUsers }              from './useSelectUsers';
import DropdownSelectTeam              from './DropdownSelectTeam';

export default function UserSelectedTab(props) {
    const { t } = useTranslation();
    const {
              userCheck, keywordSelected, handleChangeTeamChecked,
              handleCheckUserSelectedTab, handleCheckAllSelectedTab,
              handleSearchKeywordsSelected,
          }     = useSelectUsers();

    return (
        <div>
            <div className={ 'pt-2' }>
                <DropdownSelectTeam handleChangeTags={ (value) => handleChangeTeamChecked(value) }
                                    teamSearch={props.teams} showModal={props.show}
                                    placeholder={ t('RecordsBeta.refineByTeam') }
                />
            </div>
            <div className={ 'pb-3' } >
                <KirokuInput
                    style={ { height: 36, fontSize: 14 } }
                    placeholder={ t('RecordsBeta.keyword') }
                    value={ keywordSelected }
                    onChange={ (e) => handleSearchKeywordsSelected(e.target.value) }
                />
            </div>
            <div style={{ height: 'calc(100vh - 450px)'}}>
            { userCheck.length ?
                <div className={'background-color'}>
                    <div className={ 'p-3' }
                         style={{ border: 'solid 1px #dfe2e5'}}
                    >
                        <KirokuCheckBox onChange={ handleCheckAllSelectedTab }
                                        checked={ _.findIndex(userCheck, (user) => !user.isChecked) === -1 }
                        />
                    </div>
                    <div
                        style={ {
                             overflow : 'scroll',
                             height   : 'calc(100vh - 500px)',
                         } }>
                        { userCheck.map((user, i) => {
                            return (
                                <div key={ user.id }
                                     onClick={ () => handleCheckUserSelectedTab(i) }
                                >
                                    <div className={ 'cursor-pointer' }>
                                        <KirokuCheckboxBorder content={ <span className={ 'font-text-in-checkbox' }
                                                                        style={ { wordBreak: 'break-word' } }
                                        >{ user.name }</span> }
                                                        onChange={ () => handleCheckUserSelectedTab(i) }
                                                        checked={ !!user.isChecked }
                                                        id={ `${ user.id }-${ i }` }/>
                                    </div>
                                </div>
                            );
                        }) }
                    </div>
                </div> : <div className={ 'background-color d-flex' }
                              style={ { fontSize: '16px', height: 'calc(100vh - 450px)' } }>
                    <div style={ { margin: 'auto' } }>
                        { t('RecordsBeta.noStaffSelected') }
                    </div>
                </div> }
            </div>

        </div>
    );
}

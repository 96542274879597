import React, { useEffect, useRef, useState }                      from 'react';
import { useTranslation }                                          from 'react-i18next';
import useDropDownData                                             from '../../useDropDownData';
import { resources }                                               from '@kiroku/api';
import useNotification                                             from '../../Layout/useNotification';
import { conditionUpload }                                         from '../UserEditor/validateChecker';
import {
    ComponentMobile, ComponentWeb, KirokuButton, KirokuCardWeb, KirokuDropdownClose, KirokuInput, KirokuLoader,
    KirokuMultiSelect, SearchNotFound,
}                                                                  from '../../../ui';
import { InputPassword }                                           from '../UserCreator/UserCreator';
import { useHistory }                                              from 'react-router-dom';
import If                                                          from '../../If';
import DropdownSelectCustomerTag, { useGetCustomerTagByUserId }    from '../UserEditor/DropdownSelectCustomerTag';
import DropdownSelectTeamTag, { useGetTeamTagByUserId }            from '../UserEditor/DropdownSelectTeamTag';
import { useMixpanelTracking }                                     from '../../../Mixpanel/useMixpanelTracking';
import moment                                                      from 'moment';
import ModalNotificationValidateUploadAvatar, { ComponentLoading } from '../ModalNotificationValidateUploadAvatar';

export function InputUserName({ user, onBlur }) {
    const { t }           = useTranslation();
    const [name, setName] = useState(user.name);
    return (
        <KirokuInput
            value={name}
            tabIndex='1'
            name={'name'}
            placeholder={t('common.Name')}
            onChange={e => setName(e.target.value)}
            onBlur={onBlur}
        />
    );
}


export function InputTitle({ user, onBlur }) {
    const { t }             = useTranslation();
    const [title, setTitle] = useState(user.title);
    return (
        <KirokuInput
            tabIndex='5'
            name='title'
            placeholder={t('staff.title')}
            value={title}
            onChange={e => setTitle(e.target.value)}
            onBlur={onBlur}
        />
    );
}


export function WrapperUserDuplicate({ resultUser }) {
    const history                                             = useHistory();
    const { t }                                               = useTranslation();
    const { kindOfQualification, workingForm }                = useDropDownData();
    const [resultUploadAvatar, uploadAvatar, isLoadingAvatar] = resources.useUpload('User');
    const [userAvatar, setUserAvatar]                         = useState(resultUploadAvatar || '');
    const inputElement                                        = useRef(null);
    const [resultCreated, createUser, isLoadingUser]          = resources.useCreate('User');
    const { notification }                                    = useNotification();
    const { teamsBeingTagUser }                               = useGetTeamTagByUserId(resultUser.id);
    const { customersBeingTagUser }                           = useGetCustomerTagByUserId(resultUser.id);
    const { trackingMixpanel }                                = useMixpanelTracking();

    const [teamTags, setTeamTags]         = useState([]);
    const [customerTags, setCustomerTags] = useState([]);

    useEffect(() => {
        setTeamTags(teamsBeingTagUser.map(itm => {
            return {
                id  : itm.id,
                name: itm.name,
            };
        }));
    }, [teamsBeingTagUser]);

    useEffect(() => {
        setCustomerTags(customersBeingTagUser.map(itm => {
            return {
                id  : itm.id,
                name: itm.name,
            };
        }));
    }, [customersBeingTagUser]);

    const [qualification, setQualification] = useState(resultUser.qualification.map(itm => {
        return {
            key  : itm,
            value: t(`common.dropdown.${itm}`),
            label: t(`common.dropdown.${itm}`),
        };
    }));

    const [profile, setUserProfile] = useState({
        name           : resultUser.name,
        username       : '',
        title          : resultUser.title,
        qualification  : resultUser.qualification,
        password       : '',
        confirmPassword: '',
        working_form   : resultUser.working_form,
    });
    const [alertUpload, setAlertUpload] = useState(false);

    const [errorsProfile, setErrorsProfile] = useState({
        name           : false,
        password       : false,
        confirmPassword: false,
        username       : false,
        userLong       : false,
        userIsExit     : false,
    });


    const handleChangeQualification = (options) => {
        setQualification(options);
        setUserProfile({
            ...profile,
            qualification: options.map(itm => itm.key),
        });
    };

    const handleChangeImage = () => {
        if (inputElement.current.files[ 0 ] && conditionUpload(inputElement.current.files[ 0 ])) {
            let image = new FormData();
            image.append('file', inputElement.current.files[ 0 ]);
            image.append('name', inputElement.current.files[ 0 ].name);
            uploadAvatar('public', image).then((res) => {
                setUserAvatar(res.url);
            });
        } else if(inputElement.current.files.length) {
            setAlertUpload(!alertUpload);
        }
    };

    const handleOnChangeProfile = (event) => {
        setUserProfile({
            ...profile,
            [ event.target.name ]: event.target.value,
        });

        setErrorsProfile({
            ...errorsProfile,
            [ event.target.name ]: !event.target.value,
        });
    };


    const handleOnChangeUserName = (event) => {
        setUserProfile({
            ...profile,
            [ event.target.name ]: event.target.value,
        });

        setErrorsProfile({
            ...errorsProfile,
            [ event.target.name ]: !event.target.value,
            userLong             : event.target.value.length && event.target.value.length < 6,
            userIsExit           : false,
        });
    };

    const handleOnChangePassword        = (event) => {
        setUserProfile({
            ...profile,
            [ event.target.name ]: event.target.value,
        });
        setErrorsProfile({
            ...errorsProfile,
            [ event.target.name ]: !event.target.value,
            confirmPassword      : event.target.value !== profile.confirmPassword,
        });

    };
    const handleOnChangeConfirmPassword = (event) => {
        setUserProfile({
            ...profile,
            [ event.target.name ]: event.target.value,
        });
        setErrorsProfile({
            ...errorsProfile,
            confirmPassword: event.target.value !== profile.password,
        });
    };

    const handleSubmitApplicationUser = () => {
        const userDataRequestDuplicate = {
            name                    : profile.name,
            username                : profile.username,
            password                : profile.password,
            confirm_password        : profile.confirmPassword,
            title                   : profile.title,
            working_form            : profile.working_form,
            qualification           : profile.qualification,
            role                    : 'staff',
            avatar                  : userAvatar,
            customers               : customerTags,
            teams                   : teamTags,
            last_updated_password_at: moment().unix(),
        };

        createUser(userDataRequestDuplicate)
            .then((res) => {
                if (res.code === 'KIROKU_CREATED') {
                    notification(t('message.UpdateSuccess'), 'success');
                    history.push('/users/'.concat(res.id));
                }
            })
            .catch((error) => {
                setErrorsProfile({
                    ...errorsProfile,
                    userIsExit: error.code === 'KIROKU_BAD_REQUEST' && error.message === `username [${profile.username}] is existed!`,
                });
            });
        trackingMixpanel('STAFF_DUPLICATED', userDataRequestDuplicate);
    };

    useEffect(() => {

    }, [resultCreated]);

    const handleOnChangeCustomers = (tagSelected) => {
        setCustomerTags(tagSelected);
    };

    const handleOnChangeTeams = (tagSelected) => {
        setTeamTags(tagSelected);
    };

    return (<div>
        <div className='padding-responsive staff-detail'>
            <ModalNotificationValidateUploadAvatar
                flagValidatedUpload={alertUpload}
                closeModal={() => setAlertUpload(!alertUpload)}
            />
            <KirokuCardWeb>
                <div className='pt-3 pb-3 row m-0'>
                    <div className="col-md-8">
                        <div className='row'>
                            <div className="col-md-6">
                                <div className="pb-3">
                                    <label className="mt-1">{t('common.Name')}</label>
                                    <InputUserName
                                        user={resultUser}
                                        onBlur={handleOnChangeProfile}
                                    />
                                    <p hidden={!errorsProfile.name}
                                       style={{
                                           color   : 'red',
                                           fontSize: '13px',
                                           margin  : 0,
                                       }}>
                                        {t('staff.nameStaffMustNotNull')}
                                    </p>
                                </div>
                                <ComponentWeb>
                                    <div className="pb-3">
                                        <label className="mt-1">{t('Login.password')}</label>
                                        <div style={{ position: 'relative' }}>
                                            <InputPassword
                                                tabIndex='3'
                                                name='password'
                                                onBlur={handleOnChangePassword}
                                            />
                                        </div>
                                        <p hidden={!errorsProfile.password}
                                           style={{
                                               color   : 'red',
                                               fontSize: '13px',
                                               margin  : 0,
                                           }}>
                                            {t('errors.userDetail.mustNotNull')}
                                        </p>
                                    </div>
                                </ComponentWeb>
                                <div className="pb-2">
                                    <label className="mt-1">{t('staff.title')}</label>
                                    <InputTitle
                                        user={resultUser}
                                        onBlur={handleOnChangeProfile}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="pb-3">
                                    <label className="mt-1">{t('Login.username')}</label>
                                    <KirokuInput
                                        tabIndex='2'
                                        name='username'
                                        placeholder={t('Login.username')}
                                        onBlur={handleOnChangeUserName}
                                    />
                                    <p hidden={!errorsProfile.username}
                                       style={{
                                           color   : 'red',
                                           fontSize: '13px',
                                           margin  : 0,
                                       }}>
                                        {t('staff.userNameNotNull')}
                                    </p>
                                    <p hidden={!errorsProfile.userIsExit}
                                       style={{
                                           color   : 'red',
                                           fontSize: '13px',
                                           margin  : 0,
                                       }}>
                                        {t('staff.nameExisted')}
                                    </p>
                                    <p hidden={!errorsProfile.userLong}
                                       style={{
                                           color   : 'red',
                                           fontSize: '13px',
                                           margin  : 0,
                                       }}>
                                        {t('staff.validateLength')}
                                    </p>
                                </div>
                                <ComponentMobile>
                                    <div className="pb-3">
                                        <label className="mt-1">{t('Login.password')}</label>
                                        <div style={{ position: 'relative' }}>
                                            <InputPassword
                                                tabIndex='3'
                                                name='password'
                                                onBlur={handleOnChangePassword}
                                            />
                                        </div>
                                        <p hidden={!errorsProfile.password}
                                           style={{
                                               color   : 'red',
                                               fontSize: '13px',
                                               margin  : 0,
                                           }}>
                                            {t('errors.userDetail.mustNotNull')}
                                        </p>
                                    </div>
                                </ComponentMobile>
                                <div className="pb-3">
                                    <label className="mt-1">{t('common.ConfirmNewPassword')}</label>
                                    <div style={{ position: 'relative' }}>
                                        <InputPassword
                                            tabIndex='4'
                                            name='confirmPassword'
                                            onBlur={handleOnChangeConfirmPassword}
                                        />
                                    </div>
                                    <p hidden={!errorsProfile.confirmPassword}
                                       style={{
                                           color   : 'red',
                                           fontSize: '13px',
                                           margin  : 0,
                                       }}>
                                        {t('errors.userDetail.notMatch')}
                                    </p>
                                </div>
                                <div className="pb-3">
                                    <label className="mt-1">{t('common.WorkingForm')}</label>
                                    <KirokuDropdownClose
                                        title={t(`common.${profile.working_form ? profile.working_form : 'WorkingForm'}`)}
                                        items={workingForm}
                                        onSelect={event => {
                                            setUserProfile({
                                                ...profile,
                                                working_form: event.kirokuSelected.value,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="pb-3">
                            <div className="pb-2">
                                <label className="mt-1">{t('common.Qualification')}</label>
                                <KirokuMultiSelect
                                    value={qualification}
                                    onChange={handleChangeQualification}
                                    options={kindOfQualification}
                                />
                            </div>
                        </div>
                        <div className="pt-2">
                            <span className='customer-info f-bold'>{t('common.Customer')}:</span>
                            <br/>
                            <DropdownSelectCustomerTag
                                userId={resultUser.id}
                                handleOnChangeCustomers={handleOnChangeCustomers}
                            />
                        </div>
                        <div className="pt-2">
                            <span className='customer-info f-bold'>{t('common.Team')}:</span>
                            <br/>
                            <DropdownSelectTeamTag
                                userId={resultUser.id}
                                handleOnChangeTeams={handleOnChangeTeams}
                            />
                        </div>
                    </div>
                    <div className='fix-center col-md-4 as'>
                        <div className='hidden-screen-max-768'>

                            <KirokuLoader loading={isLoadingAvatar} ComponentLoading={ComponentLoading}>
                                <div className='staff-detail avatar-user-detail as'
                                     style={{
                                         backgroundImage: `url(${userAvatar || '/assets/img/profile-ic.svg'})`,
                                     }}
                                />
                            </KirokuLoader>
                            <div className='mt-24'>
                                <div className='text-center'>
                                    <KirokuButton color={'success'} onClick={() => inputElement.current.click()}>
                                        {t('CustomerEditor.ChangeAvatar')}
                                    </KirokuButton>
                                    <input type='file' ref={inputElement} hidden
                                           accept="image/jpeg, image/png"
                                           onChange={handleChangeImage}
                                    />
                                </div>
                            </div>
                            <div className='content-avatar text-center pt-3'>
                                <span>{t('Customer.SupportedFile')} </span>
                                <br/>
                                <span>{t('Customer.Maximum')}</span>
                            </div>
                        </div>
                    </div>
                    <ComponentWeb>
                        <div className='pl-3 pt-3 d-flex'>
                            <KirokuButton color='white' onClick={() => history.goBack()}>
                                {t('common.Cancel')}
                            </KirokuButton>
                            <div className='pr-1 pl-1'/>
                            <KirokuButton color='primary'
                                          disabled={
                                              !profile.name || !profile.username ||
                                              !profile.confirmPassword || !profile.password ||
                                              isLoadingUser
                                          }
                                          onClick={handleSubmitApplicationUser}>
                                {t('common.Save')}
                            </KirokuButton>
                        </div>
                    </ComponentWeb>
                </div>
            </KirokuCardWeb>
            <ComponentMobile>
                <div className={'d-flex justify-content-center pt-3'}>
                    <KirokuButton color='white' onClick={() => history.goBack()}>
                        {t('common.Cancel')}
                    </KirokuButton>
                    <div className='pr-1 pl-1'/>
                    <KirokuButton color='primary'
                                  disabled={
                                      !profile.name || !profile.username ||
                                      !profile.confirmPassword || !profile.password ||
                                      isLoadingUser
                                  }
                                  onClick={handleSubmitApplicationUser}>
                        {t('common.Save')}
                    </KirokuButton>
                </div>
                <div style={{ marginBottom: 80 }}/>
            </ComponentMobile>
        </div>
    </div>);
}


export default function UserDuplicate(props) {
    const [resultUser, userDetail, userDetailLoading] = resources.useDetail('User');
    const {t} = useTranslation();

    useEffect(() => {
        userDetail(props.match.params.id).catch(error => console.log(error));
    }, [props.match.params.id, userDetail]);


    return (<KirokuLoader loading={userDetailLoading}>
        <If statement={resultUser}
            otherwise={ <SearchNotFound message={ t('common.UserInvalid') } description={ ' ' }/> }
        >
            {() => <WrapperUserDuplicate
                resultUser={resultUser}
            />}
        </If>
    </KirokuLoader>);
}


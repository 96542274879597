import React, {useEffect, useState} from 'react';
import {
    KirokuCardWeb,
    KirokuTable, TBody, THeader, KirokuTabNoResult,
    KirokuLabel, ComponentMobile, ComponentWeb,
    KirokuCardList, KirokuCard, KirokuPopoverItem,
    KirokuLoader, KirokuLoading
}
    from '../../../ui';
import { resources } from '@kiroku/api';
import { useTranslation }            from 'react-i18next';
import { Link }                      from 'react-router-dom';
import ButtonPermission              from '../../HOC/ButtonPermission';
import { withPermissions }           from '../../HOC';
import If                            from '../../If';
import TogglePopover                 from '../../Templates/QuestionTypes/TogglePopover';
import config from "../../../config";

const ButtonAssignSurvey = withPermissions(ButtonPermission);

function NotFoundSurveyCustomerDetail({ showModal }) {
    const { t } = useTranslation();

    return (
        <div style={ { paddingBottom: 50 } }>
            <KirokuTabNoResult messageFirst={ 'survey' }>
                <img
                    className='pt-2 pb-2'
                    src={ '/assets/img/empty-survey.svg' }
                    alt="error img"/>
            </KirokuTabNoResult>
            <div className='text-center'>
                <ButtonAssignSurvey
                    resource-permission="|customerDetail|button-assignSurvey|"
                    color='success'
                    onClick={ () => {
                        showModal();
                    } }>{ t('Customer.AssignARecordingToCustomer') }
                </ButtonAssignSurvey>
            </div>
        </div>
    );
}


export function SurveyTabMobile(props) {
    const { customer, templateList, loadingAPI } = props;
    const { t }                = useTranslation();

    return <div>
        <KirokuCardList headerLeft={ <span>{ t('common.Surveys') }</span> }
                        headerRight={ <span>{ t('common.Action') }</span> }
        >
            <KirokuLoader loading={loadingAPI}>
                <If statement={ templateList.length }
                    otherwise={ <NotFoundSurveyCustomerDetail { ...props }/> }
                >
                    { () => templateList.map((survey, index) => {
                        return (
                            <KirokuCard key={ index }
                                        action={ <TogglePopover>
                                            <KirokuPopoverItem>
                                                <div className={ 'd-flex justify-content-between p-1 fix-icon-align' }>
                                                    <Link
                                                        to={ {
                                                            pathname: `/analysis`,
                                                            state   : {
                                                                survey  : { id: survey.id, title: survey.title },
                                                                customer: { id: customer.id, name: customer.name },
                                                            },
                                                        } }
                                                    >
                                                        <i className={ 'fa fa-line-chart pr-1' }/>
                                                        <span>{ t('common.Analysis') }</span>
                                                    </Link>
                                                    <div className="p-1"/>
                                                    <Link
                                                        to={ `/records/create/customer/${ customer.id }/survey/${ survey.id }` }
                                                    >
                                                        <i className="fas fa-file-signature pr-1"/>
                                                        <span>{ t('common.Records') }</span>
                                                    </Link>
                                                </div>
                                            </KirokuPopoverItem>
                                        </TogglePopover> }
                            >
                                <span>
                                    <Link to={ `/surveys/${ survey.id }` }>
                                        { survey.title }&nbsp;&nbsp;
                                    </Link>
                                </span>
                                <span className='ml-6'>
                                    { !!survey['today_recoded_record'].length ?
                                        <Link to={`/records/${survey['today_recoded_record'][0].id}`}>
                                            <KirokuLabel
                                                style={ {
                                                    backgroundColor: '#337ab7',
                                                    color          : 'white',
                                                    cursor         : 'pointer'
                                                } }>
                                                { t('CustomerEditor.Recorded') }
                                            </KirokuLabel>
                                        </Link>
                                            : ''
                                    }
                                </span>
                                <span className='ml-6'>
                                    { survey['drafted_record'].length ?
                                        <Link to={`/records/${survey['drafted_record'][0].id}`}>
                                            <KirokuLabel style={ {
                                                backgroundColor: '#f7981c',
                                                color          : 'white',
                                                cursor         : 'pointer'
                                            } }
                                            >
                                                { t('common.haveDraft') }
                                            </KirokuLabel>
                                        </Link>
                                        : '' }
                                </span>
                            </KirokuCard>
                        );
                    }) }
                </If>
            </KirokuLoader>
        </KirokuCardList>
    </div>;
}

export default function SurveyTab(props) {
    const { t }                           = useTranslation();
    const { customer }         = props;
    const {
        search, cancelAPISearch, pagination
    } = resources.useSearch('Template');

    const [loading, setLoading] = useState(false)
    const [loadingAPI, setLoadingAPI] = useState(false);
    const [hasNext, setHasNext]           = useState(false);
    const [templateList, setTemplateList] = useState([]);

    useEffect(() => {
        if (props.show) {
            setLoadingAPI(true);
            search({
                type: 'labeling',
                customer_id: customer.id,
                page: 1,
            }, 'template-with-label')
                .then((res) => {
                    setTemplateList(res.result)
                    setHasNext(res.hasNext)
                    setLoadingAPI(false);
                }).catch((error) => {
                console.log(error);
                setLoadingAPI(false);
            });

        }
        return () => {
            cancelAPISearch();
        };
    }, [customer.id, cancelAPISearch, search, props.show]);

    function isScrolling() {
        if (window.innerWidth < config.configWithMobile) {
            if ((Math.ceil(document.documentElement.scrollTop) >= document.documentElement.offsetHeight - (window.innerHeight + 100))
                && (hasNext && !loading )
            ) {
                setLoading(true);
                search({
                    type: 'labeling',
                    customer_id: customer.id,
                    page: pagination.currentPage + 1,
                }, 'template-with-label').then(res => {
                    setHasNext(res.hasNext)
                    setTemplateList(templateList.concat(res.result));
                    setLoading(false);
                }).catch(e => {
                    setLoading(false);
                });
            }
        } else {
            if (window.innerHeight + Math.ceil(document.documentElement.scrollTop) + 10 >= document.documentElement.offsetHeight){
                if (hasNext && !loading) {
                    setLoading(true);
                    search({
                        type: 'labeling',
                        customer_id: customer.id,
                        page: pagination.currentPage + 1,
                    }, 'template-with-label').then(res => {
                        setHasNext(res.hasNext)
                        setTemplateList(templateList.concat(res.result));
                        setLoading(false)
                    });
                }
            }
        }
    }

    useEffect(() => {
        if(props.show) {
            window.addEventListener('scroll', isScrolling);
            return () => window.removeEventListener('scroll', isScrolling);
        }
    });

    return (
        <div hidden={ !props.show }>
            <ComponentWeb>
                <KirokuCardWeb>
                    <div className="p-3">
                        <KirokuLoader loading={loadingAPI}>
                            <If statement={ templateList.length }
                                otherwise={ <NotFoundSurveyCustomerDetail { ...props }/> }
                            >
                                {() => <KirokuTable>
                                    <THeader>
                                        <tr>
                                            <th>{ t('common.Name') }</th>
                                            <th>{ t('common.Description') }</th>
                                            <th className='text-right'/>
                                        </tr>
                                    </THeader>
                                    <TBody>
                                        { templateList.map((survey, index) => {
                                            return <tr key={ index }>
                                                <td>
                                                    <span>
                                                        <Link to={ `/surveys/${ survey.id }` }>
                                                            { survey.title }
                                                        </Link>
                                                    </span>
                                                    <span className='ml-6'>
                                                        { !!survey['today_recoded_record'].length ?
                                                            <Link to={`/records/${survey['today_recoded_record'][0].id}`}>
                                                                <KirokuLabel
                                                                    // hidden={ !(survey['record_at']) || !!customer.delete_at }
                                                                    style={ {
                                                                        backgroundColor: '#337ab7',
                                                                        color          : 'white',
                                                                        cursor         : 'pointer'
                                                                    } }>
                                                                    { t('CustomerEditor.Recorded') }
                                                                </KirokuLabel>
                                                            </Link>
                                                                : ''
                                                        }
                                                    </span>
                                                    <span className='ml-6'>
                                                        { !!survey['drafted_record'].length ?
                                                            <Link to={`/records/${survey['drafted_record'][0].id}`}>
                                                                <KirokuLabel style={ {
                                                                    backgroundColor: '#f7981c',
                                                                    color          : 'white',
                                                                    cursor         : 'pointer'
                                                                } }>{ t('common.haveDraft') }</KirokuLabel>
                                                            </Link>
                                                                : '' }
                                                    </span>
                                                </td>
                                                <td>{ survey.description }</td>
                                                <td className='text-right d-flex'>
                                                    <Link
                                                        className='btn btn-kiroku-primary'
                                                        to={ {
                                                            pathname: `/analysis`,
                                                            state   : {
                                                                survey  : { id: survey.id, title: survey.title },
                                                                customer: { id: customer.id, name: customer.name },
                                                            },
                                                        } }
                                                    >
                                                        { t('common.Analysis') }
                                                    </Link>
                                                    <div className={ 'pr-1 pl-1' }/>
                                                    <Link
                                                        className='btn btn-kiroku-light'
                                                        to={ `/records/create/customer/${ customer.id }/survey/${ survey.id }` }
                                                    >
                                                        { t('common.Records') }
                                                    </Link>
                                                </td>
                                            </tr>;
                                        }) }
                                    </TBody>
                                </KirokuTable>}

                            </If>
                        </KirokuLoader>
                    </div>
                </KirokuCardWeb>
            </ComponentWeb>
            <ComponentMobile>
                <SurveyTabMobile { ...props } templateList={ templateList } loadingAPI={loadingAPI}/>
            </ComponentMobile>

            <div hidden={!hasNext || loadingAPI}>
                {loading && <KirokuLoading/>}
            </div>
            <div className={'d-block d-xs-block d-sm-block d-md-none d-lg-none'} style={ { paddingBottom: 80 } }/>
        </div>
    );
}

import { useCallback, useEffect, useState } from 'react';
import { resources }                        from '@kiroku/api';

export function useCustomerDefault() {
    const { search, result, cancelAPISearch }   = resources.useSearch('Customer');
    const [customerDefault, setCustomerDefault] = useState([]);

    useEffect(() => {
        if (result) {
            setCustomerDefault(result);
        }
        return () => {
            cancelAPISearch();
        };
    }, [result, cancelAPISearch]);

    const getCustomerDefault = useCallback((id) => {
        return search({ type: 'all', users: id });
    }, [search]);

    return [customerDefault, getCustomerDefault];
}

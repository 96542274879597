import { useCustomerContext }   from "../../MakeCustomer/CustomerContext";
import { useCallback }          from "react";
import { CHANGE_INFO_CUSTOMER } from "../../MakeCustomer/CustomerReducer";

const defaultObj = {
    services: {
        name: '',
        startDate: '',
        endDate: ''
    },
    monitoring_terms: {
        date: null
    }
};

export default function useCertification() {

    const {infoCustomer, dispatch} = useCustomerContext();
    const {certifications} = infoCustomer;

    const setCertifications = useCallback((name, value) => {
        dispatch({
            type: CHANGE_INFO_CUSTOMER,
            name, value
        })
    }, [ dispatch ])

    const addCertifications = useCallback((name) => {
        certifications[0][name] = [ ...certifications[0][name], defaultObj[name] ]
        setCertifications('certifications', certifications)
    }, [ certifications, setCertifications ]);

    const removeCertifications = useCallback((name, index) => {
        certifications[0][name].splice(index, 1)
        setCertifications('certifications', certifications)
    }, [ certifications, setCertifications ]);

    return {
        certifications,
        setCertifications,
        addCertifications,
        removeCertifications
    }
};

import React              from 'react';
import { useTranslation } from 'react-i18next';

export default function StatisticsBox({ icon, label, changeData }) {

    const { t } = useTranslation();
    return (
        <div className='padding-responsive'>
            <div className='d-flex justify-content-between'>
                <div>
                    <div className='title-card-dashboard d-flex'>
                        <i className={ icon }
                           style={ { fontSize: '1.3rem', color: '#6c798f' } }/>
                        &nbsp;
                        <div style={ { lineHeight: 1.2, fontSize: 14 } }>
                            { label }
                        </div>
                    </div>
                    <div className='number-statistics-dashboard'>
                        { changeData.newValue }
                    </div>
                </div>
            </div>
            <div hidden={ changeData['percent'] === null }>
                { changeData['isDecrease'] ?
                    (
                        <span className={ 'text-danger font-size-16' }>&#9660; { changeData['percent'] }%</span>
                    ) :
                    (
                        <span className={ 'text-success font-size-16' }>&#9650; { changeData['percent'] }%</span>
                    )
                }
                &nbsp;
                <span className={ 'main-content-info' }>
                    &nbsp;&nbsp;{ t('Dashboard.numberOfRecordPreviousPeriod') }
                    &nbsp;{ changeData['orginalValue'] }
                </span>
            </div>
        </div>
    );
}

import React, {Component} from 'react';
import KirokuButton       from "./KirokuButton";

export default class KirokuActionPage extends Component {
    render() {
        const { bnColor } = this.props;

        return (
            <span  hidden={this.props.hidden} className='title-page'>
                <KirokuButton color={ bnColor || 'primary'}>
                   <span>{this.props.children}</span>
                </KirokuButton>
            </span>
        )
    }
}

import React                               from 'react';
import {
    KirokuCardWeb, KirokuRadio,
    KirokuCardBodyWeb,
}                                          from '../../../../ui';
import { useHeadingQuestion }              from '../../useSurvey';
import { useAsQuestionType }               from '../../../Recording';
import { ButtonNext, getNextSingleChoice } from './getNextQuestion';
import { QuestionSkipped }                 from './QuestionSkipped';
import QuestionCardTitle                   from './QuestionCardTitle';
const width = window.innerWidth;

export function setDefaultAnswerSingleChoice(question) {
    let answers      = {};
    // convert data old choice = ['answer_1', 'answer_2']
    question.choices = question.choices.map(choice => {
        if (typeof choice === 'string') {
            return { answer: choice };
        }
        return {
            ...choice,
        };
    });

    question.choices.forEach((choice, index) => {
        if (choice[ 'defaultAnswer' ]) {
            answers = { ...choice, is_choice: index };
        }
    });

    question.answers = answers;
    return question;
}

export default function SingleChoiceQuestion({ question }) {

    const { doAnswer, answering } = useAsQuestionType(question);
    const { content }             = useHeadingQuestion(question);

    return (
        <div id='component-question'>
            <div id='web-q' className='pb-3'>
                <KirokuCardWeb>
                    <div className={ (width <= 768) && !!question.answers.answer ? 'border-has-answer': ' '}>
                    <QuestionCardTitle
                        isDisabled={question.isDisabled}
                        question={question}
                        handleResetAnswer={() => doAnswer({}, question.index + 1)}
                        hasAnswer={!!question.answers.answer}
                    />
                    <KirokuCardBodyWeb>
                        <QuestionSkipped
                            isDisabled={question.isDisabled}
                        >
                            <span className='content-name-question'>{content}</span>
                            <div className="d-flex justify-content-between" style={{ flexWrap: 'wrap' }}>
                                <div className='answer-question'>
                                    {question.choices.map((choice, index) =>
                                        <KirokuRadio
                                            disabled={question.isDisabled}
                                            key={index}
                                            id={`${question.index}-${index}`}
                                            name={`${question.index}SingleChoice`}
                                            checked={question.answers.is_choice === index}
                                            onChange={() => {
                                                answering();
                                                const answers = {
                                                    ...question.choices[ index ],
                                                    is_choice: index,
                                                };
                                                doAnswer(answers, getNextSingleChoice({ answers, index: question.index, isAnswers: true }));
                                            }}
                                        >
                                            <span style={{ color: '#495057' }}>{choice.answer}</span>

                                        </KirokuRadio>,
                                    )}
                                </div>
                                <div className='d-flex'>
                                    <div style={{ margin: 'auto' }}
                                    >
                                        <ButtonNext
                                            question={question}
                                            onClick={() => {
                                                if(question.answers && question.answers.answer) {
                                                    doAnswer(question.answers, getNextSingleChoice(question));
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </QuestionSkipped>
                    </KirokuCardBodyWeb>
                    </div>
                </KirokuCardWeb>
            </div>
        </div>
    );
}

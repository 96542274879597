import React              from "react";
import { useTranslation } from "react-i18next";

export const useBlood = () => {

    const {t} = useTranslation();
    const bloodType = [
        {
            name: 'Unknown',
            type: t('CustomerEditor.Unknown')
        }, {
            name: 'A',
            type: 'A'
        },
        {
            name: 'B',
            type: 'B'
        },
        {
            name: 'AB',
            type: 'AB'
        },
        {
            name: 'O',
            type: 'O'
        }
    ];

    const selectBloodType = (bloodType) => {
        return bloodType.map((blood) => ({
            value: blood.type,
            component: (
                <div>
                    <span className={'text-muted'}>{blood.type}</span>
                </div>
            ),
            label: blood.type
        }))
    }


    return [ selectBloodType(bloodType) ]
}

import React                   from 'react';
import moment                  from 'moment';
import CalendarChart           from '../HelperComponent/CalendarChart';
import useOnlyDateChart        from './useOnlyDateChart';
import NoAnswersFoundComponent from '../NoAnswersFoundComponent';
import { useTranslation }      from 'react-i18next';

export default function OnlyDateChart({records, question, handleSelectAnswer}) {
    const {data, startDate, endDate, isNotDataAnalysis } = useOnlyDateChart(records, question);
    const { t} = useTranslation();

    let formatStartDate = moment.unix(startDate).subtract(2,'days');
    let formatEndDate = moment.unix(endDate).add(1, 'days');
    let newStartDate = formatStartDate.format('YYYY/M/D');
    let newEndDate = formatEndDate.format('YYYY/M/D');
    let numberMonthSelected = formatEndDate.endOf('month').diff(formatStartDate.startOf('month'), 'months');

    if(isNotDataAnalysis) {
        return <NoAnswersFoundComponent/>
    }
    return <div className={'pt-2'} style={{ maxWidth: numberMonthSelected > 5 ? '100%' : '50%'  }}>
        <CalendarChart dataAnalysis={data} startDate={newStartDate} endDate={newEndDate} handleOnClick={(record) => {
            let answerSelect =  moment(record.date).format(t('dateTimeFormat.YearMonthDateWithDay'));
            handleSelectAnswer({type: question.type, content : question.content, answer : answerSelect})
        }}/>
    </div>;
}

import React, {Component}   from 'react';
import { KirokuCardAction } from '../../../../../ui';

class KirokuTogglePopover extends Component {

    render() {
        const { TogglePopover, popupVisible } = this.props;
        return (
            <div className="kiroku-toggle-popover">
                <KirokuCardAction>
                    <div className={"kiroku-toggle-popover-action-icon"}>
                        <div>
                            { TogglePopover ? TogglePopover : <div style={{color: '#1991eb'}} className={'cursor-pointer'}><i className="fas fa-ellipsis-h"/></div>  }
                        </div>
                    </div>
                    {(popupVisible) && (
                        <div>
                            {this.props.children}
                        </div>
                    )}
                </KirokuCardAction>
            </div>
        )
    }
}

export default KirokuTogglePopover;

import React, { useEffect, useState }                                                    from 'react';
import { ComponentMobile, ComponentWeb, KirokuButton, KirokuInputArea, KirokuInputDate } from '../../../../ui';
import DropdownSelectStatus                                                              from '../DropdownSelectStatus';
import { useTranslation }                                                                from 'react-i18next';
import { resources }                                                                     from '@kiroku/api';
import KirokuLoader
                                                                                         from '../../../../ui/KirokuLoader';
import useNotification
                                                                                         from '../../../Layout/useNotification';
import { useMixpanelTracking }                                                           from '../../../../Mixpanel/useMixpanelTracking';
import moment                                                                            from 'moment';


export default function ReviewForm({ closeModal, show, plan, onSubmit, isReviewAllSupported }) {
    const { t }                                               = useTranslation();
    const [comment, setComment]                               = useState('');
    const [summary, setSummary]                               = useState('');
    const [short_term_target_status, setShortTermStatus]      = useState('');
    const [long_term_target_status, setLongTermStatus]        = useState('');
    const [monitored_at, setMonitorAt]                        = useState(null);
    const [isDisabledBtnSubmit, setIsDisabledBtnSubmit]       = useState(false);
    const [isDisabledBtnSaveDraft, setIsDisabledBtnSaveDraft] = useState(false);

    const [planSupport, loadDetail] = resources.useDetail('ReviewPlan');
    const [resultUpdate, APIUpdate] = resources.useUpdate('ReviewPlan');
    const [resultCreate, APICreate] = resources.useCreate('ReviewPlan');
    const { notification }          = useNotification();
    const { trackingMixpanel }      = useMixpanelTracking();


    useEffect(() => {

    }, [resultUpdate, resultCreate]);

    useEffect(() => {
        if (show) {
            loadDetail(plan.id).then((response) => {
                if (response.review) {
                    setMonitorAt(plan.review.monitored_at);
                    setLongTermStatus(response.review.long_term_target_status);
                    setShortTermStatus(response.review.short_term_target_status);
                    setSummary(response.review.summary);
                    setComment(response.review.comment);
                }
            });
        }
    }, [loadDetail, show, plan]);


    const resetStateReviewForm = () => {
        setComment('');
        setSummary('');
        setLongTermStatus('');
        setShortTermStatus('');
        setMonitorAt(null);
        setIsDisabledBtnSubmit(false);
        setIsDisabledBtnSaveDraft(false);
    }

    const handleSubmit = (has_draft) => {
        setIsDisabledBtnSubmit(true);
        setIsDisabledBtnSaveDraft(true);
        let planSupportData = {
            short_term_target_status,
            long_term_target_status,
            comment,
            summary,
            monitored_at,
            is_draft: !!has_draft,
            review_status: !!has_draft ? 1 : 2,
        };
        if (planSupport && planSupport.review) {
            APIUpdate(plan.id, { ...planSupportData, plan_id: plan.id })
                .then(() => {
                    notification(t('message.UpdateSuccess'), 'success');
                    trackingMixpanel('UPDATE_REVIEW_PLAN', { ...planSupportData, plan_id: plan.id });
                    resetStateReviewForm();
                    closeModal();
                    onSubmit();
                }).catch(err => {
                console.log(err);
                resetStateReviewForm();
                notification(t('createRecord.CreateRecordFail'), 'danger');
            });
        } else {
            APICreate({ ...planSupportData, plan_id: plan.id })
                .then(() => {
                    notification(t('message.UpdateSuccess'), 'success');
                    trackingMixpanel('ADD_REVIEW_PLAN', { ...planSupportData, plan_id: plan.id });
                    resetStateReviewForm();
                    closeModal();
                    onSubmit();
                }).catch(err => {
                console.log(err);
                resetStateReviewForm();
                notification(t('createRecord.CreateRecordFail'), 'danger');
            });
        }
    };

    const publishReviewPlanCondition = () => {
        return  (isDisabledBtnSubmit || !long_term_target_status || !short_term_target_status ) || !(moment().unix() > plan.end_at && plan.published_at ) || !isReviewAllSupported
    }

    return (
        <div className={ 'pt-3 pb-3' } hidden={ !show }>
            <div className={ 'padding-responsive bg-white' }>
                <KirokuLoader loading={ false }>
                    <div className={ 'review-plan-text' }> { t('plans.review') }</div>
                    <div>
                        <div className={'d-flex align-items-center'}>
                            <div className={'label-monitor-form-plan font-size-14 pr-3'}>
                                {t('plans.resultForLongTermTarget')}:
                            </div>
                            <div style={{width: 120}}>
                                <DropdownSelectStatus
                                    onSelect={(value) => {
                                        setLongTermStatus(value);
                                    }}
                                    defaultStatus={long_term_target_status || ''}
                                />
                            </div>
                            <div className="col md-4"/>
                        </div>
                        <div className={'pt-3'}/>
                        <div className="d-flex align-items-center">
                            <div className={'label-monitor-form-plan font-size-14 pr-3'}>
                                {t('plans.resultForShortTermTarget')}:
                            </div>
                            <div style={{width: 120}}>
                                <DropdownSelectStatus
                                    onSelect={(value) => {
                                        setShortTermStatus(value);
                                    }}
                                    defaultStatus={short_term_target_status}
                                />
                            </div>
                        </div>
                        <div className="pt-3">
                            <div className={'d-flex align-items-center'} style={{flexWrap: 'wrap'}}>
                                <div className={'label-monitor-form-plan font-size-14 pr-3'}>
                                    {t('plans.MonitoringDate')}:
                                </div>
                                <div style={{position: 'relative', width: 190}}>
                                    <div className='monitor-at-from'>
                                        <KirokuInputDate
                                            inputProps={{disabled: false, readOnly: true}}
                                            value={monitored_at ? moment.unix(monitored_at) : ''}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            dateFormat={t('dateTimeFormat.YearMonthDate')}
                                            onChange={(event) => {
                                                setMonitorAt(event.unix());
                                            }}
                                            locale={t('createRecord.Date')}
                                            className='read-only-date'
                                        />
                                        {monitored_at ? <div style={{position: 'absolute', bottom: 0, right: 0}}>
                                            <button className='close reset-date-time-monitor-button'
                                                    onClick={() => setMonitorAt(null)}>
                                                <span style={{fontSize: '24px', color: '#a8aab7'}}>&times;</span>
                                            </button>
                                        </div> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt-3">
                            <div className={'d-flex'} style={{flexWrap: 'wrap'}}>
                                <div className={'label-monitor-form-plan font-size-14 pr-3'}>
                                    {t('plans.summary')}:
                                </div>
                                <div className='input-area-comment-monitor-plan'>
                                    <KirokuInputArea onChange={(event) => setSummary(event.target.value)}
                                                     value={summary}/>
                                </div>
                            </div>
                        </div>
                        <div className="pt-3">
                            <div className={'d-flex'} style={{flexWrap: 'wrap'}}>
                                <div className={'label-monitor-form-plan font-size-14 pr-3'}>
                                    {t('plans.comment')}:
                                </div>
                                <div className='input-area-comment-monitor-plan'>
                                    <KirokuInputArea onChange={(event) => setComment(event.target.value)}
                                                     value={comment}/>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/*<p style={{ fontSize: 12, color: 'rgb(201 160 67)'}} hidden={ isReviewAllSupported }>*/}
                    {/*    {t('plans.noticeMonitored')}*/}
                    {/*</p>*/}
                    <hr/>
                    <ComponentWeb>
                        <div className="d-flex">
                            <KirokuButton onClick={() => {
                                closeModal();
                                resetStateReviewForm();
                            }}
                                          color={'light'}
                                          className={'font-size-14'}
                            >
                                {t('plans.cancel') }
                            </KirokuButton>
                            <div className="pl-3">
                                <KirokuButton
                                    disabled={publishReviewPlanCondition()}
                                    onClick={ () => {
                                        handleSubmit();
                                    } }
                                    color={ 'success' }
                                    className={ 'font-size-14' }
                                >
                                    { t('plans.confirm') }
                                </KirokuButton>
                            </div>
                            <div className="pl-3">
                                <KirokuButton
                                    disabled={ isDisabledBtnSaveDraft }
                                    onClick={ () => {
                                        handleSubmit('has_draft');
                                    } }
                                    color={ 'primary' }
                                    className={ 'font-size-14' }
                                >
                                    { t('plans.save as draft') }
                                </KirokuButton>
                            </div>
                        </div>
                    </ComponentWeb>
                    <ComponentMobile>
                        <div className={ 'text-center pb-4' }>
                            <KirokuButton
                                disabled={ publishReviewPlanCondition() }
                                onClick={ () => {
                                    setIsDisabledBtnSubmit(true);
                                    handleSubmit();
                                } }
                                color={ 'success' }

                                className={ 'font-size-14' }
                            >
                                { t('plans.confirm') }
                            </KirokuButton>
                        </div>
                        <div className="d-flex justify-content-around">
                            <div>
                                <KirokuButton onClick={ () => {
                                    closeModal();
                                    resetStateReviewForm();
                                } }
                                              color={ 'light' }
                                              className={ 'font-size-14' }
                                >
                                    { t('plans.cancel') }
                                </KirokuButton>
                            </div>

                            <div className="pl-3">
                                <KirokuButton
                                    disabled={ isDisabledBtnSaveDraft }
                                    onClick={ () => {
                                        setIsDisabledBtnSaveDraft(true);
                                        handleSubmit('has_draft');
                                    } }
                                    color={ 'primary' }

                                    className={ 'font-size-14' }
                                >
                                    { t('plans.save as draft') }
                                </KirokuButton>
                            </div>
                        </div>
                    </ComponentMobile>
                </KirokuLoader>
            </div>
        </div>
    );
}

import { authentication, resources } from '@kiroku/api';
import { useEffect }                 from 'react';
import queryString                   from 'query-string';
import { useHistory }                from 'react-router-dom';
import { getKeyWordSearch }          from '../../ServiceSearch';

export function useLookingForTemplate() {
    const history  = useHistory();
    const { user } = authentication.useAuthenticatedContext();

    const initialCondition = {
        type      : 'condition',
        page      : 1,
        order_type: 'DESC',
        order_by  : 'update_at',

        // keywords: '' on request when keyword
        // customersId: [] on request when is customersId
    };


    const { condition, search, result, pagination, cancelAPISearch, loading } = resources.useSearch('Template', initialCondition);

    useEffect(() => {
        try {
            const conditionUrl = queryString.parse(history.location.search.replace('?', ''), { arrayFormat: 'comma' });
            if (!conditionUrl.type || conditionUrl.type !== 'condition') {
                throw new Error(`is not ${conditionUrl.type} condition type`);
            }
            if(!conditionUrl.user_id) {
                delete conditionUrl.user_id
            }
            if(conditionUrl.title) {
                conditionUrl.title = getKeyWordSearch(history.location.search.replace('?', ''))
            }
            search(conditionUrl).catch(error => {
                console.log(error);
            });
        } catch (error) {
            search({
                type      : 'condition',
                page      : 1,
                order_type: 'DESC',
                order_by  : 'update_at',
            });
        }
        localStorage.removeItem('urlSearchSurveys');
        return () => {
            cancelAPISearch();
        };
    }, [search, cancelAPISearch, history, user]);

    useEffect(() => {
        const urlQuery = queryString.stringify({ ...condition }, { arrayFormat: 'comma' });
        history.replace('/surveys?' + urlQuery);
    }, [condition, history]);


    return {
        condition, search,
        result, loading,
        pagination,
    };
}

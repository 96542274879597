import { useCallback }    from 'react';
import mixpanel           from 'mixpanel-browser';
import { authentication } from '@kiroku/api';

const WILL_BE_TRACKED = {

    CREATE_PLAN : (action) => {
        return ['Create Plan', action];
    },

    DUPLICATE_PLAN : (action) => {
        return ['Duplicate Plan', action];
    },

    UPDATE_PLAN : (action) => {
        return ['Update Plan', action];
    },

    DELETE_PLAN : (action) => {
        return ['Delete Plan', action];
    },

    PUBLISHED_PLAN : (action) => {
        return ['Publish Plan', action];
    },

    CHANGE_PLAN_TO_DRAFT : (action) => {
        return ['Change Plan To Daft', action];
    },

    ADD_REVIEW_PLAN : (action) => {
        return ['Add Review Plan', action];
    },

    UPDATE_REVIEW_PLAN : (action) => {
        return ['Update Review Plan', action];
    },

    CREATE_SUPPORT : (action) => {
        return ['Create Support', action];
    },

    UPDATE_SUPPORT : (action) => {
        return ['Update Support', action];
    },

    DELETE_SUPPORT : (action) => {
        return ['Delete Support', action];
    },

    ADD_REVIEW_SUPPORT : (action) => {
        return ['Create Review Support', action];
    },

    UPDATE_REVIEW_SUPPORT : (action) => {
        return ['Update Review Support', action];
    },

    PRINT_PLAN : (action) => {
        return ['Print Plan', action];
    },

    PRINT_REVIEW_PLAN : (action) => {
        return ['Print Review Plan', action];
    },

    EXPORT_EXCEL_PLAN : (action) => {
        return ['Export Excel Plan', action];
    },

    EXPORT_EXCEL_REVIEW_PLAN : (action) => {
        return ['Export Excel Review Plan', action];
    },

    QUICK_ADD_TEMPLATE_TO_SUPPORT : (action) => {
        return ['Quick Add Template To Support', action];
    },

    COPY_RECORD_TO_CUSTOMERS : (action) => {
        return ['Copy Record To Customers', action];
    },

    PIN_CUSTOMER: (action) => {
        return ['Pin Customer', { customerId: action }];
    },

    ASSIGN_SURVEY_TO_CUSTOMER: (action, user) => {
        return ['Assign Template to Customer', {
            customers: action.customers.map(customerId => {
                return {
                    id: customerId,
                    organization_id: user.organization_id,
                }
            }),
            surveys: action.surveys.map(surveyId => { return { id: surveyId }})
        }];
    },

    // ASSIGN_SURVEYS: (action) => {
    //     return ['Assign Template to Customer', action];
    // },

    CREATE_SURVEY: (action) => {
        return ['Create Template', action];
    },

    DUPLICATE_SURVEY: (action) => {
        return ['Duplicate Template', action];
    },

    UPDATE_SURVEY: (action) => {
        return ['Update Template', {
            id         : action.id,
            questions  : action.questions,
            description: action.description,
            title      : action.title,
        }];
    },

    PREVIEW_TEMPLATE: (action) => {
        return ['Preview Template', action];
    },

    // CREATE_SURVEY_MOBILE: (action) => {
    //     if (action.duplicate) {
    //         return ['Duplicate Template', action];
    //     }
    //     return ['Create Template', action];
    // },

    CREATE_TEAM: (action) => {
        return ['Create Team', {team: action}];
    },

    TEAM_UPDATED: (action) => {
        return ['Update Team', {team: action}];
    },

    // UPDATE_SURVEY_MOBILE: (action) => {
    //     return ['Update Template', action];
    // },

    CUSTOMER_CREATED: (action) => {
        return ['Create Customer', { customer: action }];
    },

    CUSTOMER_UPDATED: (action) => {
        return ['Update Customer', { customer: action }];
    },

    CUSTOMER_DUPLICATED: (action) => {
        return ['Duplicate Customer', { customer: action }];
    },

    DEACTIVE_CUSTOMER: (action) => {
        return ['Deactive Customers', {
            id: action.map(customer => customer.id),
        }];
    },

    STAFF_CREATED: (action) => {
        return ['Create Staff', { staff: action }];
    },

    STAFF_UPDATED: (action) => {
        return ['Update Staff', { staff: action }];
    },

    STAFF_DUPLICATED: (action) => {
        return ['Duplicate Staff', { staff: action }];
    },

    DELETE_USERS: (action) => {
        return ['Deactive Staffs', { userId: action.id, ...action }];
    },

    UPDATE_ORGANIZATION: (action) => {
        return ['Update Organization', { id: action.id, ...action }];
    },

    HANDLE_SUBMIT_COMMENT_RECORD: (action) => {
        return ['Comment Record', action];
    },

    EXPORT_EXCEL_RECORD_V2: (action) => {
        return ['Export(Record Beta)', action];
    },

    EXPORT_DOCX: (action) => {
        return ['Export Word', action];
    },

    RECORD_EDITED_TRACKING: (action) => {
        return ['Update Record', action];
    },

    RECORD_DRAFTED_TRACKING: (action) => {
        return ['Draft Record', action];
    },

    RECORD_CREATED_TRACKING: (action) => {
        return ['Create Record', action];
    },

    RECORD_CREATE_DRAFTED_TRACKING: (action) => {
        return ['Create Draft Record', action];
    },

    RECORD_QUICK_EDITED_TRACKING: (action) => {
        return ['Quick Update Record', action];
    },

    RECORD_QUICK_DRAFTED_TRACKING: (action) => {
        return ['Quick Draft Record', action];
    },

    RECORD_QUICK_CREATED_TRACKING: (action) => {
        return ['Create Quick Record', action];
    },

    NEW_RECORD_QUICK_DRAFTED_TRACKING: (action) => {
        return ['Create Quick Draft Record', action];
    },

    LOGGED_IN: (action) => {
        return ['Login', { profile: action }];
    },

    LOGOUT: (action) => {
        return ['Logout', { profile: action }];
    },
};


export function useMixpanelTracking() {
    const { user } = authentication.useAuthenticatedContext();

    const trackingMixpanel = useCallback((actionName, actionData) => {
        if(WILL_BE_TRACKED[ actionName ]) {
            actionData = JSON.parse(JSON.stringify(actionData));
            const [eventName, eventData] = WILL_BE_TRACKED[ actionName ](actionData, user);
            mixpanel.track(eventName, { type: actionName, ...eventData });
        }
    }, [user]);

    return { trackingMixpanel };
}

import React, { useEffect, useState } from 'react';
import {
    KirokuButton, KirokuCheckBox, KirokuCheckboxBorder, KirokuInput, KirokuLoader, KirokuModal, KirokuModalBody,
    KirokuModalFooter, KirokuModalHeader,
}                                     from '../../../ui';
import { resources }                  from '@kiroku/api';
import { useTranslation }             from 'react-i18next';
import _                              from 'lodash';
import DropdownTeamSearchCustomer     from './DropdownTeamSearchCustomer';

function setSelectedCustomer(customers, isSelected) {
    return customers.map(item => ({ ...item, isChecked: isSelected }));
}

export default function ModalChoiceCustomers({ show, onCloseModal, searchCustomerModal, customerDefault, customerCondition }) {
    const { search, result, cancelAPISearch }   = resources.useSearch('Customer');
    const [keywords, setKeywords]               = useState('');
    const [loadingAPI, setLoadingAPI]           = useState(true);
    const [customers, setCustomers]             = useState([]);
    const [customerBuilder, setCustomerBuilder] = useState([]);
    const [isCloseModal, setIsCloseModal]       = useState(false);

    useEffect(() => {
        if (show) {
            setLoadingAPI(true);
            search({ type: 'all' }).then(() => {
                setLoadingAPI(false);
            });
        }
        return () => {
            cancelAPISearch();
        };
    }, [search, show, cancelAPISearch]);

    useEffect(() => {
        if (result.length) {
            if (customerCondition.length) {
                customerCondition.forEach(customer => {
                    const indexCustomer   = _.findIndex(result, ctm => ctm.id === parseInt(customer));
                    result[indexCustomer] = {
                        ...result[indexCustomer],
                        isChecked: true,
                    };
                });
                setCustomers(result);
                setCustomerBuilder(result);
            } else if(!customerCondition.length) {
                setCustomers(result);
                setCustomerBuilder(result);
            } else if (customerDefault.length) {
                customerDefault.forEach(customer => {
                    const indexCustomer   = _.findIndex(result, ctm => ctm.id === customer.id);
                    result[indexCustomer] = {
                        ...result[indexCustomer],
                        isChecked: true,
                    };
                });
                setCustomers(result);
                setCustomerBuilder(result);
            } else {
                setCustomers(result);
                setCustomerBuilder(result);
            }
        }
    }, [result, customerDefault, customerCondition]);

    const { t } = useTranslation();

    const handleSearchKeywords = (event) => {
        let customerState     = [...customerBuilder];
        let customerSearching = customerState.filter(customer => customer.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1);
        setCustomers(customerSearching);
    };

    const handleCheckAll = () => {
        const isCheckAll = _.findIndex(customers, customer => !customer.isChecked) === -1;
        setCustomers(customers.map(customer => {
            return {
                ...customer,
                isChecked: !isCheckAll,
            };
        }));

        let customerCheckAll   = setSelectedCustomer(customers, !isCheckAll);
        let checkedCustomerAll = [...customerBuilder];
        customerCheckAll.forEach(customer => {
            let indexOfCustomerChecked                 = _.findIndex(customers, ['id', customer.id]);
            checkedCustomerAll[indexOfCustomerChecked] = {
                ...checkedCustomerAll[indexOfCustomerChecked],
                isChecked: customer.isChecked,
            };
        });
        setCustomerBuilder(checkedCustomerAll);
    };

    const handleCheckedCustomer = (index) => {
        let customerInModal                      = [...customers];
        customerInModal[index]                   = {
            ...customerInModal[index],
            isChecked: !customerInModal[index].isChecked,
        };
        let customerInBuilder                    = [...customerBuilder];
        let indexCustomerChecking                = _.findIndex(customerInBuilder, customer => customer.id === customerInModal[index].id);
        customerInBuilder[indexCustomerChecking] = {
            ...customerInBuilder[indexCustomerChecking],
            isChecked: !customerInBuilder[indexCustomerChecking].isChecked,
        };
        setCustomers(customerInModal);
        setCustomerBuilder(customerInBuilder);
    };

    const customerInTeams = (teamSelect, customers) => {
        if (!teamSelect.length) {
            return customers.map(item => ({ ...item, isChecked: false }));
        }
        let listCustomersInTeams = [];
        teamSelect.forEach(team => {
            listCustomersInTeams = _.concat(listCustomersInTeams, team.customers);
        });
        return _.uniqBy([...listCustomersInTeams], 'id').map(item => ({ ...item, isChecked: false }));
    };

    const handleChangeTags = (options) => {
        let customerCheckedInTeam = customerInTeams(options, customerBuilder);
        customerCheckedInTeam     = [...customerBuilder].filter(customer => {
            if (keywords) {
                return (customer.name.toLowerCase().search(keywords.toLowerCase()) !== -1)
                    && _.findIndex(customerCheckedInTeam, ctm => ctm.id === customer.id) !== -1;
            }
            return _.findIndex(customerCheckedInTeam, ctm => ctm.id === customer.id) !== -1;
        });
        setCustomers(customerCheckedInTeam);
    };

    const resetCondition = () => {
        let customerForReset = customerBuilder.map(customer => {
            return {
                ...customer,
                isChecked: _.findIndex(customerDefault, ['id', customer.id]) !== -1,
            };
        });
        setCustomers(customerForReset);
        setCustomerBuilder(customerForReset);
    };

    return (
        <div className="modal-select-record-beta">
            <KirokuModal show={ show }
                         onCloseModal={ onCloseModal }>
                <KirokuModalHeader>
                    <div className={ 'text-center font-header-on-modal' }> { t('RecordsBeta.customerSelection') }</div>
                </KirokuModalHeader>

                <KirokuModalBody style={ { padding: '17px 5px 1px 5px' } }>
                    <div className={ 'pt-2 pb-2 kiroku-section-drop-down-show-more-scroll' }>
                        <DropdownTeamSearchCustomer handleChangeTags={ handleChangeTags }
                                                    show={show}
                                                    isCloseModal={isCloseModal}
                                                    placeholder={ t('RecordsBeta.refineByTeam') }
                        />
                    </div>
                    <div className={ 'pt-2' }>
                        <KirokuInput
                            style={ { height: 36, fontSize: 14 } }
                            placeholder={ t('RecordsBeta.filterByCustomerName') }
                            value={ keywords }
                            onChange={ (e) => {
                                setKeywords(e.target.value);
                                handleSearchKeywords(e);
                            } }
                        />
                    </div>
                    <div style={ { padding: '15px 1px 5px 1px' } }/>
                    <KirokuLoader loading={ loadingAPI }>
                        <div>
                            { customers.length ?
                                <div className='background-color' style={ {
                                    overflow : 'scroll',
                                    minHeight: 'calc(100vh - 360px)',
                                    maxHeight: 'calc(100vh - 360px)',
                                    height   : 'auto',
                                } }>
                                    <div
                                        className={ ' p-3 border-bottom d-flex justify-content-between' }
                                        style={ { border: 'solid 1px #dfe2e5' } }
                                    >
                                        <KirokuCheckBox
                                            checked={ _.findIndex(customers, (ctm) => (!ctm.isChecked)) === -1 }
                                            content={ <div>&nbsp;</div> } id={ 'customer-id' }
                                            onChange={ handleCheckAll }
                                        />
                                        <div onClick={ () => resetCondition() }
                                             className={ 'cursor-pointer font-reset-user-changed' }
                                             style={ { float: 'right', color: '#007BFF' } }
                                        >
                                            { t('RecordsBeta.resetSelection') }
                                        </div>
                                    </div>
                                    { customers.map((customer, i) => {
                                        return (
                                            <div key={ i }>
                                                <div className={ 'checkbox-bigger' }
                                                     onClick={ (event) => {
                                                         event.preventDefault();
                                                         handleCheckedCustomer(i);
                                                     } }>

                                                    <KirokuCheckboxBorder
                                                        content={ customer.name }
                                                        id={ `${ customer.id }-${ i }` }
                                                        checked={ !!customer.isChecked }
                                                        onChange={ () => handleCheckedCustomer(i) }
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }) }
                                </div> : <div className='background-color d-flex'
                                              style={ { fontSize: '16px', height: 'auto', minHeight: 'calc(100vh - 450px)' } }>
                                    <div style={ { margin: 'auto' } }>
                                        { t('RecordsBeta.noCustomers') }
                                    </div>
                                </div>
                            }
                        </div>
                    </KirokuLoader>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={ () => {
                        onCloseModal();
                        setKeywords('');
                        setIsCloseModal(true);
                    } }
                                  color={'light'}
                    >
                        { t('RecordsBeta.cancel') }
                    </KirokuButton>
                    <KirokuButton onClick={ () => {
                                      let customerChecked = customerBuilder.filter(customer => customer.isChecked);
                                      let customerId      = customerChecked.map(customer => customer.id);
                                      searchCustomerModal(customerId);
                                      setIsCloseModal(true);
                                      setKeywords('');
                                      onCloseModal();
                                  } }
                                  color={'primary'}
                    >
                        { t('RecordsBeta.confirm') }
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        </div>

    );
}

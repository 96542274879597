import { createClient }   from '@kiroku/api';
import config             from './config';
import * as Sentry        from '@sentry/browser';
import { handleLogError } from './Sentry/Sentry';
import historyRouter      from './historyRouter';
import store              from './app/store';

import { KIROKU_SWITCH_STATUS_NOTIFY } from './app/applicationReducer';

export function isErrorPermissionEditRecordLocking(error) {
    return error.response && error.response.status === 403
        && error.response.config.method === 'put'
        && error.response.config.url.search('/records/') !== -1
}


const maintenanceCode = [502, 503, 504];
const client = createClient(config.kirokuAPI, (error) => {
    const isMessageNetworkError = ['timeout of 15000ms exceeded', 'Network Error'].includes(error.message);

    if(
        !!error.config && error.config.url
        && error.config.url.search('lock/records') !== -1
        && (
            (error.message === 'timeout of 15000ms exceeded' && error.code === 'ECONNABORTED') ||
            error.message === 'Network Error'
        )
    ) {
        handleLogError(Sentry, error);
        store.dispatch({
            type: KIROKU_SWITCH_STATUS_NOTIFY,
            messageNotification: 'common.Unstable network connection',
            notificationType: 'danger',
            isNotAutomaticCloseNotice: true
        });
        return Promise.reject(error);
    }

    if(isMessageNetworkError) {
        handleLogError(Sentry, error);
        store.dispatch({
            type: KIROKU_SWITCH_STATUS_NOTIFY,
            messageNotification: 'common.Unstable network connection',
            notificationType: 'danger',
            isNotAutomaticCloseNotice: true
        });
        return Promise.reject(error);
    }

    if (!error.response) {
        return Promise.reject(error);
    }
    //not handle error of api update record with status 403
    if ( isErrorPermissionEditRecordLocking(error) ) {
        return null;
    }

    if(error.response && (error.response.status === 401 || error.response.status === 403)) {
        historyRouter.push('/login');
    }

    if (error.response && maintenanceCode.includes(error.response.status)) {
        handleLogError(Sentry, error);
        return historyRouter.push('/maintenance');
    }

    if (error.response && error.response.status === 500) {
        handleLogError(Sentry, error);
        return historyRouter.push('/internal-server-error');
    }

    if(!config.KNOWN_ERRORS.includes(error.code)) {
        handleLogError(Sentry, error);
    }
    return Promise.reject(error);
});

export default client;

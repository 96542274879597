import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';
import { KirokuButton }    from '../../../ui';
import { auth2fa }         from '@kiroku/api';
import If                  from '../../If';
import { KirokuInput }     from '../../../ui';
import { useHistory }      from 'react-router-dom';
import useNotification     from '../../Layout/useNotification';

export default function TwoFactorComponent({ resultUser }) {
    const { t }                               = useTranslation();
    const { disable2FA, setup2FA, verify2FA } = auth2fa.useAuthenticated2FA(resultUser.id);

    const history = useHistory();

    const [errorOTP, setErrorOTP]                        = useState(false);
    const [otpCode, setOtpCode]                          = useState('');
    const [{ code, qrCode, secret }, setResponseEnabled] = useState({
        code  : '',
        qrCode: '',
        secret: '',
    });

    const { notification } = useNotification();

    const enableTFA = () => {
        setup2FA()
            .then(res => setResponseEnabled(res))
            .catch(() => {
                notification(t('message.UpdateFail'), 'danger');
            });
    };

    const disableTFA = () => {
        disable2FA()
            .then(() => {
                notification(t('message.UpdateSuccess'), 'success');
                history.push(`/users/${resultUser.id}`);
            })
            .catch(() => {
                notification(t('message.UpdateFail'), 'danger');
            });
    };

    const handleSubmitOTP = () => {
        verify2FA(otpCode)
            .then(() => {
                notification(t('message.UpdateSuccess'), 'success');
                history.push(`/users/${resultUser.id}`);
                setErrorOTP(false);
            })
            .catch(() => {
                setErrorOTP(true);
            });
    };

    const handleChangeInput = (event) => {
        setOtpCode(event.target.value);
        setErrorOTP(!event.target.value);
    };

    return (<div>
        <div className="row" style={{ width: '100%' }}>
            <div className='col-md-10'>
                <h5>{t('common.Two-factorAuthentication')}</h5>
            </div>
            <div className='col-md-2'>
                {!!resultUser[ '2fa' ] ? (
                    <KirokuButton color="danger" onClick={disableTFA}>{t('common.disable')}</KirokuButton>
                ) : (
                    <KirokuButton color="success" onClick={enableTFA}>{t('common.enable')}</KirokuButton>
                )}
            </div>
            <If statement={qrCode && code}>
                {() => <div className="fix-center ml-4" style={{ width: '100%' }}>
                    <h5 style={{ 'borderBottom': '1px solid #a8a8a8', 'paddingBottom': '5px' }}>{t('common.Setup TFA')}</h5>
                    <span>
                        <p>{t('common.SetupDes')}</p>
                        <img src={qrCode} alt="QRCode" className="img-thumbnail"
                             style={{ 'display': 'block', 'margin': 'auto' }}/>
                        <p>{t('common.Secret Key')} - {secret}</p>
                        <p>{t('common.Auth Type - Time Based - OTP')}</p>
                        <div style={{ position: 'relative' }}>
                            <KirokuInput
                                value={otpCode}
                                name='otp'
                                type='text'
                                onChange={handleChangeInput}/>
                            <p hidden={!errorOTP}
                               style={{
                                   color   : 'red',
                                   fontSize: '13px',
                               }}>{t('common.requireFailOTP')}</p>
                        </div>
                        <div className="fix-center pt-8">
                            <KirokuButton color="primary"
                                          disabled={!otpCode}
                                          onClick={handleSubmitOTP}
                            >
                                {t('common.EnableTFA')}
                            </KirokuButton>
                        </div>
                    </span>
                </div>}
            </If>


        </div>
    </div>);
}

import React, { useState } from 'react';
import { KirokuCardWeb }   from '../../ui';
import ReportCondition     from './ReportCondition/ReportCondition';
import { useTranslation }  from 'react-i18next';
import {
    CustomerOnly, CustomerQuestion, CustomerRecord, TemplateOnly, TemplateQuestion, TemplateRecord,
}                          from './Templates';

const tabReport = [ 'CustomerOnly', 'TemplateRecord', 'TemplateQuestion', 'TemplateOnly', 'CustomerRecord', 'CustomerQuestion'];

const template = {
    'TemplateRecord'  : <TemplateRecord/>,
    'TemplateQuestion': <TemplateQuestion/>,
    'TemplateOnly'    : <TemplateOnly/>,
    'CustomerRecord'  : <CustomerRecord/>,
    'CustomerQuestion': <CustomerQuestion/>,
    'CustomerOnly'    : <CustomerOnly/>,
};

export default function ReportContent() {

    const { t }                  = useTranslation();
    const [tabActive, changeTab] = useState('CustomerOnly');

    return (
        <div className='survey-list default-background-color'>
            <div className='padding-responsive staff-detail'>
                <KirokuCardWeb>
                    <ReportCondition/>
                </KirokuCardWeb>
            </div>

            <div className='padding-responsive staff-detail'>
                <div className='col-md-12' style={ { borderBottom: 'solid 1px #dfe3e9', backgroundColor: '#ffffff' } }>
                    <div className='d-flex justify-content-between pt-3'>
                        { tabReport.map((tab, index) => (
                            <div key={ index } style={ { cursor: 'pointer' } }
                                 className={ `nav-bar text-center ${ tabActive === tab ? 'active bg-white' : '' }` }
                                 onClick={ () => changeTab(tab) }
                            >
                                { t(`Report.${ tab }`) }
                            </div>
                        )) }
                    </div>
                </div>
                <KirokuCardWeb>
                    { template[tabActive] }
                </KirokuCardWeb>
            </div>
        </div>
    );
}

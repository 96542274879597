import React, { useState }               from 'react';
import { ComponentMobile, ComponentWeb } from '../../../../ui';
import moment                            from 'moment';

export default function ShortTerm({ plan, t }) {
    const [isOpenShortTerm, setIsOpenShortTerm] = useState(false);

    return (
        <div className={ 'accordion-ui' }>
            <div className={ 'header-card-detail-short-term d-flex align-items-center' }>
                <div className={ 'icon-accordion pl-0' } style={ { width: 24 } }>
                    <i className={ `fa fa-chevron-${ isOpenShortTerm ? 'down' : 'right' }` }
                       style={ { color: '#6C798F' } }
                       onClick={ () => setIsOpenShortTerm(!isOpenShortTerm) }
                    />
                </div>
                <div className={ 'main-content-info pl-3' }>{ t('plans.shortTermTarget') }</div>
            </div>
            <div hidden={ !isOpenShortTerm }>
                <div className={ 'pt-3 pb-3' }>
                    <ComponentWeb>
                        <div className="pb-3 row margin-none padding-content-info">
                            <div className={ 'col-6' }>
                                <span className={ 'label-info' }>{ t('plans.targetTerm') }:</span><br/>
                            </div>
                            <div className={ 'col-3' }>
                                <span className={ 'label-info' }>{ t('plans.short_term_target_start_at') }:</span><br/>
                            </div>
                            <div className={ 'col-3' }>
                                <span className={ 'label-info' }>{ t('plans.short_term_target_end_at') }:</span><br/>
                            </div>
                        </div>
                        <hr className="m-0"/>
                        <div className="pt-3 row margin-none padding-content-info">
                            <div className={ 'col-6' }>
                                <span className={ 'main-content-info' }>{ plan['short_term_target'] }</span>
                            </div>
                            <div className={ 'col-3' }>
                                <div className={ 'content ' }>
                                    { !!plan['short_term_target_start_at'] &&
                                        moment.unix(plan['short_term_target_start_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))
                                     }
                                </div>
                            </div>
                            <div className={ 'col-3' }>
                                <div className={ 'content ' }>
                                    { !!plan['short_term_target_end_at'] &&
                                        moment.unix(plan['short_term_target_end_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))
                                    }
                                </div>
                            </div>
                        </div>
                    </ComponentWeb>
                    <ComponentMobile>
                        <div style={ { padding: '0 30px' } }>
                            <span className={ 'main-content-info' }>{ plan['short_term_target'] }</span><br/>
                            <div className="pt-3 d-flex align-items-center">
                                <span className={ 'main-content-info' }>{ t('plans.short_term_target_start_at') }:</span>
                                <div className="pl-2"/>
                                <div className={ 'content ' }>
                                    { !!plan['short_term_target_start_at'] &&
                                        moment.unix(plan['short_term_target_start_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))
                                    }
                                </div>
                            </div>
                            <div className="pt-3 d-flex align-items-center">
                                <span className={ 'main-content-info' }>{ t('plans.short_term_target_end_at') }:</span>
                                <div className="pl-2"/>
                                <div className={ 'content ' }>
                                    { !!plan['short_term_target_end_at'] &&
                                        moment.unix(plan['short_term_target_end_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))
                                    }
                                </div>
                            </div>
                        </div>
                    </ComponentMobile>

                </div>
            </div>
        </div>
    );
}

import queryString from 'query-string';

function getKeyWordSearch(url, keywordName = 'title') {
    const urlArray = url.split('&');
    let indexOfTitleParams = urlArray.findIndex(item => item.search(`${keywordName}=`) !== -1);
    if(indexOfTitleParams === -1 ) {
        return ''
    }
    return queryString.parse(urlArray[indexOfTitleParams])[keywordName];
}

export {
    getKeyWordSearch
}

import { resources }                             from '@kiroku/api';
import { useEffect, useState }                   from 'react';
import queryString                               from 'query-string';
import { useHistory }                            from 'react-router-dom';
import { concatActivitiesLog, getGroupLogByDay } from './groupLog';

export default function useSearchLogs() {
    const history                                                     = useHistory();
    const { search, cancelAPISearch, condition, pagination, loading } = resources.useSearch('Logs');
    const [resultSearch, setResultSearch]                             = useState([]);
    const [loadingAPI, setLoadingAPI]                                 = useState(true);
    const [loadingPagination, setLoadingPagination]                   = useState(false);
    const [hasNext, setHasNext]                                       = useState(false);

    useEffect(() => {
        search({ page: 1, perPage: 15, type: 'condition' }).then((res) => {
            const resultLogs = getGroupLogByDay(res.result);
            setResultSearch(resultLogs);
            setLoadingAPI(false);
            setHasNext(res.hasNext);
        }).catch(err => {
            console.log(err);
            setLoadingAPI(false);
        });
        return () => {
            cancelAPISearch();
        };
    }, [search, history, cancelAPISearch]);

    useEffect(() => {
        const urlQuery = queryString.stringify({ ...condition }, { arrayFormat: 'comma' });
        history.push('/logs?' + urlQuery);
    }, [condition, history]);

    const loggingSearcher = (conditionLogs) => {
        setLoadingAPI(true);
        const valueSearch = {
            ...condition,
            ...conditionLogs,
            page: 1,
        };
        if (!valueSearch.keyword) {
            delete valueSearch.keyword;
        }
        if (!valueSearch.action) {
            delete valueSearch.action;
        }
        search(valueSearch).then((res) => {
            const resultLogs = getGroupLogByDay(res.result);
            setResultSearch(resultLogs);
            setHasNext(res.hasNext);
            setLoadingAPI(false);
        }).catch((error) => {
            console.log(error);
            setLoadingAPI(false);
        })
    };

    const paginationLogs = () => {
        setLoadingPagination(true);
        search({ ...condition, page: pagination.currentPage + 1 })
            .then(res => {
            const newResult = getGroupLogByDay(res.result);
            setResultSearch(concatActivitiesLog(newResult, resultSearch));
            setLoadingPagination(false);
            setHasNext(res.hasNext);
        }).catch((error) => {
            console.log(error);
            setLoadingPagination(false);
        });
    };

    return {
        loggingSearcher,
        paginationLogs,
        loadingPagination,
        resultSearch,
        loadingAPI,
        pagination,
        condition,
        loading,
        search,
        hasNext
    };
}

import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import { KirokuButton }               from '../../../ui';
import SelectTemplates                from './SelectTemplates';
import SelectCustomers                from './SelectCustomers';
import SelectDateRange                from './SelectDateRange';
import { resources }                  from '@kiroku/api';
import useReportCondition             from '../makeReport/useReportCondition';

export default function ReportCondition() {

    const { t }                                   = useTranslation();
    const [currentCondition, setCurrentCondition] = useState(
        {
            templates: [],
            customers: [],
        },
    );
    const { setCondition }                        = useReportCondition();
    const { result, search, cancelAPISearch }     = resources.useSearch('Team');

    useEffect(() => {
        search({ type: 'all' });
        return () => {
            cancelAPISearch();
        }
    }, [search, cancelAPISearch]);

    const handleOnChangeDate = (dateRange) => {
        setCurrentCondition(condition => ({
            ...condition,
            ...dateRange,
        }));
    };

    const handleOnChangeTemplate = (templates) => {
        setCurrentCondition(condition => ({
            ...condition,
            templates: templates,
        }));
    };

    const handleOnChangeCustomer = (customers) => {
        setCurrentCondition(condition => ({
            ...condition,
            customers: customers,
        }));
    };

    const handleResetCondition = () => {
        setCurrentCondition({
            startDate: null,
            endDate  : null,
            templates: [],
            customers: [],
        });
    };

    return (
        <div className='pt-0 padding-responsive'>
            <div className="pt-10">
                <SelectDateRange
                    onChangeDate={ handleOnChangeDate }
                    startDate={ currentCondition.startDate }
                    endDate={ currentCondition.endDate }
                />
                <SelectCustomers customers={ currentCondition.customers } teams={ result }
                                 onChange={ handleOnChangeCustomer }/>
                <SelectTemplates templates={ currentCondition.templates } onChange={ handleOnChangeTemplate }/>
                <div className="d-flex pt-2 justify-content-center">
                    <KirokuButton color={ 'light' }
                                  style={{fontSize: 14, height: 36 }}
                                  onClick={ handleResetCondition }
                    >
                        { t('common.Clear') }
                    </KirokuButton>
                    <div className="p-2"/>
                    <KirokuButton color={ 'primary' }
                                  style={{fontSize: 14, height: 36 }}
                                  disabled={ !currentCondition.startDate && !currentCondition.endDate && !currentCondition.templates.length && !currentCondition.customers.length }
                                  onClick={ () => setCondition({
                                      startDate : currentCondition.momentStartDate,
                                      endDate   : currentCondition.momentEndDate,
                                      templateId: currentCondition.templates.map(template => template.id),
                                      customerId: currentCondition.customers.map(customer => customer.id),
                                  }) }
                    >
                        { t('common.submitSearchAdvanced') }
                    </KirokuButton>
                </div>

            </div>
        </div>
    );
}

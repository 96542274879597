import { useCallback, useState } from "react";
import { useCustomerContext }    from "./CustomerContext";
import { CHANGE_INFO_CUSTOMER }  from "./CustomerReducer";

export const useYubinBango = () => {
    const [ newAddress, setAddress ] = useState('');
    const [ isShowing, setIsShowing ] = useState(false)
    const {infoCustomer, dispatch} = useCustomerContext();
    const {post_code, address} = infoCustomer;
    const {Core} = window.YubinBango;

    const setNewAddress = useCallback(() => {
        new Core(post_code, addr => {
            const newAddress = addr.region + addr.l + addr.m + addr.o;
            setAddress(newAddress);
            if (address && newAddress !== address) {
                setIsShowing(isShowing => !isShowing)
            } else {
                dispatch({
                    type: CHANGE_INFO_CUSTOMER,
                    name: 'address', value: newAddress,
                });
            }
        });
    }, [ dispatch, Core, address, post_code ])

    return {newAddress, isShowing, setNewAddress, setIsShowing}
}

import React, { useEffect, useState } from 'react';
import PlanTables                     from './PlanningList/PlanTables';
import usePageTitle                   from '../../ui/v3/hooks/usePageTitle';
import './planning.css';
import { useTranslation }             from 'react-i18next';
import { useCustomerDefault }         from './useCustomerDefault';
import { useProfile }                 from '../useProfile';
import KirokuLoader                   from '../../ui/KirokuLoader';

export default function Planning() {
    const { t }                                             = useTranslation();
    const [customerDefault, getCustomerDefault]             = useCustomerDefault();
    const [profile]                                         = useProfile();
    usePageTitle(t(`Layout.Planning`));
    const [loadingAPI, setLoadingAPI] = useState(true);

    useEffect(() => {
        getCustomerDefault(profile.id).then(() => {
            setLoadingAPI(false);
        });
    }, [profile.id, getCustomerDefault]);

    return (
        <div className='default-background-color'>
            <div className={ 'padding-responsive' }>
                <KirokuLoader loading={ loadingAPI }>
                    <PlanTables customerDefault={ customerDefault }/>
                </KirokuLoader>
            </div>
        </div>
    );
}

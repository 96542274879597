import React              from 'react';
import { KirokuButton }   from './index';
import { useTranslation } from 'react-i18next';

export default function ({ onClick, buttonClassName, labelClassName, color, icon, label }) {
    const { t } = useTranslation();
    return <KirokuButton onClick={ onClick }
                         className={ `${buttonClassName || 'delete-button'}` }
                         color={ `${color  || 'red'}` }
    >
        <i className={`${icon || 'far fa-trash-alt text-delete-button'}`}/>
        <span className={`${labelClassName || 'text-delete-button'}`}> {`${label ||  t('plans.delete') }`} </span>
    </KirokuButton>;
}

import React              from 'react';
import { KirokuCheckBox } from '../../../ui';
import useHeadingQuestion from '../../Records/useSurvey/useHeadingQuestion';


export default function MultiChoice(props) {

    const { question, isSurveyDetail } = props;
    const { heading, t } = useHeadingQuestion(question);

    return (<div className={'content-question-type'}>
        <div className='wraper-question none-border-question pt-2 pt-2'>
            <div className="background-question">
                {heading && <div className="text-overflow-elip font-heading-questions pb-10px">{ heading }</div> }
                <div className='padding-content-question'>
                    <span className={'font-content-question'}>
                        {question.unrequited ? t('createRecord.option'):''}{question.content}
                    </span>
                </div>
            </div>
            <div className='answer-question padding-16-answer-question answer-checkbox-size-min'>
                {question.answers.map((item, index) =>
                    <KirokuCheckBox key={index}
                                    checked={isSurveyDetail ? item.defaultAnswer : item.is_choice}
                                    id={`${index}-MultiChoice-${question.index}`}
                                    content={<span className='content-checkbox-size-min'>{item.answer}</span>}
                                    style={{ cursor: 'no-drop' }}
                                    readOnly
                    />,
                )}
            </div>

        </div>
    </div>);
}

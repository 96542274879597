import React, { useEffect, useState }               from 'react';
import { KirokuResetFilterCondition, KirokuButton } from '../../../ui';
import { useTranslation }                           from 'react-i18next';

export default function ModalFilterUser(props) {
    const { t }                   = useTranslation();
    const [selected, setSelected] = useState('');
    const [status, setStatus]     = useState('');
    const { condition }           = props;

    useEffect(() => {
        if (!condition.statusUser) {
            setSelected('');
            setStatus(t(`common.WorkingForm`));
        }
        if (condition.statusUser === 'full-time') {
            setSelected('full-time');
            setStatus(t('common.Full_time'));
        }
        if (condition.statusUser === 'part-time') {
            setSelected('part-time');
            setStatus(t('common.Part_time'));
        }
    }, [condition, t]);

    const dropdownItems = [
        {
            value    : 'full-time',
            component: (
                <div>{ t('common.Full_time') }</div>
            ),
            label    : t('common.Full_time'),
        },
        {
            value    : 'part-time',
            component: (
                <div>{ t('common.Part_time') }</div>
            ),
            label    : t('common.Part_time'),
        }];

    const handleSubmit = () => {
        props.searchAfterFilter(selected);
        props.closeModal();
    };

    return (
        <div hidden={ !props.show }>
            <div className="padding-responsive">
                <div className={ 'view-1vh' }>
                    <div className='survey-list'>
                        <div className='padding-responsive'>
                            <button className='close' style={ { float: 'left' } }
                                    onClick={ props.closeModal }>
                                <span style={ {
                                    fontSize: '24px',
                                    color   : '#a8aab7',
                                } }>&times;</span>
                            </button>
                            <div className='text-center'>
                                <span className='filter-record'>{ t('common.FilterStaff') }</span>
                            </div>
                        </div>
                        <div className='p-2 mt-4 filter-modal'>
                            <div>
                                <KirokuResetFilterCondition
                                    title={ status }
                                    defaultValue={ t(`common.WorkingForm`) }
                                    items={ dropdownItems }
                                    icon={ 'far fa-file-alt text-secondary' }
                                    onSelect={ (event) => {
                                        setSelected(event.kirokuSelected.value);
                                        setStatus(event.kirokuSelected.label);
                                    } }
                                />
                            </div>
                            <div className='pt-3'>
                                <div className='fix-center'>
                                    <div className='d-flex justify-content-between'>
                                        <KirokuButton color={ 'white' }
                                                      onClick={ props.closeModal }>{ t('common.Cancel') }</KirokuButton>
                                        <div className='pl-1 pr-1'/>
                                        <KirokuButton color={ 'primary' }
                                                      onClick={ handleSubmit }>{ t('common.Apply') }</KirokuButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

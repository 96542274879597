import React                 from 'react';
import { useTranslation }    from 'react-i18next';
import moment                from 'moment';
import {useProfile}          from "../../useProfile";

export default function PlanningInfo({ plan }) {

    const { t }  = useTranslation();
    const [user] = useProfile()

    return (
        <div className={'planning-info'}>
            <div className={'pt-3'} style={{ borderBottom: 'solid 1px #dfe2e5' }}>
                <div className="mb-15px">
                    <div className={'row margin-none'}>
                        <div className={'col-6 col-xs-6 col-sm-5 col-md-4 col-lg-4'}>
                            <span className={'label-info'}>{ t('plans.organizationName') }:</span><br/>
                            <div className={'pt-10'}>
                                <span className={'main-content-info'}>
                                    {plan.org_name || user.organization_name}
                                </span>
                            </div>
                        </div>
                        <div className={'col-6 col-xs-6 col-sm-6 col-md-6 col-lg-5'}>
                            <span className={'label-info'}>{ t('plans.divisionName') }:</span><br/>
                            <div className={'pt-10'}>
                                <span className={'main-content-info'}>
                                    {plan.division_name }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'pt-3'} style={{ borderBottom: 'solid 1px #dfe2e5' }}>
                <div className="mb-15px">
                    <div className={'row margin-none'}>
                        <div className={'col-6 col-xs-6 col-sm-5 col-md-4 col-lg-4'}>
                            <span className={'label-info'}>{t('plans.startDate')}:</span><br/>
                            <div className={'pt-10'}>
                                {!!plan[ 'start_at' ] && <span className={'main-content-info'}>
                                    {moment.unix(plan['start_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                </span>}
                            </div>
                        </div>
                        <div className={'col-6 col-xs-6 col-sm-5 col-md-4 col-lg-4'}>
                            <span className={'label-info'}>{t('plans.endDate')}:</span><br/>
                            <div className={'pt-10'}>
                                {!!plan[ 'end_at' ] && <span className={'main-content-info'}>
                                    {moment.unix(plan['end_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                </span>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 pb-15px">
                    <span className={'label-info'}>{t('plans.assistancePolicy')}:</span><br/>
                    <div className={'main-content-info pt-10 break-word-and-pre-wrap'}
                         style={{wordBreak: 'break-word'}}
                    >{plan[ 'overview' ]}</div>
                </div>
            </div>

            <div className={'pt-15px'} style={{ borderBottom: 'solid 1px #dfe2e5' }}>
                <div className={"col-6 col-6 main-content-info mb-15px"}>{t('plans.longTermTarget')}</div>
                <div className="mb-15px">
                    <div className="row margin-none">
                        <div className={'col-6 col-xs-6 col-sm-5 col-md-4 col-lg-4'}>
                            <span className={'label-info'}>{t('plans.long_term_target_start_at')}:</span><br/>
                            <div className={'pt-10'}>
                                {!!plan[ 'long_term_target_start_at' ] && <span className={'main-content-info'}>
                                    {moment.unix(plan['long_term_target_start_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                </span>}
                            </div>

                        </div>
                        <div className={'col-6 col-xs-6 col-sm-5 col-md-4 col-lg-4'}>
                            <span className={'label-info'}>{t('plans.long_term_target_end_at')}:</span><br/>
                            <div className={'pt-10'}>
                                {!!plan[ 'long_term_target_end_at' ] && <span className={'main-content-info'}>
                                    {moment.unix(plan['long_term_target_end_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                </span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-12 pb-15px'}>
                    <span className={'label-info'}>{t('plans.targetTerm')}:</span><br/>
                    <div className={'main-content-info pt-10 break-word-and-pre-wrap'}>{plan[ 'long_term_target' ]} </div>
                </div>
            </div>

            <div className={'pt-15px'} style={{ borderBottom: 'solid 1px #dfe2e5' }}>
                <div className={"col-6 mb-15px main-content-info"}>{t('plans.shortTermTarget')}</div>
                <div className="mb-15px">
                    <div className="row margin-none">
                        <div className={'col-6 col-xs-6 col-sm-5 col-md-4 col-lg-4'}>
                            <span className={'label-info'}>{t('plans.short_term_target_start_at')}:</span><br/>
                            <div className={'pt-10'}>
                                {!!plan[ 'short_term_target_start_at' ] && <span className={'main-content-info'}>
                                    {moment.unix(plan['short_term_target_start_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                </span>}
                            </div>

                        </div>
                        <div className={'col-6 col-xs-6 col-sm-5 col-md-4 col-lg-4'}>
                            <span className={'label-info'}>{t('plans.short_term_target_end_at')}:</span><br/>
                            <div className={'pt-10'}>
                                {!!plan[ 'short_term_target_end_at' ] && <span className={'main-content-info'}>
                                    {moment.unix(plan['short_term_target_end_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                </span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-12 pb-15px'}>
                    <span className={'label-info'}>{t('plans.targetTerm')}:</span><br/>
                    <div className={'main-content-info pt-10 break-word-and-pre-wrap'}>{plan[ 'short_term_target' ]}</div>
                </div>
            </div>
            <div className={'pt-15px'} style={{ borderBottom: 'solid 1px #dfe2e5' }}>
                <div className={"col-6 mb-15px main-content-info"}>{t('plans.offerTime')}</div>
                <div className={'col-12 pb-15px'}>
                    <span className={'label-info'}>{t('plans.standardHoursOfSupportProvision')}:</span><br/>
                    <div className={'main-content-info pt-10 break-word-and-pre-wrap'}>{plan[ 'standard_hours_of_support_provision' ]}</div>
                </div>
            </div>
            <div hidden={!plan.published_at}>
                <div className={'pt-15px row margin-none'} style={{ borderBottom: 'solid 1px #dfe2e5' }}>
                    <div className={'col-lg-8'}>
                        <span className={'label-info'}>{t('plans.published_at')}:</span><br/>
                        <div className={'pt-10 pb-15px'}>
                            {!!plan[ 'published_at' ] && <span className={'main-content-info'}>
                                    {moment.unix(plan['published_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                </span>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useState }     from 'react';
import If                      from '../../../If';
import moment                  from 'moment';
import DifferenceCustomerModal from './DifferenceCustomerModal';
import { useTranslation }      from 'react-i18next';
import useDropDownData         from '../../../useDropDownData';
import useUser                 from '../../../LazyLoad/useUser';
import KirokuLoader            from '../../../../ui/KirokuLoader';

export default function ({ historyItem, index, history, customer }) {
    const { t }           = useTranslation();
    const { labelAction } = useDropDownData();
    const {user, loading} = useUser(historyItem.user_id);

    const [modalDifference, setIsShowModalDifference] = useState({
        isShow: false,
    });
    return (
        <div>
            <KirokuLoader loading={loading}>
            {historyItem && user ?
            <div className="d-flex align-items-center">
                <div className="mr-1">
                    <div className='avatar' style={ {
                        width             : 40,
                        height            : 40,
                        borderRadius      : '100%',
                        backgroundImage   : `url(${user.avatar ? user.avatar : '/assets/img/profile-ic.svg' })`,
                        backgroundSize    : 'cover',
                        backgroundPosition: 'center center',
                    } }
                    />
                </div>
                <div className={ 'd-flex flex-wrap-wrap' }>
                    <span className="pr-2">{ labelAction[historyItem.action] }</span>
                    <b className='f-bold pr-2 font-text-card-detail'>{ user.username }</b>
                    <If statement={ historyItem.action === 'update' && index !== history.length - 1 }>
                        { () => <React.Fragment>
                                            <span className="text-link"
                                                  onClick={ () => setIsShowModalDifference({
                                                      isShow: true,
                                                      logId : historyItem.id,
                                                  }) }
                                            >
                                                { t(`logs.${ historyItem.action }${ historyItem.type }`) }
                                            </span>
                        </React.Fragment>
                        }
                    </If>
                    <span className='date-log pl-2'>
                                       <span className={ 'font-text-card-detail' }>
                                           { moment.unix(historyItem.create_at).format(t('dateTimeFormat.YearMonthDateWithHour')) }
                                       </span>
                                    </span>
                    <p className='mb-1 font-text-card-detail'>
                        { history['type_name'] ? <b>{ historyItem['type_name'] }</b> : '' }
                    </p>
                    <p>
                        <b className='f-bold font-text-card-detail'>{ user.username }</b>
                    </p>
                </div>
                <DifferenceCustomerModal
                    isShow={modalDifference.isShow}
                    logId={modalDifference.logId}
                    closeModal={() => setIsShowModalDifference({ isShow: false })}
                    customer={customer}
                />
            </div> : '' }
            </KirokuLoader>
        </div>
    );
}

import { useCallback, useEffect, useRef, useState } from 'react';
import React                                        from 'react';
import KirokuDropdownItem                           from './KirokuDropdownItem';
import ReactDom                                     from 'react-dom';


export default function KirokuDropdownResetLabelSelected(props) {

    const {
              title, className, disabled,
              style, hidden, location, items,
              onSelect, isControllerLabel
          } = props;

    const [popupVisible, setPopupVisible]   = useState(false);
    const [selectedLabel, setSelectedLabel] = useState(title);
    const refDropDown                       = useRef(null);

    const handleClick = useCallback((event) => {
        if (event.target === refDropDown.current) {
            setPopupVisible(!popupVisible);
        } else {
            setPopupVisible(false);
        }
    }, [refDropDown, popupVisible]);

    useEffect(() => {
        setSelectedLabel(title);
    }, [title]);

    useEffect(() => {
        const currentControllerElement = ReactDom.findDOMNode(refDropDown.current);

        currentControllerElement.addEventListener('click', handleClick);
        document.addEventListener('click', handleClick);

        return () => {
            currentControllerElement.removeEventListener('click', handleClick);

            document.removeEventListener('click', handleClick);
        };

    }, [handleClick, refDropDown]);

    const handleSelect = (event) => {
        onSelect(event);
        if (isControllerLabel) {
            setSelectedLabel(event.kirokuSelected.label);
        }
    };

    return (
        <div className={`dropdown ${className ? className : ''}`}
        >
            <button hidden={hidden}
                    ref={refDropDown}
                    disabled={disabled}
                    style={style}
                    className={`overflow btn btn-kiroku ${className ? className : ''}`}

            >
                <span className={'text-kiroku left'}>{selectedLabel}</span>
                <i className={`${!popupVisible ? 'fa fa-chevron-down icon-kiroku' : 'fa fa-chevron-up icon-kiroku'}`}/>
            </button>
            {popupVisible && (
                <div className={`dropdown-kiroku dropdown-menu show ${location ? location : ''}`}
                     style={{ position: 'absolute', willChange: 'top, left', left: 'auto', top: 'auto' }}>
                    <section className='scroll-data-dropdown-search'>
                        {items.map((item, index) => {
                            const Item = item.component;
                            return (
                                <KirokuDropdownItem
                                    key={index}
                                    onClick={(event) => {
                                        event.kirokuSelected = item;
                                        handleSelect(event);
                                    }}
                                    style={item.style}
                                >
                                    {Item}
                                </KirokuDropdownItem>
                            );
                        })}
                    </section>
                </div>
            )}
        </div>
    );
}

import React              from 'react';
import { useTranslation } from 'react-i18next';

export default function SortBy({condition, search}) {

    const { t } = useTranslation();

    return <div className="d-flex justify-content-around border-btn-status-group"
                style={{height: 36}}
    >
        <div className={`border-width-each-status first-btn font-text-on-btn font-size-14 cursor-pointer
                          ${condition.order_by === 'update_at' ? 'btn-active' : ''}`}
             style={{height: 36}}
             onClick={() => search({ ...condition, order_by: 'update_at', order_type: 'DESC', page: 1 })}
        >
            {t('Surveys.updateOrder')}
        </div>
        <div className={`border-width-each-status third-btn font-text-on-btn font-size-14 cursor-pointer
                        ${condition.order_by === 'create_at' ? 'btn-active' : ''}` }
             style={{height: 36}}
             onClick={() => search({ ...condition, order_by: 'create_at', order_type: 'DESC', page: 1 })}
        >
            {t('Surveys.createOrder')}
        </div>
    </div>;
}

import React, { useState }           from 'react';
import { useTranslation }            from 'react-i18next';
import { KirokuRadio, KirokuButton } from '../../../ui';
import { resources }                 from '@kiroku/api';
import momentTZ                      from 'moment-timezone';
import config                        from '../../../config';
import queryString             from 'query-string';
import { useMixpanelTracking } from '../../../Mixpanel/useMixpanelTracking';

export default function ModalSelectTypeExportDocx({ show, closeModal, records, notification }) {
    const { t }                           = useTranslation();
    const [type, setType]                 = useState('TemplateRecord');
    const [requestExport, docxAPILoading] = resources.useExportDocx('ExportDocx');
    const { trackingMixpanel }            = useMixpanelTracking();

    const handleCancelClick = () => {
        closeModal();
        setType('TemplateRecord');
    };

    const handleClickReport = () => {
        const qr       = {
            lang    : localStorage.getItem('i18nextLng'),
            timezone: momentTZ.tz.guess(),
            token   : localStorage.getItem('token'),
            records : records.map(record => record.id),
        };
        const urlQuery = queryString.stringify(qr, { arrayFormat: 'comma' });
        window.open(
            `${config.kirokuAPI.url}/v${config.kirokuAPI.version}/report/ids/${type}?${urlQuery}`, 'MsgWindow', '_blank',
        );
        closeModal();
        setType('TemplateRecord');
    };

    const handleExportToWord = () => {
        if (records.length > 100) {
            notification({ message: t('message.overReportDocX'), type: 'danger', show: true });
        } else {
            trackingMixpanel('EXPORT_DOCX', {records, lang: localStorage.getItem('i18nextLng'), timezone: momentTZ.tz.guess()})
            requestExport(`/ids/${type}`,
                { records: records.map(record => record.id) },
                { lang: localStorage.getItem('i18nextLng'), timezone: momentTZ.tz.guess() },
            ).then(response => {
                let linkDownload = document.getElementById('downloadFile');
                linkDownload.setAttribute('href', response.url);
                linkDownload.click();
            });
        }
    };

    const handleCheckType = (type) => {
        setType(type);
    };


    return (
        <div hidden={!show} className='padding-responsive'>
            <div className={'view-1vh'}>
                <div className='survey-list'>
                    <div className='p-3'>
                        <button className='close' style={{ float: 'left' }}
                                onClick={() => handleCancelClick()}>
                                <span style={{
                                    fontSize: '24px',
                                    color   : '#a8aab7',
                                }}>&times;</span>
                        </button>
                        <div className='text-center'>
                            <span className='filter-record'>{t('common.ReportFilter')}</span>
                        </div>
                    </div>
                    <div className='p-2 mt-4 print-modal'>
                        <div className='wrap-print'>
                            <div className="print-type row">
                                <div className="col-md-6">
                                    <KirokuRadio
                                        checked={type === 'TemplateRecord'}
                                        id={'print-type-template-record'} name={'print-type'}
                                        onChange={() => handleCheckType('TemplateRecord')}>
                                        {t('Report.TemplateRecord')}
                                    </KirokuRadio>
                                    <KirokuRadio
                                        checked={type === 'TemplateQuestion'}
                                        id={'print-type-template-question'} name={'print-type'}
                                        onChange={() => handleCheckType('TemplateQuestion')}>
                                        {t('Report.TemplateQuestion')}
                                    </KirokuRadio>
                                    <KirokuRadio
                                        checked={type === 'TemplateOnly'}
                                        id={'print-type-template'} name={'print-type'}
                                        onChange={() => handleCheckType('TemplateOnly')}>
                                        {t('Report.TemplateOnly')}
                                    </KirokuRadio>
                                </div>
                                <div className="col-md-6">
                                    <KirokuRadio
                                        checked={type === 'CustomerRecord'}
                                        id={'print-type-customer-record'} name={'print-type'}
                                        onChange={() => handleCheckType('CustomerRecord')}>
                                        {t('Report.CustomerRecord')}
                                    </KirokuRadio>
                                    <KirokuRadio
                                        checked={type === 'CustomerQuestion'}
                                        id={'print-type-customer-question'} name={'print-type'}
                                        onChange={() => handleCheckType('CustomerQuestion')}>
                                        {t('Report.CustomerQuestion')}
                                    </KirokuRadio>
                                    <KirokuRadio
                                        checked={type === 'CustomerOnly'}
                                        id={'print-type-customer'} name={'print-type'}
                                        onChange={() => handleCheckType('CustomerOnly')}>
                                        {t('Report.CustomerOnly')}
                                    </KirokuRadio>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pt-4'>
                    <div className='pr-5 pl-5 fix-center'>
                        <div className='d-flex justify-content-between'>
                            <KirokuButton color={'white'}
                                          onClick={() => handleCancelClick()}>{t('common.Cancel')}</KirokuButton>
                            <div className={'pr-1 pl-1'}/>
                            <KirokuButton color={'primary'}
                                          onClick={() => handleClickReport()}>{t('Report.Show')}</KirokuButton>
                            <div className={'pr-1 pl-1'}/>
                            <KirokuButton color={'primary'}
                                          disabled={docxAPILoading || !type}
                                          onClick={() => handleExportToWord()}>{t('Report.ExportToWord')}</KirokuButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useEffect, useState }                                                             from "react";
import moment                                                                                     from "moment";
import { useTranslation }                                                                         from "react-i18next";
import { ComponentMobile, ComponentWeb, KirokuDropdownClose, KirokuInputDate, KirokuMultiSelect } from "../../../../ui";
import useCustomerInformation
                                                                                                  from "./useCustomerInformation";
import { useClassifications }                                                                     from "./useClassifications";

function StartDateOfContact() {

    const { contract_start, contract_end, setContractStartCustomer } = useCustomerInformation();
    const { t } = useTranslation();
    const [ validate, setValidation ] = useState(true);
    const [ errorStartDate, setError ] = useState(true);
    const [ startDateString, setStartDateString] = useState('')

    const onChangeDate = (date) => {
        if(!date) {
            setContractStartCustomer({
                contract_start: null,
                isValidContractStartDate: !contract_end
            })
            setValidation(true);
            setError(false);
            setStartDateString('')
        } else  {
            if (moment(date, t('dateTimeFormat.YearMonthDate'), true).isValid()) {
                const StartDate = moment(date).startOf('day').unix();
                if(contract_end) {
                    setError(  contract_end > StartDate);
                }
                setContractStartCustomer({
                    contract_start: StartDate,
                    isValidContractStartDate: true
                })
                setValidation(true);
            } else {
                setValidation(false)
                setError(true)
                setStartDateString(date)
                setContractStartCustomer({
                    contract_start: null,
                    isValidContractStartDate: false
                })
            }
        }
    }

    useEffect(() => {
        if(!contract_end && !contract_end) {
            setError(true)
        }
        if(contract_end && !contract_start && validate) {
            setError(false)
        }
        if (contract_end && contract_start && contract_end >= contract_start) {
            setError(true);
        }
    }, [ contract_start, contract_end, validate ])


    const getValueDateInput = () => {
        if(contract_start) {
            return moment.unix(contract_start).format(t('dateTimeFormat.YearMonthDate'))
        } else if(validate) {
            return ''
        } else {
            return startDateString
        }
    }

    return (
        <>
            <label className='mt-1 mb-10'>{t('CustomerEditor.StartDateOfContact')}</label>
            <KirokuInputDate
                inputProps={{ value: getValueDateInput() }}
                name='contract_start'
                onChange={onChangeDate}
                dateFormat={t('dateTimeFormat.YearMonthDate')}
            />
            <p hidden={errorStartDate} style={{
                color: 'red',
                fontSize: 12
            }}>{t('CustomerEditor.validate.ErrorStartDateContact')}</p>
            <p hidden={validate} style={{
                color: 'red',
                fontSize: 12
            }}>{t('CustomerEditor.validate.ValidDate')}</p>

        </>
    )
}

function SupportClassification() {

    const { classification, setInfoCustomer } = useCustomerInformation();
    const [ listClassifications ] = useClassifications();
    const { t } = useTranslation();

    return (
        <>
            <label className='mt-1 mb-10'>{t('CustomerEditor.SupportClassification')}</label>
            <KirokuDropdownClose
                title={classification === 0 ? t('CustomerEditor.NotApplicable') : classification}
                items={listClassifications}
                onSelect={e => {
                    setInfoCustomer('classification', e.kirokuSelected.value)
                }}
            />
        </>
    )
}

function EndDateOfContact() {

    const { contract_end, contract_start, setContractEndCustomer, setCustomer } = useCustomerInformation();
    const [ validate, setValidation ] = useState(true);
    const [ errorStartDate, setError ] = useState(true);
    const { t } = useTranslation();
    const [ startDateString, setStartDateString] = useState('')

    const onChangeDate = (date) => {
        if(!date) {
            setCustomer({
                contract_end: null,
                isValidContractEndDate: true,
                isValidContractStartDate: true
            })
            setValidation(true);
            setError(true);
            setStartDateString('')
        } else  {
            if (moment(date, t('dateTimeFormat.YearMonthDate'), true).isValid()) {
                const endDate = moment(date).endOf('day').unix();
                if(contract_start) {
                    setError(endDate > contract_start);
                }
                setCustomer({
                    contract_end: endDate,
                    isValidContractEndDate: true,
                    isValidContractStartDate: !!contract_start
                })
                setValidation(true);
            } else {
                setValidation(false)
                setError(true)
                setStartDateString(date)
                setContractEndCustomer({
                    contract_end: null,
                    isValidContractEndDate: false
                })
            }
        }
    }


    const getValueDateInput = () => {
        if(contract_end) {
            return moment.unix(contract_end).format(t('dateTimeFormat.YearMonthDate'))
        } else if(validate) {
            return ''
        } else {
            return startDateString
        }
    }

    useEffect(() => {
        if (contract_end && contract_start && contract_end >= contract_start) {
            setError(true);
        }
    }, [ contract_start, contract_end ])

    return (
        <>
            <label className="mt-1 mb-10">{t('CustomerEditor.EndDateOfContact')}</label>
            <KirokuInputDate
                name={t('CustomerEditor.EndDateOfContact')}
                inputProps={{ value: getValueDateInput() }}
                onChange={onChangeDate}
                dateFormat={t('dateTimeFormat.YearMonthDate')}
            />
            <p hidden={errorStartDate} style={{
                color: 'red',
                fontSize: 12
            }}>{t('CustomerEditor.validate.ErrorStartDateCertification')}</p>
            <p hidden={validate} style={{
                color: 'red',
                fontSize: 12
            }}>{t('CustomerEditor.validate.ValidDate')}</p>
        </>
    )
}

function KindOfCertificate() {

    const { t } = useTranslation();
    const { buildCertificate, setInfoCustomer } = useCustomerInformation();
    const [ currentCertificate, setCurrentCertificate ] = useState(buildCertificate);

    const KindOfCertificateOptions = [
        {
            key: t('CustomerEditor.certificate.intellectual'),
            label: t('CustomerEditor.intellectual'),
            value: t('CustomerEditor.intellectual'),
        },
        {
            key: t('CustomerEditor.certificate.physical'),
            value: t('CustomerEditor.physical'),
            label: t('CustomerEditor.physical'),
        },
        {
            key: t('CustomerEditor.certificate.mental'),
            label: t('CustomerEditor.mental'),
            value: t('CustomerEditor.mental'),
        },
        {
            key: t('CustomerEditor.certificate.elderly'),
            label: t('CustomerEditor.elderly'),
            value: t('CustomerEditor.elderly'),
        },
        {
            key: t('CustomerEditor.certificate.other'),
            label: t('CustomerEditor.other'),
            value: t('CustomerEditor.other'),
        },
    ];

    return (
        <>
            <label className="mt-1 mb-10">{t('CustomerEditor.KindOfCertificate')}</label>
            <KirokuMultiSelect
                value={currentCertificate}
                options={KindOfCertificateOptions}
                onChange={options => {
                    setCurrentCertificate(options || []);
                    setInfoCustomer('certificate', options.map(options => options.key))
                }}
            />
        </>
    )
}

export default function CustomerInformation() {

    const { t } = useTranslation();

    return (
        <div>
            <h5 className={'card-title-info'}>{t('CustomerEditor.CustomerInformation')}</h5>
            <ComponentWeb>
                <div className='row'>
                    <div className='col-md-4'>
                        <StartDateOfContact/>
                        <div className="pt-15px"/>
                        <SupportClassification/>
                    </div>
                    <div className="col-md-4">
                        <EndDateOfContact/>
                        <div className="pt-15px"/>
                        <KindOfCertificate/>
                    </div>
                    <div className="col-md-4"/>
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <StartDateOfContact/>
                <div className="pt-15px"/>
                <EndDateOfContact/>
                <div className="pt-15px"/>
                <SupportClassification/>
                <div className="pt-15px"/>
                <KindOfCertificate/>
            </ComponentMobile>
        </div>
    )
}

import { getFlowQuestions } from '../HelperComponent/getQuestions';

export default function useInputTextChart(records, question) {

    let questions = records.map(record => getFlowQuestions(record.survey_template).filter(q => q.content === question.content && q.type === question.type));
    questions     = questions.filter(question => !!question[0]);
    questions     = questions.map(question => question[0]);

    const analysisQuestion = questions.map(question => {
        if (question['score']) {
            const score     = question['score'];
            const magnitude = roundToTwo(score['magnitude']);
            return ['', magnitude, colorByScore(score['score']), `${ question.answers } - ${ magnitude }`, null];
        }
        return null;
    });

    return {
        analysisQuestion: analysisQuestion.filter(item => item != null),
        isNotDataAnalysis: questions.filter(question => !question.answers).length === questions.length
    };
}

function colorByScore(score) {
    if (score > 0.25) {
        return 'green';
    }
    if (score < -0.25) {
        return 'red';
    }
    return '#FF9900';
}

function roundToTwo(number) {
    return +(Math.round(number + 'e+2') + 'e-2');
}

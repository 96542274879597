import React, { useEffect, useState }          from 'react';
import {
    ComponentMobile, ComponentWeb, KirokuButton, KirokuCard, KirokuCardList, KirokuCardWeb, KirokuInputSearcher,
    KirokuLoader, KirokuPagination, KirokuPopoverItem, KirokuTable, SearchNotFound, TBody, THeader,
}                                              from '../../../ui';
import { withPermissions, WrapperPermissions } from '../../HOC';
import ThPermission                            from '../../HOC/ThPermission';
import { Link, useHistory }                    from 'react-router-dom';
import TdPermission                            from '../../HOC/TdPermission';
import LinkPermission                          from '../../HOC/LinkPermission';
import { resources }                           from '@kiroku/api';
import { useTranslation }                      from 'react-i18next';
import usePageTitle                            from '../../../ui/v3/hooks/usePageTitle';
import queryString                             from 'query-string';
import useConditionTagParams                   from '../../Customer/CustomerList/useConditionFromParams';
import { ActionToggle }                        from '../../Customer/CustomerList/BulkActionCustomer';
import If                                      from '../../If';
import { getKeyWordSearch }                    from '../../ServiceSearch';

const LinkButton           = withPermissions(LinkPermission);
const Th                   = withPermissions(ThPermission);
const Td                   = withPermissions(TdPermission);
const ButtonWithPermission = withPermissions(KirokuButton);

export default function TeamListScreen() {
    const { t }                             = useTranslation();
    const history                           = useHistory();
    const {
              search, pagination, condition, cancelAPISearch, result,
          }                                 = resources.useSearch('Team', { type: 'condition', page: 1 });
    const { conditionFormParams }           = useConditionTagParams();
    const [loadingAPI, setLoadingAPI]       = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [teams, setTeams]                 = useState([]);

    useEffect(() => {
        try {
            const conditionUrl = queryString.parse(history.location.search.replace('?', ''), { arrayFormat: 'comma' });
            if (conditionUrl.type) {
                if(conditionUrl.name) {
                    conditionUrl.name = getKeyWordSearch(history.location.search.replace('?', ''), 'name')
                }
                search(conditionUrl).then(() => {
                    setLoadingAPI(false);
                });
            } else {
                search({ type: 'condition', page: 1 }).then(() => {
                    setLoadingAPI(false);
                }).catch(error => {
                    console.log(error);
                    setLoadingAPI(false);
                });
            }
        } catch (e) {
            search({ type: 'condition', page: 1 }).then(() => {
                setLoadingAPI(false);
            });
        }
        return () => {
            cancelAPISearch();
        };
    }, [search, history, cancelAPISearch]);

    useEffect(() => {
        const urlQuery = queryString.stringify({ ...condition }, { arrayFormat: 'comma' });
        history.replace('/teams?' + urlQuery);
    }, [condition, history]);

    usePageTitle(t(`Layout.Team`));

    useEffect(() => {
        if (result.length) {
            setTeams(result);
        }
    }, [result]);

    const [keyword, setKeywordSearch] = useState(conditionFormParams.keywords);
    return (
        <div className="padding-responsive">
            <ComponentWeb>
                <KirokuLoader loading={ loadingAPI }>
                    <div className={ `pt-12 ${ teams.length ? 'd-flex justify-content-flex-end' : 'd-none' }` }>
                        <div/>
                        <div>
                            <KirokuPagination
                                className={ 'pb-2' }
                                disabledPrev={ pagination.currentPage === 1 }
                                disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                                increasePage={ () => {
                                    if(loadingSearch) {
                                        return null;
                                    }
                                    setLoadingSearch(true);
                                    search({...condition, page : pagination.currentPage + 1}).then(() => {
                                        setLoadingSearch(false);
                                    })
                                } }
                                decreasePage={ () => {
                                    if(loadingSearch) {
                                        return null;
                                    }
                                    setLoadingSearch(true);
                                    search({...condition, page : pagination.currentPage - 1}).then(() => {
                                        setLoadingSearch(false);
                                    })
                                } }
                                currentPage={ pagination.currentPage }
                                totalPage={ pagination.totalPage }
                            />
                        </div>
                    </div>
                    <KirokuCardWeb>
                        <div className='d-flex justify-content-between p-3'>
                            <h5 className={ 'card-title-info' }>{ t('Team.TeamsList') }</h5>
                            <KirokuInputSearcher
                                value={ keyword }
                                onSubmit={ () => {
                                    let newCondition = {
                                        ...condition,
                                        name: keyword,
                                        page: 1,
                                    };
                                    if (!newCondition.name) {
                                        delete newCondition.name;
                                    }
                                    setLoadingSearch(true);
                                    search(newCondition).then(res => {
                                        setLoadingSearch(false);
                                        setTeams(res.result);
                                    });
                                } }
                                onChange={ (e) => setKeywordSearch(e.target.value) }
                                id={ 'inputSearchWebTeam' }
                            />
                        </div>
                        <KirokuLoader loading={ loadingSearch }>
                            <If
                                statement={ teams.length }
                                otherwise={ <SearchNotFound/> }
                            >
                                { () => <KirokuTable>
                                    <THeader>
                                        <tr>
                                            <th>{ t('common.Name') }</th>
                                            <th>{ t('common.Description') }</th>
                                            <th style={ { minWidth: 100 } }>{ t('common.Staffs') }</th>
                                            <Th resource-permission={ '|teamList|table-th|' }
                                                className='text-center'
                                                style={ { minWidth: 250 } }>
                                                { t('common.Action') }
                                            </Th>
                                        </tr>
                                    </THeader>
                                    <TBody>
                                        { teams.map(team =>
                                            <tr key={ team.id }>
                                                <td>
                                                    <Link to={{
                                                        pathname: `/teams/${ team.id }`,
                                                        state : {
                                                            previousPage : 'teamList'
                                                        }
                                                    }}>{ team.name }</Link>
                                                </td>
                                                <td>{ team.description }</td>
                                                <td>{ team.users.length }</td>
                                                <Td className='fix-center'
                                                    resource-permission={ '|teamList|table-td|' }>
                                                    <div className='d-flex justify-content-between p-1 fix-icon-align'>
                                                        <LinkButton
                                                            resource-permission={ '|teamList|button-edit|' }
                                                            href={ `/teams/edit/${ team.id }` }
                                                            icon={ <i className="fas fa-pencil-alt text-secondary"/> }
                                                            text={ t('common.Edit') }
                                                            color={ 'text-dark' }
                                                        />
                                                    </div>
                                                </Td>
                                            </tr>,
                                        ) }
                                    </TBody>
                                </KirokuTable> }
                            </If>
                        </KirokuLoader>
                    </KirokuCardWeb>
                    <div className={ `pt-3 ${ teams.length ? 'd-flex justify-content-flex-end' : 'd-none' }` }>
                        <div/>
                        <div>
                            <KirokuPagination
                                disabledPrev={ pagination.currentPage === 1 }
                                disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                                increasePage={ () => {
                                    if(loadingSearch) {
                                        return null;
                                    }
                                    setLoadingSearch(true);
                                    search({...condition, page : pagination.currentPage + 1}).then(() => {
                                        setLoadingSearch(false);
                                    })
                                } }
                                decreasePage={ () => {
                                    if(loadingSearch) {
                                        return null;
                                    }
                                    setLoadingSearch(true);
                                    search({...condition, page : pagination.currentPage - 1}).then(() => {
                                        setLoadingSearch(false);
                                    })
                                } }
                                currentPage={ pagination.currentPage }
                                totalPage={ pagination.totalPage }
                            />
                        </div>
                    </div>
                </KirokuLoader>
            </ComponentWeb>

            <ComponentMobile>
                <KirokuLoader loading={ loadingAPI }>
                    <div className='d-flex justify-content-between pb-3'>
                        <h5 className='mt-1'>{ t('Team.TeamsList') }</h5>
                        <Link to={ '/teams/create' }>
                            <ButtonWithPermission color='primary'
                                                  resource-permission={ '|teamList|button-new|' }
                            >
                                { t('Team.AddTeam') }
                            </ButtonWithPermission>
                        </Link>
                    </div>
                    <div className={ 'pb-2' }>
                        <KirokuInputSearcher
                            value={ keyword }
                            onSubmit={ () => {
                                let newCondition = {
                                    ...condition,
                                    name: keyword,
                                    page: 1,
                                };
                                if (!newCondition.name) {
                                    delete newCondition.name;
                                }
                                setLoadingSearch(true);
                                search(newCondition).then(res => {
                                    setLoadingSearch(false);
                                    setTeams(res.result);
                                });
                            } }
                            onChange={ (e) => setKeywordSearch(e.target.value) }
                            id={ 'inputSearchMobileTeam' }
                        />
                    </div>
                    <If statement={ teams.length }>
                        { () => <div className={ 'd-flex justify-content-flex-end' }>
                            <KirokuPagination
                                disabledPrev={ pagination.currentPage === 1 }
                                disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                                increasePage={ () => {
                                    if(loadingSearch) {
                                        return null;
                                    }
                                    setLoadingSearch(true);
                                    search({...condition, page : pagination.currentPage + 1}).then(() => {
                                        setLoadingSearch(false);
                                    })
                                } }
                                decreasePage={ () => {
                                    if(loadingSearch) {
                                        return null;
                                    }
                                    setLoadingSearch(true);
                                    search({...condition, page : pagination.currentPage - 1}).then(() => {
                                        setLoadingSearch(false);
                                    })
                                } }
                                currentPage={ pagination.currentPage }
                                totalPage={ pagination.totalPage }
                            />
                        </div>
                        }
                    </If>
                    <div>
                        <KirokuCardList
                            headerLeft={ <span className='customer-name'>{ t('Team.Team') }</span> }
                            headerRight={ <WrapperPermissions resource-permission={ '|teamList|table-td|' }
                                                              className='customer-name'>
                                <span>{ t('common.Action') }</span>
                            </WrapperPermissions> }
                        >
                            <KirokuLoader loading={ loadingSearch }>

                                <If
                                    statement={ teams.length }
                                    otherwise={ <SearchNotFound/> }
                                 >
                                    { () => teams.map((team, index) => {
                                        return <KirokuCard
                                            key={ index }
                                            action={ <WrapperPermissions
                                                resource-permission={ '|teamList|button-edit|' }
                                            >
                                                <ActionToggle
                                                    className={ 'with-max-content-btn' }
                                                >
                                                    <KirokuPopoverItem style={ { width: 170 } }>
                                                        <div className={ 'fix-icon-align' }>
                                                            <Link to={ `/teams/edit/${ team.id }` }>
                                                                <i className="fas fa-pencil-alt"/><span
                                                                className='ml-1'>{ t('common.Edit') }</span>
                                                            </Link>
                                                        </div>
                                                    </KirokuPopoverItem>
                                                </ActionToggle>
                                            </WrapperPermissions> }
                                        >
                                            <p><Link to={{
                                                pathname: `/teams/${ team.id }`,
                                                state : {
                                                    previousPage : 'teamList'
                                                }
                                            }}>{ team.name }</Link></p>
                                            <p className='customer-name'>{ team.description }</p>
                                        </KirokuCard>;
                                    }) }
                                </If>
                            </KirokuLoader>
                        </KirokuCardList>
                    </div>
                    <If statement={ teams.length }>
                        { () => <div className={ 'pt-3 pb-5 d-flex justify-content-flex-end' }>
                            <KirokuPagination
                                disabledPrev={ pagination.currentPage === 1 }
                                disabledNext={ pagination.currentPage === pagination.totalPage || pagination.totalPage === 0 }
                                increasePage={ () => {
                                    if(loadingSearch) {
                                        return null;
                                    }
                                    setLoadingSearch(true);
                                    search({...condition, page : pagination.currentPage + 1}).then(() => {
                                        setLoadingSearch(false);
                                    })
                                } }
                                decreasePage={ () => {
                                    if(loadingSearch) {
                                        return null;
                                    }
                                    setLoadingSearch(true);
                                    search({...condition, page : pagination.currentPage - 1}).then(() => {
                                        setLoadingSearch(false);
                                    })
                                } }
                                currentPage={ pagination.currentPage }
                                totalPage={ pagination.totalPage }
                            />
                        </div> }
                    </If>
                </KirokuLoader>
            </ComponentMobile>
        </div>

    );
}

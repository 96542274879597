import { useCustomerContext }   from "../../MakeCustomer/CustomerContext";
import { useCallback }          from "react";
import { CHANGE_INFO_CUSTOMER } from "../../MakeCustomer/CustomerReducer";

const defaultContacts = {
    name: '',
    phone_number: '',
    memo: '',
    relationship: 'unknown'
};

export default function useContacts() {

    const {infoCustomer, dispatch} = useCustomerContext();
    const {contacts} = infoCustomer;

    const setContacts = useCallback((name, value) => {
        dispatch({
            type: CHANGE_INFO_CUSTOMER,
            name, value
        })
    }, [ dispatch ])

    const addContact = useCallback(() => {
        setContacts('contacts', [ ...contacts, defaultContacts ])
    }, [ setContacts, contacts ])

    const removeContact = useCallback((index) => {
        const newContacts = [ ...contacts ];
        newContacts.splice(index, 1);
        setContacts('contacts', newContacts);
    }, [ setContacts, contacts ])

    const showButtonRemove = contacts.length === 1

    return {
        contacts,
        setContacts,
        addContact,
        removeContact,
        showButtonRemove
    }
};

import React, { useState } from 'react';
import {
    KirokuButton, KirokuModal,
    KirokuModalBody, KirokuModalFooter,
}                          from '../../../ui';
import { useTranslation }  from 'react-i18next';
import { resources }       from '@kiroku/api';

export default function ModalDeleteSupport(props) {
    const { t }                                            = useTranslation();
    const { searchAfterDelete, closeModal, show, support } = props;
    const deleteSupport                                    = resources.useDelete('Support')[ 1 ];
    const [isDisabledBtnSubmit, setIsDisabledBtnSubmit]    = useState(false);

    const confirmRemove = () => {
        setIsDisabledBtnSubmit(true);
        deleteSupport(support.id, { planId: support.plan_id, priority: support.priority })
            .then(() => {
                closeModal();
                searchAfterDelete();
                setIsDisabledBtnSubmit(false);
            });
    };

    return (
        <KirokuModal show={show} onClick={closeModal}>
            <KirokuModalBody>
                <p className='text-center pt-10'>
                    {t('plans.messageDeleteSupport')}
                </p>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={closeModal} color={'white'}>
                    {t('common.Cancel')}
                </KirokuButton>
                <KirokuButton
                    onClick={confirmRemove}
                    disabled={isDisabledBtnSubmit}
                    color={'danger'}>
                     {t('plans.delete')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

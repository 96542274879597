import {Component} from "react";
import React from "react";

export default class THeader extends Component {

    render() {
        return (
            <thead {...this.props} className="tbl-header">
                {this.props.children}
            </thead>
        )
    }
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RestService = _interopRequireDefault(require("./RestService"));
var _axios = _interopRequireDefault(require("axios"));
var _KirokuAPIError = _interopRequireDefault(require("./KirokuAPIError"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var KNOWN_ERRORS = ['KIROKU_BAD_REQUEST', 'KIROKU_NOT_FOUND', 'KIROKU_UNAUTH_INCORRECT_USERNAME', 'KIROKU_UNAUTH_INCORRECT_PASSWORD', 'KIROKU_UNAUTH_INCORRECT_OTP_FAIL', 'KIROKU_UNPROCESSABLE_ENTITY', 'KIROKU_FORBIDDEN'
// ... todo more known error here
];
function createAxios(options) {
  var axiosAPI = _axios.default.create(options);
  axiosAPI.interceptors.response.use(function (response) {
    return response.data;
  }, function (error) {
    if (error.response && KNOWN_ERRORS.includes(error.response.data.code)) {
      var convertedError = new _KirokuAPIError.default(error);
      convertedError.previous = error;
      convertedError.code = error.response.data.code;
      convertedError.status = error.response.status;
      convertedError.message = error.response.data.message;
      convertedError.response = error.response;
      convertedError.handleRemoveTokenInvalid(error);
      return Promise.reject(convertedError);
    }
    return Promise.reject(error);
  });
  return axiosAPI;
}
var _default = exports.default = function _default() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    version: '2',
    url: 'http://localhost:3002'
  };
  var onError = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
  var axiosAPI = createAxios({
    baseURL: options.url + "/v".concat(options.version),
    timeout: 15000
  });
  axiosAPI.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return onError(error);
  });
  return {
    Template: new _RestService.default(axiosAPI, "/templates"),
    Record: new _RestService.default(axiosAPI, "/records"),
    User: new _RestService.default(axiosAPI, "/users"),
    Team: new _RestService.default(axiosAPI, "/teams"),
    Customer: new _RestService.default(axiosAPI, "/customers"),
    Organization: new _RestService.default(axiosAPI, "/organizations"),
    Document: new _RestService.default(axiosAPI, "/documents"),
    Assignment: new _RestService.default(axiosAPI, "/assignments"),
    Logs: new _RestService.default(axiosAPI, "/logs"),
    RoleUser: new _RestService.default(axiosAPI, "/role"),
    History: new _RestService.default(axiosAPI, "/history"),
    Report: new _RestService.default(axiosAPI, "/report"),
    Comment: new _RestService.default(axiosAPI, "/comment"),
    Deleted: new _RestService.default(axiosAPI, "/deleted"),
    ExportDocx: new _RestService.default(axiosAPI, "/export-docx"),
    ExportRecord: new _RestService.default(axiosAPI, "/export-record"),
    ExportPlan: new _RestService.default(axiosAPI, "/export-plan"),
    Dashboard: new _RestService.default(axiosAPI, "/dashboard"),
    Pinned: new _RestService.default(axiosAPI, "/pinned"),
    CopyRecord: new _RestService.default(axiosAPI, "/copy-records"),
    Plan: new _RestService.default(axiosAPI, "/plans"),
    Support: new _RestService.default(axiosAPI, "/supports"),
    ReviewSupport: new _RestService.default(axiosAPI, "/review_support"),
    ReviewPlan: new _RestService.default(axiosAPI, "/review-plan"),
    axios: axiosAPI
    // ...
  };
};
import { useCustomerModalContext } from './CustomerModalContext';
import { useCallback }             from 'react';
import _                           from 'lodash';

import {
    HANDLE_SELECT_TAB,
    GET_CUSTOMERS_LIST,
    HANDLE_CHECK_ALL_CUSTOMERS,
    HANDLE_CHECK_CUSTOMER,
    HANDLE_SEARCH_KEYWORDS,
    HANDLE_CHANGE_TAG_TEAM,
    HANDLE_CHECK_ALL_SELECTED,
    HANDLE_CHECK_CUSTOMER_SELECTED,
    HANDLE_SELECT_CUSTOMER_IN_TEAM_SELECTED,
    CHANGE_KEYWORD_SEARCH_CUSTOMERS_MODAL_SELECTED,
    HANDLE_CONFIRM_SEARCH_MODAL,
    HANDLE_RESET_CONDITIONS,
    HANDLE_SELECT_CUSTOMER_TAB,
    HANDLE_CLOSE_MODAL_SELECT_CUSTOMER,
    HANDLE_OPEN_MODAL_SELECT_CUSTOMER,
    SHOW_MODAL_SELECT_CUSTOMER_MOBILE,
    SET_INIT_TAB_CONDITIONS,
}                           from './CustomerReducer';
import { useRecordContext } from '../../ConditionBuilder/RecordContext';
import { resources }        from '@kiroku/api';

export default function useSelectedCustomer() {
    const { customerState, dispatch } = useCustomerModalContext();
    const { search }                  = resources.useSearch('Customer');
    const {
              customers, customerDefault,
              customersShowInModal,
              selectTab, keywords,
              customersSelected, keywordSelected,
              customerSearched, customerTab, isOpenModal,
              showModalSelectCustomer,
          }                           = customerState;

    const { recordCondition } = useRecordContext();

    const getCustomers = useCallback((customers) => {
        if (recordCondition.cId === 'all') {
            let checkedAllCustomers =  customers.map(customer => {
                return {
                    ...customer,
                    selected: true
                }
            })
            dispatch({
                type: GET_CUSTOMERS_LIST,
                customers: checkedAllCustomers,
                customerDefault,
            });
        } else if (recordCondition.cId.length && recordCondition.customerTab === 'SEARCH_CUSTOM_CUSTOMER') {
            recordCondition.cId.forEach(customer => {
                let indexOfCustomerSearched        = _.findIndex(customers, ctm => ctm.id === customer);
                customers[indexOfCustomerSearched] = {
                    ...customers[indexOfCustomerSearched],
                    selected: true,
                };
            });
            dispatch({
                type: GET_CUSTOMERS_LIST,
                customers,
                customerDefault,
            });
        } else if (customerDefault.length) {
            let customerInit = customers.map(customer => {
                let indexOfCustomerDefault = _.findIndex(customerDefault, ctm => ctm.id === customer.id) !== -1;
                return {
                    ...customer,
                    selected: indexOfCustomerDefault,
                };
            });
            dispatch({
                type     : GET_CUSTOMERS_LIST,
                customers: customerInit,
                customerDefault,
            });
        } else {
            dispatch({
                type: GET_CUSTOMERS_LIST,
                customers,
                customerDefault,
            });
        }
    }, [dispatch, customerDefault, recordCondition]);

    const handleSelectTab = useCallback((selectTab) => {
        dispatch({
            type: HANDLE_SELECT_TAB,
            selectTab,
        });
    }, [dispatch]);

    const handleCheckAllCustomers = useCallback(() => {
        dispatch({
            type: HANDLE_CHECK_ALL_CUSTOMERS,
        });
    }, [dispatch]);

    const handleCheckCustomer = useCallback((index) => {
        dispatch({
            type: HANDLE_CHECK_CUSTOMER,
            index,
        });
    }, [dispatch]);

    const handleSearchKeywords = useCallback((keywords) => {
        dispatch({
            type: HANDLE_SEARCH_KEYWORDS,
            keywords,
        });
    }, [dispatch]);

    const handleChangeTags = useCallback((teamSelected) => {
        dispatch({
            type: HANDLE_CHANGE_TAG_TEAM,
            teamSelected,
        });
    }, [dispatch]);

    const handleCheckAllSelected = useCallback(() => {
        dispatch({
            type: HANDLE_CHECK_ALL_SELECTED,
        });
    }, [dispatch]);

    const handleCheckCustomerSelected = useCallback((index) => {
        dispatch({
            type: HANDLE_CHECK_CUSTOMER_SELECTED,
            index,
        });
    }, [dispatch]);

    const handleChangeKeywordSelected = useCallback((keywordSelected) => {
        dispatch({
            type: CHANGE_KEYWORD_SEARCH_CUSTOMERS_MODAL_SELECTED,
            keywordSelected,
        });
    }, [dispatch]);

    const handleChangeTeamSelected = useCallback((teamSelected) => {
        dispatch({
            type: HANDLE_SELECT_CUSTOMER_IN_TEAM_SELECTED,
            teamSelected,
        });
    }, [dispatch]);

    const getCustomerSearched = useCallback((customerSearched) => {
        dispatch({
            type: HANDLE_CONFIRM_SEARCH_MODAL,
            customerSearched,
        });
    }, [dispatch]);

    const handleResetCondition = useCallback(() => {
        dispatch({
            type: HANDLE_RESET_CONDITIONS,
        });
    }, [dispatch]);

    const handleChangeCustomerTab = useCallback((customerTab) => {
        dispatch({
            type: HANDLE_SELECT_CUSTOMER_TAB,
            customerTab,
        });
    }, [dispatch]);

    const handleCloseModalSelect = useCallback(() => {
        dispatch({
            type: HANDLE_CLOSE_MODAL_SELECT_CUSTOMER,
        });
    }, [dispatch]);

    const isOpenModalSelectCustomer = useCallback(() => {
        dispatch({
            type: HANDLE_OPEN_MODAL_SELECT_CUSTOMER,
        });
    }, [dispatch]);

    const setShowModalSelectCustomer = useCallback(() => {
        dispatch({
            type: SHOW_MODAL_SELECT_CUSTOMER_MOBILE,

        });
    }, [dispatch]);

    const setDefaultTabCondition = useCallback(() => {
        let customerSearched = recordCondition.cId;
        dispatch({
            type            : SET_INIT_TAB_CONDITIONS,
            customerSearched: customerSearched,
        });
    }, [dispatch, recordCondition]);

    const updateCustomerDefault = useCallback((profileId) => {
        search({
            type : 'all',
            users: profileId,
        }).then(res => {
            dispatch({
                type           : 'UPDATE_CUSTOMER_DEFAULT',
                customerDefault: res.result,
            });
        });
    }, [dispatch, search]);
    return {
        customers,
        selectTab,
        getCustomers,
        customersShowInModal,
        handleSelectTab,
        handleCheckAllCustomers,
        handleCheckCustomer,
        handleSearchKeywords,
        keywords,
        handleChangeTags,
        customersSelected,
        handleCheckAllSelected,
        handleCheckCustomerSelected,
        handleChangeTeamSelected,
        handleChangeKeywordSelected,
        keywordSelected,
        customerSearched,
        getCustomerSearched,
        handleResetCondition,
        customerTab,
        handleChangeCustomerTab,
        handleCloseModalSelect,
        isOpenModalSelectCustomer,
        isOpenModal,
        setShowModalSelectCustomer,
        showModalSelectCustomer,
        setDefaultTabCondition,
        updateCustomerDefault,
    };
}

import { KirokuDropdownClose, KirokuCardAction } from '../../../ui';
import React                                     from 'react';
import { useTranslation }                        from 'react-i18next';

export default function BulkActionCustomer(props) {
    const { t }                                    = useTranslation();
    const { isSelect, onSelect, location, hidden } = props;

    const bulkActionItems = [
        {
            value    : 'deactive',
            component: (<div>{t('common.deactive')}</div>),
            label    : t('common.deactive'),
        },
    ];

    return (<KirokuDropdownClose
        disabled={isSelect}
        hidden={hidden}
        location={location || 'bot'}
        title={t('common.BulkAction')}
        items={bulkActionItems}
        onSelect={onSelect}
    />);
}


export class ActionToggle extends React.Component {

    constructor() {
        super();
        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false,
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick() {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    render() {
        return (
            <div hidden={this.props.hidden} className="action-customer-surveys" ref={node => {
                this.node = node;
            }}>
                <KirokuCardAction>
                    <div className="action-icon">
                        <i style={{ color: '#1991eb' }} className="fas fa-ellipsis-h"
                           onClick={this.handleClick.bind(this)}/>
                    </div>
                    {this.state.popupVisible && (
                        <div onClick={this.handleClick.bind(this)}>
                            {this.props.children}
                        </div>
                    )}
                </KirokuCardAction>
            </div>
        );
    }
}

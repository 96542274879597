import { DeepDiff } from 'deep-diff';

export default function useGuardianship(oldProfile, logProfile) {

    const oldGuardianship = {
        guardianship_type: oldProfile.guardianship_type,

        decision_date  : oldProfile.decision_date,
        appointed_type : oldProfile.appointed_type,
        competent_court: oldProfile.competent_court,

        case_number        : oldProfile.case_number,
        registration_number: oldProfile.registration_number,
        permanent_address  : oldProfile.permanent_address,

        residence_card_address: oldProfile.residence_card_address,
        facility_name         : oldProfile.facility_name,
        report_month          : oldProfile.report_month,
    };


    const logGuardianship = {
        guardianship_type: logProfile.guardianship_type,

        decision_date  : logProfile.decision_date,
        appointed_type : logProfile.appointed_type,
        competent_court: logProfile.competent_court,

        case_number        : logProfile.case_number,
        registration_number: logProfile.registration_number,
        permanent_address  : logProfile.permanent_address,

        residence_card_address: logProfile.residence_card_address,
        facility_name         : logProfile.facility_name,
        report_month          : logProfile.report_month,
    };
    const diff    = DeepDiff.diff;

    return {
        diffs: diff(oldGuardianship, logGuardianship),
    };
}

import React              from 'react';
import {
    KirokuAge,
    KirokuCardWeb,
    KirokuCertificate,
    KirokuStaffTagList,
}                         from '../../../../ui';
import moment             from 'moment';
import { useTranslation } from 'react-i18next';

export default function ProfileTab(props) {
    const { t }        = useTranslation();
    const { customer } = props;

    return (
        <KirokuCardWeb>
            <div className="p-3">
                <div>
                    <div className="card-title-info mb-15px">{ t('CustomerEditor.PersonalInformation') }</div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='mb-15px'>
                                <span className='customer-info'>{ t('common.Name') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{ customer.name }</div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{ t('common.Age') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>
                                    <KirokuAge dob={ customer.date_of_birth }/>
                                </div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{ t('CustomerEditor.BloodType') }:</span>
                                <br/>
                                <div
                                    className='personal-info pt-10px'>{ (customer.blood_type === 'Unknown' ? t('CustomerEditor.Unknown') : customer.blood_type) || 'Unknown' }</div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{ t('common.Characteristic') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{ customer.characteristic }</div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='mb-15px'>
                                    <span
                                        className='customer-info'>{ t('CustomerEditor.CustomerGender') }:</span>
                                <br/>
                                <div
                                    className='personal-info pt-10px'>{ t(`CustomerEditor.${ customer.gender }`) }</div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{ t('CustomerEditor.PostCode') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{ customer.post_code }</div>
                            </div>

                            <div className='mb-15px'>
                                <span className='customer-info'>{ t('common.Address') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{ customer.address }</div>
                            </div>

                        </div>
                    </div>
                    <hr className={ 'm-0' }/>
                    <div
                        className={ 'card-title-info mb-15px pt-15px' }>{ t('CustomerEditor.CustomerInformation') }</div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className='mb-15px'>
                                <span className='customer-info'>{ t('CustomerEditor.TeamName') }:</span>
                                <br/>
                                <div
                                    className='personal-info pt-10px'>{ customer.team ? customer.team.name : '' }</div>
                            </div>
                            <div className='mb-15px'>
                                    <span
                                        className='customer-info'>{ t('CustomerEditor.StartDateOfContact') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>
                                    { customer.contract_start ? moment.unix(customer.contract_start).format(t('dateTimeFormat.YearMonthDateWithDay')) : '' }
                                </div>
                            </div>
                            <div className='mb-15px'>
                                    <span
                                        className='customer-info'>{ t('CustomerEditor.SupportClassification') }:</span>
                                <br/>
                                <div
                                    className='personal-info pt-10px'>{ (customer.classification === 0 ? t('CustomerEditor.NotApplicable') : customer.classification) || 0 }</div>
                            </div>
                            <div className='mb-15px'>
                                <div className="d-flex align-items-center" style={ { flexWrap: 'wrap' } }>
                                    <div className='customer-info'>{ t('CustomerEditor.KindOfCertificate') }:</div>
                                    <div className='personal-info pt-10px'>
                                        <KirokuCertificate
                                            certificate={ customer.certificate.map(item => ({ ...item, key: item })) }/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className='mb-15px'>
                                <span className='customer-info'>{ t('CustomerEditor.StaffInCharge') }:</span>
                                <div className='personal-info pt-10px'>
                                    <KirokuStaffTagList type={ 'users' } listTags={ customer.users }/>
                                </div>
                            </div>
                            <div className='mb-15px'>
                                    <span
                                        className='customer-info'>{ t('CustomerEditor.EndDateOfContact') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>
                                    { customer.contract_end ? moment.unix(customer.contract_end).format(t('dateTimeFormat.YearMonthDateWithDay')) : ''}
                                </div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{ t('common.team') }:</span>
                                <div className='personal-info pt-10px'>
                                    <KirokuStaffTagList type={ 'teams' } listTags={ customer.teams }/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4"/>
                    </div>
                    <hr className={ 'm-0' }/>
                    <div
                        className={ 'card-title-info mb-15px pt-15px' }>{ t('CustomerEditor.contact.ContactInformation') }</div>
                    <div>
                        { customer.contacts.length ? customer.contacts.map((contact, index) => (
                                <div className='row' key={ index }>
                                    <div className="col-md-4">
                                        <div className='mb-15px'>
                                            <span className='customer-info'>{ t('common.Name') }:</span>
                                            <br/>
                                            <div className='personal-info pt-10px'>{ contact.name }</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='mb-15px'>
                                            <span className='customer-info'>{ t('common.Relationship') }:</span>
                                            <br/>
                                            <div
                                                className='personal-info pt-10px'>{ t(`CustomerEditor.contact.${ contact.relationship }`) }</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className='mb-15px'>
                                            <span className='customer-info'>{ t('common.Phone') }:</span>
                                            <br/>
                                            <div className='personal-info pt-10px'>{ contact.phone_number }</div>
                                        </div>

                                    </div>
                                    <div className='col-md-12'>
                                        <div className='mb-15px'>
                                            <span className='customer-info'>{ t('common.Memo') }:</span>
                                            <br/>
                                            <div className='personal-info pt-10px'>{ contact.memo }</div>
                                        </div>
                                    </div>
                                </div>
                            )) :
                            <div className='row'>
                                <div className="col-md-4">
                                    <div className='mb-15px'>
                                        <span className='customer-info'>{ t('common.Name') }:</span>
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='mb-15px'>
                                        <span className='customer-info'>{ t('common.Relationship') }:</span>
                                        <br/>

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='mb-15px'>
                                        <span className='customer-info'>{ t('common.Phone') }:</span>
                                        <br/>
                                    </div>

                                </div>
                                <div className='col-md-12'>
                                    <div className='mb-15px'>
                                        <span className='customer-info'>{ t('common.Memo') }:</span>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <hr className={ 'm-0' }/>
                    <div className={ 'card-title-info mb-15px pt-15px' }>{ t('CustomerEditor.Certification') }</div>
                    { customer.certifications.length ?
                        customer.certifications.map((item, index) =>
                            <div className="row" key={ index }>
                                <div className="col-md-4">
                                    <div className='mb-15px'>
                                            <span className='customer-info'>
                                                { t('CustomerEditor.CertificationNumber') }:
                                            </span>
                                        <br/>
                                        <div className='personal-info pt-10px'>
                                            { item.certification_number }
                                        </div>
                                    </div>
                                    <div className={ 'mb-15px' }>
                                        <span className='customer-info'>{ t('CustomerEditor.Service') }:</span>
                                        <ul>
                                            { item.services.map((service, index) =>
                                                <li key={ index }>
                                                    <div className='pt-10px'>
                                                        <span className='customer-info'>
                                                            { t('CustomerEditor.CertificationServiceName') }:
                                                        </span>&nbsp;&nbsp;
                                                        <span className='personal-info'>{ service.name }</span>
                                                        <br/>
                                                    </div>
                                                    <div className='pt-10px'>
                                                        <span className='customer-info'>
                                                             { t('CustomerEditor.CertificationServiceStartDate') }:
                                                        </span>&nbsp;&nbsp;
                                                        <span className='personal-info'>
                                                                { service.startDate ? moment.unix(service.startDate).format(t('dateTimeFormat.YearMonthDateWithDay')) : '' }

                                                            </span>
                                                        <br/>
                                                    </div>
                                                    <div>
                                                        <span className='customer-info'>
                                                            { t('CustomerEditor.CertificationServiceEndDate') }:
                                                        </span>&nbsp;&nbsp;
                                                        <span className='personal-info'>
                                                                { service.endDate ? moment.unix(service.endDate).format(t('dateTimeFormat.YearMonthDateWithDay')) : '' }
                                                            </span>
                                                        <br/>
                                                    </div>
                                                </li>,
                                            ) }
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='mb-15px'>
                                            <span className='customer-info'>
                                                { t('CustomerEditor.CertificationIssuer') }:
                                            </span>
                                        <br/>
                                        <div className='personal-info pt-10px'>{ item.issuer }</div>
                                    </div>
                                    <span className=' customer-info'>
                                            { t('CustomerEditor.monitoringTerms') }:
                                        </span>
                                    <div className='mb-15px'>
                                        { item.monitoring_terms.map((term, index) =>
                                            term.date && <li key={ index }>
                                                    <span className='personal-info'>
                                                        &nbsp;&nbsp;
                                                        { moment.unix(term.date).format(t('dateTimeFormat.YearMonth')) }
                                                    </span>
                                            </li>,
                                        ) }
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='mb-15px'>
                                            <span className='customer-info'>
                                                { t('Customer.CustomerExpiration') }:
                                            </span>
                                        <br/>
                                        <div className='personal-info pt-10px'>
                                            { item.certification_expiration ? moment.unix(item.certification_expiration).format(t('dateTimeFormat.YearMonth')) : '' }
                                        </div>
                                    </div>
                                </div>
                            </div>,
                        )
                        :
                        <div className="row mb-15px">
                            <div className="col-md-4">
                                <div className='mb-15px'>
                                        <span
                                            className='customer-info'>{ t('CustomerEditor.CertificationNumber') }:</span>
                                </div>
                                <span className='customer-info'>{ t('CustomerEditor.Service') }:</span>
                            </div>
                            <div className="col-md-4">
                                <div className='mb-15px'>
                                        <span
                                            className='customer-info'>{ t('CustomerEditor.CertificationIssuer') }:</span>
                                </div>
                                <span className=' customer-info'>{ t('CustomerEditor.monitoringTerms') }:</span>
                            </div>
                            <div className="col-md-4"/>
                        </div>
                    }
                    <hr className={ 'm-0' }/>
                    <div className={ 'card-title-info mb-15px pt-15px' }>{ t('CustomerEditor.Guardianship') }</div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className='mb-15px'>
                                <span className='customer-info'>{ t('CustomerEditor.GuardianshipType') }:</span>
                                <br/>
                                <div
                                    className='personal-info pt-10px'>{ customer.guardianship_type ? t(`CustomerEditor.guardianshipTypes.${ customer.guardianship_type }`) : '' }</div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{ t('CustomerEditor.AppointedType') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{ customer.appointed_type || '' }</div>
                            </div>
                            <div className='mb-15px'>
                                    <span
                                        className='customer-info'>{ t('CustomerEditor.CompetentCourt') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{ customer.competent_court || '' }</div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{ t('CustomerEditor.DecisionDate') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>
                                    { customer.decision_date ? moment.unix(customer.decision_date).format(t('dateTimeFormat.YearMonthDateWithDay')) : '' }
                                </div>
                            </div>
                            <div>
                                <span className='customer-info'>{ t('CustomerEditor.CaseNumber') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{ customer.case_number || '' }</div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className='mb-15px'>
                                    <span
                                        className='customer-info'>{ t('CustomerEditor.RegistrationNumber') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{ customer.registration_number || '' }</div>
                            </div>
                            <div className='mb-15px'>
                                    <span
                                        className='customer-info'>{ t('CustomerEditor.PermanentAddress') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{ customer.permanent_address || '' }</div>
                            </div>
                            <div className='mb-15px'>
                                    <span
                                        className='customer-info'>{ t('CustomerEditor.ResidenceCardAddress') }:</span>
                                <br/>
                                <div
                                    className='personal-info pt-10px'>{ customer.residence_card_address || '' }</div>
                            </div>
                            <div className='mb-15px'>
                                <span className='customer-info'>{ t('CustomerEditor.FacilityName') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{ customer.facility_name || '' }</div>
                            </div>
                            <div>
                                <span className='customer-info'>{ t('CustomerEditor.ReportMonth') }:</span>
                                <br/>
                                <div className='personal-info pt-10px'>{ customer.report_month || '' }</div>
                            </div>
                        </div>
                        <div className="col-md-4"/>
                    </div>
                </div>
            </div>
        </KirokuCardWeb>
    );
}

import { useTranslation }             from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { KirokuInput }                from '../../../ui';

export function InputHeadingQuestion({ index = null, heading = '', onBlur }) {
    const { t }                                           = useTranslation();
    const [inputHeadingQuestion, setInputHeadingQuestion] = useState(heading);

    useEffect(() => {
        setInputHeadingQuestion(heading);
    }, [heading]);

    return <>
        <label htmlFor={`input-heading-question-${index}`}
               className={'field-form-label'}
        >
            {t('createSurvey.Heading')}
        </label>
        <KirokuInput name={'content'}
                     id={`input-heading-question-${index}`}
                     value={inputHeadingQuestion}
                     onChange={event => {
                         setInputHeadingQuestion(event.target.value);
                     }}
                     onBlur={onBlur}
        />
    </>;
}

import React, { useEffect, useState } from 'react';
import { resources }                  from '@kiroku/api';
import useAttachTemplate              from './makeTemplate/useAttachTemplate';
import {
    KirokuCheckboxBorder,
    KirokuInputSearch, KirokuLoader, KirokuTabNoResult,
} from '../../../../ui';
import { useTranslation }             from 'react-i18next';
import lodash                         from 'lodash';

function NotFoundSurvey() {
    return (
        <KirokuTabNoResult messageFirst={ 'survey' }>
            <img
                className='pt-2 pb-2'
                src={ `/assets/img/not-template.svg` }
                alt="No template"
            />
        </KirokuTabNoResult>
    );
}

export default function TemplateAttached(props) {
    const { show, customerId }        = props;
    const { search, cancelAPISearch } = resources.useSearch('Template');
    const {
              templateAttached, getTemplates,
              handleChangeKeywordAttached, handleCheckAllTemplatesAttached,
              keywords, handleCheckTemplateAttached
          }                           = useAttachTemplate();
    const [loadingAPI, setLoadingAPI] = useState(false);
    const { t }                       = useTranslation();

    useEffect(() => {
        if (show) {
            setLoadingAPI(true);
            search({ type: 'customers', customers: customerId }).then((res) => {
                getTemplates(res.result);
                setLoadingAPI(false);
            });
        }
        return () => {
            cancelAPISearch();
        };

    }, [search, cancelAPISearch, customerId, show, getTemplates]);

    return (
        <div hidden={ !show }>
            <div className={ 'pt-3' }>
                <KirokuInputSearch
                    onChange={ (event) => {
                        handleChangeKeywordAttached(event.target.value);
                    } }
                    value={ keywords }
                    placeholder={ t('CustomerEditor.EnterNameSurvey') }/>
            </div>
            <div className="pt-3">
                <div hidden={ !templateAttached.length }>
                    <div className="d-flex justify-content-between data-in-modal align-items-center p-2">
                        <div className={ 'font-text-template-name' }>
                            { t('common.surveyTemplate') }
                        </div>
                        <div className='text-primary' style={ { cursor: 'pointer' } }>
                                <span hidden={ lodash.findIndex(templateAttached, survey => !survey.isChecked) === -1 }
                                      onClick={ () => handleCheckAllTemplatesAttached() }
                                      className={ 'font-size-16' }
                                >{ t('common.SelectAll') }</span>
                            <span hidden={ lodash.findIndex(templateAttached, survey => !survey.isChecked) !== -1 }
                                  onClick={ () => handleCheckAllTemplatesAttached() }
                                  className={ 'font-size-16' }
                            >{ t('common.UnSelect') }</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={ {
                overflow : 'scroll',
                minHeight: 'calc(100vh - 450px)',
                maxHeight: 'calc(100vh - 450px)',
                height   : 'auto',
            } }>
                <KirokuLoader loading={ loadingAPI }>
                    {templateAttached.length ?
                        <section className={ `${ templateAttached.length ? 'scroll-data-template' : '' }` }>
                            { templateAttached.map((survey, index) => (
                                <div key={index}>
                                    <KirokuCheckboxBorder
                                        checked={ !!survey.isChecked }
                                        onClick={ () => handleCheckTemplateAttached(index) }
                                        onChange={ () => handleCheckTemplateAttached(index) }
                                        content={ survey.title }
                                    />
                                </div>
                            )) }
                        </section> :<div style={ {
                            minHeight: 'calc(100vh - 396px)',
                            maxHeight: 'calc(100vh - 396px)',
                            height   : 'auto',
                        } }>
                            <NotFoundSurvey/>
                        </div>
                    }
                </KirokuLoader>
            </div>
        </div>
    );
}

import React              from 'react';
import {
    KirokuModalHeader, KirokuModal,
    KirokuButton, KirokuModalFooter,
}                         from '../../../ui';
import { useTranslation } from 'react-i18next';

export default function ModalConfirmExitConductSurvey({ handleCloseModal, isShowModal, handleConfirmModal }) {

    const { t } = useTranslation();

    return (
        <KirokuModal show={isShowModal} onCloseModal={handleCloseModal}>
            <KirokuModalHeader>
                <div className={'text-center'}>
                    <div>  {t('createRecord.AreYouSureYouWantToLeaveThisPage')}</div>
                    <div>{t('createRecord.ChangesYouMadeMayNotBeSaved')}</div>
                </div>
            </KirokuModalHeader>
            <KirokuModalFooter>
                <KirokuButton onClick={handleCloseModal} color={'white'}>
                    {t('createRecord.No')}
                </KirokuButton>
                <KirokuButton color={'primary'} style={{ width: 110 }}
                              onClick={handleConfirmModal}>
                    {t('createRecord.Yes')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

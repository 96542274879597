import React              from 'react';
import { useTranslation } from 'react-i18next';

function SearchNotFound(props) {

    const style = {
        surveyMobile: {
            width        : 40,
            height       : 'auto',
            paddingBottom: 10,
        },
        exportAdmin : { width: 150, height: 'auto' },
    };

    const { t }      = useTranslation();
    const { screen } = props;
    return (
        <div className='pt-3 pb-5 text-center'>
            <img src={ `/assets/img/empty-survey.svg` } alt="search fail" style={ style[screen] }/>
            <h5 style={ { color: '#091e42' } } className={ 'pt-3' }>
                <div>{ props.message || t('common.NoResults') }</div>
            </h5>
            <p>{ props.description || t('common.TryBroadeningYourFilters') }</p>
        </div>
    );
}

export default SearchNotFound;

export const cleanQuestions = (questions) => {
    return questions.map(question => {
        if(question.type !== 'MultiChoice' && question.type !== 'Single') {
            delete question['choices'];
        }

        if(question.type !== 'YesNo') {
            delete question['nextQuestionYes'];
            delete question['nextQuestionNo'];
        }

        if(question.type === 'Single' ||  question.type === 'MultiChoice' ) {
            question['choices'] = question['choices'].map(choice => {
                delete choice['defaultAnswer'];
                return choice;
            })
        }

        delete question['defaultAnswer'];

        return {...question}
    });
};
//todo edit modal change logic question
export const keepLogicWhenDelete = (deletedIndex, questions) => {
    return questions.map((question)=>{
        switch (question.type) {
                case 'Single':
                    return {
                        ...question,
                        choices: question.choices.map((choice) => {
                            if (choice.nextQuestion > deletedIndex){
                                return {
                                    ...choice,
                                    nextQuestion: choice.nextQuestion - 1
                                }
                            }
                            if (choice.nextQuestion === deletedIndex){
                                return {
                                    ...choice,
                                    nextQuestion: ''
                                }
                            }
                            return choice;
                        })
                    };

                case 'YesNo' :

                    // next question of question
                    if(question.nextQuestion) {
                        if (question.nextQuestion ===  deletedIndex){
                            return {
                                ...question,
                                nextQuestion: ''
                            };
                        }
                        if (question.nextQuestion >  deletedIndex){
                            return {
                                ...question,
                                nextQuestion: question.nextQuestion - 1
                            };
                        }
                    }

                    //next question of answer question
                    if (question.nextQuestionNo > deletedIndex){
                        question = {
                            ...question,
                            nextQuestionNo: question.nextQuestionNo - 1,
                        };
                    }else if(question.nextQuestionNo === deletedIndex){
                        question = {
                            ...question,
                            nextQuestionNo: '',
                        };
                    }

                    if (question.nextQuestionYes > deletedIndex){
                        question = {
                            ...question,
                            nextQuestionYes: question.nextQuestionYes - 1,
                        };
                    }else if (question.nextQuestionYes === deletedIndex){
                        question = {
                            ...question,
                            nextQuestionYes : ''
                        };
                    }

                    return question;
                default :
                    if (question.nextQuestion ===  deletedIndex){
                        return {
                            ...question,
                            nextQuestion: ''
                        };
                    }
                    if (question.nextQuestion >  deletedIndex){
                        return {
                            ...question,
                            nextQuestion: question.nextQuestion - 1
                        };
                    }
                    return question;
            }
    });
};

export const revertInitQuestion = (question) => {
    switch (question.type) {
        case 'Single':
        case 'MultiChoice' :
            return {
                content: question.content,
                heading: question.heading,
                type   : question.type,
                choices: question.choices.map(choice => ({
                    answer: choice.answer,
                })),
            };
        default :
            return {
                content: question.content,
                heading: question.heading,
                type   : question.type,
            };
    }
};


export const keepLogic = (questions, currentIndex, nextIndex) => {
    return questions.map((question) => {
        switch (question.type) {
            case 'Single':
                return {
                    ...question,
                    choices: question.choices.map((choice) => {
                        if (choice.nextQuestion === currentIndex){
                            return {
                                ...choice,
                                nextQuestion: nextIndex
                            }
                        }
                        if (choice.nextQuestion === nextIndex){
                            return {
                                ...choice,
                                nextQuestion: currentIndex
                            }
                        }
                        return choice;
                    })
                };

            case 'YesNo' :
                if (question.nextQuestionNo === currentIndex && question.nextQuestionYes === nextIndex){
                    return {
                        ...question,
                        nextQuestionNo: nextIndex,
                        nextQuestionYes : currentIndex
                    };
                }else if(question.nextQuestionNo === nextIndex && question.nextQuestionYes === currentIndex){
                    return {
                        ...question,
                        nextQuestionNo: currentIndex,
                        nextQuestionYes : nextIndex
                    };
                }else if (question.nextQuestionNo === currentIndex){
                    return {
                        ...question,
                        nextQuestionNo: nextIndex,
                    };
                }else if (question.nextQuestionYes === nextIndex){
                    return {
                        ...question,
                        nextQuestionYes : currentIndex
                    };
                }else if (question.nextQuestionYes === currentIndex){
                    return {
                        ...question,
                        nextQuestionYes : nextIndex
                    };
                }else if (question.nextQuestionNo === nextIndex){
                    return {
                        ...question,
                        nextQuestionNo : currentIndex
                    };
                }else {
                    if (question.nextQuestion === currentIndex) {
                        return {
                            ...question,
                            nextQuestion: nextIndex
                        };
                    }
                    if (question.nextQuestion === nextIndex){
                        return {
                            ...question,
                            nextQuestion: currentIndex
                        };
                    }
                }
                return question;
            default :
                if (question.nextQuestion ===  currentIndex){
                    return {
                        ...question,
                        nextQuestion: nextIndex
                    };
                }
                if (question.nextQuestion ===  nextIndex){
                    return {
                        ...question,
                        nextQuestion: currentIndex
                    };
                }
                return question;
        }
    });
};


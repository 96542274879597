import React, { useEffect }         from 'react';
import {
    KirokuButton, KirokuLoader, KirokuModal, KirokuModalBody,
    KirokuModalFooter, KirokuModalHeader,
}                                   from '../../../../ui';
import { useTranslation }           from 'react-i18next';
import useDifferenceCustomerHistory from './useDefferenceCustomerHistory';
import If                           from '../../../If';
import DifferenceCustomerInfo       from './Personal-Information/DifferenceCustomerInfo';
import CustomerInformation          from './Customer-Information/CustomerInformation';
import Contacts                     from './Contacts/Contacts';
import Guardianship                 from './Guardianship/Guardianship';
import Certification                from './Certifications/Certification';

export default function DifferenceCustomerModal({ isShow, logId, closeModal, customer }) {
    const { t }                      = useTranslation();
    const { historySearch, loading, historyResult } = useDifferenceCustomerHistory(customer.id);

    useEffect(() => {
        if (isShow && logId) {
            historySearch(logId);
        }
    }, [historySearch, logId, isShow]);

    return <KirokuModal show={isShow && logId}>
        <KirokuModalHeader>
            <div className={'font-size-18'}>{t('logsRecord.changeLogRecord')}</div>
        </KirokuModalHeader>
        <KirokuModalBody>
            <div className='background-color'
                 style={{
                     overflow : 'scroll',
                     minHeight: 'calc(100vh - 250px)',
                     maxHeight: 'calc(100vh - 250px)',
                     height   : 'auto',
                 }}
            >
                <KirokuLoader loading={loading}>
                    <If statement={historyResult && historyResult['activeLog'] && historyResult['oldLog']}>
                        {() => <React.Fragment>
                            <DifferenceCustomerInfo
                                old={historyResult['oldLog']}
                                log={historyResult['activeLog']}
                            />
                            <hr/>
                            <CustomerInformation
                                old={historyResult['oldLog']}
                                log={historyResult['activeLog']}
                            />
                            <hr/>
                            <Contacts
                                old={historyResult['oldLog']}
                                log={historyResult['activeLog']}
                            />
                            <hr/>
                            <Certification
                                old={historyResult['oldLog']}
                                log={historyResult['activeLog']}
                            />
                            <hr/>
                            <Guardianship
                                old={historyResult['oldLog']}
                                log={historyResult['activeLog']}
                            />

                        </React.Fragment>}
                    </If>
                </KirokuLoader>
            </div>
        </KirokuModalBody>
        <KirokuModalFooter>
            <KirokuButton
                color={'white'}
                onClick={() => {
                    closeModal();
                }}
            >
                {t('common.close')}
            </KirokuButton>
        </KirokuModalFooter>
    </KirokuModal>;
}



import React, { useEffect }    from 'react';
import { resources }           from '@kiroku/api';
import ActionTeam              from '../ComponentUnited/ActionTeam';
import { SearchNotFound }      from '../../../ui';
import { useHistory }          from 'react-router-dom';
import useNotification         from '../../Layout/useNotification';
import { useTranslation }      from 'react-i18next';
import { useMixpanelTracking } from '../../../Mixpanel/useMixpanelTracking';
import If                      from '../../If';
import KirokuLoader            from '../../../ui/KirokuLoader';

export default function UpdateTeam(props) {
    const history                     = useHistory();
    const [team, loadDetail, loading] = resources.useDetail('Team');
    const [teamUpdated, update]       = resources.useUpdate('Team');

    const { trackingMixpanel } = useMixpanelTracking();

    const { notification } = useNotification();
    const { t }            = useTranslation();

    useEffect(() => {
        loadDetail(props.match.params.id).catch(error => console.log(error));
    }, [loadDetail, props.match.params.id]);

    useEffect(() => {
        if (teamUpdated) {
            notification(t('Team.YouUpdateTeamAreSuccessful'), 'success');
            history.push(`/teams/${ team.id }`);
        }
    });


    const handleUpdateTeam = (teamData) => {
        let valueSubmit = {
            description: teamData.description,
            users      : teamData.members,
            id         : team.id,
            name       : teamData.name,
        };
        trackingMixpanel('TEAM_UPDATED', teamData);
        update(team.id, valueSubmit);
    };

    return (
        <div>
            <KirokuLoader loading={loading}>
                <If statement={ team }
                    otherwise={ <SearchNotFound message={ t('common.TeamInvalid') } description={ ' ' }/> }
                >
                    { () => <ActionTeam
                        team={ team }
                        members={ team.users || [] }
                        handleSubmit={ (value) => handleUpdateTeam(value) }
                    /> }
                </If>
            </KirokuLoader>

        </div>
    );

}

import {Component} from "react";
import React from "react";

export default class KirokuPopoverItem extends Component {

    render() {
        const {hidden, style, children, alignPopover = 'right'} = this.props;
        return (
            <div hidden={hidden} className={`popover dropdown-kiroku dropdown-menu ${alignPopover}`}
                 style={{
                     ...style}}
            >
                <div className='dropdown-item-kiroku'>
                    {children}
                </div>
            </div>
        )
    }
}

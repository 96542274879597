import React                                  from 'react';
import { KirokuLogicApplicated, KirokuRadio } from '../../../../../ui';
import { useHeadingQuestion }                 from '../../../../Records/useSurvey';
import { useScrollContext }                   from '../../../../../ui/Scrolling';

export default function SingleChoiceQuestion(props) {
    const { question, headerNextQuestion, isSurveyDetail, value} = props;
    const { optionalContentOrHeading } = useHeadingQuestion(question);
    const { scrollTo }                 = useScrollContext();

    const singleQuestion = question.choices.map(item =>  {
        return {nextQuestion: item.nextQuestion}
    });

    return (<div className={'pb-3'}>
        <div className='wraper-question'>
            <div className="background-question">
                <div className='number-question d-flex justify-content-between align-items-center'>
                    <div className="question-number text-overflow-elip">
                        {optionalContentOrHeading}
                    </div>
                    <div hidden={!isSurveyDetail} className={'float-right'}>
                        <KirokuLogicApplicated  padding={'5px 5px 5px 7px'} question={{...question, singleQuestion}}/>
                    </div>
                </div>
                <span className='content-name-question'>{question.content}</span>
            </div>
            <div className='answer-question font-size-14'>
                {question.choices.map((choice, index) =>
                    <div key={index}>
                        <KirokuRadio
                            key={index}
                            checked={isSurveyDetail ? choice.defaultAnswer : value.is_choice === index}
                            onChange={() => {}}
                            readOnly
                        >
                            <span style={{color : '#495057', cursor : 'no-drop', fontSize: 14}}>{choice.answer}</span>
                        </KirokuRadio>
                        <div
                            hidden={ typeof choice.nextQuestion !== 'number' || !isSurveyDetail }
                            className='pl-10 text-overflow-elip text-info scroll-link-question'
                            onClick={() => {
                                scrollTo(`question-survey-${choice.nextQuestion}`, 202)}}
                        >
                            <i className='fas fa-arrow-right' style={{paddingTop: 4}}/>
                            <span> {headerNextQuestion[index]}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </div>);
}

import React              from 'react';
import _                  from 'lodash';
import { useTranslation } from 'react-i18next';

export default function KirokuLogicApplied(props) {

    const { t }              = useTranslation();
    const { padding, style } = props;
    const question           = props.question;

    let isLogic = '';

    if (typeof question.nextQuestion === 'number') {
        isLogic = question.nextQuestion;
    }

    if (question.type === 'YesNo') {

        if (typeof question.nextQuestionNo === 'number') {
            isLogic = question.nextQuestionNo;
        }

        if (typeof question.nextQuestionYes === 'number') {
            isLogic = question.nextQuestionYes;
        }

        if (typeof question.nextQuestion === 'number') {
            isLogic = question.nextQuestion;
        }

    }

    if (question.type === 'Single') {
        const singleQuestion = question.singleQuestion;
        isLogic              = _.findIndex(singleQuestion, (o) => {
            return typeof o.nextQuestion === 'number';
        }) !== -1;
    }

    return (
        <label
            style={{
                backgroundColor: '#f69931',
                fontSize: '12px',
                color          : '#FFFFFF',
                borderRadius   : 4,
                padding        : padding ? padding : '2px 5px',
                marginBottom   : 0,
                ...style
            }}
            hidden={!isLogic}>
            {t('createSurvey.LogicApplied')}
        </label>
    );

}


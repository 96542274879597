import React              from 'react';
import {
    ComponentMobile, ComponentWeb,
    KirokuCard, KirokuCardList, KirokuCardWeb,
    KirokuLoader, KirokuNotFound,
    KirokuTable, TBody, THeader,
}                         from '../../../ui';
import If                 from '../../If';
import { Link }           from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ReportMonth({condition, result, loadingAPI}) {
    const {t} = useTranslation();
    return (
        <div hidden={condition.type !== 'report_month'}>
            <KirokuCardWeb>
                <ComponentWeb>
                    <div className='p-2'>
                        <KirokuLoader loading={loadingAPI}>
                            <KirokuNotFound isResults={result.length}
                            >
                                <KirokuTable>
                                    <THeader>
                                        <tr>
                                            <th style={{minWidth: 200 }}>{t('Customer.Name')}</th>
                                            <th style={{minWidth: 200 }}>{t('CustomerEditor.CompetentCourt')}</th>
                                            <th style={{minWidth: 200 }}>{t('CustomerEditor.FacilityName')}</th>
                                        </tr>
                                    </THeader>
                                    <TBody>
                                        {result.map((customer, index) => {
                                            return <tr key={index}>
                                                <td style={{ wordBreak: 'keep-all' }}>
                                                    <If statement={condition.status !== 'deactive'}
                                                        otherwise={customer.name}>
                                                        {() => <Link
                                                            to={`/customers/${customer.id}`}>{customer.name} &nbsp;
                                                        </Link>}
                                                    </If>
                                                </td>
                                                <td>{customer.competent_court}</td>
                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                    {customer.facility_name}
                                                </td>
                                            </tr>;
                                        })}
                                    </TBody>
                                </KirokuTable>
                            </KirokuNotFound>
                        </KirokuLoader>
                    </div>
                </ComponentWeb>

                <ComponentMobile>
                    <KirokuCardList
                        headerLeft={<span>{t('common.Name')}</span>}
                        headerRight={''}
                    >
                        <KirokuLoader loading={loadingAPI}>
                            <KirokuNotFound isResults={result.length}>
                                <div>
                                    {result.map((customer, index) => {
                                        return <div key={index}>
                                            <KirokuCard action={''}>
                                                <div style={{ wordBreak: 'keep-all' }}>
                                                    <If statement={condition.status === 'active'}
                                                        otherwise={customer.name}>
                                                        {() => <Link
                                                            to={`/customers/${customer.id}`}>{customer.name} &nbsp;</Link>}
                                                    </If>
                                                    <div className={'pt-2'}>{t('CustomerEditor.CompetentCourt')}: &nbsp;{customer.competent_court}</div>
                                                    <div className={'pt-2'}>{t('CustomerEditor.FacilityName')}: &nbsp; {customer.facility_name} </div>
                                                </div>
                                            </KirokuCard>
                                            <hr className={'margin-none'}/>
                                        </div>;
                                    })}
                                </div>
                            </KirokuNotFound>
                        </KirokuLoader>
                    </KirokuCardList>
                </ComponentMobile>
            </KirokuCardWeb>
        </div>
    )
}

import Date        from './Date';
import InputText   from './InputText';
import Message     from './Message';
import YesNo       from './YesNo';
import Single      from './Single';
import MultiChoice from './MultiChoice';
import Media       from './Media';
import OnlyDate    from './OnlyDate';
import Time        from './Time';
import Number      from './Number';

export const questionTypes = {
    Date,
    InputText,
    Message,
    YesNo,
    Single,
    MultiChoice,
    Media,
    OnlyDate,
    Time,
    Number,
};

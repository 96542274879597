import { useEffect, useState } from 'react';
import { resources }           from '@kiroku/api';
import { useMixpanelTracking } from '../../../Mixpanel/useMixpanelTracking';

export default function usePinnedCustomer() {
    const [customers, setCustomer]       = useState([]);
    const { loading, search, condition } = resources.useSearch('Pinned', { order_by: 'ASC' });
    const create                         = resources.useCreate('Pinned')[1];

    const { trackingMixpanel } = useMixpanelTracking()

    useEffect(() => {
        search({ order_by: 'ASC'}).then(result => {
            const customers = result.result;
            setCustomer(customers.map(customer => ({ ...customer, hoverStar: !customer['pinned_at'] })));
        }).catch(error => {
            console.log(error);
        });
    }, [search]);

    const hoverStar = (index, status) => {
        const customerPinned            = [...customers];
        customerPinned[index].hoverStar = !status;
        setCustomer(customerPinned);
    };

    const pinCustomer = (customer) => {
        create({ customer }).then(() => {
            search({ ...condition}).then((res) => {
                setCustomer(res.result.map(customer => ({ ...customer, hoverStar: !customer['pinned_at'] })));
            })
        }).catch(error => {
            console.log(error);
        });
        trackingMixpanel('PIN_CUSTOMER', customer);
    };

    const handleSearchPinned = (conditionSearch) => {
        search({
           ...condition,
            ...conditionSearch,
        }).then(result => {
            const customers = result.result;
            setCustomer(customers.map(customer => ({ ...customer, hoverStar: !customer['pinned_at'] })));
        }).catch(error => {
            console.log(error);
        })
    }

    return { loading, hoverStar, pinCustomer, customers, handleSearchPinned, condition };
}

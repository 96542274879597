import React, { useEffect, useState }                                      from 'react';
import { KirokuCheckBox, KirokuCheckboxBorder, KirokuInput, KirokuLoader } from '../../../../ui';
import { useTranslation }                                                  from 'react-i18next';
import DropdownSelectTeam                            from './DropdownSelectTeam';
import _                                             from 'lodash';
import { useSelectUsers }                            from './useSelectUsers';

export default function UserOptionsTab({ loading, teams, show }) {
    const { t }                = useTranslation();
    const [userShow, setUsers] = useState([]);
    const {
              handleCheckAll, handleCheckUser,
              users, handleSearchKeywords,
              keywords, handleChangeTags,
          }                    = useSelectUsers();

    useEffect(() => {
        if (users) {
            setUsers(users);
        }
    }, [users]);

    return (
        <div>
            <div className={ 'pt-2' }>
                <DropdownSelectTeam handleChangeTags={ (value) => handleChangeTags(value) }
                                    teamSearch={ teams } showModal={show}
                                    placeholder={ t('RecordsBeta.refineByTeam') }
                />
            </div>
            <div className={ 'pb-3' }>
                <KirokuInput
                    style={ { height: 36, fontSize: 14 } }
                    onChange={ (e) => handleSearchKeywords(e.target.value) }
                    value={ keywords }
                    placeholder={ t('RecordsBeta.keyword') }
                />
            </div>
            <div style={{ height: 'calc(100vh - 450px)'}}>
            <KirokuLoader loading={ loading }>
                { !userShow.length ? <div className={ 'background-color d-flex' }
                                          style={ { fontSize: '16px', height: 'calc(100vh - 500px)' } }>
                    <div style={ { margin: 'auto' } }>
                        { t('RecordsBeta.noStaffSelected') }
                    </div>
                </div> : <div className={ 'background-color' }>
                    <div className={ 'p-3' }
                         style={{ border: 'solid 1px #dfe2e5'}}
                    >
                        <KirokuCheckBox onChange={ handleCheckAll }
                                        checked={ _.findIndex(users, user => !user.isChecked) === -1 }
                        />
                    </div>
                    <div
                         style={ {
                             overflow : 'scroll',
                             height   : 'calc(100vh - 500px)',
                         } }>
                        { userShow.map((user, i) => {
                            return (
                                <div key={ i }
                                     onClick={ () => handleCheckUser(i) }
                                >
                                    <div className={ 'cursor-pointer' }>
                                        <KirokuCheckboxBorder content={ <span className={ 'font-text-in-checkbox' }
                                                                        style={ { wordBreak: 'break-word' } }>{ user.name }</span> }
                                                        onChange={ () => handleCheckUser(i) }
                                                        checked={ !!user.isChecked }
                                                        id={ `${ user.id }-${ i }` }/>
                                    </div>
                                </div>
                            );
                        }) }
                    </div>
                </div> }
            </KirokuLoader>
            </div>
        </div>
    );
}

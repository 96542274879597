import React, { useEffect }             from 'react';
import { resources }                    from '@kiroku/api';
import { useHistory }                   from 'react-router-dom';
import PlanningAction                   from '../PlanningAction';
import If                               from '../../If';
import { KirokuLoader, SearchNotFound } from '../../../ui';
import { useTranslation }               from 'react-i18next';
import { useMixpanelTracking }          from '../../../Mixpanel/useMixpanelTracking';

export default function PlanningUpdater(props) {
    const [plan, loadDetail, loadingAPI] = resources.useDetail('Plan');
    const history                        = useHistory();
    const update                         = resources.useUpdate('Plan')[1];
    const { t }                          = useTranslation();
    const { trackingMixpanel }           = useMixpanelTracking();

    useEffect(() => {
        loadDetail(props.match.params.id).catch(error => console.log(error));
    }, [loadDetail, props.match.params.id]);
    return (
        <KirokuLoader loading={ loadingAPI }>
            <If statement={ plan }
                otherwise={ <SearchNotFound message={ t('common.PlanInvalid') } description={ ' ' }/> }
            >{ () =>
                <PlanningAction
                    isUpdate
                    message={ 'message.UpdateSuccess' }
                    handleSubmit={ (value) => update(plan.id, value).then(() => {
                        history.push(`/plans/${ plan.id }`);
                        trackingMixpanel('UPDATE_PLAN', value);

                    }) }
                    loadingAPI={ loadingAPI }
                    plan={ plan || {} }
                /> }
            </If>
        </KirokuLoader>
    );
}

import moment                            from 'moment';
import React                             from 'react';
import { ComponentMobile, ComponentWeb } from '../../../../ui';

export default function Plan({ plan, t }) {
    return (<>
        <div className={ 'header-card-detail' }>
            <div className="row align-items-center">
                <div className="col-md-12 col-lg-8 planning-customer-name d-flex">
                    <div>
                        {
                            plan.customer && <span className="font-size-18 pl-2">{ plan.customer.name }</span>
                        }
                    </div>
                </div>
                <div className="col-md-12 col-lg-4 ">
                    <div className={ 'last-update-planning pl-2' } style={ { fontSize: 14 } }>
                        <span style={ { fontSize: 14 } }>{ t('plans.lastUpdateAt') }: </span>
                        { moment.unix(plan['updated_at'] || plan['created_at']).format(t('dateTimeFormat.YearMonthDateWithHour')) }
                    </div>
                </div>
            </div>
        </div>

        <div className={ 'pt-3 pb-3' }>
            <ComponentWeb>
                <div className={ 'pb-3 row margin-none padding-content-info' }>
                    <div className="col-6">
                        <span className={ 'label-info' }>{ t('plans.assistancePolicy') }:</span><br/>
                    </div>

                    <div className={ 'col-3' }>
                        <span className={ 'label-info' }>{ t('plans.startDate') }:</span><br/>
                    </div>

                    <div className={ 'col-3' }>
                        <span className={ 'label-info' }>{ t('plans.endDate') }:</span><br/>
                    </div>
                </div>
                <hr className={ 'm-0' }/>
                <div className={ 'pt-3 row margin-none padding-content-info' }>
                    <div className="col-6">
                        <span className="main-content-info"
                            style={{wordBreak: 'break-word'}}
                        >{ plan['overview'] }</span>
                    </div>
                    <div className={ 'col-3 ' }>
                        <div className="content ">
                            { !!plan['start_at'] && <span className="main-content-info">
                                { moment.unix(plan['start_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}</span>
                            }
                        </div>
                    </div>
                    <div className={ 'col-3 ' }>
                        <div className="content ">
                            { !!plan['end_at'] && <span className="main-content-info">{ moment.unix(plan['end_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}</span>}
                        </div>
                    </div>
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <div style={ { padding: '0 30px' } }>
                    <span className={ 'main-content-info' }
                          style={{wordBreak: 'break-word'}}
                    >{ plan['overview'] }</span><br/>
                    <div className="pt-3 d-flex align-items-center">
                        <span className={ 'main-content-info' }>{ t('plans.startDate') }:</span>
                        <div className="pl-2"/>
                        <div className="content ">
                            { !!plan['start_at'] && <span className="main-content-info">
                                { moment.unix(plan['start_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}</span>
                            }
                        </div>
                    </div>
                    <div className="pt-3 d-flex align-items-center">
                        <span className={ 'main-content-info' }>{ t('plans.endDate') }:</span>
                        <div className="pl-2"/>
                        <div>
                            { !!plan['end_at'] && <span className="main-content-info">{ moment.unix(plan['end_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}</span>}
                        </div>
                    </div>
                </div>
            </ComponentMobile>
        </div>
    </>);
}

import { KirokuCardBodyWeb, KirokuHighLightText, KirokuLabel, KirokuLoader } from '../../../../ui';
import { Link }                                                              from 'react-router-dom';
import React, { useEffect, useState }                                        from 'react';
import { useRecordContext }                                                  from '../../ConditionBuilder/RecordContext';
import { useTranslation }                                                    from 'react-i18next';
import KirokuNotFound                                                        from '../../../../ui/KirokuNotFound';
import { useCondition }                                                      from '../../useCondition';
import moment                                                                from 'moment';

export default function TableRecords(props) {
    const { t }                                       = useTranslation();
    const { recordCondition }                         = useRecordContext();
    const { handleCheckRecordRead, records, loading } = props;
    const { order_by, sort_by, searchOrderCondition } = useCondition();
    const [sortByServicedAt, setSortByServicedAt]     = useState('DESC');
    const [sortByUpdateAt, setSortByUpdateAt]         = useState('DESC');

    useEffect(() => {
        if (sort_by === 'serviced_at') {
            setSortByServicedAt(order_by);
        }
    }, [order_by, sort_by]);

    useEffect(() => {
        if (sort_by === 'update_at') {
            setSortByUpdateAt(order_by);
        }
    }, [order_by, sort_by]);

    return (
        <div className={ 'table-record-beta background-color table mb-0' }>
            <KirokuLoader loading={ loading }>
                <KirokuNotFound isResults={ records.length }>
                    <div style={ { overflowX: 'scroll' } }>
                        <div hidden={ !records.length } className={ 'max-width-table-record-beta' }>
                            <div className="p-3">
                                <div className='d-flex'>
                                    <div
                                        className=" header-table-record-beta column-draft">{ t('RecordsBeta.status') }</div>
                                    <div
                                        className=" header-table-record-beta column-template">{ t('RecordsBeta.recordTemplate') }</div>
                                    <div
                                        className=" header-table-record-beta column-customer-name">{ t('RecordsBeta.customerName') }</div>
                                    <div
                                        className=" header-table-record-beta column-updater">{ t('RecordsBeta.updatedBy') }</div>
                                    <div
                                        className=" header-table-record-beta d-flex cursor-pointer column-service-at"
                                        onClick={ () => {
                                            searchOrderCondition('serviced_at', 'DESC');
                                        } }
                                    >
                                        <div>{ t('RecordsBeta.servicedAt') }</div>
                                        <div className="p-1"/>
                                        <div
                                            className={ `icon-order ${ sort_by === 'serviced_at' ? 'color-blue-icon-order' : '' }` }>
                                            <i hidden={ sortByServicedAt === 'DESC' }
                                               className="fas fa-lg fa-angle-up"/>
                                            <i hidden={ sortByServicedAt === 'ASC' }
                                               className="fas fa-lg fa-angle-down"/>
                                        </div>
                                    </div>
                                    <div className=" header-table-record-beta d-flex cursor-pointer column-update-at"
                                         onClick={ () => {
                                             searchOrderCondition('update_at', 'DESC');
                                         } }
                                    >
                                        <div>{ t('RecordsBeta.updatedAt') }</div>
                                        <div className="p-1"/>
                                        <div
                                            className={ `icon-order ${ sort_by === 'update_at' ? 'color-blue-icon-order' : '' }` }>
                                            <i hidden={ sortByUpdateAt === 'DESC' }
                                               className="fas fa-lg fa-angle-up"/>
                                            <i hidden={ sortByUpdateAt === 'ASC' }
                                               className="fas fa-lg fa-angle-down"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className='m-0'/>
                            <KirokuCardBodyWeb className='card-body p-0'>
                                { records.map((record, index) => {
                                    return (
                                        <Link key={ index }
                                              to={ {
                                                  pathname: `/records/${ record.id }`,
                                                  state   : records.map(item => item.id),
                                              } } onClick={ () => {
                                            localStorage.setItem('conditionRecords', JSON.stringify(recordCondition));
                                        } }>
                                            <div className={ `p-3 
                                                        ${ !handleCheckRecordRead(record) ? 'border-read-record' : '' }
                                                        ${ index % 2 === 0 ? '' : 'bg-light' }` }>
                                                <div className='d-flex' style={ { width: '100%' } }>
                                                    <div className="column-draft">
                                                        { record['drafted_at'] ?
                                                            <div className='label-drafted-at'>
                                                                <KirokuLabel color={ 'warning' }
                                                                             style={ {
                                                                                 width: 70, border: 'solid 1px #EA9B3F',
                                                                                 backgroundColor  : '#EA9B3F',
                                                                             } }>
                                                                    { t('RecordsBeta.draft') }
                                                                </KirokuLabel>
                                                            </div> : null }
                                                    </div>
                                                    <div
                                                        className="font-text-records-table column-template"
                                                        style={ { wordBreak: 'break-word', fontWeight: 300, color: '#007BFF' } }>
                                                        { record.title }
                                                    </div>
                                                    <div
                                                        className="font-text-records-table column-customer-name"
                                                        style={ { fontWeight: 300, color: '#202528' } }
                                                    >
                                                        { record.customer ? record.customer.name : '' }
                                                    </div>
                                                    <div className="column-updater">
                                                    <span className="font-text-records-table pr-0"
                                                          style={ { fontWeight: 300, color: '#202528' } }>
                                                            { record.lastUpdatedBy ? record.lastUpdatedBy.name : '' }</span>
                                                    </div>
                                                    <div className="column-service-at">
                                                        <div className={ 'font-text-date-time-records-table' }>
                                                            { moment.unix(record.serviced_at).format(record.has_time ?
                                                                t('dateTimeFormat.YearMonthDateWithHour') : t('dateTimeFormat.YearMonthDateWithDay'))
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="column-update-at">
                                                        <div className='font-text-date-time-records-table'>
                                                            { moment.unix(record['last_action_at']).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div hidden={ !recordCondition.answer_search }
                                                     style={ { color: '#7F8FA4' } }>
                                                    { record.highlight ? <div
                                                        dangerouslySetInnerHTML={ { __html: record.highlight['answer_search.answer'] }}/> :
                                                        <KirokuHighLightText
                                                            className={ 'background-highText' }
                                                            searchWords={ [recordCondition.answer_search] }
                                                            textToHighlight={ record['answer_search'] || '' }
                                                        />}
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                }) }
                            </KirokuCardBodyWeb>
                        </div>
                    </div>
                </KirokuNotFound>
            </KirokuLoader>
        </div>
    );
}

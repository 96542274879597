import moment        from 'moment';
import CalendarChart from '../HelperComponent/CalendarChart';
import React         from 'react';

export function HeadMapRecordChart({ dataAnalysis, startDate, endDate, handleOnClick }) {
    let newStartDate = moment(startDate).subtract(1, 'days').format('YYYY/M/D');
    let newEndDate   = moment(endDate).format('YYYY/M/D');

    let numberMonthSelected = endDate.diff(startDate, 'days');

    const checkWidthOfChart = (number) => {
        if (number < 30) {
            return '20%';
        } else if (number < 100) {
            return '40%';
        } else if (number < 150) {
            return '50%';
        }
        return '100%';
    };

    return (<div className={`${numberMonthSelected < 100 ? 'chart-smaller' : 'chart-larger'}`}
                 style={{ maxWidth: checkWidthOfChart(numberMonthSelected) }}
    >
        <CalendarChart dataAnalysis={dataAnalysis.data || []} startDate={newStartDate} endDate={newEndDate}
                       handleOnClick={(record) => handleOnClick(record)}
                       textValueZero={'Analysis.countingZeroRecord'}
        />
    </div>);
}

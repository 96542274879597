import SurveyContext         from './SurveyContext';
import React, { useReducer } from 'react';
import SurveyReducer         from './SurveyReducer';

export default function SurveyProvider({ survey = null, children }) {
    const initialSurvey = {
        title        : '',
        description  : '',
        questions    : [],
        currentScreen: 'Create', //'Review'
        ...survey,
    };

    const [infoSurvey, dispatch] = useReducer(SurveyReducer, initialSurvey, () => initialSurvey);
    return <SurveyContext.Provider
        value={{infoSurvey, dispatch}}
    >
        {children}
    </SurveyContext.Provider>;
}

import * as moment        from 'moment';

export const makeLabelCondition = (key, answers, t) => {
    switch (key) {
        case 'creator':
        case 'templateId':
        case 'userId':
        case 'status':
            return answers;
        case 'startDate':
            let startDate = answers ? `${ moment.unix(answers).format('LLL') }` : '';
            return t(`Surveys.${ key }`) + `${ startDate }`;
        case 'endDate':
            let endDate = answers ? `${ moment.unix(answers).format('LLL') }` : '';
            return t(`Surveys.${ key }`) + `${ endDate }`;
        default:
            return t(`RecordList.${ key }`);
    }
};

export const makeConditions = (params, valueName, t) => {
    let conditions = Object.keys(params).map(key => {
        if (params[key]) {
            return {
                [key]: params[key],
                query: key,
                value: params[key],
                label: makeLabelCondition(key, valueName[key], t),
            };
        }
        return '';
    });
    return conditions.filter(condition => condition !== '');
};

export function makeParamsFromCondition (conditions) {
    let params = {};
    conditions.map(condition => {
        params[condition.key] = condition.value;
        return condition;
    });
    return params;
}


import {
    KirokuButton,
    KirokuInput,
    KirokuModal,
    KirokuModalBody,
    KirokuModalFooter,
}                         from '../../../../../ui';
import React, {
    useEffect,
    useState,
}                         from 'react';
import { useTranslation } from 'react-i18next';
import { resources }      from '@kiroku/api';
import useNotification    from '../../../../Layout/useNotification';

const ModalRenameFile = ({ file, show, handleCloseModal, handleAfterRenameFile }) => {
    const { t } = useTranslation();
    const [fileName, setFileName] = useState(file?.name || '');
    const [result, update, isLoading, error] = resources.useUpdate('Document');
    const { notification }  = useNotification();

    useEffect(() => {
        if(file) {
            setFileName(file.name)
        }
    }, [file])


    useEffect(() => {
        if(error) {
            console.log(error);
        }
    }, [error, result])

    const handleConfirmUpload = () => {
        update(file.id, {
            ...file,
            name: fileName
        }).then(() => {
            handleAfterRenameFile();
            notification(t('message.UpdateSuccess'), 'success');
        })
    }

    const handleCancelUpload = () => {
        handleCloseModal();
    }

    return (
        <KirokuModal show={show}>
            <KirokuModalBody>
                <div className='p-3'>
                    <label className="mt-1 mb-10">File Name</label>
                    <KirokuInput
                        maxLength={250}
                        value={fileName}
                        onChange={(event) => {
                            setFileName(event.target.value)
                        }}
                    />
                    <p hidden={fileName} style={{
                        color: 'red',
                        fontSize: 12,
                        marginBottom: 0
                    }}>
                        Filename cannot be empty
                        {/*{t('CustomerEditor.validate.Name')}*/}
                    </p>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton
                    color={'white'}
                    onClick={() => handleCancelUpload()}>
                    {t('common.Cancel')}
                </KirokuButton>
                <KirokuButton
                    color={'success'}
                    disabled={!fileName || isLoading}
                    onClick={() => {
                        handleConfirmUpload();
                    }}>{t('common.Confirm')}</KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    )
}
export default ModalRenameFile;

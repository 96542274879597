import React, { useEffect, useState }                                     from "react";
import { ComponentMobile, ComponentWeb, KirokuActionSurvey, KirokuInput } from "../../../../ui";
import { useTranslation }                                                 from "react-i18next";
import Datetime                                                           from "react-datetime";
import PropTypes                                                          from 'prop-types';
import moment                                                             from "moment";

function CertificationServiceName({ isFirst, onBlur, defaultName, buttonRemove, currentService }) {

    const { t } = useTranslation();
    const [ name, setName ] = useState(defaultName);

    useEffect(() => {
        setName(defaultName)
    }, [ defaultName, currentService ])

    return (
        <>
            <ComponentMobile>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label>{t('CustomerEditor.CertificationServiceName')}</label>
                    {buttonRemove}
                </div>
            </ComponentMobile>
            <ComponentWeb>
                <label hidden={isFirst}>{t('CustomerEditor.CertificationServiceName')} </label>
            </ComponentWeb>
            <KirokuInput
                value={name}
                onChange={e => {
                    setName(e.target.value)
                }}
                onBlur={onBlur}
            />
        </>
    )
}

function CertificationServiceStartDate({ isFirst, currentService, onChange }) {
    const { t } = useTranslation();
    const onChangeDate = (value) => {
        onChange(value)
    }

    return (
        <>
            <ComponentWeb>
                <label hidden={isFirst}>{t('CustomerEditor.CertificationServiceStartDate')}</label>
            </ComponentWeb>
            <ComponentMobile>
                <label className='mt-1 mb-10'>{t('CustomerEditor.CertificationServiceStartDate')}</label>
            </ComponentMobile>
            <Datetime
                inputProps={{ disabled: false, readOnly: true }}
                value={currentService['startDate'] ? moment(currentService['startDate'] * 1000) : ''}
                timeFormat={false}
                closeOnSelect={true}
                dateFormat={t('dateTimeFormat.YearMonthDate')}
                onChange={onChangeDate}
                locale={t('createRecord.Date')}
                className='read-only-date'
                monthYearLocale={localStorage.getItem('i18nextLng')}
            />

        </>
    )
}

function CertificationServiceEndDate({ isFirst, currentService, onChange }) {

    const [ validate, setValidation ] = useState(true);
    const { t } = useTranslation();

    const onChangeDate = (value) => {
        if (currentService['startDate'] > moment(value).unix()) {
            setValidation(false);
        }
        onChange(value)
    }

    useEffect(() => {
        if (currentService['endDate'] && currentService['startDate']) {
            setValidation(currentService['endDate'] >= currentService['startDate']);
        }
    }, [ currentService ])

    return (
        <>
            <ComponentWeb>
                <label hidden={isFirst}>{t('CustomerEditor.CertificationServiceEndDate')}</label>
            </ComponentWeb>
            <ComponentMobile>
                <label className='mt-1 mb-10'>{t('CustomerEditor.CertificationServiceEndDate')}</label>
            </ComponentMobile>
            <div>
                <Datetime
                    className='read-only-date'
                    inputProps={{ disabled: false, readOnly: true }}
                    value={currentService['endDate'] ? moment(currentService['endDate'] * 1000) : ''}
                    timeFormat={false}
                    closeOnSelect={true}
                    dateFormat={t('dateTimeFormat.YearMonthDate')}
                    onChange={onChangeDate}
                    locale={t('createRecord.Date')}
                    monthYearLocale={localStorage.getItem('i18nextLng')}
                />
                <p hidden={true} style={{
                    color: 'red',
                    fontSize: 12
                }}>
                    {t('CustomerEditor.validate.ValidDate')}
                </p>
                <p hidden={validate} style={{
                    color: 'red',
                    fontSize: 12
                }}>{t('CustomerEditor.validate.ErrorStartDateCertification')}</p>
            </div>
        </>
    )
}

export default function ServiceCertification({ service, hidden, remove, isFirst, updateCertifications }) {

    const [ currentService, setCurrentService ] = useState(service);

    const handleChangeInput = e => {
        const newService = { ...currentService };
        newService.name = e.target.value
        setCurrentService(newService)
        updateCertifications(newService)
    };

    const handleChangeDate = name => value => {
        const newService = { ...currentService };
        newService[name] = moment(value).unix();
        setCurrentService(newService)
        updateCertifications(newService)
    };

    useEffect(() => {
        setCurrentService(service)
    }, [ service ])

    return (
        <>
            <div className="col-md-4">
                <CertificationServiceName
                    isFirst={isFirst}
                    defaultName={currentService['name']}
                    currentService={currentService}
                    onBlur={handleChangeInput}
                    buttonRemove={
                        <div className={'cursor-pointer pt-2'}
                             onClick={remove}
                             hidden={hidden}
                             style={{ width: 20 }}
                        >
                            <KirokuActionSurvey icon={'minus'}/>
                        </div>
                    }
                />
            </div>
            <div className="col-md-3">
                <CertificationServiceStartDate isFirst={isFirst} currentService={currentService}
                                               onChange={handleChangeDate('startDate')}/>
            </div>
            <div className="col-md-3">
                <CertificationServiceEndDate isFirst={isFirst} currentService={currentService}
                                             onChange={handleChangeDate('endDate')}/>
            </div>
            <ComponentWeb>
                <div className="col-md-2">
                    <label hidden={isFirst}/>
                    <div className={'cursor-pointer pt-2'}
                         onClick={remove}
                         hidden={hidden}
                         style={{ width: 20 }}
                    >
                        <KirokuActionSurvey icon={'minus'}/>
                    </div>
                </div>
            </ComponentWeb>
        </>
    )
}

ServiceCertification.propTypes = {
    service: PropTypes.object.isRequired,
    index: PropTypes.number,
    hidden: PropTypes.bool
}

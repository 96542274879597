import React, { useEffect }          from 'react';
import KirokuLoader                  from '../../../ui/KirokuLoader';
import If                            from '../../If';
import { RecordingProvider }         from '../../Recording';
import { availableQuestions }        from '../../Records/RecordTaking/QuestionTypeWeb';
import moment                        from 'moment';
import Survey                        from '../../Records/RecordTaking/Survey';
import { authentication, resources } from '@kiroku/api';
import { setDefaultAnswer }          from '../../Records/RecordTaking/RecordCreate/TakeRecord';
import { SearchNotFound }            from '../../../ui';
import { useMixpanelTracking }       from '../../../Mixpanel/useMixpanelTracking';
import { useHistory }                from 'react-router-dom';
import { useTranslation }            from 'react-i18next';

export default function PreviewTakeSurvey(props) {
    const [survey, loadDetailSurvey, isLoadingSurvey] = resources.useDetail('Template');
    const profile                                     = authentication.useAuthenticatedContext().user;
    const history                                     = useHistory();
    const { trackingMixpanel }                        = useMixpanelTracking();
    const { t }                                       = useTranslation();

    useEffect(() => {
        loadDetailSurvey(props.match.params.id).catch(error => console.log(error));
        trackingMixpanel('PREVIEW_TEMPLATE', { survey_id: props.match.params.id });
    }, [loadDetailSurvey, props.match.params.id, trackingMixpanel]);

    return (<div>
        <KirokuLoader
            loading={isLoadingSurvey}
        >
            <If statement={survey}
                otherwise={ <SearchNotFound message={ t('common.SurveyInvalid') } description={ ' ' }/> }
            >
                {() => <RecordingProvider
                    availableQuestions={availableQuestions}
                    survey={{
                        ...survey,
                        questions  : setDefaultAnswer(survey.questions),
                        serviced_at: moment().unix(),
                        has_time   : false,
                    }}
                >
                    <Survey
                        canIsFixIt
                        user={profile}
                        handleSaveStatus={null}
                        handleCreateRecord={null}
                        actionPreview
                        handleModalCancel={() => history.goBack()}
                    />
                </RecordingProvider>
                }
            </If>
        </KirokuLoader>
    </div>);
}

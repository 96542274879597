import DateTimeQuestion  from './DateTimeQuestion';
import InputText         from './InputText';
import Message           from './Message';
import YesNoQuestion     from './YesNoQuestion';
import SingleChoice      from './SingleChoice';
import MultiChoice       from './MultiChoice';
import MediaFileQuestion from './MediaFileQuestion';
import DateQuestion      from './DateQuestion';
import TimeQuestion      from './TimeQuestion';
import NumberQuestion    from './NumberQuestion';

export const componentQuestion = {
    InputText,
    Message,
    MultiChoice,
    YesNo   : YesNoQuestion,
    Date    : DateTimeQuestion,
    Single  : SingleChoice,
    Media   : MediaFileQuestion,
    OnlyDate: DateQuestion,
    Time    : TimeQuestion,
    Number  : NumberQuestion,
};

import React, { useContext, useReducer } from 'react';
import ModalTemplateReducer              from './ModalTemplateReducer';

export const TemplatesModalContext = React.createContext(null);

export function useTemplatesModalContext() {
    return useContext(TemplatesModalContext);
}

export function TemplatesModalProvider({ children, templateSelectedDefault = [] }) {

    const intState = {
        templates                  : [],
        templateShowInModal        : templateSelectedDefault,
        templateList               : [],
        keywords                   : '',
        keywordSelected            : '',
        templateListSelected       : [],
        selectedTabName            : 'optionsTab',
        templateSelectedShowInModal: templateSelectedDefault,
    };

    const [surveyState, dispatch] = useReducer(ModalTemplateReducer, intState, () => intState);
    return <TemplatesModalContext.Provider value={ { surveyState, dispatch } }>
        { children }
    </TemplatesModalContext.Provider>;
}

import React                           from 'react';
import moment                          from 'moment';
import { KirokuRadio, KirokuCheckBox } from '../../../../../ui';
import { useHeadingQuestion }          from '../../../../Records/useSurvey';
import { useTranslation }              from 'react-i18next';

function AnswerQuestionGeneric({ diff }) {
    return (
        <div>
            <div className={'d-flex align-items-center justify-content-around'}>
                <div className={'text-decoration text-form-area word-break'}>{diff.lhs || ''}</div>
                <div className={'p-2'}/>
                <i className="fas fa-arrow-right fa-lg"/>
                <div className={'p-2'}/>
                <pre
                    style={{
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap'
                    }}
                >{diff.rhs}
                     </pre>
            </div>
        </div>
    );
}


function AnswerQuestionYesNo({ diff }) {
    const { t }        = useTranslation();
    return (
        <div>
            <div className={'d-flex align-items-center justify-content-around'}>
                <div className={'text-decoration text-form-area word-break'}>{t(`createRecord.${diff.lhs || ''}`)}</div>
                <div className={'p-2'}/>
                <i className="fas fa-arrow-right fa-lg"/>
                <div className={'p-2'}/>
                <div className='text-form-area word-break'>{t(`createRecord.${diff.rhs}`)}</div>
            </div>
        </div>
    );
}

function AnswerQuestionDateTime({ diff }) {
    const {t} = useTranslation();
    return (
        <div>
            <div className={'d-flex align-items-center justify-content-around'}>
                <div
                    className={'text-decoration text-form-area'}>{diff.lhs && moment.unix(diff.lhs).format(t('dateTimeFormat.YearMonthDateWithHour'))}</div>
                <div className={'p-2'}/>
                <i className="fas fa-arrow-right fa-lg"/>
                <div className={'p-2'}/>
                <div className='text-form-area'>{diff.rhs && moment.unix(diff.rhs).format(t('dateTimeFormat.YearMonthDateWithHour'))}</div>
            </div>
        </div>
    );
}

function AnswerQuestionOnlyDate({ diff }) {
    const {t} = useTranslation();
    return (
        <div className={'width-difference-question'}>
            <div className={'d-flex align-items-center justify-content-around'}>
                <div
                    className={'text-decoration text-form-area'}>{diff.lhs && moment.unix(diff.lhs).format(t('dateTimeFormat.YearMonthDateWithDay'))}</div>
                <i className="fas fa-arrow-right fa-lg"/>
                <div className='text-form-area'>{diff.rhs && moment.unix(diff.rhs).format(t('dateTimeFormat.YearMonthDateWithDay'))}</div>
            </div>
        </div>
    );
}

function AnswerQuestionTime({ diff }) {
    const {t} = useTranslation();
    return (
        <div className={'width-difference-question'}>
            <div className={'d-flex align-items-center justify-content-around'}>
                <div
                    className={'text-decoration text-form-area'}>{diff.lhs && moment.unix(diff.lhs).format(t('dateTimeFormat.HourMinute'))}</div>
                <i className="fas fa-arrow-right fa-lg"/>
                <div className='text-form-area'>{diff.rhs && moment.unix(diff.rhs).format(t('dateTimeFormat.HourMinute'))}</div>
            </div>
        </div>
    );
}

function AnswerQuestionSingleChoice({ diff, questionsBefore, questionsAfter }) {
    const choices    = questionsBefore[ diff.path[ 0 ] ].choices;
    const answersLhs = questionsBefore[ diff.path[ 0 ] ].answers.is_choice;
    const answersRhs = questionsAfter[ diff.path[ 0 ] ].answers.is_choice;
    return (
        <div className={'width-difference-question'}>
            <div className="d-flex align-items-center justify-content-around">
                <div className={'answer-question size-select-question'}>
                    {choices.map((item, index) => {
                        return (
                            <div key={index}>
                                <KirokuRadio
                                    checked={answersLhs === index}
                                    id={`SingleChoice-lhs-${index}`}
                                    onChange={() => {
                                    }}
                                >
                                        <span
                                            className={`${answersLhs === index ? 'text-decoration' : ''} text-form-area word-break`}>
                                            {item.answer}
                                        </span>
                                </KirokuRadio>
                            </div>
                        );
                    })}
                </div>
                <i className="fas fa-arrow-right fa-lg"/>
                <div className={'answer-question size-select-question'}>
                    {choices.map((item, index) => {
                        return (
                            <div key={index}>
                                <KirokuRadio key={index}
                                             checked={answersRhs === index}
                                             id={`SingleChoice-rhs-${index}`}
                                             onChange={() => {
                                             }}
                                > <span className='text-form-area word-break'>{item.answer}</span>
                                </KirokuRadio>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

function AnswerQuestionMultiChoice({ diff, questionsBefore, questionsAfter }) {
    const answersLhs = questionsBefore[ diff.path[ 0 ] ].answers;
    const answersRhs = questionsAfter[ diff.path[ 0 ] ].answers;
    return (
        <div className={'width-difference-question'}>
            <div className="d-flex align-items-center justify-content-around">
                <div className='answer-question answer-checkbox-beta'>
                    {answersLhs.map((item, index) => {
                        const isCheckedOfRhs = !!answersRhs[ index ].is_choice;
                        return (
                            <div className="size-select-question" key={index}>
                                <KirokuCheckBox
                                    checked={!!item.is_choice}
                                    id={`MultiChoice_${index}`}
                                    content={<span className={`
                                                    ${isCheckedOfRhs !== !!item.is_choice ? 'text-decoration' : ''} content-checkbox-beta word-break`}>
                                                        {item.answer}
                                                    </span>}
                                    onChange={() => {
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
                <i className="fas fa-arrow-right fa-lg"/>
                <div className='answer-question answer-checkbox-beta'>
                    {answersRhs.map((item, index) => {
                        return (<KirokuCheckBox key={index}
                                                checked={!!item.is_choice}
                                                id={`MultiChoice_${index}`}
                                                content={<span
                                                    className='content-checkbox-beta word-break'>{item.answer}</span>}
                                                onChange={() => {
                                                }}
                        />);
                    })}
                </div>
            </div>
        </div>
    );
}

function AnswerQuestionMedia({ diff }) {
    return (
        <div className="d-flex align-items-center">
            <div className={'wrap-upload'}>
                <div className="form-upload">
                    <div className="image-upload"
                         style={{
                             backgroundImage: `url(${diff.lhs})`,
                             minWidth       : 100,
                             height         : 100,
                         }}
                    />
                </div>
            </div>
            <i className="fas fa-arrow-right fa-lg"/>
            <div className={'wrap-upload'}>
                <div className="form-upload">
                    <div className="image-upload"
                         style={{
                             backgroundImage: `url(${diff.rhs})`,
                             minWidth       : 100,
                             height         : 100,
                         }}
                    />
                </div>
            </div>
        </div>
    );
}

export function ComponentViewActionEdit({ diff, questionsBefore, questionsAfter }) {

    const answerQuestion = {
        Media      : AnswerQuestionMedia,
        InputText  : AnswerQuestionGeneric,
        Single     : AnswerQuestionSingleChoice,
        YesNo      : AnswerQuestionYesNo,
        Message    : AnswerQuestionGeneric,
        MultiChoice: AnswerQuestionMultiChoice,
        Date       : AnswerQuestionDateTime,
        OnlyDate   : AnswerQuestionOnlyDate,
        Time       : AnswerQuestionTime,
        Number     : AnswerQuestionGeneric,
    };

    const AnswerQuestion       = answerQuestion[ questionsAfter[ diff.path[ 0 ] ].type ];
    const { contentOrHeading } = useHeadingQuestion(questionsAfter[ diff.path[ 0 ] ]);
    return (<div>
        <div className={'font-size-16 pb-10px'}>{contentOrHeading}</div>
        <AnswerQuestion diff={diff}
                        questionsBefore={questionsBefore}
                        questionsAfter={questionsAfter}
        />
    </div>);
}



import React from 'react';
import * as Sentry from '@sentry/browser';

export const SentryContext = React.createContext({});

export const SentryProvider = ({ options, children, enabled }) => {
    const Provider = SentryContext.Provider;

    if (enabled) {
        Sentry.init(options);
    }

    return (
        <Provider value={Sentry}>
            {children}
        </Provider>
    )
};

export const withSentry = Target => {
    return class extends React.PureComponent {

        static get contextType() {
            return SentryContext;
        }

        render() {
            return <Target {...this.props} sentry={this.context} />
        }
    }
};

export const handleLogError = (peopleWriteErrors, error) => {
    peopleWriteErrors.captureException(error);
}

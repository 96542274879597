import {Component} from "react";
import React       from "react";

export default class KirokuTable extends Component {
    render() {
        return (
            <div id='table' hidden={this.props.hidden} style={{overflowX: "auto"}}>
                <table>
                    {this.props.children}
                </table>
            </div>
        )
    }
}

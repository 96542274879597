import React                         from 'react';
import { useDifferenceCustomerInfo } from './useDiffCustomerInfo';
import {
    DateDiff, GeneralDiff,
    GeneralDiffKeyTranslate,
}                                    from '../DifferenceType';
import { useTranslation }            from 'react-i18next';
import If                            from '../../../../If';

const DiffComponents = {
    name          : GeneralDiff,
    characteristic: GeneralDiff,
    blood_type    : GeneralDiffKeyTranslate,
    post_code     : GeneralDiff,
    address       : GeneralDiff,
    gender        : GeneralDiffKeyTranslate,
    date_of_birth : DateDiff,

};

export default function ({ log, old }) {
    const { t }                = useTranslation();
    const { profileDataDiffs } = useDifferenceCustomerInfo(old.data, log.data);

    return <div className="p-2">
        <div className={'font-size-16 pb-10px'}>{t('customerDifference.personalInfo')}</div>
        <If statement={profileDataDiffs}
            otherwise={<div className={'text-center'}>
                <div style={{ fontSize: 24 }} className={'pb-10px'}>{t('customerDifference.notChange')}</div>
            </div>}
        >
            {() => profileDataDiffs.map((profileDataDiff, index) => {
                const DiffComponent = DiffComponents[ profileDataDiff.path[ 0 ] ];
                return profileDataDiff.kind === 'E' && <div key={index} className="d-flex align-items-center">
                    <b>{t(`customerDifference.${profileDataDiff.path[ 0 ]}`)}: </b> &nbsp;
                    {DiffComponent && <DiffComponent lhs={profileDataDiff.lhs} rhs={profileDataDiff.rhs}/>}
                </div>;
            })}
        </If>
    </div>;
}

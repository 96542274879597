import React, { useReducer } from 'react';
import RecordingReducer      from './RecordingReducer';
import RecordingContext      from './RecordingContext';

export default function RecordingProvider({ survey, disabledRange = {}, children, customer, availableQuestions }) {

    const defaultRecodingState = {
        currentQuestion: null,
        questions      : survey.questions,
        drafts         : survey.questions,
        disabledRanges : disabledRange,
        isEnding       : false
    };

    const [
              { drafts, currentQuestion, questions, disabledRanges, isEnding },
              dispatch,
          ] = useReducer(
        RecordingReducer,
        defaultRecodingState, () => defaultRecodingState);

    return (
        <RecordingContext.Provider
            value={{ currentQuestion, questions, survey, drafts, dispatch, customer, availableQuestions, disabledRanges, isEnding }}>
            {children}
        </RecordingContext.Provider>
    );
}

import React, { useEffect, useState } from 'react';
import { resources }                  from '@kiroku/api';
import { KirokuSectionDropdown }      from '../../../ui';
import { getGroupedOptions }          from '../../Converter';

export function useGetCustomerTagByUserId(userId) {
    const { result, search } = resources.useSearch('Customer', { type: 'all', userId });
    useEffect(() => {
        search({ type: 'all', users: userId });
    }, [search, userId]);
    return { customersBeingTagUser: result };
}


function useGetDataCustomersOptions() {
    const { result, search } = resources.useSearch('Customer', { type: 'all' });
    useEffect(() => {
        search({ type: 'all' });
    }, [search]);
    return { customers: result };
}

function DropdownSelectCustomerTag({ handleOnChangeCustomers, userId }) {
    const { customers }             = useGetDataCustomersOptions();
    const { customersBeingTagUser } = useGetCustomerTagByUserId(userId);

    const [currentCustomerTags, setCustomersBeingTagUser] = useState([]);

    useEffect(() => {
        setCustomersBeingTagUser(customersBeingTagUser.map(customer => {
            return {
                id        : customer.id,
                key       : `Customer_${customer.id}`,
                label     : customer.name,
                tagType   : 'customer',
                customerId: customer.id,
                type      : 'Customers',
                value     : `${customer.name}${customer.id}`,
            };
        }));
    }, [customersBeingTagUser]);

    const handleChangeTags = (optionsSelected) => {
        setCustomersBeingTagUser(optionsSelected);
        handleOnChangeCustomers(optionsSelected.map(item => {
            return {
                id: item.customerId,
                name: item.label,
            }
        }));
    };

    return <KirokuSectionDropdown
        onChange={handleChangeTags}
        value={currentCustomerTags}
        options={getGroupedOptions('Customer', customers)}
    />;
}

export default DropdownSelectCustomerTag;

import { KirokuLoader, KirokuTextareaAutosize } from '../../../../ui';
import React                                    from 'react';
import { useTranslation }                       from 'react-i18next';


export default function Memo(props) {
    const { t } = useTranslation();
    const {
              isEditingMemo, setShowEditing,
              handleSubmit, handleCancel,
              isLoadingMemo,
              memo, setMemo,
          }     = props;

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div className={ 'card-title-info' }>
                    { t('plans.memo') }
                </div>
                <div className="justify-content-flex-end pt-2">
                    { !isEditingMemo ?
                        <span onClick={ () => setShowEditing(!isEditingMemo) }
                              className={ 'cursor-pointer' }>
                            <i className="fas fa-edit fa-lg"
                               style={ { width: 24, height: 24 } }/>
                        </span>
                        : <div className="d-flex align-items-center">
                            <div className={ 'd-flex align-items-center' }>
                                <span
                                    style={ {
                                        color   : 'red',
                                        cursor  : 'pointer',
                                        position: 'relative',
                                        margin  : 'auto 0',
                                    } } className='ml-2'
                                    onClick={ handleCancel }
                                >
                                    <i className="fas fa-2x fa-times-circle"/>
                                </span>
                                <div className="pl-2">
                                    <span
                                        onClick={ handleSubmit }

                                        style={ {
                                            color   : 'green',
                                            cursor  : 'pointer',
                                            position: 'relative',
                                            margin  : 'auto 0',
                                        } } className='ml-2'>
                                        <i className="fas fa-2x fa-check-circle"/>
                                    </span>
                                </div>
                            </div>
                        </div> }
                </div>
            </div>
            <div className={ 'pt-10px' }>
                <KirokuLoader loading={ isLoadingMemo }>
                    { !isEditingMemo ? <pre>{ memo }</pre> : <KirokuTextareaAutosize
                        value={ memo }
                        onChange={ (e) => setMemo(e.target.value) }
                        className='form-area text-form-area'
                    /> }
                </KirokuLoader>
            </div>
        </>

    );
}

import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import { KirokuDateRangePicker }      from '../../../../ui';
import { useCondition }               from '../../useCondition';
import ComponentWeb                   from '../../../../ui/ComponentWeb';
import ComponentMobile                from '../../../../ui/ComponentMobile';
import { useRecordContext }           from '../../ConditionBuilder/RecordContext';
import * as moment                    from 'moment';

export default function ServicedAtCondition(props) {
    const { t }                               = useTranslation();
    const { setDateRange }                    = useCondition();
    const [dateRange, setDateRangeService]    = useState({
        startDate: null,
        endDate  : null,
    });

    const { recordCondition } = useRecordContext();

    useEffect(() => {
        setDateRangeService({
            startDate: recordCondition.serviceStartDate ? moment.unix(recordCondition.serviceStartDate) : null,
            endDate  : recordCondition.serviceEndDate ? moment.unix(recordCondition.serviceEndDate) : null,
        });
    }, [recordCondition.serviceStartDate, recordCondition.serviceEndDate, setDateRangeService]);

    return (
        <div className={ 'pt-2 pb-2' }>
            <ComponentWeb>
                <div className="row">
                    <div className="col-lg-4 pr-0">
                        <div className={ 'pt-8 title-date-time' }>{ t('RecordsBeta.servicedAt') }</div>
                    </div>
                    <div className="col-lg-8">
                        <div className={ 'fix-height-date-range' }>
                            <div className="date-time-range custom-clear-date">
                                <KirokuDateRangePicker
                                    startDateId={ props.startDateId || 'servicedStartDateId' }
                                    endDateId={ props.endDateId || 'servicedEndDateId' }
                                    minimumNights={ 0 }
                                    startDate={ dateRange.startDate }
                                    endDate={ dateRange.endDate }
                                    onChangeDate={ (date) => {
                                        setDateRange({
                                            serviceStartDate: date.momentStartDate,
                                            serviceEndDate  : date.momentEndDate,
                                        });
                                        setDateRangeService({
                                            startDate: date.startDate,
                                            endDate  : date.endDate,
                                        });
                                    } }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <div className={ 'fix-height-date-range' }>
                    <div className="date-time-range custom-clear-date">
                        <KirokuDateRangePicker
                            isMobile
                            startDateId={ props.startDateId.concat('-mobile') || 'startDateMobile'}
                            endDateId={ props.endDateId.concat('-mobile') || 'endDateMobile'}
                            minimumNights={ 0 }
                            startDate={ dateRange.startDate }
                            numberMonthSelected={ 1 }
                            endDate={ dateRange.endDate }
                            onChangeDate={ (date) => {
                                setDateRange({
                                    serviceStartDate: date.momentStartDate,
                                    serviceEndDate  : date.momentEndDate,
                                });
                                setDateRangeService({
                                    startDate: date.startDate,
                                    endDate  : date.endDate,
                                });
                            } }
                        />
                    </div>
                </div>
            </ComponentMobile>
        </div>
    );
}

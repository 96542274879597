import React                     from 'react';
import { useTranslation }        from 'react-i18next';
import { Link }                  from 'react-router-dom';
import moment                    from 'moment';

export default function CardRecordDetailWeb({ record }) {
    const { t } = useTranslation();

    return (
        <div className='pl-3' >
            <div className="row">
                <div className=' col-xl-4 col-lg-4 font-customer-name-detail pl-3'>
                    <div>
                        <p className={'label-record-beta'} style={{ margin: 0 }}>
                            <span className="label-name" >
                                <Link to={`/customers/${record.customer.id}`} className={'font-size-16'}>
                                {record.customer.name} </Link>
                            </span>
                            <span className="font-name-san">{t('RecordsBeta.noun')}</span>
                        </p>
                    </div>
                    <div style={{color: '#091e42', fontSize: 16}}>
                        {record.title}
                    </div>
                </div>
                <div className='col-xl-4 col-lg-4 '>
                    <div className="d-flex justify-content-start font-text-card-detail">
                        <div
                            style={{ textAlign: 'end', padding: 0, minWidth: 123 }}>{t('RecordsBeta.servicedAt')}: &nbsp;</div>
                        <div style={{ padding: 0, minWidth: 167 }}>
                            <span className={'font-text-card-detail'} >
                                { moment.unix(record.serviced_at).format(record.has_time ? t('dateTimeFormat.YearMonthDateWithHour') : t('dateTimeFormat.YearMonthDateWithDay'))}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-start font-text-card-detail pt-10">
                        <div
                            style={{ textAlign: 'end', padding: 0, minWidth: 123 }}>{t('RecordsBeta.updatedDate')}: &nbsp;</div>
                        <div style={{ padding: 0, minWidth: 167 }}>
                             <span className={'font-text-card-detail'}>
                                 { moment.unix(record['last_action_at']).format(t('dateTimeFormat.YearMonthDateWithHour'))}
                             </span>
                        </div>
                    </div>
                </div>
                <div className='col-xl-4 col-lg-4'>
                    <div className="d-flex justify-content-start font-text-card-detail pl-3">
                        <div
                            style={{ textAlign: 'end', padding: 0, minWidth: 112 }}> {t('RecordsBeta.lastUpdatedBy')} : &nbsp;</div>
                        <div style={{ padding: 0, minWidth: 'max-content' }}>
                            { record.lastUpdatedBy ? record.lastUpdatedBy.delete_at ?
                                <span>{record.lastUpdatedBy.name}</span> :
                                <Link to={`/users/${record.lastUpdatedBy.id}`}
                                      className={'cursor-pointer'}>
                                    {record.lastUpdatedBy.name} </Link> : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-3 font-text-card-detail">
                {record.survey_template.description}
            </div>
        </div>
    );
}

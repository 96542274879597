import React, { useEffect, useState }                               from 'react';
import { KirokuSectionDropdownShowMore, ModalSelectCustomerOfTeam } from '../../../ui';
import { useTranslation }                                           from 'react-i18next';
import { resources }                                                from '@kiroku/api';
import { getGroupedOptions }                                        from '../../Converter';

export default function SelectCustomers({ customers, teams, onChange }) {

    const { result, search }    = resources.useSearch('Customer');
    const [showing, setShowing] = useState(false);
    const { t }                 = useTranslation();

    useEffect(() => {
        search({ type: 'all' });
    }, [search]);

    const handleChangeCustomerByTeam = (customers) => {
        onChange(getGroupedOptions('Customer', customers.filter(customer => customer.isChecked)));
    };

    return (
        <>
            <div className="p-2"/>
            <div className={ 'pb-2 d-flex' }>
                <div>{ t('RecordList.customers') }</div>
                <div className="p-2"/>
                <div className={ 'cursor-pointer' } style={ { color: '#1891eb' } }
                     onClick={ () => setShowing(true) }
                >{ t('RecordList.SelectCustomerOfTeam') }</div>
            </div>
            <KirokuSectionDropdownShowMore
                tagType={ 'Customer' }
                placeholder={ t('Customer.placeholderSearch') }
                onChange={ onChange }
                value={ customers }
                options={ getGroupedOptions('Customer', result) }
            />
            <ModalSelectCustomerOfTeam
                show={ showing }
                customers={ result }
                customersSelected={ customers }
                closeModal={ () => setShowing(false) }
                teams={ getGroupedOptions('Team', teams) }
                customerSelectedInModal={ handleChangeCustomerByTeam }
            />
        </>
    );
}

import React                                                             from "react";
import { KirokuModal, KirokuModalBody, KirokuModalFooter, KirokuButton } from "../../../../ui";
import { useTranslation }                                                from "react-i18next";
import PropTypes                                                         from 'prop-types';

export default function ConfirmOverwriteAddress({show, address, oncancel, onsubmit}) {

    const {t} = useTranslation();

    return (
        <KirokuModal show={show} onClick={oncancel}>
            <KirokuModalBody>
                <div style={{
                    padding: '16px 15px 15px 22px'
                }}>
                    <div
                        className={'text-question-confirm-addr-header'}>{t('CustomerEditor.Is it OK to overwrite address field?')}</div>
                    <div
                        className={'text-question-confirm-addr'}>{t('CustomerEditor.Current Inputted Address')}</div>
                    <div style={{fontSize: 16}}>{address['old']}</div>
                    <div
                        className={'text-question-confirm-addr'}>{t('CustomerEditor.We will overwrite this address')}</div>
                    <div style={{fontSize: 16}}>{address['new']}</div>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <div className="d-flex justify-content-around">
                    <KirokuButton color={'white'} className={'btn-cancel-confirm-overwrite-addr'}
                                  onClick={oncancel}>
                        <div style={{color: '#1891eb'}}>
                            {t('common.Cancel')}
                        </div>
                    </KirokuButton>
                    <div style={{width: 100}}/>
                    <KirokuButton color={'primary'}
                                  onClick={onsubmit}
                    >{t('CustomerEditor.Overwrite')}</KirokuButton>
                </div>
            </KirokuModalFooter>
        </KirokuModal>
    )
}

ConfirmOverwriteAddress.propTypes = {
    show: PropTypes.bool.isRequired,
    address: PropTypes.object.isRequired,
    oncancel: PropTypes.func.isRequired,
    onsubmit: PropTypes.func.isRequired
}

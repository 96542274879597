import React              from 'react';
import { KirokuCheckBox } from '../../../ui';
import { useTranslation } from 'react-i18next';
import useQuestions       from '../MakeSurvey/useQuestions';
import If                 from '../../If';

export default function CheckboxUnrequited({ question, isNotUse }) {
    const { t }      = useTranslation();
    const { update } = useQuestions();
    return (
        <If statement={!isNotUse}>
            {() => <div className={`float-right unrequited-checkbox`}>
                <KirokuCheckBox
                    id={`un-quited-${question.index}`}
                    content={<div className={'cursor-pointer font-size-16'}>{t('common.unrequited')}</div>}
                    checked={!!question[ 'unrequited' ]}
                    stylelabel={{marginTop: '-4px'}}
                    onChange={(event) => {
                        let questionRaw = question;
                        if (!event.target.checked) {
                            delete questionRaw.unrequited;
                        } else {
                            questionRaw = {
                                ...questionRaw,
                                unrequited: event.target.checked,
                            };
                        }
                        update(questionRaw);
                    }}
                />
            </div>}
        </If>
    );
}

import React                                     from 'react';
import { KirokuLogicApplicated, KirokuCheckBox } from '../../../../../ui';
import { useHeadingQuestion }                    from '../../../../Records/useSurvey';
import { useScrollContext }                      from '../../../../../ui/Scrolling';


export default function MultiChoice(props) {

    const { question, headerNextQuestion, isSurveyDetail } = props;
    const { optionalContentOrHeading } = useHeadingQuestion(question);
    const { scrollTo }                 = useScrollContext();
    const choices = isSurveyDetail ? question.choices : question.answers;
    return (<div className={'pb-3'}>
        <div className='wraper-question'>
            <div className="background-question">
                <div className='number-question d-flex justify-content-between align-items-center'>
                    <div
                        className="question-number text-overflow-elip">
                        {optionalContentOrHeading}
                    </div>
                    <div hidden={!isSurveyDetail} className={'float-right'}>
                        <KirokuLogicApplicated  padding={'5px 5px 5px 7px'} question={question}/>
                    </div>
                </div>
                <span className='content-name-question'>{question.content}</span>
                <div
                    hidden={typeof question.nextQuestion !== 'number' || !isSurveyDetail}
                    className='text-info pl-10 text-overflow-elip scroll-link-question'
                    onClick={() => scrollTo(`question-survey-${question.nextQuestion}`, 202)}
                >
                    <i className='fas fa-arrow-right text-info' style={{paddingTop: 4}}/>
                    <span className={'font-size-14'}> {headerNextQuestion}</span>
                </div>
            </div>
            <div className='answer-question font-size-14' style={{ color : '#495057' }}>
                {choices.map((item, index) =>
                    <KirokuCheckBox key={index}
                                    checked={isSurveyDetail ? item.defaultAnswer : item.is_choice}
                                    id={`${index}-MultiChoice-${question.index}`}
                                    content={item.answer}
                                    style={{ cursor : 'no-drop'}}
                                    readOnly disabled
                    />
                )}
            </div>

        </div>
    </div>);
}

import React, { Component } from 'react';
import mixpanel             from 'mixpanel-browser';

export const MixpanelContext = React.createContext({ });

export default mixpanel;

export class MixpanelProvider extends Component {

    render() {
        const { options: { token }, children }  = this.props;
        const Provider      = MixpanelContext.Provider;
        mixpanel.init(token);
        return (
            <Provider value={mixpanel} children={children} />
        );
    }
}

export const withMixpanel = (Target) => {

    return class extends Component {
        static get contextType() {
            return MixpanelContext;
        }

        render() {

            return (
                <Target {...this.props} mixpanel={this.context}  />
            )
        }
    }
};

export const prepareProfile = (profile) => {
    mixpanel.people.set({
        "username"         : profile.username,
        "$name"            : profile.name,
        "$avatar"          : profile.avatar,
        "organization_id"  : profile.organization_id,
        "organization_name": profile.organization_name,
    });
    mixpanel.identify(profile.id);
    mixpanel.register({
        org_id  : profile.organization_id,
        org_name: profile.organization_name,
    });
};

import React, { useEffect, useReducer }           from 'react';
import TemplateReducer, { INIT_TEMPLATE_DEFAULT } from './TemplateReducer';
import TemplateContext                            from './TemplateContext';

export default function TemplateProvider({ children, templateDefault = [] }) {

    const initTemplateData = {
        templateAttached      : [],
        templateAttachedList  : [],
        templateUnAttached    : [],
        templateUnAttachedList: [],
        attachStatus          : '',
        keywords              : '',
        templateDefault       : templateDefault,
        templateAttachedIds   : [],
        templateUnAttachedIds : [],
    };


    const [templatesContext, dispatch] = useReducer(TemplateReducer, initTemplateData, () => initTemplateData);

    useEffect(() => {
        dispatch({
            type: INIT_TEMPLATE_DEFAULT,
            templateDefault,
        });

    }, [templateDefault, dispatch]);

    return (
        <TemplateContext.Provider value={ { templatesContext, dispatch } }>
            { children }
        </TemplateContext.Provider>
    );
}

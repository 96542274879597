import React                                    from 'react';
import useDiffContacts                          from './useDiffContacts';
import { useTranslation }                       from 'react-i18next';
import { GeneralDiff, GeneralDiffKeyTranslate } from '../DifferenceType';

export default function Contacts({ old, log }) {
    const { t }                     = useTranslation();
    const { diffsContacts, isDiff } = useDiffContacts(old.data, log.data);

    return <div className={'p-2'}>
        <div className={'font-size-16 pb-10px'}>{t('customerDifference.contactInformation')}</div>
        <div hidden={isDiff}>
            <div className={'text-center'}>
                <div style={{ fontSize: 24 }} className={'pb-10px'}>{t('customerDifference.notChange')}</div>
            </div>
        </div>
        {diffsContacts.map((diff, index) => {
            return (<React.Fragment key={index}>
                    <div hidden={diff.isDiff} className={'text-center'}>
                        <div style={{ fontSize: 24 }} className={'pb-10px'}>
                            {t('customerDifference.notChange')}
                        </div>
                    </div>
                    <div hidden={!diff.isDiff}>
                        <div hidden={(diff.lhs && diff.lhs.name) === (diff.rhs && diff.rhs.name)}>
                            <div className={'d-flex align-items-center'}>
                                <b>{t('customerDifference.name')}: </b> &nbsp;
                                <GeneralDiff lhs={diff.lhs && diff.lhs.name} rhs={diff.rhs && diff.rhs.name}/>
                            </div>
                        </div>
                        <div hidden={(diff.lhs && diff.lhs.memo) === (diff.rhs && diff.rhs.memo)}>
                            <div className={'d-flex align-items-center'}>
                                <b>{t('customerDifference.memo')}: </b> &nbsp;
                                <GeneralDiff lhs={diff.lhs && diff.lhs.memo} rhs={diff.rhs && diff.rhs.memo}/>
                            </div>
                        </div>
                        <div hidden={(diff.lhs && diff.lhs.relationship)  === (diff.rhs && diff.rhs.relationship)} >
                            <div className={'d-flex align-items-center'}>
                                <b>{t('customerDifference.relationship')}: </b> &nbsp;
                                <GeneralDiffKeyTranslate lhs={diff.lhs && diff.lhs.relationship}
                                                         rhs={diff.rhs && diff.rhs.relationship}/>
                            </div>
                        </div>
                        <div hidden={(diff.lhs && diff.lhs.phone_number) === (diff.rhs && diff.rhs.phone_number)}>
                            <div className={'d-flex align-items-center'}>
                                <b>{t('customerDifference.phone_number')}: </b> &nbsp;
                                <GeneralDiff lhs={diff.lhs && diff.lhs.phone_number}
                                             rhs={diff.rhs && diff.rhs.phone_number}/>
                            </div>
                        </div>
                    </div>
                    <hr hidden={diffsContacts.length - 1 === index}/>
                </React.Fragment>
            );
        })}
    </div>;
}

import { ComponentMobile, ComponentWeb, KirokuCardWeb } from '../../../../ui';
import React, { useState }                              from 'react';
import Memo                                             from './Memo';
import { resources }                                    from '@kiroku/api';
import ProfileTab                                       from './ProfileTab';
import ProfileTabMobile                                 from './ProfileTabMobile';

export default function CustomerProfile({ customer, show }) {
    const [memo, setMemo]                 = useState(customer.memo || '');
    const patch                           = resources.usePatch('Customer')[1];
    const [isLoadingMemo, setLoadingMemo] = useState(false);
    const [isEditingMemo, setShowEditing] = useState(false);
    const [customerState, setCustomer]    = useState(customer)
    return <div hidden={ !show }>
        <ComponentWeb>
            <KirokuCardWeb>
                <div className="p-3">
                    <Memo setMemo={setMemo}
                          memo={memo}

                          isEditingMemo={ isEditingMemo }
                          setShowEditing={ setShowEditing }

                          isLoadingMemo={ isLoadingMemo }
                          handleSubmit={() => {
                                  setLoadingMemo(true);
                                  patch(customer.id, { memo: memo }).then((customerResponse) => {
                                      setCustomer(customerResponse)
                                      setLoadingMemo(false);
                                      setShowEditing(false);
                                  });
                          }}
                          handleCancel={() => {
                              setShowEditing(!isEditingMemo)
                              setMemo(customerState.memo || '')
                          }}
                    />
                </div>
            </KirokuCardWeb>
            <div className={ 'pb-3' }/>
            <ProfileTab customer={customer}/>
        </ComponentWeb>
        <ComponentMobile>
            <div className='card padding-info mb-2'>
                <Memo setMemo={setMemo}
                      memo={memo}

                      isEditingMemo={ isEditingMemo }
                      setShowEditing={ setShowEditing }

                      isLoadingMemo={ isLoadingMemo }
                      handleSubmit={() => {
                          setLoadingMemo(true);
                          patch(customer.id, { memo: memo }).then((customerResponse) => {
                              setCustomer(customerResponse)
                              setLoadingMemo(false);
                              setShowEditing(false);
                          });
                      }}
                      handleCancel={() => {
                          setShowEditing(!isEditingMemo)
                          setMemo(customerState.memo || '')
                      }}
                />
            </div>
            <ProfileTabMobile customer={customer}/>
        </ComponentMobile>
    </div>;
}

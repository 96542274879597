import React, { useState }        from 'react';
import {
    KirokuButton, KirokuDropdownResetLabelSelected, KirokuModal, KirokuModalBody, KirokuModalFooter, KirokuModalHeader,
}                                 from '../../../ui';
import { getDropDownItems }       from './index';
import { useTranslation }         from 'react-i18next';
import useQuestions               from '../MakeSurvey/useQuestions';
import { getHeadingNextQuestion } from '../../Records/sevicesInRecord';

export default function YesNo({ isOpen, question, confirm, cancellation }) {
    const { update, questions }  = useQuestions();
    const defaultNextQuestion    = {
        id   : question.nextQuestion,
        label: getHeadingNextQuestion(question, questions).general,
    };
    const defaultNextQuestionYes = {
        id   : question.nextQuestionYes,
        label: getHeadingNextQuestion(question, questions).yes,
    };
    const defaultNextQuestionNo  = {
        id   : question.nextQuestionNo,
        label: getHeadingNextQuestion(question, questions).no,
    };

    const [nextQuestion, setNextQuestion]       = useState(defaultNextQuestion);
    const [nextQuestionYes, setNextQuestionYes] = useState(defaultNextQuestionYes);
    const [nextQuestionNo, setNextQuestionNo]   = useState(defaultNextQuestionNo);
    const { t }                                 = useTranslation();

    const disabledCheck = () => {
        if (nextQuestion.id) {
            return !nextQuestion;
        }
        return !(nextQuestionYes.id && nextQuestionNo.id);
    };

    return (
        <KirokuModal show={isOpen}>
            <KirokuModalHeader color={'card-web'}>
                {t('createSurvey.QuestionOrder', { index: question.index + 1 })}
            </KirokuModalHeader>
            <KirokuModalBody>
                <div className='p-3'>
                    <b>{question.heading}</b>
                    <p>{question.content} </p>
                    <p>{t('createSurvey.IfAnswerIsYesThenSkipTo')}</p>
                    <KirokuDropdownResetLabelSelected
                        title={nextQuestionYes.label}
                        items={getDropDownItems(question, questions)}
                        onSelect={(event) => {
                            if (event.kirokuSelected.value > question.index) {
                                setNextQuestion({
                                    id   : '',
                                    label: '',
                                });
                                setNextQuestionYes({
                                    id   : event.kirokuSelected.value,
                                    label: event.kirokuSelected.label,
                                });
                            }
                        }}
                    />
                    <hr/>
                    <p>{t('createSurvey.IfAnswerIsNoThenSkipTo')}</p>
                    <KirokuDropdownResetLabelSelected
                        title={nextQuestionNo.label}
                        items={getDropDownItems(question, questions)}
                        onSelect={(event) => {
                            if (event.kirokuSelected.value > question.index) {
                                setNextQuestion({
                                    id   : '',
                                    label: '',
                                });
                                setNextQuestionNo({
                                    id   : event.kirokuSelected.value,
                                    label: event.kirokuSelected.label,
                                });
                            }
                        }}
                    />
                    <hr/>
                    <p>{t('createSurvey.OrAnswerAndSkipTo')}</p>
                    <KirokuDropdownResetLabelSelected
                        title={nextQuestion.label}
                        items={getDropDownItems(question, questions)}
                        onSelect={(event) => {
                            if (event.kirokuSelected.value > question.index) {
                                setNextQuestionNo({
                                    id   : '',
                                    label: '',
                                });
                                setNextQuestionYes({
                                    id   : '',
                                    label: '',
                                });
                                setNextQuestion({
                                    id   : event.kirokuSelected.value,
                                    label: event.kirokuSelected.label,
                                });
                            }
                        }}
                    />
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton
                    disabled={disabledCheck()}
                    onClick={() => {
                        confirm();
                        delete question.unrequited;
                        update({
                            ...question,
                            nextQuestion   : nextQuestion.id,
                            nextQuestionYes: nextQuestionYes.id,
                            nextQuestionNo : nextQuestionNo.id,
                        });
                    }}
                    color={'primary'}>
                    {t('createSurvey.Confirm')}
                </KirokuButton>
                <KirokuButton onClick={() => {
                    cancellation();
                    setNextQuestion(defaultNextQuestion);
                    setNextQuestionNo(defaultNextQuestionNo);
                    setNextQuestionYes(defaultNextQuestionYes);
                }} color={'white'}>
                    {t('createSurvey.Cancel')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

import React, { useRef }  from 'react';
import { Link }           from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dropdown, { Item } from '../../ui/v3/Dropdown';
import { authentication } from '@kiroku/api';
import { useHistory }     from 'react-router-dom';

const DropDownUser = () => {

    const { t }            = useTranslation();
    const { user, logout } = authentication.useAuthenticatedContext();
    const history          = useHistory();
    const ref              = useRef(null);

    return (
        <div className='header-user-avatar'>
            <div>
                <div className="avatar"
                     ref={ref}
                     style={{ backgroundImage: `url(${user.avatar ? user.avatar : '/assets/img/profile-ic.svg'})` }}
                />
            </div>
            <div>
                <Dropdown defaultOpen={false} controllerRef={ref}>
                    <Item>
                        {user && <Link to={{
                            pathname : `/users/edit-my-profile`,
                            state: {
                                user: {
                                    id: user.id,
                                }
                            }
                        }}>
                            <div className="item-user-profile">
                                <i className="fa fa-user" style={{ fontSize: '1.3em' }}/>
                                <span className='ml-3'><strong
                                    style={{ textTransform: 'uppercase' }}>{user.name}</strong></span>
                            </div>
                        </Link>}
                    </Item>
                    <Item>
                        <div className="item-user-profile" onClick={() => {
                            logout();
                            history.push('/login');
                        }}>
                            <i className="fa fa-power-off" style={{ fontSize: '1.3em' }}/>
                            <span className='ml-3'>{t('Dashboard.Logout')}</span>
                        </div>
                    </Item>
                </Dropdown>
            </div>
        </div>
    );
};
export default DropDownUser;

export default [
    {
        menuLink: 'Menu',
    }, {
        pathUrl    : '/ ',
        pageName   : 'Home',
        icon       : 'fas fa-home fa-lg',
        permissions: ['leader', 'admin', 'staff'],
    }, {
        pathUrl    : '/surveys',
        pageName   : 'SurveyTemplates',
        icon       : 'fa fa-clipboard fa-lg',
        permissions: ['leader', 'admin', 'staff'],
    }, {
        pathUrl    : '/records',
        pageName   : 'Records',
        icon       : 'fas fa-file-signature fa-lg',
        permissions: ['leader', 'admin', 'staff'],
    }, {
        pathUrl    : '/teams',
        pageName   : 'Team',
        icon       : 'fa fa-users fa-lg',
        permissions: ['leader', 'admin', 'staff'],
    }, {
        pathUrl    : '/customers',
        pageName   : 'Customers',
        icon       : 'fa fa-user fa-lg',
        permissions: ['leader', 'admin', 'staff'],
    }, {
        pathUrl    : '/users',
        pageName   : 'Staffs',
        icon       : 'fa fa-user-circle fa-lg',
        permissions: ['leader', 'admin', 'staff'],
    }, {
        pathUrl    : '/organization',
        pageName   : 'Organization',
        icon       : 'fa fa-briefcase fa-lg',
        permissions: ['leader', 'admin', 'staff'],
    }, {
        pathUrl    : '/exports',
        pageName   : 'AdminExport',
        icon       : 'fa fa-download fa-lg',
        isWeb      : true,
        permissions: ['leader', 'admin'],
    }, {
        pathUrl    : '/analysis',
        pageName   : 'Analysis',
        icon       : 'fas fa-chart-line fa-lg',
        permissions: ['leader', 'admin', 'staff'],
    }, {
        pathUrl    : '/plans',
        pageName   : 'Planning',
        icon       : 'fa fa-list-alt fa-lg',
        permissions: ['leader', 'admin', 'staff'],
    }, {
        pathUrl    : '/customers-monitoring',
        pageName   : 'CustomersMonitoring',
        icon       : 'fa fa-tasks fa-lg',
        permissions: ['leader', 'admin', 'staff'],
    }, {
        pathUrl    : '/logs',
        pageName   : 'Logs',
        icon       : 'fa fa-file fa-lg',
        permissions: ['leader', 'admin', 'staff'],
    }, {
        pathUrl    : '/surfly',
        pageName   : 'supportPage',
        icon       : '',
        iconImg    : 'support.svg',
        permissions: ['leader', 'admin', 'staff'],
    }, {
        pathUrl    : '/reports',
        pageName   : 'ReportsTab',
        icon       : 'far fa-chart-bar fa-lg',
        permissions: ['leader', 'admin', 'staff'],
        isWeb     : true,
    }, {
        pathUrl    : '/deleted',
        pageName   : 'Deleted',
        icon       : 'fa fa-trash fa-lg',
        permissions: ['leader', 'admin', 'staff'],
    }
];

import React, { Suspense }   from 'react';
import ReactDOM              from 'react-dom';
import './index.css';
import App                   from './app/App';
import * as serviceWorker    from './serviceWorker';
import { Provider }          from 'react-redux';
import store                 from './app/store';
import { KirokuProvider }    from '@kiroku/api';
import client                from './kirokuAPIClient.js';
import metadata              from './metadata';
import { KirokuPermissions } from './app/HOC/KirokuPermissions';
import i18n                  from './i18n/i18n';
import smoothScroll          from '@sphinx-software/smoothscroll';
import { ScrollProvider }    from './ui/Scrolling.js';
import config                from './config';
import moment                from 'moment';
import { SentryProvider }    from './Sentry/Sentry';
import ErrorBoundary         from './Sentry/ErrorBoundary';
import { MixpanelProvider }  from './Mixpanel/MixPanel';
import { defaultLanguage }   from './app/Login/Languages';

i18n.changeLanguage(defaultLanguage);
moment.locale(defaultLanguage);

ReactDOM.render(
    <Suspense fallback='loading'>
        <SentryProvider options={config.sentry} enabled={config.sentry.enabled}>
            <MixpanelProvider options={config.mixpanel}>
                <Provider store={store}>
                    <KirokuProvider client={client}>
                        <ScrollProvider smoothScroll={smoothScroll}>
                            <KirokuPermissions metadata={metadata}>
                                <ErrorBoundary>
                                    <App/>
                                </ErrorBoundary>
                            </KirokuPermissions>
                        </ScrollProvider>
                    </KirokuProvider>
                </Provider>
            </MixpanelProvider>
        </SentryProvider>
    </Suspense>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React                           from 'react';
import { KirokuCardWeb, KirokuLoader } from '../../../ui';
import { useContentful }               from '../../../contentful/useContentful';

function AnnouncementItem({ announcement }) {

    return (
        <KirokuCardWeb>
            <div className="d-flex">
                <div className={'d-flex justify-content-center align-items-center p-2'}>
                    <img src={'/assets/img/mail.svg'} alt="mail.svg"/>
                </div>
                <div style={{ padding: 10 }}>
                    {announcement.url ?
                        (
                            <a href={announcement.url} className='announcement-label' rel="noopener noreferrer"
                               target="_blank">
                                {announcement.title}
                            </a>
                        ) : (
                            <div className='announcement-label'>
                                {announcement.title}
                            </div>
                        )
                    }
                    <div>
                        {announcement.description}
                    </div>
                </div>
            </div>
        </KirokuCardWeb>
    );
}

export default function Announcement() {

    const [announcement, isLoading] = useContentful('announcement');

    return (
        <KirokuLoader loading={isLoading}>
            {!isLoading && announcement.items.map((item, i) => (
                <div key={i} className='pt-3 pb-3'>
                    <AnnouncementItem announcement={item.fields}/>
                </div>
            ))}
        </KirokuLoader>
    );
}

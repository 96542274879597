import React, { useEffect, useState } from 'react';
import RecordProvider                 from './ConditionBuilder/RecordProvider';
import './record.css';
import { CustomerProvider }           from './ConditionComponent/ModalSelecteCustomers/CustomerProvider';
import BuildRecordsComponent          from './BuildRecordsComponent';
import { resources }                  from '@kiroku/api';
import { useProfile }                 from '../useProfile';

export default function Records(props) {
    const {
              result, search,
              cancelAPISearch,
          }                             = resources.useSearch('Customer');
    const [profile]                     = useProfile();
    const [isLoadCustomerTagUser,
              setIsLoadCustomerTagUser] = useState(false);

    useEffect(() => {
        search({
            type : 'all',
            users: profile.id,
        }).then( () => {
            setIsLoadCustomerTagUser(true);
        });
        return () => {
            cancelAPISearch();
        }
    }, [search, profile, cancelAPISearch]);

    const initConditionAnalysis = {
        customerId : [],
        templateId : [],
        updaterId : [],
        startDate: null,
        endDate: null
    }

    return (
        <div className='default-background-color'>
            <div className={ 'padding-responsive' }>
                {isLoadCustomerTagUser ?
                    <RecordProvider  customerDefault={ result }
                                     analysisCondition={ props.location.state ? props.location.state.AnalysisCondition : initConditionAnalysis }
                    >
                        <CustomerProvider customerDefault={ result }>
                            <BuildRecordsComponent/>
                        </CustomerProvider>
                    </RecordProvider> : null
                }
            </div>
        </div>
    );
}

import React, { useState }    from 'react';
import { useTranslation }     from 'react-i18next';
import { KirokuButton }       from '../../../ui';
import { useHistory }         from 'react-router-dom';
import { authentication }     from '@kiroku/api';
import { InputPassword }      from '../UserCreator/UserCreator';
import useNotification        from '../../Layout/useNotification';
import { WrapperPermissions } from '../../HOC';
import TwoFactorComponent     from './TwoFactorComponent';
import moment                 from 'moment';

export default function TabChangePassword({ show, resultUser }) {
    const history                                         = useHistory();
    const { t }                                           = useTranslation();
    const [messageErrorFallPass, setMessageErrorFallPass] = useState(false);
    const [isDisableBtnSubmit, setDisableBtnSubmit]       = useState(true);
    const setUpdatePass                                   = authentication.useChangePassword()[1];

    const { notification } = useNotification();

    const [oldPassword, setOldPassword] = useState({
        value: '', error: false,
    });
    const [newPassword, setNewPassword] = useState({
        value: '', error: false,
    });

    const [confirmNewPassword, setConfirmNewPassword] = useState({
        value: '', error: false,
    });


    const handleChangeInputOldPassword = event => {
        setOldPassword({
            value: event.target.value,
            error: event.target.value.length < 6,
        });
        setMessageErrorFallPass(false);
        setDisableBtnSubmit(event.target.value.length < 6 || newPassword.value.length < 6 || confirmNewPassword.value.length < 6 || confirmNewPassword.value !== newPassword.value);
    };

    const handleChangeInputNewPassword = event => {
        setNewPassword({
            value: event.target.value,
            error: event.target.value.length < 6,
        });
        if (confirmNewPassword.value.length) {
            setConfirmNewPassword({
                ...confirmNewPassword,
                error: confirmNewPassword.length < 6 || event.target.value !== confirmNewPassword.value,
            });
        }
        setDisableBtnSubmit(event.target.value.length < 6 || oldPassword.value.length < 6 || confirmNewPassword.value.length < 6 || event.target.value !== confirmNewPassword.value);
    };

    const handleChangeInputConfirmNewPassword = event => {
        setConfirmNewPassword({
            value: event.target.value,
            error: event.target.value.length < 6 || event.target.value !== newPassword.value,
        });
        setDisableBtnSubmit(event.target.value.length < 6 || newPassword.value.length < 6 || oldPassword.value.length < 6 || event.target.value !== newPassword.value);
    };

    const handleSubmitChangePassword = () => {
        setDisableBtnSubmit(true);
        setUpdatePass(resultUser.id, {
            last_updated_password_at: moment().unix(),
            current_password        : oldPassword.value,
            new_password            : newPassword.value,
            confirm_password        : confirmNewPassword.value,
        }).then((res) => {

            if (res.code === 'KIROKU_SUCCESS') {
                setOldPassword({
                    value: '', error: false,
                });
                setNewPassword({
                    value: '', error: false,
                });
                setConfirmNewPassword({
                    value: '', error: false,
                });
                notification(t('message.UpdateSuccess'), 'success');
            }

        }).catch(err => {
            setMessageErrorFallPass(true);
            console.log(err.message);
        });
    };

    return (<div hidden={ !show }>
        <div className={ 'col-md-6 col-12 col-lg-6 p-0' }>
            <div className="pb-15px">
                <div className={ 'font-size-14' }>{ t('common.OldPassword') }:</div>
                <div className="pt-10">
                    <span className='personal-info'>
                        <div style={ { position: 'relative' } }>
                            <InputPassword
                                value={ oldPassword.value }
                                name='oldPassword'
                                onChange={ handleChangeInputOldPassword }/>
                        </div>
                    </span>
                    <p hidden={ !oldPassword.error }
                       style={ {
                           color   : 'red',
                           fontSize: '13px',
                       } }>
                        { t('errors.userDetail.mustNotNull') }
                    </p>
                    <p hidden={ !messageErrorFallPass }
                       style={ {
                           color   : 'red',
                           fontSize: '13px',
                       } }>
                        { t('errors.userDetail.currentPassword') }
                    </p>
                </div>
            </div>
            <div className="pb-15px">
                <div className={ 'font-size-14' }>{ t('common.NewPassword') }:</div>
                <div className="pt-10">
                    <span className='personal-info'>
                        <div style={ { position: 'relative' } }>
                            <InputPassword
                                value={ newPassword.value }
                                name='newPassword'
                                onChange={ handleChangeInputNewPassword }/>
                        </div>
                    </span>
                    <p hidden={ !newPassword.error }
                       style={ {
                           color   : 'red',
                           fontSize: '13px',
                       } }>
                        { t('errors.userDetail.mustNotNull') }
                    </p>
                </div>
            </div>
            <div className="pb-15px">
                <div className="font-size-14">{ t('common.ConfirmNewPassword') }:</div>
                <div className="pt-10">
                    <span className='personal-info'>
                    <div style={ { position: 'relative' } }>
                        <InputPassword
                            value={ confirmNewPassword.value }
                            name='confirmPassword'
                            onChange={ handleChangeInputConfirmNewPassword }/>
                    </div>
                </span>
                    <p hidden={ !confirmNewPassword.error }
                       style={ {
                           color   : 'red',
                           fontSize: '13px',
                       } }>
                        { t('errors.userDetail.notMatch') }
                    </p>
                </div>
            </div>
            <div className='pb-3'>
                <div className='fix-center'>
                    <div className='d-flex' style={ { width: 200 } }>
                        <KirokuButton
                            onClick={ () => {
                                history.goBack();
                            } }
                            color='white'
                        >
                            { t('common.Cancel') }
                        </KirokuButton>
                        <div className='pl-1 pr-1'/>
                        <KirokuButton
                            disabled={ isDisableBtnSubmit }
                            onClick={ handleSubmitChangePassword }
                            color='primary'
                        >
                            { t('common.Update') }
                        </KirokuButton>
                    </div>
                </div>
            </div>
        </div>
        <WrapperPermissions
            resource-permission={ '|userEditor|setups2FA|' }
        >
            <div>
                <hr/>
                <TwoFactorComponent resultUser={ resultUser }/>
            </div>
        </WrapperPermissions>
    </div>);
}

import {
    KirokuModal, KirokuModalHeader,
    KirokuInput, KirokuModalBody,
    KirokuButton, KirokuLoader, KirokuModalFooter, KirokuCheckboxBorder,

} from '../../../ui';
import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import lodash                         from 'lodash';
import If                             from '../../If';
import { resources }                  from '@kiroku/api';
import '../../Recordsv2/record.css';
import SearchNotFound                 from '../../../ui/SearchNotFound';

export default function ModalSelectCustomers(props) {

    const { isShowModal, cancel, confirm, customerIds } = props;
    const { result, search, loading, cancelAPISearch } = resources.useSearch('Customer', { type: 'all' });
    const { t }                                        = useTranslation();
    const [customersOnModal, setCustomerOnModal]       = useState([]);
    const [customers, setCustomers]                    = useState([]);
    const [nameKey, setNameKey]                        = useState('');

    useEffect(() => {
        if(result.length && customerIds) {
            setCustomers(result.map(customer => {
                return {
                    ...customer,
                    isChecked: !!customerIds.includes(customer.id)
                }
            }));
            setCustomerOnModal(result.map(customer => {
                return {
                    ...customer,
                    isChecked: !!customerIds.includes(customer.id)
                }
            }));
        } else {
            setCustomers(result);
            setCustomerOnModal(result);
        }
    }, [result, customerIds]);

    useEffect(() => {
        if (isShowModal) {
            search({ type: 'all' });
        }
        return () => {
            cancelAPISearch();
        }
    }, [search, isShowModal, cancel, cancelAPISearch]);

    const handleCheckAll = () => {
        let customersSelected = customersOnModal.map(customer => {
            return {
                ...customer,
                isChecked: lodash.findIndex(customersOnModal, (ctm) => !ctm.isChecked) !== -1,
            };
        });
        setCustomerOnModal(customersSelected);
        setCustomers(customers.map(ctm => {
            const indexCustomerSelected = lodash.findIndex(customersSelected, (customer) => ctm.id === customer.id);
            if (indexCustomerSelected !== -1) {
                return customersSelected[ indexCustomerSelected ];
            }
            return ctm;
        }));
    };

    const setInitModal = () => {
        setNameKey('');
        setCustomers(customers.map(customer => {
            return {
                ...customer,
                isChecked: false,
            };
        }));
        setCustomerOnModal(customers.map(customer => {
            return {
                ...customer,
                isChecked: false,
            };
        }));
    };

    const handleConfirm = () => {
        confirm(customers.filter(ctm => ctm.isChecked));
        setInitModal();
    };

    const handleCancel = () => {
        cancel();
        setInitModal();
    };

    const handleSearchName = (nameKey) => {
        const inputValue = nameKey.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        setCustomerOnModal(customers.filter(customer => customer.name.toLowerCase().search(inputValue.toLowerCase()) !== -1));
    };

    const handleSelectCustomer = (idSelected) => {
        setCustomerOnModal(customersOnModal.map(ctm => {
            if (idSelected === ctm.id) {
                return {
                    ...ctm,
                    isChecked: !ctm.isChecked,
                };
            }
            return ctm;
        }));
        setCustomers(customers.map(ctm => {
            if (idSelected === ctm.id) {
                return {
                    ...ctm,
                    isChecked: !ctm.isChecked,
                };
            }
            return ctm;
        }));
    };


    return (
        <KirokuModal show={isShowModal}>
            <KirokuModalHeader>
                <div className={ 'font-size-18' }>{t('Surveys.selectCustomer')}</div>
            </KirokuModalHeader>
            <KirokuModalBody className={'pt-3 pl-3 pr-3'}>
                <div className="mb-3">
                    <KirokuInput
                        value={nameKey}
                        onChange={(event) => {
                            handleSearchName(event.target.value);
                            setNameKey(event.target.value);
                        }}
                        placeholder={t('Customer.EnterCustomersName')}
                    />
                </div>
                <div id="body-modal-as">
                    { customersOnModal.length ? <div
                        className={ 'checkbox-bigger p-3 border-bottom d-flex justify-content-between' }
                        style={{ border: 'solid 1px #dfe2e5'}}
                    >
                        <div className={ 'font-size-16'}>{t('Customer.CustomerList')}</div>
                        <div hidden={ !customersOnModal.length }>
                            <div className='text-primary font-size-16' style={{ cursor: 'pointer' }}>
                                <span hidden={lodash.findIndex(customersOnModal, (ctm) => !ctm.isChecked) === -1}
                                      className={ 'font-size-16'}
                                      onClick={() => {
                                          handleCheckAll();
                                      }}
                                >{t('common.SelectAll')}</span>
                                <span hidden={lodash.findIndex(customersOnModal, (ctm) => !ctm.isChecked) !== -1}
                                      onClick={() => {
                                          handleCheckAll();
                                      }}
                                      className={ 'font-size-16'}
                                >{t('common.UnSelect')}</span>
                            </div>
                        </div>
                    </div>: null }
                    <div className='background-color' style={ {
                        overflow : 'scroll',
                        minHeight: 'calc(100vh - 360px)',
                        maxHeight: 'calc(100vh - 360px)',
                        height   : 'auto',
                    } }>
                        <section className="scroll-data-select-customer bg-white">
                            <KirokuLoader loading={loading}>
                                {customersOnModal.length ?
                                <If statement={customersOnModal}>
                                    {() => customersOnModal.map((customer, index) => (
                                        <div key={customer.id}>
                                            <div className={'checkbox-bigger cursor-pointer'}
                                                 onClick={(event) => {
                                                     event.preventDefault();
                                                     handleSelectCustomer(customer.id);
                                                 } }
                                            >
                                                <KirokuCheckboxBorder
                                                    key={index}
                                                    id={`${customer.id}-${index}`}
                                                    checked={!!customer.isChecked}
                                                    onChange={() => handleSelectCustomer(customer.id)}
                                                    content={ <span
                                                        className={ ' cursor-pointer font-size-14' }>{ customer.name }</span> }
                                                />
                                            </div>
                                        </div>

                                    ))}
                                </If> : <SearchNotFound/> }
                            </KirokuLoader>
                        </section>
                    </div>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={handleCancel}
                              style={ { height: 36}}
                              color={'light'}
                >
                    {t('common.Cancel')}
                </KirokuButton>
                <KirokuButton color={'primary'}
                              style={ { height: 36}}
                              onClick={handleConfirm}
                >
                    {t('common.Apply')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

import React                     from 'react';
import { KirokuDateRangePicker } from '../../../ui';
import { useTranslation }        from 'react-i18next';

export default function SelectDateRange(props) {

    const { t } = useTranslation();
    return (
        <>
            <div className={ 'pb-2' }>{ t('RecordList.ServiceAt') }</div>
            <div className="date-time-range-report">
                <KirokuDateRangePicker
                    minimumNights={ 0 }
                    selectDateRange={ (event) => event }
                    { ...props }
                />
            </div>
        </>
    );
}

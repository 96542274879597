import React              from 'react';
import { KirokuRadio }    from '../../../ui';
import useHeadingQuestion from '../../Records/useSurvey/useHeadingQuestion';

export default function YesNoQuestion(props) {

    const { question, value = '' } = props;

    const { heading, t } = useHeadingQuestion(question);

    return (<div className={'content-question-type'}>
        <div className='wraper-question none-border-question pt-2 pt-2'>
            <div className="background-question">
                {heading && <div className="text-overflow-elip font-heading-questions pb-10px">{ heading }</div> }
                <div className='padding-content-question'>
                    <span className={'font-content-question'}>
                        {question.unrequited ? t('createRecord.option'):''}{question.content}
                    </span>
                </div>
            </div>
            <div className='answer-question padding-16-answer-question size-min-select-question'>
                <KirokuRadio onChange={() => {}}
                             checked={value === 'Yes'}
                             readOnly
                >
                    <div className={'text-form-area'}>{t('createRecord.Yes')}</div>
                </KirokuRadio>
                <KirokuRadio onChange={() => {}}
                             checked={value === 'No'}
                             readOnly
                >
                    <div className={'text-form-area'}>{t('createRecord.No')}</div>
                </KirokuRadio>
            </div>
        </div>
    </div>);
}

import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import { resources }                  from '@kiroku/api';
import {
    ComponentMobile, ComponentWeb, KirokuSectionDropdownShowMore, KirokuSectionDropdownShowMoreMobile,
}                                     from '../../../ui';
import { getGroupedOptions }          from '../../Converter';
import { useConditionTag }            from '../../Customer/CustomerList/useConditionFromParams';

export default function DropdownSelectCustomers(props) {
    const { t }                               = useTranslation();
    const { result, search, cancelAPISearch } = resources.useSearch('Customer');
    const [customers, setCustomers]           = useState([]);
    const [currentTags, setCurrentTags]       = useState([]);
    const [currentCustomerTag, getInfoTag]    = useConditionTag('Customer');

    useEffect(() => {
        search({ type: 'all' });
        return () => {
            cancelAPISearch();
        };
    }, [search, cancelAPISearch]);

    useEffect(() => {
        setCustomers(result);
    }, [result]);

    useEffect(() => {
        if (props.customerTagIds) {
            getInfoTag(props.customerTagIds);
        }
    }, [props.customerTagIds, getInfoTag]);

    useEffect(() => {
        if (currentCustomerTag) {
            setCurrentTags(currentCustomerTag);
        }
    }, [currentCustomerTag]);

    let customerOptions = getGroupedOptions('Customer', customers);

    const handleChangeTags = (options) => {
        setCurrentTags(options);
        props.handleChangeCustomerTags(options);
    };

    return (
        <div>
            <ComponentWeb>
                <div className="pb-3">
                    <KirokuSectionDropdownShowMore
                        isSearchTags
                        tagType={ 'Customer' }
                        placeholder={ t('Customer.placeholderSearch') }
                        onChange={ options => handleChangeTags(options) }
                        handleSearchTags={ props.handleSearchTags }
                        value={ currentTags }
                        options={ customerOptions }
                    />
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <KirokuSectionDropdownShowMoreMobile
                    isSearchTags
                    tagType={ 'Customer' }
                    placeholder={ t('Customer.placeholderSearch') }
                    onChange={ options => handleChangeTags(options) }
                    handleSearchTags={ props.handleSearchTags }
                    value={ currentTags }
                    options={ customerOptions }
                />
            </ComponentMobile>
        </div>


    );

}

import _ from 'lodash';

export const SET_TEMPLATES                       = 'SET_TEMPLATES';
export const CHECKED_TEMPLATES                   = 'CHECKED_TEMPLATES';
export const HANDLE_CHANGE_KEYWORD               = 'HANDLE_CHANGE_KEYWORD';
export const CHANGE_SELECTED_TAB_NAME            = 'CHANGE_SELECTED_TAB_NAME';
export const HANDLE_CHECK_ALL_TEMPLATES          = 'HANDLE_CHECK_ALL_TEMPLATES';
export const HANDLE_CHANGE_KEYWORD_SELECTED      = 'HANDLE_CHANGE_KEYWORD_SELECTED';
export const HANDLE_CHECK_ALL_TEMPLATES_SELECTED = 'HANDLE_CHECK_ALL_TEMPLATES_SELECTED';
export const HANDLE_CHECK_TEMPLATE_SELECTED      = 'HANDLE_CHECK_TEMPLATE_SELECTED';
export const SET_TEMPLATE_MODAL_TAB              = 'SET_TEMPLATE_MODAL_TAB';
export const HANDLE_RESET_CONDITIONS             = 'HANDLE_RESET_CONDITIONS';

const checkTemplateChecked = (templateChecked, templates) => {
    return templates.map(template => {
        return {
            ...template,
            checked: _.findIndex(templateChecked, ['id', template.id]) !== -1,
        };
    });
};

export default function (state, action) {
    switch (action.type) {
        case SET_TEMPLATES:
            return {
                ...state,
                templates                  : action.templates,
                templateShowInModal        : action.templates,
                templateListSelected       : action.templates.filter(template => template.checked),
                templateSelectedShowInModal: action.templates.filter(template => template.checked),
            };
        case CHECKED_TEMPLATES:
            let templateShow           = [...state.templateShowInModal];
            templateShow[action.index] = {
                ...templateShow[action.index],
                checked: !templateShow[action.index].checked,
            };

            let indexSurvey              = _.findIndex(state.templates, (itemSurvey) => itemSurvey.id === templateShow[action.index].id);
            let stateLibSurveys          = [...state.templates];
            stateLibSurveys[indexSurvey] = {
                ...stateLibSurveys[indexSurvey],
                checked: !stateLibSurveys[indexSurvey].checked,
            };
            return {
                ...state,
                templateShowInModal: templateShow,
                templates          : stateLibSurveys,
            };

        case HANDLE_CHECK_ALL_TEMPLATES:
            let libSurveyNew = [...state.templates];
            let surveyNew    = state.templateShowInModal.map(itemSurvey => {
                let indexItemSurveyInLibSurvey = _.findIndex(libSurveyNew, (surveys) => surveys.id === itemSurvey.id);
                if (indexItemSurveyInLibSurvey !== -1) {
                    libSurveyNew[indexItemSurveyInLibSurvey] = {
                        ...libSurveyNew[indexItemSurveyInLibSurvey],
                        checked: !(_.findIndex(state.templateShowInModal, (ctm) => (!ctm.checked)) === -1),
                    };
                }
                return {
                    ...itemSurvey,
                    checked: !(_.findIndex(state.templateShowInModal, (ctm) => (!ctm.checked)) === -1),
                };
            });
            return {
                ...state,
                templateShowInModal: surveyNew,
                templates          : libSurveyNew,
            };
        case HANDLE_CHANGE_KEYWORD:
            let keySearch = action.keywords.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
            let libSurvey = [...state.templates];
            libSurvey     = libSurvey.filter((itmSur) => itmSur.title.toLowerCase().search(keySearch.toLowerCase()) !== -1);
            return {
                ...state,
                templateShowInModal: libSurvey,
                keywords           : keySearch,
            };

        /*-----------SELECTED_TAB----------*/

        case CHANGE_SELECTED_TAB_NAME:
            if (action.selectedTabName === state.selectedTabName) {
                return state;
            }
            let templateList = [...state.templates].map(template => ({ ...template, checked: false }));
            state.templateListSelected.filter(tmp => tmp.checked)
                .forEach((item) => {
                    let indexSurveySelected           = _.findIndex(templateList, tmp => tmp.id === item.id);
                    templateList[indexSurveySelected] = {
                        ...templateList[indexSurveySelected],
                        checked: item.checked,
                    };
                });
            return {
                ...state,
                selectedTabName            : action.selectedTabName,
                keyword                    : '',
                keywordSelected            : '',
                templates                  : templateList,
                templateShowInModal        : templateList,
                templateListSelected       : state.templates.filter(item => item.checked),
                templateSelectedShowInModal: state.templates.filter(item => item.checked),
            };
        case HANDLE_CHANGE_KEYWORD_SELECTED:
            let keySearchSelected = action.keywordSelected.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
            let libSurveySelected = [...state.templateListSelected];
            libSurveySelected     = libSurveySelected.filter((itmSur) => itmSur.title.toLowerCase().search(keySearchSelected.toLowerCase()) !== -1);
            return {
                ...state,
                templateSelectedShowInModal: libSurveySelected,
                keywordSelected            : keySearchSelected,
            };

        case HANDLE_CHECK_ALL_TEMPLATES_SELECTED:
            let surveyListSelected = [...state.templateListSelected];
            let surveySelectedNew  = [...state.templateSelectedShowInModal].map(survey => {
                let indexSurvey = _.findIndex(surveyListSelected, surveys => surveys.id === survey.id);
                if (indexSurvey !== -1) {
                    surveyListSelected[indexSurvey] = {
                        ...surveyListSelected[indexSurvey],
                        checked: !(_.findIndex(state.templateSelectedShowInModal, (ctm) => (!ctm.checked)) === -1),
                    };
                }
                return {
                    ...survey,
                    checked: !(_.findIndex(state.templateSelectedShowInModal, (ctm) => (!ctm.checked)) === -1),
                };
            });
            return {
                ...state,
                templateSelectedShowInModal: surveySelectedNew,
                templateListSelected       : surveyListSelected,
                templateShowInModal        : checkTemplateChecked(surveyListSelected.filter(template => template.checked), state.templates),
                templates                  : checkTemplateChecked(surveyListSelected.filter(template => template.checked), state.templates),
            };

        case HANDLE_CHECK_TEMPLATE_SELECTED:
            let surveysSelected           = [...state.templateSelectedShowInModal];
            surveysSelected[action.index] = {
                ...surveysSelected[action.index],
                checked: !surveysSelected[action.index].checked,
            };

            let surveyIndex                      = _.findIndex(state.templateListSelected, (itemSurvey) => (itemSurvey.id === surveysSelected[action.index].id));
            let stateLibSurveysSelected          = [...state.templateListSelected];
            stateLibSurveysSelected[surveyIndex] = {
                ...stateLibSurveysSelected[surveyIndex],
                checked: !stateLibSurveysSelected[surveyIndex].checked,
            };

            return {
                ...state,
                templateSelectedShowInModal: surveysSelected,
                templateListSelected       : stateLibSurveysSelected,
                templateShowInModal        : checkTemplateChecked(stateLibSurveysSelected.filter(template => template.checked), state.templates),
                templates                  : checkTemplateChecked(stateLibSurveysSelected.filter(template => template.checked), state.templates),
            };
        case SET_TEMPLATE_MODAL_TAB:
            const templateSelected = state.templates.filter(template => template.checked);
            return {
                ...state,
                selectedTabName: templateSelected.length ? 'selectedTab' : 'optionsTab',
            };

        case HANDLE_RESET_CONDITIONS:
            return {
                ...state,
                keywords       : '',
                keywordSelected: '',
            }

        default :
            return state;
    }
}

import React, { useRef, useState } from 'react';
import KirokuButton                from './KirokuButton';
import { useTranslation }          from 'react-i18next';
import PreviewFileModal            from '../app/Customer/CustomerDetail/Documents/DocumentUI/PreviewFileModal';

function KirokuUploadFile({ isPreview, url, disabled, onChange, fileType }) {
    const { t }            = useTranslation();
    const inputUploadMedia = useRef(null);

    const [showModalPreview, setShowModalPreview] = useState(false);
    return (
        <div className="wrap-upload">
            {
                isPreview && url ? <PreviewFileModal
                    isShowModal={showModalPreview}
                    fileBePreview={{ type: fileType.split("/")[1], url }}
                    closeModal={() => setShowModalPreview(false)}
                /> : null
            }
            {url ?
                <div className="form-upload">
                    {/*<div className="image-upload"*/}
                    {/*     style={{*/}
                    {/*         backgroundImage: `url(${url})`,*/}
                    {/*         cursor         : `${isPreview && url ? 'pointer' : 'default'}`,*/}
                    {/*     }}*/}
                    {/*     onClick={isPreview ? () => setShowModalPreview(true) : null}*/}
                    {/*/>*/}
                    <img className="image-for-upload"
                         src={url}
                         style={{
                            cursor: `${isPreview && url ? 'pointer' : 'default'}`,
                         }}
                         alt="media-question"
                         onClick={isPreview ? () => {setShowModalPreview(true)} : null}
                    />
                    <div className="overlay-upload-file" hidden={disabled}>
                        <div className="content-upload">
                            <KirokuButton color={'success'} onClick={() => inputUploadMedia.current.click()}>
                                <i className="fas fa-cloud-upload-alt cursor-pointer"/>
                                <span>{t('createRecord.ChangePhoto')}</span>
                            </KirokuButton>
                        </div>
                        <input disabled={disabled} type='file' onChange={onChange}
                               accept="image/jpeg, image/png"
                               ref={inputUploadMedia}
                               hidden/>
                    </div>
                </div> :
                <div className='form-upload'>
                    <div className="image-upload-default"
                         onClick={() => inputUploadMedia.current.click()}
                         style={{ backgroundColor: 'white' }}>
                        <div className="overlay-upload-file-default">
                            <div className="content-upload">
                                <i className="fas fa-cloud-upload-alt"/>
                                <br/>
                                <span
                                    className={`change-photo ${disabled ? '' : 'cursor-pointer'}`}>{t('createRecord.AddAPhoto')}</span>
                            </div>
                        </div>
                        <input type='file' onChange={onChange}
                               accept="image/jpeg, image/png"
                               ref={inputUploadMedia}
                               hidden
                               disabled={disabled}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

export default KirokuUploadFile;

import React                                from 'react';
import { useTranslation }                   from 'react-i18next';
import { KirokuDropdownResetTitle }         from '../../ui';
import useDropDownData                      from '../useDropDownData';


export default function Condition(props) {

    const { t }                      = useTranslation();
    const { optionalDeletedScreens } = useDropDownData();

    const {
              currentScreen,
              searchDeleted, condition,
          } = props;

    const handlerOnSelectScreen = (event) => {
        searchDeleted({
            ...condition,
            page : 1,
            name : '',
            title: '',
            type : event.kirokuSelected.value,
        });
    };

    const ConditionComponent = currentScreen.condition;

    return (<div className="pb-1 pt-1 display-flex-web">
        <div style={{ width: 161 }}>
            <KirokuDropdownResetTitle
                classNameBtn={'btn-kiroku-white-del'}
                title={t(`DeletedList.${currentScreen.name}`)}
                items={optionalDeletedScreens}
                onSelect={handlerOnSelectScreen}
            />
        </div>
        <div className="p-2"/>
        <ConditionComponent searchDeleted={searchDeleted} condition={condition}/>
    </div>);
}

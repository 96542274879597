import React, { useCallback, useEffect, useState } from 'react';
import ReactDom                                    from 'react-dom';

export default function Dropdown({ defaultOpen, controllerRef, children }) {
    const [isOpen, setOpen] = useState(defaultOpen);
    const handleClick       = useCallback((event) => {
        if (event.target === controllerRef.current) {
            setOpen(!isOpen);
        } else {
            setOpen(false);
        }
    }, [controllerRef, isOpen]);

    useEffect(() => {
        const currentControllerElement = ReactDom.findDOMNode(controllerRef.current);

        currentControllerElement.addEventListener('click', handleClick);
        document.addEventListener('click', handleClick);

        return () => {
            currentControllerElement.removeEventListener('click', handleClick);

            document.removeEventListener('click', handleClick);
        };

    }, [handleClick, controllerRef]);

    return isOpen && (
        <div className="dropdown-kiroku dropdown-menu show">
            {children}
        </div>
    );
}

export function Item({ children }) {
    return (
        <div className="dropdown-item">
            {children}
        </div>
    );
}

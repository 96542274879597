import React                                                           from 'react';
import If                                                              from '../../If';
import { ComponentMobile, ComponentWeb, KirokuLoader, SearchNotFound } from '../../../ui';
import CustomerTable                                                   from './CustomerTable';
import CustomerList                                                    from './CustomerList';

export default function Customers({ loading, result, sort_by, order_by_type, changeOrder }) {
    const customers = result;

    const handleChangeOrderBy = (field) => {
        let typeOrder = '';
        if (field === 'delete_at') {
            typeOrder = order_by_type.delete_at === 'ASC' ? 'DESC' : 'ASC';
        } else {
            typeOrder = order_by_type.create_at === 'ASC' ? 'DESC' : 'ASC';
        }
        changeOrder(field, typeOrder);
    };

    return (
        <div>
            <KirokuLoader loading={loading}>
                <If statement={customers.length}
                    otherwise={<SearchNotFound/>}
                >
                    {() => <React.Fragment>
                        <ComponentWeb>
                            <CustomerTable
                                customers={customers}
                                sort_by={sort_by}
                                order_by_type={order_by_type}
                                handleChangeOrderBy={handleChangeOrderBy}
                            />
                        </ComponentWeb>
                        <ComponentMobile>
                            <CustomerList
                                customers={customers}
                            />
                        </ComponentMobile>
                    </React.Fragment>
                    }
                </If>
            </KirokuLoader>
        </div>
    )
}

import { useEffect } from 'react';
import { resources } from '@kiroku/api';

export function useCreatorLabel(creatorId) {
    const { result, search } = resources.useSearch('User', { type: 'id' });
    useEffect(() => {
        if (creatorId) {
            search({ type: 'id', id: [creatorId] });
        }
    }, [search, creatorId]);

    const labelName = result.map(item => item.name);

    return { labelName };
}

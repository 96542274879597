import React, { useEffect, useState }                                   from 'react';
import DropdownSelectSurvey                                             from './DropdownSelectSurvey';
import { useHistory }                                                   from 'react-router-dom';
import { KirokuButton, KirokuIconButton, KirokuInput, KirokuInputArea } from '../../../ui';

import { useTranslation }      from 'react-i18next';
import ComponentMobile         from '../../../ui/ComponentMobile';
import ModalDeleteSupport      from './ModalDeleteSupport';
import useNotification         from '../../Layout/useNotification';
import PlanInfo                from './PlanInfo/PlaningInfo';
import { useMixpanelTracking } from '../../../Mixpanel/useMixpanelTracking';

export default function SupportAction({ show, support, handleSubmit, plan, customer, isUpdate, isDisabled }) {
    const { t }                         = useTranslation();
    const [surveys, setSurveys]         = useState(support.surveys || []);
    const [target, setTarget]           = useState(support.target);
    const [description, setDescription] = useState(support.description);
    const [term, setTerm]               = useState(support.term);
    const [needs, setNeeds]             = useState(support.needs || '');
    const history                       = useHistory();
    const [showModal, setShowModal]     = useState('');
    const { notification }              = useNotification();
    const { trackingMixpanel }          = useMixpanelTracking();

    useEffect(() => {
        if (show) {
            setSurveys(support.surveys || []);
            setTarget(support.target);
            setDescription(support.description);
            setTerm(support.term);
            setNeeds(support.needs || '');
        }
    }, [support, show]);

    return (
        <div className={'padding-responsive'}>
            {isUpdate && <div>
                <div className={'d-flex justify-content-flex-end pb-3'}>
                    <KirokuIconButton onClick={() => setShowModal('delete')}/>
                </div>
            </div>}

            <PlanInfo plan={plan}/>

            <div className="pb-3"/>
            <div className={' bg-white'}>
                <div style={{ borderBottom: 'solid 1px #dfe2e5' }}>
                    <div className=" p-3">
                        <div className="label-info">{t('plans.customerNeeds')}</div>
                        <div className="pt-10">
                            <KirokuInputArea
                                onChange={(event) => setNeeds(event.target.value)}
                                value={needs || ''}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ borderBottom: 'solid 1px #dfe2e5' }}>
                    <div className=" p-3" >
                        <div className="label-info">{t('plans.objective')}</div>
                        <div className="pt-10">
                            <KirokuInput
                                onChange={(event) => setTarget(event.target.value)}
                                value={target || ''}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ borderBottom: 'solid 1px #dfe2e5' }}>
                    <div className=" p-3">
                        <div className="label-info">{t('plans.content')}</div>
                        <div className="pt-10">
                            <KirokuInputArea
                                onChange={(event) => setDescription(event.target.value)}
                                value={description || ''}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ borderBottom: 'solid 1px #dfe2e5' }}>
                    <div className=" p-3">
                        <div className="label-info">{t('plans.term-edit-form')}</div>
                        <div className="pt-10">
                            <KirokuInput
                                onChange={(event) => setTerm(event.target.value.replace(/[^0-9]/g, ''))}
                                value={term || ''}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ borderBottom: 'solid 1px #dfe2e5' }}>
                    <div className="col-md-6" style={{padding: '16px 16px 33px'}}>
                        <div className="label-info">{t('plans.surveyTemplate')}</div>
                        <div className="pt-10">
                            <DropdownSelectSurvey selectedSurvey={setSurveys}
                                                  show={show}
                                                  customerId={customer}
                                                  surveyDefault={(support && support.surveys) ? support.surveys : []}/>
                        </div>
                    </div>
                </div>

                <div className="d-flex p-3">
                    <KirokuButton onClick={() => history.goBack()} color={'light'}>
                        {t('plans.cancel')}
                    </KirokuButton>
                    <div className="p-2"/>
                    <KirokuButton onClick={() => {
                        let surveyValue = surveys.map(survey => {
                            return {
                                title: survey.title,
                                id   : survey.id,
                            }
                        });

                        let valueSubmit = {
                            target,
                            description,
                            term,
                            priority: parseInt(localStorage.getItem('prioritySupportMax')),
                            assignment : surveyValue,
                            unassignment: [],
                            plan_id : plan.id, needs,
                        };
                        handleSubmit(valueSubmit);
                    }}
                                  disabled={!target || !description || !term || isDisabled}
                                  color={'primary'}>{t('plans.submitSupport')}
                    </KirokuButton>
                </div>
            </div>
            <ComponentMobile>
                <div style={{ marginBottom: 80 }}/>
            </ComponentMobile>
            <ModalDeleteSupport show={showModal === 'delete'}
                                support={support}
                                closeModal={() => setShowModal('')}
                                searchAfterDelete={() => {
                                    trackingMixpanel('DELETE_SUPPORT', support)
                                    notification(t('message.DeleteSuccess'), 'success');
                                    history.push(`/plans/${plan.id}`);
                                }}
            />
        </div>
    );
}

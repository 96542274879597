import {
    KirokuModal, KirokuModalHeader,
    KirokuModalBody, KirokuCardList,
    KirokuModalFooter,
    KirokuButton, KirokuCheckboxBorder,
} from '../../../../../ui';
import { useTranslation }     from 'react-i18next';
import React                  from 'react';
import useDocumentAction      from '../useDocumentAction';
import lodash                 from 'lodash';
import { useDocumentContext } from '../DocumentContext';

export default function ModalSelectCustomers(props) {
    const { t }             = useTranslation();
    const { documentsData } = useDocumentContext();
    const {
              handleSelectDocument, handleSelectedAll,
              showModalConfirmDelDocument
          }                 = useDocumentAction();

    const handleCheckAll = () => {
        handleSelectedAll(lodash.findIndex(documentsData.documents, documentItem => !documentItem.isChecked) === -1);
    };

    const handleSelect = (documentId) => {
        handleSelectDocument(documentId);
    };

    return (
        <KirokuModal show={props.show}
                     onCloseModal={() => props.closeModal()}>
            <KirokuModalHeader>
                <h6 className="mb-3">{t('common.ChoiceDocument')}</h6>
            </KirokuModalHeader>
            <KirokuModalBody>
                <div id="body-modal-as">
                    <KirokuCardList
                        headerLeft={t('common.DocumentList')}
                        headerRight={
                            <div className='text-primary' style={{ cursor: 'pointer' }}>
                                <span
                                    hidden={lodash.findIndex(documentsData.documents, documentItem => !documentItem.isChecked) === -1}
                                    onClick={() => handleCheckAll()}
                                >{t('common.SelectAll')}</span>
                                <span
                                    hidden={lodash.findIndex(documentsData.documents, documentItem => !documentItem.isChecked) !== -1}
                                    onClick={() => handleCheckAll()}
                                >{t('common.UnSelect')}</span>
                            </div>
                        }
                    >
                        <section className="scroll-data-customer">
                            {documentsData.documents.map((document) => (
                                <div key={document.id}>
                                    <KirokuCheckboxBorder onChange={() => handleSelect(document.id)}
                                                          onClick={() => handleSelect(document.id)}
                                                          checked={ !!document.isChecked }
                                                          content={ document.name }
                                                          classCutLongText={ 'cut-long-text-document' }
                                    />
                                </div>
                            ))}
                        </section>
                    </KirokuCardList>
                </div>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton color={'white'}
                              onClick={() => {
                                  props.closeModal();
                              }}
                >
                    {t('common.Cancel')}
                </KirokuButton>
                <KirokuButton
                    disabled={lodash.findIndex(documentsData.documents, documentItem => documentItem.isChecked) === -1}
                    onClick={() => {
                        props.closeModal();
                        showModalConfirmDelDocument();
                    }}
                    color={'primary'}>
                    {t('common.Confirm')}
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

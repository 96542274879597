import React, { useEffect, useState } from 'react';
import { resources }                  from '@kiroku/api';
import If                             from '../../If';
import {
    ComponentMobile, ComponentWeb, KirokuButtonLink, KirokuDropdownResetLabelSelected, KirokuLabel, KirokuLoader,
    SearchNotFound,
}                                     from '../../../ui';
import '../planning.css';
import { useTranslation }             from 'react-i18next';
import moment                         from 'moment';
import { useHistory }                 from 'react-router-dom';
import ModalDeletePlan                from '../PlanningList/ModalDeletePlan';
import useNotification                from '../../Layout/useNotification';
import Supports                       from './Supports';
import Tooltip                        from 'react-tooltip-lite';
import queryString                    from 'query-string';
import momentTZ                       from 'moment-timezone';
import config                         from '../../../config';
import ModalConfirmSaveDraft          from './ModalConfirmSaveDraft';
import PlanningInfo                   from './PlanningInfo';
import ModalConfirmPublishPlan        from './ModalConfirmPublishPlan';
import ReviewForm                     from './Review/ReviewForm';
import { useMixpanelTracking }        from '../../../Mixpanel/useMixpanelTracking';
import KirokuDropdownSubMenu          from './KirokuDropdownSubMenu';
import Memo                           from './Memo';

export default function PlanningDetail(props) {
    const { t }                           = useTranslation();
    const [plan, loadDetail]              = resources.useDetail('Plan');
    const history                         = useHistory();
    const [showModal, setShowModal]       = useState('');
    const { notification }                = useNotification();
    const [exports]                       = resources.useExportExcel('ExportPlan');
    const { trackingMixpanel }            = useMixpanelTracking();

    const [resultUpdateReview, APIUpdateReview] = resources.useUpdate('ReviewPlan');
    useEffect(() => {
    }, [resultUpdateReview]);

    const [showForm, setShowForm]                         = useState(false);
    const [loadingAPI, setLoadingAPI]                     = useState(true);
    const [isLoadingReview, setIsLoadingReview]           = useState(false);
    const [isReviewAllSupported, setIsReviewAllSupported] = useState(false);

    useEffect(() => {
        if (props.match.params.id) {
            loadDetail(props.match.params.id).then(() => {
                setLoadingAPI(false);
            }).catch(() => {
                setLoadingAPI(false);
            });
        }
    }, [loadDetail, props.match.params.id]);

    const getItemActionPlaning = () => {
        if (plan.review_status === 0) {
            return [
                {
                    value    : 'plans',
                    label    : t('plans.exportPlans'),
                    component: <div> { t('plans.exportPlans') }</div>,
                    subMenu  : [{
                        label: t('plans.adults'),
                        value: 'adults',
                    }, {
                        label: t('plans.children'),
                        value: 'children',
                    }],
                }
            ]
        }
        return [
            {
                value    : 'review',
                label    : t('plans.exportReview'),
                component: <div> { t('plans.exportReview') }</div>,
                subMenu  : [{
                    label: t('plans.adults'),
                    value: 'adultReview',
                }, {
                    label: t('plans.children'),
                    value: 'childrenReview',
                }],
            }, {
                value    : 'plans',
                label    : t('plans.exportPlans'),
                component: <div> { t('plans.exportPlans') }</div>,
                subMenu  : [{
                    label: t('plans.adults'),
                    value: 'adults',
                }, {
                    label: t('plans.children'),
                    value: 'children',
                }],
            }
        ];
    };

    const onClickExport = (action) => {
        const pathData = {
            type    : 'print',
            lang    : localStorage.getItem('i18nextLng'),
            planIds : [props.match.params.id],
            timezone: momentTZ.tz.guess(),
            action  : action,
        };
        if (action === 'review') {
            trackingMixpanel('EXPORT_EXCEL_REVIEW_PLAN', plan);
        }

        if (action === 'plans') {
            trackingMixpanel('EXPORT_EXCEL_PLAN', plan);
        }

        if (action === 'childrenPlans') {
            trackingMixpanel('EXPORT_EXCEL_PLAN', plan);
        }

        if (action === 'childrenReview') {
            trackingMixpanel('EXPORT_EXCEL_REVIEW_PLAN', plan);
        }

        exports(`?${ queryString.stringify(pathData, { arrayFormat: 'comma' }) }`)
            .then(response => {
                let linkDownload = document.getElementById('downloadElement');
                linkDownload.setAttribute('href', response.url);
                linkDownload.click();
            });


    };

    const onClickPrint = (action) => {
        const pathData = {
            type    : 'print',
            lang    : localStorage.getItem('i18nextLng'),
            planIds : [props.match.params.id],
            timezone: momentTZ.tz.guess(),
            action  : action,
            token   : localStorage.getItem('token'),
        };
        if (action === 'review') {
            trackingMixpanel('PRINT_REVIEW_PLAN', plan);
        }

        if (action === 'childrenReview') {
            trackingMixpanel('PRINT_REVIEW_PLAN', plan);
        }

        if (action === 'plans') {
            trackingMixpanel('PRINT_PLAN', plan);
        }

        if (action === 'childrenPlans') {
            trackingMixpanel('PRINT_PLAN', plan);
        }

        let linkDownload = document.getElementById('downloadElement');
        linkDownload.setAttribute('href', `${ config.kirokuAPI.url }/v${ config.kirokuAPI.version }/print-plan?${ queryString.stringify(pathData, { arrayFormat: 'comma' }) }`);
        linkDownload.setAttribute('target', '_blank');
        linkDownload.click();


    };

    const getItemsForDropDownPrint = () => {
        if (plan.review_status === 0) {
            return [
                {
                    value    : 'plans',
                    component: (
                        <div>{ t('plans.printPlans') }</div>
                    ),
                    label    : t('plans.printPlans'),
                    subMenu  : [{
                        label: t('plans.adults'),
                        value: 'adults',
                    }, {
                        label: t('plans.children'),
                        value: 'children',
                    }],
                },
            ];
        }
        return [
            {
                value    : 'review',
                component: (
                    <div>{ t('plans.printReview') }</div>
                ),
                label    : t('plans.printReview'),
                subMenu  : [{
                    label: t('plans.adults'),
                    value: 'adultReview',
                }, {
                    label: t('plans.children'),
                    value: 'childrenReview',
                }],
            },
            {
                value    : 'plans',
                component: (
                    <div>{ t('plans.printPlans') }</div>
                ),
                label    : t('plans.printPlans'),
                subMenu  : [{
                    label: t('plans.adults'),
                    value: 'adults',
                }, {
                    label: t('plans.children'),
                    value: 'children',
                }],
            },
        ]
    };

    const renderIconReviewStatus = (status) => {
        switch (status) {
            case 'open':
                return <img style={ { paddingBottom: 4 } } src={ '/assets/img/open.svg' } alt={ 'open.svg' }/>;
            case 'in-progress' :
                return <img style={ { paddingBottom: 4 } } src={ '/assets/img/in-progress.svg' }
                            alt={ 'in-progress.svg' }/>;
            case 'resolved' :
                return <img style={ { paddingBottom: 4 } } src={ '/assets/img/resolved.svg' } alt={ 'resolved.svg' }/>;
            default :
                return;
        }
    };

    const dropdownItemBulkAction = () => {
        if(plan.published_at && !plan.review_at && plan.review) {
            return [{
                value    : 'duplicate',
                component: (
                    <div>
                        <img src={ `/assets/img/duplicate.svg` } alt="duplicate.svg"/>&nbsp;
                        <span style={ { fontSize: 16, color: '#17a2b8' } }>{ t('common.Duplicate') }</span>
                    </div>
                ),
                label    : t('common.Duplicate'),
            },
                {
                    value    : 'saveDraft',
                    component: (
                        <div>
                            <img src={ `/assets/icon/iconDraft.svg` }
                                 alt="iconDraft.svg"/>&nbsp;<span
                            style={ { fontSize: 16, color: plan.review || !plan.published_at ? '' : '#c9a043' } }
                        >
                                { t('plans.changeDraft') }
                            </span>
                        </div>
                    ),
                    label    : t('plans.changeDraft'),
                }
            ];
        }
        if (plan.published_at && !plan.review_at) {
            return [{
                value    : 'duplicate',
                component: (
                    <div>
                        <img src={ `/assets/img/duplicate.svg` } alt="duplicate.svg"/>&nbsp;
                        <span style={ { fontSize: 16, color: '#17a2b8' } }>{ t('common.Duplicate') }</span>
                    </div>
                ),
                label    : t('common.Duplicate'),
            },
                {
                    value    : 'saveDraft',
                    component: (
                        <div>
                            <img src={ `/assets/icon/iconDraft.svg` }
                                 alt="iconDraft.svg"/>&nbsp;<span
                                 style={ { fontSize: 16, color: plan.review || !plan.published_at ? '' : '#c9a043' } }
                            >
                                { t('plans.changeDraft') }
                            </span>
                        </div>
                    ),
                    label    : t('plans.changeDraft'),
                },
                {
                    value    : 'review',
                    component: (
                        <Tooltip content={ t('plans.After plan was started, you can draft the review')
                        }
                                 className="tooltip-target tooltip-target-bg-white "
                                 direction='bottom'
                                 useHover={ !isReviewAble() }
                        >
                            <div className="d-flex align-items-center">
                                <img src={ `/assets/icon/iconMonitor.svg` }
                                     className={!isReviewAble() ? 'iconMonitor-filter-disable': ''}
                                     alt="iconMonitor.svg"/>
                                <span style={ { fontSize: 14 } }
                                      className={ `${ !isReviewAble() ? 'text-review-plan-disabled' : 'text-review-plan' }` }
                                >
                                   &nbsp; { t('plans.review') }
                                </span>
                            </div>
                        </Tooltip>
                    ),
                    label    : t('plans.changeDraft'),
                },

            ];
        } else if (plan.published_at && plan.review_at) {
            return [{
                value    : 'duplicate',
                component: (
                    <div><img src={ `/assets/img/duplicate.svg` } alt="duplicate.svg"
                    /> &nbsp;<span
                        style={ { fontSize: 16, color: '#17a2b8' } }>{ t('common.Duplicate') }</span>
                    </div>
                ),
                label    : t('common.Duplicate'),
            },
            ];

        } else {
            return [{
                value    : 'edit',
                component: (
                    <div>
                        <img src={ `/assets/img/pen-edit.svg` } alt="pen-edit.svg"
                        /> &nbsp;<span
                        style={ { fontSize: 16 } }>{ t('common.Edit') }</span>
                    </div>
                ),
                label    : t('common.Edit'),
            }, {
                value    : 'duplicate',
                component: (
                    <div><img src={ `/assets/img/duplicate.svg` } alt="duplicate.svg"
                    /> &nbsp;<span
                        style={ { fontSize: 16, color: '#17a2b8' } }>{ t('common.Duplicate') }</span>
                    </div>
                ),
                label    : t('common.Duplicate'),
            },
                {
                    value    : 'publish',
                    component: (
                        <div><img src={ `/assets/img/publish_at.svg` }
                                  alt="publish_at.svg"/>&nbsp;<span
                            style={ { fontSize: 16, color: '#249533' } }>
                        { t('plans.isPublish') }</span>
                        </div>
                    ),
                    label    : t('plans.isPublish'),
                },
            ];
        }
    };

    const isReviewAble = () => {
        return moment().unix() > plan.start_at;
    };

    return (
        <KirokuLoader loading={ loadingAPI }>
            <If statement={ plan }
                otherwise={ <SearchNotFound message={ t('common.PlanInvalid') } description={ ' ' }/> }
            >
                { () =>
                    <div>
                        <a id={ 'downloadElement' } href={ 'xxx' } hidden download>xxx</a>
                        <div className='padding-responsive'>
                            <KirokuLoader loading={ isLoadingReview }>
                                <ComponentWeb>
                                    <div className=" d-flex justify-content-between">
                                        {/* plan label btn change draft, review */ }
                                        <div className="d-flex align-items-center" style={ { flexWrap: 'wrap' } }>
                                            <div className={ 'pr-2 plan-padding-button' }
                                                 hidden={ !(plan.published_at && moment().unix() < plan.start_at) || plan.review_at }>
                                                <div className="d-flex align-items-center">
                                                    <img src={ `/assets/icon/iconBeforeStart.svg` }
                                                         alt="iconBeforeStart.svg"
                                                         style={ { width: 21, height: 20 } }
                                                    /> &nbsp;<span
                                                    className={ 'font-size-16' }>{ t('plans.beforeStart') }</span>
                                                </div>
                                            </div>

                                            <div hidden={ plan.published_at || plan.review_at }
                                                 className="pr-2 plan-padding-button">
                                                <div className="d-flex align-items-center">
                                                    <img src={ `/assets/icon/iconDraft.svg` } alt="iconDraft.svg"
                                                         style={ { width: 21, height: 20 } }
                                                    /> &nbsp;<span
                                                    className={ 'font-size-16' }>{ t('plans.draft') }</span>
                                                </div>
                                            </div>
                                            <div
                                                hidden={ !(plan.published_at && moment().unix() > plan.start_at && moment().unix() < plan.end_at) || plan.review_at }
                                                className="pr-2 plan-padding-button">
                                                <div className="d-flex align-items-center">
                                                    <img src={ `/assets/icon/iconRunning.svg` } alt="iconRunning.svg"
                                                         style={ { width: 21, height: 20 } }
                                                    /> &nbsp;<span
                                                    className={ 'font-size-16' }>{ t('plans.running') }</span>
                                                </div>
                                            </div>

                                            <div
                                                hidden={ !(plan.published_at && moment().unix() > plan.end_at) }
                                                className="pr-2 plan-padding-button">
                                                <div className="d-flex align-items-center">
                                                    <img src={ `/assets/icon/iconFinish.svg` } alt="iconFinish.svg"
                                                         style={ { width: 21, height: 20 } }
                                                    /> &nbsp;<span
                                                    className={ 'font-size-16' }>{ t('plans.finish') }</span>
                                                </div>
                                            </div>

                                            <div hidden={ !plan.published_at || plan.review }
                                                 className={ 'pr-2 plan-padding-button' }>
                                                <Tooltip content={ t('plans.After plan was started, you can draft the review') }
                                                         className="tooltip-target tooltip-target-bg-white "
                                                         direction='bottom'
                                                         useHover={ !isReviewAble() }
                                                >
                                                    <KirokuButtonLink
                                                        disabled={ !isReviewAble() }
                                                        className={ `${ !isReviewAble() ? 'disabled-review-plan-btn' : 'review-plan-btn' }` }
                                                        style={ { height: 36, padding: '0 16px' } }
                                                        onClick={ () => {
                                                            setShowForm(!showForm);
                                                        } }
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <img src={ `/assets/icon/iconMonitor.svg` }
                                                                 className={ !isReviewAble() ? `iconMonitor-filter-disable` : '' }
                                                                 alt="iconMonitor.svg"/>
                                                            <span
                                                                className={ `${ !isReviewAble() ? 'text-review-plan-disabled' : 'text-review-plan' } ` }
                                                                style={ { fontSize: 14 } }
                                                            >
                                                         &nbsp;{ t('plans.review') }
                                                        </span>
                                                        </div>
                                                    </KirokuButtonLink>
                                                </Tooltip>
                                            </div>
                                            <div className="pl-2 plan-padding-button" hidden={ plan['published_at'] }>
                                                <KirokuButtonLink
                                                    className={ 'text-save-draft' }
                                                    style={ {
                                                        border : 'solid 1px #249533',
                                                        padding: '0 16px',
                                                        color  : '#249533',
                                                        height : 36,
                                                    } }
                                                    onClick={ () => {
                                                        setShowModal('publish');
                                                    } }
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <img src={ `/assets/img/publish_at.svg` } alt="publish_at.svg"/>
                                                        &nbsp;<span
                                                        style={ { fontSize: 14 } }>{ t('plans.publishBtn') }</span>
                                                    </div>
                                                </KirokuButtonLink>
                                            </div>
                                            <div className="pl-2 plan-padding-button"
                                                 hidden={ plan.review_at || !plan.published_at }>
                                                <KirokuButtonLink
                                                    className={ 'text-save-draft' }
                                                    style={ {
                                                        border : 'solid 1px #c9a043',
                                                        padding: '0 16px',
                                                        color  : '#c9a043',
                                                        height : 36,
                                                    } }
                                                    onClick={ () => {
                                                        setShowForm(false)
                                                        setShowModal('saveDraft');
                                                    } }
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <img src={ `/assets/icon/iconDraft.svg` } alt="iconDraft.svg"/>
                                                        &nbsp;<span
                                                        style={ { fontSize: 14 } }>{ t('plans.changeDraft') }</span>
                                                    </div>
                                                </KirokuButtonLink>
                                            </div>
                                        </div>
                                        {/*
                                         action
                                         */ }
                                        <div className="d-flex align-items-center" style={ { flexWrap: 'wrap' } }>
                                            <div className={ 'plan-padding-button' }>
                                                <KirokuDropdownSubMenu
                                                    className={ 'sub-menu-button' }
                                                    items={ getItemActionPlaning() }
                                                    onSelectChildren={ (typeFormat) => {
                                                        if (typeFormat === 'adults') {
                                                            onClickExport('plans');
                                                        }
                                                        if (typeFormat === 'children') {
                                                            onClickExport('childrenPlans');
                                                        }
                                                        if (typeFormat === 'adultReview') {
                                                            onClickExport('review');
                                                        }
                                                        if (typeFormat === 'childrenReview') {
                                                            onClickExport('childrenReview');
                                                        }
                                                    } }
                                                    title={ <div className={ 'd-flex align-items-center' }><img
                                                        src={ '/assets/img/download.svg' }
                                                        alt={ 'download.svg' }/><span
                                                        className="pl-2"
                                                        style={ { fontSize: 14, minWidth: 'max-content' } }>{ t('Layout.AdminExport') }</span>
                                                    </div> }
                                                    subClassName={ {
                                                        minWidth: 100,
                                                    } }
                                                />
                                            </div>
                                            <div className={ 'plan-padding-button pl-2' }>
                                                <KirokuDropdownSubMenu
                                                    className={ 'sub-menu-button' }
                                                    onSelectChildren={ (valueChild) => {
                                                        if (valueChild === 'adults') {
                                                            onClickPrint('plans');
                                                        }
                                                        if (valueChild === 'children') {
                                                            onClickPrint('childrenPlans');
                                                        }
                                                        if (valueChild === 'adultReview') {
                                                            onClickPrint('review');
                                                        }
                                                        if (valueChild === 'childrenReview') {
                                                            onClickPrint('childrenReview');
                                                        }
                                                    } }
                                                    items={ getItemsForDropDownPrint() }
                                                    title={ <div className={ 'd-flex align-items-center' }><img
                                                        src={ '/assets/img/printer.svg' }
                                                        alt={ 'print.svg' }/><span
                                                        className="pl-2"
                                                        style={ { fontSize: 14, minWidth: 'max-content' } }>{ t('common.print') }</span>
                                                    </div> }
                                                    onSelect={ () => {
                                                    } }
                                                    subClassName={ {
                                                        minWidth: 100,
                                                    } }
                                                />
                                            </div>
                                            <div className="pl-2 plan-padding-button">
                                                <KirokuButtonLink
                                                    className={ 'font-label-preview ' }
                                                    style={ {
                                                        border : 'solid 1px #17a2b8',
                                                        color  : '#17a2b8',
                                                        height : '36px',
                                                        padding: '0 16px',
                                                    } }
                                                    onClick={ () => {
                                                        history.push(`/plans/duplicate/${ plan.id }`);
                                                    } }
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <img src={ `/assets/img/duplicate.svg` } alt="duplicate.svg"
                                                        /> &nbsp;<span
                                                        style={ { fontSize: 14 } }>{ t('common.Duplicate') }</span>
                                                    </div>
                                                </KirokuButtonLink>
                                            </div>
                                            <div className="pl-2 plan-padding-button">
                                                <Tooltip content={ t('plans.cannotEditOncePublished') }
                                                         className="tooltip-target tooltip-target-bg-white "
                                                         direction='up'
                                                         useHover={ !!plan.published_at }
                                                >
                                                    <KirokuButtonLink
                                                        disabled={ plan.published_at }
                                                        className={ 'font-label-preview btn-edit-plan' }
                                                        style={ {
                                                            padding: '0 16px',
                                                            height : 36,
                                                        } }
                                                        onClick={ () => {
                                                            history.push(`/plans/edit/${ plan.id }`);
                                                        } }
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <img src={ `/assets/img/pen-edit.svg` } alt="pen-edit.svg"
                                                            /> &nbsp;<span
                                                            style={ { fontSize: 14 } }>{ t('common.Edit') }</span>
                                                        </div>

                                                    </KirokuButtonLink>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </ComponentWeb>
                                <ComponentMobile>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                            <div hidden={ plan.published_at || plan.review_at }>
                                                <img src={ `/assets/icon/iconDraft.svg` } alt="iconDraft.svg"
                                                     style={ { width: 22, height: 20 } }
                                                /> &nbsp;<span style={ { fontSize: 16 } }>{ t('plans.draft') }</span>
                                            </div>

                                            <div
                                                hidden={ !(plan.published_at && moment().unix() < plan.start_at) || plan.review_at }>
                                                <img src={ `/assets/icon/iconBeforeStart.svg` }
                                                     alt="iconBeforeStart.svg"
                                                     style={ { width: 22, height: 20 } }
                                                /> &nbsp;<span
                                                style={ { fontSize: 16 } }>{ t('plans.beforeStart') }</span>
                                            </div>

                                            <div
                                                hidden={ !(plan.published_at && moment().unix() > plan.start_at && moment().unix() < plan.end_at) || plan.review_at }>
                                                <img src={ `/assets/icon/iconRunning.svg` } alt="iconRunning.svg"
                                                     style={ { width: 22, height: 20 } }
                                                /> &nbsp;<span style={ { fontSize: 16 } }>{ t('plans.running') }</span>
                                            </div>

                                            <div
                                                hidden={ !(plan.published_at && moment().unix() > plan.end_at)}>
                                                <img src={ `/assets/icon/iconFinish.svg` } alt="iconFinish.svg"
                                                     style={ { width: 22, height: 20 } }
                                                /> &nbsp;<span style={ { fontSize: 16 } }>{ t('plans.finish') }</span>
                                            </div>
                                        </div>

                                        <div>
                                            <KirokuDropdownResetLabelSelected
                                                title={ t('RecordList.BulkAction') }
                                                style={ { minWidth: 180 } }
                                                items={ dropdownItemBulkAction() }
                                                onSelect={ event => {
                                                    if (event.kirokuSelected.value === 'duplicate') {
                                                        history.push(`/plans/duplicate/${ plan.id }`);
                                                    }
                                                    if (event.kirokuSelected.value === 'edit' && !plan.published_at) {
                                                        history.push(`/plans/edit/${ plan.id }`);
                                                    }
                                                    if (event.kirokuSelected.value === 'saveDraft' && plan.published_at) {
                                                        setShowForm(false)
                                                        setShowModal('saveDraft');
                                                    }
                                                    if (event.kirokuSelected.value === 'publish' && !plan.published_at) {
                                                        setShowModal('publish');
                                                    }
                                                    if (event.kirokuSelected.value === 'review' && isReviewAble()) {
                                                        setShowForm(!showForm);
                                                    }
                                                } }
                                            />
                                        </div>
                                    </div>
                                </ComponentMobile>
                                { (plan.review && plan.published_at) &&
                                <div className="pt-3">
                                    <div className="bg-white main-content-info">
                                        <div hidden={ showForm }>
                                            <div className="pr-16px pl-16px">
                                                <div
                                                    className="d-flex justify-content-between pt-15px align-items-center">
                                                    <div className={'d-flex align-items-center'}>
                                                        <div className={'review-plan-text'}>
                                                            {t('plans.review')} &nbsp;
                                                        </div>
                                                        {plan.review?.is_draft ? <div className='label-drafted-at'>
                                                            <KirokuLabel color={'warning'}
                                                                         style={{
                                                                             width: 'fit-content',
                                                                             border: 'solid 1px #EA9B3F',
                                                                             backgroundColor: '#EA9B3F',
                                                                             margin: 0,
                                                                         }}>
                                                                {t('plans.draft')}
                                                            </KirokuLabel>
                                                        </div> : ''}
                                                    </div>
                                                    {plan.review?.is_draft ?
                                                        <div
                                                            className={`edit-review-text ${!plan.review?.is_draft ? 'disable' : ''}`}
                                                            onClick={() => {
                                                                if (plan.review?.is_draft) {
                                                                    setShowForm(!showForm);
                                                                }
                                                            }}>
                                                            <i className='fas fa-pencil-alt'>
                                                                <span className={'ml-1'}>
                                                                    {t('common.Edit')}
                                                                </span>
                                                            </i>
                                                        </div> : <div>
                                                            <KirokuButtonLink
                                                                className={'btn-to-draft'}
                                                                style={{
                                                                    border: 'none',
                                                                    padding: '0',
                                                                    color: '#c9a043',
                                                                    height: 36,
                                                                }}
                                                                hidden={plan.review.drafted_at}
                                                                onClick={() => {
                                                                    setIsLoadingReview(true);
                                                                    APIUpdateReview(plan.id, {
                                                                        ...plan.review,
                                                                        is_draft: true,
                                                                        review_status: 1
                                                                    }).then(() => {
                                                                        loadDetail(props.match.params.id)
                                                                            .then(() => {
                                                                                notification(t('message.UpdateSuccess'), 'success');
                                                                                setIsLoadingReview(false);
                                                                            }).catch(err => {
                                                                            console.log(err);
                                                                        });
                                                                    }).catch(err => {
                                                                        console.log(err);
                                                                    });
                                                                }}
                                                            >
                                                                <div className="d-flex align-items-center">
                                                                    <img src={`/assets/icon/iconDraft.svg`}
                                                                         alt="iconDraft.svg"/>
                                                                    &nbsp;<span
                                                                    style={{fontSize: 14}}>{t('plans.changeDraft')}</span>
                                                                </div>
                                                            </KirokuButtonLink>
                                                        </div>

                                                    }

                                                </div>
                                                <div className="pt-2">
                                                    <div className="d-flex">
                                                        <div
                                                            className={'label-monitor-plan'}>{t('plans.resultForLongTermTarget')}: &nbsp;</div>
                                                        <div className={'d-flex align-items-center'}>
                                                            <div>
                                                                {plan.review['long_term_target_status'] ? t(`status.${plan.review['long_term_target_status']}`) : ''}
                                                            </div>
                                                            <div
                                                                className={'pl-1'}>
                                                                {plan.review['long_term_target_status'] ? renderIconReviewStatus(plan.review['long_term_target_status']) : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-2">
                                                    <div className="d-flex">
                                                        <div
                                                            className={'label-monitor-plan'}>{t('plans.resultForShortTermTarget')}: &nbsp;</div>
                                                        <div className={'d-flex align-items-center'}>
                                                            <div>
                                                                {plan.review['short_term_target_status'] ? t(`status.${plan.review['short_term_target_status']}`) : ''}

                                                            </div>
                                                            <div
                                                                className={'pl-1'}>
                                                                {plan.review['short_term_target_status'] ? renderIconReviewStatus(plan.review['short_term_target_status']) : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-2">
                                                    <div className="d-flex" style={{flexFlow: 'wrap'}}>
                                                        <div
                                                            className={'label-monitor-plan'}>{t('plans.MonitoringDate')}: &nbsp;</div>
                                                        <div className={'d-flex align-items-center'}>
                                                        <span className={'main-content-info'}>
                                                            {plan.review && plan.review.monitored_at ?
                                                                moment.unix(plan.review.monitored_at).format(t('dateTimeFormat.YearMonthDateWithDay')) : ''
                                                            }
                                                        </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="review-of-plan">
                                                    <div className="pt-2">
                                                        <div className="plan-review-comment-label">
                                                            <div
                                                                className={'label-monitor-plan'}>{t('plans.summary')}: &nbsp;</div>
                                                            <pre className="font-size-16">{plan.review.summary}</pre>
                                                        </div>
                                                    </div>
                                                    <div className="pt-2">
                                                        <div className="plan-review-comment-label">
                                                            <div
                                                                className={'label-monitor-plan'}>{t('plans.comment')}: &nbsp;</div>
                                                            <pre className="font-size-16">{plan.review.comment}</pre>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pb-15px"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </KirokuLoader>

                            <ReviewForm show={showForm}
                                        closeModal={() => setShowForm(!showForm)}
                                        isReviewAllSupported={isReviewAllSupported}
                                        plan={plan}
                                        onSubmit={() => {
                                            setIsLoadingReview(true);
                                            loadDetail(props.match.params.id).then(() => {
                                                setIsLoadingReview(false);
                                            });
                                        }}
                            />

                            <div className="pt-3"/>

                            <div className="bg-white">
                                <div className={'header-card-detail'}>
                                    <div className="row align-items-center">
                                        <div className="col-md-12 col-lg-8 planning-customer-name">
                                        {
                                                plan.customer &&
                                                <span className="font-size-18">{ plan.customer.name }</span>
                                            }
                                        </div>
                                        <div className="col-md-12 col-lg-4 ">
                                            <div className={ 'last-update-planning' }
                                                 style={ { fontSize: 14 } }>
                                                <span style={ { fontSize: 14 } }>{ t('plans.lastUpdateAt') }: </span>
                                                { moment.unix(plan['updated_at'] || plan['created_at']).format(t('dateTimeFormat.YearMonthDateWithHour')) }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <PlanningInfo plan={ plan }/>
                            </div>
                            <div style={ { padding: 8 } }/>
                            <Memo plan={ plan }/>
                            <div style={ { padding: 8 } }/>
                            <Supports plan={ plan } showModal={ showModal } setShowModal={ setShowModal }
                                      hasMonitor={setIsReviewAllSupported}
                            />
                            <ComponentMobile>
                                <div style={ { marginBottom: 80 } }/>
                            </ComponentMobile>
                        </div>
                        <ModalDeletePlan show={ showModal === 'delete' } closeModal={ () => setShowModal('') }
                                         plan={ plan }
                                         searchAfterDelete={ () => {
                                             notification(t('message.DeleteSuccess'), 'success');
                                             history.push('/plans');
                                         } }/>
                        <ModalConfirmSaveDraft show={ showModal === 'saveDraft' }
                                               plan={ plan }
                                               closeModal={ () => setShowModal('') }
                                               loadDetail={ () => {
                                                   loadDetail(props.match.params.id).then(() => {
                                                       trackingMixpanel('CHANGE_PLAN_TO_DRAFT', plan);
                                                       setLoadingAPI(false);
                                                   });
                                               } }
                        />
                        <ModalConfirmPublishPlan show={ showModal === 'publish' }
                                                 plan={ plan }
                                                 closeModal={ () => setShowModal('') }
                                                 loadDetail={ () => {
                                                     loadDetail(props.match.params.id).then(() => {
                                                         trackingMixpanel('PUBLISHED_PLAN', plan);
                                                         setLoadingAPI(false);
                                                     });
                                                 } }
                        />
                    </div>
                }
            </If>
        </KirokuLoader>
    );
}

import React                          from 'react';
import { KirokuResetFilterCondition } from '../../../ui';
import { useTranslation }             from 'react-i18next';

export default function DropDownFilter(props) {
    const { t } = useTranslation();

    const filterItems = [
        {
            value    : 'active',
            component: (<div>{t('common.active')}</div>),
            label    : t('common.active'),
        },
        {
            value    : 'deactive',
            component: (<div>{t('common.deactive')}</div>),
            label    : t('common.deactive'),
        },
    ];

    const { hidden, onSelect, status } = props;

    return (<div hidden={hidden}>
        <KirokuResetFilterCondition
            title={t(`common.${status}`)}
            defaultValue={t('common.active')}
            items={filterItems}
            icon={'fas fa-check-circle text-secondary'}
            onSelect={onSelect}
        />
    </div>);
}

import React, { useEffect, useState } from "react";
import Datetime                       from "react-datetime";
import { useTranslation }             from "react-i18next";
import { KirokuActionSurvey }         from "../../../../ui";
import moment                         from "moment";

function Date({defaultDate, onChange}) {

    const {t} = useTranslation();

    return (
        <div>
            <Datetime
                className='read-only-date'
                inputProps={{disabled: false, readOnly: true}}
                value={defaultDate ? moment(defaultDate * 1000) : ""}
                timeFormat={false}
                closeOnSelect={true}
                dateFormat={t('dateTimeFormat.YearMonth')}
                onChange={onChange}
                locale={t('createRecord.Date')}
            />
            {/*<p hidden={!monitoringTerm.validateFormatDate} style={{
                                        color: 'red',
                                        fontSize: 12
                                    }}>{t('CustomerEditor.validate.ValidDate')}
                                    </p>*/}
        </div>
    )
}

export default function MonitoringTerms({monitor, hidden, remove, updateCertifications}) {

    const [ monitoringTerms, setMonitoringTerms ] = useState(monitor)

    const handleChangeDate = value => {
        const newMonitoringTerms = {...monitoringTerms};
        newMonitoringTerms.date = moment(value).unix();
        setMonitoringTerms(newMonitoringTerms);
        updateCertifications(newMonitoringTerms);
    };

    useEffect(() => {
        setMonitoringTerms(monitor);
    }, [ monitor ])

    return (
        <div className="d-flex">
            <Date defaultDate={monitoringTerms['date']} onChange={handleChangeDate}/>
            <div className={'p-2'}/>
            <div className={'cursor-pointer pt-2'}
                 onClick={remove}
                 hidden={hidden}
            >
                <KirokuActionSurvey icon={'minus'}/>
            </div>
        </div>
    )
}

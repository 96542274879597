import React, { useEffect, useState }    from 'react';
import Announcement                      from './Announcement/Announcement';
import StatisticsRecord                  from './StatisticsRecord/StatisticsRecord';
import './Dashboard.css';
import PinnedCustomer                    from './PinnedCustomer/PinnedCustomer';
import { authentication }                from '@kiroku/api';
import usePageTitle                      from '../../ui/v3/hooks/usePageTitle';
import { useTranslation }                from 'react-i18next';
import { ComponentMobile, ComponentWeb } from '../../ui';
import { useProfile }                    from '../useProfile';
import moment                            from 'moment';
import ModalAlertPassword                from './ModalAlerPassword';
import config                            from '../../config.js';
import ActivitiesList                    from './ActivitiesList/ActivitiesListV2/ActivitiesList';

export default function Dashboard() {
    const { isAdmin } = authentication.usePermission();
    const { t } = useTranslation();
    const [profile]       = useProfile();
    const [show, setShow] = useState(false);

    useEffect(() => {
        let isOutDatePassword = moment().diff(moment.unix(profile['last_updated_password_at']), 'month');
        setShow( isOutDatePassword > config.outDatePassword && window.location.previous === 'login')
    }, [setShow, profile])

    usePageTitle(t(`Layout.Home`));

    return (
        <div className='padding-responsive'>
            <Announcement/>
            <div className={ 'row dashboard-row' }>
                <div className={ isAdmin ? 'col-12' : 'col-md-9' }>
                    <StatisticsRecord/>
                    <ComponentMobile>
                        <div hidden={ isAdmin }>
                            <PinnedCustomer/>
                        </div>
                    </ComponentMobile>
                    <ActivitiesList/>
                    <div style={{ height: 10}}/>
                </div>
                <div className={ 'col-md-3 pl-md-0' } hidden={ isAdmin }>
                    <ComponentWeb>
                        <PinnedCustomer/>
                    </ComponentWeb>
                </div>
            </div>

            <ModalAlertPassword
                show={ show }
                onCloseModal={ () => setShow(!show) }
                user={ profile }
            />
        </div>
    );
}

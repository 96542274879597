import React from 'react';

function KirokuRadio(props) {
    const { onChange, disabled, value, checked, id, children, name } = props;
    return (
        <div className="custom-control custom-radio mt-2">
            <input type="radio" className="custom-control-input"
                   disabled={disabled}
                   value={value}
                   onChange={onChange}
                   checked={checked} id={id}
                   name={name}/>
            <label className="custom-control-label padding-top-2px"
                   htmlFor={id}>
                    <span className='setFontSpan'>
                        {children}
                    </span>
            </label>
        </div>
    );
}


export default KirokuRadio;

import React                     from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory }      from 'react-router-dom';
import {
    CustomerLabelComponent, PlanLabelComponent, StaffLabelComponent, SurveyRecordLabelComponent,
    SurveyTemplateLabelComponent,
    TeamLabelComponent,
}                                from './LabelHistoryLog';
import moment                    from 'moment';
import useUser                   from '../../../LazyLoad/useUser';
import KirokuLoader              from '../../../../ui/KirokuLoader';

export default ({ log }) => {
    const { t }             = useTranslation();
    const { user, loading } = useUser(log.user_id);
    const history           = useHistory();
    return (
        <div>
            <KirokuLoader loading={loading}>
            {user && log ?
            <div className="d-flex" >
                <div className="mr-1 pt-10px">
                    <Link to={ `/users/${ user.id }` }>
                        <div className='avatar' style={ {
                            width             : 48,
                            height            : 48,
                            borderRadius      : '100%',
                            backgroundImage   : `url(${ user.avatar ? user.avatar : 'assets/img/profile-ic.svg' })`,
                            backgroundSize    : 'cover',
                            backgroundPosition: 'center center',
                        } }/>

                    </Link>
                </div>
                <div className={ 'pl-1 pb-1 w-100' }>
                    { log.action === 'comment' && log.type === 'surveyRecord' ? <>
                        <div className="item-history-log">
                            <div className="d-flex flex-flow-wrap font-size-16 white-space-break-space">
                                { user.username }
                                <Trans i18nKey={ `Dashboard.${ log.action }${ log.type }` }>
                                    { (log.data && log.data.customer) ? <Link
                                        to={ `/customers/${ (log.data && log.data.customer) ? log.data.customer.id : '' }` }>
                                        { { customerName: (log.data && log.data.customer) ? log.data.customer.name : '' } }
                                    </Link> : '' }
                                    <Link
                                        to={ `/records/${ log['type_id'] }` }>{ { typeName: log['type_name'] } }</Link>
                                </Trans>
                            </div>
                            <div><SurveyRecordLabelComponent/></div>
                        </div>

                        { log.data && log.data.comment ?
                            <div className={ 'pt-10px' } hidden={ log.action !== 'comment' }>
                                <div className={ 'content-comment-log' }>
                                    { log.data && log.data.comment.content }&nbsp;<img
                                    src={ `/assets/img/file-search.svg` } alt="file-search"
                                    style={ { cursor: 'pointer' } }
                                    onClick={ (event) => {
                                        event.preventDefault();
                                        localStorage.setItem('commentId', log.data.comment && log.data.comment.id);
                                        history.push(`/records/${ log['type_id'] }#comment-tab`);
                                    } }/>
                                </div>
                            </div> : null }
                    </> : null }

                    { log.action !== 'comment' && log.type === 'surveyRecord' ? <>
                        <div className="item-history-log">
                            <div className="d-flex flex-flow-wrap font-size-16 white-space-break-space">
                                { user.username  }
                                <Trans i18nKey={ `Dashboard.${ log.action }${ log.type }` }>
                                    { (log.data && log.data.customer) ? <Link
                                        to={ `/customers/${ (log.data && log.data.customer) ? log.data.customer.id : '' }` }>
                                        { { customerName: (log.data && log.data.customer) ? log.data.customer.name : '' } }
                                    </Link> : '' }
                                    { log.action !== 'delete' ?
                                        <Link
                                            to={ `/records/${ log['type_id'] }` }>{ { typeName: log['type_name'] } }</Link> :
                                        <div> { { typeName: log['type_name'] } } </div>
                                    }
                                </Trans>
                            </div>
                            <div><SurveyRecordLabelComponent/></div>
                        </div>
                    </> : null }
                    { log.type === 'surveyTemplate' ? <>
                        <div className="item-history-log">
                            <div className="d-flex flex-flow-wrap font-size-16 white-space-break-space">
                                {  user.username }
                                <Trans i18nKey={ `Dashboard.${ log.action }${ log.type }` }>

                                    { log.action !== 'delete' ?
                                        log['type_name'] ? <Link
                                                to={ `/surveys/${ log['type_id'] }` }>{ { typeName: log['type_name'] } }&nbsp;</Link> :
                                            <div>{ { typeName: t('common.Survey') } }</div>
                                        :
                                        <div>{ { typeName: log['type_name'] ? log['type_name'] : t('common.Survey') } } </div>
                                    }
                                </Trans>
                            </div>
                            <div><SurveyTemplateLabelComponent/></div>
                        </div>
                    </> : null }

                    { log.type === 'team' ? <>
                        <div className="item-history-log">
                            <div className="d-flex flex-flow-wrap font-size-16 white-space-break-space">
                                { user.username }
                                <Trans i18nKey={ `Dashboard.${ log.action }${ log.type }` }>
                                    { log.action !== 'delete' ?
                                        log['type_name'] ? <Link
                                                to={ `/teams/${ log['type_id'] }` }>{ { typeName: log['type_name'] } } &nbsp;</Link> :
                                            <div>{ { typeName: t('common.team') } }</div>
                                        :
                                        <div> { { typeName: log['type_name'] ? log['type_name'] : t('common.team') } } </div>
                                    }
                                </Trans>
                            </div>
                            <div><TeamLabelComponent/></div>
                        </div>
                    </> : null }

                    { log.type === 'customer' ? <>
                        <div className="item-history-log">
                            <div className="d-flex flex-flow-wrap font-size-16 white-space-break-space">
                                { user.username }
                                <Trans
                                    i18nKey={ `Dashboard.${ log['type_name'] ? '' : 'isNotTypeName' }${ log.action }${ log.type }` }>
                                    { log.action !== 'delete' ?
                                        log['type_name'] ? <Link
                                                to={ `/customers/${ log['type_id'] }` }>{ { typeName: log['type_name'] } }&nbsp;</Link> :
                                            <div>{ { typeName: t('common.customer') } }</div>
                                        :
                                        <div> { { typeName: log['type_name'] ? log['type_name'] : t('common.customer') } } </div>
                                    }
                                </Trans>
                            </div>
                            <div><CustomerLabelComponent/></div>
                        </div>
                    </> : null }

                    { log.type === 'staff' ? <>
                        <div className="item-history-log">
                            <div className="d-flex flex-flow-wrap font-size-16 white-space-break-space">
                                { user.username }
                                <Trans
                                    i18nKey={ `Dashboard.${ log['type_name'] ? '' : 'isNotTypeName' }${ log.action }${ log.type }` }>
                                    { log.action !== 'delete' ?
                                        log['type_name'] ? <Link
                                                to={ `/users/${ log['type_id'] }` }>{ { typeName: log['type_name'] } }&nbsp;</Link> :
                                            <div>{ { typeName: t('common.staff') } }</div>
                                        :
                                        <div> { { typeName: log['type_name'] ? log['type_name'] : t('common.staff') } } </div>
                                    }
                                </Trans>
                            </div>
                            <div><StaffLabelComponent/></div>
                        </div>
                    </> : null }

                    { log.type === 'plan' ? <>
                        <div className="item-history-log">
                            <div className="d-flex flex-flow-wrap font-size-16 white-space-break-space">
                                { user.username }
                                <Trans i18nKey={ `Dashboard.${ log.action }${ log.type }` }>
                                    { (log.data && log.data.customer) ? <Link
                                        to={ `/customers/${ log.data ? log.data.customer.id : '' }` }> { { customerName: log.data ? log.data.customer.name : '' } }</Link> : '' }
                                    { log.action !== 'delete' ? <Link to={ `/plans/${ log['type_id'] }` }/> : <div/> }
                                </Trans>
                            </div>
                            <div><PlanLabelComponent/></div>
                        </div>
                    </> : null }
                    <div className={ 'pt-10px time-log-b' }>
                            <span className={ 'time-log' }>
                                { moment.unix(log.create_at).format(t('dateTimeFormat.HourMinute')) }
                            </span>
                    </div>
                </div>
            </div>: '' }
            </KirokuLoader>
        </div>
    );
}

import React, {Component} from 'react'
import KirokuDropdownItem from "./KirokuDropdownItem";
import KirokuCheckBox     from "./KirokuCheckBox";
import { useTranslation }     from 'react-i18next';

class KirokuSelectedCustomer extends Component {

    state = {
        listOpen: false,
        headerTitle: this.props.title,
        customers: [],
        customerRecords: [],
        defaultChecked: false,
        checkAllCustomer: false,
        selected: false
    };

    handleSelected(event, index) {
        let newCustomers             = this.props.list.customers;
        newCustomers[index].selected = !newCustomers[index].selected;
        this.props.onSelect(newCustomers);
        this.setState({
            customers: newCustomers,
            selected: false
        });
    }

    componentWillMount() {
        this.setState({
            // customers: this.props.list.customers.map(item => ({...item})),
            // customerRecords: this.props.list.customers.map(item => ({...item}))
        })
    }


    selectAll() {
        let newCustomers = this.props.list.customers.map(customer => ({
            ...customer,
            selected: !this.state.selected
        }));
        this.setState({
            customers: newCustomers,
            selected: !this.state.selected
        });
        this.props.onSelect(newCustomers);
    }

    render() {
        const {t} = useTranslation();
        const {headerTitle, defaultChecked} = this.state;
        return (
            <div className="dd-wrapper">
                <button onClick={this.selectAll.bind(this, this.props.index)}
                        className={`btn btn-kiroku ${this.props.className ? this.props.className : ''}`}>
                    <span className='text-kiroku left text-left' style={{width: 350, overflowX: 'hidden'}}>{headerTitle}</span>
                    <span className='text-kiroku float-right'>{t('common.SelectAll')}</span>
                </button>
                <div className='menu-dd-wrapper'>
                    {this.props.list.customers.map((item, index) => (
                        <div key={index}>
                            <KirokuDropdownItem key={item.name} onClick={(event) => {
                                event.selected = item;
                                this.handleSelected(event, index);
                            }}>
                                <KirokuCheckBox checked={item.selected ? item.selected : defaultChecked}
                                                onChange={(event) => {
                                                    event.selected = item;
                                                    this.handleSelected(event, index);
                                                }}
                                                content={item.name}/>
                            </KirokuDropdownItem>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default KirokuSelectedCustomer;

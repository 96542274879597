import { DeepDiff } from 'deep-diff';

export function useDifferenceCustomerInfo(oldProfile, logProfile) {
    const diff    = DeepDiff.diff;

    const oldData = {
        name          : oldProfile.name,
        date_of_birth : oldProfile.date_of_birth,
        blood_type    : oldProfile.blood_type,
        characteristic: oldProfile.characteristic,
        post_code     : oldProfile.post_code,
        address       : oldProfile.address,
        gender        : oldProfile.gender,
    };

    const logData = {
        name          : logProfile.name,
        date_of_birth : logProfile.date_of_birth,
        blood_type    : logProfile.blood_type,
        characteristic: logProfile.characteristic,
        post_code     : logProfile.post_code,
        address       : logProfile.address,
        gender        : logProfile.gender,
    };

    return { profileDataDiffs: diff(oldData, logData) };
}

import { resources }             from '@kiroku/api';
import { useCallback, useState } from 'react';

export default function () {
    const { search }        = resources.useSearch('Team');
    const [teams, setTeams] = useState([]);

    const searchTeams = useCallback(() => {
        search({type : 'all'}).then(res => {
            setTeams(res.result);
        })
    }, [search])

    return {
        teams,
        searchTeams
    };
}

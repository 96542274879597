import React, { useEffect, useState }   from 'react';
import { KirokuDropdownOnChangeSearch } from '../../ui';
import { itemsDropdownSearch }          from '../Converter';
import { resources }                    from '@kiroku/api';
import { useTranslation }               from 'react-i18next';

export default function DropdownCustomerPlanning(props) {
    const { t }                               = useTranslation();
    const { result, search, cancelAPISearch } = resources.useSearch('Customer', { type: 'all' });
    const [customers, setCustomers]           = useState([]);
    const [customerName, setCustomerName]     = useState(props.customerName || '');

    useEffect(() => {
        search({ type: 'all' });
        return () => {
            cancelAPISearch();
        };
    }, [search, cancelAPISearch]);

    useEffect(() => {
        setCustomers(result);
    }, [result]);


    const handleSearchKeywords  = (keyword) => {
        let inputValue =  keyword.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '')
        setCustomers(result.filter(customer => customer.name.toLowerCase().search(inputValue.toLowerCase()) !== -1));
    }

    return (
        <div>
            <KirokuDropdownOnChangeSearch
                disabled={props.isDisabled}
                defaultValue={t('Export.SelectCustomer')}
                title={customerName}
                icon={'far fa-user text-secondary'}
                onSelect={(event) => {
                    setCustomerName(event.kirokuSelected.label);
                    props.customerSelected(event.kirokuSelected.value || props.customerId);
                    props.getCustomerName(event.kirokuSelected.label);
                }}
                items={itemsDropdownSearch(customers, t)}
                handleChange={handleSearchKeywords}
            />
        </div>
    );
}

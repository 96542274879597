import React                                 from 'react';
import { KirokuCheckboxBorder, KirokuInput } from '../../../../../src/ui';
import _                                     from 'lodash';
import { useTranslation }                    from 'react-i18next';
import useSelectTemplateModal                from './useSelectTemplateModal';

export default function TemplateSelectedTab() {
    const { t } = useTranslation();
    const {
              keywordSelected,
              handleChangeKeywordSelected,
              templateSelectedShowInModal,
              handleCheckAllSelected,
              handleCheckTemplateSelected,
          }     = useSelectTemplateModal();
    return (
        <div>
            <div className={ 'pt-4' } style={ { paddingBottom: 30 } }>
                <KirokuInput
                    style={ { height: 36, fontSize: 14 } }
                    value={ keywordSelected }
                    onChange={ (e) => handleChangeKeywordSelected(e.target.value) }
                    placeholder={ t('RecordsBeta.keyword') }
                />
            </div>
            <div style={{ minHeight: 'calc(100vh - 400px'}}>
                { templateSelectedShowInModal.length ?
                    <div>
                        <div className={ 'p-3' }
                             style={ { border: 'solid 1px #dfe2e5' } }
                        >
                            <div className={'d-flex align-items-center'}>
                                <label className="check-group">
                                    <input type="checkbox" checked={  _.findIndex(templateSelectedShowInModal, (ctm) => (!ctm.checked)) === -1 }
                                           onChange={ handleCheckAllSelected }/>
                                    <span className="checkmark"/>
                                </label>
                                <span className={`font-text-content-in-modal pl-3`}>&nbsp;</span>
                            </div>
                        </div>

                        <div className='background-color' style={ {
                            overflow : 'scroll',
                            height   : 'auto',
                            minHeight: 'calc(100vh - 450px)',
                            maxHeight: 'calc(100vh - 450px)',
                        } }>
                            { templateSelectedShowInModal.map((survey, i) => {
                                return (
                                    <div key={ i }
                                         onClick={ () => handleCheckTemplateSelected(i) }
                                    >
                                        <div className={ ' cursor-pointer' }>
                                            <KirokuCheckboxBorder content={ <span className={ 'font-text-in-checkbox' }
                                                                            style={ { wordBreak: 'break-word' } }
                                            >{ survey.title }</span> }
                                                            checked={ !!survey.checked }
                                                            onChange={ () => handleCheckTemplateSelected(i) }
                                                            id={ `${ survey.id }-${ i }` }/>
                                        </div>
                                    </div>
                                );
                            }) }
                        </div>
                    </div> :
                    <div className='background-color d-flex'
                         style={ { fontSize: '16px', height: 'auto', minHeight: 'calc(100vh - 400px' } }>
                        <div style={ { margin: 'auto' } }>
                            { t('RecordsBeta.noSurveysSelected') }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

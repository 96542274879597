import React from 'react';
import '../../../customer.css';

export default function FormUploadCustomer({ children }) {

    return (
        <div className='wrap-upload-customer active'>
            <div className='fix-center pt-3 pb-3'>
                <div className="border-icon-upload active">
                    <i className="fas fa-file-upload color-icon-upload-customer"/>
                </div>
                <div className='text-upload-customer'>
                    {children}
                </div>
            </div>
        </div>
    );
}


import { getGroupedOptions }          from '../../Converter';
import {
    ComponentMobile, KirokuSectionDropdownShowMore,
    ComponentWeb, KirokuSectionDropdownShowMoreMobile,
}                                     from '../../../ui';
import React, { useEffect, useState } from 'react';
import { resources }                  from '@kiroku/api';
import { useTranslation }             from 'react-i18next';
import { useDropdownSurveyDefault }   from './useDropdownSurveyDefault';

export default function DropdownSelectSurvey({ selectedSurvey, surveyDefault, customerId }) {
    const { t }                                                     = useTranslation();
    const [surveys, setSurveys]                                     = useState([]);
    const { search, result }                                        = resources.useSearch('Template');
    const { surveyTagsDefault, getSurveysDefault, cancelAPISearch } = useDropdownSurveyDefault();

    useEffect(() => {
        search({
            type     : 'customers',
            customers: customerId,
        });
    }, [search, customerId]);

    useEffect(() => {
        if (surveyDefault.length) {
            let surveyId = surveyDefault.map(survey => survey.id);
            getSurveysDefault(surveyId);
        }
        return () => {
            cancelAPISearch();
        };
    }, [getSurveysDefault, surveyDefault, cancelAPISearch]);

    useEffect(() => {
        if (surveyTagsDefault.length) {
            setSurveys(surveyTagsDefault);
        } else {
            setSurveys([]);
        }
    }, [surveyTagsDefault]);

    const onChange = (options) => {
        setSurveys(options);
        selectedSurvey(options);
    };

    return (
        <div>
            <ComponentWeb>
                <KirokuSectionDropdownShowMore
                    placeholder={ t('Surveys.placeholderSearch') }
                    tagType={ 'Customer' }
                    onChange={ onChange }
                    value={ surveys }
                    options={ getGroupedOptions('Template', result) }
                />
            </ComponentWeb>
            <ComponentMobile>
                <KirokuSectionDropdownShowMoreMobile
                    placeholder={ t('Surveys.placeholderSearch') }
                    tagType={ 'Customer' }
                    onChange={ onChange }
                    value={ surveys }
                    options={ getGroupedOptions('Template', result) }
                />
            </ComponentMobile>
        </div>
    );
}

import React, {PureComponent} from "react";
import moment             from 'moment';

export default class KirokuAge extends PureComponent {

    render() {
        const {dob} = this.props;
        return <>
            {dob ? moment().diff(moment.unix(dob), 'years', false) : ''}
        </>;
    }
}

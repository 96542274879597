import React, { useEffect, useState } from 'react';
import {
    KirokuModalBody, KirokuModalFooter,
    KirokuButton, KirokuModal,
}                                     from '../../../ui';
import { useTranslation }             from 'react-i18next';
import { resources }                  from '@kiroku/api';
import useProgressEvent        from '../../Customer/useProgressEvent';
import { useMixpanelTracking } from '../../../Mixpanel/useMixpanelTracking';

export default function ModalConfirmDeleteUser(props) {
    const { t }                                         = useTranslation();
    const { searchAfterDelete, closeModal, show }       = props;
    const deleteUsers                                   = resources.useDelete('User')[1];
    const [beginDelete, isDoneDelete, setInitial]       = useProgressEvent(deleteUsers, [props.user.id]);
    const [isDisabledBtnSubmit, setIsDisabledBtnSubmit] = useState(false);
    const { trackingMixpanel }                          = useMixpanelTracking();

    useEffect(() => {
        if (isDoneDelete) {
            setIsDisabledBtnSubmit(false);
        }
        if (isDoneDelete) {
            searchAfterDelete();
            setInitial();
        }
    }, [isDoneDelete, searchAfterDelete, setInitial]);

    const confirmRemoveUser = () => {
        trackingMixpanel('DELETE_USERS', props.user);
        setIsDisabledBtnSubmit(true);
        beginDelete();
        closeModal();
        searchAfterDelete();
    };
    return (
        <KirokuModal show={ show } onClick={ closeModal }>
            <KirokuModalBody>
                <p className='text-center pt-10'>
                    { t('staff.messageDelete') }
                </p>
                <p className='text-center'>
                    { props.user.name || '' }
                </p>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={ closeModal } color={ 'white' }>
                    { t('common.Cancel') }
                </KirokuButton>
                <KirokuButton
                    disabled={ isDisabledBtnSubmit }
                    onClick={ confirmRemoveUser }
                    color={ 'primary' }>
                    { t('common.Confirm') }
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

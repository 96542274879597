import React              from 'react';
import { useTranslation } from 'react-i18next';

export default function InputUserName({ setUserName }) {

    const { t } = useTranslation();

    return (
        <label className="form-input">
            <input name="username"
                   type="text"
                   autoComplete='username'
                   onChange={(event) => {
                       event.preventDefault();
                       setUserName(event.target.value);
                   }}
            />
            <span className="label">{t('Login.username')}</span>
            <div className="underline"/>
        </label>
    );
}

import React, { useEffect }    from 'react';
import ActionTeam              from '../ComponentUnited/ActionTeam';
import { useHistory }          from 'react-router-dom';
import { resources }           from '@kiroku/api';
import useNotification         from '../../Layout/useNotification';
import { useTranslation }      from 'react-i18next';
import { useMixpanelTracking } from '../../../Mixpanel/useMixpanelTracking';

export default function CreateTeam() {
    const {notification} = useNotification();
    const { t } = useTranslation();

    const [createdTeam, create] = resources.useCreate('Team');
    const { trackingMixpanel }  = useMixpanelTracking();

    const history               = useHistory();
    const team                  = {
        name       : '',
        description: '',
    };

    useEffect(() => {
        if (createdTeam) {
            notification(t('Team.YouUpdateTeamAreSuccessful'), 'success');
            history.push(`/teams/${createdTeam.id}`);
        }
    }, [createdTeam, history, notification, t]);


    const handleSubmit = (valueSubmit) => {
        create(valueSubmit);
        trackingMixpanel('CREATE_TEAM', valueSubmit);
    };

    return (
        <ActionTeam
            team={team}
            members={[]}
            handleSubmit={(value) => handleSubmit(value)}
        />
    );
}

import { useHistory }                       from 'react-router-dom';
import queryString                          from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { resources }                        from '@kiroku/api';
import { getKeyWordSearch }                 from '../../ServiceSearch';


export default function useConditionTagParams() {
    const history = useHistory();
    let keywords  = '';
    try {
        const conditionUrl = queryString.parse(history.location.search.replace('?', ''), { arrayFormat: 'comma' });
        if(conditionUrl.name) {
            keywords = getKeyWordSearch(history.location.search.replace('?', ''), 'name');
        }
    } catch (err) {

    }

    return {
        conditionFormParams: {
            keywords,
        },
    };
}


export function useConditionTag(tagType) {
    let [currentTags, setCurrentTags] = useState([]);
    const { result, search, cancelAPISearch } = resources.useSearch(tagType);

    const getInfoTag = useCallback((id) => {
        search({ type: 'id', id });
    }, [search]);

    useEffect(() => {
        const types = {
            Team    : 'Teams',
            User    : 'Users',
            Customer: 'Customers',
        };
        setCurrentTags(result.map(itm => {
            return {
                ...itm,
                key  : `${tagType}_${itm.id}`,
                label: itm.name,
                value: `${itm.name}${itm.id}`,
                type : types[ tagType ],
                id   : itm.id,
            };
        }));
    }, [result, tagType]);
    return [currentTags, getInfoTag, cancelAPISearch];
}

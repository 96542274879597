import React, { useEffect, useState } from 'react';
import {
    KirokuModal, KirokuModalBody,
    KirokuModalFooter, KirokuButton,
}                                     from '../../../../../ui';
import { useTranslation }             from 'react-i18next';
import useProgressEvent               from '../../../useProgressEvent';
import { resources }                  from '@kiroku/api';
import useNotification                from '../../../../Layout/useNotification';

export default function ModalConfirmDelete(props) {
    const { t }             = useTranslation();
    const deleteDocumentAPI = resources.useDelete('Document')[ 1 ];

    const { documentSelected, show, closeModal, handleAfterDelete } = props;
    const [isDisabledBtnSubmit, setIsDisabledBtnSubmit]             = useState(false);
    const [isShowProgress, setIsShowProgress]                       = useState(false);
    const [beginDelete, isDoneDelete, setInitial, statusDelete]     = useProgressEvent(deleteDocumentAPI, documentSelected.map(item => item.id));
    const  { notification}  = useNotification();

    const handleConfirmDelete = () => {
        setIsShowProgress(true);
        setIsDisabledBtnSubmit(true);
        beginDelete().then(() => {
            notification(t('message.DeleteSuccess'), 'success');
        });
    };

    useEffect(() => {
        if (isDoneDelete) {
            setIsShowProgress(false);
            closeModal();
            setIsDisabledBtnSubmit(false);
        }
        if (isDoneDelete && show) {
            handleAfterDelete();
            setInitial();
        }
    }, [isDoneDelete, closeModal, show, handleAfterDelete, setInitial]);

    return (<KirokuModal show={show} onClick={() => closeModal()}>
        <KirokuModalBody>
            <p className='text-center mt-8'>
                {t('message.deleteDocument', { count: documentSelected.length })}
            </p>
            <div className="progress" hidden={!isShowProgress}>
                <div className="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                     aria-valuenow={statusDelete} aria-valuemin="0" aria-valuemax="100"
                     style={{ width: statusDelete * 100 }}>
                </div>
            </div>
        </KirokuModalBody>
        <KirokuModalFooter>
            <KirokuButton color={'white'}
                          onClick={() => {
                              closeModal();
                          }}
            >
                {t('common.Cancel')}
            </KirokuButton>
            <KirokuButton color={'primary'}
                          disabled={isDisabledBtnSubmit}
                          onClick={() => {
                              handleConfirmDelete();
                          }}
            >
                {t('common.Confirm')}
            </KirokuButton>
        </KirokuModalFooter>
    </KirokuModal>);
}

import React  from 'react';
import {Link} from 'react-router-dom';

function LinkPermission (props) {

    return (
        !props.hidden &&
            (
                <span>
                    <Link to={props.href} {...props}>
                        {props.icon}
                        <span className={`ml-1 ${props.color}`}>{props.text}</span>
                    </Link>
                </span>
            )
    );
}

export default LinkPermission;
//className={`${props.disabled === true ? "disabled-link" : ""}`} tag <span/>

import React, { useState }                                                      from 'react';
import { KirokuCardBodyWeb, KirokuCardWeb, KirokuRadio, KirokusSortableHandle } from '../../../ui';
import { useTranslation }                                                       from 'react-i18next';
import ActionGroupBtn                                                           from './ActionGroupBtn';
import QuestionCardTitle                                                        from './QuestionTitleCard';
import InfoQuestion                                                             from './InfoQuestion';
import CheckboxUnrequited                                                       from './CheckboxUnrequited';
import NextQuestion                                                             from './NextQuestion';
import useQuestions                                                             from '../MakeSurvey/useQuestions';
import { modalSetLogic }                                                        from '../ModalSetLogicByQuestionType';
import ModalEditQuestion
                                                                                from '../FormQuestion/ModalEditQuestion';

export default function YesNo({ question }) {
    const { t }                                                 = useTranslation();
    const ModalSetLogic                                         = modalSetLogic[question.type];
    const [isOpenModalEditQuestion, setIsOpenModalEditQuestion] = useState(false);
    const [isOpenModalName, setIsOpenModalName]                 = useState(null);

    const isLogic = typeof question.nextQuestion === 'number' ||
        typeof question.nextQuestionYes === 'number' ||
        typeof question.nextQuestionNo === 'number';

    const { update, copy, delQuestion } = useQuestions();

    const handleSetDefaultAnswer = (event) => {
        let newQuestion = { ...question };
        newQuestion     = {
            ...newQuestion,
            defaultAnswer: event.target.value,
        };
        update(newQuestion);
    };

    return (
        <div id='component-question'>
            <div id='web-q' className='pb-3'>
                <KirokuCardWeb>
                    <KirokusSortableHandle>
                        <QuestionCardTitle
                            index={question.index}
                            isLogic={isLogic}
                        >
                            <ActionGroupBtn
                                updateQuestion={(questionData) => update(questionData)}
                                handleOpenModalAddLogic={() => {
                                    setIsOpenModalName(question.index);
                                }}
                                question={question}
                                resetDefaultAnswer={() => {
                                    if (question.defaultAnswer !== undefined) {
                                        delete question.defaultAnswer;
                                        update(question);
                                    }
                                }}
                                handleDel={() => delQuestion(question.index)}
                                handleCopy={() => {
                                    copy(question);
                                }}
                            />
                        </QuestionCardTitle>
                    </KirokusSortableHandle>
                    <KirokuCardBodyWeb>
                        <CheckboxUnrequited
                            question={question}
                            isNotUse={isLogic}
                        />
                        <InfoQuestion question={question}/>
                        <NextQuestion nextQuestion={question.nextQuestion}/>
                        <div className='answer-question'>
                            <KirokuRadio onChange={(e) => handleSetDefaultAnswer(e)}
                                         id={`yes-${question.index}`}
                                         name={`questionYesNo${question.index}`}
                                         checked={question.defaultAnswer === 'Yes'}
                                         value={'Yes'}
                            >
                                <div className={'pr-2'}>{t('createSurvey.Yes')}</div>
                            </KirokuRadio>
                            <NextQuestion nextQuestion={question.nextQuestionYes}/>
                            <KirokuRadio onChange={(e) => handleSetDefaultAnswer(e)}
                                         checked={question.defaultAnswer === 'No'}
                                         id={`no-${question.index}`}
                                         name={`questionYesNo${question.index}`}
                                         value={'No'}

                            >
                                <div className={'pr-2'}>{t('createSurvey.No')}</div>
                            </KirokuRadio>
                            <NextQuestion nextQuestion={question.nextQuestionNo}/>
                        </div>
                    </KirokuCardBodyWeb>
                </KirokuCardWeb>

                <ModalEditQuestion
                    isOpenModalEditQuestion={isOpenModalEditQuestion}
                    handleAction={() => setIsOpenModalEditQuestion(!isOpenModalEditQuestion)}
                    question={question}
                    updateQuestion={(questionData) => update(questionData)}
                />

                <ModalSetLogic
                    question={question}
                    isOpen={isOpenModalName === question.index}
                    confirm={() => {
                        setIsOpenModalName(null);
                    }}
                    cancellation={() => setIsOpenModalName(null)}
                />
            </div>
        </div>
    );
}

import React, { useCallback, useEffect, useState }                       from 'react';
import { useClient }                                                     from '@kiroku/api';
import { useTranslation }                                                               from 'react-i18next';
import { KirokuButton, KirokuLoading, KirokuModal, KirokuModalBody, KirokuModalFooter } from '../../../ui';


export default function KirokuNoticeDownloadExport({ exportId, setExportId }) {

    const { t }                            = useTranslation();
    const { axios }                        = useClient();
    const [exportRecord, setExportRecord]  = useState(null);
    const [loading, setLoading]            = useState(true);

    const [isDisableButtonDownload, setIsButtonDownload] = useState(false)

    const fetchDataExport = useCallback(() => {
        if(exportRecord && (!exportRecord.is_exporting || exportRecord.is_error)) {
            return null;
        }
        if (typeof exportId === 'number') {
            axios.get(`/export-record/fetch-url-download/${exportId}`).then(res => {
                setExportRecord(res.results);
            })
        }
    }, [axios, exportRecord, exportId]);
    useEffect(() => {
        const interval = setInterval(() => {
            fetchDataExport();
        }, 3000);
        return () => clearInterval(interval);
    }, [axios, fetchDataExport]);

    useEffect(() => {
        setLoading(!!exportId);
        if(!exportId) {
            setExportRecord(null)
        }
    }, [exportId]);


    const handleConfirmDownload = () => {
        if(exportRecord) {
            axios.get(`/export-record/confirm-download/${ exportRecord.id }`)
                .then(() => {
                    setExportRecord(null);
                    setExportId(null)
                    setIsButtonDownload(false)
                });
        }
    }
    const handleOnclickDownload = (exportData) => {
        setIsButtonDownload(true)
        let linkDownload = document.getElementById('downloadFile');
        linkDownload.setAttribute('href', exportData.link);
        linkDownload.click();
        handleConfirmDownload();
    };
    const isLoading = !(exportRecord && (!exportRecord.is_exporting || exportRecord.is_error)) && loading;

    return <div>
        <KirokuModal show={ exportId }
                     onCloseModal={ () => setExportId(null)}
        >
            <KirokuModalBody>
                {  isLoading ? <div className={'text-center'} style={{paddingBottom: '1rem'}}>
                    <KirokuLoading/>
                    {t('common.waiting download')}
                </div> : exportRecord ? <div className={'text-center'}>
                    { exportRecord.is_error ? <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                        } }
                    >
                        <i className="far fa-times-circle fa-2x" style={{color: 'red'}}/> &nbsp;&nbsp;
                        {t('common.error download')}
                    </div> : <div>
                        {t('common.Export successfully')}
                        <br/>
                        {t('common.Click button to download')}
                    </div>}
                </div> : '' }
            </KirokuModalBody>
            {!isLoading ? <KirokuModalFooter>
                { (exportRecord && !exportRecord.is_exporting && !exportRecord.is_error) ? <KirokuButton
                    disabled={isDisableButtonDownload}
                    onClick={ () => handleOnclickDownload(exportRecord) }
                    className={'btn-active'}
                >
                    {t('common.Download')}
                </KirokuButton> : '' }
                <KirokuButton onClick={ () => {
                    setExportId(null);
                    handleConfirmDownload();
                } }
                >
                    {t('common.close')}
                </KirokuButton>
            </KirokuModalFooter> : ''}
        </KirokuModal>
    </div>;
}

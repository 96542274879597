import React, { useEffect, useState }                              from 'react';
import { KirokuCardBodyWeb, KirokuCardWeb, KirokusSortableHandle } from '../../../ui';
import useQuestions                                                from '../MakeSurvey/useQuestions';
import CheckboxUnrequited                                          from './CheckboxUnrequited';
import ActionGroupBtn                                              from './ActionGroupBtn';
import QuestionCardTitle                                           from './QuestionTitleCard';
import InfoQuestion                                                from './InfoQuestion';
import NextQuestion                                                from './NextQuestion';
import { modalSetLogic }                                           from '../ModalSetLogicByQuestionType';
import ModalEditQuestion                                           from '../FormQuestion/ModalEditQuestion';
import KirokuTextareaAutosize                                      from '../../../ui/KirokuTextareaAutosize';

export default function InputText({ question }) {

    const [inputValue, setInputValue]                           = useState(question.defaultAnswer || '');
    const [isOpenModalEditQuestion, setIsOpenModalEditQuestion] = useState(false);
    const { update, copy, delQuestion }                         = useQuestions();

    useEffect(() => {
        setInputValue(question.defaultAnswer);
    }, [question.defaultAnswer]);

    const [isOpenModalName, setIsOpenModalName] = useState(null);

    const ModalSetLogic = modalSetLogic[ question.type ];


    return (
        <div id='component-question'>
            <div id='web-q' className='pb-3'>
                <KirokuCardWeb>
                    <KirokusSortableHandle>
                        <QuestionCardTitle
                            index={question.index}
                            isLogic={typeof question.nextQuestion === 'number'}
                        >
                            <ActionGroupBtn
                                handleOpenModalAddLogic={() => {
                                    setIsOpenModalName(question.index);
                                }}
                                handleOpenModalEdit={() => {
                                    setIsOpenModalEditQuestion(true);
                                }}
                                question={question}
                                resetDefaultAnswer={() => {
                                    if (question.defaultAnswer !== undefined) {
                                        delete question.defaultAnswer;
                                        update(question);
                                    }
                                }}
                                handleDel={() => delQuestion(question.index)}
                                handleCopy={() => {
                                    copy(question);
                                }}
                            />
                        </QuestionCardTitle>
                    </KirokusSortableHandle>
                    <KirokuCardBodyWeb>
                        <CheckboxUnrequited
                            question={question}
                            isNotUse={typeof question.nextQuestion === 'number'}
                        />
                        <InfoQuestion question={question}/>
                        <div className={'pb-10px'}>
                            <NextQuestion nextQuestion={question.nextQuestion}/>
                        </div>
                        <KirokuTextareaAutosize className='form-area'
                                                value={ inputValue || '' }
                                                onChange={ (event) => {
                                                    setInputValue(event.target.value);
                                                } }
                                                onBlur={ (event) => update({
                                                    ...question,
                                                    defaultAnswer: event.target.value,
                                                }) }
                        />
                    </KirokuCardBodyWeb>
                </KirokuCardWeb>

                <ModalEditQuestion
                    isOpenModalEditQuestion={isOpenModalEditQuestion}
                    handleAction={() => setIsOpenModalEditQuestion(!isOpenModalEditQuestion)}
                    question={question}
                    updateQuestion={(questionData) => update(questionData)}
                />

                <ModalSetLogic
                    question={question}
                    isOpen={isOpenModalName === question.index}
                    confirm={() => {
                        setIsOpenModalName(null);
                    }}
                    cancellation={() => setIsOpenModalName(null)}
                />

            </div>
        </div>
    );
}

import React              from 'react';
import { useTranslation } from 'react-i18next';
import moment             from 'moment';

import {
    CustomerLabelComponent, PlanLabelComponent, StaffLabelComponent,
    SurveyRecordLabelComponent, SurveyTemplateLabelComponent,
    TeamLabelComponent,
}                   from './LabelHistoryLog';
import ComponentLog from './ComponentLog';


export default function HistoryLog({ logs = [] }) {
    const { t } = useTranslation();

    return <>
        { logs.map((log, index) => {
            return <div key={ index } className={ 'p-3 border-date-log' }>
                <div className={ 'time-label-entity-history-log' }>
                    <div className={ 'time-log' }>
                        { moment.unix(log.create_at).format(t('dateTimeFormat.HourMinute')) }
                    </div>
                    <div>
                        { log.type === 'surveyRecord' ? <SurveyRecordLabelComponent/> : null }
                        { log.type === 'surveyTemplate' ? <SurveyTemplateLabelComponent/> : null }
                        { log.type === 'team' ? <TeamLabelComponent/> : null }
                        { log.type === 'customer' ? <CustomerLabelComponent/> : null }
                        { log.type === 'staff' ? <StaffLabelComponent/> : null }
                        { log.type === 'plan' ? <PlanLabelComponent/> : null }
                    </div>
                </div>
                <ComponentLog log={ log }/>
            </div>;
        }) }
    </>;
}

import React                   from 'react';
import { useTranslation }      from 'react-i18next';
import Chart                   from 'react-google-charts';
import useNumberChart          from './useNumberChart';
import NoAnswersFoundComponent from '../NoAnswersFoundComponent';

export default function NumberChart({ records, question, handleSelectAnswer }) {

    const { t }                      = useTranslation();
    const { data, ticks, maxColumn, isNotDataAnalysis } = useNumberChart(records, question);

    if(isNotDataAnalysis) {
        return <NoAnswersFoundComponent/>
    }

    const chartEvents = [
        {
            eventName: "select",
            callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart()
                const selection = chart.getSelection()
                if (selection.length === 1) {
                    const [selectedItem] = selection
                    const dataTable = chartWrapper.getDataTable()
                    const { row, column } = selectedItem;
                    handleSelectAnswer({type: question.type, content : question.content, answer : dataTable.getValue(row, column || 0)});
                }
            }
        }
    ]

    return <div id="chart">
        <Chart
            height={'400px'}
            chartType="ScatterChart"
            loader={<div>Loading Chart</div>}
            data={[
                ['row label', t('Analysis.valueNumberAnswer')],
                ...data,
            ]}
            options={{
                title : '',
                hAxis : {
                    title: t('Analysis.servicedAt'),
                    ticks: [
                        { v: 0, f: '' },
                        ...ticks,
                        { v: maxColumn + 1, f: '' },
                    ],
                },
                vAxis : { title: t('Analysis.valueNumberAnswer') },
                legend: 'none',
            }}
            chartEvents={chartEvents}
        />
    </div>;
}

import React                           from 'react';
import { KirokuInput, KirokuCheckBox, KirokuCheckboxBorder } from '../../../../../ui';
import { useTranslation }              from 'react-i18next';
import _                               from 'lodash';
import useSelectedCustomer             from '../useSelectedCustomer';
import DropdownSelectTeam              from '../../SelectUpdater/DropdownSelectTeam';

export default function SelectedCustomerMobileTab(props) {
    const { t }                 = useTranslation();
    const {
              customersSelected, handleCheckCustomerSelected,
              handleCheckAllSelected, handleChangeKeywordSelected,
              handleChangeTeamSelected, keywordSelected,
              handleResetCondition,
          } = useSelectedCustomer();
    return (
        <div className="pt-3 pl-1 pr-1 ">
            <div className='font-text-on-btn'>
                <DropdownSelectTeam handleChangeTags={ (value) => handleChangeTeamSelected(value) }
                                    teamSearch={props.teams} showModal={props.show}
                                    placeholder={ t('RecordsBeta.refineByTeam') }/>

            </div>
            <div className="pt-1 font-text-on-btn pb-3">
                <KirokuInput value={ keywordSelected }
                             className={ 'css-input-medium' }
                             onChange={ (e) => {
                                 handleChangeKeywordSelected(e.target.value)
                             } }
                             placeholder={ t('RecordsBeta.filterByCustomerName') }/>
            </div>

            <div className={ 'card' }>
                <div>
                    { customersSelected.length ?
                        <div>
                            <div className={ 'd-flex p-3 border-bottom justify-content-between' }
                                 style={ { border: '1px solid rgb(223, 226, 229)' } }
                            >
                                <KirokuCheckBox
                                    checked={ _.findIndex(customersSelected, (ctm) => (!ctm.selected)) === -1 }
                                    onChange={ handleCheckAllSelected }
                                />
                                <div/>
                                <div className={ 'font-text-reset font-size-16' } style={ { color: '#007BFF' } }
                                     onClick={ handleResetCondition }>
                                    { t('RecordsBeta.resetSelection') }
                                </div>
                            </div>
                            { customersSelected.map((customer, index) => (
                                <div key={ index }
                                     onClick={ () => handleCheckCustomerSelected(index) }
                                >
                                    <KirokuCheckboxBorder checked={ !!customer.selected }
                                                          id={ `${ customer.id }-${ index }` }
                                                          onChange={ () => handleCheckCustomerSelected(index) }
                                                          content={ <span
                                                              className={ 'font-text-in-checkbox' }> { customer.name }</span> }
                                    />
                                </div>
                            )) }
                        </div> : <div className='d-flex' style={ { height: '50vh' } }>
                            <div className={ 'font-text-not-found pt-3' }
                                 style={ { margin: 'auto' } }>
                                { t('RecordsBeta.noCustomersSelected') }
                            </div>
                        </div> }
                </div>
            </div>
        </div>
    );
}

import { useTranslation }    from 'react-i18next';
import { resources }         from '@kiroku/api';
import React, {
    useEffect, useState,
}                            from 'react';
import {
    ComponentMobile, ComponentWeb, KirokuSectionDropdownShowMore, KirokuSectionDropdownShowMoreMobile,
}                            from '../../../ui';
import { getGroupedOptions } from '../../Converter';
import { useConditionTag }   from '../../Customer/CustomerList/useConditionFromParams';

export default function DropdownSelectTeams(props) {
    const { t }                                          = useTranslation();
    const { teamTagIds }                                 = props;
    const { result, search, cancelAPISearch }            = resources.useSearch('Team');
    const [teams, setTeams]                              = useState([]);
    const [currentTagTeam, getInfoTag, cancelGetInfoTag] = useConditionTag('Team');
    const [currentTags, setCurrentTags]                  = useState([]);

    useEffect(() => {
        search({ type: 'all' });
        return () => {
            cancelAPISearch();
        };
    }, [search, cancelAPISearch]);

    useEffect(() => {
        setTeams(result);
    }, [result]);

    useEffect(() => {
        if (teamTagIds) {
            getInfoTag(teamTagIds);
            return () => {
                cancelGetInfoTag();
            };
        }
    }, [teamTagIds, getInfoTag, cancelGetInfoTag]);

    useEffect(() => {
        setCurrentTags(currentTagTeam);
    }, [currentTagTeam]);


    let teamOptions = getGroupedOptions('Team', teams);

    const handleChangeTags = (options) => {
        setCurrentTags(options);
        props.handleChangeTeamTags(options);
    };

    return (
        <div>
            <ComponentWeb>
                <div className="pb-3">
                    <KirokuSectionDropdownShowMore
                        isSearchTags
                        tagType={ 'Team' }
                        placeholder={ t('Team.placeholderSearch') }
                        onChange={ options => handleChangeTags(options) }
                        handleSearchTags={ props.handleSearchTags }
                        value={ currentTags }
                        options={ teamOptions }
                    />
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <div>
                    <KirokuSectionDropdownShowMoreMobile
                        isSearchTags
                        tagType={ 'Team' }
                        placeholder={ t('Team.placeholderSearch') }
                        onChange={ options => handleChangeTags(options) }
                        handleSearchTags={ props.handleSearchTags }
                        value={ currentTags }
                        options={ teamOptions }
                    />
                </div>
            </ComponentMobile>
        </div>
    );

}

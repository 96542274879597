import React, { useEffect } from 'react';
import { resources }        from '@kiroku/api';
import {
    ComponentMobile,
    ComponentWeb, KirokuLoader, KirokuCard,
    KirokuTable, TBody, THeader, KirokuCardList,
}                           from '../../../ui';
import If                   from '../../If';
import TabResult            from '../UserDetail/TabResult';
import { Link }             from 'react-router-dom';
import { useTranslation }   from 'react-i18next';

export default function TabManageTeam({ show, resultUser }) {
    const { result, search, loading } = resources.useSearch('Team', { type: 'all', userId: resultUser.id });
    const { t }                       = useTranslation();
    useEffect(() => {
        if (show) {
            search({ type: 'all', userId: resultUser.id });
        }
    }, [show, search, resultUser]);

    return (<div hidden={!show}>
        <KirokuLoader loading={loading}>
            <If statement={result.length}
                otherwise={<TabResult messageFirst={'team1'} messageSecond={'team2'}>
                    <img className='pt-2 pb-2'
                         src={`/assets/img/team-icon@2x.png`} alt="no team"/>
                </TabResult>}
            >
                {() => <div>
                    <ComponentWeb>
                        <KirokuTable>
                            <THeader>
                                <tr>
                                    <th style={{ width: 200 }}>{t('staff.Name')}</th>
                                    <th>{t('common.Description')}</th>
                                    <th/>
                                </tr>
                            </THeader>
                            <TBody>
                                {result.map((team, index) =>
                                    <tr key={index}>
                                        <td>
                                            <Link to={`/teams/${team.id}`}>{team.name}</Link>
                                        </td>
                                        <td>{team.description}</td>
                                        <td/>
                                    </tr>,
                                )}
                            </TBody>
                        </KirokuTable>
                    </ComponentWeb>
                    <ComponentMobile>
                        <KirokuCardList headerLeft={<span>{t('staff.Name')}</span>}
                                        headerRight={<span/>}>
                            {result.map((team, index) =>
                                <div style={{ backgroundColor: index % 2 !== 0 ? 'rgba(197,208,222,.1)' : '' }}
                                     key={index}
                                >
                                    <KirokuCard action={<span/>}>
                                        <Link to={`/teams/${team.id}`}>{team.name}</Link>
                                    </KirokuCard>
                                </div>,
                            )}
                        </KirokuCardList>
                    </ComponentMobile>

                </div>}
            </If>
        </KirokuLoader>
    </div>);
}

import React               from 'react';
import useActionSideBar    from './useActionSideBar';
import { useTranslation }    from 'react-i18next';
import { Link }              from 'react-router-dom';
import DropDownUser          from './DropdownUser';
import {
    KirokuTitlepage,
    KirokuActionPage,
    ComponentMobile, ComponentWeb,
}                            from '../../ui';
import { withPermissions }   from '../HOC';
import { authentication }    from '@kiroku/api';
import { useHistory }        from 'react-router-dom';
import useMixPanelLoginTrack from '../../Mixpanel/useMixPanelLoginTrack';

const KirokuAction = withPermissions(KirokuActionPage);

export default ({ path }) => {
    const { t }                                 = useTranslation();
    const history                               = useHistory();
    const [isOpenMenuSideBar, changeIsOpenMenu] = useActionSideBar();
    const { user, logout }                      = authentication.useAuthenticatedContext();
    useMixPanelLoginTrack();

    const pathName = path.split('/').filter(urlName => urlName && (urlName !== '/')).filter(url => !parseInt(url))[0];

    return (
        <div className={`header ${!isOpenMenuSideBar ? 'opacity-header' : ''}`}>
            <div className='header-left'>
                <i className="fa fa-bars back-up-bars" onClick={() => {
                    changeIsOpenMenu();
                }}/>
                <KirokuTitlepage title={ pathName ? t(`titlePage./${pathName}`): ''}/>
            </div>
            <ComponentWeb>
                <div className='header-right'>
                    <div className="pt-1 pr-2 cursor-pointer">
                        <i className="fa fa-power-off" onClick={() => {
                            logout();
                            history.push('/login');
                        }} style={{ fontSize: '1.8em', color: '#FF5733' }}/>
                    </div>
                    <div style={{ paddingLeft: '2rem' }}/>
                    {user && <Link to ={{
                        pathname : `/users/edit-my-profile`,
                        state: {
                            user : {
                                id : user.id,
                            }
                        }
                    }}>
                        <div className='pl-3 font-username'>
                            {user.username}
                        </div>
                    </Link>}

                    {user && <Link to ={{
                        pathname : `/users/edit-my-profile`,
                        state: {
                            user : {
                                id : user.id,
                            }
                        }
                    }}>
                        <div className='pl-2 header-user-avatar'>
                            <div className="avatar"
                                 style={{ backgroundImage: `url(${user.avatar ? user.avatar : '/assets/img/profile-ic.svg'})` }}/>
                            </div>
                    </Link>}
                    {
                        (['/surveys'].includes(path) &&
                            <Link to={'/surveys/create'}>
                                <KirokuAction
                                    resource-permission={'|surveyList|button-new|'}
                                >
                                    {t('Surveys.NewSurvey')}
                                </KirokuAction>
                            </Link>)
                    }
                    {
                        (['/templates-beta'].includes(path) &&
                            <Link to={'/surveys/create'}>
                                <KirokuAction
                                    resource-permission={'|surveyList|button-new|'}
                                    className={'btn-active'}
                                >
                                    {t('common.create')}
                                </KirokuAction>
                            </Link>)
                    }

                    {
                        (['/teams'].includes(path) && <Link to={'/teams/create'}>
                            <KirokuAction
                                resource-permission={'|teamList|button-new|'}
                            >
                                {t('Team.AddTeam')}
                            </KirokuAction>
                        </Link>)
                    }

                    {
                        (['/customers'].includes(path) && <Link to={'/customers/create'}>
                            <KirokuAction
                                resource-permission={'|customerList|button-new|'}
                            >
                                {t('Customer.AddCustomer')}
                            </KirokuAction>
                        </Link>)
                    }
                    {
                        (['/users'].includes(path) && <Link to={'/users/create'}>
                            <KirokuAction
                                resource-permission={'|staffList|button-new|'}
                            >
                                {t('common.AddStaff')}
                            </KirokuAction>
                        </Link>)
                    }
                    {
                        (['/plans'].includes(path) && <Link to={'/plans/create'}>
                            <KirokuAction
                                resource-permission={'|planList|button-create|'}
                                bnColor={'primary'}
                            >
                                {t('plans.addPlan')}
                            </KirokuAction>
                        </Link>)
                    }

                </div>
            </ComponentWeb>
            <ComponentMobile>
                <div className='pl-5'>
                    <DropDownUser/>
                </div>
            </ComponentMobile>

        </div>
    );
}

import React, { useEffect, useState } from 'react';
import {
    ComponentMobile, KirokuButton, KirokuIconButton, KirokuInput, KirokuInputArea, KirokuInputDate,
}                                     from '../../ui';
import DropdownCustomerPlanning       from './DropdownCustomerPlanning';
import { useTranslation }             from 'react-i18next';
import { useHistory }                 from 'react-router-dom';
import useNotification                from '../Layout/useNotification';
import moment                         from 'moment';
import ModalDeletePlan                from './PlanningList/ModalDeletePlan';
import { useMixpanelTracking }        from '../../Mixpanel/useMixpanelTracking';
import { useProfile }                 from '../useProfile';

export default function PlanningAction({ plan, handleSubmit, message, isUpdate, disabledButtonSubmit, isDuplicatePlanScreen }) {
    const { t }                                         = useTranslation();
    const [user]                                        = useProfile()
    const [shortTermTarget, setShortTermTarget]         = useState(plan['short_term_target'] || '');
    const [longTermTarget, setLongTermTarget]           = useState(plan['long_term_target'] || '');
    const [overView, setOverView]                       = useState(plan['overview'] || '');
    const [standardHoursOfSupport, setStandardHoursOfSupport] = useState(plan['standard_hours_of_support_provision'] || '');
    const [customerName, setCustomerName]                 = useState(plan.customer ? plan.customer.name : '');
    const [customerId, setCustomerId]                   = useState(plan.customer_id || '');
    const [orgName, setOrgName]                         = useState(plan.org_name || user.organization_name);
    const [divisionName, setDivision]                   = useState(plan.division_name || '');
    const history                                       = useHistory();
    const { notification }                              = useNotification();
    const [isDisabledBtnSubmit, setIsDisabledBtnSubmit] = useState(false);
    const [showModal, setShowModal]                     = useState('');

    const [validateEndDatePlan, setValidateEndDatePlan]     = useState(true);

    const [validateEndDateLongTerm, setValidateEndDateLongTerm]     = useState(  true);

    const [validateEndDateShortTerm, setValidateEndDateShortTerm]     = useState( true);

    const [longTermTargetDateRange, setLongTermTargetDateRange] = useState({
        startDate: plan.long_term_target_start_at || null,
        endDate  : plan.long_term_target_end_at || null,
    });

    const [shortTermTargetDateRange, setShortTermTargetDateRange] = useState({
        startDate: plan.short_term_target_start_at || null,
        endDate  : plan.short_term_target_end_at || null,
    });

    const [planDateRange, setPlanDateRange] = useState({
        startDate: plan.start_at || null,
        endDate  : plan.end_at || null,
    });

    const { trackingMixpanel } = useMixpanelTracking();

    useEffect(() => {
        if(isDuplicatePlanScreen) {
            setIsDisabledBtnSubmit(disabledButtonSubmit);
        }
    }, [disabledButtonSubmit, isDuplicatePlanScreen]);

    useEffect(() => {
        if (planDateRange.startDate <= planDateRange.endDate) {
            setValidateEndDatePlan(true);
        }
    }, [planDateRange]);

    useEffect(() => {
        if (longTermTargetDateRange.startDate <= longTermTargetDateRange.endDate) {
            setValidateEndDateLongTerm(true);
        }
    }, [longTermTargetDateRange]);

    useEffect(() => {
        if (shortTermTargetDateRange.startDate <= shortTermTargetDateRange.endDate) {
            setValidateEndDateShortTerm(true);
        }
    }, [shortTermTargetDateRange]);

    return (
        <div className='padding-responsive'>
            <div className="pb-3">
                { isUpdate && <div className="d-flex justify-content-end">
                    <KirokuIconButton onClick={ () => setShowModal('delete') }/>
                </div> }
            </div>
            <div className="card">
                {isUpdate ? <div className={'header-card-detail'}>
                        {plan.customer && <span className="font-size-18">{plan.customer.name}</span>}
                    </div> :
                    <div
                        style={{borderBottom: 'solid 1px #dfe3e9', backgroundColor: '#ffffff'}}>
                        <div className="p-3">
                            <div className="label-info"> {t('plans.customers')}</div>
                            <div className="pt-10">
                                <DropdownCustomerPlanning
                                    isDisabled={isUpdate || plan.isDisabledCustomer}
                                    customerName={customerName}
                                    customerId={customerId}
                                    customerSelected={setCustomerId}
                                    getCustomerName={setCustomerName}
                                />
                            </div>
                        </div>
                    </div>}
                <div style={{borderBottom: 'solid 1px #dfe3e9', backgroundColor: '#ffffff'}}
                     className={'p-3'}
                >
                    <div className="row">
                        <div className="col-md-12 col-xl-8 col-lg-9">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="label-info">
                                        {t('plans.organizationName')}*
                                    </div>
                                    <div className="pt-10">
                                        <KirokuInput
                                            defaultValue={orgName}
                                            onChange={(e) => {
                                                setOrgName(e.target.value)
                                            }}
                                        />
                                        <p hidden={orgName} style={{
                                            color: 'red',
                                            fontSize: 12,
                                        }}>
                                            {t('plans.orgNameIsRequite')}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 padding-top-plan-action">
                                    <div className="label-info">
                                        {t('plans.divisionName')}
                                    </div>
                                    <div className="pt-10">
                                        <KirokuInput
                                            defaultValue={divisionName}
                                            onChange={(e) => setDivision(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{borderBottom: 'solid 1px #dfe3e9', backgroundColor: '#ffffff'}}>
                    <div className="p-3">
                        <div className="row">
                            <div className="col-md-12 col-xl-8 col-lg-9">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="label-info">
                                            {t('plans.startDateInput')}
                                        </div>
                                        <div className="pt-10">
                                            <KirokuInputDate
                                                dateFormat={t('dateTimeFormat.YearMonthDate')}
                                                className={'date-chart-search'}
                                                value={planDateRange.startDate ? moment.unix(planDateRange.startDate) : null}
                                                onChange={(date) => {
                                                    setPlanDateRange({
                                                        ...planDateRange,
                                                        startDate: moment(date).startOf('day').unix(),
                                                    });
                                                }}
                                                inputProps={{disabled: false, readOnly: true}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 padding-top-plan-action">
                                        <div className="label-info">
                                            {t('plans.endDateInput')}
                                        </div>
                                        <div className="pt-10">
                                            <KirokuInputDate
                                                value={planDateRange.endDate ? moment.unix(planDateRange.endDate) : null}
                                                dateFormat={t('dateTimeFormat.YearMonthDate')}
                                                onChange={(date) => {
                                                    let currentStartDate = planDateRange.startDate ? moment(planDateRange.startDate * 1000).unix() : 0;
                                                    if (currentStartDate > moment(date).unix()) {
                                                        setValidateEndDatePlan(false);
                                                    }
                                                    setPlanDateRange({
                                                        ...planDateRange,
                                                        endDate: moment(date).endOf('day').unix(),
                                                    });
                                                }}
                                                inputProps={{disabled: false, readOnly: true}}
                                                className={'date-chart-search'}
                                            />
                                            <p hidden={validateEndDatePlan} style={{
                                                color: 'red',
                                                fontSize: 12,
                                            }}>
                                                {t('CustomerEditor.validate.ErrorStartDateCertification')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{paddingTop: 24}}>
                            <div className="label-info"> {t('plans.assistancePolicy')}</div>
                            <div className="pt-10">
                                <KirokuInputArea
                                    value={overView}
                                    onChange={(e) => {
                                        setOverView(e.target.value);
                                    }}
                                />
                            </div>
                            <p hidden={overView.length <= 1000} style={{
                                color: 'red',
                                fontSize: 14,
                            }}> {t('plans.Please input within 1,000 characters')}</p>
                        </div>
                    </div>
                </div>
                <div style={{borderBottom: 'solid 1px #dfe3e9', backgroundColor: '#ffffff'}}>
                    <div className="p-3">
                        <div className="main-content-info">
                            {t('plans.longTermTarget')}
                        </div>
                        <div className="row" style={{paddingTop: 26}}>
                            <div className="col-md-12 col-xl-8 col-lg-9">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="label-info">
                                            {t('plans.startDate')}
                                        </div>
                                        <div className="pt-10">
                                            <KirokuInputDate
                                                className={'date-chart-search'}
                                                dateFormat={t('dateTimeFormat.YearMonthDate')}
                                                value={longTermTargetDateRange.startDate ? moment.unix(longTermTargetDateRange.startDate) : null}
                                                onChange={(date) => {
                                                    setLongTermTargetDateRange({
                                                        ...longTermTargetDateRange,
                                                        startDate: moment(date).startOf('day').unix(),
                                                    });
                                                }}
                                                inputProps={{disabled: false, readOnly: true}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 padding-top-plan-action">
                                        <div className="label-info">
                                            {t('plans.endDate')}
                                        </div>
                                        <div className="pt-10">
                                            <KirokuInputDate
                                                className={'date-chart-search'}
                                                value={longTermTargetDateRange.endDate ? moment.unix(longTermTargetDateRange.endDate) : null}
                                                dateFormat={t('dateTimeFormat.YearMonthDate')}
                                                onChange={(date) => {
                                                    let currentStartDate = longTermTargetDateRange.startDate ? moment(longTermTargetDateRange.startDate * 1000).unix() : 0;
                                                    if (currentStartDate > moment(date).unix()) {
                                                        setValidateEndDateLongTerm(false);
                                                    }
                                                    setLongTermTargetDateRange({
                                                        ...longTermTargetDateRange,
                                                        endDate: moment(date).endOf('day').unix(),
                                                    });

                                                }}
                                                inputProps={{disabled: false, readOnly: true}}
                                            />
                                            <p hidden={validateEndDateLongTerm} style={{
                                                color: 'red',
                                                fontSize: 12,
                                            }}>
                                                {t('CustomerEditor.validate.ErrorStartDateCertification')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{paddingTop: 24}}>
                            <div className="label-info"> {t('plans.targetTerm')}</div>
                            <div className="pt-10">
                                <KirokuInputArea
                                    defaultValue={longTermTarget}
                                    onChange={(e) => setLongTermTarget(e.target.value)}
                                />
                            </div>
                            <p hidden={longTermTarget.length < 250} style={{
                                color: 'red',
                                fontSize: 14,
                            }}> {t('plans.longTermTargetWithOverTextSize')}</p>
                        </div>
                    </div>
                </div>
                <div style={{borderBottom: 'solid 1px #dfe3e9', backgroundColor: '#ffffff'}}>
                    <div className="p-3">
                        <div className="main-content-info">
                            {t('plans.shortTermTarget')}
                        </div>
                        <div className="row" style={{paddingTop: 26}}>
                            <div className="col-md-12 col-xl-8 col-lg-9">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="label-info">
                                            {t('plans.startDate')}
                                        </div>
                                        <div className="pt-10">
                                            <KirokuInputDate
                                                className={'date-chart-search'}
                                                value={shortTermTargetDateRange.startDate ? moment.unix(shortTermTargetDateRange.startDate) : null}
                                                dateFormat={t('dateTimeFormat.YearMonthDate')}
                                                onChange={(date) => {
                                                    setShortTermTargetDateRange({
                                                        ...shortTermTargetDateRange,
                                                        startDate: moment(date).startOf('day').unix(),
                                                    });
                                                }}
                                                inputProps={{disabled: false, readOnly: true}}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 padding-top-plan-action">
                                        <div className="label-info">
                                            {t('plans.endDate')}
                                        </div>
                                        <div className="pt-10">
                                            <KirokuInputDate
                                                className={'date-chart-search'}
                                                value={shortTermTargetDateRange.endDate ? moment.unix(shortTermTargetDateRange.endDate) : null}
                                                dateFormat={t('dateTimeFormat.YearMonthDate')}
                                                onChange={(date) => {
                                                    let currentStartDate = shortTermTargetDateRange.startDate ? moment(shortTermTargetDateRange.startDate * 1000).unix() : 0;
                                                    if (currentStartDate > moment(date).unix()) {
                                                        setValidateEndDateShortTerm(false);
                                                    }
                                                    setShortTermTargetDateRange({
                                                        ...shortTermTargetDateRange,
                                                        endDate: moment(date).endOf('day').unix(),
                                                    });
                                                }}
                                                inputProps={{disabled: false, readOnly: true}}
                                            />
                                            <p hidden={validateEndDateShortTerm} style={{
                                                color: 'red',
                                                fontSize: 12,
                                            }}>
                                                {t('CustomerEditor.validate.ErrorStartDateCertification')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{paddingTop: 24}}>
                            <div className="label-info"> {t('plans.targetTerm')}</div>
                            <div className="pt-10">
                                <KirokuInputArea
                                    defaultValue={shortTermTarget}
                                    onChange={(e) => setShortTermTarget(e.target.value)}
                                />
                            </div>
                            <p hidden={shortTermTarget.length < 250} style={{
                                color: 'red',
                                fontSize: 14,
                            }}> {t('plans.shortTermTargetWithOverTextSize')}</p>
                        </div>
                    </div>
                </div>
                <div style={{borderBottom: 'solid 1px #dfe3e9', backgroundColor: '#ffffff'}}>
                    <div className="p-3">
                        <div className="main-content-info">
                            {t('plans.offerTime')}
                        </div>
                        <div style={{paddingTop: 24}}>
                            <div className="label-info"> {t('plans.standardHoursOfSupportProvision')}</div>
                            <div className="pt-10">
                                <KirokuInputArea
                                    defaultValue={standardHoursOfSupport}
                                    onChange={(e) => setStandardHoursOfSupport(e.target.value)}
                                />
                            </div>
                            <p hidden={standardHoursOfSupport.length < 250} style={{
                                color: 'red',
                                fontSize: 14,
                            }}> {t('plans.shortTermTargetWithOverTextSize')}</p>
                        </div>
                    </div>
                </div>
                <div className={'p-3 d-flex'}>
                    <KirokuButton
                        color={'light'}
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        {t('plans.cancel')}
                    </KirokuButton>
                    <div className="p-2"/>
                    <KirokuButton
                        onClick={() => {
                            setIsDisabledBtnSubmit(true);
                            let value = {
                                org_name: orgName,
                                division_name: divisionName,
                                customer_name: customerName,
                                customer_id: customerId,
                                overview: overView,
                                short_term_target: shortTermTarget,
                                long_term_target: longTermTarget,
                                long_term_target_start_at: longTermTargetDateRange.startDate,
                                long_term_target_end_at: longTermTargetDateRange.endDate,
                                short_term_target_start_at: shortTermTargetDateRange.startDate,
                                short_term_target_end_at: shortTermTargetDateRange.endDate,
                                start_at: planDateRange.startDate,
                                end_at: planDateRange.endDate,
                                standard_hours_of_support_provision: standardHoursOfSupport
                            };
                            if (message) {
                                notification(t(message), 'success');
                            }
                            handleSubmit(value);
                        }}
                        className={'save-as-draft-btn'}
                        disabled={!customerName || overView.length > 1000 || longTermTarget.length > 250
                            || shortTermTarget.length > 250 || isDisabledBtnSubmit
                            || !validateEndDatePlan || !validateEndDateLongTerm || !validateEndDateShortTerm
                            || !planDateRange.startDate || !planDateRange.endDate || !orgName}>
                        {t('plans.btnSaveDraft')}
                    </KirokuButton>
                </div>
                <ModalDeletePlan show={showModal === 'delete'}
                                 closeModal={() => setShowModal('')}
                                 plan={plan}
                                 searchAfterDelete={() => {
                                     notification(t('message.DeleteSuccess'), 'success');
                                     trackingMixpanel('DELETE_PLAN', plan);
                                     history.push('/plans');
                                 }}/>
            </div>
            <ComponentMobile>
                <div style={{marginBottom: 80}}/>
            </ComponentMobile>
        </div>
    );
}

import React              from 'react';
import {
    KirokuCardWeb, TBody,
    ComponentMobile, KirokuCard, KirokuCardList,
    THeader, ComponentWeb, KirokuLoader,
    KirokuNotFound, SearchNotFound, KirokuTable,
}                         from '../../../ui';
import { useTranslation } from 'react-i18next';

import If       from '../../If';
import { Link } from 'react-router-dom';
import moment   from 'moment';

export function PagNotFound() {
    const { t } = useTranslation();
    return <SearchNotFound
        description={t('NullPage.customerForTag2')}
    />;
}

export default function ServiceMonthComponent({ condition, result, loadingAPI, isGroupCustomer }) {
    const { t } = useTranslation();

    return (<div hidden={(condition.type !== 'services_end_date' && condition.type !== 'customer_services_end_date') || isGroupCustomer}>
        <KirokuCardWeb>
            <ComponentWeb>
                <div className='p-2'>
                    <KirokuLoader loading={loadingAPI}>
                        <KirokuNotFound isResults={result.length}
                                        ComponentSearchNoFound={PagNotFound}
                        >
                            <KirokuTable>
                                <THeader>
                                    <tr>
                                        <th style={{minWidth: 200}}>{t('Customer.Name')}</th>
                                        <th style={{minWidth: 200}}>{t('CustomerEditor.CertificationServiceName')}</th>
                                        <th style={{minWidth: 180}}>{t('CustomerEditor.CertificationServiceStartDate')}</th>
                                        <th style={{minWidth: 180}}>{t('CustomerEditor.CertificationServiceEndDate')}</th>
                                    </tr>
                                </THeader>
                                <TBody>
                                    {result.map((service, index) => {
                                        return <tr key={index} >
                                            <td style={{ wordBreak: 'keep-all' }} >
                                                <If statement={condition.status !== 'deactive'}
                                                    otherwise={service.customer_name}>
                                                    {() => <Link
                                                        to={`/customers/${service.customer_id}`}>{service.customer_name} &nbsp;
                                                    </Link>}
                                                </If>
                                            </td>
                                            <td>
                                                {service.name}
                                            </td>
                                            <td>
                                                {service['start_date'] ? moment(service['start_date']).format(t('dateTimeFormat.YearMonthDateWithDay')) : ''}
                                            </td>
                                            <td>
                                                {moment(service['end_date']).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                            </td>
                                        </tr>;
                                    })}
                                </TBody>
                            </KirokuTable>
                        </KirokuNotFound>
                    </KirokuLoader>
                </div>
            </ComponentWeb>

            <ComponentMobile>
                <KirokuCardList>
                    <KirokuLoader loading={loadingAPI}>
                        <KirokuNotFound isResults={result.length}>
                            <div>
                                {result.map((service, index) => {
                                    return <div key={index}>
                                        <KirokuCard action={''}>
                                            <div style={{ wordBreak: 'keep-all' }}>
                                                <Link
                                                    to={`/customers/${service.customer_id}`}>{service.customer_name} &nbsp;</Link>
                                                <div className={'pt-2'}> {t('CustomerEditor.CertificationServiceName')}: &nbsp;
                                                    {service.name}
                                                </div>
                                                <div className={'pt-2'}>{t('CustomerEditor.CertificationServiceStartDate')}: &nbsp;
                                                    {service['start_date'] ? moment(service['start_date']).format(t('dateTimeFormat.YearMonthDateWithDay')) : ''}
                                                </div>
                                                <div className={'pt-2'}>{t('CustomerEditor.CertificationServiceEndDate')}: &nbsp;
                                                    {moment(service['end_date']).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                                </div>
                                            </div>
                                        </KirokuCard>
                                        <hr className={'margin-none'}/>
                                    </div>;
                                })}
                            </div>
                        </KirokuNotFound>
                    </KirokuLoader>
                </KirokuCardList>
            </ComponentMobile>
        </KirokuCardWeb>
    </div>);
}

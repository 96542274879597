import { KirokuCardTitleWeb } from '../../../ui';
import React                  from 'react';
import { useTranslation }     from 'react-i18next';

export default function QuestionCardTitle({ index, children, isLogic }) {
    const { t } = useTranslation();
    return (
        <KirokuCardTitleWeb color={'card-web'} style={{ paddingBottom: 10, paddingTop: 10 }}>
            <div className='d-flex justify-content-between align-items-center' style={{ flexWrap: 'wrap' }}>
                <div className="question-number">
                    {t('createSurvey.QuestionOrder', { index: index + 1 })}
                </div>
                <label
                       style={{
                           backgroundColor: '#f69931',
                           fontSize: '12px',
                           color: '#FFFFFF',
                           borderRadius: 4,
                           margin: 0,
                           padding: '4px 10px'}}
                       hidden={!isLogic}
                >
                    {t('createSurvey.LogicApplied')}
                </label>
                {children}
            </div>
        </KirokuCardTitleWeb>
    );
}

import React, { useState }   from 'react';
import { Link }              from 'react-router-dom';
import LogLabel              from '../../../Logs/LogLabel';
import moment                from 'moment';
import ModalDifferenceRecord from './ModalDifference';
import { useTranslation }    from 'react-i18next';
import useUser               from '../../../LazyLoad/useUser';
import KirokuLoader          from '../../../../ui/KirokuLoader';

export default function LazyLoadHistory({record, log}) {
    const { t }                                       = useTranslation();
    const { user, loading }                           = useUser(log.user_id);
    const [modalDifference, setIsShowModalDifference] = useState({
        isShow: false,
    });

    return (
        <div>
            <KirokuLoader loading={loading}>
            {user && log ?
                <div hidden={log.firstActionUpdate}>
                    <div className="d-flex pl-3 pr-3 pt-2 border-date-log">
                        <div className="mr-2 pt-10px">
                            <div className='avatar' style={ {
                                width             : 48,
                                height            : 48,
                                borderRadius      : '100%',
                                backgroundImage   : `url(${ user.avatar ? user.avatar : '/assets/img/profile-ic.svg' })`,
                                backgroundSize    : 'cover',
                                backgroundPosition: 'center center',
                            } }
                            />
                        </div>
                        <div style={ { width: '100%' } } className={ 'pl-1 pb-1' }>
                            <div className="d-flex justify-content-between"
                                 style={ { flexWrap: 'wrap' } }>
                                <div className={ 'font-size-title' }>
                                    <Link to={`/users/${user.id}`} style={{ fontSize: 16}}>{ user.username } </Link>
                                </div>
                                <LogLabel log={ log }/>
                            </div>
                            <b className={ log.action === 'update' ? 'text-primary cursor-pointer' : '' }
                               onClick={ () => {
                                   if (log.action === 'update') {
                                       return setIsShowModalDifference({
                                           isShow: true,
                                           logId : log.id,
                                       });
                                   }
                                   return null;
                               } }
                            >
                                <span style={{ fontSize: 14 }}>{ t(`logs.${ log.action }${ log.type }`) }</span>
                            </b>
                            <div
                                className={ 'font-text-time pt-2' } style={{ fontSize: 12 }}>
                                { moment.unix(log.create_at).format(t('dateTimeFormat.HourMinute')) }
                            </div>
                        </div>
                    </div>
                    <ModalDifferenceRecord
                        isShow={ modalDifference.isShow }
                        logId={ modalDifference.logId }
                        closeModal={ () => setIsShowModalDifference({ isShow: false }) }
                        record={ record }
                    />
                </div> : ''}
            </KirokuLoader>
        </div>
    )
}

import React              from 'react';
import { useTranslation } from 'react-i18next';
import { useCondition }   from '../../useCondition';

export default function StatusCondition() {
    const { t }                       = useTranslation();
    const { status, updateRecordCondition, recordCondition }    = useCondition();

    return (
        <div className="d-flex justify-content-around border-btn-status-group">
            <div className={ `border-width-each-status first-btn font-text-on-btn cursor-pointer
                                  ${ status === 'recorded' ? 'btn-active' : '' }` }
                 onClick={ () => updateRecordCondition({...recordCondition, status: 'recorded', page : 1}) }
            >
                { t('RecordsBeta.recorded') }
            </div>
            <div className={ `border-width-each-status-center font-text-on-btn cursor-pointer
                                ${ status === 'draft' ? 'btn-active' : '' }` }
                 onClick={ () =>  updateRecordCondition({...recordCondition, status: 'draft', page : 1})}
            >
                { t('RecordsBeta.draft') }
            </div>
            <div className={ `border-width-each-status third-btn font-text-on-btn cursor-pointer
                                ${ status === 'all' ? 'btn-active' : '' }` }
                 onClick={ () =>  updateRecordCondition({...recordCondition, status: 'all', page : 1}) }
            >
                { t('RecordsBeta.all') }
            </div>
        </div>
    );
}

import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';

export default function InputPassword({ setPassword }) {
    const { t } = useTranslation();


    const [isShowPassword, setShowPassword] = useState(false);

    return (
        <label className="form-input">
            <input type={isShowPassword ? 'text' : 'password'}
                   name="password"
                   autoComplete='password'
                   onChange={(event) => {
                       event.preventDefault();
                       setPassword(event.target.value);
                   }}
            />
            <span className="label">{t('Login.password')}</span>
            <span className='change-status-password'>
                <i onClick={() => setShowPassword(!isShowPassword)}
                   className={isShowPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}
                />
            </span>
            <div className="underline"/>
        </label>
    );
}

import React, { useState }     from 'react';
import PropTypes               from 'prop-types';
import usePersonalInformation  from './usePersonalInformation';
import { useTranslation }      from 'react-i18next';
import {
    ComponentMobile, ComponentWeb, KirokuDropdownClose, KirokuInput, KirokuInputDate, PostalCodeInput,
}                              from '../../../../ui';
import { useBlood }            from './useBlood';
import { useGender }           from './useGender';
import ConfirmOverwriteAddress from './ConfirmOverwriteAddress';
import { useYubinBango }       from '../../MakeCustomer/useYubinBango';
import moment                  from 'moment';

function Name() {

    const { t } = useTranslation();
    const { name, setPersonalInformation } = usePersonalInformation();
    const [ inputName, setInputName ] = useState(name);
    const [ validate, setValidation ] = useState(true);

    return (
        <>
            <label className="mt-1 mb-10">{t('CustomerEditor.Name')}</label>
            <KirokuInput
                name='name'
                placeholder={t('common.Name')}
                onChange={e => setInputName(e.target.value)}
                onBlur={e => {
                    setPersonalInformation(e.target.name, e.target.value)
                    setValidation(!!e.target.value);
                }}
                value={inputName || ''}
                className={!validate ? 'form-error' : ''}
            />
            <p hidden={validate} style={{
                color: 'red',
                fontSize: 12
            }}>{t('CustomerEditor.validate.Name')}</p>
        </>
    )
}

function DateOfBirth() {

    const { date_of_birth, setPersonalInformation } = usePersonalInformation();
    const { t } = useTranslation();
    const [ validate, setValidation ] = useState(true);

    const onChangeDate = (value) => {
        if (moment(value, t('dateTimeFormat.YearMonthDate'), true).isValid()) {
            setValidation(true)
            setPersonalInformation('date_of_birth', moment(value).unix())
        }
        else if (value === "") {
            setValidation(true)
            setPersonalInformation('date_of_birth', null)
        }
        else {
            setValidation(false)
            setPersonalInformation('date_of_birth', false)
        }

    }

    return (
        <>
            <label className='mt-1 mb-10'>{t('CustomerEditor.DateOfBirth')}</label>
            <KirokuInputDate
                inputProps={{style: {height: 35}}}
                name='date_of_birth'
                onChange={onChangeDate}
                dateFormat={t('dateTimeFormat.YearMonthDate')}
                defaultValue={date_of_birth ? moment(date_of_birth * 1000) : ""}
            />
            <p hidden={validate} style={{
                color: 'red',
                fontSize: 12
            }}>{t('CustomerEditor.validate.ValidDate')}</p>
        </>
    )
}

function BloodType() {

    const { blood_type, setPersonalInformation, } = usePersonalInformation();
    const [ bloodList ] = useBlood();
    const { t } = useTranslation();

    return (
        <>
            <label className='mt-1 mb-10'>{t('CustomerEditor.BloodType')}</label>
            <KirokuDropdownClose
                title={(blood_type === 'Unknown' ? t('CustomerEditor.Unknown') : blood_type) || t('CustomerEditor.Unknown')}
                items={bloodList}
                onSelect={e => {
                    const blood = e.kirokuSelected.label === "不明" ? 'Unknown' : e.kirokuSelected.label;
                    setPersonalInformation('blood_type', blood)
                }}
            />
        </>
    )
}

function Characteristic() {

    const { characteristic, setPersonalInformation, } = usePersonalInformation();
    const { t } = useTranslation();
    const [ inputCharacteristic, setCharacteristic ] = useState(characteristic)

    return (
        <>
            <label className='mt-1 mb-10'>{t('CustomerEditor.Characteristic')}</label>
            <KirokuInput
                name='characteristic'
                placeholder={t('common.Characteristic')}
                value={inputCharacteristic || ''}
                onChange={e => {
                    setCharacteristic(e.target.value)
                }}
                onBlur={e => setPersonalInformation(e.target.name, e.target.value)}
            />
        </>
    )
}

function PostCode() {

    const { address, setPersonalInformation, post_code } = usePersonalInformation();
    const { newAddress, isShowing, setNewAddress, setIsShowing } = useYubinBango();
    const { t } = useTranslation();

    return (
        <>
            <div className="d-flex" style={{ justifyContent: "space-between", flexWrap: "wrap" }}>
                <label className='mt-1 mb-10'>{t('CustomerEditor.PostCode')}</label>
                <label className={'cursor-pointer mt-1'}
                       style={{ color: '#1891eb' }}
                       onClick={setNewAddress}>
                    {t('CustomerEditor.InsertAddr')}
                </label>
            </div>
            <PostalCodeInput
                initial={post_code}
                onPostalCodeChange={value => setPersonalInformation('post_code', value)}
            />

            <ConfirmOverwriteAddress
                show={isShowing}
                oncancel={() => setIsShowing(isShowing => !isShowing)}
                address={{
                    old: address,
                    new: newAddress
                }}
                onsubmit={() => {
                    setPersonalInformation('address', newAddress);
                    setIsShowing(isShowing => !isShowing)
                }}
            />
        </>
    )
}

function Address() {
    const { address, setPersonalInformation } = usePersonalInformation();
    const { t } = useTranslation();

    return (
        <>
            <label className='mt-1 mb-10'>{t('CustomerEditor.Address')}</label>
            <KirokuInput
                name='address'
                placeholder={t('common.Address')}
                value={address || ''}
                onChange={e => setPersonalInformation(e.target.name, e.target.value)}
            />
        </>
    );
}

function CustomerGender() {

    const {
        gender,
        setPersonalInformation,
    } = usePersonalInformation();
    const { t } = useTranslation();
    const [ genderList ] = useGender();

    return (
        <>
            <label className='mt-1 mb-10'>{t('CustomerEditor.CustomerGender')}</label>
            <KirokuDropdownClose
                title={t('CustomerEditor.' + gender)}
                items={genderList}
                onSelect={e => {
                    const gender = e.kirokuSelected.label === "未選択" ? 'unselected' : e.kirokuSelected.value;
                    setPersonalInformation('gender', gender)
                }}
            />
        </>
    )
}

function Furigana() {
    const { t }                                = useTranslation();
    const { furigana, setPersonalInformation } = usePersonalInformation();
    const [furiganaText, setFurigana]          = useState(furigana);
    const [validate, setValidate]          = useState(false);

    return (
        <>
            <label className='mt-1 mb-10'>{t('Customer.Furigana')}</label>
            <KirokuInput
                name={'furigana'}
                value={ furiganaText || ''}
                onChange={(e) => {
                    setFurigana(e.target.value);
                } }
                onBlur={(e) => {
                    let isHiraganaText = e.target.value && !e.target.value.match(/^[ぁ-んーゔ\s]+$/g);
                    setValidate(isHiraganaText);
                    setPersonalInformation(e.target.name, e.target.value);
                }}
            />
            <p hidden={!validate} style={{
                color: 'red',
                fontSize: 12
            }}>{t('CustomerEditor.validate.ErrorFuriganaCharacter')}</p>
        </>
    )
}

export default function PersonalInformation() {

    const { t } = useTranslation();
    return (
        <div>
            <h5 className={'card-title-info'}>{t('CustomerEditor.PersonalInformation')}</h5>
            <ComponentWeb>
                <div className="row">
                    <div className="col-md-6">
                        <Name/>
                        <div className="pt-15px">
                            <Furigana/>
                        </div>
                        <div className={'pt-15px'}>
                            <DateOfBirth/>
                        </div>
                        <div className={'pt-15px'}>
                            <BloodType/>
                        </div>
                    </div>
                    <div className="col-md-6">
                            <PostCode/>
                        <div className={'pt-15px'}>
                            <Address/>
                        </div>
                        <div className={'pt-15px'}>
                            <CustomerGender/>
                        </div>
                        <div className={'pt-15px'}>
                            <Characteristic/>
                        </div>
                    </div>
                </div>
            </ComponentWeb>
            <ComponentMobile>
                <Name/>
                <div className="pt-15px"/>
                <Furigana/>
                <div className="pt-15px"/>
                <DateOfBirth/>
                <div className="pt-15px"/>
                <CustomerGender/>
                <div className="pt-15px"/>
                <BloodType/>
                <div className="pt-15px"/>
                <PostCode/>
                <div className="pt-15px"/>
                <Address/>
                <div className="pt-15px"/>
                <Characteristic/>
            </ComponentMobile>
        </div>
    );
}

PersonalInformation.propTypes = {
    customer: PropTypes.object,
    errors: PropTypes.object,
    onChange: PropTypes.func
}

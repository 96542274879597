export const HANDLE_SELECT_CUSTOMER = 'HANDLE_SELECT_CUSTOMER';
export const HANDLE_SELECT_SURVEY   = 'HANDLE_SELECT_SURVEY';
export const HANDLE_SELECT_DATE     = 'HANDLE_SELECT_DATE';
export const HANDLE_SELECT_USER     = 'HANDLE_SELECT_USER';

export default function (state, action) {
    switch (action.type) {
        case HANDLE_SELECT_DATE:
            return {
                ...state,
                ...action.date,
                updaters: action.updaters,
                creators: action.creators,
            };
        case HANDLE_SELECT_SURVEY:
            return {
                ...state,
                survey: action.survey,
                updaters: action.updaters,
                creators: action.creators,
            };
        case HANDLE_SELECT_CUSTOMER:
            return {
                ...state,
                customers: action.customers,
                survey  : {
                    id   : null,
                    title: '',
                },
            };
        case HANDLE_SELECT_USER:
            return {
              ...state, ...action
            };
        default :
            return state;
    }
}

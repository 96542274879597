import React               from 'react';
import * as moment         from 'moment';
import useHeadingQuestion  from '../../Records/useSurvey/useHeadingQuestion';
import { KirokuInputIcon } from '../../../ui';

export default function DateTimeQuestion(props) {

    const { question, value = null } = props;

    const { t, heading } = useHeadingQuestion(question);

    return (<div className={'content-question-type'}>
        <div className='wraper-question none-border-question pt-2 pt-2'>
            <div className="background-question">
                {heading && <div className="text-overflow-elip font-heading-questions pb-10px">{ heading }</div> }
                <div className='padding-content-question'>
                    <span className={'font-content-question'}>
                        {question.unrequited ? t('createRecord.option'):''}{question.content}
                    </span>
                </div>
            </div>
            <div className='answer-question padding-16-answer-question'>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <KirokuInputIcon readOnly name={'date'} icon={'far fa-calendar-alt'}
                                         style={{
                                             height       : '35px',
                                             paddingLeft  : '2rem',
                                             color        : '#0a1d42',
                                             pointerEvents: 'none',
                                         }}
                                         value={value ? moment.unix(value).format(t('dateTimeFormat.YearMonthDate')) : ''}
                        />
                        <div className={'mb-2 mt-2'}/>
                        <KirokuInputIcon readOnly name={'clock'} icon={'far fa-clock'}
                                         style={{
                                             height       : '35px',
                                             paddingLeft  : '2rem',
                                             color        : '#0a1d42',
                                             pointerEvents: 'none',
                                         }}
                                         value={value ? moment.unix(value).format(t('dateTimeFormat.HourMinute')) : ''}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

import React                                       from 'react';
import { KirokuSortableContainer, SearchNotFound } from '../../../ui';
import { useTranslation }                          from 'react-i18next';
import If                                          from '../../If';
import KirokuLoader                                from '../../../ui/KirokuLoader';
import { SortableElement, sortableHandle }         from 'react-sortable-hoc';
import { useHistory }                              from 'react-router-dom';

const SortableHandlePoint = sortableHandle(() => <span><img style={{cursor: 'move'}}
                                                            src={`/assets/img/move-point.svg`}
                                                            alt="move-point.svg"/></span>);

function SupportItem({ support, stt, t }) {
    const history = useHistory();

    return <div className="d-flex p-3"
                style={{ background: stt % 2 !== 0 ? 'rgba(197, 208, 222, 0.1)' : 'none' }}
    >
        <div
            className={'column-priority cursor-pointer'} onClick={() => {
                history.push(`/supports/${ support.id }`);
        }}>
            <span className="ellipse d-flex justify-content-center align-items-center"
            >
                {support.priority}
            </span>
        </div>
        <div className={'column-needs font-content-table cursor-pointer break-word-and-pre-wrap'}
             onClick={() => {
                 history.push(`/supports/${ support.id }`);
             }}
        >{support.needs}</div>
        <div className={ 'column-object cursor-pointer' } onClick={() => {
            history.push(`/supports/${ support.id }`);
        }}>{ support.target }</div>
        <div className={'column-overview font-content-table cursor-pointer break-word-and-pre-wrap'}
             onClick={() => {
                 history.push(`/supports/${ support.id }`);
             }}
        >{support.description}</div>
        <div className={'column-term font-content-table cursor-pointer'}
             onClick={() => {
                 history.push(`/supports/${ support.id }`);
             }}
        >{support.term}{t('plans.monthLater')}</div>
        <div className={'column-status font-content-table cursor-pointer'}
             onClick={() => {
                 history.push(`/supports/${ support.id }`);
             }}
        >
            {support.review ?
            <div className="font-content-table d-flex align-items-center" >
                {support.review.is_draft ?
                    <div className="d-flex align-items-center">
                        <img src={ `/assets/icon/iconDraft.svg` } alt="iconDraft.svg"
                             style={ { width: 18, height: 16 } }
                        /> &nbsp;<span
                        className={ 'font-size-14' }>{ t('plans.draft') }</span>
                    </div> :
                    <div>
                        <img src={`/assets/img/reviewd.svg`} alt="reviewd.svg"/>
                        &nbsp;{t('plans.monitoredStatus')}
                    </div>
                }
            </div>
                : ''}
        </div>
        <div className={'column-action-move'}><SortableHandlePoint/></div>
    </div>;
}

export default function SupportTable({ plan, result, loading, handlePriority }) {
    const { t }     = useTranslation();
    const onSortEnd = ({ oldIndex, newIndex }) => {
        handlePriority(oldIndex, newIndex, result)
    };

    return (
        <div>
            <KirokuLoader loading={loading}>
                <If statement={result.length}
                    otherwise={<SearchNotFound description={' '} message={t('plans.NoResult')}/>}
                >
                    {() => <KirokuSortableContainer
                        onSortEnd={onSortEnd}
                        helperClass={'pointer-events-auto'}
                        useDragHandle
                    >
                        <div className='card'>
                            <div style={{ overflowX: 'scroll' }}>
                                <div className={'max-width-table-record-beta'}>
                                    <div className="d-flex p-3 font-header-support-table" style={{ borderBottom: '1px solid #dfe2e5' }}>
                                        <div className={'column-priority'}>{t('plans.priority')}</div>
                                        <div className={'column-needs'}>{t('plans.customerNeeds')}</div>
                                        <div className={'column-object'}>{t('plans.objective')}</div>
                                        <div className={'column-overview'}>{t('plans.content')}</div>
                                        <div className={'column-term'}>{t('plans.achievementTime')}</div>
                                        <div className={'column-status'}>{t('plans.statusReview')}</div>
                                        <div className={'column-action-move'}>&nbsp;</div>
                                    </div>

                                    {result.map((support, index) => {
                                        const SortableElementItemSupport = SortableElement(SupportItem);
                                        return <SortableElementItemSupport key={index} support={support} index={index}
                                                                           stt={index}
                                                                           plan={plan} t={t}/>;
                                    })}
                                </div>
                            </div>
                        </div>
                    </KirokuSortableContainer>
                    }
                </If>
            </KirokuLoader>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { resources }                  from '@kiroku/api';
import PlanningAction                 from '../PlanningAction';
import If                             from '../../If';
import { KirokuLoader, KirokuModal, SearchNotFound,
    KirokuModalBody, KirokuModalFooter, KirokuButton
}                                     from '../../../ui';
import { useHistory }                 from 'react-router-dom';
import { useTranslation }             from 'react-i18next';
import useNotification                from '../../Layout/useNotification';
import { useMixpanelTracking }        from '../../../Mixpanel/useMixpanelTracking';

function useGetSupportPlan(plan_id) {
    const { search, result, cancelAPISearch } = resources.useSearch('Support');

    useEffect(() => {
        if (plan_id) {
            search({
                type  : 'plan',
                planId: plan_id,
            });
        }
        return () => {
            cancelAPISearch();
        };
    }, [search, plan_id, cancelAPISearch]);

    return { supports: result };
}

export default function PlanningDuplicate(props) {
    const [plan, loadDetail, loadingAPI]                    = resources.useDetail('Plan');
    const history                                           = useHistory();
    const create                                            = resources.useCreate('Plan')[1];
    const { supports }                                      = useGetSupportPlan(props.match.params.id);
    const [modalConfirmDuplicate, setModalConfirmDuplicate] = useState(false);
    const [planData, setPlanData]                           = useState(null);
    const { notification }                                  = useNotification();
    const { trackingMixpanel }                              = useMixpanelTracking();


    useEffect(() => {
        loadDetail(props.match.params.id).catch(error => console.log(error));
    }, [loadDetail, props.match.params.id]);


    const handleSubmit = (value) => {
        setModalConfirmDuplicate(!modalConfirmDuplicate);
        setPlanData(value);
    }
    const { t } = useTranslation();

    return (<>
            <KirokuModal show={ modalConfirmDuplicate } onCloseModal={ () => setModalConfirmDuplicate(!modalConfirmDuplicate) }>
                <div>
                    <button className='close' style={{ float: 'right', marginRight: 10, marginTop: 10 }}
                            onClick={() => setModalConfirmDuplicate(!modalConfirmDuplicate) }>
                                <span style={{
                                    fontSize: '24px',
                                    color   : '#a8aab7',
                                }}>&times;</span>
                    </button>
                </div>
                <KirokuModalBody>
                    <div className="p-3 font-size-14" >
                        {t('plans.Would you like to copy also Supports?')}
                        <div style={{color: '#7f8fa4'}}>
                            ( {t('plans.Template will automatically assigned')} )
                        </div>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton
                        style={{padding: '5px 16px'}}
                        className={'btn btn-kiroku-success font-size-14'}
                        onClick={() => {
                            const newPlan = {
                                ...planData,
                                supports: supports.map(support => {
                                    return {
                                        description: support.description,
                                        needs: support.needs,
                                        priority: support.priority,
                                        target: support.target,
                                        term: support.term,
                                        surveys: support.surveys,
                                    }
                                })
                            };
                            create(newPlan).then(resultAfterCreatePlan => {
                                history.push(`/plans/${resultAfterCreatePlan.id}`);
                                notification(t('message.UpdateSuccess'), 'success');
                                trackingMixpanel('DUPLICATE_PLAN', newPlan);
                            })
                        }}
                    >
                        {t('plans.Duplicate supports')}
                    </KirokuButton>
                    <KirokuButton
                        style={{padding: '5px 16px'}}
                        className={'btn btn-kiroku-primary font-size-14'}
                        onClick={() => {
                            create({
                                ...planData,
                            }).then(resultAfterCreatePlan => {
                                history.push(`/plans/${resultAfterCreatePlan.id}`);
                                notification(t('message.UpdateSuccess'), 'success');
                                trackingMixpanel('DUPLICATE_PLAN', planData);
                            })
                        }}
                    >
                        {t('plans.Duplicate Only Plan')}
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>

        <KirokuLoader loading={loadingAPI}>
            <If statement={plan}
                otherwise={ <SearchNotFound message={ t('common.PlanInvalid') } description={ ' ' }/> }
            >{() =>
                <PlanningAction
                    disabledButtonSubmit={modalConfirmDuplicate}
                    isDuplicatePlanScreen
                    handleSubmit={handleSubmit}
                    loadingAPI={loadingAPI}
                    plan={plan || {}}
                />}
            </If>
        </KirokuLoader>
        </>
    );
}

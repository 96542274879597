import React                     from 'react';
import { KirokuSectionDropdown } from '../../../../ui';
import { useTranslation }        from 'react-i18next';
import useTagCustomers           from './useTagCustomers';
// import _                         from 'lodash';

export default function TagTeam({ teams }) {

    const { t }                                               = useTranslation();
    const { currentTeam, getGroupedOptions, setTagCustomers } = useTagCustomers();

    const optionsTeam = getGroupedOptions('Team', teams);

    const handleChangeTags = (options) => {
        setTagCustomers('teams', options.map(option => ({
            id  : option.id,
            name: option.name,
        })));
    };

    const handleChangeAllTags = () => {
        setTagCustomers('teams', optionsTeam.map(option => ({
            id  : option.id,
            name: option.name,
        })));
    };

    return (
        <>
            <div className="d-flex">
                <label className="mt-2 mb-10">{ t('common.team') }</label>
                <div className="p-2"/>
                {!!(optionsTeam && optionsTeam.length) && (
                <div className={ 'cursor-pointer mt-2' } style={ { color: '#1891eb' } } onClick={ handleChangeAllTags }>
                    { t('Customer.TagAll') }
                </div>
                )}

            </div>
            <KirokuSectionDropdown
                onChange={ handleChangeTags }
                value={ currentTeam }
                options={ [{
                    label  : 'Teams',
                    options: optionsTeam,
                }] }
            />
        </>
    );
};

import React from 'react';
import {
    ComponentMobile,
    ComponentWeb,
    KirokuCard,
    KirokuCardList,
    KirokuCardWeb,
    KirokuLoader,
    KirokuNotFound,
} from "../../../ui";
import {PagNotFound} from "./ServiceMonthComponent";
import {Link} from "react-router-dom";
import moment from "moment";
import {useTranslation} from "react-i18next";

function getCustomerServiceEndDateOnMonthSelected(services, monthSelected) {
    const monthSelectedString =  moment.unix(monthSelected).format('M/YYYY')
    return services.filter((service) => {
        const serviceMonth = moment.unix(service.endDate).format('M/YYYY')
        return serviceMonth === monthSelectedString
    })
}

export default function ServiceEndDateGroupCustomer({condition, isGroupCustomer, result, loadingAPI}) {

    const {t} = useTranslation();
    return <div hidden={(condition.type !== 'customer_services_end_date' && condition.type !== 'customer_services_end_date') || !isGroupCustomer}>
        <KirokuCardWeb>
            <ComponentWeb>
                <KirokuLoader loading={loadingAPI}>
                    <KirokuNotFound isResults={result.length}
                                    ComponentSearchNoFound={PagNotFound}
                    >
                        <div className={'customer-service-header'}>
                            <div className={'csc-sn csc-header'}>{t('CustomerEditor.CertificationServiceName')}</div>
                            <div className={'csc-sd csc-header'}>{t('CustomerEditor.CertificationServiceStartDate')}</div>
                            <div className={'csc-ed csc-header'}>{t('CustomerEditor.CertificationServiceEndDate')}</div>
                        </div>

                        {result.map(({id : customerId, certifications, name}, index) => {
                            const services = getCustomerServiceEndDateOnMonthSelected(certifications[0].services, condition.customer_services_end_date);
                            return <div key={index}>
                                <div className={'csc-cn'}>
                                    <Link to={`/customers/${customerId}`}>
                                    {name}<span style={{ fontSize: 12 }}>{t('RecordsBeta.noun')}</span>
                                    </Link>
                                </div>
                                {services.map((service, i) => {
                                    return <div className={'customer-service-row'} key={i}>
                                        <div className={'csc-sn'}>{service.name}</div>
                                        <div className={'csc-sd'}>{service['startDate'] ? moment.unix(service['startDate']).format(t('dateTimeFormat.YearMonthDateWithDay')) : ''}</div>
                                        <div className={'csc-ed'}>{moment.unix(service['endDate']).format(t('dateTimeFormat.YearMonthDateWithDay'))}</div>
                                    </div>
                                })}
                            </div>
                        })}
                    </KirokuNotFound>
                </KirokuLoader>
            </ComponentWeb>
            <ComponentMobile>
                <KirokuCardList>
                    <KirokuLoader loading={loadingAPI}>
                        <KirokuNotFound isResults={result.length}>
                            <div>
                                {result.map(({id : customerId, certifications, name}) => {
                                    const services = getCustomerServiceEndDateOnMonthSelected(certifications[0].services, condition.customer_services_end_date);
                                    return <div key={customerId}>
                                        <KirokuCard>
                                            <Link to={`/customers/${customerId}`}>
                                                {name}
                                                <span style={{ fontSize: 12 }}> {t('RecordsBeta.noun')} </span>
                                            </Link>
                                            <ul style={{paddingLeft: 30}}>
                                                {services.map((service, index) => {
                                                    return <li key={index} className={'pt-2'}>
                                                        <div className={'customer-info'}>
                                                            {t('CustomerEditor.CertificationServiceName')}:
                                                            <span className='personal-info'>
                                                                &nbsp;&nbsp;{service.name}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className={'customer-info'}>{t('CustomerEditor.CertificationServiceStartDate')}:
                                                            <span className='personal-info'>&nbsp;&nbsp;
                                                                {service['startDate'] ? moment.unix(service['startDate']).format(t('dateTimeFormat.YearMonthDateWithDay')) : ''}
                                                                </span>
                                                        </div>
                                                        <div
                                                            className={'customer-info'}>{t('CustomerEditor.CertificationServiceEndDate')}:
                                                            <span className='personal-info'>&nbsp;&nbsp;
                                                                {moment.unix(service['endDate']).format(t('dateTimeFormat.YearMonthDateWithDay'))}
                                                            </span>
                                                        </div>
                                                    </li>
                                                })}
                                            </ul>
                                        </KirokuCard>
                                        <hr className={'margin-none'}/>
                                    </div>
                                })}
                            </div>
                        </KirokuNotFound>
                    </KirokuLoader>
                </KirokuCardList>
            </ComponentMobile>

        </KirokuCardWeb>
    </div>
}

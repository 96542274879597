import React, { useEffect } from "react";
import { useTranslation }   from "react-i18next";
import TagStaffs            from "./TagStaffs";
import TagTeam              from "./TagTeam";
import { resources }        from "@kiroku/api";

export default function TagCustomer() {

    const { t } = useTranslation();
    const { result, search } = resources.useSearch('Team');

    useEffect(() => {
        search({ type: 'all' });
    }, [ search ]);

    return (
        <div>
            <h5 className="card-title-info">{t('common.tag')}</h5>
            <div className="row">
                <br/>
                <div className="col-md-12">
                    <TagStaffs teams={result}/>
                    <div className="pt-15px"/>
                    <TagTeam teams={result}/>
                </div>
            </div>
        </div>
    )
}

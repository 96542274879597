import React                                              from 'react';
import { useTranslation }                                 from 'react-i18next';
import useGuardianship                                    from './useGuardianship';
import { DateDiff, GeneralDiff, GeneralDiffKeyTranslate } from '../DifferenceType';
import If                                                 from '../../../../If';

const DiffComponents = {
    guardianship_type     : GeneralDiffKeyTranslate,

    decision_date         : DateDiff,
    appointed_type        : GeneralDiff,
    competent_court       : GeneralDiff,

    case_number           : GeneralDiff,
    registration_number   : GeneralDiff,
    permanent_address     : GeneralDiff,

    residence_card_address: GeneralDiff,
    facility_name         : GeneralDiff,
    report_month          : GeneralDiff,


};
export default function Guardianship({ log, old }) {
    const { t }     = useTranslation();
    const { diffs } = useGuardianship(old.data, log.data);

    return <div className={'p-2'}>
        <div className={'font-size-16 pb-10px'}>{t('customerDifference.guardianship')}</div>
        <If statement={diffs}
            otherwise={<div className={'text-center'}>
                <div style={{ fontSize: 24 }} className={'pb-10px'}>{t('customerDifference.notChange')}</div>
            </div>}
        >
            {() => diffs.map((diff, index) => {
                const DiffComponent = DiffComponents[ diff.path[ 0 ] ];
                return diff.kind === 'E' && <div
                    key={index}
                    className="d-flex align-items-center"
                >
                    <b>{t(`customerDifference.${diff.path[ 0 ]}`)}: </b> &nbsp;
                    {DiffComponent && <DiffComponent lhs={diff.lhs} rhs={diff.rhs}/>}
                </div>;
            })}
        </If>
    </div>;
}

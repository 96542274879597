import React, { useEffect, useState }                        from 'react';
import usePageTitle                                          from '../../ui/v3/hooks/usePageTitle';
import './log.css';
import { KirokuCardWeb, KirokuInputSearcher, KirokuLoading } from '../../ui';
import { useTranslation }                                    from 'react-i18next';
import config                                                from '../../config';
import useSearchLogs                                         from './useSearchLogs';
import moment                                                from 'moment';
import KirokuLoader                                          from '../../ui/KirokuLoader';
import SearchNotFound                                        from '../../ui/SearchNotFound';
import LoadingComponent
                                                             from '../Dashboard/HelperComponentLoading/LoadingComponent';
import ConditionSearchActionLog                              from './ConditionSearchActionLog';
import LazyLoadAvatar                                        from './LazyLoadAvatar';

export default function Logs() {
    const { t }                 = useTranslation();
    const [keyword, setKeyword] = useState('');

    usePageTitle(t(`Layout.Logs`));

    const {
              resultSearch, loggingSearcher,
              loadingAPI, pagination, loadingPagination,
              paginationLogs, condition, hasNext
          } = useSearchLogs();

    function isScrolling() {
        if (window.innerWidth < config.configWithMobile) {
            if ((Math.ceil(document.documentElement.scrollTop) >= document.documentElement.offsetHeight - (window.innerHeight + 100))
                && (hasNext && !loadingPagination)
            ) {
                paginationLogs();
            }
        } else {
            if ((window.innerHeight + Math.ceil(document.documentElement.scrollTop) + 10 >= document.documentElement.offsetHeight)
                && (hasNext && !loadingPagination)) {
                paginationLogs();
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', isScrolling);
        return () => window.removeEventListener('scroll', isScrolling);
    });

    useEffect(() => {
        if (condition.keyword) {
            setKeyword(condition.keyword);
        }
    }, [condition]);

    return (
        <div className='padding-responsive'>
            <div id={ 'page-log' }>
                <div className="bg-white" style={ { minHeight: 66 } }>
                    <div className="d-flex justify-content-between align-items-center p-3" style={{flexWrap: 'wrap'}}>
                        <div className={'pt-2'}>
                            <ConditionSearchActionLog search={loggingSearcher} action={condition.action}/>
                        </div>
                        <div className={'p-2'}/>
                        <div className={'pt-2'}>
                            <KirokuInputSearcher
                                value={ keyword }
                                onSubmit={ () => {
                                    loggingSearcher({
                                        page   : 1,
                                        keyword: keyword,
                                    });
                                } }
                                onChange={ e => setKeyword(e.target.value) }
                                id={ 'inputSearchLogsWeb' }
                            />
                        </div>
                    </div>
                </div>
                <KirokuCardWeb>
                    <KirokuLoader loading={ loadingAPI } ComponentLoading={LoadingComponent}>
                        { !resultSearch.length ? <SearchNotFound/> : <div>
                            { resultSearch.map((result, index) =>
                                <div key={ index }>
                                    <div style={ { height: 50 } }
                                         className={ 'd-flex justify-content-center align-items-center font-text-date-log border-date-log background-color-date ' }>
                                        { moment.unix(result.date).format(t('dateTimeFormat.YearMonthDateWithDay')) }
                                    </div>
                                    { result.logs.map((log, indexLogs) =>
                                        <div key={ indexLogs }>
                                            <LazyLoadAvatar log={ log }/>
                                        </div>
                                        ) }
                                </div>,
                            ) }
                        </div> }
                    </KirokuLoader>
                </KirokuCardWeb>
                <div hidden={loadingAPI || !resultSearch.length || pagination.currentPage === pagination.totalPage}>
                    { !loadingPagination && <KirokuLoading/> }
                </div>
            </div>
        </div>
    );
}

import { Component }      from 'react';
import React              from 'react';
import KirokuDropdownItem from './KirokuDropdownItem';

export default class BulkAction extends Component {

    handleSelected(event) {
        this.props.onSelect(event);
        this.setState({
            popupVisible: false,
        });
    }

    constructor() {
        super();

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false,
        };
    }


    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    render() {
        const { popupVisible } = this.state;
        const {
                  title, className, hidden,
                  disabled, style, items, location,
              }                          = this.props;
        return (
            <div ref={node => {
                this.node = node;
            }}>
                <div className={`dropdown ${className ? className : ''}`}>
                    <button hidden={hidden}
                            disabled={disabled}
                            style={style}
                            className={`overflow btn btn-kiroku ${className ? className : ''}`}
                            onClick={this.handleClick.bind(this)}>
                        <span className={'text-kiroku left'}>{title}</span>
                        { location === 'top' ? <i className={`${!popupVisible ? 'fa fa-chevron-up icon-kiroku' : 'fa fa-chevron-down icon-kiroku'}`}/>:
                            <i className={`${!popupVisible ? 'fa fa-chevron-down icon-kiroku' : 'fa fa-chevron-up icon-kiroku'}`}/>
                        }
                    </button>
                    {popupVisible && (
                        <div
                            className={`dropdown-kiroku dropdown-menu show ${location ? location : ''}`}
                            style={{ position: 'absolute', willChange: 'top, left', left: 'auto', top: 'auto' }}>
                            <section className='scroll-data-dropdown-search'>
                                {items.map((item, index) => {
                                    const Item = item.component;
                                    return (
                                        <KirokuDropdownItem key={index} onClick={(event) => {
                                            event.kirokuSelected = item;
                                            this.handleClick(event);
                                            this.handleSelected(event);
                                        }}>
                                            {Item}
                                        </KirokuDropdownItem>
                                    );
                                })}
                            </section>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}


import React, { useEffect }   from 'react';
import { resources }          from '@kiroku/api';
import {
    KirokuCard, KirokuCardList, KirokuButton,
    KirokuLoader, KirokuPagination, KirokuCardWeb
}                             from '../../../ui';
import If                     from '../../If';
import { useTranslation }     from 'react-i18next';
import { Link }               from 'react-router-dom';
import TabResult              from './TabResult';
import { WrapperPermissions } from '../../HOC';
import { useHistory }         from 'react-router-dom';


export default function TabTeamsComponent({ isShow, userId }) {
    const { t } = useTranslation();
    const history = useHistory();
    const {
              result, search,
              loading, pagination, nextPage, prevPage,
          }     = resources.useSearch('Team', { type: 'condition', userId: userId, page: 1 });

    useEffect(() => {
        if (userId && isShow) {
            search({ type: 'condition', userId, page: 1 });
        }
    }, [userId, isShow, search]);

    return (
        <div hidden={!isShow}>
            <KirokuLoader loading={loading}>
                <If statement={result.length}
                    otherwise={<KirokuCardWeb>
                        <TabResult messageFirst={'team1'} messageSecond={'team2'}
                        >
                            <img className='pt-2 pb-2'
                                 src={`/assets/img/team-icon@2x.png`}
                                 alt="no team"/>
                        </TabResult>
                        <WrapperPermissions
                            resource-permission={'|staffDetail|button-editProfile|'}
                            className={'pb-3'}
                        >
                            <div className='text-center'>
                                <KirokuButton onClick={() => history.push(`/users/edit/${userId}`)} color={'primary'}>
                                    {t('common.EditProfile')}
                                </KirokuButton>
                            </div>
                        </WrapperPermissions>
                    </KirokuCardWeb>}
                >
                    {() => {
                        return <div>
                            <div className="d-flex justify-content-between">
                                <div/>
                                <KirokuPagination
                                    className={'pb-3'}
                                    disabledPrev={pagination.currentPage === 1}
                                    disabledNext={pagination.currentPage === pagination.totalPage || pagination.totalPage === 0}
                                    increasePage={nextPage}
                                    decreasePage={prevPage}
                                    currentPage={pagination.currentPage}
                                    totalPage={pagination.totalPage}
                                />
                            </div>
                            <KirokuCardList
                                headerLeft={<span>{t('common.Name')}</span>}
                                headerRight={<span/>}
                            >
                                {result.map((team, index) =>
                                    <div style={{ backgroundColor: index % 2 !== 0 ? 'rgba(197,208,222,.1)' : '' }}
                                         key={index}
                                    >
                                        <KirokuCard action={''}>
                                            <Link to={`/teams/${team.id}`}>{team.name}&nbsp;&nbsp;</Link>
                                        </KirokuCard>
                                    </div>,
                                )}
                            </KirokuCardList>
                            <div className="d-flex justify-content-between">
                                <div/>
                                <KirokuPagination
                                    className={'pt-3'}
                                    disabledPrev={pagination.currentPage === 1}
                                    disabledNext={pagination.currentPage === pagination.totalPage || pagination.totalPage === 0}
                                    increasePage={nextPage}
                                    decreasePage={prevPage}
                                    currentPage={pagination.currentPage}
                                    totalPage={pagination.totalPage}
                                />
                            </div>
                        </div>;
                    }}
                </If>
            </KirokuLoader>
        </div>
    );
}

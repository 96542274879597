import React              from 'react';
import { useTranslation } from 'react-i18next';
import {
    KirokuButtonLink,
    KirokuButton, KirokuPopoverItem,
}                         from '../../../ui';
import useQuestions       from '../MakeSurvey/useQuestions';
import TogglePopover      from './TogglePopover';
import config             from '../../../config';


export default function ActionGroupBtn(props) {
    const {
              resetDefaultAnswer, handleDel, handleCopy,
              question, isNotSetDefaultAnswer, handleOpenModalAddLogic, handleOpenModalEdit,
          }             = props;
    const { t }         = useTranslation();
    const { questions } = useQuestions();

    return (
        <div>
            <div className={'d-md-none'}>
                <div className="d-flex justify-content-between">
                    <div className={'pr-4'}>
                        <KirokuButtonLink
                            hidden={isNotSetDefaultAnswer}
                            style={{ padding: '0 8px' }}
                            onClick={resetDefaultAnswer}
                        >
                            {t('createRecord.resetAnswer')}
                        </KirokuButtonLink>
                    </div>
                    <TogglePopover>
                        <KirokuPopoverItem>
                            <div className='d-flex justify-content-between fix-icon-align' style={{ width: 260 }}>
                                <span
                                    hidden={questions.length >= config.maxAmountQuestion}
                                    onClick={handleCopy}
                                    className={'pl-3'}
                                >
                                    <i className="fas fa-copy"/>
                                    <span className={`ml-1`}>{t('Surveys.Copy')}</span>
                                </span>
                                <span
                                    hidden={question.index === questions.length - 1}
                                    onClick={handleOpenModalAddLogic}
                                    className={'pl-3'}
                                >
                                    <i className="fas fa-link"/>
                                    <span className={`ml-1`}>{t('createSurvey.AddLogic')}</span>
                                </span>
                                <span
                                    onClick={handleOpenModalEdit}
                                    className={'pl-3'}
                                >
                                    <i className="fas fa-pencil-alt"/>
                                    <span className={`ml-1`}>{t('createSurvey.Edit')}</span>
                                </span>
                                <span
                                    onClick={handleDel}
                                    className={'pl-3'}
                                >
                                    <i className="fas fa-trash-alt"/>
                                    <span className={`ml-1`}>{t('createSurvey.Remove')}</span>
                                </span>
                            </div>
                        </KirokuPopoverItem>
                    </TogglePopover>
                </div>
            </div>
            <div className={'d-none d-sm-none d-md-block'}>
                <div style={{ float: 'right' }} className='btn-group-action-review'>
                    <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                        <div className={'pl-1'}>
                            <KirokuButtonLink
                                hidden={isNotSetDefaultAnswer}
                                onClick={resetDefaultAnswer}>
                                {t('createRecord.resetAnswer')}
                            </KirokuButtonLink>
                        </div>
                        <div className={'pl-1'}>
                            <KirokuButton color='success'
                                          disabled={questions.length >= config.maxAmountQuestion}
                                          style={{ padding: '0 13px' }}
                                          onClick={handleCopy}
                            >
                                {t('Surveys.Copy')}
                            </KirokuButton>
                        </div>
                        <div className={'pl-1'}
                             hidden={question.index === questions.length - 1}
                        >
                            <KirokuButton
                                color={'warning'}
                                style={{ padding: '0 13px' }}
                                onClick={handleOpenModalAddLogic}
                            >
                                {t('createSurvey.AddLogic')}
                            </KirokuButton>
                        </div>
                        <div className={'pl-1'}>
                            <KirokuButton color={'primary'}
                                          style={{ padding: '0 13px' }}
                                          onClick={handleOpenModalEdit}
                            >
                                {t('createSurvey.Edit')}
                            </KirokuButton>
                        </div>
                        <div className={'pl-1'}>
                            <KirokuButton color={'danger'}
                                          style={{ padding: '0 13px' }}
                                          onClick={handleDel}
                            >
                                {t('createSurvey.Remove')}
                            </KirokuButton>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

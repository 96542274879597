import React                      from 'react';
import { KirokuLabel }            from '../../../ui';
import { useConditionFromParams } from './useConditionFromParams';

export default function LabelCondition(props) {
    const { conditionLabels } = useConditionFromParams(props.condition);
    return (
        <div className={ props.className }>
            { conditionLabels.map((cdt, index) => {
                return <span key={ index } className={'pl-3'} >
                        <KirokuLabel
                            style={ {
                                border: 'solid 1px #1991eb',
                                maxWidth: '250px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            } }
                            onClick={ () => props.handleRemoveCondition(cdt) }
                            color={ 'default' }>
                            <span className='mt-1'>{ cdt.label }</span>
                            <span className='align-icon'>&times;</span>
                        </KirokuLabel>
                    </span>;
            })
            }
        </div>
    );
}

import React    from 'react';
import { Link } from 'react-router-dom';

function KirokuStaffTag(props) {
    const tagShows = props['tag'];
    return (
        <span className="representative-tag">
                { tagShows.map((tagShow, index) =>
                    <span key={ index }>
                        { props.type === 'teams' ?
                            <Link key={ index }
                                  to={ `/teams/${ tagShow.tagId ? tagShow.tagId : tagShow.id }` }>
                                <div className="kiroku-btn-data mr-1 mb-1" key={ index }>
                                    { tagShow.name ? tagShow.name : tagShow.value }
                                </div>
                            </Link>
                            :
                            <Link key={ index } to={ `/users/${ tagShow.tagId ? tagShow.tagId : tagShow.id }` }>
                                <div className="kiroku-btn-data mr-1 mb-1" key={ index }>
                                    <i className="avatar-staff"
                                       style={ { backgroundImage: `url(${tagShow.avatar || '/assets/img/profile-ic.svg'})` } }/>
                                    <span>
                                           { tagShow.name ? tagShow.name : tagShow.value }
                                       </span>
                                </div>
                            </Link>
                        }
                    </span>,
                ) }
            </span>
    );
}

export default KirokuStaffTag;

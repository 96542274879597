import React                             from 'react';
import { ComponentWeb, ComponentMobile } from '../../../../ui';
import StatusDropdown                    from '../StatusDropdown';
import { useTranslation }                from 'react-i18next';

export default function ConditionSearchActivitiesLog({ search, status }) {
    const { t } = useTranslation();

    return (<>
        <ComponentWeb>
            <div className={'action-log'} style={{
                borderLeft: 'none',
                borderRight: 'none',
            }}>
                <div className={`action-log-entity ${!status ? 'active-tab' : ''}`}
                     onClick={() => {
                         search({ action: 'all', page: 1 });
                     }}
                >
                    <img src={`/assets/img/All.svg`} style={{ minWidth: 'max-content' }}
                         alt={'All.svg '}/>&nbsp;{t('common.All')}
                </div>
                <div className={`action-log-entity ${status === 'update' ? 'active-tab' : ''}`}
                     onClick={() => {
                         search({ action: 'update', page: 1 });
                     }}
                >
                    <img src={`/assets/img/file-update.svg`}
                         alt={'file-update.svg '}/>&nbsp;{t('common.update')}
                </div>
                <div className={`action-log-entity ${status === 'create' ? 'active-tab' : ''}`}
                     onClick={() => {
                         search({ action: 'create', page: 1 });
                     }}
                >
                    <img src={`/assets/img/file-add.svg`}
                         alt={'file-add.svg '}/>&nbsp;{t('common.create')}
                </div>
                <div className={`action-log-entity ${status === 'delete' ? 'active-tab' : ''}`}
                     onClick={() => {
                         search({ action: 'delete', page: 1 });
                     }}
                >
                    <img src={`/assets/img/file-delete.svg`}
                         alt={'file-delete.svg '}/>&nbsp;{t('common.delete')}
                </div>
                <div className={`action-log-entity ${status === 'comment' ? 'active-tab' : ''}`}
                     onClick={() => {
                         search({ action: 'comment', page: 1 });
                     }}
                >
                    <img src={`/assets/img/file-text.svg`}
                         alt={'file-delete.svg '}/>&nbsp;{t('common.comment')}
                </div>
            </div>
        </ComponentWeb>
        <ComponentMobile>
            <div className="action-log pl-3 pr-3" style={{
                borderLeft: 'none',
                borderRight: 'none',
            }}>
                <StatusDropdown search={search} status={status}/>
            </div>
        </ComponentMobile>
    </>);
}

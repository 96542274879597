import React              from "react";
import Select             from 'react-select';
import makeAnimated       from 'react-select/lib/animated';
import { useTranslation } from 'react-i18next'

export default function KirokuMultiSelect(props) {
    let {t} = useTranslation();
    return (
        <div id='multi-select'>
            <Select
                isMulti
                placeholder={t('common.Select')}
                components={makeAnimated()}
                {...props}
                closeMenuOnSelect={false}
                noOptionsMessage={() => t('common.noOptions')}
            />
        </div>
    )
};

import React, { useContext } from 'react';
import { authentication }    from '@kiroku/api';

export const KirokuPermissionContext = React.createContext({});

export class KirokuPermissions extends React.PureComponent {

    render() {
        const Provider = KirokuPermissionContext.Provider;
        return <Provider {...this.props} value={this.props.metadata}/>;
    }

}


export const withPermissions = (Target) => {
    return (props) => {
        const { permissions } = useContext(KirokuPermissionContext);
        const { user }        = authentication.useAuthenticatedContext();
        const myPermission = permissions[ user.role ][ props[ 'resource-permission' ] ];
        return <Target {...props} hidden={myPermission === 'hidden' || props.hidden}/>;
    };
};

export function WrapperPermissions(props) {
    const { permissions } = useContext(KirokuPermissionContext);
    const { user }        = authentication.useAuthenticatedContext();
    const myPermission = permissions[ user.role ][ props[ 'resource-permission' ] ];
    return (
        <div {...props} hidden={myPermission === 'hidden' || props.hidden}>
            {props.children}
        </div>
    )
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAuthenticated2FA = useAuthenticated2FA;
var _useClient = _interopRequireDefault(require("./useClient"));
var _react = require("react");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function useAuthenticated2FA(user_id) {
  var client = (0, _useClient.default)();
  var disable2FA = (0, _react.useCallback)(function () {
    return client.axios.delete("/auth2fa/".concat(user_id));
  }, [client, user_id]);
  var setup2FA = (0, _react.useCallback)(function () {
    return client.axios.get("/auth2fa/".concat(user_id));
  }, [client, user_id]);
  var verify2FA = (0, _react.useCallback)(function (otp) {
    return client.axios.post("/auth2fa", {
      user_id: user_id,
      otp: otp
    });
  }, [client, user_id]);
  return {
    disable2FA: disable2FA,
    setup2FA: setup2FA,
    verify2FA: verify2FA
  };
}
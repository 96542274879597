import React, { useEffect, useState }                    from 'react';
import { useTranslation }                                from 'react-i18next';
import { KirokuSectionDropdown, ModalSelectStaffOfTeam } from '../../../../ui';
import useTagCustomers                                   from './useTagCustomers';
import { resources }                                     from '@kiroku/api';

export default function TagStaffs({ teams }) {

    const { t }                                               = useTranslation();
    const { currentUser, getGroupedOptions, setTagCustomers } = useTagCustomers();
    const { result, search, cancelAPISearch }                 = resources.useSearch('User');
    const [isShowing, setIsShowing]                           = useState(false);

    useEffect(() => {
        search({ type: 'all' });
        return () => {
            cancelAPISearch();
        }
    }, [search, cancelAPISearch]);

    const optionsStaff = getGroupedOptions('User', result);

    const handleChangeTags = (options) => {
        setTagCustomers('users', options.map(option => ({
            id    : option.id,
            name  : option.name,
            avatar: option.avatar,
        })));
    };

    const handleChangeAllTags = () => {
        setTagCustomers('users', optionsStaff.map(option => ({
            id    : option.id,
            name  : option.name,
            avatar: option.avatar,
        })));
    };

    const optionsTeam = getGroupedOptions('Team', teams);

    return (
        <>
            <div className="d-flex">
                <label className="mt-1 mb-10">{ t('common.staff') }</label>
                <div className="p-2"/>
                { !! (optionsStaff && optionsStaff.length) &&
                (<div
                    className={ 'cursor-pointer mt-1' }
                    style={ { color: '#1891eb' } }
                    onClick={ handleChangeAllTags }
                >
                    { t('Customer.TagAll') }
                </div>)}
                <div className="p-2"/>
                <div
                    className={ 'cursor-pointer mt-1' }
                    style={ { color: '#1891eb' } }
                    onClick={ () => setIsShowing(true) }
                >
                    { t('RecordList.SelectCustomerOfTeam') }
                </div>
                <ModalSelectStaffOfTeam
                    staffSelected={ currentUser }
                    show={ isShowing }
                    staffsSelectedInModal={ (options) => {
                        handleChangeTags(options.filter(option => option.isChecked));
                    } }
                    closeModal={ () => setIsShowing(false) }
                    teams={ optionsTeam }
                    staffs={ optionsStaff }
                />
            </div>
            <KirokuSectionDropdown
                onChange={ handleChangeTags }
                value={ currentUser }
                options={ [{
                    label  : 'User',
                    options: optionsStaff,
                }] }
            />
        </>
    );
};

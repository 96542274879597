import React              from 'react';
import {
    KirokuModalBody, KirokuModal,
    KirokuModalFooter, KirokuButton,
}                         from '../../../ui';
import { resources }      from '@kiroku/api';
import { useTranslation } from 'react-i18next';

export default function ModalSetRoleLeader(props) {
    const { t }     = useTranslation();
    const [setRole] = resources.useUpdateRole('RoleUser');

    const handleConfirmSetRole = () => {
        let userIds = props.checkedUsers.map(user => user.id);
        let valueSetRole = {
            users : userIds,
            role : 'leader'
        };
         setRole(valueSetRole).then(() => {
             props.closeModal();
             props.searchAfterSetRole();
         });
    };

    return (
        <KirokuModal show={ props.show } onClick={ props.closeModal }>
            <KirokuModalBody>
                <p className='text-center pt-10'>
                    { t('staff.SetRole') }
                </p>
            </KirokuModalBody>
            <KirokuModalFooter>
                <KirokuButton onClick={ props.closeModal } color={ 'white' }>
                    { t('common.Cancel') }
                </KirokuButton>
                <KirokuButton onClick={ handleConfirmSetRole }
                              color={ 'primary' }>
                    { t('common.Confirm') }
                </KirokuButton>
            </KirokuModalFooter>
        </KirokuModal>
    );
}

import React, { useEffect, useState } from 'react';
import SupportAction                  from './SupportAction';
import { resources }                  from '@kiroku/api';
import useNotification                from '../../Layout/useNotification';
import { useTranslation }             from 'react-i18next';
import queryString                    from 'query-string';
import { useHistory }                 from 'react-router-dom';
import KirokuLoading                  from '../../../ui/KirokuLoading';
import { useMixpanelTracking }        from '../../../Mixpanel/useMixpanelTracking';

export default function SupportCreator() {
    const { t }                         = useTranslation();
    const create                        = resources.useCreate('Support')[1];
    const [planInfo, loadDetail, loading]         = resources.useDetail('Plan');

    const { notification }              = useNotification();
    const history                       = useHistory();
    const plans                         = queryString.parse(history.location.search.replace('?', ''));
    const { trackingMixpanel }          = useMixpanelTracking();
    const [isDisabled, setDisabled]     = useState(false);

    useEffect(() => {
        const plansURL = queryString.parse(history.location.search.replace('?', ''));
        loadDetail(plansURL.planId)
    }, [history.location.search, loadDetail]);

    return (
        !loading ?
            planInfo ?
            <SupportAction support={ {} }
                           plan={ planInfo }
                           isDisabled={ isDisabled }
                           customer={ plans.customerId }
                           titleHeader={ t('plans.addSupport') }
                           handleSubmit={ (value) => {
                               setDisabled(true);
                               create(value).then((res) => {
                                   setDisabled(false);
                                   trackingMixpanel('CREATE_SUPPORT', value);
                                   localStorage.setItem('prioritySupportMax', value.priority + 1);
                                   notification(t('message.CreateSuccess'), 'success');
                                   history.push(`/supports/${ res.id }`);
                               });
                           } }
        />: <div/> : <KirokuLoading/>
    );
}

import React                    from 'react';
import { useTranslation }       from 'react-i18next';
import moment                   from 'moment';
function GeneralDiff({lhs, rhs}) {
    return <React.Fragment>
        <p className={'text-decoration margin-none'}>{lhs}</p>
        <i className="fas fa-arrow-right pl-2 pr-2"/>
        <p className="margin-none">{rhs}</p>
    </React.Fragment>
}

function DateDiff({lhs, rhs, hasTime = false}) {
    const {t} = useTranslation();
    return <React.Fragment>
        <p className={'text-decoration margin-none'}>
            {lhs ? moment.unix(lhs).format(hasTime ? t('dateTimeFormat.YearMonthDateWithHour'): t('dateTimeFormat.YearMonthDateWithDay')) : ''}
        </p>
        <i className="fas fa-arrow-right pl-2 pr-2"/>
        <p className="margin-none">
            {rhs ? moment.unix(rhs).format(hasTime ? t('dateTimeFormat.YearMonthDateWithHour'): t('dateTimeFormat.YearMonthDateWithDay')) : ''}
        </p>
    </React.Fragment>
}

function GeneralDiffKeyTranslate({lhs, rhs}) {
    const {t} = useTranslation();
    return <React.Fragment>
        <p className={'text-decoration margin-none'}>{t(`customerDifference.${lhs}`)}</p>
        <i className="fas fa-arrow-right pl-2 pr-2"/>
        <p className="margin-none">{t(`customerDifference.${rhs}`)}</p>
    </React.Fragment>
}


function ArrayStringDiff({lhs = [], rhs = []}) {

    return <React.Fragment>
        {lhs.map((item, index) => {
            return <React.Fragment key={index}>
                <p className={`margin-none ${item['beRemoved'] ? 'text-decoration' : ''}`}>{item.label} </p>
                {lhs.length - 1 !== index ? ', ': ''}
            </React.Fragment>
        })}
        <i className="fas fa-arrow-right pl-2 pr-2"/>
        {rhs.map((item, index) => {
            return  <React.Fragment key={index}>
                <p className={`margin-none`} key={index}>{item} </p>
                {rhs.length - 1 !== index ? ',': ''}
            </React.Fragment>
        })}
    </React.Fragment>
}

export {
    GeneralDiffKeyTranslate,
    GeneralDiff,
    DateDiff,
    ArrayStringDiff
}

import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import { getGroupedOptions }          from '../../../Converter';
import {
    ComponentMobile, ComponentWeb, KirokuSectionDropdownShowMore, KirokuSectionDropdownShowMoreMobile,
}                                     from '../../../../ui';

export default function DropdownSelectTeam({ handleChangeTags, placeholder, teamSearch, showModal }) {
    const { t }                         = useTranslation();
    const [currentTags, setCurrentTags] = useState([]);

    useEffect(() => {
        if (showModal) {
            setCurrentTags([]);
        }
    }, [showModal]);

    let teams = getGroupedOptions('Team', teamSearch);

    return (
        <div className={ 'pb-2' }>
            <ComponentWeb>
                <KirokuSectionDropdownShowMore
                    isSearchTags
                    tagType={ 'Team' }
                    onChange={ (options) => {
                        handleChangeTags(options);
                        setCurrentTags(options);
                    } }
                    value={ currentTags }
                    options={ teams }
                    handleSearchTags={ () => {
                    } }
                    placeholder={ placeholder || t('Team.placeholderSearch') }
                    sizeCustomize={4}
                />
            </ComponentWeb>
            <ComponentMobile>
                <KirokuSectionDropdownShowMoreMobile
                    isSearchTags
                    tagType={ 'Team' }
                    onChange={ (options) => {
                        handleChangeTags(options);
                        setCurrentTags(options);
                    } }
                    value={ currentTags }
                    options={ teams }
                    handleSearchTags={ () => {
                    } }
                    placeholder={ t('Team.placeholderSearch') }
                />
            </ComponentMobile>
        </div>
    );
}

import React                   from 'react';
import { Chart }               from 'react-google-charts';
import { KirokuLoading }       from '../../../../ui';
import useSingleChart          from './useSingleChart';
import NoAnswersFoundComponent from '../NoAnswersFoundComponent';

export default function SingleChart({ records, question, handleSelectAnswer }) {

    const { data, isNotDataAnalysis } = useSingleChart(records, question);

    const options = {
        pieHole: 0.4,
        legend: 'bottom',
        pieSliceTextStyle: {
            color: 'black',
        },
        tooltip: { trigger: 'selection' }
    };

    if(isNotDataAnalysis) {
        return <NoAnswersFoundComponent/>
    }

    const chartEvents = [
        {
            eventName: "select",
            callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart()
                const selection = chart.getSelection()
                if (selection.length === 1) {
                    const [selectedItem] = selection
                    const dataTable = chartWrapper.getDataTable()
                    const { row, column } = selectedItem;
                    handleSelectAnswer({type: question.type, content : question.content, answer : dataTable.getValue(row, column || 0)});
                }
            }
        }
    ]


    return (<Chart
        chartType="PieChart"
        width="100%"
        height="500px"
        data={data}
        loader={<KirokuLoading/>}
        options={options}
        chartEvents={chartEvents}
    />);
}

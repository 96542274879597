import React, { useEffect } from 'react';
import { DeletedProvider }  from './DeleteProvider';
import Condition            from './Condition';
import UIList               from './UIList';
import { resources }        from '@kiroku/api';
import If                   from '../If';
import { KirokuLoader }     from '../../ui';
import usePageTitle         from '../../ui/v3/hooks/usePageTitle';
import { useTranslation }   from 'react-i18next';
import useDeleted           from './useDeleted';

export default function DeletedList() {
    const {
              result, search,
              loading, pagination, cancelAPISearch
          } = resources.useSearch('Deleted', { type: 'survey', page: 1 });

    const { t } = useTranslation();

    usePageTitle(t('Layout.Deleted'));

    useEffect(() => {
        search({ type: 'survey', page: 1 });
        return () => {
            cancelAPISearch();
        }
    }, [search, cancelAPISearch]);

    return (
        <KirokuLoader loading={loading}>
            <If statement={result && !pagination.page}>
                {() => <DeletedProvider result={result} pagination={pagination}>
                    <WrapUseSearchDelete/>
                </DeletedProvider>}
            </If>
        </KirokuLoader>
    );
}

const WrapUseSearchDelete = () => {
    const {
              currentScreen,
              searchDeleted, condition, pagination,
              loading, result, sort_by, order_by_type
          } = useDeleted();

    return (
        <div className={'padding-responsive'}>
            <Condition
                currentScreen={currentScreen}
                searchDeleted={searchDeleted}
                condition={condition}
            />
            <UIList currentScreen={currentScreen}
                    condition={condition}
                    pagination={pagination}
                    searchDeleted={searchDeleted}
                    loading={loading}
                    result={result}
                    sort_by={sort_by}
                    order_by_type={order_by_type}
            />
        </div>
    )
}

import { useSearchContext }       from './SearchSystem/SearchContext';
import { useCallback, useEffect } from 'react';
import {
    HANDLE_SELECT_CUSTOMER, HANDLE_SELECT_DATE,
    HANDLE_SELECT_SURVEY, HANDLE_SELECT_USER,
} from './SearchSystem/SearchReducer';
import { resources }              from '@kiroku/api';

export default function useSearch() {

    const { analysisState, dispatch } = useSearchContext();
    const {
              customers, survey, startDate,
              endDate, updaters, creators,
          }                           = analysisState;

    const { search, result, loading, cancelAPISearch } = resources.useSearch('Record');

    useEffect(() => {
        if (analysisState.customers.length && analysisState.survey.id && analysisState.startDate && analysisState.endDate) {
            search({
                type      : 'analytics',
                customerId: analysisState.customers.map(customer => customer.id),
                templateId: analysisState.survey.id,
                startDate : analysisState.startDate.startOf('date').unix(),
                endDate   : analysisState.endDate.endOf('date').unix(),
                updaterId: analysisState.updaters.map(updater => updater.id),
                creatorId: analysisState.creators.map(creator => creator.id)
            });
        }
        return () => {
            cancelAPISearch();
        }
    }, [analysisState, search, cancelAPISearch]);

    const handleSelectCustomer = useCallback((customers) => {
        dispatch({
            type     : HANDLE_SELECT_CUSTOMER,
            customers: customers,
        });
    }, [dispatch]);

    const handleSelectSurvey = useCallback((event, creators, updaters) => {
        dispatch({
            type  : HANDLE_SELECT_SURVEY,
            survey: {
                id  : event.kirokuSelected.value,
                name: event.kirokuSelected.name,
            },
            updaters,
            creators
        });
    }, [dispatch]);

    const handleOnChangeDateTime = useCallback((event, dateKey, creators, updaters) => {
        dispatch({
            type: HANDLE_SELECT_DATE,
            date: {
                [ dateKey ]: event,
            },
            updaters,
            creators
        });

    }, [dispatch]);

    const handleSelectUpdater = useCallback((updaters, currentCreatorTags) => {
        dispatch({
            type: HANDLE_SELECT_USER,
            updaters,
            creators: currentCreatorTags
        })
    }, [dispatch]);

    const handleSelectCreator = useCallback((creators, currentUpdaterTags) => {
        dispatch({
            type: HANDLE_SELECT_USER,
            creators,
            updaters: currentUpdaterTags
        })
    }, [dispatch]);

    return {
        result, loading,

        startDate, endDate,
        handleOnChangeDateTime,

        customers,
        handleSelectCustomer,

        survey,
        handleSelectSurvey,

        updaters,
        handleSelectUpdater,

        creators,
        handleSelectCreator
    };
}

import moment from 'moment';

export const SET_DATE_RANGE                = 'SET_DATE_RANGE';
export const SEARCH_ORDER_CONDITION        = 'SEARCH_ORDER_CONDITION';
export const HANDLE_RESET_CONDITION        = 'HANDLE_RESET_CONDITION';
export const SEARCH_ORDER_CONDITION_MOBILE = 'SEARCH_ORDER_CONDITION_MOBILE';
export const SEARCH_PAGINATOR_CONDITION    = 'SEARCH_PAGINATOR_CONDITION';

export default function (state, action) {
    switch (action.type) {
        case 'UPDATE_RECORD_CONDITION':
            return {
                ...state,
                ...action.conditions,
            };
        case SET_DATE_RANGE:
            return {
                ...state,
                ...action.dateRange,
                page : 1
            };
        case SEARCH_ORDER_CONDITION:
            return {
                ...state,
                sort_by : action.sort_by,
                order_by: state.order_by === 'DESC' ? 'ASC' : 'DESC',
                page : 1
            };

        case HANDLE_RESET_CONDITION:
            return {
                ...state,
                status          : 'recorded',
                serviceStartDate: moment().subtract(1, 'month').startOf('day').unix(),
                serviceEndDate  : moment().endOf('day').unix(),
                updateStartDate : null,
                updateEndDate   : null,
                cId             : action.customerDefault.map(customer => customer.id),
                udId            : [],
                tId             : [],
                answer_search   : '',
                contain_read    : true,
                order_by        : 'DESC',
                sort_by         : 'serviced_at',
                customerTab     : 'SEARCH_BY_CUSTOMER_TAG',
                page            : 1,
            };
        case SEARCH_ORDER_CONDITION_MOBILE:
            return {
                ...state,
                sort_by : action.sort_by,
                order_by: action.order_by,
            };
        case SEARCH_PAGINATOR_CONDITION:
            return {
                ...state,
                page: action.page,
            };

        default:
            return state;
    }
}

const userCondition = (condition) => {
    return {
        name    : condition.name,
        order_by: condition.order_by,
        sort_by : condition.sort_by,
        type    : 'user',
        page    : condition.page,
    };
}

const surveyCondition = (condition) =>  {
    return {
        title   : condition.title,
        order_by: condition.order_by,
        sort_by : condition.sort_by,
        type    : 'survey',
        page    : condition.page,
    };
}

const recordCondition = (condition) => {
    return {
        name    : condition.name,
        title   : condition.title,
        order_by: condition.order_by,
        sort_by : condition.sort_by,
        type    : 'record',
        page    : condition.page,
    };
}

const customerCondition = (condition) => {
    return {
        name    : condition.name,
        order_by: condition.order_by,
        sort_by : condition.sort_by,
        type    : 'customer',
        page    : condition.page,
    };
}

export default {
    surveys  : surveyCondition,
    records  : recordCondition,
    customers: customerCondition,
    users    : userCondition,
};


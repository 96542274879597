import React, { useEffect }             from 'react';
import SurveyProvider                   from '../MakeSurvey/SurveyProvider';
import Survey                           from '../Survey';
import { resources }                    from '@kiroku/api';
import If                               from '../../If';
import { KirokuLoader, SearchNotFound } from '../../../ui';
import { useHistory }                   from 'react-router-dom';
import { useMixpanelTracking }          from '../../../Mixpanel/useMixpanelTracking';
import useNotification                  from '../../Layout/useNotification';
import { useTranslation }               from 'react-i18next';

export default function SurveyDuplicate(props) {

    const [survey, loadDetail, isLoading] = resources.useDetail('Template');
    const [resultSurvey, createSurvey]    = resources.useCreate('Template');

    const { notification }     = useNotification();
    const { t }                = useTranslation();
    const history              = useHistory();
    const { trackingMixpanel } = useMixpanelTracking();

    useEffect(() => {
        loadDetail(props.match.params.id).catch(error => console.log(error));
    }, [loadDetail, props.match.params.id]);

    useEffect(() => {
        if (resultSurvey) {
            notification(t('Surveys.Created'), 'success');
            history.push(`/surveys/${resultSurvey.id}`);
        }
    }, [resultSurvey, history, notification, t]);

    return (
        <KirokuLoader loading={isLoading}>
            <If statement={survey}
                otherwise={ <SearchNotFound message={ t('common.SurveyInvalid') } description={ ' ' }/> }
            >
                {() => {
                    return <div>
                        <SurveyProvider
                            survey={survey}
                        >
                            <Survey
                                handleOnSubmit={(surveyDataUpdate) => {
                                    createSurvey(surveyDataUpdate);
                                    trackingMixpanel('DUPLICATE_SURVEY', surveyDataUpdate);
                                }}
                            />
                        </SurveyProvider>
                    </div>;
                }}
            </If>
        </KirokuLoader>
    );
}

import React                                         from 'react';
import { KirokuLoader, KirokuTable, TBody, THeader } from '../../../ui';
import { Link }                                      from 'react-router-dom';
import { useTranslation }                            from 'react-i18next';
import SearchByHiragana                              from '../../Customer/CustomerList/SearchByHiragana';
import usePinnedCustomer                             from './usePinnedCustomer';

export default function PinnedCustomer() {
    const { t } = useTranslation();
    const { loading, hoverStar, pinCustomer, customers, handleSearchPinned, condition } = usePinnedCustomer();

    return (
            <div>
                <h5 className='pt-3 pb-3 pr-3 recent-title'>{t('Dashboard.myCustomers')}</h5>
                <div className="bg-white">
                    <div className={'p-3'}>
                        <SearchByHiragana
                            furiganaSearch={condition?.furigana || ''}
                            handleSearch={ value => {
                                handleSearchPinned({ furigana: value})
                            }}
                        />
                    </div>
                    <KirokuLoader loading={loading}>
                    {customers.length ? (
                        <KirokuTable>
                            <THeader className={'tbl-header'}>
                                <tr>
                                    <th>{t('Dashboard.customerName')}</th>
                                    <th>
                                        <div className={'text-align-end'}>
                                            { t('Customer.Furigana') }
                                            <span className={'pl-1 cursor-pointer'}>
                                                <i hidden={condition.order_by === 'DESC'} className="fas fa-lg fa-angle-up"
                                                   onClick={() => {
                                                       handleSearchPinned({ order_by: 'DESC' })
                                                   } }
                                                />
                                                <i hidden={condition.order_by === 'ASC'} className="fas fa-lg fa-angle-down"
                                                   onClick={() => {
                                                       handleSearchPinned({ order_by: 'ASC' })
                                                   } }
                                                />
                                            </span>
                                        </div>
                                    </th>
                                </tr>
                            </THeader>
                            <TBody>
                                {customers.map((customer, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Link to={`/customers/${customer.id}`}>
                                                {customer.name}
                                            </Link>
                                            <div>{customer?.furigana}</div>
                                        </td>
                                        <td>
                                            <div
                                                onMouseEnter={() => hoverStar(index, customer.hoverStar)}
                                                onMouseLeave={() => hoverStar(index, customer.hoverStar)}
                                                className='group-pin'
                                                onClick={() => pinCustomer(customer.id)}
                                                style={{ textAlign: 'right', cursor: 'pointer' }}>

                                                <i style={{ color: '#7f8fa4' }}
                                                   className={`${customer[ 'pinned_at' ] ?
                                                       `${customer.hoverStar ? `far fa-star` : `fas fa-star`}` : `${customer.hoverStar ? `far fa-star` : `fas fa-star`}`}`}/>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </TBody>
                        </KirokuTable>
                    ) : (
                        <div>
                            <KirokuTable>
                                <THeader className={'tbl-header'}>
                                    <tr>
                                        <th>{t('Dashboard.customerName')}</th>
                                        <th/>
                                    </tr>
                                </THeader>
                            </KirokuTable>
                            <div className={'text-center p-2'}>
                                <h5>
                                    {t('Dashboard.noCustomer')}
                                </h5>
                            </div>
                        </div>
                    )}
                    </KirokuLoader>
                </div>
            </div>
    );
};

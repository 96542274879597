import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';
import {
    KirokuButton, KirokuCard, KirokuCardList, KirokuCardWeb, KirokuDropdownClose, KirokuInput, KirokuInputArea,
    KirokuTable, TBody, THeader,
}                          from '../../../ui';
import ModalSelectMember   from './ModalSelectMember';
import ModalRemoveMembers  from './ModalRemoveMembers';
import { useHistory }      from 'react-router-dom';
import ComponentWeb        from '../../../ui/ComponentWeb';
import ComponentMobile     from '../../../ui/ComponentMobile';

export default function ActionTeam(props) {
    const { t }                                     = useTranslation();
    const [name, setName]                           = useState(props.team.name);
    const [description, setDescription]             = useState(props.team.description);
    const [validateName, setValidName]              = useState(false);
    const [invalidDescription, setValidDescription] = useState(false);
    const [showModal, setShowModal]                 = useState('');
    const [membersChoice, setMemberChoice]          = useState(props.members);
    const history                                   = useHistory();

    const bulkActionItems = [
        {
            value    : 'delete',
            component: (
                <div>{ t('common.Delete') }</div>
            ),
            label    : t('common.Delete'),
        },
    ];

    const handleUpdateTeam = () => {
        let userTagged = membersChoice.map(member => ({ ...member, tagContent: { ...member }, tagId: member.id }));
        props.handleSubmit({
            name,
            description,
            members: userTagged,
        });
    };

    return (
        <div className='padding-responsive'>
            <div>
                <KirokuCardWeb>
                    <div className='p-3'>
                        <div className="row">
                            <div className="col-md-6">
                                <div className={'font-size-18'}> { t('Team.AddTeam') }</div>
                                <div className='pt-2 pb-2'>{ t('Team.TeamName') }</div>
                                <KirokuInput value={ name }
                                             name='name'
                                             onChange={ (e) => setName(e.target.value) }
                                             onBlur={ () => setValidName(!name) }
                                             error={ validateName ? 'form-error' : '' }

                                />
                                <p hidden={ !validateName } style={ {
                                    color   : 'red',
                                    fontSize: 12,
                                } }>{ t('Team.Team name must not null') }</p>
                                <div className='pt-2 pb-2'>{ t('common.Description') }</div>
                                <KirokuInputArea name='description' value={ description }
                                                 onChange={ (e) => setDescription(e.target.value) }
                                                 onBlur={ () => setValidDescription(!description) }
                                                 error={ invalidDescription ? 'form-error' : '' }
                                />

                                <p hidden={ !invalidDescription } style={ {
                                    color   : 'red',
                                    fontSize: 12,
                                } }>{ t('Team.Description must not null') }</p>
                            </div>
                        </div>
                    </div>
                </KirokuCardWeb>
                <ComponentWeb>
                    <div className="pt-3">
                        <KirokuCardWeb>
                            <h6 className='p-3 card-title-info'>{ t('common.Members') }</h6>
                            { membersChoice.length ?
                                <div>
                                    <KirokuTable>
                                        <THeader>
                                            <tr>
                                                <th>{ t('common.Name') }</th>
                                                <th>{ t('common.Role') }</th>
                                                <th>{ t('common.Title') }</th>
                                                <th>{ t('common.WorkingForm') }</th>
                                            </tr>
                                        </THeader>
                                        <TBody>
                                            { membersChoice.map((member, index) =>
                                                <tr key={ index }>
                                                    <td>{ member.name }</td>
                                                    <td>{ t(`role.${ member['role'] }`) }</td>
                                                    <td>{ member.title }</td>
                                                    <td>{ member.working_form ? t(`common.${ member.working_form }`) : '' }</td>
                                                </tr>,
                                            ) }
                                        </TBody>
                                    </KirokuTable>
                                    <div className='p-3 d-flex justify-content-between'>
                                        <KirokuDropdownClose
                                            style={ { minWidth: 160 } }
                                            location={ 'top' }
                                            title={ t('common.BulkAction') }
                                            items={ bulkActionItems }
                                            onSelect={ event => setShowModal(event.kirokuSelected.value)}
                                        />
                                        <KirokuButton color='success' onClick={ () => setShowModal('addMembers') }>
                                            { t('Team.AddMember') }
                                        </KirokuButton>
                                    </div>
                                </div>
                                :
                                <div>
                                    <KirokuTable>
                                        <THeader>
                                            <tr>
                                                <th>{ t('common.Name') }</th>
                                                <th>{ t('common.Role') }</th>
                                                <th>{ t('common.Title') }</th>
                                                <th>{ t('common.WorkingForm') }</th>
                                            </tr>
                                        </THeader>
                                    </KirokuTable>
                                    <div className='fix-center pt-3'>
                                        <div>
                                            <div className='image-default'
                                                 style={ {
                                                     backgroundImage   : 'url(/assets/img/profile-ic.svg)',
                                                     width: 120, height: 120,
                                                 } }
                                            />
                                        </div>
                                        <div>
                                            <p className='text-center'>{ t('Team.Opps') }</p>
                                            <p className='text-center'>{ t('Team.PleaseTeam') }</p>
                                        </div>
                                    </div>
                                    <div className="pt-3 pb-3 text-center">
                                        <KirokuButton color='success'
                                                      onClick={ () => setShowModal('addMembers') }>
                                            { t('Team.AddMember') }
                                        </KirokuButton>
                                    </div>
                                </div> }
                        </KirokuCardWeb>
                    </div>
                </ComponentWeb>
                <ComponentMobile>
                    <div className="pt-2">
                        <div className='card'>
                            <div className='p-2 pt-3'>
                                <h6 className='card-title-info'>{ t('common.Members') }</h6>
                                <div>
                                    { membersChoice.length ?
                                        <KirokuCardList
                                            headerLeft={ <span className='customer-name'>{ t('common.Name') }</span> }
                                            headerRight={ <span className='customer-name'>{ t('common.Role') }</span> }
                                        >
                                            { membersChoice.map(member =>
                                                <KirokuCard key={ member.id }
                                                            action={ <p>{ t(`role.${ member['role'] }`) }</p> }>
                                                    <p>{ member.name }</p>
                                                </KirokuCard>,
                                            ) }
                                        </KirokuCardList> :
                                        <div className='fix-center'>
                                            <div>
                                                <div className='image-default'
                                                     style={ {
                                                         backgroundImage   : `url(/assets/img/profile-ic.svg)`,
                                                         width: 120, height: 120,
                                                     } }
                                                />
                                            </div>
                                            <div>
                                                <p className='text-center'>{ t('Team.Opps') }</p>
                                                <p className='text-center'>{ t('Team.PleaseTeam') }</p>
                                            </div>
                                        </div>
                                    }
                                    <div className="pt-3 pb-3 text-center">
                                        <KirokuButton color={ 'success' }
                                                      onClick={ () => setShowModal('addMembers') }>{ t('Team.AddMember') }</KirokuButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { membersChoice.length ?
                            <div className="pt-2 d-flex mb-2">
                                <KirokuDropdownClose
                                    style={ { minWidth: 130 } }
                                    location={ 'top' }
                                    title={ t('common.BulkAction') }
                                    items={ bulkActionItems }
                                    onSelect={ event => setShowModal(event.kirokuSelected.value) }
                                />
                            </div> : <div/> }
                    </div>
                </ComponentMobile>
                <ModalRemoveMembers membersChoice={ membersChoice }
                                    hide={ () => setShowModal('')}
                                    getMemberRemoved={ (value) => setMemberChoice(value) }
                                    show={ showModal === 'delete' }/>
                <ModalSelectMember show={ showModal === 'addMembers' }
                                   checkedUsers={ membersChoice }
                                   hide={ () => setShowModal('')}
                                   handleSubmit={ (value) => setMemberChoice(value) }/>
                <div className='fix-center'>
                    <div className='pt-3 pb-3' style={ { width: 200 } }>
                        <div className='d-flex justify-content-between'>
                            <KirokuButton color={ 'white' }
                                          onClick={ () => history.goBack() }>{ t('common.Cancel') }</KirokuButton>
                            <KirokuButton color={ 'primary' } disabled={ !name || !description }
                                          onClick={ () => handleUpdateTeam() }>{ t('common.Update') }</KirokuButton>
                        </div>
                    </div>
                </div>
                <ComponentMobile>
                    <div style={ { marginBottom: 80 } }/>
                </ComponentMobile>
            </div>
        </div>
    );
}

import React, { useEffect, useState }                                                              from 'react';
import queryString                                                                                 from 'query-string';
import { KirokuButton, KirokuCheckBox, KirokuLoader, KirokuTable, SearchNotFound, TBody, THeader } from '../../../ui';
import If                                                                                          from '../../If';
import { resources }                                                                               from '@kiroku/api';
import {
    Link, useHistory,
}                                                                                                  from 'react-router-dom';
import { useTranslation }                                                                          from 'react-i18next';
import _                                                                                           from 'lodash';
import ModalSelectTypeExportDocx
                                                                                                   from './ModalSelectTypeExportDocx';
import KirokuNotificationContent
                                                                                                   from '../../../ui/KirokuNotificationContent';
import momentTZ                                                                                    from 'moment-timezone';
import { useMixpanelTracking }                                                                     from '../../../Mixpanel/useMixpanelTracking';
import moment                                                                                      from 'moment';
import { useProfile }                                                                              from '../../useProfile';
import config                                                                                      from '../../../config';
import KirokuNoticeDownloadExport                                                                  from './KirokuNoticeDownloadExport';

function isUsingElasticSearch(user) {
    if (config.orgUsingElasticSearch.includes(user.organization_id)) {
        return 'elasticsearchExport'
    }
    return 'export';
}

export default function BatchAction(props) {

    const [records, setRecords]     = useState([]);
    const history                   = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [exportId, setExportId]   = useState(null);

    const [sortByServicedAt, setSortByServicedAt] = useState('DESC');
    const [sortByUpdateAt, setSortByUpdateAt]     = useState('DESC');

    const [user] = useProfile();

    const { result, search, loading, condition } = resources.useSearch('Record', {
        ...queryString.parse(props.location.search, { arrayFormat: 'comma' }),
        type: isUsingElasticSearch(user),
    });

    const { t }                           = useTranslation();
    const [notification, setNotification] = useState({ message: '', type: '', show: false });

    const [exportExcel, exportRecordLoading] = resources.useExport('ExportRecord');
    const { trackingMixpanel }               = useMixpanelTracking();

    useEffect(() => {
        const condition = queryString.parse(props.location.search, { arrayFormat: 'comma' });
        search({
            ...condition,
            type: isUsingElasticSearch(user),
        });
    }, [props.location.search, search, user]);


    useEffect(() => {
        setRecords(result);
    }, [result]);

    useEffect(() => {
        if (condition.sort_by === 'serviced_at') {
            setSortByServicedAt(condition.order_by);
        }
        if (condition.sort_by === 'update_at') {
            setSortByUpdateAt(condition.order_by);
        }
    }, [condition]);

    useEffect(() => {
        const urlQuery = queryString.stringify({ ...condition }, { arrayFormat: 'comma' });
        history.push('/batch-action-records/record?' + urlQuery);
    }, [condition, history]);

    const handleChangeOrderBy = (sort_by) => {
        const newCondition = {
            ...condition,
            sort_by,
            order_by: condition.order_by === 'ASC' ? 'DESC' : 'ASC',
        }
        const urlQuery = queryString.stringify({ ...newCondition }, { arrayFormat: 'comma' });
        history.push('/batch-action-records/record?' + urlQuery);
    };

    const handleCheckedRecordsBatchAction = (id) => {
        setRecords(records.map(record => {
            if (record.id === id) {
                return { ...record, isChecked: !record.isChecked };
            }
            return record;
        }));
    };

    const handleExportRecord = () => {
        if(records.filter(itm => itm.isChecked).length > 1000) {
            setNotification({
                message: t('message.overExport'), type: 'danger', show: true
            })
        } else {
            trackingMixpanel('EXPORT_EXCEL_RECORD_V2', {
                records : records.filter(itm => itm.isChecked),
                lang    : localStorage.getItem('i18nextLng') || 'ja',
                timezone: momentTZ.tz.guess(),
            })
            exportExcel('ids', {
                records : records.filter(itm => itm.isChecked).map(item => item.id),
                lang    : localStorage.getItem('i18nextLng') || 'ja',
                timezone: momentTZ.tz.guess(),
            }).then((response) => {
                let linkDownload = document.getElementById('downloadFile');
                if(response.url) {
                    linkDownload.setAttribute('href', response.url);
                    linkDownload.click();
                }
            });
        }

    };

    const handleCheckAll = (isStatusCheckAll) => {
        setRecords(records.map(record => {
            return { ...record, isChecked: !isStatusCheckAll };
        }));
    };

    useEffect(() => {
        let id = setInterval(() => {
            if (notification.show && notification.message && notification.type) {
                setNotification({
                    message: '', type: '', show: false,
                });
            }
        }, 3000);
        return () => clearInterval(id);
    });


    const handleExportExcelUnLimited = () => {
        exportExcel('unlimited/ids', {
            records : records.filter(itm => itm.isChecked).map(item => item.id),
            lang    : localStorage.getItem('i18nextLng') || 'ja',
            timezone: momentTZ.tz.guess(),
        }).then((response) => {
            setExportId(response.id)
        });
    }

    return (<div className={'default-background-color padding-responsive'}>
        <a href={'xxx'} id={'downloadFile'} hidden download>xxx</a>
        <KirokuLoader loading={loading}>
            <If statement={records.length}
                otherwise={<div className={'default-background-color '} style={{ backgroundColor: '#FFF' }}>
                    <SearchNotFound/>
                </div>}
            >
                {() => <div>
                    <div className={notification.show ? 'on' : 'off'}>
                        <KirokuNotificationContent message={notification.message} color={notification.type}/>
                    </div>
                    <KirokuNoticeDownloadExport
                        exportId={exportId}
                        setExportId={setExportId}
                    />
                    <ModalSelectTypeExportDocx
                        show={showModal}
                        closeModal={() => setShowModal(false)}
                        records={records.filter(itm => itm.isChecked)}
                        notification={setNotification}
                    />
                    <div hidden={showModal}>
                        <div className="d-flex">
                            <div>
                                <KirokuButton className={'btn-active font-text-in-batch-action'}
                                              hidden={!records.length}
                                              style={{fontSize: 14 }}
                                              disabled={!records.filter(itm => itm.isChecked).length || exportRecordLoading}
                                              onClick={() => handleExportExcelUnLimited()}
                                >
                                    {t('RecordsBeta.exportSelectRecordsBeta', { count: records.filter(itm => itm.isChecked).length })}
                                </KirokuButton>
                            </div>
                            <div className={'pl-3'}>
                                <KirokuButton className={'btn-active font-text-in-batch-action'}
                                              hidden={!records.length}
                                              style={{fontSize: 14 }}
                                              disabled={!records.filter(itm => itm.isChecked).length || exportRecordLoading}
                                              onClick={() => handleExportRecord()}
                                >
                                    {t('RecordsBeta.exportSelectRecords', { count: records.filter(itm => itm.isChecked).length })}
                                </KirokuButton>
                            </div>
                            <div className="pl-3">
                                <KirokuButton className={'btn-active pl-3 font-text-in-batch-action'}
                                              hidden={!records.length}
                                              style={{fontSize: 14 }}
                                              disabled={!records.filter(itm => itm.isChecked).length}
                                              onClick={() => setShowModal(true)}
                                >
                                    {t('RecordsBeta.BatchActionReport', { count: records.filter(itm => itm.isChecked).length })}
                                </KirokuButton>
                            </div>
                        </div>
                        <div className={'pt-3'} hidden={!records.length}/>
                        <KirokuTable>
                            <THeader>
                                <tr>
                                    <th style={{ minWidth: 50, padding: '1px 1px 17px 38px' }}>
                                        <div className={'checkbox-weight'}>
                                            <KirokuCheckBox content={''}
                                                            onChange={() => {
                                                                handleCheckAll(_.findIndex(records, (ctm) => (!ctm.isChecked)) === -1);
                                                            }}
                                                            checked={_.findIndex(records, (ctm) => (!ctm.isChecked)) === -1}
                                            /></div>
                                    </th>
                                    <th style={{ minWidth: 171 }}
                                        className={'font-text-in-batch-action'}>{t('RecordsBeta.customerName')}</th>
                                    <th style={{ minWidth: 175 }}
                                        className={'font-text-in-batch-action'}>{t('RecordsBeta.recordTemplate')}</th>
                                    <th className="th-date-batch-action-record-beta font-text-in-batch-action"
                                        style={{ minWidth: 250 }}
                                    >
                                        <div className="d-flex width-max-content cursor-pointer"
                                             onClick={() => handleChangeOrderBy('serviced_at')}
                                        >
                                            <div
                                                className='font-text-in-batch-action'>{t('RecordsBeta.ServiceTime')}</div>
                                            <div className="p-1"/>
                                            <div
                                                className={`icon-order ${condition.sort_by === 'serviced_at' ? 'color-blue-icon-order' : ''}`}>
                                                <i hidden={sortByServicedAt === 'DESC'}
                                                   className="fas fa-lg fa-angle-up"/>
                                                <i hidden={sortByServicedAt === 'ASC'}
                                                   className="fas fa-lg fa-angle-down"/>
                                            </div>
                                        </div>
                                    </th>
                                    <th className="th-date-batch-action-record-beta"
                                        style={{ minWidth: 250 }}
                                    >
                                        <div className="d-flex width-max-content cursor-pointer"
                                             onClick={() => handleChangeOrderBy('update_at')}
                                        >
                                            <div
                                                className={'font-text-in-batch-action'}>{t('RecordsBeta.updatedDate')}</div>
                                            <div className="p-1"/>
                                            <div
                                                className={`icon-order ${condition.sort_by === 'update_at' ? 'color-blue-icon-order' : ''}`}>
                                                <i hidden={sortByUpdateAt === 'DESC'}
                                                   className="fas fa-lg fa-angle-up"/>
                                                <i hidden={sortByUpdateAt === 'ASC'}
                                                   className="fas fa-lg fa-angle-down"/>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </THeader>
                            <TBody>
                                {records.map((record, i) => {
                                    return (
                                        <tr key={i} className={'label-record-beta'}>
                                            <td>
                                                <div className={'checkbox-weight'}
                                                     style={{ padding: '1px 1px 17px 23px' }}>
                                                    <KirokuCheckBox content={''} checked={!!record.isChecked}
                                                                    onChange={() => handleCheckedRecordsBatchAction(record.id)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                {record.customer &&  <Link to={`/customers/${record.customer.id}`}>
                                                    <span className={'label-name'}>{record.customer.name}</span>
                                                </Link>}
                                            </td>
                                            <td>
                                                <Link to={{ pathname: `/records/${record.id}` }}>
                                                    <span className={'label-name'}>{record.title}</span>
                                                </Link>
                                            </td>
                                            <td>
                                            <span className={'font-text-date-time-records-table'}>
                                                {moment.unix(record.serviced_at).format(record.has_time ? t('dateTimeFormat.YearMonthDateWithHour') : t('dateTimeFormat.YearMonthDateWithDay'))}
                                            </span>
                                            </td>
                                            <td>
                                            <span className={'font-text-date-time-records-table'}>
                                                {moment.unix(record['last_action_at']).format(t('dateTimeFormat.YearMonthDateWithHour'))}
                                            </span>
                                            </td>
                                        </tr>
                                    );
                                })}

                            </TBody>
                        </KirokuTable>
                    </div>

                </div>}
            </If>
        </KirokuLoader>
    </div>);
}

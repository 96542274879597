import If                               from '../../If';
import { KirokuLoader, SearchNotFound } from '../../../ui';
import CustomerProvider                 from '../MakeCustomer/CustomerProvider';
import CustomerForm                   from '../CustomerForm/CustomerForm';
import React, { useEffect, useState } from 'react';
import { resources }                  from '@kiroku/api';
import CustomerFactory                  from '../MakeCustomer/CustomerFactory';
import { useHistory }                   from 'react-router-dom';
import { useTranslation }               from 'react-i18next';
import useNotification         from '../../Layout/useNotification';
import { useMixpanelTracking } from '../../../Mixpanel/useMixpanelTracking';

export default function CustomerDuplicate(props) {

    const [customer, loadDetail, isLoading]   = resources.useDetail('Customer');
    const [resultCustomer, create]            = resources.useCreate('Customer');
    const history                             = useHistory();
    const { t }                               = useTranslation();
    const { notification }                    = useNotification();
    const { trackingMixpanel }                = useMixpanelTracking();
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        loadDetail(props.match.params.id).catch(error => console.log(error));
    }, [loadDetail, props.match.params.id]);

    useEffect(() => {
        if (resultCustomer) {
            notification(t('message.UpdateSuccess'), 'success');
            history.push(`/customers/${resultCustomer.id}`);
        }
    }, [resultCustomer, history, notification, t]);

    const factory = new CustomerFactory();
    return (
        <KirokuLoader loading={isLoading}>
            <If statement={customer}
                otherwise={ <SearchNotFound message={ t('common.CustomerInvalid') } description={ ' ' }/> }>
                {() => (
                    <CustomerProvider customer={factory.makeCustomerDuplicate(customer)}>
                        <CustomerForm
                            disabledButton={disabledButton}
                            submit={(customer) => {
                                setDisabledButton(true);
                                const customerRequestData = factory.make(customer);
                                create({
                                    ...customerRequestData,
                                    surveys: customer.surveys || []
                                });
                                trackingMixpanel('CUSTOMER_DUPLICATED', { ...customerRequestData, hadDuplicatedCustomerId: props.match.params.id});
                            }}
                        />
                    </CustomerProvider>
                )}
            </If>
        </KirokuLoader>
    );
};

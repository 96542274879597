import React, {Component} from 'react';
import KirokuCardAction   from './KirokuCardAction';

class KirokuTogglePopover extends Component {

    constructor(props) {
        super(props);

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible
        }));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    render() {
        return (
            <div className="kiroku-toggle-popover" ref={node => {
                this.node = node
            }}>
                <KirokuCardAction>
                    <div className={"kiroku-toggle-popover-action-icon"}>
                        <i style={{color: '#1991eb'}} className="fas fa-ellipsis-h"
                           onClick={this.handleClick.bind(this)}/>
                    </div>
                    {this.state.popupVisible && (
                        <div onClick={this.handleClick.bind(this)}>
                            {this.props.children}
                        </div>
                    )}
                </KirokuCardAction>
            </div>
        )
    }
}

export default KirokuTogglePopover;

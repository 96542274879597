import { getFlowQuestions } from '../HelperComponent/getQuestions';

export default function useMediaChart(records, question) {
    let questionFlowed = records.map(record => {
        return {
            ...getFlowQuestions(record.survey_template).filter(item => item.content === question.content && item.type === question.type).map((itm) => {
                return {
                    ...itm,
                };
            }),
        };
    }).map(itm => itm['0']).filter(itm => !!itm);

    return questionFlowed.map(question => {
        return {
            original : question.answers.answer || '',
            thumbnail: question.answers.answer || '',
        };
    });
}

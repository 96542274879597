import React, { useEffect, useState } from 'react';
import {
    KirokuModal, KirokuModalBody,
    KirokuModalHeader, KirokuInputSearch,
    KirokuCardList, KirokuModalFooter,
    KirokuButton, KirokuLoader, KirokuCheckboxBorder,

} from '../../../../../ui';
import { useTranslation }             from 'react-i18next';
import lodash                         from 'lodash';
import { resources }                  from '@kiroku/api';


export default function ModalSelectUser(props) {
    const {show, handleCloseModal, handleShareDocument, modalData} = props;
    const [keywordSearchStaff, setKeywordSearchStaff] = useState('');
    const [usersForModal, setUsersForModal] = useState([]);
    const [users, setUsers] = useState([]);
    const {result, search, loading, cancelAPISearch} = resources.useSearch('User');
    const { t } = useTranslation();

    useEffect(() => {
        if(show) {
            search({type: 'all'});
        }
        return () => {
            cancelAPISearch();
        }
    }, [search, show, cancelAPISearch]);

    useEffect(() => {
        const users = show ? modalData.document.users: [];
        setUsersForModal(result.map(user => {
            if(lodash.findIndex(users, userItem => userItem.id === user.id) !== -1) {
                return {
                    ...user,
                    isChecked: true,
                }
            }
            return user;
        }));
        setUsers(result.map(user => {
            if(lodash.findIndex(users, userItem => userItem.id === user.id) !== -1) {
                return {
                    ...user,
                    isChecked: true,
                }
            }
            return user;
        }));
    }, [result, modalData, show]);

    const handleCheckUsers = (userId) => {
        setUsersForModal(usersForModal.map(user => {
            if(user.id === userId) {
                return {
                    ...user,
                    isChecked: !user.isChecked
                }
            }
            return user;
        }));
        setUsers(users.map(user => {
            if(user.id === userId) {
                return {
                    ...user,
                    isChecked: !user.isChecked
                }
            }
            return user;
        }))

    };

    const handleCheckAll = () => {
        const listUserSelectAll = usersForModal.map(user => {
            return {
                ...user,
                isChecked: lodash.findIndex(usersForModal, user => !user.isChecked) !== -1
            }
        });
        setUsersForModal(listUserSelectAll);
        setUsers(users.map(user => {
            const indexUserIsChecked = lodash.findIndex(listUserSelectAll, userItem => userItem.id === user.id);
            if(indexUserIsChecked !== -1) {
                return {
                    ...user,
                    isChecked: listUserSelectAll[indexUserIsChecked].isChecked
                }
            }
            return user;
        }))

    };

    const onChangeSearchUsers = (event) => {
        const inputValue = event.target.value.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        setKeywordSearchStaff(inputValue);
        setUsersForModal(users.filter(user => {
            return user.name.toLowerCase().search(inputValue.toLowerCase()) !== -1;
        }))
    };

    return (<KirokuModal show={show}
                         onCloseModal={() => handleCloseModal()}>
        <KirokuModalHeader>
            <h6 className="mb-3 font-size-16">{t('CustomerEditor.AssignToUsers')}</h6>
            <KirokuInputSearch onChange={(event) => onChangeSearchUsers(event)}
                               value={keywordSearchStaff}
                               placeholder={t('CustomerEditor.EnterNameUser')}/>
        </KirokuModalHeader>
        <KirokuModalBody>
            <div id="body-modal-as">
                <KirokuLoader loading={loading}>
                    <KirokuCardList
                        headerLeft={t('Dashboard.Users')}
                        headerRight={
                            <div className='text-primary' style={{ cursor: 'pointer' }}>
                                <span hidden={lodash.findIndex(usersForModal, user => !user.isChecked) === -1}
                                      onClick={() => handleCheckAll()}
                                >{t('common.SelectAll')}</span>
                                <span hidden={lodash.findIndex(usersForModal, user => !user.isChecked) !== -1}
                                      onClick={() => handleCheckAll()}
                                >{t('common.UnSelect')}</span>
                            </div>
                        }
                    >
                        <section className="scroll-data-customer">
                            {usersForModal.map((user, index) => (
                                <div key={index} className={'cursor-pointer'}>
                                    <KirokuCheckboxBorder
                                        onChange={() => handleCheckUsers(user.id)}
                                        onClick={() => handleCheckUsers(user.id)}
                                        id={`user-select-modal-document-tab-${index}`}
                                        name={`user-select-modal-document-tab`}
                                        checked={!!user.isChecked} content={user.name}/>
                                </div>
                            ))}
                        </section>
                    </KirokuCardList>
                </KirokuLoader>
            </div>
        </KirokuModalBody>
        <KirokuModalFooter>
            <KirokuButton
                onClick={() => {
                    setKeywordSearchStaff('');
                    handleCloseModal();
                }}
                color={'white'}>
                {t('common.Cancel')}
            </KirokuButton>
            <KirokuButton
                color={'primary'}
                  onClick={() => {
                      handleShareDocument(users.filter(u => u.isChecked));
                      setKeywordSearchStaff('');
                  }}
            >
                {t('common.Assign')}
            </KirokuButton>
        </KirokuModalFooter>
    </KirokuModal>);
}

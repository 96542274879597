import React              from 'react';
import KirokuLabel        from './KirokuLabel';
import { useTranslation } from 'react-i18next';

function KirokuCheckDraft(props) {
    let { t }       = useTranslation();
    const draftable = props['draftable'];
    return (
        <KirokuLabel hidden={ !draftable['drafted_at'] } color={ 'warning' }>{ t('Surveys.Draft') }</KirokuLabel>
    );
}

export default KirokuCheckDraft;

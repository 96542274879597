import React, { useEffect, useState } from 'react';
import {
    KirokuButton, KirokuInputSearch, KirokuModal,
    KirokuModalBody, KirokuModalFooter, KirokuModalHeader, KirokuLoader,
    KirokuNotFound, KirokuCheckboxBorder,
} from '../../../../src/ui';
import { resources }                  from '@kiroku/api';
import { useTranslation }             from 'react-i18next';
import _                              from 'lodash';
import { useMixpanelTracking }        from '../../../Mixpanel/useMixpanelTracking';
import useNotification                from '../../Layout/useNotification';

export default function ModalAssignSurveys(props) {
    const { t }                               = useTranslation();
    const { result, search, loading }         = resources.useSearch('Template');
    const [templates, setTemplates]           = useState([]);
    const [templatesBank, setTemplatesBank]   = useState([]);
    const [keywords, setKeySearch]            = useState('');
    const [checkedSurveys, setCheckedSurveys] = useState([]);
    const assignments                         = resources.useAssign('Assignment')[0];
    const [disabledButton, setDisabledButton] = useState(false);
    const { notification }                    = useNotification();

    const { show } = props;

    const { trackingMixpanel } = useMixpanelTracking();

    useEffect(() => {
        setCheckedSurveys(props.checkedSurveys);
    }, [props.checkedSurveys, setCheckedSurveys]);

    useEffect(() => {
        search({
            type: 'all',
        });
    }, [search]);

    useEffect(() => {
        setTemplates(result);
        setTemplatesBank(result);
    }, [result]);


    useEffect(() => {
        if (!show) {
            setTemplates(result);
            setKeySearch('');
        }
    }, [result, show]);


    const surveyIds = templates.map(survey => survey.id);

    const checkedSurveyIds = checkedSurveys.map(survey => survey.id);

    const handleCheckAll = () => {
        setCheckedSurveys([...checkedSurveys, ...templates]);
    };

    const handleUncheckAll = () => {
        setCheckedSurveys(checkedSurveys.filter(survey => !surveyIds.includes(survey.id)));
    };

    const isCheckedAll = templates.reduce((checkAll, survey) => {
        return checkAll && checkedSurveyIds.includes(survey.id);
    }, true);

    const handleAssignments = (surveyAssign) => {
        let surveyAssignIds = surveyAssign.map(survey => survey.id);
        let valueAssign     = {
            customers: [props.customerId],
            surveys  : surveyAssignIds,
            fromDetailCustomer: true
        };
        setDisabledButton(true);
        trackingMixpanel('ASSIGN_SURVEY_TO_CUSTOMER', valueAssign);

        assignments(valueAssign).then(() => {
            props.closeModal();
            props.loadDetailCustomer();
            notification(t('message.AssignSuccess'), 'success');
        }).catch(error => {
            console.log(error);
        });

    };

    const handleSearch = (keywordSearch) => {
        let inputSearch = keywordSearch.replace(/[°"§%()\][{}=\\?´`'#<>|,;.:*+_-]+/g, '');
        setTemplates(templatesBank.filter(template => {
            return template.title.toLowerCase().search(inputSearch.toLowerCase()) !== -1;
        }));
    };

    return (
        <div>
            <KirokuModal show={ props.show } onCloseModal={ () => props.closeModal() }>
                <KirokuModalHeader>
                    <div className="font-header-on-modal">{ t('CustomerEditor.AssignCustomerToSurvey') }</div>
                </KirokuModalHeader>
                <KirokuModalBody className={'pt-3 pl-3 pr-3'}>
                    <div style={ { paddingBottom: 17 } }>
                        <KirokuInputSearch
                            onChange={ (event) => {
                                setKeySearch(event.target.value);
                                handleSearch(event.target.value);
                            } }
                            value={ keywords }
                            style={ {height: 36, fontSize: 14}}
                            placeholder={ t('CustomerEditor.EnterNameSurvey') }/>
                    </div>

                    <div id="body-modal-as">
                        { templates.length ? <div className={'checkbox-bigger p-3 d-flex justify-content-between data-in-modal'}>
                            <div className={'font-size-16'}>{ t('Dashboard.SurveyTemplates') }</div>
                            <div className='text-primary' style={ { cursor: 'pointer' } }>
                                    <span onClick={ handleCheckAll }
                                          hidden={ isCheckedAll }
                                          className={'font-size-16'}
                                    >{ t('common.SelectAll') }</span>
                                <span hidden={ !isCheckedAll }
                                      onClick={ handleUncheckAll }
                                      className={'font-size-16'}
                                >{ t('common.UnSelect') }</span>
                            </div>
                        </div>: null
                        }
                        <div className="background-color" style={ {
                            overflow : 'scroll',
                            minHeight: 'calc(100vh - 450px)',
                            maxHeight: 'calc(100vh - 450px)',
                            height   : 'auto',
                        } }>
                            <KirokuLoader loading={ loading }>
                                <KirokuNotFound isResults={ templates.length }>
                                    { templates.map((survey) => (
                                        <div key={ survey.id }
                                             className={'cursor-pointer'}
                                                 onClick={ (event) => {
                                                     event.preventDefault();
                                                     if (!checkedSurveyIds.includes(survey.id)) {
                                                         setCheckedSurveys([...checkedSurveys, survey]);
                                                     } else {
                                                         setCheckedSurveys(checkedSurveys.filter(checkedSurvey => checkedSurvey.id !== survey.id));
                                                     }
                                                 }}
                                        >
                                            <KirokuCheckboxBorder
                                                onChange={ (event) => {
                                                    if (event.target.checked) {
                                                        setCheckedSurveys([...checkedSurveys, survey]);
                                                    } else {
                                                        setCheckedSurveys(checkedSurveys.filter(checkedSurvey => checkedSurvey.id !== survey.id));
                                                    }
                                                } }
                                                checked={ checkedSurveyIds.includes(survey.id) }
                                                content={<span className={ 'font-text-in-checkbox cursor-pointer' }>{  survey.title }</span> }/>
                                        </div>
                                    )) }
                                </KirokuNotFound>
                            </KirokuLoader>
                        </div>
                    </div>
                </KirokuModalBody>
                <KirokuModalFooter>
                    <KirokuButton onClick={ () => props.closeModal() } color={ 'white' }>
                        { t('common.Cancel') }
                    </KirokuButton>
                    <KirokuButton color={ 'success' }
                                  disabled={ checkedSurveys.length === 0 || disabledButton }
                                  onClick={ () => {
                                      let surveyAssign = _.uniqBy(checkedSurveys, 'id');
                                      handleAssignments(surveyAssign);
                                  } }>
                        { t('common.Assign') }
                    </KirokuButton>
                </KirokuModalFooter>
            </KirokuModal>
        </div>
    );
}

import React, { useRef, useState } from 'react';
import { useAsQuestionType }                  from '../../../Recording';
import {
    KirokuCardWeb,
    KirokuCardBodyWeb, KirokuTextareaAutosize,
} from '../../../../ui';
import { useHeadingQuestion }       from '../../useSurvey';
import QuestionCardTitle            from './QuestionCardTitle';
import { ButtonNext, getNext }      from './getNextQuestion';
import { QuestionSkipped }          from './QuestionSkipped';

export function setDefaultAnswerInput(question) {

    if (question[ 'defaultAnswer' ]) {
        return {
            ...question,
            answers: question[ 'defaultAnswer' ],
        };
    }
    return {
        ...question,
        answers: '',
    };
}

export default function InputText({ question }) {

    const refInputQuestion        = useRef(null);
    const { doAnswer, answering } = useAsQuestionType(question);

    const { content } = useHeadingQuestion(question);
    const [answer, setAnswer] = useState(question.answers)
    return (<div>
        <div id='component-question' >
            <div id='web-q' className='pb-3 '>
                <KirokuCardWeb>
                    <div>
                        <QuestionCardTitle
                            isDisabled={question.isDisabled}
                            question={question}
                            handleResetAnswer={() => {
                                doAnswer('', question.index + 1);
                                setAnswer('');
                            }}
                            hasAnswer={!!question.answers}
                        />
                        <KirokuCardBodyWeb>
                            <QuestionSkipped
                                isDisabled={question.isDisabled}
                            >
                                <span className='content-name-question'>{content}</span>
                                <div className='answer-question'>
                                    <KirokuTextareaAutosize className='form-area'
                                                            disabled={ question.isDisabled }
                                                            forwardref={ refInputQuestion }
                                                            name={ `${ question.index }-input` }
                                                            value={ answer }
                                                            onClick={ () => {
                                                                answering();
                                                            } }
                                                            onChange={ (event) => setAnswer(event.target.value) }
                                                            onBlur={ (event) => {
                                                                answering();
                                                                doAnswer(event.target.value,
                                                                    getNext({ ...question, answers: event.target.value }));
                                                            } }
                                    />

                                    <div className={'pb-3'} style={{ float: 'right' }}>
                                        <ButtonNext
                                            question={question}
                                            onClick={() => {
                                                if(answer) {
                                                    doAnswer(answer, getNext(question))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </QuestionSkipped>
                        </KirokuCardBodyWeb>
                    </div>
                </KirokuCardWeb>
            </div>
        </div>
    </div>);
}

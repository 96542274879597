import React, { useCallback, useEffect, useState }                 from 'react';
import If                                                          from '../../../If';
import { RecordingProvider }                                       from '../../../Recording';
import { availableQuestions }                                      from '../QuestionTypeWeb';
import { authentication, resources }                               from '@kiroku/api';
import ModalConfirmExitConductSurvey                               from '../ModalConfirmExitConductSurvey';
import {
    KirokuButton, KirokuLoader, KirokuModal,
    KirokuModalFooter, KirokuModalHeader, SearchNotFound,
}                                                                  from '../../../../ui';
import moment                                                      from 'moment';
import { getAnswersFormQuestion }                                  from '../../sevicesInRecord';
import ConvertDataRecordEditProvider, { useDataRecordEditContext } from './ConvertDataRecordEditProvider';
import useLockEditRecord                                           from '../../../Recording/useLockEditRecord';
import config                                                      from '../../../../config';
import useNotification                                             from '../../../Layout/useNotification';
import { useTranslation }                                          from 'react-i18next';
import { useMixpanelTracking }                                     from '../../../../Mixpanel/useMixpanelTracking';
import SurveyMemo                                                  from '../SurveyMemo';
import queryString                                                 from 'query-string';
import { isErrorPermissionEditRecordLocking }                      from '../../../../kirokuAPIClient';

const RecordingProviderMemo = React.memo(
    props => {
        return <RecordingProvider {...props}/>;
    },
    (prevProps, nextProps) => {
        return prevProps.survey && (prevProps.survey.id !== nextProps.survey.id);
    },
);

function WrapperRecordingProvider({ children }) {

    const { survey, disabledRange, record } = useDataRecordEditContext();

    return <RecordingProviderMemo
        availableQuestions={availableQuestions}
        survey={survey}
        disabledRange={disabledRange}
        customer={record.customer}
    >
        {children}
    </RecordingProviderMemo>;
}


export default function RecordEditor(props) {
    const { t } = useTranslation();

    const [isShowModalConfirmCancel, setIsShowModalConfirmCancel] = useState(false);

    const [record, loadDetail, isLoading]                            = resources.useDetail('Record');
    const [resultUpdateRecord, update, isLoadingUpdate, errorUpdate] = resources.useUpdate('Record');
    const profile                                                    = authentication.useAuthenticatedContext().user;


    const {
              infoLocking, canIsFixIt, previousLock, unRegisterLock, comebackCalling,
              registerLock, isDisplayAskReload, setDisplayAskReload, lockAPI
          }                    = useLockEditRecord('Record');
    const { notification }     = useNotification();
    const { trackingMixpanel } = useMixpanelTracking();

    useEffect(() => {
        window.addEventListener('mouseenter', comebackCalling )
        window.addEventListener('mousemove', comebackCalling )
        window.addEventListener('touchstart', comebackCalling)
        return () => {
            window.removeEventListener('mouseenter', comebackCalling)
            window.removeEventListener('mousemove', comebackCalling)
            window.removeEventListener('touchstart', comebackCalling)
        }
    }, [comebackCalling])



    useEffect(() => {
        const dataPolling = setInterval(() => {
            registerLock(props.match.params.id);
        }, config.lockCheckTime);
        return () => {
            clearInterval(dataPolling);
        };
    }, [registerLock, props.match.params.id]);

    useEffect(() => {
        lockAPI(props.match.params.id);
    }, [lockAPI, props.match.params.id]);

    const goBack = useCallback(() => {
        if (window.location.previous === '/records/create/customer/:customerId/survey/:surveyId') {
            delete window.location.previous;
            props.history.go(-2);
        } else {
            props.history.goBack();
        }
    }, [props.history]);

    useEffect(() => {
        loadDetail(props.match.params.id).catch(error => console.log(error));
    }, [props.match.params.id, loadDetail]);

    useEffect(() => {
        if (resultUpdateRecord && !resultUpdateRecord.has_draft) {
            const urlQuery = queryString.stringify({ customerId: resultUpdateRecord.customer.id, customerName:resultUpdateRecord.customer.name, surveyId: resultUpdateRecord.survey_template.id, title: resultUpdateRecord.survey_template.title });
            props.history.push(`/record-completed?${urlQuery}`);
        }
    }, [resultUpdateRecord, props, goBack]);

    useEffect(() => {
        if (errorUpdate) {
            console.log(errorUpdate);
        }
    }, [errorUpdate]);


    const handleSaveDraftRecord = (dataQuestions, serviceAt) => {
        const statusDataRecord = {
            survey_template: {
                ...record[ 'survey_template' ],
                ...dataQuestions,
            },
            customer       : {
                id         : record.customer.id,
                name       : record.customer.name,
                address    : record.customer.address,
                certificate: record.customer.certificate,
            },
            has_draft      : 'draft',
            draft_by       : profile.id,
            drafted_at     : moment().unix(),
            ...serviceAt,
            answer_search  : getAnswersFormQuestion(dataQuestions.flowedQuestions),
        };
        update(record.id, statusDataRecord).then(() => {
            notification(t('createRecord.SaveDraftSuccess'), 'success');
        }).catch((error) => {
            if(isErrorPermissionEditRecordLocking(error)) {
                lockAPI(record.id)
            }
            notification(t('createRecord.CreateRecordFail'), 'danger');
        });
        trackingMixpanel('RECORD_DRAFTED_TRACKING', statusDataRecord);
    };

    const handleUpdateRecord = (dataQuestions, serviceAt) => {
        const dataRecord = {
            survey_template: {
                ...record[ 'survey_template' ],
                ...dataQuestions,
            },
            customer       : {
                id         : record.customer.id,
                name       : record.customer.name,
                address    : record.customer.address,
                certificate: record.customer.certificate,
            },
            has_draft      : null,
            draft_by       : null,
            drafted_at     : null,
            ...serviceAt,
            answer_search  : getAnswersFormQuestion(dataQuestions.flowedQuestions),
        };
        update(record.id, dataRecord).then(() => {
            notification(t('createRecord.UpdateRecordSuccess'), 'success');
            unRegisterLock(record.id);
        }).catch((error) => {
            if(isErrorPermissionEditRecordLocking(error)) {
                lockAPI(record.id)
            }
            notification(t('createRecord.CreateRecordFail'), 'danger');
        });
        trackingMixpanel('RECORD_EDITED_TRACKING', dataRecord);
    };


    return (
        <div>
            <KirokuLoader
                loading={isLoading || !infoLocking}
            >
                <If statement={infoLocking}
                >
                    {() => <If statement={record}
                               otherwise={ <SearchNotFound message={ t('common.RecordInvalid') } description={ ' ' }/> }
                    >
                        {() =>
                            <ConvertDataRecordEditProvider
                                record={record}
                            >

                                <KirokuModal show={isDisplayAskReload && previousLock} onCloseModal={() => {
                                    setDisplayAskReload(false)
                                }}>
                                    <KirokuModalHeader>
                                        <div className={'text-center'}>
                                            {t('common.It seems the record has been updated by', {userName: previousLock && previousLock.user_name})}
                                        </div>
                                    </KirokuModalHeader>
                                    <KirokuModalFooter>
                                        <KirokuButton
                                            onClick={() => {
                                                setDisplayAskReload(false)
                                            } }
                                            color={'white'}>
                                            {t('createRecord.No')}
                                        </KirokuButton>
                                        <KirokuButton color={'primary'} style={{ width: 110 }}
                                                      onClick={() => window.location.reload() }>
                                            {t('createRecord.Yes')}
                                        </KirokuButton>
                                    </KirokuModalFooter>
                                </KirokuModal>
                                <WrapperRecordingProvider>
                                    <SurveyMemo
                                        user={record.user}
                                        handleModalCancel={() => setIsShowModalConfirmCancel(!isShowModalConfirmCancel)}
                                        handleSaveStatus={(dataQuestions, serviceAt) => handleSaveDraftRecord(dataQuestions, serviceAt)}
                                        handleCreateRecord={(dataQuestions, serviceAt) => handleUpdateRecord(dataQuestions, serviceAt)}
                                        isLoadingAPI={isLoadingUpdate}

                                        infoLocking={infoLocking}
                                        canIsFixIt={canIsFixIt}
                                        isRecodingScreen
                                    />
                                </WrapperRecordingProvider>
                                <ModalConfirmExitConductSurvey
                                    handleCloseModal={() => setIsShowModalConfirmCancel(false)}
                                    isShowModal={isShowModalConfirmCancel}
                                    handleConfirmModal={() => {
                                        setIsShowModalConfirmCancel(false);
                                        goBack();
                                        unRegisterLock(record.id);
                                    }}
                                />
                            </ConvertDataRecordEditProvider>
                        }
                    </If> }
                </If>

            </KirokuLoader>
        </div>
    );
}




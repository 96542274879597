import React              from 'react';
import Dropzone           from 'react-dropzone';
import config             from '../../../../../config';
import { useTranslation } from 'react-i18next';
import FormUploadCustomer from '../DocumentUI/FormUploadCustomer';


export default function UploadFileWeb(props) {
    const { t } = useTranslation();
    const { onDrop, onCancel, loadingUpload, files } = props;

    return (<div className='form-upload-data cursor-pointer'>
        <Dropzone
            multiple={false}
            accept={config.acceptFileTypes}
            onDrop={onDrop}
            onFileDialogCancel={() => onCancel}
        >
            {({ getRootProps, getInputProps, isDragActive }) => (
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ?
                        <FormUploadCustomer>
                            <span>{t('common.DragFile')}</span>
                        </FormUploadCustomer> :
                        <div className='wrap-upload-customer'>
                            <div className='fix-center pt-3 pb-3'>
                                <div
                                    className={`border-icon-upload ${(files.length && loadingUpload) ? 'active' : ''}`}>
                                    <div className='main-loading'>
                                        <div className={loadingUpload ? 'opacity-main' : ''}>
                                            <div className="overlay"/>
                                        </div>
                                        <div
                                            className={!loadingUpload ? 'display-none' : 'modal-kiroku fade show'}>
                                            <div className="modal-dialog modal-dialog-centered fix-center">
                                                <div className='as'>
                                                    <button className='btn' style={{
                                                        padding   : '.5rem',
                                                        background: 'transparent',
                                                    }}>
                                                        <div className="spinner"
                                                             style={{ width: 60, height: 60 }}/>
                                                        <p className='text-white pt-2'>{t('common.Uploading')}</p>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <i hidden={loadingUpload}
                                       className="fas fa-file-upload color-icon-upload-customer"/>
                                </div>
                                <div className='text-upload-customer'>
                                    <span>{t('common.DragFile')}</span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )}
        </Dropzone>
    </div>);
}

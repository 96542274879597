import React              from 'react';
import { useTranslation } from 'react-i18next';

export default function TabResult({ messageFirst, messageSecond, children }) {
    const { t } = useTranslation();
    return (
        <div className='fix-center'>
            {children}
            <h5 className='mt-1'>{messageFirst ? t(`NullPage.${messageFirst}`) : ''}</h5>
            <h5 className='text-center'>{messageSecond ? t(`NullPage.${messageSecond}`) : ''}</h5>
        </div>
    );
}

import React                                       from 'react';
import { KirokuSortableContainer, SearchNotFound } from '../../../ui';
import { useTranslation }                          from 'react-i18next';
import { useHistory }                              from 'react-router-dom';
import If                                          from '../../If';
import KirokuLoader                                from '../../../ui/KirokuLoader';
import { SortableElement, sortableHandle }         from 'react-sortable-hoc';

const SortableHandlePoint = sortableHandle(() => <span><img className={'cursor-pointer'}
                                                            src={`/assets/img/move-point.svg`}
                                                            alt="move-point.svg"/></span>);
function SupportItem({ support, stt, t }) {
    const history = useHistory();
    return <div>
        <div className="pl-3 pr-1 pt-2 pb-3 d-flex bg-white"
             style={{
                 borderTop: '1px solid #dfe2e5',
                 background: stt % 2 !== 0 ? 'rgba(197, 208, 222, 0.1)' : 'none'
             }}
        >
            <div style={ { minWidth: '95%' } }>
                <div className="d-flex"><span className='ellipse'>{support.priority}</span></div>
                <div className={'pt-2'} onClick={() => {
                    history.push(`/supports/${support.id}`)
                }}>{support.target}</div>
                <div className={'planning-list-text pt-2'}
                     onClick={() => {
                         history.push(`/supports/${support.id}`)
                     }}
                > {support.description} </div>
                <div
                    className={'planning-list-text pt-2'}
                    onClick={() => {
                        history.push(`/supports/${support.id}`)
                    }}
                > {t('plans.achievementTime')} : {support.term}{t('plans.monthLater')}</div>
                <div
                    className={'planning-list-text pt-2'}
                    onClick={() => {
                        history.push(`/supports/${support.id}`)
                    }}
                > {t('plans.customerNeeds')} : </div>
                <div
                    className={'planning-list-text pt-2'}
                    onClick={() => {
                        history.push(`/supports/${support.id}`)
                    }}
                > {support.needs} </div>
                <div className={'planning-list-text pt-2 d-flex'}>
                    <div>
                        {t('plans.statusReview')}: &nbsp;
                    </div>
                    { support.review ?
                    <div className={'d-flex align-items-center'}>
                        {support.review.is_draft ?
                            <div className="d-flex align-items-center">
                                <img src={ `/assets/icon/iconDraft.svg` } alt="iconDraft.svg"
                                     style={ { width: 18, height: 16 } }
                                /> &nbsp;<span
                                className={ 'font-size-14' }>{ t('plans.draft') }</span>
                            </div> :
                            <div className="d-flex">
                                <img src={`/assets/img/reviewd.svg`} alt="reviewd.svg"/>
                                &nbsp;<span
                                className={ 'font-size-14' }>{t('plans.monitoredStatus')}</span>
                            </div>
                        }
                    </div>
                    : ''}
                </div>
            </div>
            <div style={ { minWidth: '5%'} }>
                <SortableHandlePoint/>
            </div>
        </div>
    </div>;
}
export default function SupportTableMobile({ result, loading, plan, handlePriority }) {
    const { t }     = useTranslation();

    const onSortEnd = ({ oldIndex, newIndex }) => {
        handlePriority(oldIndex, newIndex, result);
    };

    return (
        <KirokuLoader loading={loading}>
            <If statement={result.length}
                otherwise={<SearchNotFound description={' '} message={t('plans.NoResult')}/>}
            >
                {() =>
                    <KirokuSortableContainer
                        onSortEnd={onSortEnd}
                        useDragHandle
                    >
                        <div className='card'>
                            <div className='media-list media-list-hover media-list-divided scrollable ps-container ps-theme-default'>
                                {result.map((support, index) => {
                                    const SortableElementItemSupport = SortableElement(SupportItem);
                                    return <SortableElementItemSupport key={index} support={support} index={index}
                                                                       plan={plan} t={t}/>;
                                })}
                            </div>
                        </div>
                    </KirokuSortableContainer>
                }
            </If>
        </KirokuLoader>
    );
}

import { useCallback, useEffect, useState } from 'react';
import { resources }                        from '@kiroku/api';
import { useMixpanelTracking }              from '../../../../Mixpanel/useMixpanelTracking';
import { getGroupCommentByDay }             from './groupComment';

export function useCommentsTab(record) {
    const { result, search, loading }          = resources.useSearch('Comment');
    const [resultCommentCreate, createComment] = resources.useCreate('Comment');

    const [contentComment, setContentComment] = useState('');
    const { trackingMixpanel }                 = useMixpanelTracking();

    const handleSubmitComment = useCallback(() => {
        const commentRequestData = {
            record : record.id,
            title  : record.title,
            content: contentComment,
            customer: { id: record.customer.id, name: record.customer.name }
        };
        createComment(commentRequestData).then(() => {
            search({ record: record.id });
            setContentComment('');
        });
        trackingMixpanel('HANDLE_SUBMIT_COMMENT_RECORD', {...commentRequestData, title: record.title, record_id: record.id});
    }, [createComment, contentComment, record, search, trackingMixpanel]);

    useEffect(() => {

        return () => localStorage.removeItem('commentId');
    }, [resultCommentCreate]);


    const handleChangeComment = (value) => {
        localStorage.removeItem('commentId');
        setContentComment(value);
    };


    const getComments = useCallback((recordId) => {
        search({
            record: recordId,
        });
    }, [search]);


    return {
        comments        : result ? getGroupCommentByDay(result) : [],
        result,
        contentComment,
        handleSubmitComment,
        setContentComment,
        handleChangeComment,
        isLoadingComment: loading,
        getComments,
    };
}

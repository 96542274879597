import { useCustomerContext }   from "../../MakeCustomer/CustomerContext";
import { useCallback }          from "react";
import { CHANGE_INFO_CUSTOMER } from "../../MakeCustomer/CustomerReducer";

export default function useGuardianship() {

    const {infoCustomer, dispatch} = useCustomerContext();

    const {
        guardianship_type, decision_date,
        appointed_type, case_number, competent_court, report_month,
        registration_number, permanent_address, residence_card_address,
        facility_name
    } = infoCustomer;
    const setGuardianship = useCallback((name, value) => {
        dispatch({
            type: CHANGE_INFO_CUSTOMER,
            name, value
        })
    }, [ dispatch ]);

    return {
        guardianship_type,
        decision_date,
        appointed_type,
        competent_court,
        case_number,
        registration_number,
        permanent_address,
        residence_card_address,
        facility_name,
        report_month,
        setGuardianship
    };
};

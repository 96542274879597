import React, { useReducer } from 'react';
import UpdaterContext        from './UpdaterContext';
import UpdaterReducer        from './UpdaterReducer';

export default function UpdaterProvider({ children }) {
    const initState = {
        users          : [],
        userList       : [],
        userCheck      : [],
        userListChecked: [],
        keywords       : '',
        teamSelected   : [],
        isSelectedTab  : 'optionTab',
        keywordSelected: '',
        userConfirm    : [],
    };

    const [updaterState, dispatch] = useReducer(UpdaterReducer, initState, () => initState);

    return (
        <UpdaterContext.Provider value={ { updaterState, dispatch } }>
            { children }
        </UpdaterContext.Provider>
    );
}

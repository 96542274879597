import { useReportContext }        from './ReportContext';
import { useCallback }             from 'react';
import { CHANGE_CONDITION_REPORT } from './ReportReducer';
import queryString                 from 'query-string';
import momentTZ                    from 'moment-timezone';
import config                      from '../../../config';

export default function useReportCondition() {

    const { condition, dispatch } = useReportContext();

    const setCondition = useCallback((value) => {
        dispatch({
            type: CHANGE_CONDITION_REPORT,
            value,
        });
    }, [dispatch]);

    const print = (name) => {
        const urlQuery = queryString.stringify({
            ...condition,
            type    : 'report',
            template: name,
            token   : localStorage.getItem('token'),
            lang    : localStorage.getItem('i18nextLng') || 'ja',
            print   : true,
            timezone: momentTZ.tz.guess(),
        });
        window.open(`${ config.kirokuAPI.url }/v${ config.kirokuAPI.version }/report?${ urlQuery }`, 'MsgWindow', '_blank');
    };

    return {
        condition,
        setCondition,
        print,
    };
}

import {resources} from "@kiroku/api";

export default function useSearchCustomerServiceEndDate() {
    const {
        result, search,
        pagination,
        cancelAPISearch,
        condition
    } = resources.useSearch('Customer', {
        type        : 'customer_services_end_date',
        page        : 1,
    });

    return {
        resultCustomerServiceEndDate: result, searchCustomerServiceEndDate: search,
        paginationCustomerServiceEndDate: pagination,
        cancelAPISearch, conditionCustomerServiceEndDate: condition
    }
}

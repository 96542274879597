import React from "react";

function TBody (props){
    return (
        <tbody {...props} className="tbl-content">
            {props.children}
        </tbody>
    )
}

export default TBody;

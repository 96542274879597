import { useHistory }                   from 'react-router-dom';
import queryString                      from 'query-string';
import moment                           from 'moment';
import { useEffect, useState }          from 'react';
import useSearchServiceCustomer         from './ServiceEndDate/useSearchServiceCustomer';
import useSearchCustomerServiceEndDate  from './ServiceEndDate/useSearchCustomerServiceEndDate';
import useSearchCertificationExpiration from './CertificationTab/useSearchCertificationExpiration';
import useSearchCustomer                from './MonitoringTerm/useSearchCustomer';
import useSearchPlan                    from './PlanTab/useSearchPlan';
import useSearchReportMonthly           from './ReportMonth/useSearchReportMonthly';
import useGetMonthlyListCount           from './useGetMonthlyListCount';

export default function () {
    const history      = useHistory();
    const conditionUrl = queryString.parse(history.location.search.replace('?', ''), { arrayFormat: 'comma' });

    const getDefaultSelectionDate                       = () => {
        const urlUnixTime = conditionUrl.monitor_term ||
            conditionUrl.services_end_date ||
            conditionUrl.certification_expiration ||
            conditionUrl.report_month || conditionUrl.customer_services_end_date;
        if (urlUnixTime) {
            return {
                startMonth: urlUnixTime,
                endMonth  : moment.unix(parseInt(urlUnixTime)).endOf('month').endOf('day').unix(),
            };
        }

        if (conditionUrl.startMonth && conditionUrl.endMonth) {
            return {
                startMonth: conditionUrl.startMonth,
                endMonth  : conditionUrl.endMonth,
            };
        }
        return {
            startMonth: moment().startOf('month').startOf('day').unix(),
            endMonth  : moment().endOf('month').endOf('day').unix(),
        };
    };
    const [monthSelectionRange, setMonthSelectionRange] = useState(getDefaultSelectionDate());
    const [condition, setCondition]                     = useState(
        {
            type                      : conditionUrl.type ? conditionUrl.type : 'monitor_term',
            page                      : conditionUrl.page ? conditionUrl.page : 1,
            status                    : conditionUrl.status ? conditionUrl.status : 'active',
            monitor_term              : monthSelectionRange.startMonth,
            startMonth                : monthSelectionRange.startMonth,
            endMonth                  : monthSelectionRange.endMonth,
            report_month              : monthSelectionRange.startMonth,
            certification_expiration  : monthSelectionRange.startMonth,
            services_end_date         : monthSelectionRange.startMonth,
            customer_services_end_date: monthSelectionRange.startMonth,
            actionSearch              : 'getMonitorCount',
        });

    const [isGroupCustomerService, setIsGroupService] = useState(condition.type === 'customer_services_end_date');
    const {
              resultServiceEndDate, searchServiceEndDate,
              paginationServiceEndDate,
          }                                           = useSearchServiceCustomer();
    const {
              resultCustomerServiceEndDate, searchCustomerServiceEndDate,
              paginationCustomerServiceEndDate,
          }                                           = useSearchCustomerServiceEndDate();
    const {
              resultSearchExpired, searchCertificationExpired,
              paginationExpired,
          }                                           = useSearchCertificationExpiration();
    const { result, search, paginationCustomer }      = useSearchCustomer();
    const { resultPlan, searchPlan, paginationPlan }  = useSearchPlan();
    const {
              resultReportMonth, searchReportMonth,
              paginationReportMonth,
          }                                           = useSearchReportMonthly();

    const { getMonthlyCount, countResult } = useGetMonthlyListCount();

    const [loadingAPI, setLoadingAPI] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPage  : 1,
        totalResult: 0,
    });

    useEffect(() => {
        setLoadingAPI(true);
        if (condition.actionSearch === 'getMonitorCount') {
            getMonthlyCount(condition.startMonth, condition.endMonth);
        }
        if (condition.type === 'monitor_term') {
            search({
                type        : 'monitor_term', page: condition.page,
                monitor_term: moment.unix(condition.monitor_term).format('M/YYYY'),
                status      : condition.status,
            }).then(() => {
                setLoadingAPI(false);
            }).catch(err => {
                console.log(err);
                setLoadingAPI(false);
            });
        }
        if (condition.type === 'services_end_date') {
            searchServiceEndDate({
                type             : 'services_end_date', page: condition.page,
                services_end_date: moment.unix(condition.services_end_date).format('YYYY-MM'),
                status           : condition.status,
            }).then(() => {
                setLoadingAPI(false);
            }).catch(err => {
                console.log(err);
                setLoadingAPI(false);
            });
        }
        if (condition.type === 'condition') {
            searchPlan({
                type      : 'condition', page: condition.page,
                startMonth: condition.startMonth,
                endMonth  : condition.endMonth,
            }).then(() => {
                setLoadingAPI(false);
            }).catch(err => {
                console.log(err);
                setLoadingAPI(false);
            });
        }
        if (condition.type === 'report_month') {
            searchReportMonth({
                type        : 'report_month',
                report_month: moment.unix(condition.report_month).format('M'),
                status      : condition.status,
                page        : condition.page,
            }).then(() => {
                setLoadingAPI(false);
            }).catch(err => {
                console.log(err);
                setLoadingAPI(false);
            });
        }
        if (condition.type === 'certification_expiration') {
            searchCertificationExpired({
                type                    : 'certification_expiration',
                certification_expiration: moment.unix(condition.certification_expiration).format('M/YYYY'),
                status                  : condition.status,
                page                    : condition.page,
            }).then(() => {
                setLoadingAPI(false);
            }).catch(err => {
                console.log(err);
                setLoadingAPI(false);
            });
        }
        if (condition.type === 'customer_services_end_date') {
            searchCustomerServiceEndDate({
                type                      : 'customer_services_end_date',
                customer_services_end_date: moment.unix(condition.customer_services_end_date).format('M/YYYY'),
                status                    : condition.status,
                page                      : condition.page,
            }).then(() => {
                setLoadingAPI(false);
            }).catch(err => {
                console.log(err);
                setLoadingAPI(false);
            });
        }
    }, [search, searchPlan, searchReportMonth, condition, setCondition,
        searchServiceEndDate, searchCertificationExpired, searchCustomerServiceEndDate,
        getMonthlyCount,
    ]);


    useEffect(() => {
        const paginateCondition = {
            'monitor_term'              : paginationCustomer,
            'condition'                 : paginationPlan,
            'report_month'              : paginationReportMonth,
            'services_end_date'         : paginationServiceEndDate,
            'certification_expiration'  : paginationExpired,
            'customer_services_end_date': paginationCustomerServiceEndDate,
        };
        setPagination(paginateCondition[condition.type]);
    }, [paginationPlan, paginationCustomer, condition, paginationReportMonth,
        paginationServiceEndDate, paginationExpired, paginationCustomerServiceEndDate]);

    useEffect(() => {
        let urlCondition = { ...condition };
        if (urlCondition.type === 'monitor_term') {
            delete urlCondition.startMonth;
            delete urlCondition.endMonth;
            delete urlCondition.report_month;
            delete urlCondition.services_end_date;
            delete urlCondition.certification_expiration;
            delete urlCondition.actionSearch;
            delete urlCondition.customer_services_end_date;
        }
        if (urlCondition.type === 'services_end_date') {
            delete urlCondition.startMonth;
            delete urlCondition.endMonth;
            delete urlCondition.report_month;
            delete urlCondition.monitor_term;
            delete urlCondition.certification_expiration;
            delete urlCondition.customer_services_end_date;
        }
        if (urlCondition.type === 'customer_services_end_date') {
            delete urlCondition.startMonth;
            delete urlCondition.endMonth;
            delete urlCondition.report_month;
            delete urlCondition.monitor_term;
            delete urlCondition.certification_expiration;
            delete urlCondition.services_end_date;
        }
        if (urlCondition.type === 'report_month') {
            delete urlCondition.startMonth;
            delete urlCondition.endMonth;
            delete urlCondition.monitor_term;
            delete urlCondition.services_end_date;
            delete urlCondition.certification_expiration;
            delete urlCondition.customer_services_end_date;
        }
        if (urlCondition.type === 'certification_expiration') {
            delete urlCondition.startMonth;
            delete urlCondition.endMonth;
            delete urlCondition.monitor_term;
            delete urlCondition.services_end_date;
            delete urlCondition.report_month;
            delete urlCondition.customer_services_end_date;
        }
        if (urlCondition.type === 'condition') {
            delete urlCondition.monitor_term;
            delete urlCondition.services_end_date;
            delete urlCondition.report_month;
            delete urlCondition.certification_expiration;
            delete urlCondition.customer_services_end_date;
        }
        delete urlCondition.actionSearch;
        const urlQuery = queryString.stringify(urlCondition, { arrayFormat: 'comma' });
        history.replace('/customers-monitoring?' + urlQuery);
    }, [condition, history]);

    return {
        countResult,

        isGroupCustomerService, setIsGroupService, loadingAPI,

        resultServiceEndDate, resultCustomerServiceEndDate,
        resultSearchExpired, result, resultPlan, resultReportMonth,

        pagination, paginationCustomer, paginationServiceEndDate,
        paginationPlan, paginationExpired, paginationReportMonth,

        setMonthSelectionRange, monthSelectionRange, setCondition,
        condition,
    };
}

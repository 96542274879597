import React, { useEffect }  from 'react';
import {
    SearchNotFound, KirokuLoader,
    ComponentMobile, ComponentWeb,
    KirokuNotFound,
}                            from '../../../../ui';
import './PlanTab.css';
import { resources }         from '@kiroku/api';
import PlanTabMobile         from './PlanTabMobile';
import PlanTableWebComponent from './PlanTabWebComponent';

const NotPlanComponent = () => {
    return <div className="bg-white"><SearchNotFound description={' '}/></div>;
};

export default function PlanTab({ surveyId, tabType }) {

    //description === overview target === Objective
    const { search, result, condition, loading } = resources.useSearch('Plan', { type: 'survey', surveyId: surveyId });
    useEffect(() => {
        if (tabType === 'PLAN_TAB') {
            search(condition);
        }
    }, [tabType, condition, search]);


    return (
        <div hidden={tabType !== 'PLAN_TAB'} className={'pt-3'}>
            <KirokuLoader loading={loading}>
                <KirokuNotFound isResults={result.length} ComponentSearchNoFound={NotPlanComponent}>
                    <ComponentMobile>
                        <PlanTabMobile
                            result={result}
                            surveyId={surveyId}
                        />
                    </ComponentMobile>
                    <ComponentWeb>
                        <PlanTableWebComponent
                            result={result}
                            surveyId={surveyId}
                        />
                    </ComponentWeb>
                </KirokuNotFound>
            </KirokuLoader>
        </div>
    );
}

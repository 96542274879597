import { useCustomerContext }   from "../../MakeCustomer/CustomerContext";
import { useCallback }          from "react";
import { CHANGE_INFO_CUSTOMER, CHANGE_CUSTOMER,
    CHANGE_CONTRACT_START_CUSTOMER,
    CHANGE_CONTRACT_END_CUSTOMER } from "../../MakeCustomer/CustomerReducer";
import { useTranslation }       from "react-i18next";

export default function useCustomerInformation() {

    const {infoCustomer, dispatch} = useCustomerContext();
    const {contract_start, contract_end, classification, certificate} = infoCustomer;
    const {t} = useTranslation();

    const setInfoCustomer = useCallback((name, value) => {
        dispatch({
            type: CHANGE_INFO_CUSTOMER,
            name, value
        })
    }, [ dispatch ]);

    const setContractStartCustomer = useCallback((contractStart) => {
        dispatch({
            type: CHANGE_CONTRACT_START_CUSTOMER,
            ...contractStart
        })
    }, [ dispatch ]);

    const setContractEndCustomer = useCallback((contractEnd) => {
        dispatch({
            type: CHANGE_CONTRACT_END_CUSTOMER,
            ...contractEnd
        })
    }, [ dispatch ]);

    const setCustomer = useCallback((value) => {
        dispatch({
            type: CHANGE_CUSTOMER,
            value
        })
    }, [ dispatch ]);

    const buildCertificate = () => {
        return certificate.map(certificate => (
            {
                key: t(`CustomerEditor.certificate.${certificate}`),
                label: t(`CustomerEditor.${certificate}`),
                value: t(`CustomerEditor.${certificate}`)
            }
        ))
    }

    return {
        contract_start,
        contract_end,
        classification,
        buildCertificate,
        setInfoCustomer,
        setContractEndCustomer,
        setContractStartCustomer,
        setCustomer
    }
};

import { useEffect, useState } from 'react';
import { resources }           from '@kiroku/api';

export default function useTeamForSelectCustomer() {
    const { search, loading } = resources.useSearch('Team');
    const [teams, setTeams]   = useState([]);

    useEffect(() => {
        search({ type: 'all' }).then(res => {
            setTeams(res.result);
        });
    }, [search]);

    return {
        teams,
        loading,
    };
}

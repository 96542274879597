import React, { useState }                                                         from 'react';
import { KirokuCardBodyWeb, KirokuCardWeb, KirokuCheckBox, KirokusSortableHandle } from '../../../ui';
import ActionGroupBtn                                                              from './ActionGroupBtn';
import QuestionCardTitle                                                           from './QuestionTitleCard';
import InfoQuestion                                                                from './InfoQuestion';
import CheckboxUnrequited                                                          from './CheckboxUnrequited';
import NextQuestion                                                                from './NextQuestion';
import useQuestions                                                                from '../MakeSurvey/useQuestions';
import { modalSetLogic }                                                           from '../ModalSetLogicByQuestionType';
import ModalEditQuestion
                                                                                   from '../FormQuestion/ModalEditQuestion';

export default function MultiChoice({ question }) {
    const { update, copy, delQuestion }                         = useQuestions();
    const ModalSetLogic                                         = modalSetLogic[question.type];
    const [isOpenModalEditQuestion, setIsOpenModalEditQuestion] = useState(false);
    const [isOpenModalName, setIsOpenModalName]                 = useState(null);
    const handleSetDefaultAnswer                                = (indexAnswerDefault) => {
        let newQuestion                           = { ...question };
        newQuestion.choices[ indexAnswerDefault ] = {
            ...newQuestion.choices[ indexAnswerDefault ],
            defaultAnswer: !newQuestion.choices[ indexAnswerDefault ].defaultAnswer,
        };
        update(newQuestion);
    };

    const handleResetDefaultAnswer = () => {
        let newQuestion     = { ...question };
        newQuestion.choices = newQuestion.choices.map((choice) => {
            if (choice.defaultAnswer !== undefined) {
                delete choice.defaultAnswer;
            }
            return choice;
        });
        update(newQuestion);
    };

    return (
        <div id='component-question'>
            <div id='web-q' className='pb-3'>
                <KirokuCardWeb>
                    <KirokusSortableHandle>
                        <QuestionCardTitle
                            index={question.index}
                            isLogic={typeof question.nextQuestion === 'number'}
                        >
                            <ActionGroupBtn
                                handleOpenModalAddLogic={() => {
                                    setIsOpenModalName(question.index);
                                }}
                                handleOpenModalEdit={() => {
                                    setIsOpenModalEditQuestion(true);
                                }}
                                question={question}
                                resetDefaultAnswer={() => handleResetDefaultAnswer()}
                                handleDel={() => delQuestion(question.index)}
                                handleCopy={() => {
                                    copy(question);
                                }}
                            />
                        </QuestionCardTitle>
                    </KirokusSortableHandle>
                    <KirokuCardBodyWeb>
                        <CheckboxUnrequited
                            question={question}
                            isNotUse={typeof question.nextQuestion === 'number'}
                        />
                        <InfoQuestion question={question}/>
                        <NextQuestion nextQuestion={question.nextQuestion}/>
                        <div className='answer-question'>
                            {question.choices.map((item, index) =>
                                <KirokuCheckBox key={index}
                                                checked={!!item.defaultAnswer}
                                                id={`${question.index}-MultiChoice-${index}`}
                                                content={item.answer}
                                                onChange={() => handleSetDefaultAnswer(index)}
                                />,
                            )}
                        </div>
                    </KirokuCardBodyWeb>
                </KirokuCardWeb>

                <ModalEditQuestion
                    isOpenModalEditQuestion={isOpenModalEditQuestion}
                    handleAction={() => setIsOpenModalEditQuestion(!isOpenModalEditQuestion)}
                    question={question}
                    updateQuestion={(questionData) => update(questionData)}
                />

                <ModalSetLogic
                    question={question}
                    isOpen={isOpenModalName === question.index}
                    confirm={() => {
                        setIsOpenModalName(null);
                    }}
                    cancellation={() => setIsOpenModalName(null)}
                />
            </div>
        </div>
    );
}

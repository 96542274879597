import React, { useCallback, useEffect, useState }           from 'react';
import { useRecordingContext }                               from '../../Recording';
import AboutSurvey                                           from '../../Templates/surveysDetail/AboutSurvey';
import DescriptionSurvey                                     from '../DescriptionSurvey';
import { ScrollingAnchor, useScrollContext }                 from '../../../ui/Scrolling';
import { ComponentMobile, KirokuButton, KirokuItemNavigate } from '../../../ui';
import { useTranslation }                                    from 'react-i18next';
import { validateAnswer }                                    from '../sevicesInRecord';
import lodash                                                from 'lodash';
import useHeadingQuestion                                    from '../useSurvey/useHeadingQuestion';
import useAsQuestion                                         from '../../Recording/useAsQuestion';
import SurveyPlans                                           from '../SurveyPlans';
import config                                                from '../../../config';

function getDataRecords(dataQuestions) {
    dataQuestions = JSON.parse(JSON.stringify(dataQuestions));
    return {
        flowedQuestions: dataQuestions
            .filter(question => !question.isDisabled)
            .map(question => {
                delete question.isDisabled;
                return question;
            }),
        questions      : dataQuestions.map(question => {
            delete question.isDisabled;
            return question;
        }),
    };
}


function TitleQuestion({ question, currentQuestion , isMobile }) {
    const { contentOrHeading } = useHeadingQuestion(question);
    const { scrollTo }         = useScrollContext();
    const { navigate }         = useAsQuestion(question);

    const hasAnswer = (question) => {
        switch (question.type) {
            case 'InputText':
            case 'Date' :
            case 'Number':
            case 'OnlyDate':
            case 'Time':
            case 'YesNo':
                return !!question.answers;
            case 'Media':
            case 'Single':
                return !!question.answers.answer;
            case 'MultiChoice':
                return !!question.answers.filter(answer => answer[ 'is_choice' ]).length;
            case 'Message':
                return false;
            default:
                return question;
        }
    }

    return (
        <KirokuItemNavigate
            onClick={() => {
                scrollTo(`question-${question.index}`, 300);
                navigate(question.index);
            }}
            isDisabled={question.isDisabled}
            active={(currentQuestion && (question.index === currentQuestion.index))}
            hasAnswer={hasAnswer(question)}
            requiredAnswer={!question.unrequited}
            isMobile={isMobile}
            isMessageQuestion={question.type === 'Message'}
        >
            {isMobile ? question.index + 1 : contentOrHeading}
        </KirokuItemNavigate>
    );

}

export default function Survey(props) {
    const {
              user, handleModalCancel, isLoadingAPI,
              handleSaveStatus, handleCreateRecord,
              actionPreview, infoLocking, canIsFixIt, isRecodingScreen
          }                                                                 = props;
    const { survey, customer, drafts, availableQuestions, currentQuestion } = useRecordingContext();
    const { t }                                                             = useTranslation();

    const handleFocusIn = useCallback((e) => {
        const target = e.target;
        if ( window.innerWidth < config.configWithMobile && target.tagName === "TEXTAREA") {
            let header = document.getElementById('hidden-about-survey');
            header.classList.remove('keeping-about-survey');
        }
    },[]);

    const handleFocusOut = useCallback(() => {
        if(window.innerWidth < config.configWithMobile) {
            let header = document.getElementById('hidden-about-survey');
            header.classList.add('keeping-about-survey');
        }
    }, [])

    useEffect(() => {
        document.addEventListener('focusin', handleFocusIn);
        document.addEventListener('focusout', handleFocusOut);
        return () => {
            document.removeEventListener('focusin', handleFocusIn);
            document.removeEventListener('focusout', handleFocusOut);
        }
    },[handleFocusIn, handleFocusOut]);

    const [serviceAt, setServicedAt] = useState({
        serviced_at: survey.serviced_at,
        has_time   : survey.has_time,
    });

    const setDisable = () => {
        const questions                   = [...drafts].filter(question => !question.isDisabled);
        const arrayValidateAnswerQuestion = questions.map(question => {
            return {
                validate: question.unrequited ? (question.answers || '') : validateAnswer(question),
            };
        });
        return lodash.findIndex(arrayValidateAnswerQuestion, ['validate', null]) !== -1;
    };

    return (
        <div>
            <div className={'survey-recording'}>
                <div className={'navigator-question'}>
                    <div className="padding-responsive keeping-about-survey"
                         style={{ paddingBottom: 0, top: 70, paddingRight: 0 }}
                    >
                        <div className={'scroll-navigator-question bg-white'}>
                            <ul className="list-group">
                                {drafts.map((question, index) => {
                                    return <TitleQuestion question={question}
                                                          key={index}
                                                          currentQuestion={currentQuestion}/>;

                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={'range-recoding'}>
                    <div className="padding-responsive keeping-about-survey bg-main" id={'hidden-about-survey'}
                         style={{ paddingBottom: 0, top: 70 }}
                    >
                        <div className=" ">
                            <div className='fix-center'>
                                <div style={{ width: '100%' }}>
                                    <AboutSurvey
                                        survey={survey}
                                        customer={customer}
                                        user={user}
                                        isUseServicedAt
                                        isNotEditServiced={!canIsFixIt}
                                        handleChangedServicedAt={(serviceAt) => {
                                            setServicedAt(serviceAt);
                                        }}
                                        infoLocking={infoLocking}
                                        canIsFixIt={canIsFixIt}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="padding-responsive" style={{ paddingTop: 0 }}>
                        <div className={' '}>
                            <DescriptionSurvey
                                description={survey.description}
                            />
                            {
                                isRecodingScreen && <SurveyPlans  survey_id={survey.id} customer_id={customer.id}/>
                            }
                            <div>
                                {drafts.map((question, index) => {
                                    const Question = availableQuestions[ question.type ].component;
                                    return (
                                        <div key={index}>
                                            <ScrollingAnchor anchor={`question-${index}`}>
                                                <Question
                                                    question={{
                                                        ...question,
                                                        isDisabled: canIsFixIt ? question.isDisabled : true,
                                                    }}
                                                />
                                            </ScrollingAnchor>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className='fix-center pt-2 pb-4'>
                                <KirokuButton
                                    hidden={actionPreview}
                                    disabled={isLoadingAPI || setDisable() || !canIsFixIt}
                                    color={'success'}
                                    onClick={() => {
                                        handleCreateRecord(getDataRecords(drafts), serviceAt);
                                    }}
                                >
                                    {t('createRecord.Submit')}
                                </KirokuButton>
                            </div>
                        </div>
                    </div>
                    <div className={'fix-center pb-3'}>
                        <div className={'d-flex justify-content-between'}>
                            <KirokuButton
                                color={'white'}
                                onClick={handleModalCancel}>
                                {t('createRecord.cancel')}
                            </KirokuButton>
                            <KirokuButton
                                hidden={actionPreview}
                                disabled={isLoadingAPI || !canIsFixIt}
                                style={{ marginLeft: 10 }} color={'primary'}
                                onClick={() => {
                                    handleSaveStatus(getDataRecords(drafts), serviceAt);
                                }}>
                                {t('createRecord.SaveDraft')}
                            </KirokuButton>
                        </div>
                    </div>
                    <ComponentMobile>
                        {/*<div className={'pr-2'} style={ { position: 'fixed', bottom: 20, right: 0, zIndex: 1000 } }>*/}
                        {/*    <div hidden={ isShowQuestion }>*/}
                        {/*        <div style={{*/}
                        {/*            overflow : 'scroll',*/}
                        {/*            maxHeight: '80vh',*/}
                        {/*            width: 50*/}
                        {/*        }}>*/}
                        {/*            <ul className="list-group">*/}
                        {/*                { drafts.map((question, index) => {*/}
                        {/*                    return <TitleQuestion question={ question }*/}
                        {/*                                          key={ index }*/}
                        {/*                                          currentQuestion={ currentQuestion }*/}
                        {/*                                          isMobile={true}*/}
                        {/*                    />;*/}
                        {/*                }) }*/}
                        {/*            </ul>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="pt-2 pb-2"  style={{  width: 50, backgroundColor: '#d6d6d6', textAlign: 'center'}}>*/}
                        {/*            <span onClick={ () => {*/}
                        {/*                setShowQuestion(!isShowQuestion);*/}
                        {/*            } }>*/}
                        {/*                <img src={ `/assets/img/pen-edit.svg` } alt="pen-edit.svg" style={{ width: 20, height: 20}}/>*/}
                        {/*        </span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="padding-responsive">
                            <div style={ { marginBottom: 80 } }/>
                        </div>
                    </ComponentMobile>
                </div>
            </div>
        </div>

    );
}

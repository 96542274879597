import React                              from 'react';
import {
    KirokuCardWeb,
    KirokuCardBodyWeb,
    KirokuCheckBox,
}                                         from '../../../../ui';
import { useHeadingQuestion }             from '../../useSurvey';
import { useAsQuestionType }              from '../../../Recording';
import QuestionCardTitle                  from './QuestionCardTitle';
import { ButtonNext, getNextMultiChoice } from './getNextQuestion';
import { QuestionSkipped }                from './QuestionSkipped';
import lodash                             from 'lodash';
const width = window.innerWidth;

export function setDefaultAnswerMultiChoice(question) {
    const answers = question.choices.map(choice => {
        return {
            ...choice,
            answer   : typeof choice === 'string' ? choice : choice.answer,
            is_choice: !!choice[ 'defaultAnswer' ],
        };
    });
    return {
        ...question,
        answers: answers,
    };
}

export default function MultiChoice({ question }) {

    const { doAnswer, answering } = useAsQuestionType(question);
    const { content }             = useHeadingQuestion(question);

    const handleAnswer = (event, index) => {
        const newAnswer    = [...question.answers];
        newAnswer[ index ] = { ...newAnswer[ index ], is_choice: event.currentTarget.checked };
        answering();
        doAnswer(newAnswer, getNextMultiChoice({
            ...question,
            answers: newAnswer,
        }));
    };

    const defaultAnswer = question.answers.map(answer => {
        return {
            ...answer,
            is_choice: false,
        };
    });

    return (
        <div id='component-question'>
            <div id='web-q' className='pb-3'>
                <KirokuCardWeb>
                    <div className={ (width <= 768) && !!question.answers.filter(answer => answer[ 'is_choice' ]).length ? 'border-has-answer': ' '}>
                    <QuestionCardTitle
                        isDisabled={question.isDisabled}
                        question={question}
                        handleResetAnswer={() => doAnswer(defaultAnswer, question.index + 1)}
                        hasAnswer={!!question.answers.filter(answer => answer[ 'is_choice' ]).length}
                    />
                    <KirokuCardBodyWeb>
                        <QuestionSkipped
                            isDisabled={question.isDisabled}
                        >
                            <span className='content-name-question'>{content}</span>
                            <div className='answer-question'>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        {question.answers.map((item, index) =>
                                            <KirokuCheckBox key={index}
                                                            checked={item.is_choice}
                                                            id={`${question.index}-${index}`}
                                                            content={item.answer}
                                                            disabled={question.isDisabled}
                                                            onChange={(event) => handleAnswer(event, index)}
                                            />,
                                        )}
                                    </div>
                                    <div>
                                        <ButtonNext
                                            question={question}
                                            onClick={() => {
                                                if (lodash.findIndex(question.answers, answer => answer[ 'is_choice' ]) !== -1) {
                                                    doAnswer(question.answers, getNextMultiChoice(question));
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </QuestionSkipped>
                    </KirokuCardBodyWeb>
                    </div>
                </KirokuCardWeb>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { authentication, resources }  from '@kiroku/api';
import Survey                        from '../Survey';
import If                            from '../../../If';
import { RecordingProvider }         from '../../../Recording';
import { KirokuLoader }              from '../../../../ui';
import { availableQuestions }        from '../QuestionTypeWeb';
import ModalConfirmExitConductSurvey from '../ModalConfirmExitConductSurvey';
import moment                        from 'moment';
import { getAnswersFormQuestion }    from '../../sevicesInRecord';
import lodash                        from 'lodash';
import { useTranslation }            from 'react-i18next';
import useNotification               from '../../../Layout/useNotification';
import { useMixpanelTracking }       from '../../../../Mixpanel/useMixpanelTracking';
import { Link }                      from 'react-router-dom';
import queryString                   from 'query-string';

export function setDefaultAnswer(questions) {
    return questions.map(question => {
        return availableQuestions[ question.type ].setDefaultAnswer(question);
    });
}

export default function TakeRecord({ history, match }) {

    const [survey, loadDetailSurvey, isLoadingSurvey]                = resources.useDetail('Template');
    const [customer, loadDetailCustomer, isLoadingCustomer]          = resources.useDetail('Customer');
    const profile                                                    = authentication.useAuthenticatedContext().user;
    const [resultCreateRecord, create, isLoadingCreate, errorCreate] = resources.useCreate('Record');

    const [isShowModalConfirmCancel, setIsShowModalConfirmCancel]    = useState(false);
    const [isSurveyAssigned, setIsSurveyAssigned]                    = useState(false);

    const { t }                = useTranslation();
    const { notification }     = useNotification();
    const { trackingMixpanel } = useMixpanelTracking();

    useEffect(() => {
        if (parseInt(match.params.customerId)) {
            loadDetailCustomer(match.params.customerId);
        }
    }, [loadDetailCustomer, match.params.customerId]);


    useEffect(() => {
        if (customer && parseInt(match.params.surveyId)) {
            setIsSurveyAssigned(lodash.find(customer['surveys'], (survey) => survey.id === parseInt(match.params.surveyId)));
        }
    }, [customer, match.params.surveyId]);

    useEffect(() => {
        if (isSurveyAssigned) {
            loadDetailSurvey(match.params.surveyId);
        }
    }, [loadDetailSurvey, match.params.surveyId, isSurveyAssigned]);

    useEffect(() => {
        if (resultCreateRecord && resultCreateRecord.code !== 'KIROKU_CREATED') {
            history.push(`/records/${resultCreateRecord.id}`);
        }
        if (resultCreateRecord && resultCreateRecord.has_draft) {
            window.location.previous = match.path;
            history.push(`/records/edit/${resultCreateRecord.id}`);
        }
    }, [resultCreateRecord, history, match]);

    useEffect(() => {
        if (errorCreate) {
            console.log(errorCreate);
        }
    }, [errorCreate]);

    const handleCreateDraftRecord = (dataQuestions, serviceAt) => {
        delete survey.code;
        delete customer.code;
        const statusDataRecord = {
            survey_template: {
                ...survey,
                ...dataQuestions,
            },
            customer       : {
                id         : customer.id,
                name       : customer.name,
                address    : customer.address,
                certificate: customer.certificate,
            },
            has_draft      : 'draft',
            draft_by       : profile.id,
            drafted_at     : moment().unix(),
            ...serviceAt,
            answer_search  : getAnswersFormQuestion(dataQuestions.flowedQuestions),
        };
        create(statusDataRecord).then(() => {
            notification(t('createRecord.SaveDraftSuccess'), 'success');
        }).catch(() => {
            notification(t('createRecord.CreateRecordFail'), 'danger')
        });
        trackingMixpanel('RECORD_CREATE_DRAFTED_TRACKING', statusDataRecord);
    };

    const handleCreateRecord      = (dataQuestions, serviceAt) => {
        delete survey.code;
        delete customer.code;
        const dataRecord = {
            survey_template: {
                ...survey,
                ...dataQuestions,
            },
            customer       : {
                id         : customer.id,
                name       : customer.name,
                address    : customer.address,
                certificate: customer.certificate,
            },
            has_draft       : null,
            draft_by        : null,
            drafted_at      : null,
            ...serviceAt,
            answer_search   : getAnswersFormQuestion(dataQuestions.flowedQuestions),
        };

        create(dataRecord).then(() => {
            const urlQuery = queryString.stringify({ customerId: customer.id, customerName: customer.name, surveyId:survey.id, title: survey.title });
            history.push(`/record-completed?${urlQuery}`);

        }).catch(() => {
            notification(t('createRecord.CreateRecordFail'), 'danger');
        });
        trackingMixpanel('RECORD_CREATED_TRACKING', dataRecord);
    };

    return (
        <div>
            <KirokuLoader
                loading={ (isLoadingSurvey || isLoadingCustomer) && isLoadingCustomer}
            >
                <If statement={isSurveyAssigned}
                    otherwise={<div className={'padding-responsive'}>
                        <div className={'d-flex justify-content-center text-align-center'}>
                            <h5>{t('common.notifySurveyIsNotPermissionTake')}</h5>
                        </div>
                        <div className={'d-flex justify-content-center'}>
                            {customer && <Link to={`/customers/${customer.id}`}>
                                {t('common.textLinkToCustomerDetail')}
                            </Link>}
                        </div>
                    </div>}
                >
                    {() => <If statement={survey && customer}>
                        {() => <RecordingProvider
                            availableQuestions={availableQuestions}
                            survey={{
                                ...survey,
                                questions  : setDefaultAnswer(survey.questions),
                                serviced_at: moment().unix(),
                                has_time   : false,
                            }}
                            customer={customer}
                        >
                            <Survey
                                user={profile}
                                survey_id={survey.id}
                                handleModalCancel={() => setIsShowModalConfirmCancel(!isShowModalConfirmCancel)}
                                handleSaveStatus={(dataQuestions, serviceAt) => handleCreateDraftRecord(dataQuestions, serviceAt)}
                                handleCreateRecord={(dataQuestions, serviceAt) => handleCreateRecord(dataQuestions, serviceAt)}
                                isLoadingAPI={isLoadingCreate}
                                canIsFixIt
                                isRecodingScreen
                            />

                            <ModalConfirmExitConductSurvey
                                handleCloseModal={() => setIsShowModalConfirmCancel(false)}
                                isShowModal={isShowModalConfirmCancel}
                                handleConfirmModal={() => {
                                    setIsShowModalConfirmCancel(false);
                                    history.goBack();
                                }}
                            />
                        </RecordingProvider>
                        }
                    </If>
                    }
                </If>
            </KirokuLoader>
        </div>
    );
}

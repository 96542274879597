import {resources} from "@kiroku/api";

export default function useSearchPlan() {
    const {
        result, search,
        pagination,
        cancelAPISearch,
        condition
    } = resources.useSearch('Plan');


    return {
        resultPlan: result,
        searchPlan: search,
        paginationPlan: pagination,
        cancelAPISearchPlan: cancelAPISearch,
        conditionPlan: condition
    }
}
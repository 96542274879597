import React, { Component } from 'react';
import KirokuDropdownItem   from './KirokuDropdownItem';

export default class KirokuResetFilterCondition extends Component {

    state = {
        selected    : {
            label: '',
        },
        value       : '',
        popupVisible: false,
    };

    handleSelected(event) {
        this.props.onSelect(event);
        this.setState({
            popupVisible: false,
            selected    : event.kirokuSelected,
        });
    }

    constructor() {
        super();

        this.handleClick        = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick(e) {
        if (!this.node) {
            return;
        }
        this.handleClick();
    }

    componentDidMount() {
        if (this.props.title) {
            const newSelected = { ...this.state.selected, label: this.props.title };
            this.setState({
                selected: newSelected,
                value   : this.props.title,
            });
        }
    }

    // TODO will replace this method
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.title !== this.state.selected.label && nextProps.title !== this.state.value) {
            const newSelected = { ...this.state.selected, label: nextProps.title };
            this.setState({
                selected: newSelected,
                value   : nextProps.title,
            });
        }
        if (nextProps.title !== this.props.title) {
            this.setState({
                label : nextProps.title,
                value   : nextProps.title,
            })
        }
    }

    render() {
        return (
            <div ref={ node => {
                this.node = node;
            } }>
                <div className={ `dropdown ${ this.props.className ? this.props.className : '' }` }>
                    <button hidden={ this.props.hidden }
                            disabled={ this.props.disabled }
                            style={ this.props.style }
                            className={ `overflow btn btn-kiroku ${ this.props.className ? this.props.className : '' }` }
                            onClick={ this.handleClick.bind(this) }>
                        <i className={ `${ this.props.icon } left text-kiroku pr-5px` }/>
                        <span
                            className={ 'text-kiroku left' }>{ this.state.selected.label ? this.state.selected.label : this.props.defaultValue }</span>
                        <i className={ `${ !this.state.popupVisible ? 'fa fa-chevron-down icon-kiroku' : 'fa fa-chevron-up icon-kiroku' }` }/>
                    </button>
                    { this.state.popupVisible && (
                        <div
                            className={ `dropdown-kiroku dropdown-menu show ${ this.props.location ? this.props.location : '' }` }
                            style={ { position: 'absolute', willChange: 'top, left', left: 'auto', top: 'auto' } }>
                            <section className='scroll-data-dropdown-search'>
                                { this.props.items.map((item, index) => {
                                    const Item = item.component;
                                    return (
                                        <KirokuDropdownItem key={ index } onClick={ (event) => {
                                            event.kirokuSelected = item;
                                            this.handleClick(event);
                                            this.handleSelected(event);
                                        } }>
                                            { Item }
                                        </KirokuDropdownItem>
                                    );
                                }) }
                            </section>
                        </div>
                    ) }
                </div>
            </div>
        );
    }
}

